import axios from '../../../Axios';
import Vue from 'vue';
import { URLS } from '../../constants';
import {
  GET_EMAIL_TEMPLATES,
  SET_EMAIL_TEMPLATES,
  GET_UPDATE_EMAIL_TEMPLATE,
  SET_UPDATE_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_BY_LANG,
} from '../types';
const state = {
  emailTemplates: [],
};

const getters = {
  [GET_EMAIL_TEMPLATE_BY_LANG]: STATE => LANG =>
    STATE.emailTemplates.filter(emailTemplate => emailTemplate.lang === LANG),
};

const mutations = {
  [SET_EMAIL_TEMPLATES](state, emailTemplates) {
    state.emailTemplates = emailTemplates;
  },
  [SET_UPDATE_EMAIL_TEMPLATE](state, emailTemplate) {
    state.emailTemplates = state.emailTemplates.map(template =>
      template._id === emailTemplate._id ? emailTemplate : template
    );
  },
};

const actions = {
  [GET_EMAIL_TEMPLATES]({ commit: COMMIT }) {
    return new Promise(function(resolve, reject) {
      axios
        .get(URLS.EMAIL_TEMPLATE)
        .then(function(res) {
          COMMIT(SET_EMAIL_TEMPLATES, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_UPDATE_EMAIL_TEMPLATE]({ commit: COMMIT }, EMAIL_TEMPLATE) {
    return new Promise(function(resolve, reject) {
      axios
        .put(URLS.EMAIL_TEMPLATE, EMAIL_TEMPLATE)
        
        .then(function(res) {
          COMMIT(SET_UPDATE_EMAIL_TEMPLATE, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
