/**
 * The Dirt Lot Assessor focuses on space per cow for resting, shade and feeding, in addition to management of the feeding alley.
 */

const convert = require('convert-units');
import Assessor from './Assessor';

class DirtLot extends Assessor {
  constructor(app, evaluation, group, assessor) {
    super(app, evaluation, group, assessor);

    this.app = app;
    this.evaluation = evaluation;
    this.group = group;
    this.assessor = assessor;
    this.targetCorralArea = 46.3;
    this.minDirtLyingArea = 7.62;
  }

  getTargetCorralAreaPerCow() {
    const result = parseFloat(
      convert(this.targetCorralArea)
        .from('m2')
        .to(
          this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm2')
        )
    ).toDecimal(1);
    return Math.round10(result, 1);
  }

  getCorralAreaPerCow(roundPoint) {
    if (
      this.assessor.data.corral_area_per_cow === undefined ||
      this.assessor.data.corral_area_per_cow === null
    ) {
      return this.assessor.data.corral_area_per_cow;
    }

    if (roundPoint === false) {
      roundPoint = this.assessor.data.corral_area_per_cow.countDecimals() - 3;
      if (roundPoint < 0) roundPoint = 0;
    }

    return parseFloat(
      convert(this.assessor.data.corral_area_per_cow)
        .from('m2')
        .to(
          this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm2')
        )
    ).toDecimal(roundPoint);
  }

  setCorralAreaPerCow(newValue) {
    this.assessor.data.corral_area_per_cow = convert(newValue)
      .from(
        this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm2')
      )
      .to('m2')
      .toDecimal(newValue.countDecimals() + 3);
  }

  getAtLeastOneHeadlockPerCow() {
    return this.assessor.data.at_least_one_headlock_per_cow;
  }

  getConcreteFeedingAreaScrapedAtLeastOncePerDay() {
    return this.assessor.data
      .concrete_feeding_area_scraped_at_least_once_per_day;
  }

  getMinDirtLyingArea() {
    const measurements = this.evaluation.getMeasurements();
    if (measurements === 'imperial') {
      return 3
    } else {
      return 7.5
    }
    // const result = convert(this.minDirtLyingArea)
    //   .from('cm')
    //   .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
    //   .toDecimal(1);
    //
    // return Math.round10(result, 1);
  }

  getBeddingBeneathFreeOfWetManureContamination() {
    return this.assessor.data.bedding_beneath_free_of_wet_manure_contamination;
  }

  getAtLeast3InchesOfLooseManureInLyingArea() {
    return this.assessor.data.at_least_3_inches_of_loose_manure_in_lying_area;
  }

  getLyingAreaPassKneeDropTest() {
    return this.assessor.data.lying_area_pass_knee_drop_test;
  }
}

export default DirtLot;
