const Table = require('../../Misc/Table');

export const biosecurity = (app, report, evaluation, group, assessor) => {
  let slide;

  const categoriesObj = [
    {
      text: app.$t('Question'),
      options: report.styleOptions.tableHeader
    },
    {
      text: app.$t('Answer'),
      options: report.styleOptions.tableHeader
    },
    {
      text: app.$t('Comment'),
      options: report.styleOptions.tableHeader
    }
  ]
  let contentObj = [];

  if (assessor.getAreAnyMovingOnToTheMainFarm() !== null) {
    contentObj.push([
      {
        text: app.$t(
          'Are any cows/heifers/calves moving on to the main farm coming from a property where hygiene practices and previous infectious disease presence is unknown?'
        ),
        options: {
          color: '000000',
          align: 'left',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getAreAnyMovingOnToTheMainFarm() === 1
            ? app.$t('Yes')
            : app.$t('No'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getAreAnyMovingOnToTheMainFarm() === 1
            ? app.$t('Cattle should be carefully examined to determine presence of infectious disease such as digital dermatitis. In case of confirming infection, proper treatment and quarantine should be provided to avoid infection of healthy cattle.')
            : app.$t('Good!'),
        options: {
          align: 'left',
          color:
            assessor.getAreAnyMovingOnToTheMainFarm() === 1
              ? 'a40000'
              : '249824',
          fill: 'efefef',
          bold: false,
        },
      },
    ]);
  }

  if (assessor.getIsTheDairyBringingAnyCattleOntoTheDairy() !== null) {
    contentObj.push([
      {
        text: app.$t(
          'Is the dairy bringing ANY cattle (purchased or from custom heifer grower) on to the dairy?'
        ),
        options: {
          color: '000000',
          align: 'left',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getIsTheDairyBringingAnyCattleOntoTheDairy() === 1
            ? app.$t('Yes')
            : app.$t('No'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getIsTheDairyBringingAnyCattleOntoTheDairy() === 1
            ? app.$t(
                'Brought in cattle are a major risk factor for bringing in infectious disease, such as digital dermatitis, on the dairy.'
              )
            : app.$t('Good!'),
        options: {
          align: 'left',
          color:
            assessor.getIsTheDairyBringingAnyCattleOntoTheDairy() === 1
              ? 'a40000'
              : '249824',
          fill: 'efefef',
          bold: false,
        },
      },
    ]);
  }

  if (assessor.getAreCattleFromTheDairyShownAtFairsAndCattleShows() !== null) {
    contentObj.push([
      {
        text: app.$t(
          'Are cattle from the dairy shown at fairs and cattle shows?'
        ),
        options: {
          color: '000000',
          align: 'left',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getAreCattleFromTheDairyShownAtFairsAndCattleShows() === 1
            ? app.$t('Yes')
            : app.$t('No'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getAreCattleFromTheDairyShownAtFairsAndCattleShows() === 1
            ? app.$t(
                'Mixing cattle creates risk for transmission of infectious disease, which may then be transferred to the herd when the cows return home.'
              )
            : app.$t('Good!'),
        options: {
          align: 'left',
          color:
            assessor.getAreCattleFromTheDairyShownAtFairsAndCattleShows() === 1
              ? 'a40000'
              : '249824',
          fill: 'efefef',
          bold: false,
        },
      },
    ]);
  }

  if (
    assessor.getAreReturningCattleOrPurchasedCattleFootbathedBeforeComminglingWithTheMainHerd() !==
    null
  ) {
    contentObj.push([
      {
        text: app.$t(
          'Are returning cattle or purchased cattle footbathed before co-mingling with the main herd?'
        ),
        options: {
          color: '000000',
          align: 'left',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getAreReturningCattleOrPurchasedCattleFootbathedBeforeComminglingWithTheMainHerd() ===
          1
            ? app.$t('Yes')
            : app.$t('No'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getAreReturningCattleOrPurchasedCattleFootbathedBeforeComminglingWithTheMainHerd() ===
          1
            ? app.$t('Good!')
            : app.$t(
                'All returning cattle should be treated as contaminated and footbathed to reduce the risk of bringing in digital dermatitis.'
              ),
        options: {
          align: 'left',
          color:
            assessor.getAreReturningCattleOrPurchasedCattleFootbathedBeforeComminglingWithTheMainHerd() ===
            1
              ? '249824'
              : 'a40000',
          fill: 'efefef',
          bold: false,
        },
      },
    ]);
  }

  if (assessor.getIsTheHoofTrimmersEquipmentAlwaysClean() !== null) {
    contentObj.push([
      {
        text: app.$t(
          'Is the hoof trimmer’s equipment always clean (manure free) before arrival on the dairy?'
        ),
        options: {
          color: '000000',
          align: 'left',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getIsTheHoofTrimmersEquipmentAlwaysClean() === 1
            ? app.$t('Yes')
            : app.$t('No'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getIsTheHoofTrimmersEquipmentAlwaysClean() === 1
            ? app.$t('Good!')
            : app.$t(
                "The trimmer's chute and equipment should be manure free when they arrive on the farm to minimize spread of disease."
              ),
        options: {
          align: 'left',
          color:
            assessor.getIsTheHoofTrimmersEquipmentAlwaysClean() === 1
              ? '249824'
              : 'a40000',
          fill: 'efefef',
          bold: false,
        },
      },
    ]);
  }

  if (assessor.getDoesTheHerdTestForBvdPiCattle() !== null) {
    contentObj.push([
      {
        text: app.$t('Does the herd test for BVD PI cattle?'),
        options: {
          color: '000000',
          align: 'left',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getDoesTheHerdTestForBvdPiCattle() === 1
            ? app.$t('Yes')
            : app.$t('No'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getDoesTheHerdTestForBvdPiCattle() === 1
            ? app.$t('Good!')
            : app.$t(
                'BVD PI testing is recommended, please contact your veterinarian.'
              ),
        options: {
          align: 'left',
          color:
            assessor.getDoesTheHerdTestForBvdPiCattle() === 1
              ? '249824'
              : 'a40000',
          fill: 'efefef',
          bold: false,
        },
      },
    ]);
  }

  if (
    assessor.getHaveAnyPiCattleBeenIdentifiedInTheLast12Months() !== null &&
    assessor.getDoesTheHerdTestForBvdPiCattle() === 1
  ) {
    contentObj.push([
      {
        text: app.$t(
          'Have any PI cattle been identified in the last 12 months?'
        ),
        options: {
          color: '000000',
          align: 'left',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getHaveAnyPiCattleBeenIdentifiedInTheLast12Months() === 1
            ? app.$t('Yes')
            : app.$t('No'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false,
        },
      },
      {
        text:
          assessor.getHaveAnyPiCattleBeenIdentifiedInTheLast12Months() === 1
            ? app.$t(
                'BVD lowers the immunity of cattle and places them at greater risk for infectious disease. PI surveillance is recommended to monitor herd infection status.'
              )
            : app.$t('Good!'),
        options: {
          align: 'left',
          color:
            assessor.getHaveAnyPiCattleBeenIdentifiedInTheLast12Months() === 1
              ? 'a40000'
              : '249824',
          fill: 'efefef',
          bold: false,
        },
      },
    ]);
  }

  if (contentObj.length > 0) {

    slide = report.pptx.addSlide('Slide master');

    slide.addText(
      app.$t('Biosecurity Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
    );

    slide.addText(app.$t('Your Operation'), {
      x: 0.5,
      y: 2.4,
      ...report.styleOptions.subheaderBlue
    });

    Table.create(
      slide,
      categoriesObj,
      contentObj,
      report,
      group,
      'Biosecurity Assessor',
      {
        y: 2.7,
        colW: [3.25, 1.25, 3],
        ...report.styleOptions.tableOptions
      }
    );
  }
};
