<template>
  <div class="item mt-3">
    <slot></slot>
  </div>
</template>
<script>
import _ from 'lodash';
import { GET_CREATE_GROUP } from '../../store/types';
import { mapActions } from 'vuex';
export default {
  props: ['evaluation', 'group'],
  data() {
    return {
      timeoutSave: null,
    };
  },
  methods: {
    ...mapActions('groups', [GET_CREATE_GROUP]),
  },
  // watch: {
  //   group: {
  //     handler: _.debounce(async function() {
  //       await this[GET_CREATE_GROUP]({
  //         group: this.group,
  //         evaluationId: this.evaluation._id,
  //       });
  //     }),
  //     deep: true,
  //   },
  // },
  mounted() {
    this.$watch(
      'group',
      (newGroup, oldGroup) => {
        console.log('mounted');
        if (this.timeoutSave) {
          clearTimeout(this.timeoutSave);
          this.timeoutSave = null;
        }

        var self = this;
        this.timeoutSave = setTimeout(function() {
          //deep clone so we can delete assessors and groups before sending to firestore
          console.log('update');

          // self.$store.dispatch('setData', {
          //     currentVueInstance: self,
          //     endpointUrl: 'users/' + this.$route.params.userId + '/evaluation/' + evaluation.id + '/groups/' + newGroup.id,
          //     objectBeingUpdated: newGroup
          //  });
        //   this[GET_CREATE_GROUP]({
        //     group: this.group,
        //     evaluationId: this.evaluation._id,
        //   });
        }, 4000);
      },
      {
        deep: true,
      }
    );
  },
};
</script>
