import Group from './Group.js';

import convert from 'convert-units';

class Evaluation {
  constructor(app, evaluation) {
    this.app = app;
    this.evaluation = evaluation;
  }

  getId() {
    return this.evaluation._id;
  }

  getName() {
    return this.evaluation.name;
  }

  getCurrencyLogo(currency) {
    switch (currency) {
      case "AUD":
        return "A$";
      case "BRL":
        return "R$";
      case "GBP":
        return "£";
      case "CAD":
        return "C$";
      case "CNY":
        return "C¥";
      case "EGP":
        return "£";
      case "EUR":
        return "€";
      case "INR":
        return "₹";
      case "IDR":
        return "Rp";
      case "JPY":
        return "J¥";
      case "JOD":
        return "JD";
      case "MYR":
        return "RM";
      case "MXN":
        return "M$";
      case "MAD":
        return "د.م.";
      case "TWD":
        return "NT$";
      case "TRY":
        return "₺";
      case "NZD":
        return "NZ$";
      case "SAR":
        return "ریال";
      case "PEN":
        return "S/";
      case "CHF":
        return "CHF";
      case "KRW":
        return "₩";
      case "SEK":
        return "kr";
      case "THB":
        return "฿";
      case "USD":
        return "US$";
      case "VEB":
        return "Bs";
      case "VND":
        return "₫";
      default:
        return currency;
    }
  }

  getDate() {
    let date = new Date(this.evaluation.data.consultation_date);
    var monthNames = [
      this.app.$t("Jan."),
      this.app.$t("Feb."),
      this.app.$t("Mar."),
      this.app.$t("Apr."),
      this.app.$t("May"),
      this.app.$t("Jun."),
      this.app.$t("Jul."),
      this.app.$t("Aug."),
      this.app.$t("Sep."),
      this.app.$t("Oct."),
      this.app.$t("Nov."),
      this.app.$t("Dec."),
    ];
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    return day + " " + monthNames[monthIndex] + " " + year;
  }

  getHerdSize() {
    return this.evaluation.data.herdSize;
  }

  getCurrency() {
    return this.evaluation.data.currency;
  }

  getMeasurements() {
    return this.evaluation.data.measurements;
  }
  getMixMeasurements() {
    return this.evaluation.data.mixMeasurements;
  }

  get isImperial() {
    return this.evaluation.data.measurements === "imperial";
  }

  getInitiallMilkProduction(roundPoint) {
    if (
      this.evaluation.data.milkProduction === undefined ||
      this.evaluation.data.milkProduction === null
    ) {
      return this.evaluation.data.milkProduction;
    }

    if (roundPoint === false) {
      roundPoint =
        this.evaluation.data.milkProduction.countDecimals() - 3;
      if (roundPoint < 0) roundPoint = 0;
    }

    return convert(this.evaluation.data.milkProduction)
      .from("kg")
      .to(this.app.$getEquivalentUnit(this.getMeasurements(), "kg"))
      // .toDecimal(roundPoint);
  }

  setInitiallMilkProduction(newValue) {
    let DECIMAL_ROUND_OF = 3;

    if (!newValue.countDecimals()) {
      DECIMAL_ROUND_OF = 0;
    }

    if (!newValue) {
      this.evaluation.data.milkProduction = null;
      return;
    }
    this.evaluation.data.milkProduction = parseFloat(
      convert(newValue)
        .from(
          this.app.$getEquivalentUnit(this.getMeasurements(), "kg")
        )
        .to("kg")
    )
    // .toDecimal(newValue.countDecimals() + DECIMAL_ROUND_OF);
  }

  getMilkPrice(roundPoint, returnNumber = true) {
    if (
      this.evaluation.data.milkPrice === undefined ||
      this.evaluation.data.milkPrice === null
    ) {
      return undefined;
    }

    let milkPrice = parseFloat(this.evaluation.data.milkPrice);

    if (roundPoint === false) {
      roundPoint = milkPrice.countDecimals() - 3;
      if (roundPoint < 0) roundPoint = 0;
    }
    let convertUnit = this.app.$getEquivalentUnit(
      this.getMeasurements(),
      "kg"
    );
    if (convertUnit === "lb") {
      milkPrice = convert(milkPrice)
        .from("lb")
        .to("kg")
        .toDecimal(roundPoint);
    } else {
      milkPrice = convert(milkPrice)
        .from("kg")
        .to(convertUnit)
        .toDecimal(roundPoint);
    }
    if (returnNumber === true) {
      return milkPrice;
    }
    return milkPrice.toCurrency();
  }

  setMilkPrice(newValue) {
    let convertUnit = this.app.$getEquivalentUnit(
      this.getMeasurements(),
      "kg"
    );
    if (!newValue) {
      this.evaluation.data.milkPrice = null;
      return;
    }
    if (convertUnit === "lb") {
      this.evaluation.data.milkPrice = parseFloat(
        convert(newValue).from("kg").to(convertUnit)
      ).toDecimal(newValue.countDecimals() + 3);
    } else {
      this.evaluation.data.milkPrice = parseFloat(
        convert(newValue).from(convertUnit).to("kg")
      ).toDecimal(newValue.countDecimals() + 3);
    }
  }

  getGroups() {
    let groups = [];
    if (Object.keys(this.evaluation.groups).length > 0) {
      for (let groupId in this.evaluation.groups) {
        groups.push(
          new Group(this.app, this, this.evaluation.groups[groupId])
        );
      }
    }
    return groups;
  }

  getGroupById(id) {
    if (this.evaluation.groups && this.evaluation.groups[id]) {
      return new Group(this.app, this, this.evaluation.groups[id]);
    }
    return false;
  }

  getReport() {
    return this.evaluation.report;
  }
}

export default Evaluation;
