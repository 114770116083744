<!-- @format -->

<template>
  <div>
    <div
      class="container main-container main-container--hygiene view"
    >
      <div class="row">
        <div :class="[hasBoth, 'd-flex', 'align-items-center']">
          <h2 class="h2 m-0 mb-1 mr-1">
            {{ "Evaluate Your Mix" | translate }}
          </h2>
          <modal-info :value="'create-new'">
            <template slot="modal-content">
              <h2
                v-if="$route.path.search(/existing/) === -1"
                class="h2 pb-2 px-3 text-center mt-3"
              >
                {{ "Create A New Mix" | translate }}
              </h2>
              <h2 v-else class="h2 pb-2 px-3 text-center mt-3">
                {{ "Evaluate Your Mix" | translate }}
              </h2>
              <p class="px-3">
                {{
                  "Footbaths are a proven method of fighting lesions that can lead to lameness. Depending upon environmental factors and management practices, the amount of footbaths that are necessary can vary."
                    | translate
                }}
              </p>
              <p class="px-3">
                {{
                  "Make adjustments based on FirstStep® recommendations that arrive at a footbath program that works for your budget and your herd."
                    | translate
                }}
              </p>
              <p class="px-3">
                {{
                  "For more information about footbaths, click the link to learn more from"
                    | translate
                }}
                <a
                  :href="
                    $t(
                      'https://library.zinprofirststep.com/footbath/'
                    )
                  "
                  @click="$goToLameness($t('/footbath/'), $event)"
                  target="_blank"
                >
                  {{ "FirstStep Resources" | translate }}.</a
                >
              </p>
              <div class="text-center">
                <button
                  class="btn-cancel-modal pb-3"
                  data-dismiss="modal"
                >
                  {{ "Cancel" | translate }}
                </button>
              </div>
            </template>
          </modal-info>
        </div>
        <div
          v-if="
            $route.path.search(/existing/) !== -1 &&
            footbathsCount > 1
          "
          class="col-md-6 d-none d-lg-block"
        >
          <label class="mb-1">{{
            "Select a Footbath" | translate
          }}</label>
          <select
            @change="changeFootbath()"
            class="form-control mb-0"
            v-model="selectedFootbathId"
          >
            <option disabled :value="null">
              {{ "Select" | translate }}
            </option>
            <option
              v-for="(footbath, index) in assessor.footbaths"
              :key="index"
              :value="footbath.id"
            >
              {{ footbath.name }}
            </option>
          </select>
        </div>
        <div v-if="mixesCount > 1" class="col-md-6 d-none d-lg-block">
          <label class="mb-1">{{ "Select a Mix" | translate }}</label>
          <select
            v-if="footbathClass.footbath.mixes"
            class="form-control mb-0"
            v-model="selectedMixId"
          >
            <option disabled :value="null">
              {{ "Select" | translate }}
            </option>
            <option
              v-for="(mix, index) in formattedMixes"
              :key="index"
              :value="mix.id"
            >
              {{ mix.name }}
            </option>
          </select>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-24">
          <div class="row mb-3">
            <div
              v-if="
                $route.path.search(/existing/) !== -1 &&
                footbathsCount > 1
              "
              class="col-md-12 d-lg-none"
            >
              <label class="mb-1">{{
                "Select a Footbath" | translate
              }}</label>
              <select
                @change="changeFootbath()"
                class="form-control"
                v-model="selectedFootbathId"
              >
                <option disabled :value="null">
                  {{ "Select" | translate }}
                </option>
                <option
                  v-for="(footbath, index) in assessor.footbaths"
                  :key="index"
                  :value="footbath.id"
                >
                  {{ footbath.name }}
                </option>
              </select>
            </div>
            <div v-if="mixesCount > 1" class="col-md-12 d-lg-none">
              <label class="mb-1">{{
                "Select a Mix" | translate
              }}</label>
              <select
                v-if="footbathClass.footbath.mixes"
                class="form-control"
                v-model="selectedMixId"
              >
                <option disabled :value="null">
                  {{ "Select" | translate }}
                </option>
                <option
                  v-for="(mix, index) in formattedMixes"
                  :key="index"
                  :value="mix.id"
                >
                  {{ mix.name }}
                </option>
              </select>
            </div>

            <div class="col-lg-8 col-md-12">
              <label class="mb-1">{{ "Mix Name" | translate }}</label>
              <input
                type="text"
                class="form-control mb-0 mb-md-3"
                placeholder="ex. Mix 1"
                name="name"
                v-model="mix.name"
                data-vv-as="Mix Name"
                v-validate="'required'"
              />
              <span v-show="errors.has('name')" class="text-danger">
                <small>{{
                  "The Mix Name field is required." | translate
                }}</small>
              </span>
            </div>
            <div class="col-lg-8 col-md-12 product_selector_desktop">
              <label class="mb-1">{{ "Products" | translate }}</label>
              <select
                class="form-control"
                v-model="product"
                @change="addToMixProducts"
              >
                <option value="add_product" disabled selected>
                  + {{ "Add Product to Mix" | translate }}
                </option>
                <option
                  :disabled="mix.productsToDisplay['copper_sulfate']"
                  value="copper_sulfate"
                >
                  {{ "Copper Sulfate (Solid)" | translate }}
                </option>
                <option
                  :disabled="mix.productsToDisplay['formalin']"
                  value="formalin"
                >
                  {{ "Formalin (Liquid)" | translate }}
                </option>
                <option
                  :disabled="mix.productsToDisplay['zinc_sulfate']"
                  value="zinc_sulfate"
                >
                  {{ "Zinc Sulfate (Solid)" | translate }}
                </option>

                <!-- :disabled="mix.productsToDisplay['other_liquid']" -->
                <option value="other_liquid">
                  {{ "Other (Liquid)" | translate }}
                </option>
                <!-- :disabled="mix.productsToDisplay['other_solid']" -->
                <option value="other_solid">
                  {{ "Other (Solid)" | translate }}
                </option>
              </select>
            </div>
            <measurement-selection-radio
              v-model="evaluation.data.mixMeasurements"
              classes="mt-1 ml-2"
            />
          </div>

          <div class="row">
            <div
              class="col-lg-8 col-md-12"
              v-for="(item, index) in mixClass.getProducts()"
              :key="index + timestamp"
            >
              <div
                :class="[
                  'card',
                  { 'mt-3': index !== 0 },
                  { 'mt-md-0': index !== 0 },
                  {
                    'mb-3':
                      index === mixClass.getProducts().length - 1,
                  },
                  'mb-md-3',
                ]"
              >
                <product
                  @removed="removeProduct"
                  :index="index"
                  :product="item"
                  :measurements="evaluation.data.mixMeasurements"
                ></product>
              </div>
            </div>
          </div>

          <div class="row product_selector_mobile">
            <div class="col-md-24">
              <label>{{ "Products" | translate }}</label>
              <select
                class="form-control mb-0"
                v-model="product"
                @change="addToMixProducts"
              >
                <option
                  :disabled="mix.productsToDisplay['copper_sulfate']"
                  value="copper_sulfate"
                >
                  {{ "Copper Sulfate (Solid)" | translate }}
                </option>
                <option
                  :disabled="mix.productsToDisplay['formalin']"
                  value="formalin"
                >
                  {{ "Formalin (Liquid)" | translate }}
                </option>
                <option
                  :disabled="mix.productsToDisplay['zinc_sulfate']"
                  value="zinc_sulfate"
                >
                  {{ "Zinc Sulfate (Solid)" | translate }}
                </option>
                <!-- :disabled="mix.productsToDisplay['other_solid']" -->
                <option value="other_solid">
                  {{ "Other (Solid)" | translate }}
                </option>
                <!-- :disabled="mix.productsToDisplay['other_liquid']" -->
                <option value="other_liquid">
                  {{ "Other (Liquid)" | translate }}
                </option>
              </select>
            </div>
          </div>

          <div
            class="row mix-per-week"
            v-if="this.mix.productsToDisplay"
          >
            <div class="col-md-24 px-1">
              <label>{{
                "How many times is the mix used per day on a weekly basis?"
                  | translate
              }}</label>
            </div>

            <div class="col-lg-3 mt-3 text-center">
              <label>{{ "Sunday" | translate }}</label>
              <div class="day_selector_mobile d-lg-flex">
                <button
                  @click="subtract('score_sunday')"
                  class="btn day_selector_mobile__minus-button d-lg-none"
                >
                  <i class="fa fa-minus"></i>
                </button>
                <div class="day_selector_mobile__input">
                  <input
                    style="pointer-events: none"
                    type="text"
                    min="0"
                    max="9"
                    onkeypress="return event.charCode >= 48"
                    class="form-control mb-0 mr-lg-2"
                    v-model.number="mix.score_sunday"
                  />
                </div>
                <button
                  @click="add('score_sunday')"
                  class="btn day_selector_mobile__plus-button order-3 d-lg-none"
                >
                  <i class="fa fa-plus"></i>
                </button>
                <!-- show mobile -->
                <!-- @TODO Use only flexbox and remove hide on mobile -->
                <div class="d-none d-lg-flex flex-column order-2">
                  <button
                    @click="add('score_sunday')"
                    class="btn day_selector_mobile__plus-button"
                  >
                    <i class="fa fa-plus font-sm"></i>
                  </button>
                  <button
                    @click="subtract('score_sunday')"
                    class="btn day_selector_mobile__minus-button"
                  >
                    <i class="fa fa-minus font-sm"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 mt-3 text-center">
              <label>{{ "Monday" | translate }}</label>
              <div class="day_selector_mobile d-lg-flex">
                <button
                  @click="subtract('score_monday')"
                  class="btn day_selector_mobile__minus-button d-lg-none"
                >
                  <i class="fa fa-minus"></i>
                </button>
                <div class="day_selector_mobile__input">
                  <input
                    style="pointer-events: none"
                    type="text"
                    min="0"
                    max="9"
                    onkeypress="return event.charCode >= 48"
                    class="form-control mb-0 mr-lg-2"
                    v-model.number="mix.score_monday"
                  />
                </div>
                <button
                  @click="add('score_monday')"
                  class="btn day_selector_mobile__plus-button order-3 d-lg-none"
                >
                  <i class="fa fa-plus"></i>
                </button>
                <!-- show mobile -->
                <!-- @TODO Use only flexbox and remove hide on mobile -->
                <div class="d-none d-lg-flex flex-column order-2">
                  <button
                    @click="add('score_monday')"
                    class="btn day_selector_mobile__plus-button"
                  >
                    <i class="fa fa-plus font-sm"></i>
                  </button>
                  <button
                    @click="subtract('score_monday')"
                    class="btn day_selector_mobile__minus-button"
                  >
                    <i class="fa fa-minus font-sm"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-lg-3 mt-3 text-center">
              <label>{{ "Tuesday" | translate }}</label>
              <div class="day_selector_mobile d-lg-flex">
                <button
                  @click="subtract('score_tuesday')"
                  class="btn day_selector_mobile__minus-button d-lg-none"
                >
                  <i class="fa fa-minus"></i>
                </button>
                <div class="day_selector_mobile__input">
                  <input
                    style="pointer-events: none"
                    type="text"
                    min="0"
                    max="9"
                    onkeypress="return event.charCode >= 48"
                    class="form-control mb-0 mr-lg-2"
                    v-model.number="mix.score_tuesday"
                  />
                </div>
                <button
                  @click="add('score_tuesday')"
                  class="btn day_selector_mobile__plus-button order-3 d-lg-none"
                >
                  <i class="fa fa-plus"></i>
                </button>
                <!-- show mobile -->
                <!-- @TODO Use only flexbox and remove hide on mobile -->
                <div class="d-none d-lg-flex flex-column order-2">
                  <button
                    @click="add('score_tuesday')"
                    class="btn day_selector_mobile__plus-button"
                  >
                    <i class="fa fa-plus font-sm"></i>
                  </button>
                  <button
                    @click="subtract('score_tuesday')"
                    class="btn day_selector_mobile__minus-button"
                  >
                    <i class="fa fa-minus font-sm"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 mt-3 text-center">
              <label>{{ "Wednesday" | translate }}</label>
              <div class="day_selector_mobile d-lg-flex">
                <button
                  @click="subtract('score_wednesday')"
                  class="btn day_selector_mobile__minus-button d-lg-none"
                >
                  <i class="fa fa-minus"></i>
                </button>
                <div class="day_selector_mobile__input">
                  <input
                    style="pointer-events: none"
                    type="text"
                    min="0"
                    max="9"
                    onkeypress="return event.charCode >= 48"
                    class="form-control mb-0 mr-lg-2"
                    v-model.number="mix.score_wednesday"
                  />
                </div>
                <button
                  @click="add('score_wednesday')"
                  class="btn day_selector_mobile__plus-button order-3 d-lg-none"
                >
                  <i class="fa fa-plus"></i>
                </button>
                <!-- show mobile -->
                <!-- @TODO Use only flexbox and remove hide on mobile -->
                <div class="d-none d-lg-flex flex-column order-2">
                  <button
                    @click="add('score_wednesday')"
                    class="btn day_selector_mobile__plus-button"
                  >
                    <i class="fa fa-plus font-sm"></i>
                  </button>
                  <button
                    @click="subtract('score_wednesday')"
                    class="btn day_selector_mobile__minus-button"
                  >
                    <i class="fa fa-minus font-sm"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 mt-3 text-center">
              <label>{{ "Thursday" | translate }}</label>
              <div class="day_selector_mobile d-lg-flex">
                <button
                  @click="subtract('score_thursday')"
                  class="btn day_selector_mobile__minus-button d-lg-none"
                >
                  <i class="fa fa-minus"></i>
                </button>
                <div class="day_selector_mobile__input">
                  <input
                    style="pointer-events: none"
                    type="text"
                    min="0"
                    max="9"
                    onkeypress="return event.charCode >= 48"
                    class="form-control mb-0 mr-lg-2"
                    v-model.number="mix.score_thursday"
                  />
                </div>
                <button
                  @click="add('score_thursday')"
                  class="btn day_selector_mobile__plus-button order-3 d-lg-none"
                >
                  <i class="fa fa-plus"></i>
                </button>
                <!-- show mobile -->
                <!-- @TODO Use only flexbox and remove hide on mobile -->
                <div class="d-none d-lg-flex flex-column order-2">
                  <button
                    @click="add('score_thursday')"
                    class="btn day_selector_mobile__plus-button"
                  >
                    <i class="fa fa-plus font-sm"></i>
                  </button>
                  <button
                    @click="subtract('score_thursday')"
                    class="btn day_selector_mobile__minus-button"
                  >
                    <i class="fa fa-minus font-sm"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 mt-3 text-center">
              <label>{{ "Friday" | translate }}</label>
              <div class="day_selector_mobile d-lg-flex">
                <button
                  @click="subtract('score_friday')"
                  class="btn day_selector_mobile__minus-button d-lg-none"
                >
                  <i class="fa fa-minus"></i>
                </button>
                <div class="day_selector_mobile__input">
                  <input
                    style="pointer-events: none"
                    type="text"
                    min="0"
                    max="9"
                    onkeypress="return event.charCode >= 48"
                    class="form-control mb-0 mr-lg-2"
                    v-model.number="mix.score_friday"
                  />
                </div>
                <button
                  @click="add('score_friday')"
                  class="btn day_selector_mobile__plus-button order-3 d-lg-none"
                >
                  <i class="fa fa-plus"></i>
                </button>
                <!-- show mobile -->
                <!-- @TODO Use only flexbox and remove hide on mobile -->
                <div class="d-none d-lg-flex flex-column order-2">
                  <button
                    @click="add('score_friday')"
                    class="btn day_selector_mobile__plus-button"
                  >
                    <i class="fa fa-plus font-sm"></i>
                  </button>
                  <button
                    @click="subtract('score_friday')"
                    class="btn day_selector_mobile__minus-button"
                  >
                    <i class="fa fa-minus font-sm"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 mt-3 text-center">
              <label>{{ "Saturday" | translate }}</label>
              <div class="day_selector_mobile d-lg-flex">
                <button
                  @click="subtract('score_saturday')"
                  class="btn day_selector_mobile__minus-button d-lg-none"
                >
                  <i class="fa fa-minus"></i>
                </button>
                <div class="day_selector_mobile__input">
                  <input
                    style="pointer-events: none"
                    type="text"
                    min="0"
                    max="9"
                    onkeypress="return event.charCode >= 48"
                    class="form-control mb-0 mr-lg-2"
                    v-model.number="mix.score_saturday"
                  />
                </div>
                <button
                  @click="add('score_saturday')"
                  class="btn day_selector_mobile__plus-button order-3 d-lg-none"
                >
                  <i class="fa fa-plus"></i>
                </button>
                <!-- show mobile -->
                <!-- @TODO Use only flexbox and remove hide on mobile -->
                <div class="d-none d-lg-flex flex-column order-2">
                  <button
                    @click="add('score_saturday')"
                    class="btn day_selector_mobile__plus-button"
                  >
                    <i class="fa fa-plus font-sm"></i>
                  </button>
                  <button
                    @click="subtract('score_saturday')"
                    class="btn day_selector_mobile__minus-button"
                  >
                    <i class="fa fa-minus font-sm"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-lg-3 mt-3 text-center">
              <label>{{ "Total" | translate }}</label>
              <div class="row">
                <div
                  class="day_selector_mobile d-lg-flex justify-content-lg-center"
                >
                  <div class="day_selector_mobile__input-total total">
                    <input
                      readonly
                      type="text"
                      min="0"
                      onkeypress="return event.charCode >= 48"
                      class="form-control mb-0"
                      v-model.number="mix.score_total"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End row -->

          <hr />

          <question
            @change="mix.footbath_system = $event.value"
            :initial-value="mix.footbath_system"
          >
            <label class="label d-inline-block">
              {{
                "Is there a footbath mixing system in place?"
                  | translate
              }}
            </label>
          </question>

          <hr v-show="mix.footbath_system === 1" />

          <question
            v-show="mix.footbath_system === 1"
            @change="mix.mixed_properly = $event.value"
            :initial-value="mix.mixed_properly"
          >
            <label class="label d-inline-block">
              {{ "Is the bath mixed properly?" | translate }}
            </label>
          </question>

          <hr />

          <div class="row">
            <div
              class="col-md-24 mb-4 mt-0 mt-md-4 text-right misc-bottom product_selector_mobile"
            >
              <button @click="saveMix" class="btn">
                {{ "Save Mix" | translate }}
              </button>
              <button
                v-if="!newMix"
                data-toggle="modal"
                :data-target="'#modal'"
                @click="goToNewMix"
                class="btn mt-3"
              >
                {{ "New Mix" | translate }}
              </button>
            </div>
            <div class="col-md-12">
              <personal-notes
                v-model="assessor.data.personal_notes"
              ></personal-notes>
            </div>
            <div class="col-md-12">
              <presentation-notes
                v-model="assessor.data.presentation_notes"
              ></presentation-notes>
            </div>
          </div>

          <div class="row misc-bottom mt-0 mt-md-4 pb-2 pb-md-3">
            <div class="col-md-6 col-lg-12">
              <photoUpload
                :assessorId="1"
                :assessor="assessor"
              ></photoUpload>
            </div>
            <div
              class="col-md-18 col-lg-12 text-right product_selector_desktop"
            >
              <button
                v-if="!newMix"
                @click="goToNewMix"
                class="btn btn-primary btn-full--sm pull-right"
              >
                {{ "New Mix" | translate }}
              </button>
              <button
                @click="saveMix"
                :class="[
                  'btn btn-primary btn-full--sm pull-right',
                  { 'mr-3': !newMix },
                ]"
              >
                {{ "Save Mix" | translate }}
              </button>
            </div>
          </div>

          <hr
            :class="[
              'mt-4',
              {
                'mb-md-5':
                  successMessage === false && errorMessage === false,
              },
              {
                'my-md-4':
                  successMessage === true || errorMessage === true,
              },
            ]"
          />

          <div
            v-if="successMessage"
            class="alert alert-primary mb-4"
            role="alert"
          >
            {{ "Mix saved!" | translate }}
          </div>

          <div
            v-if="errorMessage"
            class="alert alert-danger mb-4"
            role="alert"
          >
            {{
              "Please fill in all of the required fields!" | translate
            }}
          </div>

          <modal-confirm
            v-if="showModal"
            @close="showModal = false"
            :link="url"
            :changeFootbathCall="willFootbathChange"
            :revertFunction="revertFootbath"
            :onClick="propFunction"
          ></modal-confirm>

          <div class="row misc-bottom">
            <div class="col-md-16">
              <!-- {{ $route.path.search(/new/) }} -->
              <button
                @click="
                  checkIfDataHasChanged(
                    '/users/' +
                      $route.params.userId +
                      '/evaluations/' +
                      $route.params.evaluationId +
                      '/groups/' +
                      group.id +
                      '/assessors/' +
                      assessor.type +
                      '/footbaths/' +
                      $route.params.footbathType +
                      ($route.params.footbathType === 'existing'
                        ? '/?footbathId=' + $route.params.footbathId
                        : '')
                  )
                "
                class="btn btn-secondary mr-4 btn-full--sm"
              >
                {{ "Back" | translate }}
              </button>
              <button
                v-if="$route.path.search(/new/) === -1"
                @click="
                  checkIfDataHasChanged(
                    '/users/' +
                      $route.params.userId +
                      '/evaluations/' +
                      evaluation._id +
                      '/groups/' +
                      group.id +
                      '/assessors/' +
                      assessor.type +
                      '/footbaths/new/'
                  )
                "
                class="btn btn-primary btn-full--sm mt-2 mt-md-0"
              >
                {{ "Design New Footbath" | translate }}
              </button>
              <button
                v-else
                @click="
                  checkIfDataHasChanged(
                    '/users/' +
                      $route.params.userId +
                      '/evaluations/' +
                      evaluation._id +
                      '/groups/' +
                      group.id +
                      '/assessors/' +
                      assessor.type +
                      '/footbaths/existing/'
                  )
                "
                class="btn btn-primary btn-full--sm mt-2 mt-md-0"
              >
                {{ "Evaluate Existing Footbath" | translate }}
              </button>
            </div>
            <div class="col-md-8 text-right">
              <button
                v-if="user && user.role !== 'data_entry'"
                @click="checkIfDataHasChanged('/reports')"
                class="btn btn-primary btn-full--sm mt-2 mt-md-0"
              >
                {{ "Reports" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import subtract from "lodash/subtract";
import Question from "../assessor_components/question";
import PresentationNotes from "../assessor_components/presentation-notes";
import PersonalNotes from "../assessor_components/personal-notes";
import BottomNav from "../assessor_components/bottom-nav";
import ModalInfo from "@/components/misc/modal-info.vue";
import ModalConfirm from "@/components/misc/modal-confirm.vue";
import HygieneHeader from "../assessor_components/hygiene-header.vue";
import PhotoUpload from "@/components/misc/photoUpload";
import Product from "../assessor_components/product.vue";
import _ from "lodash";
var $ = (window.jQuery = require("jquery"));
import Evaluation from "@/libs/Classes/Evaluation.js";
import Group from "@/libs/Classes/Group.js";
import Mix from "@/libs/Classes/Mix.js";
import { mapState, mapActions } from "vuex";
import {
  GET_UPDATE_ASSESSOR,
  GET_UPDATE_EVALUATION,
} from "../../../store/types";
import MeasurementSelectionRadio from "@/components/common/MeasurementSelectionRadio.vue";

export default {
  components: {
    BottomNav,
    HygieneHeader,
    ModalInfo,
    ModalConfirm,
    PresentationNotes,
    PersonalNotes,
    PhotoUpload,
    Question,
    Product,
    MeasurementSelectionRadio,
  },
  props: ["assessor", "footbathMixIds"],
  data() {
    return {
      currentMixInState: null,
      day: {},
      default_mix_copy: null,
      errorMessage: false,
      // evaluation: null,
      evaluationClass: null,
      footbath: null,
      footbathChanging: false,
      footbathClass: null,
      group: null,
      groupClass: null,
      hygieneClass: null,
      mix: null,
      mixClass: null,
      oldMixId: null,
      newMix: false,
      propFunction: null,
      showModal: false,
      timeoutSave: false,
      selectedFootbathId: null,
      selectedMixId: null,
      successMessage: false,
      units: null,
      url: null,
      willFootbathChange: false,
      default_mix: {
        id: 0,
        footbath_system: null,
        mixed_weekly_personal_notes: "",
        mixed_personal_notes: "",
        mixed_presentation_notes: "",
        mixed_properly: null,
        measurements: null,
        name: null,
        productsToDisplay: {}, // Gets sent to the object, two-way binding is already in place.
        score_sunday: 0,
        score_monday: 0,
        score_tuesday: 0,
        score_wednesday: 0,
        score_thursday: 0,
        score_friday: 0,
        score_saturday: 0,
        score_total: 0,
      },
      products: [
        {
          other_liquid: {
            title: "Other (Liquid)",
            classification: "Liquid",
            inclusion: 0,
            concentration: 0,
            price: 0,
            productType: "Other",
          },
          other_solid: {
            title: "Other (Solid)",
            classification: "Solid",
            inclusion: 0,
            concentration: 0,
            price: 0,
            productType: "Other",
          },
          copper_sulfate: {
            title: "Copper Sulfate",
            classification: "Solid",
            inclusion: 50,
            concentration: 5,
            price: 0,
          },
          zinc_sulfate: {
            title: "Zinc Sulfate",
            classification: "Solid",
            inclusion: 50,
            concentration: 5,
            price: 0,
          },
          formalin: {
            title: "Formalin",
            classification: "Liquid",
            inclusion: 50,
            concentration: 5,
            price: 0,
          },
        },
      ],
      product: "add_product",
      classificationType: "solid",
    };
  },
  watch: {
    $route(to, from) {
      this.initializeData();
      this.$forceUpdate();
    },
    selectedMixId(newId, previousId) {
      if (!this.footbathChanging) {
        this.changeMix(newId, previousId);
      } else {
        this.footbathChanging = false;
      }
    },
  },
  computed: {
    ...mapState({
      evaluation: (state) => state.evaluations.evaluation,
      user: (state) => state.users.user,
    }),
    timestamp() {
      return new Date().getTime() + Math.random() * 10;
    },
    mixesCount: function () {
      return this.footbath.mixes
        ? Object.keys(this.footbath.mixes).length
        : 0;
    },
    footbathsCount: function () {
      return Object.keys(this.assessor.footbaths).length;
    },
    formattedMixes: {
      cache: false,
      get: function () {
        let footbath;
        if (this.$route.params.footbathType === "existing") {
          if (Array.isArray(this.assessor.footbaths)) {
            footbath = this.assessor.footbaths.find((footbath) => {
              return footbath.id === this.$route.params.footbathId;
            });
          } else {
            for (let footbathId in this.assessor.footbaths) {
              if (footbathId === this.$route.params.footbathId) {
                footbath = this.assessor.footbaths[footbathId];
              }
            }
          }
        } else {
          footbath = this.assessor.new_footbath;
        }

        if (footbath.mixes) {
          let mixes = _.cloneDeep(footbath.mixes);
          if (this.newMix) {
            let currentMix = _.cloneDeep(this.mix);
            currentMix.name = this.$t("New Mix");
            mixes[currentMix.id] = currentMix;
          }

          return mixes;
        }

        return {};
      },
    },
    hasBoth: {
      cache: false,
      get: function () {
        return this.formattedMixes.length > 1 &&
          this.$route.path.search(/existing/) !== -1
          ? "col-md-12"
          : "col-md-18";
      },
    },
  },
  methods: {
    ...mapActions("assessors", [GET_UPDATE_ASSESSOR]),
    ...mapActions("evaluations", [GET_UPDATE_EVALUATION]),
    initializeData() {
      this.group = this.evaluation.groups[this.$route.params.groupId];
      // this.user = this.$store.getters.getData('user');
      this.evaluationClass = new Evaluation(this, this.evaluation);
      this.groupClass = new Group(
        this,
        this.evaluationClass,
        this.group
      );

      // we've this feature separated mix measurement later, so the evaluation before adding this feature doesn't contain the mixMeasurements, if doesn't contain the property so we attach this property
      if (!this.evaluation.data.mixMeasurements) {
        this.evaluation.data.mixMeasurements =
          this.evaluation.data.measurements;
      }

      if (Array.isArray(this.group.assessors)) {
        this.assessor = this.group.assessors.find((assessor) => {
          return assessor.type === this.$route.params.assessorType;
        });
      } else {
        for (let assessorId in this.group.assessors) {
          if (
            this.group.assessors[assessorId].type ===
            this.$route.params.assessorType
          ) {
            this.assessor = this.group.assessors[assessorId];
          }
        }
      }

      this.hygieneClass = this.groupClass.getAssessorByType(
        this.$route.params.assessorType
      );

      if (this.$route.params.footbathType === "existing") {
        if (Array.isArray(this.assessor.footbaths)) {
          this.footbath = this.assessor.footbaths.find(
            (footbath) =>
              footbath.id === this.$route.params.footbathId
          );
        } else {
          this.footbath =
            this.assessor.footbaths[this.$route.params.footbathId];
        }

        let footbaths = this.hygieneClass.getExistingFootbaths();
        this.footbathClass = footbaths[this.$route.params.footbathId];
      } else {
        this.footbathClass = this.hygieneClass.getNewFootbath();
        this.footbath = this.assessor.new_footbath;
      }

      // place 1
      if (this.footbath.mixes) {
        // we've mixes in two types one is in arrays and one is in objects  this checks if mixes are an array and handle the rest
        if (Array.isArray(this.footbathClass.footbath.mixes)) {
          if (this.footbathClass.footbath.mixes.length > 0) {
            this.footbathClass.footbath.mixes =
              this.footbathClass.footbath.mixes.map((mix) => ({
                measurements: this.evaluation.data.measurements,
                ...mix,
              }));
            this.mix = _.cloneDeep(
              this.footbathClass.footbath.mixes[0]
            );
            this.mixClass = new Mix(
              this,
              this.evaluationClass,
              this.groupClass,
              this.hygieneAssessor,
              this.footbathClass,
              this.mix
            );
            this.selectedMixId = this.mix.id;
          } else {
            this.createNewMix();
          }
        } else {
          //
          if (
            Object.keys(this.footbathClass.footbath.mixes).length > 0
          ) {
            for (let mixId in this.footbathClass.footbath.mixes) {
              this.mix = _.cloneDeep({
                measurements: this.evaluation.data.measurements,
                ...this.footbathClass.footbath.mixes[mixId],
              });
              this.mixClass = new Mix(
                this,
                this.evaluationClass,
                this.groupClass,
                this.hygieneAssessor,
                this.footbathClass,
                this.mix
              );
              this.selectedMixId = this.mix.id;
              break;
            }
          } else {
            this.createNewMix();
          }
        }
      } else {
        this.createNewMix();
      }
    },
    revertFootbath() {
      this.footbathChanging = true;
      this.selectedMixId = this.mix.id;
      this.selectedFootbathId = this.footbath.id;
      this.willFootbathChange = false;
      this.url = null;
    },
    checkIfDataHasChanged(url) {
      if (
        this.footbath.mixes !== undefined &&
        this.footbath.mixes.length !== 0
      ) {
        if (
          (this.getCurrentMixInState() !== undefined &&
            !_.isEqual(this.mix, this.getCurrentMixInState())) ||
          (this.getCurrentMixInState() === undefined &&
            !this.checkIfDefaultMix())
        ) {
          this.willFootbathChange = true;
          this.url = url;
          this.showModal = true;
        } else {
          this.$router.push(url);
        }
      } else {
        let clonedDefaultMix = _.cloneDeep(this.default_mix);
        clonedDefaultMix.id = this.mix.id;
        clonedDefaultMix.date_created = this.mix.date_created;

        if (!_.isEqual(this.mix, clonedDefaultMix)) {
          this.url = url;
          this.showModal = true;
        } else {
          this.$router.push(url);
        }
      }
    },
    checkIfDefaultMix() {
      let clonedDefaultMix = _.cloneDeep(this.default_mix);
      clonedDefaultMix.id = this.mix.id;
      return _.isEqual(this.mix, clonedDefaultMix);
    },
    async removeProduct(name) {
      for (const mixName in this.mix.productsToDisplay) {
        if (this.mix.productsToDisplay[mixName].title === name) {
          this.$delete(this.mix.productsToDisplay, mixName);

          // Push updates to DB
          let endpointUrl =
            "users/" +
            this.$route.params.userId +
            "/evaluations/" +
            this.$route.params.evaluationId +
            "/groups/" +
            this.$route.params.groupId +
            "/assessors/" +
            this.assessor.id +
            "/footbaths/" +
            this.$route.params.footbathId +
            "/mixes/" +
            this.mix.id;

          let cachedMix = {}; // = JSON.parse(JSON.stringify(this.mix))
          cachedMix.productsToDisplay = null;

          this[GET_UPDATE_ASSESSOR]({
            evaluationId: this.evaluation._id,
            groupId: this.group.id,
            assessor: this.assessor,
          });
        }
      }
    },
    createNewMix() {
      this.newMix = true;
      this.mix = _.cloneDeep(this.default_mix);
      this.mix.id = this.$generateId();
      this.mix.date_created = new Date().getTime();
      // this.mix.measurements = this.evaluation.data.measurements
      this.mixClass = new Mix(
        this,
        this.evaluationClass,
        this.groupClass,
        this.hygieneAssessor,
        this.footbathClass,
        this.mix
      );
      // we've this feature separated mix measurement later, so the evaluation before adding this feature doesn't contain the mixMeasurements, if doesn't contain the property so we attach this property
      if (!this.evaluation.data.mixMeasurements) {
        this.evaluation.data.mixMeasurements =
          this.evaluation.data.measurements;
      }
      this.default_mix_copy = _.cloneDeep(this.mix);
      this.selectedMixId = this.mix.id;
      this.showModal = false;
      this.$validator.reset();
      this.$scrollTop();
    },
    propChangeMix(mix) {
      if (!mix) {
        if (Array.isArray(this.footbath.mixes)) {
          mix = this.footbath.mixes.find(
            (mix) => mix.id === this.selectedMixId
          );
        } else {
          mix = this.footbath.mixes[this.selectedMixId];
        }
        if (!mix) return;
      }

      this.mix = _.cloneDeep(mix);
      this.mixClass = new Mix(
        this,
        this.evaluationClass,
        this.groupClass,
        this.hygieneAssessor,
        this.footbathClass,
        this.mix
      );
      this.propFunction = this.changeFootbath;
      this.newMix = false;
      this.showModal = false;
      this.$scrollTop();
      this.$validator.reset();
    },
    mixTotal() {
      this.mix.score_total =
        Number(this.mix.score_monday) +
        Number(this.mix.score_tuesday) +
        Number(this.mix.score_wednesday) +
        Number(this.mix.score_thursday) +
        Number(this.mix.score_friday) +
        Number(this.mix.score_saturday) +
        Number(this.mix.score_sunday);
    },
    getCurrentMix(id) {
      if (Array.isArray(this.footbath.mixes)) {
        return this.footbath.mixes.find((mix) => mix.id === id);
      } else {
        return this.footbath.mixes[id];
      }
    },
    hasMixChangedWithoutSave(mixId) {
      if (
        this.default_mix_copy !== null &&
        _.isEqual(this.default_mix_copy, this.mix)
      ) {
        return false;
      }

      if (!this.footbath.mixes) {
        return false;
      }

      let mix;
      if (Array.isArray(this.footbath.mixes)) {
        mix = this.footbath.mixes.find((mix) => mix.id === mixId);
      } else {
        mix = this.footbath.mixes[mixId];
      }

      if (_.isEqual(mix, this.mix)) {
        return false;
      }

      return true;
    },
    changeMix(newMixId, oldMixId) {
      if (oldMixId === null || oldMixId === undefined) return;

      if (this.hasMixChangedWithoutSave(oldMixId)) {
        this.willFootbathChange = true;
        this.propFunction = this.propChangeMix;
        this.showModal = true;
      } else {
        let newMix;
        if (Array.isArray(this.footbath.mixes)) {
          newMix = this.footbath.mixes.find(
            (mix) => mix.id === newMixId
          );
        } else {
          newMix = this.footbath.mixes[newMixId];
        }
        this.propChangeMix(!newMix ? newMix : false);
      }
    },
    getCurrentMixInState() {
      if (Array.isArray(this.footbath.mixes)) {
        return this.footbath.mixes.find(
          (mix) => mix.id === this.selectedMixId
        );
      } else {
        return this.footbath.mixes[this.selectedMixId];
      }
    },
    changeFootbath() {
      this.newMix = false;
      let footbathLink =
        "/users/" +
        this.$route.params.userId +
        "/evaluations/" +
        this.$route.params.evaluationId +
        "/groups/" +
        this.$route.params.groupId +
        "/assessors/" +
        this.assessor.type +
        "/footbaths/existing/" +
        this.selectedFootbathId +
        "/mixes/";
      this.footbathChanging = true;

      if (this.hasMixChangedWithoutSave(this.mix.id)) {
        this.willFootbathChange = true;
        this.url = footbathLink;
        this.showModal = true;
      } else {
        this.$router.push(footbathLink);
      }
    },
    containsObject: function (obj, list) {
      let i;
      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true;
        }
      }
      return false;
    },
    addToMixProducts: function () {
      // if (
      //   !this.containsObject(
      //     this.products[0][this.product],
      //     this.mix.productsToDisplay
      //   )
      // ) {

      // for other type
      if (this.product.startsWith("other")) {
        const uniqueProductKey = `${
          this.product
        }_${this.getUniqueId()}`;
        this.mix.productsToDisplay[uniqueProductKey] = Object.assign(
          {},
          this.products[0][this.product],
        );
      } else {
        this.mix.productsToDisplay[this.product] =
          this.products[0][this.product];
      }
      // }
      this.product = "add_product";
    },
    add: function (day) {
      if (this.mix[day] < 9) {
        this.mix[day]++;
      }
      this.mixTotal();
    },
    subtract: function (day) {
      if (this.mix[day] > 0) {
        this.mix[day]--;
      }
      this.mixTotal();
    },
    goToNewMix() {
      if (this.footbath.mixes !== undefined) {
        if (
          this.getCurrentMixInState() !== undefined &&
          !_.isEqual(this.mix, this.getCurrentMixInState())
        ) {
          this.showModal = true;
          this.propFunction = this.createNewMix;
          return false;
        }
      }
      this.createNewMix();
    },
    cancelGoToNewMix() {
      this.$validator.validateAll();
    },

    getUniqueId() {
      const time = `${new Date().getTime()}`;
      return time.substring(10, 13);
    },

    saveMix() {
      this.$validator.validateAll().then(async (result) => {
        if (result === true) {
          if (this.footbath_system === 0) {
            this.mixed_properly = null;
          }

          if (this.$route.params.footbathType === "existing") {
            if (this.footbath.mixes) {
              if (Array.isArray(this.footbath.mixes)) {
                let mixIndex = this.footbath.mixes.findIndex(
                  (mix) => {
                    return mix.id === this.mix.id;
                  }
                );

                if (mixIndex === -1) {
                  this.footbath.mixes.push(_.cloneDeep(this.mix));
                } else {
                  this.footbath.mixes[mixIndex] = _.cloneDeep(
                    this.mix
                  );
                }
              } else {
                this.footbath.mixes[this.mix.id] = _.cloneDeep(
                  this.mix
                );
              }
            } else {
              this.footbath.mixes = {
                [this.mix.id]: _.cloneDeep(this.mix),
              };
            }

            this[GET_UPDATE_ASSESSOR]({
              evaluationId: this.evaluation._id,
              groupId: this.group.id,
              assessor: this.assessor,
            });
          } else {
            if (this.assessor.new_footbath.mixes) {
              if (Array.isArray(this.footbath.mixes)) {
                let mixIndex =
                  this.assessor.new_footbath.mixes.findIndex(
                    (mix) => {
                      return mix.id === this.mix.id;
                    }
                  );

                if (mixIndex === -1) {
                  this.assessor.new_footbath.mixes.push(
                    _.cloneDeep(this.mix)
                  );
                } else {
                  this.assessor.new_footbath.mixes[mixIndex] =
                    _.cloneDeep(this.mix);
                }
              } else {
                this.assessor.new_footbath.mixes[this.mix.id] =
                  _.cloneDeep(this.mix);
              }
            } else {
              this.assessor.new_footbath.mixes = [
                _.cloneDeep(this.mix),
              ];
            }
            this[GET_UPDATE_ASSESSOR]({
              evaluationId: this.evaluation._id,
              groupId: this.group.id,
              assessor: this.assessor,
            });
          }

          // get update the evaluation, because we need to update the mixMeasurements property in mix.
          await this[GET_UPDATE_EVALUATION](this.evaluation);

          this.successMessage = true;
          this.newMix = false;

          setTimeout(() => {
            this.successMessage = false;
          }, 5000);
        } else {
          this.errorMessage = true;
          setTimeout(() => {
            this.errorMessage = false;
          }, 5000);
        }
      });
    },
  },
  created() {
    this.initializeData();
    if (this.mix.productsToDisplay === null) {
      this.mix.productsToDisplay = {};
    }
  },
  mounted() {
    this.mixTotal();
    this.selectedFootbathId = this.footbathClass.getId();
  },
};
</script>
