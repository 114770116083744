export const create = (self, slide, obj, options) => {
    var presets = {
        chartColors: ['0000FF', 'A1A3A6', '98C793', 'EC5F67', 'F9C862', '669ACC', '000000'],
        showValue: false,
        showPercent: true,
        showLegend: true,

        legendPos: 'b',
                dataLabelColor: 'ffffff',

        legendFontSize: 9
    };

    var mergedObject = {
        ...presets, // jshint ignore:line
        ...options // jshint ignore:line
    };

    slide.addChart(self.pptx.charts.PIE, obj, mergedObject);
}
