<template>
  <div
    v-show="isLoaded"
    class="container view main-container--margin-bt col-md-14 col-lg-10 col-24 view-form"
  >
    <div class="main-container" :style="{ width: '100%' }">
        <h1 class="h1 text-center mb-3">{{ 'Change Password' | translate }}</h1>

        <div v-if="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage | translate }}
        </div>
        <div class="card main-card" v-if="codeValid">
          <div class="card-body p-0 p-md-3">
            <form autocomplete="off" v-on:submit.prevent>
              <div class="row">
                <div class="col-24">
                  <div class="form-group">
                    <label class="login__label">{{ 'Password' | translate }}</label>
                    <span class="required">*</span>
                    <vue-password
                      v-model="password.new_password"
                      classes="form-control"
                      name="password"
                      v-validate="'required'"
                      ref="password"
                      data-vv-as="Password"
                    ></vue-password>
                    <span v-show="errors.has('password')" class="text-danger">
                      <small>{{ 'The Password field is required.' | translate }}</small>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-24 mb-3">
                  <div class="form-group">
                    <label class="login__label">{{
                      'Confirm Password' | translate
                    }}</label>
                    <span class="required">*</span>
                    <input
                      type="password"
                      class="form-control"
                      v-model="password.new_password2"
                      v-validate="'confirmed:password|required'"
                      name="password2"
                      data-vv-as="Password"
                    />
                  </div>
                  <span v-show="errors.has('password2')" class="text-danger">
                    <small>{{
                      'The Confirm Password field is required.' | translate
                    }}</small>
                  </span>
                </div>
              </div>
              <div class="row mx-0 d-flex justify-content-end mg-md">
                <button
                  type="submit"
                  @click="submit"
                  class="btn btn-primary btn-full--sm"
                >
                  {{ 'Submit' | translate }}
                </button>
              </div>
            </form>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader';
import AppFooter from '../components/AppFooter';
import VuePassword from 'vue-password';
import { GET_CHANGE_PASSWORD } from '../store/types';
import { mapActions } from 'vuex';

export default {
  components: {
    AppHeader,
    AppFooter,
    VuePassword,
  },
  data() {
    return {
      confirmMessage: false,
      errorMessage: null,
      codeValid: false,
      password: {
        new_password: null,
        new_password2: null,
        isActive: false,
      },
      isLoaded: true,
    };
  },
  methods: {
    ...mapActions('users', [GET_CHANGE_PASSWORD]),
    submit() {
      // this.$firebase
      //   .auth()
      //   .confirmPasswordReset(this.$route.query.oobCode, this.password.new_password)
      const { new_password, new_password2 } = this.password;
      console.log({token : this.$route.query.token});
      this[GET_CHANGE_PASSWORD]({
        password: new_password,
        confirmPassword: new_password2,
        token: this.$route.query.token
      })
        .then(() => {
          this.$router.push({
            path: '/login',
            query: { success_code: 'auth/reset-password' },
          });
        })
        .catch(error => {
          console.log('error', error);
          this.errorMessage = this.generateErrorMsg(error);
          
          setTimeout(() => {
            this.errorMessage = false;
          }, 5000);
        });
    },
    generateErrorMsg({ errors, message = '' }) {
      return errors ? Object.values(errors).join(', ') : message;
    },
  },
  created() {
    const { token , lang} = this.$route.query;
    localStorage.setItem('lang', lang)
    this.$store.state.users.user.lang = lang
   if (token) {
      this.codeValid = true;
    }
  },
};
</script>
