<template>
  <div v-if="filteredGroups.length" class="dashboard-freestall dashboard__stats-block main-container">
    <div class="row no-gutters w-100 align-items-start mb-md-4">
      <div class="col-24 col-md-16">
        <h2 class="h2 d-inline-block">
          {{ 'Freestall' | translate }}
          ({{ 'Type -' | translate }}
          {{ translateType(groupType) }})
        </h2>
      </div>
      <div v-if="filteredGroups.length > 1" class="col-24 col-md-8 d-flex justify-content-md-end mb-3 my-md-0">
        <select
          v-model="groupId"
          class="form-control dashboard__select"
        >
          <option :value="null" disabled>{{ 'Select' | translate }}</option>
            <option
            v-for="group in filteredGroups"  :key="`freestall_${group.id}`"             
            :value="group.id"
            >
              {{ group.name }}
            </option>
        </select>
      </div>
    </div>
    <DashboardFreestallMeas
      :freestall="freestallInstance(evaluation.groups[groupId])"
      :units="$t($getEquivalentUnit(evaluation.data.measurements, 'cm'))"
    />
  </div>
</template>

<script>
import { animalMixin } from '@/mixins/animal.mixin.js';
import { translationsMixin } from '@/mixins/translations.mixin.js';

import DashboardFreestallMeas from './DashboardFreestallMeas';

export default {
  name: 'DashboardFreestall',
  mixins: [animalMixin, translationsMixin],
  props: {
    evaluation: Object,
  },
  components: {
    DashboardFreestallMeas,
  },
  data() {
    return {
      groupId: '',
    };
  },
  created() {
    if(this.filteredGroups.length) {
      let defaultGroupId = this.filteredGroups[0].id;
      this.groupId = defaultGroupId;
    }
  },
  computed: {
    groupType() {
      return this.getGroup(this.groupId).data.type_of_animal;
    },
    filteredGroups() {
      return Object.values(this.evaluation.groups).filter(group => {
        const freestall = this.freestallInstance(group)
        const freestallDefined = !_.isEmpty(freestall)
        if(freestallDefined) {
          const values = [
            freestall.getWidth(0),
            freestall.assessor.data.stall_length_double_row,
            freestall.getInsideLoopDividerDiameter(),
            freestall.getRearCurbHeight(0),
            freestall.getNeckrailToRearCurbDistance(0),
            freestall.getSingleRowStallLength(),
            freestall.getRearCurbWidth(0),
            freestall.getBrisketLocatorHeight(0),
            freestall.getCurbToBrisketLocatorDistance(),
            freestall.getLength(0),
            freestall.getLowerDividerHeight(0),
            freestall.getNeckrailHeight(0),
          ]
          return values.filter(value => value).length
        } else {
          return false
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../_dashboard.scss';
</style>
