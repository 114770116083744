<template>
    <div class="item mt-3">
        <slot></slot>
    </div>
</template>
<script>
export default {
  props: ["evaluation", "user"],
  data: function () {
    return {
      timeoutSave: null
    }
  },
  mounted() {
    // this.$watch('evaluation', (newEvaluation, oldEvaluation) => {
    //   // console.log('Ding! Evaluation updated')
    //   if (this.timeoutSave) {
    //     clearTimeout(this.timeoutSave);
    //     this.timeoutSave = null;
    //   }
    //   let self = this;
    //   this.timeoutSave = setTimeout(() => {

    //     self.$store.dispatch('setData', {
    //       currentVueInstance: self,
    //       endpointUrl: 'users/' + this.user.id + '/evaluations/' + newEvaluation.id,
    //       objectBeingUpdated: newEvaluation
    //     });

    //   }, 4000);
    // }, {
    //   deep: true
    // });
  }
}
</script>
