<template>
    <div style="display: inline">
        <div class="card-exit">
            <i data-toggle="modal" :data-target="['#mixModal-' + index]" class="fa fa-times"></i>
        </div>
        <div class="modal fade" tabindex="-1" :id="['mixModal-' + index]" role="dialog" :aria-labelledby="['mixModal-' + index]" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content edit-modal">
                    <h2 class="h2 text-center mt-md-0">{{"Are you sure?" | translate}}</h2>
                    <div class="modal-body my-0">
                        <slot name="modal-content"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showModal: false
            }
        },
        props: ['index']
    }
</script>
