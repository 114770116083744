import { freeStall } from './freeStall';
import { medias } from './../Global/medias.js';

class FreestallAssessorSlides {

    constructor(app, report, evaluation, group, user) {
        this.app = app;
        this.report = report;
        this.evaluation = evaluation;
        this.group = group;
        this.user = user;
    }

    add(tableOfContentsCalculatorPosition) {
        let assessor = this.group.getAssessorByType("2");

        if ( assessor === false || assessor.assessor.data === undefined ) {
            throw this.group.getName() + " - Freestall assessor is not ready.";

        }

        this.report.tableOfContentsHelper("Freestall", tableOfContentsCalculatorPosition, this.group);

        freeStall(this.app, this.report, this.evaluation, this.group, assessor);
        // freeStall2(this.app, this.report, this.evaluation, this.group);
        medias(this.app, this.report, this.group, "2", this.user);
    }

}

export default FreestallAssessorSlides;
