var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-ventilation dashboard__stats-block main-container mb-0"},[_c('div',{staticClass:"row no-gutters w-100 align-items-start mb-lg-4"},[_c('div',{staticClass:"col-24 col-md-24 col-lg-12"},[_c('h2',{staticClass:"h2 d-inline-block dashboard-ventilation__title"},[_vm._v(" "+_vm._s(_vm._f("translate")("Heat Stress & Ventilation"))+" ("+_vm._s(_vm._f("translate")("Type - "))+" "+_vm._s(_vm.translateType(_vm.groupType))+") ")])]),(_vm.filteredGroups.length > 1)?_c('div',{staticClass:"col-24 col-md-24 col-lg-12 mb-3 my-1 my-lg-0 text-lg-right"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.groupId),expression:"groupId"}],staticClass:"form-control dashboard__select d-inline",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.groupId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm._f("translate")("Select")))]),_vm._l((_vm.filteredGroups),function(group){return _c('option',{key:`freestall_${group.id}`,domProps:{"value":group.id}},[_vm._v(" "+_vm._s(group.name)+" ")])})],2)]):_vm._e()]),_c('div',{staticClass:"row d-flex justify-content-between"},[_c('div',{staticClass:"col-md-12 text-center mb-3"},[(
          !_vm.airSpeedAllCowsKey(_vm.groupId) ||
          ['no-vent', 'less-5'].includes(_vm.airSpeedAllCowsKey(_vm.groupId))
        )?_c('img',{staticClass:"dashboard-ventilation__img-all-cows mw-100",attrs:{"src":require("@/assets/img/dashboard/air-speed-red.svg")}}):_vm._e(),(_vm.airSpeedAllCowsKey(_vm.groupId) === '5-8')?_c('img',{staticClass:"dashboard-ventilation__img-all-cows mw-100",attrs:{"src":require("@/assets/img/dashboard/air-speed-yellow.svg")}}):_vm._e(),(_vm.airSpeedAllCowsKey(_vm.groupId) === 'more-5')?_c('img',{staticClass:"dashboard-ventilation__img-all-cows mw-100",attrs:{"src":require("@/assets/img/dashboard/air-speed-green.svg")}}):_vm._e(),_c('h6',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm._f("translate")("Avg Air Speed at Cow Level")))]),_c('small',[_vm._v(_vm._s(_vm._f("translate")(_vm.airSpeedAllCows(_vm.groupId) || "-")))])]),_c('div',{staticClass:"col-md-12 text-center mb-3"},[(
          !_vm.airSpeedCowKey(_vm.groupId) ||
          ['no-vent', 'less-300'].includes(_vm.airSpeedCowKey(_vm.groupId))
        )?_c('img',{staticClass:"dashboard-ventilation__img-cow mw-100",attrs:{"src":require("@/assets/img/dashboard/cow-red.svg")}}):_vm._e(),(_vm.airSpeedCowKey(_vm.groupId) === '300-1000')?_c('img',{staticClass:"dashboard-ventilation__img-cow mw-100",attrs:{"src":require("@/assets/img/dashboard/cow-yellow.svg")}}):_vm._e(),(_vm.airSpeedCowKey(_vm.groupId) === 'more-1000')?_c('img',{staticClass:"dashboard-ventilation__img-cow mw-100",attrs:{"src":require("@/assets/img/dashboard/cow-green.svg")}}):_vm._e(),_c('h6',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm._f("translate")("Avg Air Volume per Cow")))]),_c('small',[_vm._v(_vm._s(_vm._f("translate")(_vm.airSpeedCow(_vm.groupId) || "-")))])]),_c('div',{staticClass:"col-md-12 text-center mb-3"},[(
          !_vm.heatAbatementKey(_vm.groupId) ||
          _vm.heatAbatementKey(_vm.groupId) === 'no-fans'
        )?_c('img',{staticClass:"dashboard-ventilation__img-cooling mw-100",attrs:{"src":require("@/assets/img/dashboard/water-cooling-red.svg")}}):(_vm.heatAbatementKey(_vm.groupId) === 'fans')?_c('img',{staticClass:"dashboard-ventilation__img-cooling mw-100",attrs:{"src":require("@/assets/img/dashboard/water-cooling-yellow.svg")}}):(_vm.heatAbatementKey(_vm.groupId) === 'fans-water')?_c('img',{staticClass:"dashboard-ventilation__img-cooling mw-100",attrs:{"src":require("@/assets/img/dashboard/water-cooling-green.svg")}}):_vm._e(),_c('h6',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm._f("translate")("Water Cooling?")))]),_c('small',[_vm._v(_vm._s(_vm._f("translate")(_vm.heatAbatement(_vm.groupId) || "-")))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }