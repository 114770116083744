<template>
  <div>
    <div class="loading" v-show="!isLoaded">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <div
      class="container main-container main-container--accordion"
      v-show="isLoaded"
    >
      <div class="row">
        <div class="col-sm-16">
          <h1 class="h1 mb-3">{{ 'Companies' | translate }}</h1>
        </div>
        <div class="col-sm-8 d-flex justify-content-end align-items-center">
          <router-link
            to="/admin/new-company/"
            class="btn btn-primary btn-primary--thin btn-primary--thin--short btn-full--sm mb-2 mb-sm-0"
            >{{ 'Add a Company' | translate }}</router-link
          >
        </div>
      </div>

      <div v-if="info" class="alert alert-primary mb-4" role="alert">
        <span v-if="infoCode === 'companies/company-deleted'">{{
          'Company has been deleted!' | translate
        }}</span>
        <span v-if="infoCode === 'companies/company-created'">{{
          'New company has been created!' | translate
        }}</span>
      </div>

      <div class="row mx-0">
        <div class="table-responsive">
          <table class="table table-striped mb-0">
            <thead class="table-heading">
              <tr>
                <th
                  class="table-heading__text text-white text-center"
                  scope="col"
                >
                  {{ 'Name' | translate }}
                </th>
                <th
                  class="table-heading__text text-white text-center"
                  scope="col"
                >
                  {{ 'City' | translate }}
                </th>
                <th
                  class="table-heading__text text-white text-center"
                  scope="col"
                >
                  {{ 'State/Province' | translate }}
                </th>
                <th
                  class="table-heading__text text-white text-center"
                  scope="col"
                >
                  {{ 'Country' | translate }}
                </th>
                <th
                  class="table-heading__text text-white text-center"
                  scope="col"
                >
                  {{ 'Company Updated' | translate }}
                </th>
                <th
                  class="table-heading__text text-white text-center"
                  scope="col"
                >
                  {{ 'Actions' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(company, index) in companies" :key="index">
                <td class="table-item text-center align-middle">
                  {{ company.name }}
                </td>
                <td class="table-item text-center align-middle">
                  {{ company.city }}
                </td>
                <td class="table-item text-center align-middle">
                  <span v-if="company.state !== null">{{ company.state }}</span>
                  <span v-else>--</span>
                </td>
                <td class="table-item text-center align-middle">
                  {{ company.country }}
                </td>
                <td class="table-item text-center align-middle">
                  {{ $store.getters.getDate(company.updatedAt) }}
                </td>
                <td class="table-item text-center align-middle">
                  <router-link
                    :to="'/admin/company/' + company._id"
                    class="table-item__link"
                    >{{ 'Edit' | translate }}</router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- end table-responsive -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<script>
import AppHeader from '../../../components/AppHeader';
import AppFooter from '../../../components/AppFooter';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  data() {
    return {
      info: false,
      infoMessage: '',
      isLoaded: true,
    };
  },

  computed: {
    ...mapState({
      user: state => state.users.user,
      companies: state => state.company.companies,
    }),
  },

  methods: {
    // getDate(timestamp) {
    //   // let date = new Date(timestamp);
    //   // var monthNames = [
    //   //   this.$t('Jan.'),
    //   //   this.$t('Feb.'),
    //   //   this.$t('Mar.'),
    //   //   this.$t('Apr.'),
    //   //   this.$t('May'),
    //   //   this.$t('Jun.'),
    //   //   this.$t('Jul.'),
    //   //   this.$t('Aug.'),
    //   //   this.$t('Sep.'),
    //   //   this.$t('Oct.'),
    //   //   this.$t('Nov.'),
    //   //   this.$t('Dec.'),
    //   // ];
    //   // var day = date.getDate();
    //   // var monthIndex = date.getMonth();
    //   // var year = date.getFullYear();
    //   // return day + ' ' + monthNames[monthIndex] + ' ' + year;
    //   const dateFormatter = Intl.DateTimeFormat('sv-SE');
    //   return dateFormatter.format(new Date(timestamp));
    // },
  },
  async created() {
    try {
      // this.isLoaded = false;
      if (this.$route.query.info_code) {
        this.info = true;
        this.infoCode = this.$route.query.info_code;
      }
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
