<template>
  <section>
    <!-- START MOBILE SORT -->
    <a
      href="#"
      class="btn btn-primary btn-primary--thin btn-primary--thin--short btn-full--xs mb-sm-0 d-lg-none"
      data-toggle="modal"
      data-target="#sort"
      >{{ 'Sort' | translate }}</a
    >
    <hr class="mt-4 mb-2 d-lg-none" />
    <div class="modal fade" id="sort">
      <div class="modal-dialog modal-dialog--edit">
        <div class="modal-content edit-modal">
          <div class="modal-body">
            <h2 class="h2 h2--sort">{{ 'Sort By' | translate }}</h2>
            <hr class="my-4" />
            <select
              name="company"
              class="form-control text-center"
              aria-required="true"
              aria-invalid="true"
            >
              <option value="">{{ 'Ascending' | translate }}</option>
              <option value="manual">{{ 'Chronological' | translate }}</option>
            </select>
            <div class="modal__radio-item">
              <p class="modal__radio-item__p">{{ 'Evaluation' | translate }}</p>
              <input
                type="radio"
                class="modal__radio-item__radio"
                v-model="sorted"
                value="name"
              />
            </div>
            <div class="modal__radio-item">
              <p class="modal__radio-item__p">{{ 'Dairy' | translate }}</p>
              <input
                type="radio"
                class="modal__radio-item__radio"
                v-model="sorted"
                value="dairy"
              />
            </div>
            <div class="modal__radio-item">
              <p class="modal__radio-item__p">{{ 'Company' | translate }}</p>
              <input
                type="radio"
                class="modal__radio-item__radio"
                v-model="sorted"
                value="company"
              />
            </div>
            <div class="modal__radio-item">
              <p class="modal__radio-item__p">
                {{ 'Report Date' | translate }}
              </p>
              <input
                type="radio"
                class="modal__radio-item__radio"
                v-model="sorted"
                value="date_created"
              />
            </div>
            <div class="modal__radio-item">
              <p class="modal__radio-item__p">
                {{ 'Date Modified' | translate }}
              </p>
              <input
                type="radio"
                class="modal__radio-item__radio"
                v-model="sorted"
                value="date_modified"
              />
            </div>

            <hr class="my-4" />

            <div class="row modal-bottom">
              <div class="col-md-24">
                <button
                  type="button"
                  class="btn btn-primary btn-primary--modal mb-2"
                  data-dismiss="modal"
                  @click="sort(sorted.toString())"
                >
                  {{ 'Done' | translate }}
                </button>
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center mt-2"
                  data-dismiss="modal"
                  >{{ 'Cancel' | translate }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END MOBILE SORT -->

    <!-- START MOBILE REPORT MODAL -->
    <transition name="modal" v-if="showModal">
      <div
        class="modal-overlay modal-iframe-wrapper d-flex align-items-center justify-content-center"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog--edit" role="document">
          <div class="modal-content edit-modal">
            <div class="modal-body">
              <div class="modal-iframe-wrapper__responsive">
                <iframe
                  id="pdfFrame"
                  class="modal-iframe-wrapper__responsive__iframe"
                  :src="$store.state.reportUrl"
                ></iframe>
              </div>
              <div class="row modal-bottom">
                <button
                  @click="showModal = false"
                  class="btn-cancel-modal d-flex justify-content-center"
                >
                  {{ 'Cancel' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- END MOBILE REPORT MODAL -->

    <!-- START COLUMN HEADINGS -->
    <div class="card main-card main-card--evaluations">
      <div id="columns" class="row d-none d-sm-none d-md-none d-lg-flex mx-0">
        <div class="col-5 offset-1 heading mb-0">
          <span
            class="pointer"
            @click="
              sort('name');
              sortedEvaluations();
            "
          >
            {{ 'Evaluations' | translate }}
            <img
              class="ml-1 sort"
              src="@/assets/icons/Sortiicon.svg"
              alt="sort by evaluation name"
            />
          </span>
        </div>
        <div class="col-4 heading mb-0">
          <span
            class="pointer"
            @click="
              sort('dairy');
              sortedEvaluations();
            "
          >
            {{ 'Dairy' | translate }}
            <img
              class="ml-1 sort"
              src="@/assets/icons/Sortiicon.svg"
              alt="sort by dairy name"
            />
          </span>
        </div>
        <div class="col-5 heading mb-0">
          <span
            class="pointer"
            @click="
              sort('company');
              sortedEvaluations();
            "
          >
            {{ 'Company' | translate }}
            <img
              class="ml-1 sort"
              src="@/assets/icons/Sortiicon.svg"
              alt="sort by company name"
            />
          </span>
        </div>
        <div class="col-4 heading mb-0">
          <span
            class="pointer"
            @click="
              sort('date_created');
              sortedEvaluations();
            "
          >
            {{ 'Report Date' | translate }}
            <img
              class="ml-1 sort"
              src="@/assets/icons/Sortiicon.svg"
              alt="sort by report date"
            />
          </span>
        </div>
        <div class="col-4 heading mb-0">
          <span
            class="pointer"
            @click="
              sort('date_modified');
              sortedEvaluations();
            "
          >
            {{ 'Date Modified' | translate }}
            <img
              class="ml-1 sort"
              src="@/assets/icons/Sortiicon.svg"
              alt="sort by date modified"
            />
          </span>
        </div>
      </div>
      <!-- END COLUMN HEADINGS -->
      <div v-if="!evaluations || !isLoaded">
        <div class="loading loading--sm">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
      </div>
      <div v-else-if="evaluations.length === 0" class="mt-4 alert alert-danger">
        <p class="mb-0">{{ 'No evaluations have been added!' | translate }}</p>
      </div>
      <evaluation
        v-else
        v-for="(evaluation, index) in evaluations"
        :key="evaluation._id + index"
        :evaluation="evaluation"
        :user="user"
      >
        <div class="accordion-heading--wrapper">
          <div
            :id="[userType + '-evaluation-' + index]"
            class="accordion-heading row mx-0"
          >
            <div
              class="open-eval col-3 col-lg-1 px-0"
              data-toggle="collapse"
              :href="['#' + userType + 'EvaluationAccordion' + index]"
              aria-expanded="false"
              :aria-controls="[userType + 'EvaluationAccordion' + index]"
            >
              <i
                class="fa fa-angle-down fa-angle-down--eval"
                aria-hidden="true"
              ></i>
              <i
                class="fa fa-angle-up fa-angle-up--eval"
                aria-hidden="true"
              ></i>
            </div>
            <div class="col-17 col-lg-5 heading mb-0">
              {{ evaluation.name ? evaluation.name : evaluation._id }}
            </div>
            <div
              class="col-4 d-none d-sm-none d-md-none d-lg-block heading mb-0"
            >
              {{ getDairyLocationName(evaluation) }}
            </div>
            <div
              class="col-5 d-none d-sm-none d-md-none d-lg-block heading mb-0"
            >
              {{ getClientName(evaluation) }}
            </div>
            <div
              class="col-4 d-none d-sm-none d-md-none d-lg-block heading mb-0"
            >
              {{ $store.getters.getDate(evaluation.date_created) }}
            </div>
            <div
              class="col-4 d-none d-sm-none d-md-none d-lg-block heading mb-0"
            >
              {{
                evaluation.date_updated
                  ? $store.getters.getDate(evaluation.date_updated)
                  : $store.getters.getDate(evaluation.date_created)
              }}
            </div>
            <div class="col-lg-1 col-md-4 col-3 pl-0 heading mb-0">
              <modal-ellipsis :value="userType + index" class="pull-right">
                <template slot="modal-title">{{
                  'What Would You Like To Do?' | translate
                }}</template>
                <template slot="modal-content">
                  <!-- Do not know where this should link to. Herd Characteristics or assessor? -->
                  <router-link
                    class="btn btn-full btn-full--modal"
                    :to="
                      '/users/' + user._id + '/evaluations/' + evaluation._id
                    "
                    data-dismiss="modal"
                    >{{ 'Edit' | translate }}</router-link
                  >
                  <div class="row">
                    <div class="col-md-24 modal-bottom">
                      <a
                        href="#"
                        class="btn-cancel-modal mt-2"
                        data-dismiss="modal"
                        >{{ 'Cancel' | translate }}</a
                      >
                    </div>
                  </div>
                </template>
              </modal-ellipsis>
            </div>
          </div>
        </div>
        <div
          :id="[userType + 'EvaluationAccordion' + index]"
          class="collapse pb-3"
          role="tabpanel"
        >
          <div class="row evaluations-row d-md-none">
            <div class="col-md-22 pt-3 offset-1">
              <h3 class="h3">
                {{ getDairyLocationName(evaluation) }}
              </h3>
              <h3 class="h3">{{ getClientName(evaluation) }}</h3>
              <div class="row">
                <div class="col-12 py-0">
                  <strong>{{ 'Report Date' | translate }}</strong>
                  <p class="p--large my-0">
                    {{ $store.getters.getDate(evaluation.date_created) }}
                  </p>
                </div>
                <div class="col-11 py-0 d-flex justify-content-end">
                  <div>
                    <strong>{{ 'Date Modified' | translate }}</strong>
                    <p class="p--large my-0">
                      {{ $store.getters.getDate(evaluation.date_updated) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0 mt-3">
            <div class="col-22 offset-1 accordion-heading--wrapper">
              <div
                :id="[userType + '-evaluation-' + index]"
                class="accordion-heading accordion-heading--sm row"
              >
                <div class="col-21 col-lg-23 heading mb-0">
                  {{ 'Select/Deselect Assessors' | translate }}
                </div>
                <div
                  class="open-eval open-eval-left col-3 col-lg-1"
                  data-toggle="collapse"
                  :href="['#' + userType + 'SelectAssessors' + index]"
                  aria-expanded="false"
                  :aria-controls="[userType + 'SelectAssessors' + index]"
                >
                  <i
                    class="fa fa-angle-down fa-angle-down--eval"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="fa fa-angle-up fa-angle-up--eval"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div
              :id="[userType + 'SelectAssessors' + index]"
              class="col-22 offset-1 collapse background-white background-white--reports"
              role="tabpanel"
            >
              <div class="form-group w-100">
                <label class="mt-2 d-none d-md-inline"
                  >{{ 'Select Assessors' | translate }}
                  <span class="required mr-2">*</span></label
                >
                <label class="label-checkbox">
                  <input
                    type="checkbox"
                    :checked="assessorsCheckAll[index]"
                    @click="checkAll(index, 'assessors')"
                  />
                  <span class="checkbox checkbox--main"></span>
                </label>
                <label class="label--font-thin mt-2" :for="evaluation._id">{{
                  'Select/Deselect All' | translate
                }}</label>
              </div>
              <div class="row assessors-list">
                <div
                  v-for="(assessorTypeId, assessorIndex) in evaluation.order"
                  :key="assessorIndex"
                  class="assessorCheckbox col-lg-12"
                >
                  <div class="form-group">
                    <label class="label-checkbox">
                      <input
                        :id="evaluation._id + assessorTypeId"
                        v-model="evaluation.report.assessors"
                        :value="assessorTypeId"
                        @change="checkIndividual(index, 'assessors')"
                        type="checkbox"
                      />
                      <span class="checkbox"></span>
                    </label>
                    <label
                      class="label--font-thin mt-2"
                      :for="evaluation._id + assessorTypeId"
                      >{{
                        $store.getters.getAssessorTypeById(assessorTypeId).name
                          | translate
                      }}
                    </label>
                    <modal-info :value="assessorTypeId + evaluation._id">
                      <template slot="modal-content">
                        <h2 class="h2 pb-2 pt-3 px-3 text-center">
                          {{
                            $store.getters.getAssessorTypeById(assessorTypeId)
                              .name | translate
                          }}
                        </h2>
                        <p
                          class="p--md px-3"
                          v-for="(description,
                          index) in $store.getters.getAssessorTypeById(
                            assessorTypeId
                          ).description"
                          :key="index"
                        >
                          {{ description | translate }}
                        </p>
                        <div class="row modal-bottom pb-3">
                          <a
                            href="#"
                            class="btn-cancel-modal d-flex justify-content-center"
                            data-dismiss="modal"
                            >{{ 'Cancel' | translate }}</a
                          >
                        </div>
                      </template>
                    </modal-info>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0 mt-3">
            <div class="col-22 offset-1 accordion-heading--wrapper">
              <div
                :id="[userType + '-evaluation-' + index]"
                class="row accordion-heading accordion-heading--sm"
              >
                <div class="col-21 col-lg-23 heading mb-0">
                  {{ 'Select/Deselect' | translate }} {{ 'Groups' | translate }}
                </div>
                <div
                  class="open-eval open-eval-left col-3 col-lg-1"
                  data-toggle="collapse"
                  :href="['#selectGroups' + index]"
                  aria-expanded="false"
                  :aria-controls="['selectGroups' + index]"
                >
                  <i
                    class="fa fa-angle-down fa-angle-down--eval"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="fa fa-angle-up fa-angle-up--eval"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0" v-if="evaluation.groups && evaluation.report">
            <div
              :id="['selectGroups' + index]"
              class="col-22 offset-1 collapse background-white background-white--reports"
              role="tabpanel"
            >
              <div class="form-group w-100">
                <label class="mt-2 d-none d-md-inline"
                  >{{ 'Select Groups' | translate }}
                  <span class="required mr-2">*</span></label
                >
                <label :class="['label-checkbox']">
                  <input
                    type="checkbox"
                    :checked="groupsCheckAll[index]"
                    @click="checkAll(index, 'groups')"
                  />
                  <span class="checkbox"></span>
                </label>
                <label class="label--font-thin mt-2" for="inlineCheckbox2">{{
                  'Select/Deselect All' | translate
                }}</label>
              </div>
              <div class="row">
                <div
                  v-for="(group, groupIndex) in evaluation.groups"
                  :key="groupIndex + group.id"
                  class="assessorCheckbox col-lg-6"
                >
                  <div class="form-group">
                    <label :class="['label-checkbox']">
                      <input
                        @change="checkIndividual(index, 'groups')"
                        v-model="evaluation.report.groups"
                        :value="group.id"
                        :id="group.id"
                        type="checkbox"
                      />
                      <span class="checkbox"></span>
                    </label>
                    <label class="label--font-thin mt-2" :for="group.id">
                      {{ group.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-22 offset-1">
              <hr class="my-4" />
            </div>
          </div>
          <div class="row">
            <div class="col-22 offset-1 col-sm-11">
              <modal-exec :evaluationid="evaluation._id">
                <template slot="modal-title">
                  <span>{{ 'Executive Summary' | translate }}</span>
                </template>
                <template slot="modal-content">
                  <label class="float-left mb-1">{{
                    'Executive Summary Title' | translate
                  }}</label>
                  <div class="input-group mb-3">
                    <input
                      v-model="executive_summary_titles[index]"
                      type="text"
                      class="form-control"
                      aria-describedby="basic-addon3"
                    />
                  </div>
                  <label class="float-left mb-1">{{
                    'Executive Summary' | translate
                  }}</label>
                  <div class="executiveContent input-group">
                    <textarea
                      v-model="executive_summary_contents[index]"
                      class="form-control"
                      aria-label="With textarea"
                    ></textarea>
                  </div>
                  <div class="row mt-3 flex-sm-row-reverse">
                    <div class="col-md-12">
                      <button
                        type="button"
                        class="mt-1 btn btn-primary pull-right btn-full--sm btn-font-18--sm"
                        @click="saveExecutiveSummary(index)"
                        data-dismiss="modal"
                      >
                        {{ 'Save' | translate }}
                      </button>
                    </div>
                    <div
                      class="col-md-12 d-flex justify-content-center align-items-center"
                    >
                      <a
                        href="#"
                        class="btn-cancel-modal mt-3 mt-md-0"
                        data-dismiss="modal"
                        @click="cancelExecutiveSummary(index)"
                        >{{ 'Cancel' | translate }}</a
                      >
                    </div>
                  </div>
                </template>
              </modal-exec>
            </div>
            <div class="col-22 col-sm-10 offset-1">
              <button
                @click="generateReport(index, evaluation._id)"
                :disabled="
                  evaluation.report.assessors.length === 0 ||
                    evaluation.report.groups.length === 0
                "
                class="btn btn-primary--thin btn-full--sm pull-right mt-2 mt-sm-0"
              >
                {{ 'Export' | translate }}
              </button>
            </div>
          </div>
          <!-- end row -->
        </div>
        <div class="row px-2">
          <div class="col-22">
            <div v-show="x === 'one'" class="form-group">
              <label class="mt-2"
                >{{ 'Select Assessors' | translate }}
                <span class="required mr-2">*</span>
              </label>
              <label class="label-checkbox">
                <input type="checkbox" id="inlineCheckbox1" value="option1" />
                <span class="checkbox"></span>
              </label>
              <label class="label--font-thin mt-2" for="inlineCheckbox1">{{
                'Select/Deselect Assessors' | translate
              }}</label>
            </div>
            <div v-show="x === 'two'" class="form-group">
              <label class="mt-2"
                >{{ 'Select Groups' | translate }}
                <span class="required mr-2">*</span>
              </label>
              <label class="label-checkbox">
                <input type="checkbox" id="inlineCheckbox1" value="option1" />
                <span class="checkbox"></span>
              </label>
              <label class="label--font-thin mt-2" for="inlineCheckbox1">{{
                'Select/Deselect Groups' | translate
              }}</label>
            </div>
          </div>
        </div>
      </evaluation>
    </div>

    <!-- <div class="d-flex justify-content-center w-100">
            <button v-if="evaluationsToShow.length !== evaluations.length" class="btn btn-primary mt-3" @click="showMore()">{{ 'Show More' | translate }}</button>
        </div> -->
  </section>
</template>

<script>
import Vue from 'vue';
import Status from '@/components/evaluations/status.vue';
import GroupCount from '@/components/evaluations/groupCount.vue';
import Evaluation from '@/components/misc/evaluation.vue';
import ModalExec from '@/components/misc/modal-exec.vue';
import ModalEllipsis from '@/components/misc/modal-ellipsis.vue';
import ModalInfo from '@/components/misc/modal-info.vue';
import ReportPptx from '../../libs/ReportPptx/reportpptx';
import { mapActions, mapGetters, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import {
  GET_CLIENT_BY_ID_GETTER,
  GET_CUSTOMER_EVALUATIONS,
  GET_EVALUATION_BY_USER_ID_GETTER,
  GET_UPDATE_EVALUATION,
  GET_USER_MEDIAS,
  SET_EVALUATIONS,
} from '../../store/types';

export default {
  components: {
    Evaluation,
    GroupCount,
    ModalExec,
    ModalEllipsis,
    ModalInfo,
    Status,
  },
  props: ['user', 'customerId'],
  data() {
    return {
      allSelected: false,
      assessors: [],
      assessorsCheckAll: [],
      currentSort: 'name',
      currentSortDir: 'asc',
      evaluations: null,
      executive_summary_titles: [],
      executive_summary_contents: [],
      groupsCheckAll: [],
      iframeSrc: null,
      isPWA: false,
      selected: [],
      showModal: false,
      showRep: false,
      sorted: 'name',
      userIds: [],
      userType: null,
      value: null,
      isLoaded: false,
      customerEvaluation: null,
      x: '',
    };
  },
  metaInfo: {
    title: 'Evaluations',
  },
  computed: {
    ...mapState({
      loggedInUser: state => state.users.user,
      allEvaluations: state => state.evaluations.evaluations,
      medias: state => state.medias.medias,
    }),

    ...mapGetters('clients', [GET_CLIENT_BY_ID_GETTER]),
    ...mapGetters('evaluations', [GET_EVALUATION_BY_USER_ID_GETTER]),

    // evaluationList() {
    //   return this.customerEvaluation || this.evaluations;
    // },
  },
  methods: {
    ...mapActions('evaluations', [
      GET_UPDATE_EVALUATION,
      GET_CUSTOMER_EVALUATIONS,
    ]),
    ...mapActions('medias', [GET_USER_MEDIAS]),
    async getUserData() {
      // todo: 1. get the user evaluations, 2. get the user medias;
      this.isLoaded = false;
      if (this.customerId) {
        this.evaluations = await this[GET_CUSTOMER_EVALUATIONS](
          this.customerId
        );
        this.userType = 'customer';
      } else {
        this.userType = 'user';
        this.evaluations = this.allEvaluations;
      }

      // added two fields in evaluation report to for adding the additional information.
      this.setExecutiveSummary();
      this.emptyCheckboxes();
      this.isLoaded = true;
    },

    setExecutiveSummary() {
      this.evaluations.map(evaluation => {
        if (!evaluation.report) {
          evaluation.report = {
            executive_summary_title: '',
            executive_summary_content: '',
          };
        }
        return evaluation;
      });

      this.evaluations.forEach((evaluation, index) => {
        // Set executive summary title
        if (evaluation.report.executive_summary_title) {
          this.executive_summary_titles[index] =
            evaluation.report.executive_summary_title;
        } else {
          this.executive_summary_titles[index] = '';
        }

        // Set executive summary content
        if (evaluation.report.executive_summary_content) {
          this.executive_summary_contents[index] =
            evaluation.report.executive_summary_content;
        } else {
          this.executive_summary_contents[index] = '';
        }
      });
    },
    sortedEvaluations() {
      this.evaluations = this.evaluations.sort((a, b) => {
        let caseA;
        let caseB;
        switch (this.currentSort) {
          case 'name':
            caseA = a.name ? a.name.toLowerCase() : '';
            caseB = b.name ? b.name.toLowerCase() : '';
            break;
          case 'dairy':
            caseA = this.getDairyLocationName(a);
            caseB = this.getDairyLocationName(b);
            break;
          case 'company':
            caseA = this.getClientName(a);
            caseB = this.getClientName(b);
            break;
          case 'date_created':
            caseA = new Date(new Date(a.date_created).getTime() * 1000);
            caseB = new Date(new Date(b.date_created).getTime() * 1000);
            break;
          case 'date_modified':
            caseA = new Date(new Date(a.date_created).getTime() * 1000);
            caseB = new Date(new Date(b.date_created).getTime() * 1000);
            break;
          default:
        }
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (caseA < caseB) return -1 * modifier;
        if (caseA > caseB) return 1 * modifier;

        return 0;
      });
      // this.$store.commit(`evaluations/${SET_EVALUATIONS}`, evaluations);
    },
    sort(s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    emptyCheckboxes() {
      this.evaluations.forEach(evaluation => {
        evaluation.report.assessors = [];
        evaluation.report.groups = [];
        if (evaluation.groups) {
          for (let groupId in evaluation.groups) {
            evaluation.groups[groupId].disabled = true;
          }
        }
      });
    },
    disableGroups(evaluation) {
      evaluation.groups.forEach(a => {
        let self = this;
        let intersection = _.intersection(
          a.assessor_list,
          evaluation.report.assessors
        );
        if (intersection.length == 0) {
          return (a.disabled = true);
        } else {
          return (a.disabled = false);
        }
      });
    },
    checkAll(index, type) {
      this.evaluations[index].report[type] = [];
      if (!this[type + 'CheckAll'][index]) {
        // Check all
        if (type === 'groups') {
          let newReportValues = [];
          for (let groupId in this.evaluations[index].groups) {
            newReportValues.push(groupId);
          }
          this.evaluations[index].report[type] = newReportValues;
        } else {
          this.evaluations[index].report[type] = this.evaluations[
            index
          ].order.map(assessor => {
            return assessor;
          });
        }
      }

      if (type === 'groups') {
        this[type + 'CheckAll'][index] =
          this.evaluations[index].report[type].length ===
          Object.values(this.evaluations[index].groups).length;
      } else {
        this[type + 'CheckAll'][index] =
          this.evaluations[index].report[type].length ===
          this.evaluations[index].order.length;
      }
      this.$forceUpdate();
    },
    checkIndividual(index, type) {
      console.log({ report: this.evaluations[index].report[type] });
      if (type === 'groups') {
        this[type + 'CheckAll'][index] =
          this.evaluations[index].report[type].length ===
          Object.values(this.evaluations[index].groups).length;
      } else {
        this[type + 'CheckAll'][index] =
          this.evaluations[index].report[type].length ===
          this.evaluations[index].order.length;
      }
      this.$forceUpdate();
    },
    orderedGroups(groups) {
      return groups.sort((a, b) => {
        return a.order > b.order;
      });
    },
    getDairyLocationName(evaluation) {
      const path = `clientData.dairy_locations.${evaluation.dairyLocationId}.name`;
      return _.get(evaluation, path, '');
    },
    getClientName(evaluation) {
      return _.get(evaluation, 'clientData.name', '');
    },
    async saveExecutiveSummary(evaluationIndex) {
      Vue.set(
        this.evaluations[evaluationIndex].report,
        'executive_summary_title',
        this.executive_summary_titles[evaluationIndex]
      );
      Vue.set(
        this.evaluations[evaluationIndex].report,
        'executive_summary_content',
        this.executive_summary_contents[evaluationIndex]
      );
      const evaluation = {
        ...this.evaluations[evaluationIndex],
        report: {
          ...this.evaluations[evaluationIndex].report,
          executive_summary_titles: this.executive_summary_titles,
          executive_summary_contents: this.executive_summary_contents,
        },
      };
      await this[GET_UPDATE_EVALUATION](evaluation);
    },
    cancelExecutiveSummary(evaluationIndex) {
      Vue.set(
        this.executive_summary_titles,
        evaluationIndex,
        this.evaluations[evaluationIndex].report.executive_summary_title
      );
      Vue.set(
        this.executive_summary_contents,
        evaluationIndex,
        this.evaluations[evaluationIndex].report.executive_summary_content
      );
    },
    selectAllCheckboxes(evaluationIndex, typeOfCheckbox) {
      this.evaluations[evaluationIndex].report[
        typeOfCheckbox
      ] = this.evaluations[evaluationIndex].map(evaluation => {
        return evaluation._id;
      });
    },

    getClientById(id) {
      // return this.$store.getters.getData('users/' + this.user.id + '/clients/' + id);
      return this[GET_CLIENT_BY_ID_GETTER](id);
    },

    removeAllCheckboxes(evaluationIndex, typeOfCheckbox) {
      this.evaluations[evaluationIndex].report[typeOfCheckbox] = [];
    },

    generateReport(evaluationIndex, evaluationId) {
      const evaluation = cloneDeep(this.evaluations[evaluationIndex]); // Update client data
      // const clientData =
      //   evaluation.clientData && evaluation.clientData._id ? this.getClientById(evaluation.clientData._id) : null;
      // evaluation.clientData = clientData ? JSON.parse(JSON.stringify(clientData)) : null;

      let reportPptx = new ReportPptx(this, this.user, evaluation);

      // reportPptx.setBrowser(true);
      // let evaluation = this.$store.getters.getData(
      //   'users/' + this.user.id + '/evaluations/' + evaluationId
      // );

      let currentDate = new Date();
      evaluation.report.date_created = Math.round(currentDate.getTime() / 1000);

       reportPptx.generateTitle(
         evaluation.name,
         this.$store.getters.getDate(evaluation.report.date_created),
         this.user
       );

      // Render executive summary content
      if (
        evaluation.report.executive_summary_content !== null &&
        evaluation.report.executive_summary_content !== ''
      ) {
        reportPptx.generateExecSummary(
          evaluation.report.executive_summary_title,
          evaluation.report.executive_summary_content
        );
      }

      // Render assessors' slides
      for (var i = 0; i < evaluation.report.assessors.length; i++) {
        reportPptx.generateAssessor(
          evaluation,
          evaluation.report.assessors[i],
          i
        );
      }

      // Render table of contents
      reportPptx.tableOfContents(
        reportPptx.tableOfContentsCalculator,
        evaluation.report.executive_summary_title,
        evaluation.report.executive_summary_content
      );

      // Render error slides
      // reportPptx.generateErrorSlides();

      // If mobile or PWA - show report in modal
      // if ((this.$store.getters.isPWA() && this.$store.getters.isIOS()) || this.$route.query.testReport) {
      //   reportPptx.pptx.write("blob").then((data) => {
      //     const blob = new Blob([ data ], {
      //       type: "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      //     });
      //     const url = window.URL.createObjectURL(blob);
      //     this.$store.state.reportUrl = url;
      //     this.showModal = true;
      //     setTimeout(() => {
      //       this.$forceUpdate();
      //     }, 2000);
      //   }).catch((err) => {
      //     console.error(err);
      //   });
      // } else {
      // Save report
      reportPptx.pptx.writeFile(
          this.$t('Zinpro FirstStep - ') 
          +
          evaluation.name +
          this.$t(' - ') +
          (currentDate.getMonth() + 1) +
          '-' +
          currentDate.getDate() +
          this.$t(' ') +
          currentDate.getHours() +
          '-' +
          currentDate.getMinutes() +
          '-' +
          currentDate.getSeconds(),
      );
      // }
    },
  },
  created() {
    this.getUserData();
  },
  watch: {
    customerId: {
      handler: function(customerId) {
        this.getUserData(customerId);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.assessors-list {
  label {
    font-size: 13px;
  }
  .col-md-8 {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
