
import convert from 'convert-units';
import { $getEquivalentUnit } from '../utils';

const transitionConstants = {
  projected_first_test_305_min: 9000,
  projected_first_test_305_mid: 10500,
  projected_first_test_305_max: 12000,
  peak_ratio_min: 73,
  peak_ratio_mid: 76,
  peak_ratio_max: 78,
  first_test_fat_protein_ratio_1_min: 22,
  first_test_fat_protein_ratio_1_mid: 40,
  first_test_fat_protein_ratio_1_max: 55,
  first_test_fat_protein_ratio_2_min: 22,
  first_test_fat_protein_ratio_2_mid: 40,
  first_test_fat_protein_ratio_2_max: 55,
  scc_at_first_test_1_min: 8,
  scc_at_first_test_1_mid: 18,
  scc_at_first_test_1_max: 27,
  scc_at_first_test_2_min: 8,
  scc_at_first_test_2_mid: 18,
  scc_at_first_test_2_max: 27,
  stillbirths_1_min: 5,
  stillbirths_1_mid: 9,
  stillbirths_1_max: 15,
  stillbirths_2_min: 3,
  stillbirths_2_mid: 6,
  stillbirths_2_max: 11,
  dry_period_cure_rate_min: 50,
  dry_period_cure_rate_mid: 66,
  dry_period_cure_rate_max: 80,
  turnover_sold_died_min: 5,
  turnover_sold_died_mid: 7,
  turnover_sold_died_max: 10,
  deaths_min: 1,
  deaths_mid: 3,
  deaths_max: 5,
  clinical_milk_fever_min: 1,
  clinical_milk_fever_mid: 5,
  clinical_milk_fever_max: 15,
  clinical_milk_fever_green: 2,
  clinical_milk_fever_yellow: 5,
  retained_placenta_min: 5,
  retained_placenta_mid: 10,
  retained_placenta_max: 15,
  retained_placenta_green: 8,
  retained_placenta_yellow: 10,
  metritis_min: 7,
  metritis_mid: 15,
  metritis_max: 25,
  metritis_green: 5,
  metritis_yellow: 10,
  displaced_abomasum_min: 1,
  displaced_abomasum_mid: 3,
  displaced_abomasum_max: 10,
  displaced_abomasum_green: 3,
  displaced_abomasum_yellow: 6,
  pneumonia_min: 1,
  pneumonia_mid: 4,
  pneumonia_max: 8,
  pneumonia_green: 2,
  pneumonia_yellow: 5,
  enteritis_min: 0.5,
  enteritis_mid: 2,
  enteritis_max: 5,
  enteritis_green: 1,
  enteritis_yellow: 3,
  ketosis_min: 8,
  ketosis_mid: 30,
  ketosis_max: 50,
  ketosis_green: 15,
  ketosis_yellow: 25,
  feed_bunk_space_min: 46,
  feed_bunk_space_max: 75,
  feed_drink_space_min: 8,
  feed_drink_space_max: 10,
  bunk_divider_type_min: 'no-bunk',
  bunk_divider_type_mid: 'slant-bar',
  bunk_divider_type_max: 'headlocks',
  lying_space_min: 6,
  lying_space_max: 11,
  freestall_surface_cushion_min: 'firm-bed',
  freestall_surface_cushion_mid: 'mattress',
  freestall_surface_cushion_max: 'deep-loose-bedding',
  freestall_occupation_area_min: 'small-overlap',
  freestall_occupation_area_mid: 'large-overlap',
  freestall_occupation_area_max: 'no-overlap',
  freestalls_lunge_obstruction_min: 'front-side',
  freestalls_lunge_obstruction_mid: 'front',
  freestalls_lunge_obstruction_max: 'none',
  heat_abatement_min: 'no-fans',
  heat_abatement_mid: 'fans',
  heat_abatement_max: 'fans-water',
  move_into_calving_pen_min: '3_7',
  move_into_calving_pen_mid: null,
  move_into_calving_pen_max: '2_6',
  how_many_moves_into_close_up_pen_min: 'multiple',
  how_many_moves_into_close_up_pen_mid: 'weekly',
  how_many_moves_into_close_up_pen_max: 'more_than_14',
  how_are_sick_cows_managed_min: 'mixed_all_times',
  how_are_sick_cows_managed_mid: 'segregated',
  how_are_sick_cows_managed_max: 'segregated_all_times',
  body_condition_score_1_min: 10,
  body_condition_score_1_mid: 20,
  body_condition_score_1_max: 30,
  body_condition_score_2_min: 10,
  body_condition_score_2_mid: 20,
  body_condition_score_2_max: 30,
  tmr_sorting_min: 'more-10',
  tmr_sorting_mid: '5-9',
  tmr_sorting_max: 'less-5',
  time_spent_in_lockups_min: 'more-3',
  time_spent_in_lockups_mid: '2',
  time_spent_in_lockups_max: 'less-1',
  screening_method_min: 'no-program',
  screening_method_mid: 'routine-temping',
  screening_method_mid2: 'daily-milk-wt',
  screening_method_max: 'dedicated-staff',
  time_checking_hr_min: 'more-3',
  time_checking_hr_mid: 'equal-2',
  time_checking_hr_max: 'less-1',
  air_speed_all_cows_min: 'less-5',
  air_speed_all_cows_mid: '5-8',
  air_speed_all_cows_max: 'more-5',
  air_speed_cow_min: 'less-300',
  air_speed_cow_mid: '300-1000',
  air_speed_cow_max: 'more-1000',
  dry_off_protocol_min: 'daily',
  dry_off_protocol_mid: 'multiple_per_week',
  dry_off_protocol_max: 'weekly',
  cows_rumen_fill_min: 15,
  cows_rumen_fill_max: 25,
  cows_manure_score_min: 15,
  cows_manure_score_max: 25,
  refusal_min: 3,
  refusal_max: 5,
};

const comparativeValues = {
  hr: {
    'more-3': '≥3 hrs',
    'equal-2': '1-3 hrs',
    'less-1': '≤1 hr',
  },
  calvinPattern: {
    yearRound: 'year_round',
    seasonal: 'seasonal',
  },
  airSpeedCow(key, measurements, $t) {
    let unit = $getEquivalentUnit(measurements, 'cmh');

    // TODO: Check units to convert&find coefficient
    const toUnit_300 = unit === 'cmh' ? 300 : 175;
    const toUnit_1000 = unit === 'cmh' ? 1000 : 590;
    
    // on few places we're not sending the unites along with value, because somehow it wasn't translated on these places.
    if (!$t) {
      unit = '';
      $t = val => val;
    }
    const values = {
      'no-vent': 'No ventilation available',
      'less-300': `< ${toUnit_300} ${$t(unit)}`,
      '300-1000': `${toUnit_300}-${toUnit_1000} ${$t(unit)}`,
      'more-1000': `> ${toUnit_1000} ${$t(unit)}`,
    };

    return values[key];
  },
  // airSpeedAllCows (key, measurements, $t) {
  //   const unit = $getEquivalentUnit(measurements, 'km/h');

  //   const toUnit = unit === 'mph' ? 'm/h' : unit;

  //   const toConverted = (value) => Math.round(
  //     convert(value)
  //       .from('km/h')
  //       .to(toUnit));

  //   const values = {
  //     'no-vent': 'No ventilation available',
  //     'less-5': `< ${toConverted(5)} ${unit}`,
  //     '5-8': `${toConverted(5)}-${toConverted(8)} ${unit}`,
  //     'more-5': `> ${toConverted(8)} ${unit}`,
  //   };

  //   return values[key];
  // },
  airSpeedAllCows(key, measurements, $t) {
    const unit = $getEquivalentUnit(measurements, 'km/h');

    // const toUnit = unit === 'mph' ? 'm/h' : unit;

    const toConverted = value =>
      Math.round(
        convert(value)
          .from('km/h')
          .to(unit)
      );

    let testUnit = unit === 'm/h' ? 'mph' : unit;

    // on few places we're not sending the unites along with value, because somehow it wasn't translated on these places.
    if (!$t) {
      $t = val => val;
      testUnit = '';
    }

    console.log({ testUnit: $t(testUnit) });

    const values = {
      'no-vent': 'No ventilation available',
      'less-5': `< ${toConverted(5)} ${$t(testUnit)}`,
      '5-8': `${toConverted(5)}-${toConverted(8)} ${$t(testUnit)}`,
      'more-5': `> ${toConverted(8)} ${$t(testUnit)}`,
    };

    return values[key];
  },
  heatAbatement: {
    'no-fans': 'No Fans or Water Cooling',
    fans: 'Fans Over Stalls, No Water Cooling',
    'fans-water': 'Fans Over Freestalls, Water Cooling',
  },
};

export { comparativeValues, transitionConstants };
export default transitionConstants;
