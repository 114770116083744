<template>
  <div>
    <div class="fixed-loading" v-show="!isLoaded">
      <div class="loading">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>

    <div class="container main-container main-container--accordion">
      <div class="card mb-0">
        <h1 class="h1">{{ 'Upload Translations' | translate }}</h1>
        <div class="card-body p-4">
          <div v-for="file in languageList" :key="file.id">
            <div class="card-body__top">
              <h2 class="h2 mb-0">{{ file.title | translate }}</h2>
            </div>
            <div class="row card-section mt-0">
              <div class="file-info" v-if="file.metadata">
                <p>{{ 'Last Update' }}: {{ lastUpdated(file) }}</p>
                <download-form :file="file"></download-form>
                <!-- href="http://showpdf.rahnuma.org/religion/Wasif.Ali.Wasif/Wasif.Ali.Wasif_Kiran-Kiran-Suraj.pdf" -->
                <!-- <a
                  :href="file.metadata.customMetadata.fileURL"
                  class="file-link"
                  target="_blank"
                >
                  Open
                  <svg
                    version="1.1"
                    viewBox="0 0 100 125"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <path
                        d="m43.505 50.11 6.6231 6.6231 35.71-35.71-0.078835 20.871 9.347-0.034022 0.13247-36.861-36.861 0.13247-0.034261 9.3468 20.871-0.078835-35.71 35.71zm6.3927-34.151-2.526e-4 11.291-33.695 6.243e-4v56.458h56.159l-8.79e-4 -33.876 11.233 8.728e-4v45.166h-78.623v-79.041h44.927z"
                        fill="#000"
                      />
                    </g>
                  </svg>
                </a> -->
              </div>
              <div class="last-user-name">
                <p>
                  {{ 'Modified User Name' }}:
                  {{ fullName(file) }}
                </p>
              </div>
              <div class="col-md-24 background-white p-2 mb-3 border">
                <input
                  :name="file.id"
                  type="file"
                  class="upload-file-input"
                  @change="inputFile"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
              </div>
              <div class="col-md-24 text-right p-0">
                <button
                  class="btn btn-primary btn-full--sm"
                  @click="fileUpload(file.id)"
                >
                  {{ 'Upload' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- end row -->
    </div>
  </div>
</template>

<script>
import AppHeader from '../../../components/AppHeader';
import AppFooter from '../../../components/AppFooter';
import { mapState } from 'vuex';
import formUploadMixin from '@/mixin/formUpload.js';
import DownloadForm from '@/components/misc/DownloadForm.vue';

export default {
  components: {
    AppHeader,
    AppFooter,
    DownloadForm,
  },
  mixins: [formUploadMixin],

  data() {
    return {
      info: false,
      infoMessage: '',
      isLoaded: true,
      filesRef: null,
      type: 'languageList',
      // storageList: [],
      // languageList: null
    };
  },
  computed: {
    ...mapState({
      storageList: state => state.storageList,
      languageList: state => state.languageList,
    }),
  },
  mounted() {
    this.isLoaded = true;
  },
};
</script>
<style>
input[type='file'].upload-file-input {
  display: block;
  position: relative;
  opacity: 1;
  z-index: 2;
}
.file-info {
  display: flex;
  align-items: center;
}
.file-link {
  color: #0000ff;
  text-decoration: underline;
  margin: 0 10px;
  position: relative;
}

.file-link svg {
  height: 16px;
  width: 11px;
  margin: 13px 3px;
  position: absolute;
  right: -17px;
  top: -12px;
}

.fixed-loading {
  position: fixed;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  z-index: 9999;
  top: 0;
  left: 0;
}
</style>
