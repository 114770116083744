const Table = require('../../Misc/Table');
const twoRowTail = '/images/Two_Row_Tail_to_Tail.jpg';
const twoRowHeadTail = '/images/Two_Row_Head_to_Tail.jpg';
const SingleRow = '/images/Single_Row.jpg';
const HeadHead = '/images/Two_Row_Head_to_Head.jpg';
const ThreeRow = '/images/Three_Row.jpg';
const Cantilever = '/images/Cantilever.jpg';
const Michigan = '/images/Michigan.jpg';
const MultipleBend = '/images/Multiple_Bend.jpg';
const Square = '/images/Square.jpg';
const SturdyBuilt = '/images/Sturdy_built.jpg';

/**
 * @TODO Determine if work needs to be done for fields missing ideal, min, max
 */

export const freeStall = function (app, report, evaluation, group, assessor) {

  let slide = report.pptx.addSlide('Slide master');

  slide.addText(
    app.$t('Freestall Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
  );

  slide.addText(
    app.$t('Your Operation'), {
      x: 0.5,
      y: 2.4,
      ...report.styleOptions.subheaderBlue
    }
  );

  slide.addText(
    [{
      text: app.$t('Average Cow Size') + app.$t(': '),
      options: {
        bold: true,
        fontFace: 'Arial',
      }
    },
      {
        text: group.getCowSizeLabel() + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')),
        options: {
          breakLine: true,
          fontFace: 'Arial',
        }
      },

      {
        text: app.$t('Bedding Type') + ': ',
        options: {
          bold: true,
          fontFace: 'Arial',
        }
      },
      {
        text: assessor.getBeddingType(),
        options: {
          breakLine: true,
          fontFace: 'Arial',
        }
      },

      {
        text: app.$t('Freestall Alignment:') + app.$t(' '),
        options: {
          bold: true,
          fontFace: 'Arial',
        }
      },
      {
        text: app.$t(assessor.getStallAlignment()),
        options: {
          breakLine: true,
          fontFace: 'Arial',
        }
      },

      {
        text: app.$t('Loop Divider Type:') + app.$t(' '),
        options: {
          bold: true,
          fontFace: 'Arial',
        }
      },
      {
        text: app.$t(assessor.getDividerType()),
        options: {
          breakLine: true,
          fontFace: 'Arial',
        }
      },

      {
        text: app.$t('Analysis Type:') + app.$t(' '),
        options: {
          bold: true,
          fontFace: 'Arial',
        }
      },
      {
        text: app.$t(assessor.getFreeStallType()),
        options: {
          breakLine: true,
          fontFace: 'Arial',
        }
      }
    ], {
      x: 0.5,
      y: 2.3,
      w: 7,
      h: 1.75,
      fontSize: 12
    }
  );

  slide.addShape(report.pptx.shapes.RECTANGLE, {
    x: 0.5,
    y: 4,
    w: 3.5,
    h: 2.15,
    line: '000000',
    line_size: 1
  });

  slide.addImage({
    path: assessor.getStallAlignmentValue() === 'two' ? HeadHead : assessor.getStallAlignmentValue() === 'three' ? ThreeRow : assessor.getStallAlignmentValue() === 'one' ? SingleRow : assessor.getStallAlignmentValue() === 'two_row_head_to_tail' ? twoRowHeadTail : twoRowTail,
    x: .75,
    y: 4.1,
    h: 1.7,
    w: 3.25
  });

  slide.addText(
    app.$t(assessor.getStallAlignment()), {
      x: .75,
      y: 5.75,
      w: 1.95,
      color: '000000',
      fontSize: 10,
      fontFace: 'Arial',
    }
  );

  slide.addShape(report.pptx.shapes.RECTANGLE, {
    x: 4.5,
    y: 4,
    w: 3.5,
    h: 2.15,
    line: '000000',
    line_size: 1
  });

  slide.addImage({
    path: assessor.getDividerTypeValue() === 'cantilever' ? Cantilever : assessor.getDividerTypeValue() === 'michigan' ? Michigan : assessor.getDividerTypeValue() === 'multiple_blend' ? MultipleBend : assessor.getDividerTypeValue() === 'square' ? Square : SturdyBuilt,
    x: 4.75,
    y: 4.1,
    h: 1.7,
    w: 3
  });

  slide.addText(
    app.$t(assessor.getDividerType()), {
      x: 4.75,
      y: 5.75,
      w: 1.95,
      color: '000000',
      fontSize: 10,
      fontFace: 'Arial',
    }
  );

  slide.addText(
    app.$t('Freestall Measurements'), {
      x: 0.5,
      y: 6.6,
      ...report.styleOptions.subheaderBlue
    }
  );

  let obj = [
    {
      text: app.$t('Question'),
      options: report.styleOptions.tableHeader
    },
    {
      text: app.$t('Answer'),
      options: report.styleOptions.tableHeader
    },
    {
      text: app.$t('Comment'),
      options: report.styleOptions.tableHeader
    }
  ]

  let obj2 = [];

  let resultMessage;

  resultMessage = assessor.getResultMessage('bedding_depth');

  if (assessor.getBeddingDepth() !== undefined) {
    obj2.push([{
      text: app.$t('Bedding Depth (') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: assessor.getBeddingDepth(),
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: resultMessage.formatted_feedback,
      options: {
        color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
        align: 'left',
        fill: 'efefef',
        margin: 5
      }
    },
    ]);
  }
  obj2.push([{
    text: app.$t('Is the Stall Surface Dry?'),
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: assessor.isSurfaceDry() === 1 ? app.$t('Yes') : app.$t('No'),
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: assessor.isSurfaceDry() === 1 ? app.$t('Good!') : app.$t('Excessive moisture on the lying surface may decrease lying time by about 1 hour per day. (Reich et al., 2010: JDS 93: 1561)'),
    options: {
      color: assessor.isSurfaceDry() === 1 ? '249824' : 'a40000',
      align: 'left',
      fill: 'efefef',
      margin: 5
    }
  },
  ]);
  obj2.push([{
    text: app.$t('Are hock lesions present?'),
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: assessor.getHockLesion() === 1 ? app.$t('Yes') : app.$t('No'),
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: assessor.getHockLesion() === 1 ? app.$t('Poor lying surfaces tend to increase the risk of hock lesions.') : app.$t('Good!'),
    options: {
      color: assessor.getHockLesion() === 1 ? 'a40000' : '249824',
      align: 'left',
      fill: 'efefef'
    }
  }]);

  resultMessage = assessor.getResultMessage('stall_width');

  obj2.push([{
    text: app.$t('Stall Width') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: assessor.getWidth(),
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: resultMessage.formatted_feedback,
    options: {
      color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
      align: 'left',
      fill: 'efefef',
      margin: 5
    }
  }]);

  resultMessage = assessor.getResultMessage('stall_length_single_row');

  if (assessor.getStallAlignmentValue() !== 'two') {
    obj2.push([{
      text: app.$t('Stall Length Single Row ') + '(' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: assessor.getSingleRowStallLength(),
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: resultMessage.formatted_feedback,
      options: {
        color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
        align: 'left',
        fill: 'efefef',
        margin: 5
      }
    }]);
  }

  resultMessage = assessor.getResultMessage('stall_length_double_row');
  if (assessor.getStallAlignmentValue() === 'two' || assessor.getStallAlignmentValue() === 'three') {
    obj2.push([{
      text: app.$t('Stall Length Double Row (') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: assessor.getDoubleRowStallLength(),
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: resultMessage.formatted_feedback,
      options: {
        color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
        align: 'left',
        fill: 'efefef',
        margin: 5
      }
    }]);
  }

  resultMessage = assessor.getResultMessage('rear_curb');

  if (['sand', 'manure_solids', 'straw', 'other'].includes(assessor.getBeddingTypeValue())) {
    obj2.push([{
      text: app.$t('Rear Curb Width') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: assessor.getRearCurbWidth(),
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: resultMessage.formatted_feedback,
      options: {
        color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
        align: 'left',
        fill: 'efefef',
        margin: 5
      }
    }]);
  }

  resultMessage = assessor.getResultMessage('rear_curb_height');

  obj2.push([{
    text: app.$t('Rear Curb Height') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: assessor.getRearCurbHeight(),
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: resultMessage.formatted_feedback,
    options: {
      color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
      align: 'left',
      fill: 'efefef',
      margin: 5
    }
  },]);

  resultMessage = assessor.getResultMessage('lower_divider_height');

  obj2.push([{
    text: app.$t('Lower Divider Height') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: assessor.getLowerDividerHeight(),
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: resultMessage.formatted_feedback,
    options: {
      color: resultMessage.feedback.mood === "text-danger" ? 'a40000' : '249824',
      align: 'left',
      fill: 'efefef',
      margin: 5
    }
  },]);

  resultMessage = assessor.getResultMessage('neckrail_height');

  obj2.push([{
    text: app.$t('Neckrail Height') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm') )+ ')',
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: assessor.getNeckrailHeight(),
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: resultMessage.formatted_feedback,
    options: {
      color: assessor.getNeckrailHeight() < assessor.getMinNeckrailHeight() || assessor.getNeckrailHeight() > assessor.getMaxNeckrailHeight() ? 'a40000' : '249824',
      align: 'left',
      fill: 'efefef',
      margin: 5
    }
  },]);

  resultMessage = assessor.getResultMessage('brisket_locator_height');

  obj2.push([{
    text: app.$t('Brisket Locator Height') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: assessor.getBrisketLocatorHeight(),
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: resultMessage.formatted_feedback,
    options: {
      color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
      align: 'left',
      fill: 'efefef',
      margin: 5
    }
  },]);

  resultMessage = assessor.getResultMessage('inside_loop_divider_diameter');

  if (assessor.getDividerTypeValue() !== 'michigan') {
    obj2.push([{
      text: app.$t('Inside Loop Divider Diameter') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: assessor.getInsideLoopDividerDiameter(),
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: resultMessage.formatted_feedback,
      options: {
        color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
        align: 'left',
        fill: 'efefef',
        margin: 5
      }
    }]);
  }

  resultMessage = assessor.getResultMessage('curb_to_brisket_locator_distance');

  obj2.push([{
    text: app.$t('Curb to Brisket Locator Distance') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: assessor.getCurbToBrisketLocatorDistance(),
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: resultMessage.formatted_feedback,
    options: {
      color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
      align: 'left',
      fill: 'efefef',
      margin: 5
    }
  },]);

  resultMessage = assessor.getResultMessage('neckrail_to_rear_curb_distance');

  obj2.push([{
    text: app.$t('Neckrail to Rear Curb Distance') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + ')',
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: assessor.getNeckrailToRearCurbDistance(),
    options: {
      color: '000000',
      fill: 'ffffff',
      valign: 'center',
      align: 'center',
      margin: 5
    }
  }, {
    text: resultMessage.formatted_feedback,
    options: {
      color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
      align: 'left',
      fill: 'efefef',
      margin: 5
    }
  },]);

  resultMessage = assessor.getResultMessage('bob_zone');

  if (assessor.getBobZone() !== undefined) {
    obj2.push([{
      text: app.$t('Is there a bob-zone obstruction?'),
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: assessor.getBobZone() === 1 ? app.$t('Yes') : app.$t('No'),
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: resultMessage.formatted_feedback,
      options: {
        color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
        align: 'left',
        fill: 'efefef',
        margin: 5
      }
    }]);
  }

  resultMessage = assessor.getResultMessage('concrete_fill');

  if (assessor.getConcreteFill() !== undefined) {
    obj2.push([{
      text: app.$t('Is there a concrete fill behind brisket locator?'),
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: assessor.getConcreteFill() === 1 ? app.$t('Yes') : app.$t('No'),
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: resultMessage.formatted_feedback,
      options: {
        color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
        align: 'left',
        fill: 'efefef',
        margin: 5
      }
    }]);
  }

  resultMessage = assessor.getResultMessage('brisket_locator_properly_locate_cows');

  if (assessor.getBrisketLocatorProperlyLocateCows() !== undefined) {
    obj2.push([{
      text: app.$t('Does the brisket locator properly locate the cows?'),
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: assessor.getBrisketLocatorProperlyLocateCows() === 1 ? app.$t('Yes') : app.$t('No'),
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: resultMessage.formatted_feedback,
      options: {
        color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
        align: 'left',
        fill: 'efefef',
        margin: 5
      }
    }]);
  }

  resultMessage = assessor.getResultMessage('knee_drop_test');

  if (assessor.getKneeDropTest() !== undefined) {
    obj2.push([{
      text: app.$t('Does the freestall pass the knee-drop test?'),
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: assessor.getKneeDropTest() === 1 ? app.$t('Yes') : app.$t('No'),
      options: {
        color: '000000',
        fill: 'ffffff',
        valign: 'center',
        align: 'center',
        margin: 5
      }
    }, {
      text: resultMessage.formatted_feedback,
      options: {
        color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
        align: 'left',
        fill: 'efefef',
        margin: 5
      }
    }]);
  }

  Table.create(slide, obj, obj2, report, group, 'Freestall Assessor', {
    y: 6.8,
    autoPageLineWeight: .25,
    colW: [2.5, 1, 4],
    ...report.styleOptions.tableOptions
  });

  return true;

}
