<!-- @format -->

<template>
  <div>
    <div class="loading" v-show="!isLoaded">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <div
      class="main-container main-container--margin-bt container"
      v-show="isLoaded"
    >
      <div class="card mb-0">
        <div class="row">
          <div class="col-md-10">
            <h1 class="h1 mb-3 mb-md-1">
              {{ "Edit Customer" | translate }}
            </h1>
          </div>
          <div
            class="col-md-14 d-flex align-items-center justify-content-md-end flex-column-reverse flex-md-row"
          >
            <button
              v-if="!customer.isVerified"
              @click="resendInvite"
              type="button"
              class="btn btn-primary btn-full--sm"
              :disabled="loader2"
            >
              {{ "Resend Invite" | translate }}
              <span v-show="loader2" class="loader ml-1"></span>
            </button>
            <button
              @click="exportCsv"
              type="button"
              class="btn btn-primary btn-full--sm ml-md-4 mb-2 mb-md-0"
            >
              {{ "Export CSV" | translate }}
              <span
                v-show="csvExportLoading"
                class="loader ml-1"
              ></span>
            </button>
          </div>
        </div>

        <div
          v-show="success2"
          class="alert alert-primary mt-2 mb-0"
          role="alert"
        >
          {{ successMessage | translate }}
        </div>

        <div class="card-body mt-3">
          <general-profile
            v-model="customer"
            :companies="companies"
          ></general-profile>

          <div class="row my-md-4">
            <div class="col-lg-12 mb-4 mb-lg-0">
              <div class="card-body__top">
                <h2 class="h2">{{ "Password Reset" | translate }}</h2>
              </div>
              <hr />
              <button
                @click="sendPasswordReset()"
                class="btn btn-primary btn-full--sm"
              >
                {{ "Reset Password" | translate }}
              </button>
              <div
                v-show="confirmEmailMessage === true"
                class="alert alert-primary mt-4 mb-0"
                role="alert"
              >
                {{
                  "Reset password email has been sent!" | translate
                }}
              </div>
            </div>
            <div class="col-lg-12">
              <div class="card-body__top">
                <h2 class="h2">{{ "Status" | translate }}</h2>
              </div>
              <!-- end row -->
              <hr />
              <p v-if="!customer.isVerified">
                {{
                  "An invitation has been sent to this user and is still pending."
                    | translate
                }}
              </p>
              <div v-else>
                <div class="form-group mb-0">
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        v-model="customer.status"
                        name="status"
                        :value="true"
                        data-vv-as="status"
                        class="form-check-input"
                        data-vv-id="33"
                        aria-required="true"
                        aria-invalid="false"
                      />{{ "Active" | translate }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        v-model="customer.status"
                        name="status"
                        :value="false"
                        data-vv-as="status"
                        class="form-check-input"
                        data-vv-id="34"
                        aria-required="true"
                        aria-invalid="false"
                      />{{ "Deactivate" | translate }}
                    </label>
                  </div>
                  <span
                    v-show="errors.has('status')"
                    class="text-danger d-block"
                  >
                    <small>{{ errors.first("status") }}</small>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end row -->
          <div class="row mt-lg-5">
            <div class="col-lg-12" v-if="user._id !== customer._id">
              <div class="card-body__top">
                <h2 class="h2">{{ "Permissions" | translate }}</h2>
              </div>
              <hr />
              <div class="row mt-3 mb-md-3">
                <div class="col-lg-24">
                  <div class="form-group">
                    <label
                      >{{ "Role" | translate }}
                      <span class="required">*</span>
                    </label>
                    <select
                      class="form-control"
                      v-model="customer.role"
                      name="role"
                      data-vv-as="role"
                      v-validate="'required'"
                    >
                      <option disabled :value="null">
                        {{ "Select" | translate }}
                      </option>
                      <option
                        v-if="user.role === 'admin'"
                        value="admin"
                      >
                        {{ "Admin" | translate }}
                      </option>
                      <option
                        value="external"
                        :disabled="myCustomers.length > 0"
                      >
                        {{ "Advanced Customer" | translate }}
                      </option>
                      <option
                        value="data_entry"
                        :disabled="myCustomers.length > 0"
                      >
                        {{ "Customer" | translate }}
                      </option>
                      <option
                        v-if="user.role === 'admin'"
                        value="zinpro_representative"
                        >{{ 'Zinpro Representative' | translate }}
                        </option>
                    </select>
                    <div v-if="myCustomers.length > 0">
                      {{
                        "This user selected as a Zinpro representative for "
                          | translate
                      }}
                      <b>{{ myCustomers.length }}</b>
                      {{
                        myCustomers.length > 1
                          ? "users"
                          : "user" | translate
                      }}

                      <span>
                        <router-link
                          v-if="
                            user.role === 'admin' &&
                            customer.customers.length
                          "
                          :to="`/admin/customer-management/${customer._id}`"
                          class="btn btn-secondary btn-full--sm float-left mt-md-2 order-3 order-md-3"
                          exact
                          >{{ "More" | translate }}</router-link
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                user.role === 'admin' &&
                !['admin', 'zinpro_representative'].includes(
                  customer.role
                )
              "
              class="col-md-12"
            >
              <div class="card-body__top">
                <h2 class="h2">
                  {{ "Zinpro Representative" | translate }}
                </h2>
              </div>
              <hr />
              <div v-if="zinproReps.length !== 0" class="form-group">
                <label>{{
                  "Zinpro Representative" | translate
                }}</label>
                <span class="required">*</span>
                <select
                  class="form-control"
                  v-model="customer.representative"
                  name="representative"
                  data-vv-as="Zinpro Representative"
                  v-validate="'required'"
                >
                  <option selected disabled value="null">
                    {{ "Select" | translate }}
                  </option>
                  <option
                    v-for="(rep, index) in zinproReps"
                    :key="index"
                    :value="rep._id"
                  >
                    {{ rep.firstName }} {{ rep.lastName }}
                  </option>
                </select>
                <span
                  v-show="errors.has('representative')"
                  class="text-danger"
                >
                  <small>{{
                    "The Zinpro Representative field is required."
                      | translate
                  }}</small>
                </span>
              </div>
              <div v-else>
                <div class="loading loading--sm">
                  <div class="double-bounce1"></div>
                  <div class="double-bounce2"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-md-4">
            <div class="col-lg-12">
              <div class="card-body__top">
                <h2 class="h2">{{ "User Stats" | translate }}</h2>
              </div>
              <hr />

              <div class="row my-3" v-if="customer">
                <div class="col-lg-12" v-if="customer.createdAt">
                  <div class="form-group">
                    <label>{{
                      "Registration Date" | translate
                    }}</label>
                    <p>
                      {{ $store.getters.getDate(customer.createdAt) }}
                    </p>
                  </div>
                </div>
                <div
                  class="col-lg-12"
                  v-if="
                    customer.metadata &&
                    customer.metadata.lastSignInTime
                  "
                >
                  <div class="form-group">
                    <label>{{ "Last Login Date" | translate }}</label>
                    <p>
                      {{
                        $store.getters.getDate(
                          customer.metadata.lastSignInTime
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-show="errorCode !== ''"
          class="alert alert-danger mt-4 mb-0"
          role="alert"
        >
          <span v-if="errorCode === 'form/required-fields'">{{
            "Please fill out the required fields!" | translate
          }}</span>
          <span v-if="errorCode === 'csv-export/failed'">{{
            "This user's data cannot be exported to a CSV" | translate
          }}</span>
          <span v-if="errorCode === somethingWentWrong">{{
            this.errorMessage | translate
          }}</span>
        </div>

        <div
          v-show="success"
          class="alert alert-primary mt-4 mb-0"
          role="alert"
        >
          {{ successMessage | translate }}
        </div>

        <!-- show for representative, which have customer left -->
        <modal-confirm
          v-if="showDeleteUserModal"
          :headline="
            $t(
              'You cannot perform deletion because the user is a representative for other users.'
            )
          "
          yesText="OK"
          :showNoButton="false"
          @close="
            () => {
              showDeleteUserModal = false;
              confirmToDelete = false;
            }
          "
          :onClick="
            () => {
              showDeleteUserModal = false;
              confirmToDelete = false;
            }
          "
        ></modal-confirm>

        <!-- this is the confirmation popup will confirm that customer not get deleted accidentally -->
        <modal-confirm
          v-else-if="confirmToDelete"
          :headline="`${$t('Are you sure you want to delete')} ${
            customer.firstName
          } ${customer.lastName} ?`"
          :showNoButton="true"
          @close="confirmToDelete = false"
          yesText="Yes"
          :onClick="() => deleteCustomer('bottom')"
        ></modal-confirm>

        <!-- end card body -->

        <div class="row d-flex flex-column-reverse flex-md-row">
          <div class="col-md-18 d-flex flex-column flex-md-row">
            <router-link
              :to="routeToUserManagementScreen"
              class="btn btn-secondary btn-full--sm float-left mt-2 mt-md-4 order-1 order-md-1"
              >{{ "Back" | translate }}</router-link
            >
            <button
              v-if="user._id !== customer._id"
              @click="() => (confirmToDelete = true)"
              class="btn btn-secondary btn-full--sm mt-2 mt-md-4 mr-4 ml-md-4 order-2 order-md-2"
            >
              {{ "Delete" | translate }}
            </button>
          </div>
          <div class="col-md-6">
            <button
              @click="updateCustomer('bottom')"
              type="button"
              class="btn btn-primary btn-full--sm float-right mt-4"
              :disabled="loader"
            >
              {{ "Save Changes" | translate }}
              <span v-if="loader" class="loader ml-1 mr-2"></span>
            </button>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
    <!-- end container -->
  </div>
</template>

<script>
import Vue from "vue";
import { Validator } from "vee-validate";

// profile component
import generalProfile from "../../../components/admin/general.vue";
import _ from "lodash";
import ExportCsv from "../../../libs/ExportCsv/exportcsv";
import ModalConfirm from "@/components/misc/modal-confirm.vue";
import {
  // GET_USERS,
  GET_USERS_BY_ROLE_GETTER,
  GET_USERS_BY_ID_GETTER,
  GET_USER_BY_ID,
  GET_RESET_PASSWORD_EMAIL,
  GET_RESEND_INVITE,
  GET_UPDATE_USER,
  GET_DELETE_USER,
  GET_COMPANIES_BY_USER_ID,
  GET_UPDATE_CUSTOMER,
} from "../../../store/types";
import { mapActions, mapGetters, mapState } from "vuex";
import { ROLES } from "../../../constants";
import customerMixin from "./mixin";
import companyMixin from "@/mixins/companyMixin";

const axios = require("axios");

export default {
  components: {
    generalProfile,
    ModalConfirm,
  },
  props: ["data"],
  mixins: [customerMixin, companyMixin],
  data() {
    return {
      confirmToDelete: false,
      csvExportLoading: false,
      confirmEmailMessage: false,
      customer: {
        address: null,
        brandingImageBase64: "",
        brandingImageHeight: 0,
        brandingImageWidth: 0,
        city: null,
        clientId: null,
        company: null,
        companyName: null,
        countryCode: null,
        currency: null,
        disabled: false,
        email: null,
        firstName: null,
        lastName: null,
        measurements: null,
        phone: null,
        postalCode: null,
        prefix: null,
        representative: null,
        representativeName: null,
        role: null,
        stateProvince: null,
        title: null,
      },
      deleteMessage: false,
      deleteMessage2: false,
      error: false,
      errorMessage: "",
      errorCode: "",
      id: this.$route.params.id,
      isLoaded: false,
      loader: false,
      loader2: false,
      success: false,
      success2: false,
      successMessage: null,
      selectedRep: null,
      zinproReps: [],
      myCustomers: [],
      showDeleteUserModal: false,
      errorMessageText: "",
      somethingWentWrong: "somethingWentWrong",
    };
  },

  computed: {
    ...mapState({
      users: (state) => state.users.users,
      user: (state) => state.users.user,
    }),

    ...mapGetters("users", [
      GET_USERS_BY_ID_GETTER,
      GET_USERS_BY_ROLE_GETTER,
    ]),

    routeToUserManagementScreen() {
      let baseURL = `/admin/customer-management`;
      const { _id, representative } = this.customer;
      const {
        query: { isFilter },
      } = this.$route;
      if (
        this.user.role === "admin" &&
        this.customer?.customers?.length &&
        isFilter == 'true'
      ) {
        baseURL += `/${_id}`;
      } else if (representative && isFilter == 'true') {
        baseURL += `/${representative}`;
      }
      return baseURL;
    },
  },

  methods: {
    renderResetInviteSuccessMsg() {
      this.success2 = true;
      this.successMessage = "User invite has been sent!";
      this.loader2 = false;
      setTimeout(() => {
        this.success2 = false;
        this.successMessage = null;
        this.loader2 = false;
      }, 4000);
    },

    showSuccessMsgForUpdateUser() {
      this.success = true;
      this.loader = false;
      this.successMessage = "Updated Successfully";
      setTimeout(() => {
        this.success = false;
        this.successMessage = null;
        this.loader = false;
      }, 4000);
    },

    showValidationErrForUpdateUser() {
      this.errorCode = "form/required-fields";
      this.loader = false;
      setTimeout(() => {
        this.errorCode = "";
      }, 4000);
    },

    exportCsv() {
      this.csvExportLoading = true;

      let exportcsv = new ExportCsv();

      exportcsv.convertObjectToArrays(this.customer);
      this.csvExportLoading = false;
    },

    // getDate(timestamp) {
    //   let date = new Date(timestamp);
    //   var monthNames = [
    //     this.$t('Jan.'),
    //     this.$t('Feb.'),
    //     this.$t('Mar.'),
    //     this.$t('Apr.'),
    //     this.$t('May'),
    //     this.$t('Jun.'),
    //     this.$t('Jul.'),
    //     this.$t('Aug.'),
    //     this.$t('Sep.'),
    //     this.$t('Oct.'),
    //     this.$t('Nov.'),
    //     this.$t('Dec.'),
    //   ];
    //   var day = date.getDate();
    //   var monthIndex = date.getMonth();
    //   var year = date.getFullYear();
    //   return day + ' ' + monthNames[monthIndex] + ' ' + year;
    // },

    async updateCustomer() {
      this.loader = true;
      const { representativeName, representativeId } =
        this.getRepresentativeNameAndId();

      const customer = {
        ...this.customer,
        representative: representativeId,
        representativeName: representativeName,
        ...this.getCompanyName("customer"),
        brandingImageBase64:
          this.customer.brandingImageBase64 !== undefined
            ? this.customer.brandingImageBase64
            : "",
        brandingImageHeight:
          this.customer.brandingImageHeight !== undefined
            ? this.customer.brandingImageHeight
            : "",
        brandingImageWidth:
          this.customer.brandingImageWidth !== undefined
            ? this.customer.brandingImageWidth
            : "",
      };

      this.$validator
        .validateAll()
        .then(async (result) => {
          console.log(result);
          if (result === true) {
            await this[GET_UPDATE_CUSTOMER](customer);
            this.showSuccessMsgForUpdateUser();
          } else {
            this.showValidationErrForUpdateUser();
          }
        })
        .catch((error) => {
          this.errorCode = this.somethingWentWrong;
          this.errorMessage = error.message || defaultErr;
          this.loader = false;
          this.error = true;
          setTimeout(() => {
            this.errorMessage = false;
            this.errorCode = ''
          }, 4000);
        });
    },

    async resendInvite() {
      try {
        this.loader2 = true;

        const emailTo = {
          clientId: this.customer._id,
          email: this.customer.email,
          firstName: this.customer.firstName,
          lastName: this.customer.lastName,
          lang: this.user.lang,
        };

        await this[GET_RESEND_INVITE](emailTo);

        this.renderResetInviteSuccessMsg();
      } catch (error) {
        this.error = false;
        this.errorCode = this.somethingWentWrong;
        this.errorMessage = error.message;
        setTimeout(() => {
          this.error = false;
          this.errorCode = "";
          this.errorMessageText = "";
        }, 4000);
      }
    },

    removeBrandingImage(e) {
      this.customer.brandingImageBase64 = null;
      this.customer.brandingImageHeight = null;
      this.customer.brandingImageWidth = null;
    },

    async sendPasswordReset() {
      try {
        await this[GET_RESET_PASSWORD_EMAIL]({
          EMAIL: this.customer.email,
          LANG: this.user.lang,
        });
        this.confirmEmailMessage = true;
        setTimeout(() => {
          this.confirmEmailMessage = false;
        }, 4000);
      } catch (error) {
        
        this.error = false;
        this.errorCode = this.somethingWentWrong;
        this.errorMessage = error.message;
        setTimeout(() => {
          this.error = false;
          this.errorCode = "";
          this.errorMessageText = "";
        }, 4000);
      }
    },

    async deleteCustomer() {
      if (this.myCustomers.length > 0) {
        this.showDeleteUserModal = true;
        return;
      }
      this.isLoaded = false;
      await this[GET_DELETE_USER](this.customer._id)
        .then((res) => {
          this.$router.push({
            path: "/admin/customer-management",
            query: { error_code: "user-deleted" },
          });
        })
        .catch((error) => {
          
          this.error = false;
          this.isLoaded = true
          this.confirmToDelete = false
          this.showDeleteUserModal = false;
        this.errorCode = this.somethingWentWrong;
        this.errorMessage = error.message;
        this.showDeleteUserModal = false;
        setTimeout(() => {
          this.error = false;
          this.errorCode = "";
          this.errorMessageText = "";
        }, 4000);
        });
    },
    //Upload a photo to the local
    fileUpload(e) {
      // Function used to return
      const toBase64 = (url) =>
        fetch(url)
          .then((response) => response.blob())
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onerror = reject;
                reader.onloadend = () => resolve(reader.result);
              })
          );

      this.loader = true;

      //Getting the files from the vue upload
      var files = e.target.files;
      // Getting the first file
      var getFile = files[0]; // getFile is now a Blob
      if (getFile.size / 1024 / 1024 > 5) {
        // 5MB
        alert("This file is too large, must be less than 5MB");
        return;
      }
      //Creating the Url
      var url = URL.createObjectURL(getFile);

      let orientation;

      this.toDataURL(getFile)
        .then((processedImage) => {
          const maxWidth = 300;
          let aspectRatio =
            processedImage.width / processedImage.height;
          let height = maxWidth / aspectRatio;
          let base64 = processedImage.base64;

          if (processedImage.width > maxWidth) {
            base64 = this.$store.getters.resizeBase64(
              base64,
              300,
              height
            );
          }

          const orientationPromise = new Promise(
            (resolve, reject) => {
              this.$store.getters.getOrientation(
                getFile,
                (orientationNumber) => {
                  if (orientationNumber !== undefined) {
                    resolve(orientationNumber);
                  } else {
                    reject(Error("It broke"));
                    resolve(false);
                  }
                }
              );
            }
          )
            .then((result) => {
              if (result !== false) {
                orientation = result;
                this.$store.getters.resetOrientation(
                  base64,
                  orientation,
                  (resetBase64Image) => {
                    base64 = resetBase64Image;
                    this.customer.brandingImageBase64 = base64;
                    this.customer.brandingImageHeight = height;
                    this.customer.brandingImageWidth = maxWidth;
                  }
                );
              } else {
                this.customer.brandingImageBase64 = base64;
                this.customer.brandingImageHeight = height;
                this.customer.brandingImageWidth = maxWidth;
              }
            })
            .catch((err) => {
              console.log("ERROR #1", err); // Error: "It broke"
              this.loader = false;
            });
        })
        .catch((err) => {
          console.log("ERROR #2", err); // Error: "It broke"
          this.loader = false;
        });
    },
    //Converts an uploaded photo to base64
    toDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onloadend = (e) => {
          resolve({
            base64: e.target.result,
            id: this.$generateId(),
            comment: null,
            date_created: Date.now(),
          });
        };
        reader.readAsDataURL(file);
      }).then(
        (file) =>
          new Promise((resolve, reject) => {
            let image = new Image();
            image.src = file.base64;
            image.onload = () => {
              file.width = image.width;
              file.height = image.height;
              resolve(file);
            };
          })
      );
    },
    //Converts binary (DATA URI) from the database to a more uniform URL
    dataURItoBlob: function (dataURI) {
      var array, binary, i;

      //Gets the binary from the Data URI
      binary = atob(dataURI.split(",")[1]);
      array = [];
      i = 0;
      while (i < binary.length) {
        array.push(binary.charCodeAt(i));
        i++;
      }

      //Takes a blob and converts it to a url:
      return URL.createObjectURL(
        new Blob([new Uint8Array(array)], {
          type: "image/jpeg",
        })
      );
    },

    async fetchUsers() {
      try {
        this.isLoaded = false;
        this[GET_USERS]();
        this.isLoaded = true;
      } catch (error) {
        this.isLoaded = true;
      }
    },

    async getCurrentCustomerData() {
      try {
        this.isLoaded = false;
        const { id } = this.$route.params;
        let user = this[GET_USERS_BY_ID_GETTER](id);
        if (!user) {
          user = await this[GET_USER_BY_ID](id);
        }
        this.customer = user;
        this.isLoaded = true;
      } catch (error) {
        this.isLoaded = true;
      }
    },
    ...mapActions("users", [
      // GET_USERS,
      GET_USER_BY_ID,
      GET_RESET_PASSWORD_EMAIL,
      GET_UPDATE_USER,
      GET_RESEND_INVITE,
      GET_DELETE_USER,
      GET_UPDATE_CUSTOMER,
    ]),
  },
  created() {
    console.log(this.$route.query, typeof this.$route.query.isFilter);
    setTimeout(async () => {
      await Promise.all([this.getCurrentCustomerData()]);
      this.zinproReps = this[GET_USERS_BY_ROLE_GETTER](
        ROLES.zinpro_representative
      );
      this.myCustomers = this.users.filter((user) => {
        return user.representative === this.customer._id;
      });

      this.zinproReps.sort((a, b) => {
        return a.lastName.localeCompare(b.lastName);
      });
      this.myCustomers.sort((a, b) => {
        return a.lastName.localeCompare(b.lastName);
      });
    }, 1000);
  },
  // mounted() {
  //   this.fetchUsers();
  // },
};
</script>
