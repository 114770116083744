import { hygiene } from './hygiene';
import { hygiene2 } from './hygiene2';
import { footbath } from './footbath';
import { footbath2 } from './footbath2';
import { medias } from './../Global/medias.js';

import { group } from './group';

class HygieneFootbathAssessorSlides {

    constructor(app, report, evaluation, group, user) {
    	this.app = app;
    	this.report = report;
    	this.evaluation = evaluation;
		this.group = group;
		this.user = user;
	}

	add(tableOfContentsCalculatorPosition) {
        let assessor = this.group.getAssessorByType("1");

        if ( assessor === false || assessor.assessor.data === undefined ) {
            throw this.group.getName() + this.app.$t(" - ") + this.app.$t("Hygiene/Footbath assessor is not ready.");
        }

        this.report.tableOfContentsHelper("Hygiene/Footbath", tableOfContentsCalculatorPosition, this.group);

		hygiene(this.app, this.report, this.evaluation, this.group, assessor);
		hygiene2(this.app, this.report, this.evaluation, this.group, assessor);
		footbath(this.app, this.report, this.evaluation, this.group, assessor);
		footbath2(this.app, this.report, this.evaluation, this.group, assessor);
		medias(this.app, this.report, this.group, "1", this.user);
	}

    groupSummary(tableOfContentsCalculatorPosition) {
        group(this.app, this.report, this.evaluation);
    }
}

export default HygieneFootbathAssessorSlides;