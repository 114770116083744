<template>
  <div class="bar-chart">
    <div class="bar-chart__container">
      <canvas ref="chartRequirements"></canvas>
    </div>
  </div>
</template>

<script>
import { translationsMixin } from '@/mixins/translations.mixin.js';
import { animalMixin } from '@/mixins/animal.mixin.js';
import { Chart, registerables } from 'chart.js';

export default {
  name: 'BarChart',
  inject: ['assessorType'],
  mixins: [translationsMixin, animalMixin],
  props: {
    barChartData: Object,
  },
  watch: {
    barChartData: {
      handler () {
        this.$chart.destroy()
        this.drawChart()
      },
      deep: true
    }
  },
  data () {
    return {
      chart: null
    }
  },
  mounted() {
    this.drawChart()
  },
  methods: {
      /* 
        Data format example
        const data = {
          labels: [
            'Lying Space',
            'Stalls',
            'Headlocks',
            'Feeding Space',
            'Drinking Space',
          ],
          values: [50, 75, 100, 175, 150],
          min: 50,
          max: 150,
          stepSize: 25,
        };
      */
    drawChart () {
      Chart.register(...registerables);

      this.$chart = new Chart(this.$refs.chartRequirements, this.chartSetup());
    },

    chartSetup() {
      const data = this.barChartData

      const colors = {
        backgrounds: {
          high: 'rgb(204,0,0)',
          middle: 'rgb(255,204,0)',
          low: 'rgb(0,153,0)',
        },
        grid: {
          color: 'rgb(0,0,255)',
        },
        bar: {
          borderColor: 'rgba(223,223,223, 1)',
        },
        whiteSpace: 'rgba(223,223,223, 1)',
        text: 'rgb(0,0,0)',
      };

      const whiteSpaceFill = {
        id: 'whiteSpaceFill',
        beforeDraw: function(chart) {
          const {
            ctx,
            chartArea: { top, height },
          } = chart;

          const datasetMeta = chart.getDatasetMeta(0);

          datasetMeta.data.forEach(({ x, width }) => {
            ctx.save();

            let rectHeight = height,
              rectWidth = width,
              rectX = x - width / 2,
              rectY = top;

            ctx.fillStyle = colors.whiteSpace;
            ctx.fillRect(rectX, rectY, rectWidth, rectHeight);
          });

          ctx.restore();
        },
      };

      return {
        type: 'bar',
        plugins: [whiteSpaceFill],
        data: {
          datasets: [
            {
              data: data.values,
              backgroundColor: ctx => {
                let index = ctx.dataIndex;
                let value = ctx.dataset.data[index];
                return value < 100
                  ? colors.backgrounds.low
                  : value > 120
                  ? colors.backgrounds.high
                  : colors.backgrounds.middle;
              },
              minBarLength: 2,
              borderColor: colors.bar.borderColor,
              borderWidth: 5,
              order: 1,
            },
          ],
        },
        options: {
          aspectRatio: 1.3,
          responsive: true,
          plugins: {
            tooltip: {
              filter: function(tooltip) {
                const data = tooltip.dataset.data;
                const index = tooltip.datasetIndex;
                return data[index] === 175 ? false : true;
              },
            },
            legend: false,
          },
          scales: {
            x: {
              position: 'top',
              stacked: true,
              labels: data.labels,
              title: {
                display: Boolean(data.titleX),
                align: 'start',
                text: data.titleX,
                color: colors.text,
                font: {
                  weight: 'bold',
                },
                padding: { top: 20 },
              },
              grid: {
                borderWidth: 0,
                display: false,
                drawTicks: false,
              },
              ticks: {
                color: colors.text,
                padding: 15,
              },
            },
            y: {
              type: 'linear',
              stacked: true,
              offset: true,
              beginAtZero: true,
              min: data.min,
              max: data.max,
              title: {
                display: Boolean(data.titleY),
                position: 'left',
                text: data.titleY,
                color: colors.text,
                font: {
                  weight: 'bold',
                },
                padding: { top: 20, bottom: 20 },
              },
              grid: {
                borderWidth: 0,
                drawTicks: false,
                color: colors.grid.color,
                borderDash: [3],
                borderDashOffset: 10,
              },
              ticks: {
                stepSize: data.stepSize,
                callback(v) {
                  return v + '%';
                },
                minRotation: 90,
                maxRotation: 90,
                color: colors.text,
                crossAlign: 'center',
                padding: 10,
              },
            },
          },
        },
      };
    },
  },
};
</script>
