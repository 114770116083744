import axios from '../../../Axios';
import Vue from 'vue';
import {
  GET_CREATE_GROUP,
  GET_DELETE_GROUP,
  SET_CREATE_GROUP,
  SET_DELETE_GROUP,
  SET_EVALUATION_GROUP,
  SET_EVALUATION_GROUP_DELETE,
} from '../types';
import { URLS } from '../../constants';
const state = {
  groups: {
    id: null,
    name: null,
    dateCreated: null,
    dateUpdated: null,
    evaluationId: null,
  },
  groups: [],
};

const getters = {};

const mutations = {
  [SET_CREATE_GROUP](STATE, GROUP) {
    STATE.groups.push(GROUP);
  },
};
const actions = {
  [GET_CREATE_GROUP]({ commit: COMMIT }, { group, evaluationId }) {
    COMMIT(
      `evaluations/${SET_EVALUATION_GROUP}`,
      { ...group, evaluationId },
      { root: true }
    );
    return new Promise((resolve, reject) => {
      axios
        .post(`${URLS.GROUP}/${evaluationId}`, group)
        .then(function(groupResponse) {
          return resolve(group.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_DELETE_GROUP]({ commit: COMMIT }, { groupId, evaluationId }) {
    COMMIT(
      `evaluations/${SET_EVALUATION_GROUP_DELETE}`,
      { groupId, evaluationId },
      { root: true }
    );
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URLS.EVALUATION}/${evaluationId}/group/${groupId}`)
        .then(function(group) {
          // COMMIT(SET_DELETE_GROUP, group.data.data);
         
          return resolve(group.data.data);
        })
        .catch(function(err) {
          reject(err.response ? err.response.data : err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
