
import FootbathSlide from './FootbathSlide';

const Table = require('../../Misc/Table');

export const footbath2 = (app, report, evaluation, group, assessor) => {

    let newFootbath = assessor.getNewFootbath();

    if (newFootbath === undefined ) return false; 

    let footbathSlide = new FootbathSlide(app, report, evaluation, group);
    footbathSlide.addSlide(newFootbath, true);
}