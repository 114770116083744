<template>
  <div>
    <div>
      <p>SMTP setting</p>
      <hr />
    </div>
    <div class="mt-4">
      <p class="verification-p-h">{{ 'Sender Address' | translate }}</p>
      <input
        class="verification-input-long p-2"
        v-model="SenderAddress"
        placeholder="noreplymail.zinprofirststep.com"
        type="text"
      />
    </div>
    <div class="mt-4">
      <p class="verification-p-h">SMTP server host</p>
      <input class="verification-input-long p-2" v-model="ServerHost" placeholder="smtp.mailgun.org" type="text" />
    </div>
    <div class="mt-4">
      <p class="verification-p-h">{{ 'SMTP server port' | translate }}</p>
      <input class="verification-input-long p-2" v-model="ServerPort" placeholder="587" type="text" />
    </div>
    <div class="mt-4">
      <p class="verification-p-h">SMTP account username</p>
      <input
        class="verification-input-long p-2"
        v-model="Username"
        placeholder="noreplymail.zinprofirststep.com"
        type="text"
      />
    </div>
    <div class="mt-4">
      <p class="verification-p-h">{{ 'SMTP account password' | translate }}</p>
      <input
        class="verification-input-long p-2"
        v-model="Password"
        placeholder="noreplymail.zinprofirststep.com"
        type="password"
      />
    </div>
    <div class="mt-4">
      <p class="verification-p-h">{{ 'SMTP security mode' | translate }}</p>
      <input class="verification-input-long p-2" v-model="SecurityMode" type="text" />
    </div>
    <div class="submit-div">
      <button type="submit" class="btn-template btn-primary" @click="SmtpDataSave()">{{ 'Save' | translate }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmtpSetting',
  data() {
    return {
      SecurityMode: '',
      Password: '',
      Username: '',
      ServerPort: '',
      ServerHost: '',
      SenderAddress: '',
    };
  },
  methods: {
    SmtpDataSave() {
      console.log(
        this.SecurityMode,
        this.Password,
        this.Username,
        this.ServerPort,
        this.ServerHost,
        this.SenderAddress
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
