export const GET_SIGN_IN_USER = 'GET_SIGN_IN_USER';
export const SET_SIGN_IN_USER = 'SET_SIGN_IN_USER';

export const GET_LOGOUT_USER = 'GET_LOGOUT_USER';
export const SET_LOGOUT_USER = 'SET_LOGOUT_USER';

export const GET_INVITE_CUSTOMER = 'GET_INVITE_CUSTOMER';
export const SET_INVITE_CUSTOMER = 'SET_INVITE_CUSTOMER';

export const GET_CREATE_USER = 'GET_CREATE_USER';
export const SET_CREATE_USER = 'SET_CREATE_USER';

export const GET_REQUEST_EMAIL_FORGOT_PASSWORD =
  'GET_REQUEST_EMAIL_FORGOT_PASSWORD';
export const SET_REQUEST_EMAIL_FORGOT_PASSWORD =
  'SET_REQUEST_EMAIL_FORGOT_PASSWORD';

export const GET_USER_LOGGED_FROM_LOCAL_STORAGE =
  'GET_USER_LOGGED_FROM_LOCAL_STORAGE';
export const SET_USER_LOGGED_FROM_LOCAL_STORAGE =
  'SET_USER_LOGGED_FROM_LOCAL_STORAGE';

export const GET_NEW_COMPANY = 'GET_NEW_COMPANY';
export const SET_NEW_COMPANY = 'SET_NEW_COMPANY';

export const GET_COMPANIES = 'GET_COMPANIES';
export const SET_COMPANIES = 'SET_COMPANIES';

export const GET_COMPANY_UPDATE = 'GET_COMPANY_UPDATE';
export const SET_COMPANY_UPDATE = 'SET_COMPANY_UPDATE';

export const GET_COMPANY_BY_ID = 'GET_COMPANY_BY_ID';
export const SET_COMPANY_BY_ID = 'SET_COMPANY_BY_ID';

export const GET_COMPANY_DELETE = 'GET_COMPANY_DELETE';
export const SET_COMPANY_DELETE = 'SET_COMPANY_DELETE';

export const GET_RESET_PASSWORD_EMAIL = 'GET_RESET_PASSWORD_EMAIL';
export const SET_RESET_PASSWORD_EMAIL = 'SET_RESET_PASSWORD_EMAIL';

export const GET_RESEND_INVITE = 'GET_RESEND_INVITE';
export const SET_RESEND_INVITE = 'SET_RESEND_INVITE';

export const GET_UPDATE_USER = 'GET_UPDATE_USER';
export const SET_UPDATE_USER = 'SET_UPDATE_USER';

export const GET_CHANGE_PASSWORD = 'GET_CHANGE_PASSWORD';
export const SET_CHANGE_PASSWORD = 'SET_CHANGE_PASSWORD';

// USERS TYPES
export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';

export const GET_CUSTOMER_BY_ID = 'GET_CUSTOMER_BY_ID';
export const SET_CUSTOMER_BY_ID = 'SET_CUSTOMER_BY_ID';
export const GET_UPDATE_CUSTOMER = 'GET_UPDATE_CUSTOMER';
export const SET_UPDATE_CUSTOMER = 'SET_UPDATE_CUSTOMER';

export const GET_DELETE_USER = 'GET_DELETE_USER';
export const SET_DELETE_USER = 'SET_DELETE_USER';

export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const SET_USER_BY_ID = 'SET_USER_BY_ID';

export const GET_UNVERIFIED_USER = 'GET_UNVERIFIED_USER';
export const SET_UNVERIFIED_USER = 'SET_UNVERIFIED_USER';

export const GET_UPDATE_UNVERIFIED_USER = 'GET_UPDATE_UNVERIFIED_USER';
export const SET_UPDATE_UNVERIFIED_USER = 'SET_UPDATE_UNVERIFIED_USER';

export const GET_VERIFY_USER_PASSWORD = 'GET_VERIFY_USER_PASSWORD';
export const SET_VERIFY_USER_PASSWORD = 'SET_VERIFY_USER_PASSWORD';

export const GET_UPLOAD_PROFILE_IMAGE = 'GET_UPLOAD_PROFILE_IMAGE';
export const SET_UPLOAD_PROFILE_IMAGE = 'SET_UPLOAD_PROFILE_IMAGE';

export const GET_UPDATE_ME = 'GET_UPDATE_ME';
export const SET_UPDATE_ME = 'SET_UPDATE_ME';

export const GET_REPRESENTATIVE_USERS = "GET_REPRESENTATIVE_USERS";
export const SET_REPRESENTATIVE_USERS = "SET_REPRESENTATIVE_USERS";

// GETTER TYPES
export const GET_USERS_BY_ROLE_GETTER = 'GET_USERS_BY_ROLE_GETTER';
export const GET_USERS_BY_ID_GETTER = 'GET_USERS_BY_ID_GETTER';

export const SET_UPDATE_LOGIN_USER = 'SET_UPDATE_LOGIN_USER';

export const GET_COMPANIES_BY_USER_ID = 'GET_COMPANIES_BY_USER_ID';


