<!-- @format -->

<template>
  <div class="container main-container main-container--hygiene view">
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">
          {{ "Tell Us About Your Dirt Lot" | translate }}
        </h2>
        <modal-info :value="'tell-us'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ "Dirt Lot" | translate }}
            </h2>
            <p class="px-3">
              {{
                "For more information about Dirt Lots, click the link to learn more from "
                  | translate
              }}
              <a
                :href="
                  $t('https://library.zinprofirststep.com/dirt-lot')
                "
                @click="$goToLameness($t('/dirt-lot/'), $event)"
                target="_blank"
                >{{ "FirstStep Resources" | translate }}</a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ "Cancel" | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </div>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-xl-12 col-md-13 d-md-flex align-self-center">
        <div class="mb-1">
          <label class="label d-inline">{{
            $t("What is the corral area per cow ") +
            "(" +
            $t(
              $getEquivalentUnit(
                evaluation.data.measurements,
                "square meters"
              )
            ) +
            ")?"
          }}</label>
        </div>
      </div>
      <div
        class="col-xl-12 col-md-11 d-flex align-self-center justify-content-between justify-content-md-end flex-row"
      >
        <input
          v-model.number="converted_corral_area_per_cow"
          type="number"
          inputmode="decimal"
          class="form-control"
        />
      </div>
    </div>

    <hr />

    <question
      @change="
        assessor.data.at_least_one_headlock_per_cow = $event.value
      "
      :initial-value="assessor.data.at_least_one_headlock_per_cow"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t("Is there at least one headlock (feed space) per cow?")
        }}</label>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.concrete_feeding_area_scraped_at_least_once_per_day =
          $event.value
      "
      :initial-value="
        assessor.data
          .concrete_feeding_area_scraped_at_least_once_per_day
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t(
            "Is the concrete feeding area scraped at least once a day?"
          )
        }}</label>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.bedding_beneath_free_of_wet_manure_contamination =
          $event.value
      "
      :initial-value="
        assessor.data.bedding_beneath_free_of_wet_manure_contamination
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t(
            "Is the bedding beneath the shade dry and free of wet manure contamination?"
          )
        }}</label>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.at_least_3_inches_of_loose_manure_in_lying_area =
          $event.value
      "
      :initial-value="
        assessor.data.at_least_3_inches_of_loose_manure_in_lying_area
      "
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          evaluation.data.measurements === "metric"
            ? "Is there at least 7.5 cm of loose dirt/dried manure in the lying area?"
            : "Is there at least 3 in of loose dirt/dried manure in the lying area?" | translate
        }}</label>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.lying_area_pass_knee_drop_test = $event.value
      "
      :initial-value="assessor.data.lying_area_pass_knee_drop_test"
    >
      <div class="mb-1">
        <label class="label d-inline">{{
          $t("Does the lying area pass the knee drop test?")
        }}</label>
        <modal-info :value="'knee-drop'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{
                "Does the lying area pass the knee drop test?"
                  | translate
              }}
            </h2>
            <div class="row px-3 mb-3">
              <div class="col-md-12 mb-3 mb-lg-0">
                <img
                  src="@/assets/img/Dirt_Lot_Knee-Drop_Standing.png"
                  alt="Example Kneel Down 2"
                  class="w-100"
                />
              </div>
              <div class="col-md-12">
                <img
                  src="@/assets/img/Dirt_Lot_Knee-Drop_Kneeling.png"
                  alt="Example Kneel Down 1"
                  class="w-100"
                />
              </div>
            </div>
            <p class="px-3 mb-2">
              {{
                "Crouch slightly and then drop to your knees in the lying area.  If you can do this comfortably and without feeling pain, then the lying area has adequate bedding and passes the knee-drop test."
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ "Cancel" | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </question>

    <div class="row">
      <div class="col-lg-24">
        <hr />
      </div>
    </div>

    <div class="row my-0">
      <div class="col-md-12">
        <personal-notes
          v-model="assessor.data.personal_notes"
        ></personal-notes>
      </div>
      <div class="col-md-12">
        <presentation-notes
          v-model="assessor.data.presentation_notes"
        ></presentation-notes>
      </div>
    </div>
    <div
      class="row mx-0 mt-4 justify-content-center justify-content-md-start center align-items-center"
    >
      <photoUpload :assessorId="5" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

    <div class="row misc-bottom">
      <div class="col-md-12 flex-sm-row-reverse">
        <a
          class="btn btn-secondary btn-full--sm mb-2 mt-md-0"
          href="javascript:history.go(-1)"
          >{{ "Back" | translate }}</a
        >
      </div>
      <div class="col-md-12">
        <router-link
          v-if="user && user.role !== 'data_entry'"
          :to="'/reports'"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ "Reports" | translate }}
        </router-link>
        <button
          v-else-if="
            user &&
            user.role === 'data_entry' &&
            !$store.state.offline
          "
          data-toggle="modal"
          @click="requestReport(evaluation.name)"
          :data-target="['#requestSent-' + user.id]"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ "Request Report" | translate }}
          <span
            v-show="requestReportLoader"
            class="loader ml-1"
          ></span>
        </button>
      </div>
    </div>

    <div
      v-show="successMessage"
      class="alert alert-primary mt-3 mb-0"
      role="alert"
    >
      {{ "Your request has been sent!" | translate }}
    </div>

    <div
      v-show="errorMessage"
      class="alert alert-danger mt-3 mb-0"
      role="alert"
    >
      {{ "An error has occurred during your request!" | translate }}
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import PresentationNotes from "./assessor_components/presentation-notes";
import PersonalNotes from "./assessor_components/personal-notes";
import Question from "./assessor_components/question";
import ModalInfo from "@/components/misc/modal-info.vue";
import PhotoUpload from "@/components/misc/photoUpload";
import HygieneHeader from "./assessor_components/hygiene-header.vue";
import DirtLot from "@/libs/Classes/DirtLot.js";
import Evaluation from "@/libs/Classes/Evaluation.js";
import Group from "@/libs/Classes/Group.js";
import convert from "convert-units";
import requestReportMixin from "@/mixins/requestReport.js";

export default {
  components: {
    PersonalNotes,
    PresentationNotes,
    Question,
    HygieneHeader,
    PhotoUpload,
    ModalInfo,
  },
  mixins: [requestReportMixin],
  props: ["assessor", "group", "evaluation"],
  data() {
    return {
      dirtlot: null,
    };
  },
  computed: {
    converted_corral_area_per_cow: {
      get: function () {
        return this.dirtlot.getCorralAreaPerCow(false);
      },
      set: function (newValue) {
        return this.dirtlot.setCorralAreaPerCow(newValue);
      },
    },
  },
  created() {
    let evaluationClass = new Evaluation(this, this.evaluation);
    let groupClass = new Group(this, evaluationClass, this.group);
    this.dirtlot = new DirtLot(
      this,
      evaluationClass,
      this.groupClass,
      this.assessor
    );

    console.log("dirt lot data", this.assessor.data);
  },
  methods: {
    // requestReport(evaluationName) {
    //   this.$store.dispatch('requestReport', {
    //     currentVueInstance: this,
    //     evaluationName: evaluationName
    //   })
    // }
  },
};
</script>
