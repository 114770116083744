<template>
  <!-- main-container -->
  <div class="view-form">
    <div
      class="container  main-container--login "
      :style="{background: 'none', marginTop:'0 !important'}"
    >
      <div class="row main-container--login_gray">
        <div class="col pt-4">
           <h1 class="h1 text-center mb-3">
            {{ 'Forgot Password?' | translate }}
          </h1>
          <p class="p--login mb-4 pl-3 pr-3">
            {{
              'Please enter the email address associated with your account. The system will send an email with a link to reset your password. Please make sure to check your spam filters for the email.'
              | translate
            }}
          </p>
    
          <div v-if="confirmMessage" class="alert alert-primary" role="alert">
            {{ 'Email sent' | translate }}
          </div>
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage | translate }}
          </div>
          <div class="card main-card">
            <div class="card-body p-0 p-md-3">
              <form autocomplete="off" @submit.prevent="submit" method="post">
                <div class="form-group">
                  <label for="username" class="login__label">{{
                    'Email Address' | translate
                  }}</label>
                  <input
                    type="text"
                    class="form-control mb-0"
                    id="username"
                    aria-describedby="username"
                    v-model="email"
                    :placeholder="'Email Address' | translate"
                  />
                </div>
                <div class="row mx-0 d-flex justify-content-end mg-md">
                  <button type="submit" class="btn btn-secondary btn-full--sm">
                    {{ 'Submit' | translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader';
import AppFooter from '../components/AppFooter';
import { GET_REQUEST_EMAIL_FORGOT_PASSWORD } from '../store/types';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      email: '',
      confirmMessage: false,
      errorMessage: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.users.user,
    }),
  },
  methods: {
    ...mapActions('users', [GET_REQUEST_EMAIL_FORGOT_PASSWORD]),
    generateErrorMsg({ errors, message = '' }) {
      return errors ? Object.values(errors).join(', ') : message;
    },
    
    async submit() {
      const localStorageUser = localStorage.getItem('lang') || 'en';
      try {
        this.errorMessage = '';
        await this[GET_REQUEST_EMAIL_FORGOT_PASSWORD]({
          EMAIL: this.email,
          LANG: localStorageUser,
        });
        this.confirmMessage = true;
      } catch (error) {
        this.confirmMessage = false;
        this.errorMessage = this.generateErrorMsg(error);;
      }
    },
  },
};
</script>
