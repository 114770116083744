import convert from 'convert-units'
import constantValues from '../../../Classes/constants/transition.js'
const Table = require('../../Misc/Table');

export const transition = (app, report, evaluation, group, assessor) => {
    let slide = report.pptx.addSlide('Slide master');
    let slide2 = null;
    const language = self.app.$store.state.i18n.locale;
    slide.addText(
        app.$t('Transition Assessor'), report.styleOptions.slideHeader
    );

    let tableBody = []

    tableBody.push([{
        text: app.$t('Index'),
        options: {
            ...report.styleOptions.tableHeader,
            // rowspan: 2
        }
    },
    {
        text: app.$t('10th'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('25th'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('50th'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('75th'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('90th'),
        options: report.styleOptions.tableHeader
    }
    // {
    //     text: app.$t('Industry Percentile'),
    //     options: {
    //         ...report.styleOptions.tableHeader,
    //         colspan: 5
    //     },
    // }
]);
    // tableBody.push([
       
    // ])

    // let tableHead = tableBody
    // tableBody = []
    // Projected First Test 305-Day Milk
    const projected_first_test = assessor.generate5ValueRow('projected_first_test_305', 'kg', 'ltr', true)
    if (projected_first_test.length > 0) {
        tableBody.push([
            {
                text: app.$t('Projected First Test 305-Day Milk') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')) + ')',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: convert(constantValues['projected_first_test_305_min']).from('kg').to(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')).toDecimal(0),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: convert(constantValues['projected_first_test_305_mid']).from('kg').to(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')).toDecimal(0),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: convert(constantValues['projected_first_test_305_max']).from('kg').to(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')).toDecimal(0),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }
        ])
        tableBody.push([
            {
                text: app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: false
                }
            },
            ...projected_first_test
        ])
    }

    // Peak Ratio
    const peak_ratio = assessor.generate5ValueRow('peak_ratio', '%', 'ltr', true)
    if (peak_ratio.length > 0) {
        tableBody.push([
            {
                text: app.$t('Peak Ratio') + ' %',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t('<73%'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t('76%'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t('>78%'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }
        ])
        tableBody.push([
            {
                text: app.$t('Ratio of 1st to ≥ 2nd Lactation Milk Production Peaks (%)'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: false
                }
            },
            ...peak_ratio
        ])
    }

    // First test fat-protein ratio
    const first_test_fat_protein_ratio_1 = assessor.generate5ValueRow('first_test_fat_protein_ratio_1', '%', 'rtl', true)
    const first_test_fat_protein_ratio_2 = assessor.generate5ValueRow('first_test_fat_protein_ratio_2', '%', 'rtl', true)
    if (first_test_fat_protein_ratio_1.length > 0 || first_test_fat_protein_ratio_2.length > 0) {
        tableBody.push([
            {
                text: app.$t('% First Test Fat-Protein Ratio'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '>' + constantValues['first_test_fat_protein_ratio_1_max'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: constantValues['first_test_fat_protein_ratio_1_mid'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '<' + constantValues['first_test_fat_protein_ratio_1_min'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }
        ])
        // 1st Lactation, % >1.4
        if (first_test_fat_protein_ratio_1.length > 0) {
            tableBody.push([
                {
                    text: app.$t('1st Lactation, % >1.4'),
                    options: {
                        color: '000000',
                        valign: 'center',
                        align: 'left',
                        fill: 'ffffff',
                        fontFace: 'Arial',
                        margin: 5,
                        bold: false
                    }
                },
                ...first_test_fat_protein_ratio_1
            ])
        }
        // ≥ 2nd Lactation, % >1.4
        if (first_test_fat_protein_ratio_2.length > 0) {
            tableBody.push([
                {
                    text: app.$t('≥ 2nd Lactation, % >1.4'),
                    options: {
                        color: '000000',
                        valign: 'center',
                        align: 'left',
                        fill: 'ffffff',
                        fontFace: 'Arial',
                        margin: 5,
                        bold: false
                    }
                },
                ...first_test_fat_protein_ratio_2
            ])
        }
    }

    // SCC at First Test (5 to 40 DIM)
    const scc_at_first_test_1 = assessor.generate5ValueRow('scc_at_first_test_1', '%', 'rtl', true)
    const scc_at_first_test_2 = assessor.generate5ValueRow('scc_at_first_test_2', '%', 'rtl', true)
    if (scc_at_first_test_1.length  > 0 || scc_at_first_test_2.length > 0) {
        tableBody.push([
            {
                text: app.$t('SCC at First Test (5 to 40 DIM)'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '>' + constantValues['scc_at_first_test_1_max'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: constantValues['scc_at_first_test_1_mid'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '<' + constantValues['scc_at_first_test_1_min'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }
        ])

        // 1st Lactation, % ≥200,000
        if (scc_at_first_test_1.length  > 0) {
            tableBody.push([
                {
                    text: app.$t('1st Lactation, % ≥200,000'),
                    options: {
                        color: '000000',
                        valign: 'center',
                        align: 'left',
                        fill: 'ffffff',
                        fontFace: 'Arial',
                        margin: 5,
                        bold: false
                    }
                },
                ...scc_at_first_test_1
            ])
        }

        // ≥ 2nd Lactation, % ≥200,000
        if (scc_at_first_test_2.length > 0) {
            tableBody.push([
                {
                    text: app.$t('≥ 2nd Lactation, % ≥200,000'),
                    options: {
                        color: '000000',
                        valign: 'center',
                        align: 'left',
                        fill: 'ffffff',
                        fontFace: 'Arial',
                        margin: 5,
                        bold: false
                    }
                },
                ...scc_at_first_test_2
            ])
        }
    }


    // Stillbirths, 1st Lactation
    const stillbirths_1 = assessor.generate5ValueRow('stillbirths_1', '%', 'rtl', true)
    if (stillbirths_1.length > 0) {
        tableBody.push([
            {
                text: app.$t('% Stillbirths, 1st Lactation'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '>' + constantValues['stillbirths_1_max'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: constantValues['stillbirths_1_mid'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '<' + constantValues['stillbirths_1_min'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }
        ])
        // % Stillbirths, 1st Lactation
        tableBody.push([
            {
                text: app.$t('% Stillbirths, 1st Lactation'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: false
                }
            },
            ...stillbirths_1
        ])
    }


    // Stillbirths, ≥ 2nd Lactation
    const stillbirths_2 = assessor.generate5ValueRow('stillbirths_2', '%', 'rtl', true)
    if (stillbirths_2.length > 0) {
        tableBody.push([
            {
                text: app.$t('% Stillbirths, ≥ 2nd Lactation'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '>' + constantValues['stillbirths_2_max'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: constantValues['stillbirths_2_mid'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '<' + constantValues['stillbirths_2_min'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }
        ])
        // % Stillbirths, ≥ 2nd Lactation
        tableBody.push([
            {
                text: app.$t('% Stillbirths, ≥ 2nd Lactation'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: false
                }
            },
            ...stillbirths_2
        ])
    }


    // Dry Period "Cure" Rate
    const dry_period_cure_rate = assessor.generate5ValueRow('dry_period_cure_rate', '%', 'ltr', true)
    if (dry_period_cure_rate.length > 0) {
        tableBody.push([
            {
                text: app.$t('Dry Period "Cure" Rate'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '<' + constantValues['dry_period_cure_rate_min'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: constantValues['dry_period_cure_rate_mid'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '>' + constantValues['dry_period_cure_rate_max'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }
        ])
        tableBody.push([
            {
                text: app.$t('% Cows ≥200,000 at Dry Off, < 200,000 at 1st Test'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: false
                }
            },
            ...dry_period_cure_rate
        ])
    }


    // Turnover % (Sold + Died) ≤ 60 DIM
    const turnover_sold_died = assessor.generate5ValueRow('turnover_sold_died', '%', 'rtl', true)
    if (turnover_sold_died.length > 0) {
        tableBody.push([
            {
                text: app.$t('Turnover % (Sold + Died) ≤ 60 DIM'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '>' + constantValues['turnover_sold_died_max'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: constantValues['turnover_sold_died_mid'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '<' + constantValues['turnover_sold_died_min'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }
        ])
        tableBody.push([
            {
                text: app.$t('Turnover % (Sold + Died) ≤ 60 DIM'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: false
                }
            },
            ...turnover_sold_died
        ])
    }


    // % Death Loss, ≤ 60 DIM
    const deaths = assessor.generate5ValueRow('deaths', '%', 'rtl', true)
    if (deaths.length > 0) {
        tableBody.push([
            {
                text: app.$t('% Death Loss, ≤ 60 DIM'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '>' + constantValues['deaths_max'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: constantValues['deaths_mid'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: app.$t(''),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }, {
                text: '<' + constantValues['deaths_min'] + '%',
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'center',
                    fill: 'dddddd',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: true
                }
            }
        ])
        tableBody.push([
            {
                text: app.$t('% Death Loss, ≤ 60 DIM'),
                options: {
                    color: '000000',
                    valign: 'center',
                    align: 'left',
                    fill: 'ffffff',
                    fontFace: 'Arial',
                    margin: 5,
                    bold: false
                }
            },
            ...deaths
        ])
    }
    if (tableBody.length > 0) {
        slide.addText(
          `${app.$t('Transition Performance Parameters')} - ${app.$t('Industry Percentile')}`, {
              x: 0.5,
              y: 2.38,
              ...report.styleOptions.subheaderBlue
          }
        );

        Table.create(slide, null, tableBody, report, group, 'Transition Assessor', {
            ...report.styleOptions.tableOptions,
            y: 2.7,
            colW: [4, .7, .7, .7, .7, .7],
        });

        slide2 = report.pptx.addSlide('Slide master');
        slide2.addText(
          app.$t('Transition Assessor'), report.styleOptions.slideHeader
        );
    } else {
        slide2 = slide
    }

    slide2.addText(
        `${app.$t('Disease Rate (%)')} - ${app.$t('Industry Percentile')}` , {
            x: 0.5,
            y: 2.38,
            ...report.styleOptions.subheaderBlue
        }
    );

    let tableBody2 = []
    let diseaseRateHeader = []
    diseaseRateHeader.push([{
        text: app.$t('Disease'),
        options: {
            ...report.styleOptions.tableHeader,
            // rowspan: 2
        }
    },
    {
        text: app.$t('10th'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('25th'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('50th'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('75th'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('90th'),
        options: report.styleOptions.tableHeader
    }
    //  {
    //     text: app.$t('Industry Percentile'),
    //     options: {
    //         ...report.styleOptions.tableHeader,
    //         colspan: 5
    //     }
    // }
]);
    // diseaseRateHeader.push([
        
    // ])
    tableBody2 = diseaseRateHeader
    // Clinical Milk Fever (for lact ≥2 only)
    tableBody2.push([
        {
            text: app.$t('Clinical Milk Fever (for lact ≥2 only)'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '>' + constantValues['clinical_milk_fever_max'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: constantValues['clinical_milk_fever_mid'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '<' + constantValues['clinical_milk_fever_min'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }
    ])
    // Herd clinical milk fever rate (cases in 2+ lact in last yr/2+ lact fresh in last yr)
    tableBody2.push([
        {
            text: app.$t('Herd clinical milk fever rate (cases in 2+ lact in last yr/2+ lact fresh in last yr)'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'ffffff',
                fontFace: 'Arial',
                margin: 5,
                bold: false
            }
        },
        ...assessor.generate5ValueRow('clinical_milk_fever', '%', 'rtl-slider', true)
    ])

    // Retained Placenta
    tableBody2.push([
        {
            text: app.$t('Retained Placenta'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '>' + constantValues['retained_placenta_max'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: constantValues['retained_placenta_mid'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '<' + constantValues['retained_placenta_min'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }
    ])
    // Herd retained placenta rate (cases in last yr / fresh in last yr)
    tableBody2.push([
        {
            text: app.$t('Herd retained placenta rate (cases in last yr / fresh in last yr)'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'ffffff',
                fontFace: 'Arial',
                margin: 5,
                bold: false
            }
        },
        ...assessor.generate5ValueRow('retained_placenta', '%', 'rtl-slider', true)
    ])

    // Metritis
    tableBody2.push([
        {
            text: app.$t('Metritis'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '>' + constantValues['metritis_max'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: constantValues['metritis_mid'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '<' + constantValues['metritis_min'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }
    ])
    // Herd retained placenta rate (cases in last yr / fresh in last yr)
    tableBody2.push([
        {
            text: app.$t('Herd metritis rate (cases in last yr / fresh in last yr)'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'ffffff',
                fontFace: 'Arial',
                margin: 5,
                bold: false
            }
        },
        ...assessor.generate5ValueRow('metritis', '%', 'rtl-slider', true)
    ])

    // Displaced Abomasum
    tableBody2.push([
        {
            text: app.$t('Displaced Abomasum'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '>' + constantValues['displaced_abomasum_max'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: constantValues['displaced_abomasum_mid'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '<' + constantValues['displaced_abomasum_min'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }
    ])
    // Herd displaced abomasum rate (cases ≤60 DIM in last year/fresh in last year)
    tableBody2.push([
        {
            text: app.$t('Herd displaced abomasum rate (cases ≤60 DIM in last year/fresh in last year)'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'ffffff',
                fontFace: 'Arial',
                margin: 5,
                bold: false
            }
        },
        ...assessor.generate5ValueRow('displaced_abomasum', '%', 'rtl-slider', true)
    ])

    // Pneumonia
    tableBody2.push([
        {
            text: app.$t('Pneumonia'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '>' + constantValues['pneumonia_max'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: constantValues['pneumonia_mid'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '<' + constantValues['pneumonia_min'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }
    ])
    // Herd Pneumonia rate (cases ≤60 DIM in last yr / fresh in last yr)
    tableBody2.push([
        {
            text: app.$t('Herd Pneumonia rate (cases in last year/fresh in last year)'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'ffffff',
                fontFace: 'Arial',
                margin: 5,
                bold: false
            }
        },
        ...assessor.generate5ValueRow('pneumonia', '%', 'rtl-slider', true)
    ])

    // Enteritis
    tableBody2.push([
        {
            text: app.$t('Enteritis'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '>' + constantValues['enteritis_max'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: constantValues['enteritis_mid'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '<' + constantValues['enteritis_min'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }
    ])
    // Herd enteritis rate (cases ≥ 60 DIM in last year/fresh in last year)
    tableBody2.push([
        {
            text: app.$t('Herd enteritis rate (cases ≤ 60 DIM in last year/fresh in last year)'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'ffffff',
                fontFace: 'Arial',
                margin: 5,
                bold: false
            }
        },
        ...assessor.generate5ValueRow('enteritis', '%', 'rtl-slider', true)
    ])

    // Ketosis (assuming intense detection)
    tableBody2.push([
        {
            text: app.$t('Ketosis (assuming intense detection)'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '>' + constantValues['ketosis_max'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: constantValues['ketosis_mid'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: app.$t(''),
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }, {
            text: '<' + constantValues['ketosis_min'] + '%',
            options: {
                color: '000000',
                valign: 'center',
                align: 'center',
                fill: 'dddddd',
                fontFace: 'Arial',
                margin: 5,
                bold: true
            }
        }
    ])
    // Herd ketosis rate (cases ≤ 60 DIM in last year/fresh in last year) assuming intense surveillance
    tableBody2.push([
        {
            text: app.$t('Herd ketosis rate (cases ≤ 60 DIM in last year/fresh in last year) assuming intense surveillance'),
            options: {
                color: '000000',
                valign: 'center',
                align: 'left',
                fill: 'ffffff',
                fontFace: 'Arial',
                margin: 5,
                bold: false
            }
        },
        ...assessor.generate5ValueRow('ketosis', '%', 'rtl-slider', true)
    ])

    Table.create(slide2,null, tableBody2, report, group, 'Transition Assessor', {
        ...report.styleOptions.tableOptions,
        y: 2.7,
        colW: [4, .7, .7, .7, .7, .7]
    });

}
