import pptxgen from 'pptxgenjs';
import * as SlideMaster from './Core/SlideMaster';
import * as TableOfContents from './Misc/TableOfContents';
import * as Title from './Misc/Title';
import * as ExecutiveSummary from './Misc/ExecutiveSummary';
import commonStyle from './Core/commonStyle';

import Evaluation from '../Classes/Evaluation.js';
import HygieneFootbathAssessorSlides from './Assessors/HygieneFootbath/index.js';
import LocomotionAssessorSlides from './Assessors/Locomotion/index.js';
import TimeBudgetAssessorSlides from './Assessors/TimeBudget/index.js';
import FreeStallAssessorSlides from './Assessors/FreeStall/index.js';
import LesionAssessorSlides from './Assessors/Lesion/index.js';
import HoldingAreaAssessorSlides from './Assessors/HoldingArea/index.js';
import NutritionAndFeedingAssessorSlides from './Assessors/NutritionAndFeeding/index.js';
import TieStallStanchionHeatAbatementAssessorSlides from './Assessors/TieStallStanchionHeatAbatement/index.js';
import HoofTrimmingAssessorSlides from './Assessors/HoofTrimming/index.js';
import WalkingSurfaceAssessorSlides from './Assessors/WalkingSurface/index.js';
import RacetrackAssessorSlides from './Assessors/Racetrack/index.js';
import FreestallVentilationAssessorSlides from './Assessors/FreestallVentilation/index.js';
import TieStallStanchionAssessorSlides from './Assessors/TieStallStanchion/index.js';
import HeatAbatementAssessorSlides from './Assessors/HeatAbatement/index.js';
import BeddedPackAssessorSlides from './Assessors/BeddedPack/index.js';
import BiosecurityAssessorSlides from './Assessors/Biosecurity/index.js';
import DirtLotAssessorSlides from './Assessors/DirtLot/index.js';
import HoldingYardMilkShedAssessorSlides from './Assessors/HoldingYardMilkShed/index.js';
import TransitionAssessorSlides from './Assessors/Transition';
import ErrorSlides from './Core/ErrorSlides/index.js';
import NotStartedAssessorSlides from './Core/NotStartedAssessor.js';

import {
  GET_EVALUATIONS_BY_ID_GETTER,
  GET_UPDATE_ASSESSOR,
} from '../../store/types';

// The constructor function once here has been converted to a class.
class ReportPptx {
  constructor(app, user, evaluationData) {
    // Object.assign(this, new pptxgen());
    this.pptx = new pptxgen();
    this.slideHeight = 0;
    this.element = {};
    this.user = user;
    this.app = app;
    this.slideErrors = [];
    this.tableOfContentsCalculator = [];
    this.pptx.defineLayout({
      name: 'PAPER',
      width: 8.5,
      height: 11,
    });
    this.pptx.layout = 'PAPER';
    this.styleOptions = commonStyle;
    //Slide Masters
    SlideMaster.removeFooterContents(this, user, evaluationData);
    ////////////GENERATORS/////////////
    Title.generateTitle(this, user);
    ExecutiveSummary.generate(this);
    // No instantiation of these abstracted functions
    //Assign TableOfContents to this.element
    TableOfContents.create(this, evaluationData);

    this.tableOfContentsHelper = (
      assessorTitle,
      tableOfContentsCalculatorPosition,
      group
    ) => {
      if (
        this.tableOfContentsCalculator[tableOfContentsCalculatorPosition] ===
        undefined
      ) {
        this.tableOfContentsCalculator[tableOfContentsCalculatorPosition] = {
          page: this.pptx.slides.length,
          title: assessorTitle,
          children: [],
        };
      }

      this.tableOfContentsCalculator[
        tableOfContentsCalculatorPosition
      ].children.push({
        page: this.pptx.slides.length,
        title: group.getName(),
      });
    };

    this.generateErrorSlides = function() {
      let errorSlides = new ErrorSlides(this.app, this);
      errorSlides.display();
    };

    this.generateAssessor = async function(
      evaluation,
      assessorType,
      tableOfContentsCalculatorPosition
    ) {
      this.evaluation = new Evaluation(this.app, evaluation);

      let groups = this.evaluation.getGroups();
      let isTransitionAdded = false;
      const promises = [];
      for (let i = 0; i < groups.length; i++) {
        let assessors = groups[i].getAssessors();
        for (let j = 0; j < assessors.length; j++) {
          if (
            assessors[j] &&
            this.evaluation
              .getReport()
              .assessors.includes(assessors[j].getType())
          ) {
            if (!assessors[j].isReported()) {
              // this.app.$store.dispatch('setData', {
              //   currentVueInstance: this.app,
              //   endpointUrl: "users/" + this.user.id + "/evaluations/" + this.evaluation.getId() + "/groups/" + groups[i].getId() + "/assessors/" + assessors[j].getType(),
              //   objectBeingUpdated: {
              //     data: {
              //       isReported: true
              //     }
              //   }
              // })

              const assessorToUpdate = {
                evaluationId: this.evaluation.getId(),
                groupId: groups[i].getId(),
                assessor: { ...assessors[j].assessor, isReported: true },
              };
              // promises.push(
              this.app.$store.dispatch(
                `assessors/${GET_UPDATE_ASSESSOR}`,
                assessorToUpdate
              );
              // );
              // set the isReported property as true in the assessor
              // this[GET_UPDATE_ASSESSOR]();
            }
          }
        }
      }

      // await Promise.all(promises)

      // Fixed the bug with group summaries not showing
      // const reportGroups = this.evaluation.getReport().groups
      const reportGroups = this.evaluation
        .getReport()
        .groups.filter(groupID => {
          let group = this.evaluation.getGroups().find(group => {
            return group.getId() === groupID;
          });
          return group && group.getAssessorByType(assessorType);
        });

      for (let i = 0; i < reportGroups.length; i++) {
        let group = this.evaluation.getGroups().find(group => {
          return group.getId() === reportGroups[i];
        });

        if (group === undefined) continue;

        let assessor = group.getAssessorByType(assessorType);
        let isAssessorStarted = assessor && assessor.isStarted();
        if (assessor) {
          if (!assessor.isStarted()) {
            let notStartedAssessor = new NotStartedAssessorSlides(
              this.app,
              this,
              assessor,
              group
            );
            notStartedAssessor.add(tableOfContentsCalculatorPosition);
            // continue
          }
        } else {
          // Assessors not in the report
          continue;
        }

        switch (assessorType) {
          case '1':
            try {
              let hygieneFootbathAssessor = new HygieneFootbathAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                hygieneFootbathAssessor.add(tableOfContentsCalculatorPosition);
              if (i === reportGroups.length - 1) {
                let groupAssessors = reportGroups.map(reportGroup => {
                  if (
                    this.evaluation
                      .getGroupById(reportGroup)
                      .getAssessorByType(assessorType)
                  ) {
                    return this.evaluation
                      .getGroupById(reportGroup)
                      .getAssessorByType(assessorType)
                      .isStarted();
                  }
                });
                let lengthOfStartedAssessors = groupAssessors.filter(Boolean)
                  .length;
                if (lengthOfStartedAssessors > 1)
                  hygieneFootbathAssessor.groupSummary(
                    tableOfContentsCalculatorPosition
                  );
              }
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '2':
            try {
              let freestallAssessor = new FreeStallAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                freestallAssessor.add(tableOfContentsCalculatorPosition);
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '3':
            try {
              let locomotionAssessor = new LocomotionAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                locomotionAssessor.add(tableOfContentsCalculatorPosition);
              // console.log({ i, 'Report groups length': this.evaluation.getReport().groups.length})
              console.log({ i, 'reportGroups.length': reportGroups.length });
              if (i === reportGroups.length - 1) {
                let groupAssessors = reportGroups.map(reportGroup => {
                  if (
                    this.evaluation
                      .getGroupById(reportGroup)
                      .getAssessorByType(assessorType)
                  ) {
                    return this.evaluation
                      .getGroupById(reportGroup)
                      .getAssessorByType(assessorType)
                      .isStarted();
                  }
                });
                let lengthOfStartedAssessors = groupAssessors.filter(Boolean)
                  .length;
                if (lengthOfStartedAssessors > 1)
                  locomotionAssessor.groupSummary(
                    tableOfContentsCalculatorPosition
                  );
              }
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '4':
            try {
              let timeBudgetAssessor = new TimeBudgetAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                timeBudgetAssessor.add(tableOfContentsCalculatorPosition);
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '10':
            try {
              let tieStallStanchion = new TieStallStanchionAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                tieStallStanchion.add(tableOfContentsCalculatorPosition);
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '16':
            try {
              let heatAbatementAssessorSlides = new HeatAbatementAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                heatAbatementAssessorSlides.add(
                  tableOfContentsCalculatorPosition
                );
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '11':
            try {
              let holdingAreaAssessor = new HoldingAreaAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                holdingAreaAssessor.add(tableOfContentsCalculatorPosition);
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '14':
            try {
              let nutritionAndFeedingAssessor = new NutritionAndFeedingAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                nutritionAndFeedingAssessor.add(
                  tableOfContentsCalculatorPosition
                );
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '13':
            try {
              let tieStallStanchionHeatAbatement = new TieStallStanchionHeatAbatementAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                tieStallStanchionHeatAbatement.add(
                  tableOfContentsCalculatorPosition
                );
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '15':
            try {
              let hoofTrimmingAssessor = new HoofTrimmingAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                hoofTrimmingAssessor.add(tableOfContentsCalculatorPosition);
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '17':
            try {
              let lesionAssessor = new LesionAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                lesionAssessor.add(tableOfContentsCalculatorPosition);
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '18':
            try {
              let walkingSurfaceAssessor = new WalkingSurfaceAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                walkingSurfaceAssessor.add(tableOfContentsCalculatorPosition);
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '9':
            try {
              let racetrackAssessor = new RacetrackAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                racetrackAssessor.add(tableOfContentsCalculatorPosition);
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '12':
            try {
              let freestallVentilationAssessor = new FreestallVentilationAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                freestallVentilationAssessor.add(
                  tableOfContentsCalculatorPosition
                );
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '7':
            try {
              let beddedPackAssessor = new BeddedPackAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                beddedPackAssessor.add(tableOfContentsCalculatorPosition);
            } catch (errorMessage) {
              this.slideErrors.push(errorMessage);
            }
            break;
          case '6':
            try {
              let biosecurityAssessor = new BiosecurityAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                biosecurityAssessor.add(tableOfContentsCalculatorPosition);
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '5':
            try {
              let dirtLotAssessor = new DirtLotAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (isAssessorStarted)
                dirtLotAssessor.add(tableOfContentsCalculatorPosition);
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '19':
            try {
              let transitionAssessor = new TransitionAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group,
                this.user
              );
              if (!isTransitionAdded && isAssessorStarted) {
                isTransitionAdded = true;
                transitionAssessor.add(tableOfContentsCalculatorPosition);
              }
              if (i === reportGroups.length - 1) {
                let groupAssessors = reportGroups.map(reportGroup => {
                  if (
                    this.evaluation
                      .getGroupById(reportGroup)
                      .getAssessorByType(assessorType)
                  ) {
                    return this.evaluation
                      .getGroupById(reportGroup)
                      .getAssessorByType(assessorType)
                      .isStarted();
                  }
                });
                let lengthOfStartedAssessors = groupAssessors.filter(Boolean)
                  .length;
                // if (lengthOfStartedAssessors > 1) transitionAssessor.groupSummary(tableOfContentsCalculatorPosition);
                transitionAssessor.groupSummary(
                  tableOfContentsCalculatorPosition
                );
              }
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;
          case '20':
            try {
              let holdingYardMilkShedAssessor = new HoldingYardMilkShedAssessorSlides(
                this.app,
                this,
                this.evaluation,
                group
              );
              if (isAssessorStarted)
                holdingYardMilkShedAssessor.add(
                  tableOfContentsCalculatorPosition
                );
            } catch (errorMessage) {
              console.log(errorMessage);
              this.slideErrors.push(errorMessage);
            }
            break;

          default:
            console.log('Default Called');
        }
      }
    };
  }
}

export default ReportPptx;
