<template>
  <div class="timemeter d-flex flex-column text-center">
    <div class="timemeter-wrapper">
      <img
        v-if="value && success(value)"
        class="timemeter__img"
        src="@/assets/img/dashboard/timemeter-success.png"
      />

      <template
        v-else-if="value && danger(value)"
      >
        <img
          v-if="reversed"
          class="timemeter__img"
          src="@/assets/img/dashboard/timemeter-danger-reversed.png"
        />

        <img
          v-else
          class="timemeter__img"
          src="@/assets/img/dashboard/timemeter-danger.png"
        />
      </template>

      <img
        v-else
        class="timemeter__img"
        src="@/assets/img/dashboard/timemeter-warn.png"
      />
    </div>

    <h6 class="mb-0 mt-2">
      {{ label }}
    </h6>

    <small class="mt-1">
      {{ `${value} ${units}` }}
    </small>
  </div>
</template>

<script>
export default {
  name: 'timemeter',

  props: {
    reversed: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    units: {
      type: String,
      default: '',
    },
    danger: {
      type: Function,
      default: false,
    },
    success: {
      type: Function,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.timemeter {
  &__img {
    max-width: 100px;
    width: 100%;
  }
}
</style>
