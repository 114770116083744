<!-- @format -->

<template>
  <!-- Get image upload back up -->
  <div
    v-if="evaluation !== null && assessor !== null"
    class="container main-container main-container--padding-b"
  >
    <confirmation-modal
      :isOpen="isModalOpen"
      @update:isOpen="isModalOpen = $event"
    />
    <hygiene-header
      :assessorId="$route.params.assessorType"
      :groups="evaluation.groups"
      :assessors="group.assessors"
      :show-nav="showAssessorNav"
      :show-only-group-tabs="showOnlyGroupTabs"
    />
    <existing-footbath
      v-if="
        $route.params.assessorType == 1 &&
        $store.state.subPage === 'existing-footbath'
      "
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}asdfasdfdsfasdgasdasdfasdf`"
    />
    <new-footbath
      v-else-if="
        $route.params.assessorType == 1 &&
        $store.state.subPage === 'new-footbath'
      "
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}asdfasdfdsfasdgasd`"
    />
    <mix
      v-else-if="
        $route.params.assessorType == 1 &&
        $store.state.subPage === 'mix'
      "
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}asdfasdf`"
    />
    <score-cow
      v-else-if="$route.params.assessorType == 1"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
    />
    <!-- :key="`${group.name}_${assessor.id}`" -->
    <!-- TODO -->

    <freestall
      v-if="$route.params.assessorType == 2"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <!-- TODO -->
    <locomotion
      v-if="$route.params.assessorType == 3"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <!-- TODO -->

    <time-budget
      v-if="$route.params.assessorType == 4"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <!-- sprint 4 -->
    <dirt-lot
      v-if="$route.params.assessorType == 5"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <biosecurity
      v-if="$route.params.assessorType == 6"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <bedded-pack
      v-if="$route.params.assessorType == 7"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <racetrack-lanes
      v-if="$route.params.assessorType == 9"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <tie-stall-stanchion
      v-if="$route.params.assessorType == 10"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <holding-area
      v-if="$route.params.assessorType == 11"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <freestall-ventilation
      v-if="$route.params.assessorType == 12"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <tie-stall-stanchion-heat-abatement
      v-if="$route.params.assessorType == 13"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <nutrition-and-feeding
      v-if="$route.params.assessorType == 14"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <hoof-trimming
      v-if="$route.params.assessorType == 15"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <heat-abatement
      v-if="$route.params.assessorType == 16"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <lesion
      v-if="$route.params.assessorType == 17"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <walking-surface
      v-if="$route.params.assessorType == 18"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <transition
      v-if="
        assessor.type == 19 &&
        !['main', 'summary'].includes($store.state.subPage)
      "
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <transition-main
      v-if="assessor.type == 19 && $store.state.subPage === 'main'"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <transition-summary
      v-if="assessor.type == 19 && $store.state.subPage === 'summary'"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />
    <holding-yard-milk-shed
      v-if="assessor.type == 20"
      :assessor="assessor"
      :group="group"
      :evaluation="evaluation"
      :key="`${group.name}_${assessor.id}`"
    />

    <div class="clearfix" v-if="assessorUrl">
      <div v-if="!(assessor.type == 19 && !showOnlyGroupTabs)">
        <router-link
          @click.native="$scrollTop"
          v-if="previousGroup !== false"
          class="btn btn-primary btn-full--sm pull-left"
          :to="
            '/users/' +
            this.$route.params.userId +
            '/evaluations/' +
            this.$route.params.evaluationId +
            '/groups/' +
            previousGroup.id +
            '/assessors/' +
            this.$route.params.assessorType +
            groupLinkPostfix
          "
        >
          <span>{{ "Previous Group" | translate }}</span>
        </router-link>
        <router-link
          @click.native="$scrollTop"
          v-if="nextGroup !== false"
          class="btn btn-primary btn-full--sm pull-right mt-2 mt-md-0"
          :to="
            '/users/' +
            this.$route.params.userId +
            '/evaluations/' +
            this.$route.params.evaluationId +
            '/groups/' +
            nextGroup.id +
            '/assessors/' +
            this.$route.params.assessorType +
            groupLinkPostfix
          "
        >
          <span>{{ "Next Group" | translate }}</span>
        </router-link>
      </div>
    </div>
    <div class="clearfix" v-else>
      <router-link
        @click.native="$scrollTop"
        v-if="previousAssessor !== false"
        class="btn btn-primary btn-full--sm pull-left"
        :to="
          '/users/' +
          this.$route.params.userId +
          '/evaluations/' +
          this.$route.params.evaluationId +
          '/groups/' +
          this.$route.params.groupId +
          '/assessors/' +
          previousAssessor
        "
      >
        <span>{{ "Previous Assessor" | translate }}</span>
      </router-link>
      <router-link
        @click.native="$scrollTop"
        v-if="nextAssessor !== false"
        class="btn btn-primary btn-full--sm pull-right mt-2 mt-md-0"
        :to="
          '/users/' +
          this.$route.params.userId +
          '/evaluations/' +
          this.$route.params.evaluationId +
          '/groups/' +
          this.$route.params.groupId +
          '/assessors/' +
          nextAssessor
        "
      >
        <span>{{ "Next Assessor" | translate }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import PresentationNotes from "./assessor_components/presentation-notes";
import PersonalNotes from "./assessor_components/personal-notes";
import HygieneHeader from "./assessor_components/hygiene-header.vue";
import ModalInfo from "@/components/misc/modal-info.vue";
import Question from "./assessor_components/question";
import ModalScore from "@/components/misc/modal-score.vue";
import ScoreCow from "./hygiene-footbath/score-cow.vue";
import ExistingFootbath from "./hygiene-footbath/evaluate-existing-footbath.vue";
import NewFootbath from "./hygiene-footbath/design-new-footbath.vue";
import Mix from "./hygiene-footbath/create-new-mix.vue";
import TimeBudget from "./time-budget.vue";
import HoldingArea from "./holdingArea.vue";
import NutritionAndFeeding from "./nutritionAndFeeding.vue";
import TieStallStanchionHeatAbatement from "./tieStallStanchionHeatAbatement.vue";
import HeatAbatement from "./heatAbatement.vue";
import Locomotion from "./locomotion.vue";
import HoofTrimming from "./hoofTrimming.vue";
import Lesion from "./lesion.vue";
import WalkingSurface from "./walkingSurface.vue";
import FreestallVentilation from "./freestallVentilation.vue";
import Biosecurity from "./biosecurity.vue";
import HoldingYardMilkShed from "./holdingYardMilkShed.vue";

import Cookie from "js-cookie";
import Freestall from "./freestall.vue";
import RacetrackLanes from "./racetrackLanes.vue";
import BeddedPack from "./beddedPack.vue";

import _ from "lodash";
import TieStallStanchion from "./tieStallStanchion.vue";
import DirtLot from "./dirtLot.vue";
import Transition from "./transition";
import TransitionMain from "./transitionMain";
import TransitionSummary from "./transitionSummary";
import ConfirmationModal from "@/components/misc/ConfirmationModal.vue";

import { initializeAssessorDataByType } from "../../helperFunctions";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  GET_EVALUATION_BY_ID,
  GET_EVALUATION_BY_ID_GETTER,
  GET_UPDATE_ASSESSOR,
  GET_UPDATE_EVALUATION,
} from "../../store/types";

export default {
  name: "BaseAssessor",
  components: {
    BeddedPack,
    Biosecurity,
    DirtLot,
    ExistingFootbath,
    Freestall,
    FreestallVentilation,
    HeatAbatement,
    HoofTrimming,
    HoldingArea,
    HoldingYardMilkShed,
    HygieneHeader,
    Lesion,
    Locomotion,
    Mix,
    ModalInfo,
    ModalScore,
    NewFootbath,
    NutritionAndFeeding,
    PresentationNotes,
    PersonalNotes,
    Question,
    RacetrackLanes,
    ScoreCow,
    TimeBudget,
    TieStallStanchionHeatAbatement,
    TieStallStanchion,
    Transition,
    TransitionMain,
    TransitionSummary,
    WalkingSurface,
    ConfirmationModal,
  },
  data() {
    // const { userId, evaluationId, groupId, assessorType } = this.$route.params;
    return {
      // unsubscribe: null,
      isModalOpen: false,
      showConfirmationPopup: true,
      evaluation: null,
      assessor: null,
      group: null,
      timeoutSave: null,
      assessorUrl: null,
      showGuide: false,
      arr: null,
      showAssessorNav: true,
      showOnlyGroupTabs: false,
      groupLinkPostfix: "",
      userId: this.$route.params.userId,
      evaluationId: this.$route.params.evaluationId,
      groupId: this.$route.params.groupId,
      assessorType: this.$route.params.assessorType,
      originalEvaluationData: this.evaluation && this.evaluation.data,
    };
  },
  methods: {
    ...mapActions("evaluations", [
      GET_EVALUATION_BY_ID,
      GET_UPDATE_EVALUATION,
    ]),
    ...mapActions("assessors", [GET_UPDATE_ASSESSOR]),
    checkShowAssessorNav() {
      if (this.$route.params.assessorType == 19) {
        this.showOnlyGroupTabs = ["main"].includes(
          this.$store.state.subPage
        );
        this.groupLinkPostfix = ["main"].includes(
          this.$store.state.subPage
        )
          ? "/main"
          : "";
        if (Cookie.get("assessor_nav") === "assessor") {
          this.showAssessorNav = ["main"].includes(
            this.$store.state.subPage
          );
        } else {
          this.showAssessorNav = true;
        }
      } else {
        this.showOnlyGroupTabs =
          Cookie.get("assessor_nav") === "assessor";
        this.showAssessorNav = true;
      }
    },
    getFirstAssessorTypeByGroup(group) {
      return this.evaluation.order.find((assessorType) => {
        return group.assessor_list.find((groupAssessorType) => {
          return Number(groupAssessorType) === Number(assessorType);
        });
      });
    },
    initializeData: async function () {
      // if (this.evaluation._id !== this.evaluationId)
      this.evaluation = this[GET_EVALUATION_BY_ID_GETTER](
        this.evaluationId
      );

      let assessorsInEvaluation = [];

      if (Array.isArray(this.evaluation.groups)) {
        for (let i = 0; i < this.evaluation.groups.length; i++) {
          for (
            let j = 0;
            j < this.evaluation.groups[i].assessor_list.length;
            j++
          ) {
            assessorsInEvaluation.push(
              this.evaluation.groups[i].assessor_list[j]
            );
          }
        }
      } else {
        for (let groupId in this.evaluation.groups) {
          for (
            let j = 0;
            j < this.evaluation.groups[groupId].assessor_list.length;
            j++
          ) {
            assessorsInEvaluation.push(
              this.evaluation.groups[groupId].assessor_list[j]
            );
          }
        }
      }

      assessorsInEvaluation = _.uniqWith(
        assessorsInEvaluation,
        _.isEqual
      );

      assessorsInEvaluation.sort();

      let sortedEvalOrder = _.sortBy(this.evaluation.order);

      if (
        this.evaluation.order === undefined ||
        !_.isEqual(assessorsInEvaluation, sortedEvalOrder)
      ) {
        this.$router.push(
          "/users/" +
            this.userId +
            "/evaluations/" +
            this.evaluationId +
            "/order/"
        );
        return false;
      }
      // this.group = this.$store.getters.getData('users/' + this.userId + '/evaluations/' + this.evaluationId + '/groups/' + this.groupId);
      this.group = this.evaluation.groups[this.$route.params.groupId];
      let assessorExists = false;
      let assessorType = this.$route.params.assessorType;
      this.$forceUpdate();
      Cookie.get("assessor_nav") !== undefined &&
      Cookie.get("assessor_nav") === "assessor"
        ? (this.assessorUrl = true)
        : (this.assessorUrl = false);

      if (this.group.assessors === undefined) {
        this.group.assessors = {};
      }

      if (Array.isArray(this.group.assessors)) {
        this.assessor = this.group.assessors.find((assessor) => {
          return Number(assessor.type) === Number(assessorType);
        });
      } else {
        for (let assessorId in this.group.assessors) {
          this.assessor = this.group.assessors[assessorType];
          break;
        }
      }

      if (!(this.assessor && this.assessor.data !== undefined)) {
        this.assessor = initializeAssessorDataByType(
          this,
          this.$route.params.assessorType
        );
        this.group.assessors[this.assessor.id] = this.assessor;
      }

       if (this.evaluation.isForTesting === null || !this.evaluation.hasOwnProperty("isForTesting")) {
        this.isModalOpen = true
      }

      this.$scrollTop();
    },
    getEvaluationOrder(evaluation) {
      if (
        evaluation.order !== undefined &&
        evaluation.order.length > 0
      ) {
        return evaluation.order;
      } else {
        let self = this;
        let assessorOrder = [];

        if (Array.isArray(evaluation.groups)) {
          evaluation.groups.forEach(function (group) {
            group.assessor_list.forEach(function (assessorTypeId) {
              let assessorTypeExists = assessorOrder.find(
                (assessorType) => {
                  return assessorType.id === assessorTypeId;
                }
              );

              if (assessorTypeExists === undefined) {
                assessorOrder.push(
                  self.$store.state.assessorTypes.find(function (
                    assessorType
                  ) {
                    return assessorType.id === assessorTypeId;
                  }).id
                );
              }
            });
          });
        } else {
          for (let groupId in evaluation.groups) {
            evaluation.groups[groupId].assessor_list.forEach(
              function (assessorTypeId) {
                let assessorTypeExists = assessorOrder.find(
                  (assessorType) => {
                    return assessorType.id === assessorTypeId;
                  }
                );

                if (assessorTypeExists === undefined) {
                  assessorOrder.push(
                    self.$store.state.assessorTypes.find(function (
                      assessorType
                    ) {
                      return assessorType.id === assessorTypeId;
                    }).id
                  );
                }
              }
            );
          }
        }

        return assessorOrder;
      }
    },
  },
  watch: {
    $route(to, from) {
      this.initializeData();
      this.$forceUpdate();
      this.checkShowAssessorNav();
      this.$store.state.loader = false;
      this.$store.state.successMessage = false;
      this.$store.state.error = false;
      this.$forceUpdate();
    },

    assessor: {
      handler: _.debounce(async function (newAssessor, oldAssessor) {
        console.log("fine", newAssessor);
        // if ids are the same, content hasn't been updated then stop processing
        if (oldAssessor !== null && newAssessor.id !== oldAssessor.id)
          return false;
        await this[GET_UPDATE_ASSESSOR]({
          evaluationId: this.evaluation._id,
          groupId: this.group.id,
          assessor: newAssessor,
        });
      }, 500),
      deep: true,
    },
    "evaluation.data": {
      handler: _.debounce(async function (evaluationData) {
        if (_.isEqual(evaluationData, this.originalEvaluationData))
          return;

        // update the evaluation whenever the evaluation data changes,
        await this[GET_UPDATE_EVALUATION]({
          ...this.evaluation,
          data: { ...this.evaluation.data, ...evaluationData },
        });
        // we're setting this value just because we can compare both and not run this watch as infinite loop
        this.originalEvaluationData = { ...this.evaluation.data };
      }, 500),
      deep: true,
    },
  },
  computed: {
    ...mapState({
      // evaluation: state => state.evaluations.evaluation,
      evaluations: (state) => state.evaluations.evaluations,
      user: (state) => state.users.user,
      subPage: (state) => state.subPage,
    }),
    ...mapGetters("evaluations", [GET_EVALUATION_BY_ID_GETTER]),
    previousGroup: function () {
      let firstValueInArray = this.groupsWithAssessorType[0];
      let currentGroupIndex = this.groupsWithAssessorType.findIndex(
        (group) => group.id === this.$route.params.groupId
      );
      return firstValueInArray.id !== this.$route.params.groupId
        ? this.groupsWithAssessorType[currentGroupIndex - 1]
        : false;
    },
    nextGroup: function () {
      let lastValueInArray =
        this.groupsWithAssessorType[
          this.groupsWithAssessorType.length - 1
        ];
      let currentGroupIndex = this.groupsWithAssessorType.findIndex(
        (group) => group.id === this.$route.params.groupId
      );
      return lastValueInArray.id !== this.$route.params.groupId
        ? this.groupsWithAssessorType[currentGroupIndex + 1]
        : false;
    },
    previousAssessor: function () {
      let firstValueInArray = this.orderedAsssessorTypeList[0];
      let currentAssessorTypeIndex =
        this.orderedAsssessorTypeList.findIndex(
          (assessorType) =>
            assessorType === this.$route.params.assessorType
        );
      return firstValueInArray !== this.$route.params.assessorType
        ? this.orderedAsssessorTypeList[currentAssessorTypeIndex - 1]
        : false;
    },
    nextAssessor: function () {
      let lastValueInArray =
        this.orderedAsssessorTypeList[
          this.orderedAsssessorTypeList.length - 1
        ];
      let currentAssessorTypeIndex =
        this.orderedAsssessorTypeList.findIndex(
          (assessorType) =>
            assessorType === this.$route.params.assessorType
        );
      return lastValueInArray !== this.$route.params.assessorType
        ? this.orderedAsssessorTypeList[currentAssessorTypeIndex + 1]
        : false;
    },
    groupsWithAssessorType() {
      if (Array.isArray(this.evaluation.groups)) {
        return this.evaluation.groups.filter((group) => {
          // loop through groups
          // find if this group has the selected assessor type
          return group.assessor_list.find(
            (groupAssessorType) =>
              groupAssessorType === this.$route.params.assessorType
          );
        });
      } else {
        let groups = [];
        for (let groupId in this.evaluation.groups) {
          const hasAssessorType = this.evaluation.groups[
            groupId
          ].assessor_list.find(
            (groupAssessorType) =>
              groupAssessorType === this.$route.params.assessorType
          );
          if (hasAssessorType) {
            groups.push(this.evaluation.groups[groupId]);
          }
        }
        return groups;
      }
    },
    orderedAsssessorTypeList() {
      let order = this.getEvaluationOrder(this.evaluation);
      return order.filter((assessorType) =>
        this.group.assessor_list.includes(assessorType)
      );
    },
  },
  created() {
    setTimeout(async () => {
      await this.initializeData();
      this.checkShowAssessorNav();

    }, 1000);
  },
  destroyed() {
    // if (this.unsubscribe) {
    //   this.unsubscribe();
    // }
  },
};
</script>
