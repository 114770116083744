<template>
    <div>
        <a href="#" data-toggle="modal" :data-target="['#score-' + image]">
            <img v-if="size === 'lg'" :src="locomotion_image" :alt="[$t('locomotion cow ') + image]" class="hygiene-score-images__img mb-3"  :style="{width: '255px', height:'170px'}">
            <img v-else :src="locomotion_image" :alt="[$t('locomotion cow ') + image]" class="hygiene-score-images__img mb-3">
        </a>
        <div class="modal fade" :id="['score-' + image]" tabindex="-1" role="dialog" :aria-labelledby="['score-' + image]" aria-hidden="true">
            <div class="modal-dialog modal-dialog--edit" role="document">
                <div class="modal-content edit-modal">
                    <h2 class="h2 h2--modal text-center mt-1 mt-md-0 mb-0">
                        {{ $t('Score ') + image }}
                    </h2>
                    <div class="modal-body my-0">
                        <div :key="video" class="wistia_responsive_padding mb-3" style="padding-bottom:75%;position:relative;">
                            <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                                <div :class="'wistia_embed wistia_async_' + video + ' seo=false videoFoam=true'" style="height:100%;position:relative;width:100%">&nbsp;</div>
                            </div>
                        </div>
                        <!-- Rest of your content -->
                        <p v-if="image === 1" class="mb-1"><strong>{{ 'Normal' | translate }}{{ ' - ' | translate }}</strong>{{ 'The cow walks and stands with a flat back. She walks normally.' | translate }}</p>
                        <p v-else-if="image === 2" class="mb-1"><strong>{{ 'Slightly Abnormal Gait' | translate }}{{ ' - ' | translate }}</strong>{{ 'The cow stands with a flat back but arches* her back while walking. She walks normally.' | translate }}</p>
                        <p v-else-if="image === 3" class="mb-1"><strong>{{ 'Moderately Lame' | translate }}{{ ' - ' | translate }}</strong>{{ 'The cow stands with an arched back and walks with an arched back. She short-steps while walking.' | translate }}</p>
                        <p v-else-if="image === 4" class="mb-1"><strong>{{ 'Lame' | translate }}{{ ' - ' | translate }}</strong>{{ 'The cow stands with an arched back and walks with an arched back and walks with decreased weight bearing on one limb (limps).' | translate }}</p>
                        <p v-else-if="image === 5" class="mb-1"><strong>{{ 'Severely Lame' | translate }}{{ ' - ' | translate }}</strong>{{ 'The cow stands and walks with an arched back and refuses to bear weight on a limb.' | translate }}</p>
                        <div class="row">
                            <div class="col-md-24 modal-bottom">
                                <a href="#" class="btn-cancel-modal mt-2" data-dismiss="modal">{{ 'Cancel' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['image', 'video', 'type', 'size'],
        computed: {
            locomotion_image() {
                return `/images/Locomotion_${this.image}.jpg`;
                },
            }
        }
    
</script>
