<template>
  <div class="col-lg-24">
    <div class="row">
      <div class="col-lg-12">
        <label class="mb-1"
          >{{
            $t("Fan Width") +
            " (" +
            $t($t($getEquivalentUnit(evaluation.data.measurements, "cm"))) +
            ")"
          }}

        <template v-if="showModal">
          <modal-info :value="'base-layer'" class="d-inline">
            <template slot="modal-content">
              <h2 class="h2 pb-2 pt-3 px-3 text-center">
                {{ "Number of Fans" | translate }}
              </h2>
              <p class="px-3">
                {{
                  "Enter up to three fan sizes and quantities below" | translate
                }}
              </p>
              <div class="row modal-bottom pb-3">
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center"
                  data-dismiss="modal"
                  >{{ "Cancel" | translate }}</a
                >
              </div>
            </template>
          </modal-info>
        </template>
        </label>

        <!-- v-model.number="assessor.data[fanWidth]" -->
        <input
          v-model="localFanWidth"
          type="number"
          inputmode="numeric"
          class="form-control"
          @keypress="$isNumber($event)"
        />
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label class="mb-1">{{ $t("Number of Fans") }}</label>
          <input
            v-model.number="assessor.data[noOfFan]"
            type="number"
            inputmode="numeric"
            class="form-control"
            @keypress="$isNumber($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalInfo from "@/components/misc/modal-info.vue";

export default {
  name: "Fan",
  components: {
    ModalInfo,
  },
  props: {
    assessor: {
      type: Object,
      required: true,
    },
    fanWidth: {
      type: String | null | Number,
      required: true,
    },
    noOfFan: {
      type: String,
      required: true,
    },
    evaluation: {
      type: Object,
      required: true,
    },
    showModal:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localFanWidth: this.fanWidth,
    };
  },
  watch: {
    fanWidth(newValue) {
      // Update the localFanWidth whenever the prop changes
      this.localFanWidth = newValue;
    },
  },

  methods: {
    updateFanWidth() {
      // Emit the "update:fanWidth" event with the new value
      this.$emit("update:fanWidth", +this.localFanWidth);
    },
  },
  // You can also use the "updated" hook to watch for changes in localFanWidth
  updated() {
    this.updateFanWidth();
  }
};
</script>
