<template>
    <div>
        <a href="#" data-toggle="modal" :data-target="['#ellipsis-' + value]">
			<img class="ellipsis-icon" src="@/assets/icons/ellipsis.svg" alt="ellipsis icon" height="20px">
        </a>
        <div class="modal fade" :id="['ellipsis-' + value]" tabindex="-1" role="dialog" :aria-labelledby="['ellipsis-' + value]" aria-hidden="true">
            <div class="modal-dialog modal-dialog--edit" role="document">
                <div class="modal-content edit-modal">
                    <h2 class="h2 h2--modal text-center mt-1 mt-md-0 mb-0">
                        <slot name="modal-title"></slot>
                    </h2>
                    <div class="modal-body my-0">
                        <slot name="modal-content"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showModal: false,
            }
        },
        props: ['value']
    }
</script>
