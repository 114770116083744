export const generate = (self) => {
    return self.generateExecSummary = function (title, content) {
        let slide = this.pptx.addSlide('Slide master');
        slide.addText(
            app.$t('Executive Summary'), this.styleOptions.slideHeader
        );

        slide.addText(
            title, {
                x: 0.5,
                y: 2.25,
                ...this.styleOptions.subheaderBlue
            }
        );

        slide.addText(
            content, {
                x: 0.5,
                y: 2.7,
                w: 7.5,
                valign: 'top',
                color: '000000',
                fontSize: 12,
                fontFace: 'Arial',
                fontWeight: 500
            }
        );

        return true;
    };

};
