import { dirtLot } from './dirtLot';
import { medias } from './../Global/medias.js';

class DirtLotAssessorSlides {

    constructor(app, report, evaluation, group, user) {
        this.app = app;
        this.report = report;
        this.evaluation = evaluation;
        this.group = group;
        this.user = user
    }

    add(tableOfContentsCalculatorPosition) {

        let assessor = this.group.getAssessorByType("5");

        if (assessor === false || assessor.assessor.data === undefined) {
            throw this.group.getName() + this.app.$t(" - ") + this.app.$t("Dirt Lot assessor is not ready.");
        }

        this.report.tableOfContentsHelper("Dirt Lot", tableOfContentsCalculatorPosition, this.group);

        dirtLot(this.app, this.report, this.evaluation, this.group, assessor);
        medias(this.app, this.report, this.group, "5", this.user);
    }

}

export default DirtLotAssessorSlides;