/**
 * The hoof trimming assessor provides recommendations and warnings for the user depending on how the user trims their herd. The assessor
 * is made up of mostly yes/no questions but also a number of simple calculated fields.
 */

const convert = require('convert-units');
import Assessor from './Assessor';

class HoofTrimming extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;

        this.idealAreToesTrimmed = 8;
    }

    getPrimaryHoofTrimmer() {
        return this.assessor.data.primary_hoof_trimmer;
    }

    getTrimmingFrequency() {
        if (this.assessor.data.trimming_frequency === '') {
            return null;
        }
        return this.assessor.data.trimming_frequency;
    }

    getTrimmerVisitFrequency() {
        if(this.assessor.data.trimmer_visit_frequency === '') {
            return null;
        }
        return this.assessor.data.trimmer_visit_frequency;
    }

    getNumberOfTrimsRecordedPerYear() {
        if (this.assessor.data.number_of_trims_recorded_per_year === '') {
            return null;
        }
        return this.assessor.data.number_of_trims_recorded_per_year;
    }

    getPercentageOfCowsInHerdWithLocomotionScoresOf4And5() {
        if (this.assessor.data.percentage_of_cows_in_herd_with_locomotion_scores_of_4_and_5 === '') {
            return null;
        }
        return this.assessor.data.percentage_of_cows_in_herd_with_locomotion_scores_of_4_and_5;
    }

    getAreHeifersTrimmedPriorToCalving() {
        if (this.assessor.data.are_heifers_trimmed_prior_to_calving === '') {
            return null;
        }
        return this.assessor.data.are_heifers_trimmed_prior_to_calving;
    }

    getHeifersInGroupNumber() {
        return this.assessor.data.heifers_in_group_number;
    }

    getHeifersInGroupPercent() {
        return this.assessor.data.heifers_in_group_percent;
    }

    getAreLameCowsTreatedByHoofTrimmer() {
        return this.assessor.data.are_lame_cows_treated_by_hoof_trimmer;
    }

    getAtWhatLocomotionScoreAreTheDairyPersonnelTrainedToIdentifyCowsInNeedOfTreatment() {
        return this.assessor.data.at_what_locomotion_score_are_the_dairy_personnel_trained_to_identify_cows_in_need_of_treatment;
    }

    getDoesDairyPersonnelActivelyIdentifyLameCowsForTreatmentAsSoonAsTheyOccur() {
        return this.assessor.data.does_dairy_personnel_actively_identify_lame_cows_for_treatment_as_soon_as_they_occur;
    }

    getDoesTheHoofTrimmerLikeWorkingWithCows() {
        return this.assessor.data.does_the_hoof_trimmer_like_working_with_cows;
    }

    getDoesTheHoofTrimmerHandleCowsGently() {
        return this.assessor.data.does_the_hoof_trimmer_handle_cows_gently;
    }

    getIsTheTrimmingAreaAPleasantPlaceToWorkInAllWeatherConditions() {
        return this.assessor.data.is_the_trimming_area_a_pleasant_place_to_work_in_all_weather_conditions;
    }

    getIsShadeAvailable() {
        return this.assessor.data.is_shade_available;
    }

    getIsWaterAvailable() {
        return this.assessor.data.is_water_available;
    }

    getIsFeedAvailable() {
        return this.assessor.data.is_feed_available;
    }

    getHasTheHoofTrimmerReceivedFormalTraining() {
        return this.assessor.data.has_the_hoof_trimmer_received_formal_training;
    }

    getDoesTheHoofTrimmerUseALesionRecordingSystemWithStandardDefinitionsForHoofLesions() {
        return this.assessor.data.does_the_hoof_trimmer_use_a_lesion_recording_system_with_standard_definitions_for_hoof_lesions;
    }

    geIsThereANoticeableIncreaseInNewCasesOfLamenessWithinAWeekOfRoutineTrimming() {
        return this.assessor.data.is_there_a_noticeable_increase_in_new_cases_of_lameness_within_a_week_of_routine_trimming;
    }

    getAreToesTrimmedToMaintainTheTopFrontDorsalHoofWallAtALengthOfOrGreater() {
        return this.assessor.data.are_toes_trimmed_to_maintain_the_top_front_dorsal_hoof_wall_at_a_length_of_or_greater;
    }

    getIdealAreToesTrimmed() {
        return convert(this.idealAreToesTrimmed)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(0);
    }

    getDoesTheHoofTrimmerSpareTheHeightOfTheMedialClawHeelInsideClawOfTheHindFootInParticularToTheHeel() {
        return this.assessor.data.does_the_hoof_trimmer_spare_the_height_of_the_medial_claw_heel_inside_claw_of_the_hind_foot_in_particular_to_the_heel;
    }

    getIsTheInsideWallAxialWallOrTopOfTheInnerOrOuterWallRemoved() {
        return this.assessor.data.is_the_inside_wall_axial_wall_or_toe_of_the_inner_or_outer_wall_removed;
    }

    getAreClawsTrimmedSoThatTheSoleSurfaceIsFlatRatherThanConcave() {
        return this.assessor.data.are_claws_trimmed_so_that_the_sole_surface_is_flat_rather_than_concave;
    }

    getIsTheOuterWallAbaxialWallRemovedFromTheToeAndSideWallExcessively() {
        return this.assessor.data.is_the_outer_wall_abaxial_wall_removed_from__the_toe_and_side_wall_excessively;
    }

    getIsTheSoleTrimmedExcessively() {
        return this.assessor.data.is_the_sole_trimmed_excessively;
    }

    /**
     * Calculations
     */
    getEstimatedTrimmedPerVisit() {
        if (this.getTrimmerVisitFrequency() && this.getTrimmerVisitFrequency() > 0) {
            return Math.round(this.getNumberOfTrimsRecordedPerYear() / this.getTrimmerVisitFrequency())
        }

        return 0
    }

    getEstimatedTrimmedPerYear() {
        let estimate = 0.0

        if (this.getTrimmingFrequency()) {
            estimate += this.group.getGroupSize() * this.getTrimmingFrequency()
        }
        if (this.getAreHeifersTrimmedPriorToCalving()) {
            if (this.getHeifersInGroupNumber()) {
                estimate += this.getHeifersInGroupNumber()
            } else if (this.getHeifersInGroupPercent()) {
                estimate += this.group.getGroupSize() * (this.getHeifersInGroupPercent() / 100)
            }
        }
        if (this.getPrimaryHoofTrimmer()) {
            estimate += this.group.getGroupSize() * 0.4
        }

        console.log('estimate', estimate);

        return Math.round(estimate)
    }

};

export default HoofTrimming;