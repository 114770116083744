<template>
  <div class="container main-container main-container--hygiene view">
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block mb-lg-4 mr-1">
          {{ $t('Tell Us About Your Nutrition and Feeding Program') }}
        </h2>
        <modal-info :value="'nutrition'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ $t('Nutrition and Feeding') }}
            </h2>
            <p class="px-3">
              {{
                'For more information about Nutrition and Feeding, click the link to learn more from'
                  | translate
              }}
              <a
                :href="$t('https://library.zinprofirststep.com/nutrition/')"
                @click="$goToLameness($t('/nutrition/'), $event)"
                target="_blank"
                >{{ 'FirstStep Resources' | translate }}</a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ $t('Cancel') }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </div>

    <hr class="mb-4 mt-1 mt-lg-0" />

    <div class="row align-items-end">
      <div class="col-24">
        <h2 class="h2 mb-lg-4">{{ $t('Feed Access') }}</h2>
      </div>
      <div class="col-lg-12 col-xl-8">
        <label class="mb-1">{{
          $t('Feed Space (') +
            $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
            $t('/cow)')
        }}</label>
        <input
          type="number"
          step="0.01"
          inputmode="decimal"
          v-model.number="converted_feed_space"
          class="form-control"
        />
      </div>
      <div class="col-lg-12 col-xl-8">
        <label class="mb-1">{{
          $t('Percentage of Group Lying Down Chewing Cud')
        }}</label>
        <input
          type="number"
          step="0.01"
          inputmode="decimal"
          v-model.number="
            assessor.data.percentage_of_group_lying_down_chewing_cud
          "
          class="form-control"
        />
      </div>
      <div class="col-lg-12 col-xl-8">
        <label class="mb-1">{{
          $t(
            'What was the time relative to milking or feeding that cud chewing was assessed?'
          )
        }}</label>
        <input
          type="text"
          v-model="assessor.data.time_of_lying_down_chewing_cud_assessment"
          class="form-control"
        />
      </div>
    </div>

    <hr class="mt-4" />

    <question-three
      @change="
        assessor.data.are_there_any_empty_feed_bunks_after_milking =
          $event.value
      "
      :initial-value="
        assessor.data.are_there_any_empty_feed_bunks_after_milking
      "
    >
      <label class="label d-inline-block">
        {{ $t('Are there any empty feed bunks after milking?') }}
      </label>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.are_grazing_cows_held_off_pasture_for_prolonged_periods_other_than_milking_time =
          $event.value
      "
      :initial-value="
        assessor.data
          .are_grazing_cows_held_off_pasture_for_prolonged_periods_other_than_milking_time
      "
    >
      <label class="label  d-inline-block">
        {{
          $t(
            'Are grazing cows held off pasture for prolonged periods (> 1 hour) other than milking time?'
          )
        }}
      </label>
    </question-three>

    <hr class="mb-4" />

    <div class="row">
      <div class="col-24">
        <h2 class="h2 mb-lg-4">{{ $t('Risk of Acidosis') }}</h2>
      </div>
      <div class="col-lg-8">
        <label class="mb-1">{{ $t('Dry Matter %') }}</label>
        <input
          type="number"
          step="0.01"
          inputmode="decimal"
          v-model.number="assessor.data.dry_matter"
          class="form-control"
        />
      </div>
      <div class="col-lg-8">
        <label class="mb-1">{{ $t('TMR Top Screen %') }}</label>
        <input
          type="number"
          step="0.01"
          inputmode="decimal"
          v-model.number="assessor.data.tmr_top_screen"
          class="form-control"
        />
      </div>
      <div class="col-lg-8">
        <label class="mb-1">{{ $t('TMR Middle Screen %') }}</label>
        <input
          type="number"
          step="0.01"
          inputmode="decimal"
          v-model.number="assessor.data.tmr_middle_screen"
          class="form-control"
        />
      </div>
      <div class="col-lg-8">
        <label class="mb-1">{{ $t('TMR Bottom Screen %') }}</label>
        <input
          type="number"
          step="0.01"
          inputmode="decimal"
          v-model.number="assessor.data.tmr_bottom_screen"
          class="form-control"
        />
      </div>
      <div class="col-lg-8">
        <label class="mb-1">{{ $t('TMR Pan %') }}</label>
        <input
          type="number"
          step="0.01"
          inputmode="decimal"
          v-model.number="assessor.data.tmr_pan"
          class="form-control"
        />
      </div>
      <div class="col-lg-8">
        <label class="mb-1">{{
          $t('Rumination Time per Day (minutes)')
        }}</label>
        <input
          type="number"
          step="0.01"
          inputmode="decimal"
          v-model.number="assessor.data.rumination_time_per_day"
          class="form-control"
        />
      </div>
    </div>

    <hr class="mt-4" />

    <question-three
      @change="
        assessor.data.are_feed_delivery_and_pushups_timed_to_maximize_feed_access =
          $event.value
      "
      :initial-value="
        assessor.data
          .are_feed_delivery_and_pushups_timed_to_maximize_feed_access
      "
    >
      <label class="label d-inline-block">
        {{
          'Are feed delivery and pushups timed to maximize feed access?'
            | translate
        }}
      </label>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.are_cows_fed_more_than_of_concentrate_grain_in_the_parlor =
          $event.value
      "
      :initial-value="
        assessor.data.are_cows_fed_more_than_of_concentrate_grain_in_the_parlor
      "
    >
      <label class="label d-inline-block">
        {{

          evaluationClass.isImperial ? 
          'Are cows fed more than 8 lb of concentrate/grain in the parlor?'
           : 'Are cows fed more than 3.6 kg of concentrate/grain in the parlor?' | translate
          
        }}
        <!-- $t('Are cows fed more than ') +
          nutritionandfeeding.getMinAreCowsFedMoreThanOfConcentrateGrainInTheParlor() +
          $t(' ') +
          $t($getEquivalentUnit(evaluation.data.measurements, 'kg')) +
          $t(' of concentrate/grain in the parlor?') + evaluationClass.isImperial -->
      </label>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_there_a_highly_fermentable_carbohydrate_feed_delivered_separate_at_any_time =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_there_a_highly_fermentable_carbohydrate_feed_delivered_separate_at_any_time
      "
    >
      <label class="label d-inline-block">
        {{
          'Is there a highly fermentable carbohydrate feed delivered separate at any time?'
            | translate
        }}
      </label>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.if_rumenocentesis_has_been_performed_does_the_best_indicate_a_problem_with_sara =
          $event.value
      "
      :initial-value="
        assessor.data
          .if_rumenocentesis_has_been_performed_does_the_best_indicate_a_problem_with_sara
      "
    >
      <label class="label d-inline-block">
        {{
          'If rumenocentesis has been performed, does the test indicate a problem with SARA (Sub-Acute Ruminal Acidosis)?'
            | translate
        }}
      </label>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_a_complete_nutrient_workup_required = $event.value
      "
      :initial-value="assessor.data.is_a_complete_nutrient_workup_required"
    >
      <label class="label d-inline-block">
        {{ 'Is a complete nutrient workup required?' | translate }}
      </label>
    </question-three>

    <hr class="mb-4" />

    <div class="row mx-0 mb-3">
      <div class="col-24 pl-0">
        <h2 class="h2 mb-lg-4">{{ 'Nutrition Supply' | translate }}</h2>
      </div>

      <div class="col-lg-12">
        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1">
              {{
                $t('Dry Matter Intake (') +
                  $t($getEquivalentUnit(evaluation.data.measurements, 'kg')) +
                  $t('/day)')
              }}
            </label>
            <label class="mb-0 mr-2 pull-right">{{ $t('Target') }}</label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="converted_dry_matter_intake"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          ></div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{
                $t('Buffer (') +
                  $t($getEquivalentUnit(evaluation.data.measurements, 'g')) +
                  $t('/cow/day)')
              }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              @keypress="$isNumber"
              v-model.number="converted_buffer"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{
              String(nutritionandfeeding.getMinBuffer()).replace(/^(0+)/g, '') +
                $t('-') +
                String(nutritionandfeeding.getMaxBuffer()).replace(/^(0+)/g, '')
            }}
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('Forage NDF %') }}
            </label>
          </div>
          <div class="col-20 col-md-21  pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.forage_ndf"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{
              nutritionandfeeding.getMinForageNdf() +
                $t('-') +
                nutritionandfeeding.getMaxForageNdf()
            }}
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('NDF %') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.ndf"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ $t('≥') + nutritionandfeeding.getMinNdf() }}
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('NFC %') }}
            </label>
          </div>
          <div
            class="col-20 col-md-21  col-lg- pl-0 stall d-flex align-items-center"
          >
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.nfc"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ nutritionandfeeding.getIdealNfcMin() }}-{{
              nutritionandfeeding.getIdealNfcMax()
            }}
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('ADF %') }}
            </label>
          </div>
          <div class="col-20 col-md-21  pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.adf"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ nutritionandfeeding.getIdealAdfMin() }}-{{
              nutritionandfeeding.getIdealAdfMax()
            }}
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('CP %') }}
            </label>
          </div>
          <div class="col-20 col-md-21  pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.cp"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 px-0 d-flex justify-content-center align-items-center ideal"
          >
            {{
              nutritionandfeeding.getMinCp() +
                $t('-') +
                nutritionandfeeding.getMaxCp()
            }}
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('Ca %') }}
            </label>
          </div>
          <div class="col-20 col-md-21  pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.ca"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 px-0 d-flex justify-content-center align-items-center ideal"
          >
            {{
              String(nutritionandfeeding.getMinCa()).replace(/^(0+)/g, '') +
                $t('-') +
                nutritionandfeeding.getMaxCa()
            }}
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('P %') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.p"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{
              String(nutritionandfeeding.getMinP()).replace(/^(0+)/g, '') +
                $t('-') +
                String(nutritionandfeeding.getMaxP()).replace(/^(0+)/g, '')
            }}
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('K %') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.k"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{
              nutritionandfeeding.getMinK() +
                $t('-') +
                nutritionandfeeding.getMaxK()
            }}
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('Mg %') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.mg"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{
              String(nutritionandfeeding.getMinMg()).replace(/^(0+)/g, '') +
                $t('-') +
                String(nutritionandfeeding.getMaxMg()).replace(/^(0+)/g, '')
            }}
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('S %') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.s"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{
              String(nutritionandfeeding.getMinS()).replace(/^(0+)/g, '') +
                $t('-') +
                String(nutritionandfeeding.getMaxS()).replace(/^(0+)/g, '')
            }}
          </div>
        </div>

        <div class="row mb-3 mb-md-0">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('Zn ppm') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.zn_ppm"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{
              nutritionandfeeding.getMinZnPpm() +
                $t('-') +
                nutritionandfeeding.getMaxZnPpm()
            }}
          </div>
          <div class="col-20 col-md-21 pl-0">
            <div class="form-group mb-0 mb-sm-1 mt-3">
              <select
                :class="[
                  'form-control mb-3',
                  {
                    'is-invalid':
                      assessor.data.zn_ppm_select === null &&
                      assessor.data.zn_ppm !== null,
                  },
                ]"
                v-model="assessor.data.zn_ppm_select"
              >
                <option :value="null" disabled>{{
                  'Select' | translate
                }}</option>
                <option value="supplemental" selected>{{
                  'Supplemental' | translate
                }}</option>
                <option value="total_dietary">{{
                  'Total Dietary' | translate
                }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- end left column -->

      <!-- right column -->
      <div class="offset-lg-1 col-lg-11">
        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1">
              {{ $t('Cu ppm') }}
            </label>
            <label class="mb-0 mr-2 d-none d-md-block pull-right">{{
              $t('Target')
            }}</label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.cu_ppm"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{
              nutritionandfeeding.getMinCuPpm() +
                $t('-') +
                nutritionandfeeding.getMaxCuPpm()
            }}
          </div>
          <div class="col-20 col-md-21 pl-0">
            <div class="form-group mb-0 mb-sm-1 mt-3">
              <select
                :class="[
                  'form-control mb-0',
                  {
                    'is-invalid':
                      assessor.data.cu_ppm_select === null &&
                      assessor.data.cu_ppm !== null,
                  },
                ]"
                v-model="assessor.data.cu_ppm_select"
              >
                <option :value="null" disabled>{{
                  'Select' | translate
                }}</option>
                <option value="supplemental" selected>{{
                  'Supplemental' | translate
                }}</option>
                <option value="total_dietary">{{
                  'Total Dietary' | translate
                }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('Mn ppm') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.mn_ppm"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{
              nutritionandfeeding.getMinMnPpm() +
                $t('-') +
                nutritionandfeeding.getMaxMnPpm()
            }}
          </div>
          <div class="col-20 col-md-21 pl-0">
            <div class="form-group mb-0 mb-sm-1 mt-3">
              <select
                :class="[
                  'form-control mb-0',
                  {
                    'is-invalid':
                      assessor.data.mn_ppm_select === null &&
                      assessor.data.mn_ppm !== null,
                  },
                ]"
                v-model="assessor.data.mn_ppm_select"
              >
                <option :value="null" disabled>{{
                  'Select' | translate
                }}</option>
                <option value="supplemental" selected>{{
                  'Supplemental' | translate
                }}</option>
                <option value="total_dietary">{{
                  'Total Dietary' | translate
                }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('Co ppm') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.co_ppm"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{
              String(nutritionandfeeding.getMinCoPpm()).replace(/^(0+)/g, '') +
                $t('-') +
                nutritionandfeeding.getMaxCoPpm()
            }}
          </div>
          <div class="col-20 col-md-21 pl-0">
            <div class="form-group mb-0 mb-sm-1 mt-3">
              <select
                class="form-control mb-0"
                v-model="assessor.data.co_ppm_select"
              >
                <option :value="null" disabled>{{
                  'Select' | translate
                }}</option>
                <option value="supplemental">{{
                  'Supplemental' | translate
                }}</option>
                <option value="total_dietary">{{
                  'Total Dietary' | translate
                }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('I ppm') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.i_ppm"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{
              String(nutritionandfeeding.getMinIPpm()).replace(/^(0+)/g, '') +
                $t('-') +
                nutritionandfeeding.getMaxIPpm()
            }}
          </div>
          <div class="col-20 col-md-21 pl-0">
            <div class="form-group mb-0 mb-sm-1 mt-3">
              <select
                class="form-control mb-0"
                v-model="assessor.data.i_ppm_select"
              >
                <option :value="null" disabled>{{
                  'Select' | translate
                }}</option>
                <option value="supplemental">{{
                  'Supplemental' | translate
                }}</option>
                <option value="total_dietary">{{
                  'Total Dietary' | translate
                }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('Se ppm') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.se_ppm"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{
              String(nutritionandfeeding.getMinSePpm()).replace(/^(0+)/g, '') +
                $t('-') +
                String(nutritionandfeeding.getMaxSePpm()).replace(/^(0+)/g, '')
            }}
          </div>
          <div class="col-20 col-md-21 pl-0">
            <div class="form-group mb-0 mb-sm-1 mt-3">
              <select
                class="form-control mb-0"
                v-model="assessor.data.se_ppm_select"
              >
                <option :value="null" disabled>{{
                  'Select' | translate
                }}</option>
                <option value="supplemental">{{
                  'Supplemental' | translate
                }}</option>
                <option value="total_dietary">{{
                  'Total Dietary' | translate
                }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('Fe ppm') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.fe_ppm"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 px-0 col-md-3 d-flex justify-content-center align-items-center ideal"
          >
            {{
              nutritionandfeeding.getMinFePpm() +
                $t('-') +
                nutritionandfeeding.getMaxFePpm()
            }}
          </div>
          <div class="col-20 col-md-21 pl-0">
            <div class="form-group mb-0 mb-sm-1 mt-3">
              <select
                class="form-control mb-0"
                v-model="assessor.data.fe_ppm_select"
              >
                <option :value="null" disabled>{{
                  'Select' | translate
                }}</option>
                <option value="supplemental">{{
                  'Supplemental' | translate
                }}</option>
                <option value="total_dietary">{{
                  'Total Dietary' | translate
                }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('Mo ppm') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <!-- <input type="number" step="0.01" inputmode="decimal" v-model.number="assessor.data.mo_ppm" :class="['form-control form-control&#45;&#45;freestall mb-0', {'is-invalid':(6 >= nutritionandfeeding.getMoNutritionRatio()) && assessor.data.mo_ppm !== null}]">-->
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.mo_ppm"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            <!-- {{ $t(">6:1") }} -->
          </div>
        </div>

        <div class="row">
          <div class="col-20 pl-0">
            <label class="mb-0 mt-3 mr-1">
              {{ $t('Biotin') + ' (' + $t('mg') + $t('/d)') }}
              <!-- $getEquivalentUnit(evaluation.data.measurements,'mg') -->
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.biotin"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ nutritionandfeeding.getMinBiotin() }}
          </div>
        </div>
      </div>
      <!-- end right column -->
    </div>
    <!-- end nutrition supply -->

    <hr class="my-4" />

    <div class="row mx-0 mb-3">
      <div class="col-24 pl-0">
        <h2 class="h2 mb-lg-4">
          {{ $t('Zinpro Performance Minerals') + ' (' + $t('g') + ')' }}
        </h2>
      </div>

      <div class="col-lg-12">
        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('4-Plex-C') }}
            </label>
            <label class="mb-0 mr-2 pull-right">{{ $t('Target') }}</label>
          </div>

          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.four_plex_c"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ String(nutritionandfeeding.getMin4PlexC()) }}
          </div>
        </div>

        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('Availa-4') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.availa_4"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ String(nutritionandfeeding.getMinAvaila4()) }}
          </div>
        </div>

        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('Availa-Cu 100') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.availa_cu_100"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 px-0 d-flex justify-content-center align-items-center ideal"
          >
            {{ String(nutritionandfeeding.getMinAvailaCu100()) }}
          </div>
        </div>

        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('Availa-Mn 80') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.availa_mn_80"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ String(nutritionandfeeding.getMinAvailaMn80()) }}
          </div>
        </div>

        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('Availa-Zn 120') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.availa_zn_120"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ String(nutritionandfeeding.getMinAvailaZn120()) }}
          </div>
        </div>

        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('Availa-Zn 40') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.availa_zn_40"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ String(nutritionandfeeding.getMinAvailaZn40()) }}
          </div>
        </div>

        <div class="row mb-3 mb-md-0">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('Availa-Plus') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.availa_plus"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ String(nutritionandfeeding.getMinAvailaPlus()) }}
          </div>
        </div>

        <div class="row mb-3 mb-md-0">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('Availa-Dairy') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.availa_dairy"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ String(nutritionandfeeding.getMinAvailaDairy()) }}
          </div>
        </div>
      </div>

      <div class="offset-lg-1 col-lg-11">
        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('Copro 25') }}
            </label>
            <label class="mb-0 mr-2 d-none d-md-block pull-right">{{
              $t('Target')
            }}</label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.copro_25"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 px-0 d-flex justify-content-center align-items-center ideal"
          >
            {{ String(nutritionandfeeding.getMinCopro25()) }}
          </div>
        </div>

        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('CuPlex 100') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.cuplex_100"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 px-0 d-flex justify-content-center align-items-center ideal"
          >
            {{ String(nutritionandfeeding.getMinCuplex100()) }}
          </div>
        </div>

        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('CuPlex 50') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.cuplex_50"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 px-0 d-flex justify-content-center align-items-center ideal"
          >
            {{ String(nutritionandfeeding.getMinCuplex50()) }}
          </div>
        </div>

        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('Manpro 160') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.manpro_160"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ String(nutritionandfeeding.getMinManpro160()) }}
          </div>
        </div>

        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('Zinpro 120') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.zinpro_120"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ String(nutritionandfeeding.getMinZinpro120()) }}
          </div>
        </div>

        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('Zinpro 180') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.zinpro_180"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ String(nutritionandfeeding.getMinZinpro180()) }}
          </div>
        </div>

        <div class="row">
          <div class="col-24 pl-0 pr-1">
            <label class="mb-0 mr-1 mt-3">
              {{ $t('Zinpro 40') }}
            </label>
          </div>
          <div class="col-20 col-md-21 pl-0 stall d-flex align-items-center">
            <input
              type="number"
              step="0.01"
              inputmode="decimal"
              v-model.number="assessor.data.zinpro_40"
              class="form-control form-control--freestall mb-0"
            />
          </div>
          <div
            class="col-4 col-md-3 d-flex justify-content-center align-items-center px-0 ideal"
          >
            {{ String(nutritionandfeeding.getMinZinpro40()) }}
          </div>
        </div>
      </div>
      <!-- end right column -->
    </div>
    <!-- end Zinpro Performance Minerals -->

    <hr class="mt-4" />

    <div class="row my-0">
      <div class="col-md-12">
        <personal-notes v-model="assessor.data.personal_notes"></personal-notes>
      </div>
      <div class="col-md-12">
        <presentation-notes
          v-model="assessor.data.presentation_notes"
        ></presentation-notes>
      </div>
    </div>

    <div
      class="row mx-0 justify-content-center justify-content-md-start center align-items-center pb-0 mt-4"
    >
      <photoUpload :assessorId="14" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

    <div class="row misc-bottom">
      <div class="col-md-12 flex-sm-row-reverse">
        <a
          class="btn btn-secondary btn-full--sm mb-2 mt-md-0"
          href="javascript:history.go(-1)"
          >{{ 'Back' | translate }}</a
        >
      </div>
      <div class="col-md-12">
        <router-link
          v-if="user && user.role !== 'data_entry'"
          :to="'/reports'"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Reports' | translate }}
        </router-link>
        <button
          v-else-if="
            user && user.role === 'data_entry' && !$store.state.offline
          "
          data-toggle="modal"
          @click="requestReport(evaluation.name)"
          :data-target="['#requestSent-' + user.id]"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Request Report' | translate }}
          <span v-show="requestReportLoader" class="loader ml-1"></span>
        </button>
      </div>
    </div>

    <div
      v-show="successMessage"
      class="alert alert-primary mt-3 mb-0"
      role="alert"
    >
      {{ 'Your request has been sent!' | translate }}
    </div>

    <div
      v-show="errorMessage"
      class="alert alert-danger mt-3 mb-0"
      role="alert"
    >
      {{ 'An error has occurred during your request!' | translate }}
    </div>
  </div>
  <!-- end container -->
</template>

<script>
import _ from 'lodash';
import PresentationNotes from '@/pages/assessors/assessor_components/presentation-notes';
import PersonalNotes from '@/pages/assessors/assessor_components/personal-notes';
import Question from '@/pages/assessors/assessor_components/question';
import QuestionThree from '@/pages/assessors/assessor_components/question-three';
import ModalInfo from '@/components/misc/modal-info';
import PhotoUpload from '@/components/misc/photoUpload';
import HygieneHeader from '@/pages/assessors/assessor_components/hygiene-header';
import convert from 'convert-units';
import NutritionAndFeeding from '@/libs/Classes/NutritionAndFeeding.js';
import Evaluation from '@/libs/Classes/Evaluation.js';
import Group from '@/libs/Classes/Group.js';
import requestReportMixin from '@/mixins/requestReport.js';

export default {
  components: {
    PersonalNotes,
    PresentationNotes,
    Question,
    QuestionThree,
    HygieneHeader,
    PhotoUpload,
    ModalInfo,
  },
  props: ['assessor', 'group', 'evaluation'],

  mixins: [requestReportMixin],

  data() {
    return {
      nutritionandfeeding: null,
      evaluationClass: null
    };
  },
  created() {
    this.evaluationClass = new Evaluation(this, this.evaluation);
    let groupClass = new Group(this, this.evaluationClass, this.group);
    this.nutritionandfeeding = new NutritionAndFeeding(
      this,
      this.evaluationClass,
      groupClass,
      this.assessor
    );
  },
  computed: {
    converted_feed_space: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getFeedSpace(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setFeedSpace(newValue);
      },
    },
    converted_dry_matter_intake: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getDryMatterIntake(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setDryMatterIntake(newValue);
      },
    },
    converted_buffer: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getBuffer(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setBuffer(newValue);
      },
    },
    // converted_biotin: {
    // 	get: function () {return (this.nutritionandfeeding !== null ) ? this.nutritionandfeeding.getBiotin(0) : false},
    //     set: function (newValue) {return this.nutritionandfeeding.setBiotin(newValue)}
    // },
    converted_4_plex_c: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.assessor.data.four_plex_c
          : 0;
      }, //this.nutritionandfeeding.get4PlexC(0) : false},
      set: function(newValue) {
        this.assessor.data.four_plex_c = newValue;
      },
    },
    converted_availa_4: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getAvaila4(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setAvaila4(newValue);
      },
    },
    converted_availa_cu_100: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getAvailaCu100(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setAvailaCu100(newValue);
      },
    },
    converted_availa_mn_80: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getAvailaMn80(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setAvailaMn80(newValue);
      },
    },
    converted_availa_zn_120: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getAvailaZn120(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setAvailaZn120(newValue);
      },
    },
    converted_availa_zn_40: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getAvailaZn40(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setAvailaZn40(newValue);
      },
    },
    converted_availa_plus: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getAvailaPlus(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setAvailaPlus(newValue);
      },
    },
    converted_copro_25: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getCopro25(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setCopro25(newValue);
      },
    },
    converted_cuplex_100: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getCuplex100(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setCuplex100(newValue);
      },
    },
    converted_cuplex_50: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getCuplex50(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setCuplex50(newValue);
      },
    },
    converted_zinpro_120: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getZinpro120(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setZinpro120(newValue);
      },
    },
    converted_zinpro_180: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getZinpro180(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setZinpro180(newValue);
      },
    },
    converted_zinpro_40: {
      get: function() {
        return this.nutritionandfeeding !== null
          ? this.nutritionandfeeding.getZinpro40(0)
          : false;
      },
      set: function(newValue) {
        return this.nutritionandfeeding.setZinpro40(newValue);
      },
    },
  },
  watch: {
    // 'assessor.data': {
    // 	deep: true
    // },
    'assessor.data.dry_matter': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.dry_matter = 100;
      } else if (newVal < 0) {
        this.assessor.data.dry_matter = null;
      }
    },
    'assessor.data.percentage_of_group_lying_down_chewing_cud': function(
      newVal,
      oldVal
    ) {
      if (newVal > 100) {
        this.assessor.data.percentage_of_group_lying_down_chewing_cud = 100;
      } else if (newVal < 0) {
        this.assessor.data.percentage_of_group_lying_down_chewing_cud = null;
      }
    },
    'assessor.data.tmr_top_screen': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.tmr_top_screen = 100;
      } else if (newVal < 0) {
        this.assessor.data.tmr_top_screen = null;
      }
    },
    'assessor.data.tmr_middle_screen': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.tmr_middle_screen = 100;
      } else if (newVal < 0) {
        this.assessor.data.tmr_middle_screen = null;
      }
    },
    'assessor.data.tmr_bottom_screen': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.tmr_bottom_screen = 100;
      } else if (newVal < 0) {
        this.assessor.data.tmr_bottom_screen = null;
      }
    },
    'assessor.data.tmr_pan': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.tmr_pan = 100;
      } else if (newVal < 0) {
        this.assessor.data.tmr_pan = null;
      }
    },
    'assessor.data.forage_ndf': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.forage_ndf = 100;
      } else if (newVal < 0) {
        this.assessor.data.forage_ndf = null;
      }
    },
    'assessor.data.ndf': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.ndf = 100;
      } else if (newVal < 0) {
        this.assessor.data.ndf = null;
      }
    },
    'assessor.data.nfc': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.nfc = 100;
      } else if (newVal < 0) {
        this.assessor.data.nfc = null;
      }
    },
    'assessor.data.adf': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.adf = 100;
      } else if (newVal < 0) {
        this.assessor.data.adf = null;
      }
    },
    'assessor.data.cp': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.cp = 100;
      } else if (newVal < 0) {
        this.assessor.data.cp = null;
      }
    },
    'assessor.data.ca': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.ca = 100;
      } else if (newVal < 0) {
        this.assessor.data.ca = null;
      }
    },
    'assessor.data.p': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.p = 100;
      } else if (newVal < 0) {
        this.assessor.data.p = null;
      }
    },
    'assessor.data.k': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.k = 100;
      } else if (newVal < 0) {
        this.assessor.data.k = null;
      }
    },
    'assessor.data.mg': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.mg = 100;
      } else if (newVal < 0) {
        this.assessor.data.mg = null;
      }
    },
    'assessor.data.s': function(newVal, oldVal) {
      if (newVal > 100) {
        this.assessor.data.s = 100;
      } else if (newVal < 0) {
        this.assessor.data.s = null;
      }
    },
  },
};
</script>
