import { mapState } from 'vuex';

export default {
  methods: {
    getCompanyName(object) {
      const company = this.companies.find(
        ({ _id }) => _id === this[object].company
      );
      return {
        companyName: company ? company.name : '',
      };
    },
  },
  computed: {
    ...mapState({
      companies: state => state.company.companies,
    }),

    userCompanies() {
      return this.companies.filter(
        ({ createdBy }) => createdBy === this.user._id
      );
    },
  },
};
