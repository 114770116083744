<template>
    <div class="container main-container main-container--hygiene view">
        <div class="row">
            <div class="col-lg-24">
                <h2 class="h2 d-inline-block">{{'Tell Us About Your Races, Tracks and Lanes' | translate}}</h2>
                <modal-info :value="'tell-us'" class="d-inline">
                    <template slot="modal-content">
                        <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ 'Races, Tracks and Lanes' | translate }}</h2>
						<p class="px-3">{{ 'For more information about Races, Tracks and Lanes, click the link to learn more from' | translate}}
							<a :href="$t('https://library.zinprofirststep.com/races-tracks-lanes/')" @click="$goToLameness($t('/races-tracks-lanes/'), $event);" target="_blank">{{'FirstStep Resources' | translate}}</a>.
						</p>
                        <div class="row modal-bottom pb-3">
                            <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ "Cancel" | translate }}</a>
                        </div>
                    </template>
				</modal-info>
			</div>
		</div>

		<hr class="mb-lg-4">

        <question-three @change="assessor.data.is_the_free_choice_walking_speed_of_cows = $event.value" :initial-value="assessor.data.is_the_free_choice_walking_speed_of_cows">
            <div class="mb-1">
                <label class="label d-inline">
                    {{ $t("Do cows walk at an average pace of ") + racetrack.getTargetIsTheFreeChoiceWalkingSpeedOfCows() + $t(' ') + $t("?") }}
                </label>
            </div>
        </question-three>

        <div v-if="assessor.data.is_the_free_choice_walking_speed_of_cows !== 1">
            <hr>

            <h2 class="h2 d-inline-block">{{'Construction Base Layer' | translate}}</h2>

            <question-three @change="assessor.data.is_base_layer_compacted_and_dry = $event.value" :initial-value="assessor.data.is_base_layer_compacted_and_dry">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is the base layer compacted and dry?") }}
                        <modal-info :value="'base-layer'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{'Is the base layer compacted and dry?' | translate}}</h2>
                                <p class="px-3">{{'Base layer information may be obtained by cutting into race cross section.' | translate}}</p>
                                <div class="row modal-bottom pb-3"><a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ 'Cancel' | translate }}</a></div>
                            </template>
                        </modal-info>
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.above_water_table = $event.value" :initial-value="assessor.data.above_water_table">
                <div class="mb-1">
                    <label class="label d-inline">
                        <!-- {{ $t("Is the base layer at least ") + racetrack.getTargetAboveWaterTable() + $t(" ") + $t($getEquivalentUnit(evaluation.data.measurements,'cm')) + $t(" above the water table?") }} -->
                   {{ evaluationClass.isImperial ? 'Is the base layer at least 24 in above the water table?' : 'Is the base layer at least 60 cm above the water table?' | translate  }}
                    </label>
                </div>
            </question-three>

            <hr class="mb-4">

            <h2 class="h2 d-inline-block">{{'Construction Top Layer' | translate}}</h2>

            <question-three @change="assessor.data.is_top_layer_smooth_and_compacted = $event.value" :initial-value="assessor.data.is_top_layer_smooth_and_compacted">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is the top layer smooth and compacted?") }}
                        <modal-info :value="'top-layer'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{'Is the top layer smooth and compacted?' | translate}}</h2>
                                <p class="px-3">{{'To determine if top course is resisting disintegration, kick/scrape with boot to test; should be unable to dislodge material.' | translate}}</p>
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ 'Cancel' | translate }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.are_there_pot_holes_present = $event.value" :initial-value="assessor.data.are_there_pot_holes_present">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Are there pot holes present?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.is_surface_slippery_when_wet = $event.value" :initial-value="assessor.data.is_surface_slippery_when_wet">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is the surface slippery when wet?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.machine_compacted = $event.value" :initial-value="assessor.data.machine_compacted">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is the top layer machine compacted?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.gravel_surface = $event.value" :initial-value="assessor.data.gravel_surface">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is the top layer gravel?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.crowned_or_tilted = $event.value" :initial-value="assessor.data.crowned_or_tilted">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is the top layer crowned or tilted 1:10?") }}
                        <modal-info :value="'crowned'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{'Is the top layer crowned or tilted 1:10?' | translate}}</h2>
                                <p class="px-3">
                                    <!-- {{$t('1:10 refers to 1 ') + $t($getEquivalentUnit(evaluation.data.measurements, 'm')) + $t(' of drop for every 10 ') + $t($getEquivalentUnit(evaluation.data.measurements, 'm')) + $t(' of length.') }} -->
                              {{ evaluationClass.isImperial ? '1:10 refers to 1 ft of drop for every 10 ft of length.'  : '1:10 refers to 1 m of drop for every 10 m of length.'  | translate }}
                                </p>
                                <div class="row modal-bottom pb-3"><a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ 'Cancel' | translate }}</a></div>
                            </template>
                        </modal-info>
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.any_scouring_on_steep_section_diversion_bars_not_in_place = $event.value" :initial-value="assessor.data.any_scouring_on_steep_section_diversion_bars_not_in_place">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Any scouring on steep section or diversion bars not in place?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.any_track_junctures_to_lower_quality_surfaces = $event.value" :initial-value="assessor.data.any_track_junctures_to_lower_quality_surfaces">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Any track junctures to lower quality surfaces?") }}
                        <modal-info :value="'track-junctures'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{'Any track junctures to lower quality surfaces?' | translate}}</h2>
                                <p class="px-3">{{'Track junctures are where two different tracks or track materials join together.' | translate}}</p>
                                <div class="row modal-bottom pb-3"><a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ 'Cancel' | translate }}</a></div>
                            </template>
                        </modal-info>
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.any_puddles_wet_or_muddy_areas = $event.value" :initial-value="assessor.data.any_puddles_wet_or_muddy_areas">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Any puddles, wet or muddy areas?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.does_the_race_pass_the_human_walk_test = $event.value" :initial-value="assessor.data.does_the_race_pass_the_human_walk_test">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Does the race pass the human walk test?") }}
                        <modal-info :value="'race-pass'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{'Does the race pass the human walk test?' | translate}}</h2>
                                <p class="px-3">{{'Are sections comfortable to walk in plastic disposable boots or bare feet, preferably in wet conditions' | translate}}</p>
                                <div class="row modal-bottom pb-3"><a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ 'Cancel' | translate }}</a></div>
                            </template>
                        </modal-info>
                    </label>
                </div>
            </question-three>

            <hr class="mb-4">

            <h2 class="h2 d-inline-block">{{ 'Layout/Width' | translate }}</h2>

            <div class="row">
                <div class="col-lg-8">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Group Size") }}
                        </label>
                    </div>
                    <input :value="groupClass.getGroupSize()" type="number" inputmode="numeric" @keypress="$isNumber" class="form-control" disabled>
                </div>

                <div class="col-lg-8">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Track Width ") + "(" + $t($getEquivalentUnit(evaluation.data.measurements, 'm')) + ")" }}
                        </label>
                    </div>
                    <input v-model.number="converted_track_width" type="number" inputmode="decimal" class="form-control">
                </div>
            </div>

            <hr>

            <question @change="assessor.data.is_full_width_available = $event.value" :initial-value="assessor.data.is_full_width_available">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is the full width available?") }}
                        <modal-info :value="'full-width'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{'Obstacles Scare Cows' | translate}}</h2>
                                <p class="px-3">{{ $t('Are obstructions present that often cause track width to vary?') }}</p>
                                <div class="row modal-bottom pb-3"><a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ 'Cancel' | translate }}</a></div>
                            </template>
                        </modal-info>
                    </label>
                </div>
            </question>

            <hr>

            <question @change="assessor.data.are_there_any_materials = $event.value" :initial-value="assessor.data.are_there_any_materials">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is there debris in or beside the track that cows tend to avoid?") }}
                    </label>
                </div>
            </question>

            <hr>

            <question-three @change="assessor.data.corners_replaced_by_curved_corners = $event.value" :initial-value="assessor.data.corners_replaced_by_curved_corners">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Are all 90˚ corners replaced by curved corners?") }}
                        <modal-info :value="'corners-replaced'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{'Obstacles Scare Cows' | translate}}</h2>
                                <div class="wistia_responsive_padding mx-3" style="padding:56.25% 0 0 0;position:relative;">
                                    <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                                        <div class="wistia_embed wistia_async_80dhh7063k videoFoam=true" style="height:100%;width:100%">&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="row modal-bottom pb-3"><a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ 'Cancel' | translate }}</a></div>
                            </template>
                        </modal-info>
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.gate_width_race_width = $event.value" :initial-value="assessor.data.gate_width_race_width">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is the gate width ≥ race width?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.gates_angled_at_race = $event.value" :initial-value="assessor.data.gates_angled_at_race">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Are the gates angled at the race end?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.are_exit_surfaces_capped_dry_well_drained_and_firm = $event.value" :initial-value="assessor.data.are_exit_surfaces_capped_dry_well_drained_and_firm">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Are the exit surfaces capped, dry/well drained and firm?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <div class="row">
                <div class="col-md-12 col-lg-11 col-xl-13 d-lg-flex align-self-center">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("What is the furthest distance from paddock to milking shed?") }}
                        </label>
                    </div>
                </div>
                <div class="col-md-12 col-lg-13 col-xl-11 d-flex flex-row flex-lg-row justify-content-center justify-content-md-end flex-md-wrap flex-lg-nowrap">
                    <div class="label_group label_group--width-sm label_group--inline mr-2 mr-sm-2 ml-0">
                        <input class="label_group__input" v-model="assessor.data.furthest_distance_from_paddock_to_milking_shed" type="radio" id="minId" value="-1"/>
                        <label class="label_group__label px-0" for="minId">{{ $t('< ') + racetrack.getMinDistanceFromPaddockToMilkingShed() + $t(' ') + $t($getEquivalentUnit(evaluation.data.measurements,'km')) }}</label>
                    </div>
                    <div class="label_group label_group--width-sm label_group--inline">
                        <input class="label_group__input" v-model="assessor.data.furthest_distance_from_paddock_to_milking_shed" type="radio" id="midId" value="0"/>
                        <label class="label_group__label px-0" for="midId">{{ racetrack.getMidDistanceFromPaddockToMilkingShed() + $t(' ') + $t($getEquivalentUnit(evaluation.data.measurements,'km')) }}</label>
                    </div>
                    <div class="label_group label_group--width-sm label_group--inline ml-2">
                        <input class="label_group__input" v-model="assessor.data.furthest_distance_from_paddock_to_milking_shed" type="radio" id="maxId" value="1"/>
                        <label class="label_group__label px-0" for="maxId">{{ $t('> ') + racetrack.getMaxDistanceFromPaddockToMilkingShed() + $t(' ') + $t($getEquivalentUnit(evaluation.data.measurements,'km')) }}</label>
                    </div>
                </div>
            </div>

            <hr>

            <question-three @change="assessor.data.are_water_and_feed_troughs_away_from_exits = $event.value" :initial-value="assessor.data.are_water_and_feed_troughs_away_from_exits">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Are the water and feed troughs away from the exits?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.auto_gate_latches_used = $event.value" :initial-value="assessor.data.auto_gate_latches_used">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Are auto gate latches used?") }}
                        <modal-info :value="'gate-latches'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ 'Are auto gate latches used?' | translate}}</h2>
                                <p class="px-3">{{ $t('Auto gate latches hold an elastic hot wire across the gate with a spring-loaded catch. At the pre-set time, a buzzer alarm sounds alerting the cows, and the catch releases the elastic hot wire so that the gate opens.') }}</p>
                                <p class="px-3">{{ $t('The advantage of this system is that no person has to go out to the paddock to open the gates, gather up the cows and herd them to the milking parlor or feed pad. If there is a feed-pad waiting with fresh feed, the cows will be very motivated to voluntarily walk to the shed/pad. This subsequently removes the need for a herdsman to walk behind the cows, so that there will be no pushing of the herd.') }}</p>
                                <!-- leads to a broken link -->
                                <!-- <a class="d-inline-block px-3 mb-3" href="http://www.novel.co.nz/battlatch.html" target="_blank">http://www.novel.co.nz/battlatch.html</a> -->
                                <div class="row modal-bottom pb-3">
                                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ 'Cancel' | translate }}</a>
                                </div>
                            </template>
                        </modal-info>
                    </label>
                </div>
            </question-three>

            <hr class="mb-4">

            <h2 class="h2 d-inline-block">{{'Terminal' | translate}}</h2>

            <question-three @change="assessor.data.is_the_terminal_extra_wide = $event.value" :initial-value="assessor.data.is_the_terminal_extra_wide">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is the terminal extra wide? ") + "(" + $t("the last ") + racetrack.getTargetIsTheTerminalExtraWide() + " " + $t($getEquivalentUnit(evaluation.data.measurements,'m')) + ")" }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.is_the_yard_entrance_width_race_width = $event.value" :initial-value="assessor.data.is_the_yard_entrance_width_race_width">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is the yard entrance width ≥ race width?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.do_cows_have_to_turn_to_enter_the_yard = $event.value" :initial-value="assessor.data.do_cows_have_to_turn_to_enter_the_yard">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Do cows have to turn to enter the yard?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.firm_smooth_non_slip_surface = $event.value" :initial-value="assessor.data.firm_smooth_non_slip_surface">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is the surface firm, smooth and non-slip?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.excellent_drainage = $event.value" :initial-value="assessor.data.excellent_drainage">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is there excellent drainage?") }}
                    </label>
                </div>
            </question-three>

            <hr>

            <question-three @change="assessor.data.are_stones_brought_onto_yards = $event.value" :initial-value="assessor.data.are_stones_brought_onto_yards">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Are stones brought onto yards?") }}
                    </label>
                </div>
            </question-three>

            <hr class="mb-4">

            <h2 class="h2 d-inline-block">{{'Maintenance' | translate}}</h2>

            <question @change="assessor.data.documented_maintenance_plan_adhered_to = $event.value" :initial-value="assessor.data.documented_maintenance_plan_adhered_to">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is a documented maintenance plan adhered to?") }}
                    </label>
                </div>
            </question>

            <hr>

            <question @change="assessor.data.emergency_repairs_carried_out_ASAP = $event.value" :initial-value="assessor.data.emergency_repairs_carried_out_ASAP">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Are emergency repairs carried out as soon as possible?") }}
                    </label>
                </div>
            </question>

            <hr class="mb-4">

            <h2 class="h2 d-inline-block">{{'Herdsman' | translate}}</h2>

            <question @change="assessor.data.are_cows_pushed = $event.value" :initial-value="assessor.data.are_cows_pushed">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Are cows pushed?") }}
                        <modal-info :value="'cows-pushed'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{'Walking and Pushed' | translate}}</h2>
                                <div class="wistia_responsive_padding mx-3" style="padding:56.25% 0 0 0;position:relative;">
                                    <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                                        <div class="wistia_embed wistia_async_qcx6c9af4y videoFoam=true" style="height:100%;width:100%">&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="row modal-bottom pb-3"><a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ 'Cancel' | translate }}</a></div>
                            </template>
                        </modal-info>
                    </label>
                </div>
            </question>

            <hr>

            <question @change="assessor.data.distance_between_herdsman_and_tail_ender_cows = $event.value" :initial-value="assessor.data.distance_between_herdsman_and_tail_ender_cows">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Is the distance between the herdsman and tail-end of cows ≥ ") + racetrack.getTargetDistanceBetweenHerdsmanAndTailEnderCows() + ' ' + $t($getEquivalentUnit(evaluation.data.measurements,'m')) + "?" }}
                    </label>
                </div>
            </question>

            <hr>

            <question @change="assessor.data.allows_cows_time_to_navigate_obstacles = $event.value" :initial-value="assessor.data.allows_cows_time_to_navigate_obstacles">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Does the herdsman allow cows time to navigate all obstacles?") }}
                        <modal-info :value="'allows-cows'" class="d-inline">
                            <template slot="modal-content">
                                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{'Stone Navigation' | translate}}</h2>
                                <div class="wistia_responsive_padding mx-3" style="padding:56.25% 0 0 0;position:relative;">
                                    <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                                        <div class="wistia_embed wistia_async_5prj4llbto videoFoam=true" style="height:100%;width:100%">&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <p class="px-3">{{'Given time and freedom, cows will successfully navigate around obstacles.' | translate}}</p>
                                <div class="row modal-bottom pb-3"><a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ 'Cancel' | translate }}</a></div>
                            </template>
                        </modal-info>
                    </label>
                </div>
            </question>

            <hr>

            <question @change="assessor.data.no_yelling_claxons_revving_motor = $event.value" :initial-value="assessor.data.no_yelling_claxons_revving_motor">
                <div class="mb-1">
                    <label class="label d-inline">
                        {{ $t("Are loud noises kept to a minimum? Are dogs kept well back, and not barking?") }}
                    </label>
                </div>
            </question>
        </div>

        <div v-else class="alert alert-primary mt-4 mb-0" role="alert">
            {{ 'Good! You do not have to complete this assessment.' | translate }}
        </div>

        <hr>

		<div class="row my-0">
			<div class="col-md-12">
				<personal-notes v-model="assessor.data.personal_notes"></personal-notes>
			</div>
			<div class="col-md-12">
				<presentation-notes v-model="assessor.data.presentation_notes"></presentation-notes>
			</div>
		</div>

		<div class="row mx-0 mt-4 justify-content-center justify-content-md-start center align-items-center">
			<photoUpload :assessorId="9" :assessor="assessor"></photoUpload>
		</div>

		<hr class="mb-3 mb-md-5 mt-3 mt-md-5">

        <div class="row misc-bottom">
            <div class="col-md-12 flex-sm-row-reverse">
                <a class="btn btn-secondary btn-full--sm mb-2 mt-md-0" href="javascript:history.go(-1)">{{ "Back" | translate }}</a>
            </div>
            <div class="col-md-12">
                <router-link v-if="user && user.role !== 'data_entry'" :to="'/reports'" class="btn btn-primary btn-full--sm pull-right">
                    {{"Reports" | translate}}
                </router-link>
                <button v-else-if="user && user.role === 'data_entry' && !$store.state.offline" data-toggle="modal" @click="requestReport(evaluation.name)" :data-target="['#requestSent-' + user.id]" class="btn btn-primary btn-full--sm pull-right">
                    {{ 'Request Report' | translate }}
                    <span v-show="requestReportLoader" class="loader ml-1"></span>
                </button>
            </div>
        </div>

        <div v-show="successMessage" class="alert alert-primary mt-3 mb-0" role="alert">
            {{ 'Your request has been sent!' | translate }}
        </div>

        <div v-show="errorMessage" class="alert alert-danger mt-3 mb-0" role="alert">
            {{ 'An error has occurred during your request!' | translate }}
        </div>

    </div>
</template>

<script>
    import _ from 'lodash';
    import PresentationNotes from './assessor_components/presentation-notes';
    import PersonalNotes from './assessor_components/personal-notes';
    import Question from './assessor_components/question';
    import QuestionThree from './assessor_components/question-three';
    import ModalInfo from '@/components/misc/modal-info.vue';
    import PhotoUpload from '@/components/misc/photoUpload';
    import HygieneHeader from './assessor_components/hygiene-header.vue';
    import Racetrack from '@/libs/Classes/Racetrack.js';
    import Evaluation from '@/libs/Classes/Evaluation.js';
    import Group from '@/libs/Classes/Group.js';
    import convert from 'convert-units';
    import requestReportMixin from '@/mixins/requestReport.js'; 

    export default {
        components: {
            PersonalNotes,
            PresentationNotes,
            Question,
            QuestionThree,
            HygieneHeader,
            PhotoUpload,
            ModalInfo
        },
        props: ['assessor', 'group', 'evaluation'],
            mixins: [requestReportMixin],   
        data() {
            return {
                racetrack: null,
                evaluationClass: null,
            }
        },
        computed: {
            converted_track_width: {
                get: function() { return this.racetrack.getTrackWidth(false) },
                set: function(newValue) { return this.racetrack.setTrackWidth(newValue) }
            }
        },
        methods: {
        },
        created() {
            this.evaluationClass = new Evaluation(this, this.evaluation);
            this.groupClass = new Group(this, this.evaluationClass, this.group);
            // this.user = this.$store.getters.getData("user");
            this.racetrack = new Racetrack(this, this.evaluationClass, this.groupClass, this.assessor);
        }
    }
</script>

