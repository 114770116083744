<template>
    <transition name="modal">
        <div class="modal-overlay d-flex align-items-center" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog--edit" role="document">
                <div class="modal-content edit-modal pt-0 px-3 pb-3">
                    <div class="modal-body my-0 py-0 px-0">
                        <h2 class="h2 px-3 text-center mt-3">
                            {{ (headline ? headline : "Are you sure? Your content has not been saved.") | translate }}
                        </h2>
                        <p v-if="description" class="mb-4" v-html="description"></p>
                        <div class="d-flex justify-content-between">
                          <router-link
                                  v-if="link && link !== null"
                                  :to="link"
                                  @click.native="$emit('close')"
                                  class="btn btn-primary "
                          >
                              {{ yesText | translate }}
                          </router-link>
                          <button v-else
                                  @click="onClick()"
                                  class="btn btn-primary "
                                  :class="{'btn-full':!showNoButton }"

                          >
                              {{ yesText | translate}}
                          </button>
                          <button v-if="changeFootbathCall"
                                  @click="revertFunction();$emit('close');"
                                  class="btn btn-primary btn-full--modal"
                          >
                              {{ noText | translate }}
                          </button>
                          <button v-else-if="showNoButton"
                                  @click="$emit('close')"
                                  class="btn btn-primary btn-full--modal"

                          >
                              {{ noText | translate }}
                          </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  data () {
    return {
      showModal: false
    }
  },
  props: {
    value: String,
    link: String,
    changeFootbathCall: Boolean,
    onClick: Function,
    revertFunction: Function,
    headline: String,
    description: String,
    showNoButton: {
      default: true,
      type: Boolean
    },
    yesText: {
      default: 'Yes',
      type: String
    },
    noText: {
      default: 'No',
      type: String
    }
  }
}
</script>
