<template>
  <div class="card--measurements card--measurements--small">
    <div class="row card--measurements__first-row">
      <div class="col-md-24 measurements_heading">
        <p class="p--measurements-heading mb-0 d-inline-block">
          {{ 'Measurements' | translate }}
        </p>
        <!--        <modal-info :value="'measurements'" class="d-inline-block" icon-color="white">-->
        <!--            <template slot="modal-content">-->
        <!--                <h2 class="h2 pb-2 pt-3 px-3 text-center">{{"Measurements" | translate}}</h2>-->
        <!--                <div class="row modal-bottom pb-3">-->
        <!--                    <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ "Cancel" | translate }}</a>-->
        <!--                </div>-->
        <!--            </template>-->
        <!--        </modal-info>-->
      </div>
    </div>
    <div v-if="assessor.data.summer_ventilation_system === 'cross'" class="row">
      <div class="col-md-24">
        <div class="form-group pt-4">
          <label>{{
            $t('Barn Length') + ' (' + $t($getEquivalentUnit(evaluation.data.measurements, 'm')) + ')'
          }}</label>
          <input type="number" inputmode="decimal" class="form-control" v-model.number="computed_barn_length" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-24">
        <div :class="['form-group', { 'pt-4': assessor.data.summer_ventilation_system === 'tunnel' }]">
          <label>{{ $t('Barn Width') + ' (' + $t($getEquivalentUnit(evaluation.data.measurements, 'm')) + ')' }}</label>
          <input type="number" inputmode="decimal" class="form-control" v-model.number="computed_barn_width" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-24">
        <div class="form-group">
          <label>{{
            $t('Barn Ceiling Height') + ' (' + $t($getEquivalentUnit(evaluation.data.measurements, 'm')) + ')'
          }}</label>
          <input type="number" inputmode="decimal" class="form-control" v-model.number="computed_ceiling_height" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-24">
        <div class="form-group">
          <label>{{
            $t('Inlet area') + ' (' + $t($getEquivalentUnit(evaluation.data.measurements, 'sq m')) + ')'
          }}</label>
          <input type="number" inputmode="decimal" class="form-control" v-model.number="computed_inlet_area" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-24">
        <div class="form-group">
          <label>{{
            $t('Cross-Sectional Area') + ' (' + $t($getEquivalentUnit(evaluation.data.measurements, 'sq m')) + ')'
          }}</label>
          <input
            type="number"
            inputmode="decimal"
            class="form-control"
            :value="computed_cross_sectional_area"
            readonly
          />
        </div>
      </div>
    </div>
    <div v-if="assessor.data.summer_ventilation_system === 'cross'" class="row">
      <div class="col-md-24">
        <div class="form-group">
          <label>{{
            $t('Barn Volume') + ' (' + $t($getEquivalentUnit(evaluation.data.measurements, 'cu meters')) + ')'
          }}</label>
          <input type="number" inputmode="decimal" class="form-control" :value="volume" readonly />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalInfo from '@/components/misc/modal-info.vue';
import TieStallStanchionHeatAbatement from '@/libs/Classes/TieStallStanchionHeatAbatement.js';
import Evaluation from '@/libs/Classes/Evaluation.js';
import Group from '@/libs/Classes/Group.js';
import convert from 'convert-units';
import { mapState } from 'vuex';

export default {
  components: {
    ModalInfo,
  },
  inject: ['$validator'],
  data() {
    return {
      evaluationClass: null,
      group: null,
      assessor: null,
    };
  },

  created() {
    // this.evaluation = this.$store.getters.getData('users/' + this.$route.params.userId + '/evaluations/' + this.$route.params.evaluationId);
    this.evaluationClass = new Evaluation(this, this.evaluation);
    this.groupClass = new Group(this, this.evaluationClass, this.group);
    // this.group = this.$store.getters.getData('users/' + this.$route.params.userId + '/evaluations/' + this.$route.params.evaluationId + "/groups/" + this.$route.params.groupId);
    this.group = this.evaluation.groups[this.$route.params.groupId]
    let assessorType = this.$route.params.assessorType;
    if (this.group.assessors !== undefined) {
      this.assessor = this.group.assessors[Number(assessorType)];
      // this.assessor = this.group.assessors.find(assessor => {
      //     return Number(assessor.type) === Number(assessorType);
      // });
    }
    // this.user = this.$store.getters.getData("user");
    this.tiestallstanchionheatabatement = new TieStallStanchionHeatAbatement(
      this,
      this.evaluationClass,
      this.groupClass,
      this.assessor
    );
  },
  computed: {
  ...mapState({
      evaluation: state => state.evaluations.evaluation,
    }),
  computed_barn_length: {
      get: function() {
        return this.tiestallstanchionheatabatement.getBarnLength(false);
      },
      set: function(newValue) {
        return this.tiestallstanchionheatabatement.setBarnLength(newValue);
      },
    },
    computed_barn_width: {
      get: function() {
        return this.tiestallstanchionheatabatement.getBarnWidth(false);
      },
      set: function(newValue) {
        return this.tiestallstanchionheatabatement.setBarnWidth(newValue);
      },
    },
    computed_ceiling_height: {
      get: function() {
        return this.tiestallstanchionheatabatement.getBarnCeilingHeight(false);
      },
      set: function(newValue) {
        return this.tiestallstanchionheatabatement.setBarnCeilingHeight(newValue);
      },
    },
    computed_inlet_area: {
      get: function() {
        return this.tiestallstanchionheatabatement.getInletArea(false);
      },
      set: function(newValue) {
        return this.tiestallstanchionheatabatement.setInletArea(newValue);
      },
    },
    computed_cross_sectional_area: {
      get: function() {
        return this.tiestallstanchionheatabatement.getCrossSectionalArea(false);
      },
    },
    volume: {
      get: function() {
        return this.tiestallstanchionheatabatement.getBarnVolume();
      },
    },
  },
  watch: {
    value: function(newValue) {
      if (
        newValue.measurements_length === null &&
        newValue.measurements_width === null &&
        newValue.measurements_volume === null &&
        newValue.measurements_inlet_area === null &&
        newValue.measurements_cross_sectional_area === null
      ) {
        this.$validator.reset();
      }
    },
  },
};
</script>
