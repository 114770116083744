const Table = require('../../Misc/Table');

export const racetrack = (app, report, evaluation, group, assessor) => {

    let slide;
    let slide2;
    let slide3;
    let slide4;
    let slide5;

    let titleY = 2.8;
    let slideY = 3;

    const categoriesObj = [{
        text: app.$t('Question'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Answer'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Comment'),
        options: report.styleOptions.tableHeader
    }];
    let constructionBaseObj = [];
    let constructionTopObj = [];
    let layoutObj = [];
    let terminalObj = [];
    let maintenanceObj = [];
    let herdsmanObj = [];

    // If no need to fill out the assessor
    if (assessor.getIsTheFreeChoiceWalkingSpeedOfCows() !== null && assessor.getIsTheFreeChoiceWalkingSpeedOfCows() === 1) {
        constructionTopObj.push([{
            text: app.$t("Do cows walk at an average pace of ") + assessor.getTargetIsTheFreeChoiceWalkingSpeedOfCows() + app.$t(' ')  + app.$t("?"),
            options: {
                color: '000000',
                align: 'left',
                fill: 'ffffff',
                bold: false
            }
        },
            {
                text: assessor.getIsTheFreeChoiceWalkingSpeedOfCows() === 1 ? app.$t('Yes') : assessor.getIsTheFreeChoiceWalkingSpeedOfCows() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheFreeChoiceWalkingSpeedOfCows() === 1 ? app.$t('Good!') : assessor.getIsTheFreeChoiceWalkingSpeedOfCows() === 0 ? app.$t('Slow, free-choice walking speeds indicate reduced cow comfort and confidence, which may increase the risk of the herdsperson pushing the cows.') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsTheFreeChoiceWalkingSpeedOfCows() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
        slide = report.pptx.addSlide('Slide master');

        slide.addText(
          app.$t('Races, Tracks and Lanes Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide.addText(
          app.$t('Construction Top Layer'), {
              x: 0.5,
              y: 2.38,
              ...report.styleOptions.subheaderBlue
          }
        );

        Table.create(slide, categoriesObj, constructionTopObj, report, group, 'Races, Tracks and Lanes Assessor', {
            ...report.styleOptions.tableOptions,
            y: 2.7,
            colW: [3.25, 1.25, 3]
        });

        slide.addText(
          app.$t('Good! You do not have to complete this assessment.'), {
              shape: report.pptx.shapes.ROUNDED_RECTANGLE,
              x: 0.5,
              y: 4,
              w: 7.5,
              h: .7,
              rectRadius: .05,
              fontFace: 'Arial',
              fontSize: 14,
              align: 'center',
              valign: 'middle',
              color: 'ffffff',
              fill: '0000ff'
          });

        return
    }

    if (assessor.getIsBaseLayerCompactedAndDry() !== null) {
        constructionBaseObj.push([{
                text: app.$t("Is the base layer compacted and dry?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsBaseLayerCompactedAndDry() === 1 ? app.$t('Yes') : assessor.getIsBaseLayerCompactedAndDry() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsBaseLayerCompactedAndDry() === 1 ? app.$t('Good!') : assessor.getIsBaseLayerCompactedAndDry() === 0 ? app.$t('A soft, wet base layer will not support the top course (wear course). Over time, the track will deteriorate slowing cow flow and increasing risk of lameness.') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsBaseLayerCompactedAndDry() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        assessor.getIsBaseLayerCompactedAndDry() === 0 ? slideY += 1.2 : slideY += .65;
        assessor.getIsBaseLayerCompactedAndDry() === 0 ? titleY += 1.2 : titleY += .65;

    }

    if (assessor.getAboveWaterTable() !== null) {
        constructionBaseObj.push([{
                // text: app.$t("Is the base layer at least ") + assessor.getTargetAboveWaterTable() + " " + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + app.$t(" above the water table?"),
                text: evaluation.isImperial ?  app.$t('Is the base layer at least 24 in above the water table?') : app.$t('Is the base layer at least 60 cm above the water table?') ,
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAboveWaterTable() === 1 ? app.$t('Yes') : assessor.getAboveWaterTable() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAboveWaterTable() === 1 ? app.$t('Good!') : assessor.getAboveWaterTable() === 0 ? app.$t('A wet base layer will not support the wear course. Over time, the track will deteriorate, slowing cow flow and increasing risk of lameness.') : '',
                options: {
                    align: 'left',
                    color: assessor.getAboveWaterTable() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
        assessor.getAboveWaterTable() === 0 ? slideY += 1.1 : slideY += .6;
        assessor.getAboveWaterTable() === 0 ? titleY += 1.1 : titleY += .6;
        
    }

    if (assessor.getIsTopLayerSmoothAndCompacted() !== null) {
        constructionTopObj.push([{
                text: app.$t("Is the top layer smooth and compacted?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTopLayerSmoothAndCompacted() === 1 ? app.$t('Yes') : assessor.getIsTopLayerSmoothAndCompacted() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTopLayerSmoothAndCompacted() === 1 ? app.$t('Good!') : assessor.getIsTopLayerSmoothAndCompacted() === 0 ? app.$t('Cows prefer walking on smooth, firm, non-slip surfaces. Lower quality surfaces will reduce walking speed, which could cause herdsperson impatience and create a series of negative reactions, resulting in increased incidence of claw lesions.') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsTopLayerSmoothAndCompacted() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getAreTherePotHolesPresent() !== null) {
        constructionTopObj.push([{
                text: app.$t("Are there pot holes present?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreTherePotHolesPresent() === 0 ? app.$t('No') : app.$t('Yes'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreTherePotHolesPresent() === 0 ? app.$t('Good!') : app.$t('If not fixed promptly, holes will get bigger over time, exposing more of the base layer material which can damage the cows feet. Potholes may reflect poor maintenance standards.'),
                options: {
                    align: 'left',
                    color: assessor.getAreTherePotHolesPresent() === 0 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getIsSurfaceSlipperyWhenWet() !== null) {
        constructionTopObj.push([{
                text: app.$t("Is the surface slippery when wet?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsSurfaceSlipperyWhenWet() === 0 ? app.$t('No') : app.$t('Yes'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsSurfaceSlipperyWhenWet() === 0 ? app.$t('Good!') : app.$t('Slippery surfaces result in reduced walking speed of cows and increases risk of injury. Dry non-slip surfaces may turn very slippery when wet (e.g. limestone).'),
                options: {
                    align: 'left',
                    color: assessor.getIsSurfaceSlipperyWhenWet() === 0 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getMachineCompacted() !== null) {
        constructionTopObj.push([{
                text: app.$t("Is the top layer machine compacted?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getMachineCompacted() === 1 ? app.$t('Yes') : assessor.getMachineCompacted() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getMachineCompacted() === 1 ? app.$t('Good! However over-compaction can crush rounded aggregates into smaller, sharp particles; this should be avoided.') : assessor.getMachineCompacted() === 0 ? app.$t('Letting cows compact the top course over time will result in damage to races and the cows feet.') : '',
                options: {
                    align: 'left',
                    color: assessor.getMachineCompacted() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getGravelSurface() !== null) {
        constructionTopObj.push([{
                text: app.$t("Is the top layer gravel?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getGravelSurface() === 1 ? app.$t('Yes') : assessor.getGravelSurface() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getGravelSurface() === 1 ? app.$t('Good!') : assessor.getGravelSurface() === 0 ? app.$t('Sharp edged stones over ') + assessor.getTargetGravelSurface() + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + app.$t(' will increase risk of damage to the cows feet.') : '',
                options: {
                    align: 'left',
                    color: assessor.getGravelSurface() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getCrownedOrTilted() !== null) {
        constructionTopObj.push([{
                text: app.$t("Is the top layer crowned or tilted 1:10?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getCrownedOrTilted() === 1 ? app.$t('Yes') : assessor.getCrownedOrTilted() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getCrownedOrTilted() === 1 ? app.$t('Good!') : assessor.getCrownedOrTilted() === 0 ? app.$t('A gradient of less than 10% will not effectively shed water, resulting in pooling water and excessive track wear. Gradients over 10% make walking uncomfortable and may reduce walking speed of cows.') : '',
                options: {
                    align: 'left',
                    color: assessor.getCrownedOrTilted() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getAnyScouringOnSteepSectionDiversionBarsNotInPlace() !== null) {
        constructionTopObj.push([{
                text: app.$t("Any scouring on steep section or diversion bars not in place?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAnyScouringOnSteepSectionDiversionBarsNotInPlace() === 0 ? app.$t('No') : app.$t('Yes'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAnyScouringOnSteepSectionDiversionBarsNotInPlace() === 0 ? app.$t('Good!') : app.$t("Water running rapidly down the track will quickly erode the surface. Diversion bars, or \"humps\", on top layer will help slow water speed and divert water off of the track. Diversion bars help increase the life span of the race."),
                options: {
                    align: 'left',
                    color: assessor.getAnyScouringOnSteepSectionDiversionBarsNotInPlace() === 0 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getAnyTrackJuncturesToLowerQualitySurfaces() !== null) {
        constructionTopObj.push([{
                text: app.$t("Any track junctures to lower quality surfaces?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAnyTrackJuncturesToLowerQualitySurfaces() === 0 ? app.$t('No') : app.$t('Yes'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAnyTrackJuncturesToLowerQualitySurfaces() === 0 ? app.$t('Good!') : app.$t('Cows moving from a poor section of track onto a harder, more compact area may drag stones onto the harder surface, leading to hoof trauma. Moving from a section of track with a good quality surface onto a section of track with poorer quality surface will slow down or even stop cow movement, resulting in dunging and urinating and further deterioration of the track surface.'),
                options: {
                    align: 'left',
                    color: assessor.getAnyTrackJuncturesToLowerQualitySurfaces() === 0 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getAnyPuddlesWetOrMuddyAreas() !== null) {
        constructionTopObj.push([{
                text: app.$t("Any puddles, wet or muddy areas?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAnyPuddlesWetOrMuddyAreas() === 0 ? app.$t('No') : app.$t('Yes'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAnyPuddlesWetOrMuddyAreas() === 0 ? app.$t('Good!') : app.$t('Wet or muddy areas will soften and erode the surface layer of the track, exposing the base course of the track and increasing the risk of trauma to cows feet. Producers should ensure that drainage beside the track is functioning, that irrigation water does not land on the track, and that water pipes and troughs are not leaking onto the track.Sags in the track that accumulate water should be fixed so water does not collect on the track surface. If the track is muddy due to excessive dung and urination, producers should investigate why cows are urinating and dunging in a particular area.Reasons could include obstructions in or around tracks, 90° turns, excess slope / crown and change in track surface. Also, any trees that shade the track or block the wind should be removed. Similarly, producers should avoid building sheds next to tracks resulting in the shed shading the track or blocking the wind.'),
                options: {
                    align: 'left',
                    color: assessor.getAnyPuddlesWetOrMuddyAreas() === 0 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getIsTheFreeChoiceWalkingSpeedOfCows() !== null) {
        console.log({betaLog: assessor.getTargetIsTheFreeChoiceWalkingSpeedOfCows()})
        constructionTopObj.push([{
                text: app.$t("Do cows walk at an average pace of ") + assessor.getTargetIsTheFreeChoiceWalkingSpeedOfCows() + app.$t(' ') + app.$t("?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheFreeChoiceWalkingSpeedOfCows() === 1 ? app.$t('Yes') : assessor.getIsTheFreeChoiceWalkingSpeedOfCows() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheFreeChoiceWalkingSpeedOfCows() === 1 ? app.$t('Good!') : assessor.getIsTheFreeChoiceWalkingSpeedOfCows() === 0 ? app.$t('Slow, free-choice walking speeds indicate reduced cow comfort and confidence, which may increase the risk of the herdsperson pushing the cows.') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsTheFreeChoiceWalkingSpeedOfCows() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getDoesTheRacePassTheHumanWalkTest() !== null) {
        constructionTopObj.push([{
                text: app.$t("Does the race pass the human walk test?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoesTheRacePassTheHumanWalkTest() === 1 ? app.$t('Yes') : assessor.getDoesTheRacePassTheHumanWalkTest() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoesTheRacePassTheHumanWalkTest() === 1 ? app.$t('Good! People should be able to walk the race barefoot. Be sure to walk several sections, preferably in wet conditions when assessing quality of tracks.') : assessor.getDoesTheRacePassTheHumanWalkTest() === 0 ? app.$t('Surfaces that are not comfortable for people to walk on with bare feet are also not comfortable for cows and increase risk of trauma to cows feet.') : '',
                options: {
                    align: 'left',
                    color: assessor.getDoesTheRacePassTheHumanWalkTest() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if(constructionBaseObj.length > 0 || constructionTopObj.length > 0) {
        slide = report.pptx.addSlide('Slide master');
    }

    if (constructionBaseObj.length > 0) {

        slide.addText(
            app.$t('Races, Tracks and Lanes Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide.addText(
            app.$t('Construction Base Layer'), {
                x: 0.5,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide, categoriesObj, constructionBaseObj, report, group, 'Races, Tracks and Lanes Assessor', {
            ...report.styleOptions.tableOptions,
            y: 2.7,
            colW: [3.25, 1.25, 3]
        });

    }

    if (constructionTopObj.length > 0) {

        // slide2 = report.pptx.addSlide('Slide master');
        //
        // slide2.addText(
        //     app.$t('Races, Tracks and Lanes Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        // );

        slide.addText(
            app.$t('Construction Top Layer'), {
                x: 0.5,
                y: titleY,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide, categoriesObj, constructionTopObj, report, group, 'Races, Tracks and Lanes Assessor', {
            ...report.styleOptions.tableOptions,
            y: slideY,
            autoPageLineWeight: .25,
            colW: [3.25, 1.25, 3]
        });

    }

    if (assessor.getTrackWidth() !== null) {
        layoutObj.push([{
                text: app.$t("Track Width ") + "(" + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + ")",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getTrackWidth(),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getTrackWidth() > assessor.getTargetTrackWidth() ? app.$t('Good!') : app.$t('Races that are too narrow slow cow flow and can increase animal to animal and/or human to animal confrontations. Objects like fallen branches or trees, parked motorbikes, etc. can narrow the effective width of the race as well.'),
                options: {
                    align: 'left',
                    color: assessor.getTrackWidth() > assessor.getTargetTrackWidth() ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getIsFullWidthAvailable() !== null) {
        layoutObj.push([{
                text: app.$t("Is the full width available?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsFullWidthAvailable() === 1 ? app.$t('Yes') : app.$t('No'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsFullWidthAvailable() === 1 ? app.$t('Good!') : app.$t('Races that are too narrow slow cow flow and can increase animal to animal and/or human to animal confrontations. Objects in the track can narrow the effective width of the race as well.'),
                options: {
                    align: 'left',
                    color: assessor.getIsFullWidthAvailable() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getAreThereAnyMaterialsBagsTrashEtcInOrBesideTheTrackThatCowsAvoidOrReduceEffectiveTrackWidth() !== null) {
        layoutObj.push([{
                text: app.$t("Is there debris in or beside the track that cows tend to avoid?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreThereAnyMaterialsBagsTrashEtcInOrBesideTheTrackThatCowsAvoidOrReduceEffectiveTrackWidth() === 1 ? app.$t('Yes') : app.$t('No'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreThereAnyMaterialsBagsTrashEtcInOrBesideTheTrackThatCowsAvoidOrReduceEffectiveTrackWidth() === 0 ? app.$t('Good!') : app.$t('Debris in or along side the track can narrow the effective width of the race. This can slow cow flow and increase animal to animal and/or human to animal confrontations.'),
                options: {
                    align: 'left',
                    color: assessor.getAreThereAnyMaterialsBagsTrashEtcInOrBesideTheTrackThatCowsAvoidOrReduceEffectiveTrackWidth() === 0 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getCornersReplacedByCurvedCorners() !== null) {
        layoutObj.push([{
                text: app.$t("Are all 90˚ corners replaced by curved corners?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getCornersReplacedByCurvedCorners() === 1 ? app.$t('Yes') : assessor.getCornersReplacedByCurvedCorners() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getCornersReplacedByCurvedCorners() === 1 ? app.$t('Good!') : assessor.getCornersReplacedByCurvedCorners() === 0 ? app.$t('A 90° corner is hard for the mob to navigate and will slow cow flow. Replace a 90° corner with two 45° corners, or a wide sweeping bend.') : '',
                options: {
                    align: 'left',
                    color: assessor.getCornersReplacedByCurvedCorners() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getGateWidthRaceWidth() !== null) {
        layoutObj.push([{
                text: app.$t("Is the gate width ≥ race width?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getGateWidthRaceWidth() === 1 ? app.$t('Yes') : assessor.getGateWidthRaceWidth() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getGateWidthRaceWidth() === 1 ? app.$t('Good!') : assessor.getGateWidthRaceWidth() === 0 ? app.$t('Narrow paddock gates slow cow flow, resulting in increased dunging and urinating at the paddock exit. Dunging and urination deteriorate the surface at paddock exit.') : '',
                options: {
                    align: 'left',
                    color: assessor.getGateWidthRaceWidth() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }

    if (assessor.getGatesAngledAtRace() !== null) {
        layoutObj.push([{
                text: app.$t("Are the gates angled at the race end?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getGatesAngledAtRace() === 1 ? app.$t('Yes') : assessor.getGatesAngledAtRace() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getGatesAngledAtRace() === 1 ? app.$t('Good!') : assessor.getGatesAngledAtRace() === 0 ? app.$t('An angled exit is easier for cows to negotiate than a 90° exit. A 90° exit will slow cow flow.') : '',
                options: {
                    align: 'left',
                    color: assessor.getGatesAngledAtRace() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }

    if (assessor.getAreExitSurfacesCappedDryWellDrainedAndFirm() !== null) {
        layoutObj.push([{
                text: app.$t("Are the exit surfaces capped, dry/well drained and firm?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreExitSurfacesCappedDryWellDrainedAndFirm() === 1 ? app.$t('Yes') : assessor.getAreExitSurfacesCappedDryWellDrainedAndFirm() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreExitSurfacesCappedDryWellDrainedAndFirm() === 1 ? app.$t('Good!') : assessor.getAreExitSurfacesCappedDryWellDrainedAndFirm() === 0 ? app.$t('Paddock exits are really part of the race system. Wide, angled, crowned and capped exits will require less maintenance and improve cow flow into and out of the paddock.') : '',
                options: {
                    align: 'left',
                    color: assessor.getAreExitSurfacesCappedDryWellDrainedAndFirm() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }

    if (assessor.getFurthestDistanceFromPaddockToMilkingShed() !== null) {
        layoutObj.push([{
                text: app.$t("What is the furthest distance from paddock to milking shed?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getFurthestDistanceFromPaddockToMilkingShed() === 1 ? assessor.getMaxDistanceFromPaddockToMilkingShed() : assessor.getFurthestDistanceFromPaddockToMilkingShed() === 0 ? assessor.getMidDistanceFromPaddockToMilkingShed() : assessor.getMinDistanceFromPaddockToMilkingShed(),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getFurthestDistanceFromPaddockToMilkingShed() === 1 ? app.$t("Long walking distances increase the risk of thin soles and damage to cows' feet. Tracks need to be well maintained and herdspeople need to be trained in proper handling of cows to minimize risk of injury to cows. Producers should try to rotate cows to avoid placing them into paddocks that result in long walking distances in successive milkings.") : assessor.getFurthestDistanceFromPaddockToMilkingShed() === 0 ? app.$t("Long walking distances increase the risk of thin soles and damage to cows' feet. Tracks need to be well maintained and herdspeople need to be trained in proper handling of cows to minimize risk of injury to cows.") : app.$t('Good!'),
                options: {
                    align: 'left',
                    color: assessor.getFurthestDistanceFromPaddockToMilkingShed() === -1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }

            },
        ]);
    }

    if (assessor.getAreWaterAndFeedTroughsAwayFromExits() !== null) {
        layoutObj.push([{
                text: app.$t("Are the water and feed troughs away from the exits?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreWaterAndFeedTroughsAwayFromExits() === 1 ? app.$t('Yes') : assessor.getAreWaterAndFeedTroughsAwayFromExits() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreWaterAndFeedTroughsAwayFromExits() === 1 ? app.$t('Good!') : assessor.getAreWaterAndFeedTroughsAwayFromExits() === 0 ? app.$t('Placing water and feed troughs at the paddock exit will result in deteriorated paddock exits. In addition, water and feed troughs at the paddock exit will slow cow flow, as cows stop to drink and eat as they pass the water and feed troughs.') : '',
                options: {
                    align: 'left',
                    color: assessor.getAreWaterAndFeedTroughsAwayFromExits() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getAutoGateLatchesUsed() !== null) {
        layoutObj.push([{
                text: app.$t("Are auto gate latches used?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAutoGateLatchesUsed() === 1 ? app.$t('Yes') : assessor.getAutoGateLatchesUsed() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAutoGateLatchesUsed() === 1 ? app.$t('Good!') : assessor.getAutoGateLatchesUsed() === 0 ? app.$t('An automatic gate latch ("Battlatch") opens automatically following an audio signal. With automatic gates, cows walk to the dairy or feed pad without human intervention. Automatic gate latches work best when used in conjunction with a feed pad. Automatic gate latches save time for the operators and at the same time ensure a pressure-free walk for cows from the paddock to the shed.') : '',
                options: {
                    align: 'left',
                    color: assessor.getAutoGateLatchesUsed() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (layoutObj.length > 0) {
        // did this so the group size doesn't show up on the page if this section hasn't been filled out
        layoutObj.unshift([{
                text: app.$t("Group Size"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: group.getGroupSize(),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: '',
                options: {
                    align: 'left',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);

        slide3 = report.pptx.addSlide('Slide master');

        slide3.addText(
            app.$t('Races, Tracks and Lanes Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide3.addText(
            app.$t('Layout/Width'), {
                x: 0.5,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide3, categoriesObj, layoutObj, report, group, 'Races, Tracks and Lanes Assessor', {
            ...report.styleOptions.tableOptions,
            y: 2.7,
            colW: [3.25, 1.25, 3]
        });

    }

    if (assessor.getIsTheTerminalExtraWide() !== null) {
        terminalObj.push([{
                text: app.$t("Is the terminal extra wide? ") + "(" + app.$t('the last ') + assessor.getTargetIsTheTerminalExtraWide() + " " + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + ")",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheTerminalExtraWide() === 1 ? app.$t('Yes') : assessor.getIsTheTerminalExtraWide() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheTerminalExtraWide() === 1 ? app.$t('Good! However, the terminal section should not be so wide that cows slow down before entering the yard. This will result in more defecation and urination in the terminal section of the track. As a rule of thumb, increase the width of the track in the terminal section by no more than ') + assessor.getMinTerminalExtraWide() + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + app.$t('.') : assessor.getIsTheTerminalExtraWide() === 0 ? app.$t('A slightly wider terminal section will improve cow flow into the yard. However, the terminal section should not be so wide that cows slow down before entering the yard. This will result in more defecation and urination in the terminal section of the track. As a rule of thumb, increase the width of the track in the terminal section by no more than ') + assessor.getMinTerminalExtraWide() + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + app.$t('.') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsTheTerminalExtraWide() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getIsTheTerminalExtraWide() !== null) {
        terminalObj.push([{
                text: app.$t("Is the yard entrance width ≥ race width?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheTerminalExtraWide() === 1 ? app.$t('Yes') : assessor.getIsTheTerminalExtraWide() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getIsTheTerminalExtraWide() === 1 ? app.$t('Good!') : assessor.getIsTheTerminalExtraWide() === 0 ? app.$t('A yard entrance narrower than the race width will slow cow flow, resulting in increased dunging and urination on the terminal section of the track.') : '',
                options: {
                    align: 'left',
                    color: assessor.getIsTheTerminalExtraWide() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getDoCowsHaveToTurnToEnterTheYard() !== null) {
        terminalObj.push([{
                text: app.$t("Do cows have to turn to enter the yard?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoCowsHaveToTurnToEnterTheYard() === 1 ? app.$t('Yes') : assessor.getDoCowsHaveToTurnToEnterTheYard() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDoCowsHaveToTurnToEnterTheYard() === 0 ? app.$t('Good!') : assessor.getDoCowsHaveToTurnToEnterTheYard() === 1 ? app.$t('Sharp turns, especially on hard surfaces like concrete will increase stress on feet of cows and slow cow flow.') : '',
                options: {
                    align: 'left',
                    color: assessor.getDoCowsHaveToTurnToEnterTheYard() === 0 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getFirmSmoothNonSlipSurface() !== null) {
        terminalObj.push([{
                text: app.$t("Is the surface firm, smooth and non-slip?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getFirmSmoothNonSlipSurface() === 1 ? app.$t('Yes') : assessor.getFirmSmoothNonSlipSurface() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getFirmSmoothNonSlipSurface() === 1 ? app.$t('Good!') : assessor.getFirmSmoothNonSlipSurface() === 0 ? app.$t('The surface of the terminal section of the track needs to be extra durable, well drained, non-slip and comfortable to the cow as this is the most intensively used part of the track. A terminal section of the track that is deteriorated will slow cow flow and increase risk of trauma to cows feet.') : '',
                options: {
                    align: 'left',
                    color: assessor.getFirmSmoothNonSlipSurface() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getExcellentDrainage() !== null) {
        terminalObj.push([{
                text: app.$t("Is there excellent drainage?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getExcellentDrainage() === 1 ? app.$t('Yes') : assessor.getExcellentDrainage() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getExcellentDrainage() === 1 ? app.$t('Good!') : assessor.getExcellentDrainage() === 0 ? app.$t('The terminal section is the most intensively used part of the race; the better the surface quality and drainage, the longer it will last and the better it will support cow flow and hoof health.') : '',
                options: {
                    align: 'left',
                    color: assessor.getExcellentDrainage() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (assessor.getAreStonesBroughtOntoYards() !== null) {
        terminalObj.push([{
                text: app.$t("Are stones brought onto yards?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreStonesBroughtOntoYards() === 1 ? app.$t('Yes') : assessor.getAreStonesBroughtOntoYards() === 0 ? app.$t('No') : app.$t('N/A'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                // text: assessor.getAreStonesBroughtOntoYards() === 1 ? app.$t('A stone barrier, such as ') + assessor.getTargetMinStoneBarrier() + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + app.$t(' board installed ') + assessor.getTargetBoard() + app.$t(' ') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + app.$t(' prior to the yard entrance will reduce the amount of stones and debris brought on to the yard. Increasing the distance between the stone barrier and the yard entrance, and covering the surface of the area between the stone barrier and the yard entrance with postpeelings, sawdust or some other soft material, will improve the effectiveness of the stone barrier and minimize trauma on cows feet.') : '',
                text: assessor.getAreStonesBroughtOntoYards() === 1 ? evaluation.isImperial ? app.$t('A stone barrier, such as 6 in board installed 16 ft prior to the yard entrance will reduce the amount of stones and debris brought on to the yard. Increasing the distance between the stone barrier and the yard entrance, and covering the surface of the area between the stone barrier and the yard entrance with postpeelings, sawdust or some other soft material, will improve the effectiveness of the stone barrier and minimize trauma on cows feet.') : app.$t('A stone barrier, such as 15 cm board installed 5 m prior to the yard entrance will reduce the amount of stones and debris brought on to the yard. Increasing the distance between the stone barrier and the yard entrance, and covering the surface of the area between the stone barrier and the yard entrance with postpeelings, sawdust or some other soft material, will improve the effectiveness of the stone barrier and minimize trauma on cows feet.') : '',
                options: {
                    align: 'left',
                    color: assessor.getAreStonesBroughtOntoYards() === 0 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
    }

    if (terminalObj.length > 0) {

        slide4 = report.pptx.addSlide('Slide master');

        slide4.addText(
            app.$t('Races, Tracks and Lanes Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide4.addText(
            app.$t('Terminal'), {
                x: 0.45,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide4, categoriesObj, terminalObj, report, group, 'Races, Tracks and Lanes Assessor', {
            ...report.styleOptions.tableOptions,
            y: 2.7,
            colW: [3.25, 1.25, 3]
        });

    }

    slideY = 3;
    titleY = 2.6;

    if (assessor.getDocumentedMaintenancePlanAdheredTo() !== null) {

        maintenanceObj.push([{
                text: app.$t("Is a documented maintenance plan adhered to?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDocumentedMaintenancePlanAdheredTo() === 1 ? app.$t('Yes') : app.$t('No'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDocumentedMaintenancePlanAdheredTo() === 1 ? app.$t('Good!') : app.$t('Adhering to a documented maintenance plan will help ensure races are maintained in top condition.'),
                options: {
                    align: 'left',
                    color: assessor.getDocumentedMaintenancePlanAdheredTo() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        assessor.getDocumentedMaintenancePlanAdheredTo() === 0 ? slideY += 1.1 : slideY += .65;
        assessor.getDocumentedMaintenancePlanAdheredTo() === 0 ? titleY += 1.1 : titleY += .65;
    }

    if (assessor.getEmergencyRepairsCarriedOutASAP() !== null) {
        maintenanceObj.push([{
                text: app.$t("Are emergency repairs carried out as soon as possible?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getEmergencyRepairsCarriedOutASAP() === 1 ? app.$t('Yes') : app.$t('No'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getEmergencyRepairsCarriedOutASAP() === 1 ? app.$t('Good!') : app.$t('Delaying repairs can result in substantial damage to the track and cows.'),
                options: {
                    align: 'left',
                    color: assessor.getEmergencyRepairsCarriedOutASAP() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            },
        ]);
        assessor.getEmergencyRepairsCarriedOutASAP() === 0 ? titleY += .8 : titleY += .45;
        assessor.getEmergencyRepairsCarriedOutASAP() === 0 ? slideY += .8 : slideY += .45;
    }

    if (assessor.getAreCowsPushed() !== null) {
        herdsmanObj.push([{
                text: app.$t("Are cows pushed?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreCowsPushed() === 1 ? app.$t('Yes') : app.$t('No'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAreCowsPushed() === 0 ? app.$t('Good!') : app.$t('Cows that are pushed are more prone to injury and lameness. Cows that are not pushed have their heads down, avoid contact with each other, display minimal aggression towards other cows and are able to avoid obstacles such as stones, puddles, etc.'),
                options: {
                    align: 'left',
                    color: assessor.getAreCowsPushed() === 0 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getDistanceBetweenHerdsmanAndTailEnderCows() !== null) {
        herdsmanObj.push([{
                text: app.$t("Is the distance between the herdsman and tail-end of cows ≥ ") + assessor.getTargetDistanceBetweenHerdsmanAndTailEnderCows() + ' ' +  app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) + "?",
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDistanceBetweenHerdsmanAndTailEnderCows() === 1 ? app.$t('Yes') : app.$t('No'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getDistanceBetweenHerdsmanAndTailEnderCows() === 1 ? app.$t('Good!') : app.$t('Pushing tail-end cows does not speed up the lead cows. Cows that are not pushed have their heads down, avoid contact with each other, display minimal aggression towards herdmates and are able to avoid obstacles such as stones, puddles, etc. Pushing tail-end cows only serves to increase confrontation between cows and increase risk of lameness.'),
                options: {
                    align: 'left',
                    color: assessor.getDistanceBetweenHerdsmanAndTailEnderCows() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getAllowsCowsTimeToNavigateObstacles() !== null) {
        herdsmanObj.push([{
                text: app.$t("Does the herdsman allow cows time to navigate all obstacles?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAllowsCowsTimeToNavigateObstacles() === 1 ? app.$t('Yes') : app.$t('No'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getAllowsCowsTimeToNavigateObstacles() === 1 ? app.$t('Good!') : app.$t('Pushing tail-end cows does not speed up the lead cows. Cows that are not pushed have their heads down, avoid contact with each other, display minimal aggression towards herdmates and are able to avoid obstacles such as stones, puddles, etc. Pushing tail-end cows only serves to increase confrontation between cows and increase risk of damage to cows\' feet.'),
                options: {
                    align: 'left',
                    color: assessor.getAllowsCowsTimeToNavigateObstacles() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if (assessor.getNoYellingClaxonsRevvingMotor() !== null) {
        herdsmanObj.push([{
                text: app.$t("Are loud noises kept to a minimum? Are dogs kept well back, and not barking?"),
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getNoYellingClaxonsRevvingMotor() === 1 ? app.$t('Yes') : app.$t('No'),
                options: {
                    color: '000000',
                    fill: 'ffffff',
                    bold: false
                }
            },
            {
                text: assessor.getNoYellingClaxonsRevvingMotor() === 1 ? app.$t('Good!') : app.$t("Cows don't respond well to loud noise. It makes them restless and skittish, increasing risk of injuries. Herdsman using these tactics may be too impatient to work the cows. Biting dogs are an absolute taboo. Dogs need to be kept well back from the cows or they may try to move away from the dog, increasing contact with other cows and increasing confrontation between cows."),
                options: {
                    align: 'left',
                    color: assessor.getNoYellingClaxonsRevvingMotor() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false
                }
            }
        ]);
    }

    if(maintenanceObj.length > 0 || herdsmanObj.length > 0) {
        slide5 = report.pptx.addSlide('Slide master');
    }

    if (maintenanceObj.length > 0) {

        slide5.addText(
            app.$t('Races, Tracks and Lanes Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide5.addText(
            app.$t('Maintenance'), {
                x: 0.45,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide5, categoriesObj, maintenanceObj, report, group, 'Races, Tracks and Lanes Assessor', {
            ...report.styleOptions.tableOptions,
            y: 2.7,
            colW: [3.25, 1.25, 3]
        });

    }

    if (herdsmanObj.length > 0) {
        if (maintenanceObj.length < 1) {
            slide5.addText(
              app.$t('Races, Tracks and Lanes Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
            );

        }

        slide5.addText(
            app.$t('Herdsman'), {
                x: 0.45,
                y: titleY + .1,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide5, categoriesObj, herdsmanObj, report, group, 'Races, Tracks and Lanes Assessor', {
            ...report.styleOptions.tableOptions,
            y: slideY + .1,
            colW: [3.25, 1.25, 3]
        });

    }
}
