import _ from "lodash";
import {initializeAssessorDataByType} from '../../helperFunctions';

require('./utils.js');

class Assessor {

  constructor(app, evaluation, group, assessor) {
    this.app = app;
    this.evaluation = evaluation;
    this.group = group;
    this.assessor = assessor;
  }

  isReported() {
    return this.assessor.data.isReported || this.assessor.isReported;
  }

  getType() {
    return this.assessor.type;
  }

  getData() {
    return this.assessor.data;
  }

  getInitialData() {
    return initializeAssessorDataByType(null, this.getType())
  }

  isStarted() {
    let assessorData = JSON.parse(JSON.stringify(this.getData()))
    let assessorInitialData = this.getInitialData().data
    delete assessorData.isReported
    delete assessorInitialData.isReported

    return !_.isEqual(assessorData, assessorInitialData)
  }

  getPresentationNotes() {
    return this.assessor.data.presentation_notes;
  }

  getName() {
    let assessorName = null
    switch (this.getType()) {
      case "1":
        assessorName = "Hygiene/Footbath";
        break;
      case "2":
        assessorName = "Freestall";
        break;
      case "3":
        assessorName = "Locomotion";
        break;
      case "4":
        assessorName = "Time Budget";
        break;
      case "5":
        assessorName = "Dirt Lot";
        break;
      case "6":
        assessorName = "Biosecurity";
        break;
      case "7":
        assessorName = "Bedded Pack";
        break;
      case "9":
        assessorName = "Races, Tracks and Lanes";
        break;
      case "10":
        assessorName = "Tie Stall/Stanchion";
        break;
      case "11":
        assessorName = "Holding Area";
        break;
      case "12":
        assessorName = "Freestall Ventilation";
        break;
      case "13":
        assessorName = "Tiestall/Stanchion Heat Abatement";
        break;
      case "14":
        assessorName = "Nutrition and Feeding";
        break;
      case "15":
        assessorName = "Hoof Trimming";
        break;
      case "16":
        assessorName = "Heat Abatement";
        break;
      case "17":
        assessorName = "Lesion";
        break;
      case "18":
        assessorName = "Walking Surface";
        break;
      case "19":
        assessorName = "Transition";
        break;
      case "20":
        assessorName = "Holding Yard & Milking Shed";
        break;
      default:
        assessorName = "Not Found";
        break;
    }
    return assessorName
  }
}

export default Assessor;