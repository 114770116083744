<template>
  <div
    class="dashboard-groups dashboard__stats-block main-container px-4"
    v-if="filteredGroups.length"
  >
    <div
      class="dashboard-groups__container row align-content-center justify-content-center justify-content-md-start"
    >
      <div v-for="(groupType, index) of groupTypes" :key="groupType + index">
        <dashboard-typed-groups
          class="mb-md-5"
          :groupType="groupType"
          :groups="filterGroupsByType(groupType, filteredGroups)"
        />
        <div
          v-if="(index + 1) % 2"
          :key="'vl' + groupType"
          class="dashboard-groups__vertical-line d-none d-md-flex justify-content-start"
        >
          <hr class="hr--vertical mx-3" />
        </div>
        <div
          v-if="index + 1 !== groupTypes.length"
          :key="'hl' + groupType"
          class="dashboard-groups__vertical-line w-100 d-md-none"
        >
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardTypedGroups from "./DashboardTypedGroups.vue";
import { animalMixin } from "@/mixins/animal.mixin.js";

export default {
  name: "DashboardGroups",
  inject: ["assessorType"],
  mixins: [animalMixin],
  components: {
    DashboardTypedGroups,
  },
  props: {
    evaluation: Object,
  },
  created() {},
  computed: {
    groupTypes() {
      const groupTypes = new Set();
      for (let group of Object.values(this.filteredGroups)) {
        groupTypes.add(group.data.type_of_animal);
      }
      return [...groupTypes];
    },
    filteredGroups() {
      return Object.values(this.evaluation.groups).filter((group) => {
        const assessor = group.assessors[this.assessorType];
        if (assessor) {
          const values = [
            assessor.data.cows_rumen_fill,
            assessor.data.cows_manure_score,
            assessor.data.body_condition_score_1,
            assessor.data.body_condition_score_2,
            assessor.data.refusal,
            assessor.data.is_feed_available_af_milking,
            assessor.data.checks_af_milking,
            assessor.data.time_checking_hr,
            assessor.data.is_appetite_checked,
          ];
          return values.filter((value) => value).length;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../_dashboard.scss";
</style>
