<template>
  <div>
    <div class="container main-container">
      <!-- START USER DATA -->
      <div class="row">
        <div class="col-lg-16">
          <h1 class="h1 h1--mb-small">
            <span>{{ 'My Photos' | translate }}</span>
          </h1>
        </div>
        <div class="col-lg-8">
          <div class="d-flex justify-content-start justify-content-lg-end">
            <button
              type="button"
              class="btn btn-primary btn-full--sm"
              data-toggle="modal"
              data-target="#filter"
            >
              {{ 'Filter' | translate }}
            </button>
          </div>
        </div>
      </div>

      <hr class="mt-4 mb-2 d-lg-none" />

      <my-photos-section :user="user"></my-photos-section>

      <hr
        v-show="this.user.role === 'zinpro_representative'"
        class="my-4 d-lg-none"
      />
      <!-- END USER DATA -->

      <!-- START CUSTOMER DATA -->
      <div v-show="this.user.role === 'zinpro_representative'">
        <div class="row mt-5">
          <div
            class="col-xl-16 evaluations__top-left d-inline-flex justify-content-between align-items-center"
          >
            <h2 class="h1 h1--mb-small">
              <span>{{ 'Customer Photos' | translate }}</span>
            </h2>
          </div>
          <div class="col-xl-8">
            <select
              class="form-control"
              v-model="customerId"
              @change="changeCustomer(customerId)"
            >
              <option selected disabled value="null">{{
                'Select' | translate
              }}</option>
              <option
                v-for="(customer, index) in user.customers"
                :key="index"
                :value="customer.id"
              >
                {{ customer.name }} ({{
                  $store.getters.getRoleName(customer.role) | translate
                }})
              </option>
            </select>
          </div>
        </div>

        <hr class="my-4 d-lg-none" />

        <div v-if="customer === null || !Object.keys(customer).length">
          <div class="mt5 text-center">
            <h5 class="text-uppercase">
              {{ 'You have not selected a customer.' | translate }}
            </h5>
          </div>
        </div>
        <my-photos-section
          v-else
          :user="customer"
          :is-loaded="isLoaded"
          :is-customer="true"
        ></my-photos-section>
      </div>
      <!-- END CUSTOMER DATA -->
    </div>
  </div>
</template>

<script>
import MyPhotosSection from '@/pages/my_photos/MyPhotosSection.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  GET_CUSTOMER_BY_ID,
  GET_USERS_BY_ID_GETTER,
  SET_CUSTOMER_BY_ID,
} from '../../store/types';

export default {
  components: {
    MyPhotosSection,
  },
  data() {
    return {
      customerId: null,
      isLoaded: false,
      customer: null,
      // user: null,
    };
  },
  metaInfo: {
    title: 'Evaluations',
  },
  computed: {
    ...mapState({
      user: state => state.users.user,
      users: state => state.users.users,
      customerData: state => state.users.customer,
      evaluations: state => state.evaluations.evaluations,
    }),
    ...mapGetters('users', [GET_USERS_BY_ID_GETTER]),
  },
  // watch: {
  //   users: {
  //     handler() {
  //       console.log('user watch ran');
  //       this.customer = this[GET_USERS_BY_ID_GETTER](this.customer._id);
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    ...mapActions('users', [GET_CUSTOMER_BY_ID]),
    getData() {
      var buildMedias = [];
      //  this.medias = this.$store.getters.getData('medias');
      //  this.medias = this.user;
      var temp = {};
      this.medias.forEach(function(media) {
        temp[media.id] = media;
      });
      //this.medias = temp;

      // this.evaluations = this.$store.getters.getData('evaluations');
      this.groups = [];
      this.assessors = [];
      let self = this;
      const counter = 0;
      self.evaluations.forEach(function(evaluation) {
        const evaluationName = evaluation.name;
        const evaluationID = evaluation.id;
        evaluation.groups.forEach(function(group) {
          const groupName = group.name;
          const groupID = group.id;
          self.groups.push(group);
          group.assessors.forEach(function(assessor) {
            const assessorName = '';
            const assessorID = assessor.id;
            const assessorType = assessor.type;
            switch (assessorType) {
              case '1':
                assessorName = 'Footbath';
                break;
              case '2':
                assessorName = 'Freestall';
                break;
              case '3':
                assessorName = 'Locomotion';
                break;
              case '4':
                assessorName = 'Time Budget';
                break;
              case '5':
                assessorName = 'Dirt Lot';
                break;
              case '6':
                assessorName = 'Biosecurity';
                break;
              case '9':
                assessorName = 'Races, Tracks and Lanes';
                break;
              default:
                assessorName = 'Not Found';
                break;
            }
            self.assessors.push(assessor);
            if (typeof assessor.data.medias !== 'undefined') {
              var foundMedia = {};
              assessor.data.medias.forEach(function(mediasID) {
                foundMedia = temp[mediasID];
                console.log(foundMedia);

                if (foundMedia) {
                  foundMedia.evaluationName = evaluationName;
                  foundMedia.groupName = groupName;
                  foundMedia.assessorName = assessorName;
                  foundMedia.assessorID = assessorID;
                  foundMedia.groupID = groupID;
                  foundMedia.evaluationID = evaluationID;
                  foundMedia.displayDate = new Date(
                    foundMedia.time
                  ).toDateString();
                  foundMedia.sortingID = counter;
                  foundMedia.assessorType = assessorType;
                  buildMedias.push(temp[mediasID]);
                  counter++;
                }
              });
            }
          });
        });
      });
      /*this.groups.forEach(function(group){
						this.assessors = this.assessors.concat(group.assessors);
					}.bind(this));

					this.medias = temp;*/
      this.originals = buildMedias;
      return buildMedias;
    },
    assignMedia() {
      this.assessors.forEach(assessor => {
        let buildMedias = [];
        let foundMedia = {};
        if (typeof assessor.data.medias !== 'undefined') {
          assessor.data.medias.forEach(mediasID => {
            foundMedia = this.medias[mediasID];
            buildMedias.push(this.medias[mediasID]);
          });
          assessor.data.medias = buildMedias;
        }
      });
    },

    sort(key) {
      let self = this;
      if (self.sortedBy == key) {
        if (self.orderBy == 'asc') {
          self.orderBy = 'desc';
        } else {
          self.orderBy = 'asc';
        }
      } else {
        self.orderBy = 'asc';
      }
      this.images.sort(function(a, b) {
        if (a[key] < b[key]) {
          if (self.orderBy == 'asc') {
            return -1;
          } else {
            return 1;
          }
        } else if (a[key] > b[key]) {
          if (self.orderBy == 'asc') {
            return 1;
          } else {
            return -1;
          }
        } else {
          return a.sortingID - b.sortingID;
        }
      });
      this.sortedBy = key;
    },

    async changeCustomer(customerId) {
      try {
        this.isLoaded = false;
        this.customer = this[GET_USERS_BY_ID_GETTER](customerId);
        this.isLoaded = true;
      } catch (error) {
        this.isLoaded = false;
        console.warn('Error: ', error);
      }
    },
  },
};
</script>
