<template>
  <div style="color: black">password</div>
</template>

<script>
export default {
  name: 'PasswordResetTab',
};
</script>

<style lang="scss" scoped>
</style>