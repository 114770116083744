export const translationsMixin = {
  methods: {
    translateType(type) {
      let typeT9n;

      switch (type) {
        case 'close-up':
          typeT9n = 'Close Up';
          break;
        case 'far-off':
          typeT9n = 'Far Off';
          break;
        case 'maternity':
          typeT9n = 'Maternity';
          break;
        case 'fresh':
          typeT9n = 'Fresh';
          break;
        case 'lactating':
          typeT9n = 'Lactating';
          break;
        case 'heifers-first-lactation':
          typeT9n = 'Heifers (First Lactation)';
          break;
        case 'heifers-growing':
          typeT9n = 'Heifers (Growing)';
          break;
        case 'mixed':
          typeT9n = 'Mixed';
          break;
      }

      return this.$t(typeT9n);
    },
    translateBoolValues(value) {
      return value ? this.$t(value[0].toUpperCase() + value.slice(1)) : value;
    },
  },
};
