<template>
  <div>
    <div class="card-body__top">
      <h2 class="h2 mb-0">{{ 'General' | translate }}</h2>
      <p>* {{ 'Field Required' | translate }}</p>
    </div>

    <hr />

    <div class="row">
      <div class="col-lg-4">
        <div class="form-group">
          <label
            >{{ 'Prefix' | translate }}
            <span class="required">*</span>
          </label>
          <select
            class="form-control"
            v-model="value.prefix"
            name="prefixes"
            data-vv-as="Prefix"
            v-validate="'required'"
          >
            <option disabled value="null">{{ 'Select' | translate }}</option>
            <option
              v-for="(prefix, index) in $store.state.misc.prefixes"
              :key="index"
              :value="prefix"
            >
              {{ prefix }}
            </option>
          </select>
          <span v-show="errors.has('prefixes')" class="text-danger">
            <small>{{ 'The Prefix field is required.' | translate }}</small>
          </span>
        </div>
      </div>

      <div class="col-lg-8">
        <div class="form-group">
          <label
            >{{ 'First Name' | translate }}
            <span class="required">*</span>
          </label>
          <input
            type="text"
            name="first_name_v"
            class="form-control"
            v-model="value.firstName"
            data-vv-as="First Name"
            v-validate="'required'"
          />
          <span v-show="errors.has('first_name_v')" class="text-danger">
            <small>{{ 'The First Name field is required.' | translate }}</small>
          </span>
        </div>
      </div>

      <div class="col-lg-12 companies-dropdown">
        <div v-if="companies !== null" class="form-group">
          <label
            >{{ 'Company' | translate }} <span class="required">*</span></label
          >
          <select
            v-if="$route.path.search(/admin/) === 1"
            class="form-control"
            v-model="value.company"
            name="company"
            data-vv-as="Company"
            v-validate="'required'"
          >
            <option disabled value="null">{{ 'Select' | translate }}</option>
            <option
              v-for="(company, index) in companies"
              :key="index"
              :value="company._id"
            >
              {{ company.name | translate }}
            </option>
          </select>
          <input
            v-else
            type="text"
            class="form-control"
            :value="value.companyName"
            name="company"
            readonly
          />
          <span v-show="errors.has('company')" class="text-danger">
            <small>{{ 'The Company field is required.' | translate }}</small>
          </span>
        </div>
        <div v-else>
          <div class="loading loading--sm">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="form-group">
          <label
            >{{ 'Last Name' | translate }}
            <span class="required">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            name="last_name_v"
            v-model="value.lastName"
            data-vv-as="Last Name"
            v-validate="'required'"
          />
          <span v-show="errors.has('last_name_v')" class="text-danger">
            <small>{{ 'The Last Name field is required.' | translate }}</small>
          </span>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="form-group">
          <label
            >{{ 'Country' | translate }}
            <span class="required">*</span>
          </label>
          <select
            class="form-control"
            v-model="value.countryCode"
            name="country"
            data-vv-as="Country"
            v-validate="'required'"
          >
            <option :value="null" disabled>{{ 'Select' | translate }}</option>
            <option
              v-for="(country, index) in $store.state.misc.countriesPrimary"
              :key="index"
              :value="index"
            >
              {{ country | translate }}
            </option>
            <option disabled>------------------</option>
            <option
              v-for="(country, index) in $store.state.misc.countries"
              :key="index"
              :value="index"
            >
              {{ country | translate }}
            </option>
          </select>
          <span v-show="errors.has('country')" class="text-danger">
            <small>{{ 'The Country field is required.' | translate }}</small>
          </span>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="form-group">
          <label
            >{{ 'Title' | translate }}
            <span class="required">*</span>
          </label>
          <input
            type="text"
            name="title_v"
            class="form-control"
            v-model="value.title"
            data-vv-as="Title"
            v-validate="'required'"
          />
          <span v-show="errors.has('title_v')" class="text-danger">
            <small>{{ 'The Title field is required.' | translate }}</small>
          </span>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="form-group">
          <label
            >{{ 'Address' | translate }}
            <span class="required">*</span>
          </label>
          <input
            type="text"
            name="address_v"
            class="form-control"
            v-model="value.address"
            data-vv-as="Address"
            v-validate="'required'"
          />
          <span v-show="errors.has('address_v')" class="text-danger">
            <small>{{ 'The Address field is required.' | translate }}</small>
          </span>
        </div>
      </div>

      <div
        :class="[
          {
            'col-lg-12':
              $route.path === '/profile' || $route.path === '/admin/profile'
                ? true
                : false,
          },
          {
            'col-lg-6':
              $route.path.search(/admin/) === 1 ||
              $route.path.search(/invite-customer/) === 1,
          },
        ]"
      >
        <div class="form-group">
          <label
            >{{ 'Phone' | translate }}
            <span class="required">*</span>
          </label>
          <input
            type="tel"
            name="phone_v"
            class="form-control"
            v-model="value.phone"
            data-vv-as="Phone"
            v-validate="'required'"
          />
          <span v-show="errors.has('phone_v')" class="text-danger">
            <small>{{ 'The Phone field is required.' | translate }}</small>
          </span>
        </div>
      </div>

      <div v-if="$route.path.search(/profile/) === -1" class="col-lg-6">
        <div class="form-group">
          <label
            >{{ 'Email' | translate }}
            <span class="required">*</span>
          </label>
          <input
            type="text"
            name="email"
            class="form-control"
            v-model="value.email"
            data-vv-as="Email"
            v-validate="'email|required'"
          />
          <span v-show="errors.has('email')" class="text-danger">
            <small>{{ 'The Email field is required.' | translate }}</small>
          </span>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="form-group">
          <label
            >{{ 'City' | translate }}
            <span class="required">*</span>
          </label>
          <input
            type="text"
            name="city_v"
            class="form-control"
            v-model="value.city"
            data-vv-as="City"
            v-validate="'required'"
          />
          <span v-show="errors.has('city_v')" class="text-danger">
            <small>{{ 'The City field is required.' | translate }}</small>
          </span>
        </div>
      </div>

      <div
        class="col-lg-12"
        v-if="
          $route.path === '/profile' || $route.path === '/admin/profile'
            ? true
            : false
        "
      >
        <div class="form-group">
          <label
            >{{ 'Email' | translate }}
            <span class="required">*</span>
          </label>
          <input
            type="text"
            name="email_v"
            class="form-control"
            v-model="value.email"
            data-vv-as="Email"
            v-validate="'required'"
          />
          <span v-show="errors.has('email_v')" class="text-danger">
            <small>{{ 'The Email field is required.' | translate }}</small>
          </span>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>
            {{ 'State/Province' | translate }}
          </label>
          <select
            v-if="misc.states[value.countryCode]"
            class="form-control"
            v-model="value.stateProvince"
          >
            <option :value="null" disabled>{{ 'Select' | translate }}</option>
            <template
              v-if="
                misc.states[value.countryCode].primary &&
                  Object.keys(misc.states[value.countryCode].primary).length
              "
            >
              <option
                v-for="(state, abbr) in misc.states[value.countryCode].primary"
                :key="state"
                :value="abbr"
              >
                {{ state }}
              </option>
              <option disabled>------------------</option>
            </template>
            <option
              v-for="(state, abbr) in misc.states[value.countryCode].regular"
              :key="state"
              :value="abbr"
            >
              {{ state }}
            </option>
          </select>
          <input
            v-else
            v-model="value.stateProvince"
            type="text"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-lg-6">
        <div class="form-group">
          <label
            >{{ 'Postal Code' | translate }}
          </label>
          <input
            v-model="value.postalCode"
            type="text"
            name="postal_v"
            class="form-control"
            data-vv-as="Postal"
          />
        </div>
      </div>
      <div
        v-if="
          $route.path === '/profile' || $route.path === '/admin/profile'
            ? true
            : false
        "
        class="col-lg-6"
      >
        <div class="form-group">
          <label class="d-block"
            >{{ 'Measurements' | translate }}
            <span class="required">*</span>
          </label>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                type="radio"
                v-model="value.measurements"
                name="measurements"
                value="metric"
                data-vv-as="Measurements"
                class="form-check-input"
                data-vv-id="33"
                aria-required="true"
                aria-invalid="false"
              />{{ 'Metric' | translate }}
            </label>
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                type="radio"
                v-model="value.measurements"
                name="measurements"
                value="imperial"
                data-vv-as="Measurements"
                class="form-check-input"
                data-vv-id="34"
                aria-required="true"
                aria-invalid="false"
              />{{ 'Imperial' | translate }}
            </label>
          </div>
          <span v-show="errors.has('measurements')" class="text-danger d-block">
            <small>{{ errors.first('measurements') }}</small>
          </span>
        </div>
      </div>
      <div
        v-if="
          $route.path === '/profile' || $route.path === '/admin/profile'
            ? true
            : false
        "
        class="col-lg-6"
      >
        <div class="form-group">
          <label
            >{{ 'Currency' | translate }}
            <span class="required">*</span>
          </label>
          <select
            name="currency"
            class="form-control"
            v-model="value.currency"
            data-vv-as="Currency"
            v-validate="'required'"
          >
            <option :value="null" disabled>{{ 'Select' | translate }}</option>
            <option
              v-for="(currency, index) in $store.state.misc.currencies"
              :key="index"
              :value="index"
            >
              {{ currency | translate }}
            </option>
          </select>
          <span v-show="errors.has('currency')" class="text-danger">
            <small>{{ 'The Currency field is required.' | translate }}</small>
          </span>
        </div>
      </div>
      <div class="col-lg-12">
        <div
          class="form-group"
          v-if="
            $route.path.search(/admin/) === -1 &&
              ['external', 'data_entry'].includes(value.role)
          "
        >
          <label>{{ 'Zinpro Representative' | translate }}</label>
          <input
            class="form-control"
            :value="value.representativeName"
            name="representative"
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: ['value', 'companies'],
  inject: ['$validator'],
  data: function() {
    return {
      id: this.$route.params.id,
      loaded: false,
      companiesAdmin: null,
      company: {
        country: '',
      },
    };
  },
  computed: {
    ...mapState({
      misc: state => state.misc,
    }),
  },
};
</script>
