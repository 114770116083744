<template>
  <div>
    <div class="fixed-loading" v-if="!isLoaded">
      <div class="loading">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>

    <div v-else class="container main-container main-container--accordion">
      <div class="row">
        <div class="col-24 col-xs-24 col-sm-24 col-lg-6">
          <div
            class="nav flex-column nav-pills"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <router-link
              v-for="(emailTemplate, i) in emailTemplates"
              :to="path(emailTemplate)"
              class="btn btn-dashboard btn-block tab"
              :class="{ 'selected-tab': activeTabIndex == i }"
              :key="emailTemplate._id"
            >
              <span
                @click="activeTabIndex = i"
                :style="{
                  display: 'flex',
                  'justify-content': 'center',
                  'align-items': 'center',
                  height: '100%',
                }"
                >{{ emailTemplate.title | translate }}</span
              >
            </router-link>
          </div>
        </div>
        <div class="col-24 col-sm-24 col-lg-18 col-xs-24">
          <div class="tab-content" id="v-pills-tabContent">
            <div>
              <router-view
                :active-tab-index="activeTabIndex"
                :content="{ senderName: '', ...emailTemplates[activeTabIndex] }"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<script>
import AppHeader from '../../../components/AppHeader';
import AppFooter from '../../../components/AppFooter';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATE_BY_LANG,
} from '../../../store/types';

export default {
  name: 'EmailTemplate',
  components: {
    AppHeader,
    AppFooter,
  },

  data() {
    return {
      route: this.$route.name,
      tabs: [
        'Email Address Verification',
        'Password Reset Tab',
        'Email Address Change',
        'Enrollment Notification',
        'Smtp Setting',
      ],
      selected: 'EmailAddressVerification',
      info: false,
      infoMessage: '',
      isLoaded: false,
      filesRef: null,
      storageList: [],
      settingsList: null,
      activeTabIndex: 0,
      emailTemplates: null,
      confirmMessage: false,
    };
  },
  methods: {
    ...mapActions('emailTemplate', [GET_EMAIL_TEMPLATES]),
    path(emailTemplate = {}) {
      return `/admin/email-template/${this.fromTitleToKebabCase(
        emailTemplate.title
      )}?template_id=${emailTemplate._id}`;
    },
    activeTab(tab) {
      activeTabIndex = tab;
    },
    fromTitleToKebabCase(str = '') {
      return str
        .toLowerCase()
        .split(' ')
        .join('-');
    },
    loadFirstRoute() {
      const path = this.path(this.emailTemplates[0]);
      this.$router.push(path);
    },
    sortByTitle(a, b) {
      const textA = a.title.toUpperCase();
      const textB = b.title.toUpperCase();
      return textA > textB ? -1 : textA < textB ? 1 : 0;
    },
  },
  computed: {
    ...mapState({
      user: state => state.users.user,
      locale: state => state.i18n.locale,
    }),
    ...mapGetters('emailTemplate', [GET_EMAIL_TEMPLATE_BY_LANG]),
  },
  // when ever language change
  watch: {
    locale: {
      handler(lang) {
        this.emailTemplates = this[GET_EMAIL_TEMPLATE_BY_LANG](lang).sort(
          this.sortByTitle
        );
      },
      deep: true,
    },
  },
  async created() {
    try {
      this.isLoaded = false;
      await this[GET_EMAIL_TEMPLATES]();
      this.emailTemplates = this[GET_EMAIL_TEMPLATE_BY_LANG](
        this.user.lang
      ).sort(this.sortByTitle);
      this.loadFirstRoute();
      this.isLoaded = true;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
<style>
.tab {
  display: block !important;
  font-size: 18px !important;
}
.selected-tab {
  background-color: #0000ff !important;
}
input[type='file'].upload-file-input {
  display: block;
  position: relative;
  opacity: 1;
  z-index: 2;
}
.file-info {
  display: flex;
  align-items: center;
}
.file-link {
  color: #0000ff;
  text-decoration: underline;
  margin: 0 10px;
  position: relative;
}

.file-link svg {
  height: 16px;
  width: 11px;
  margin: 13px 3px;
  position: absolute;
  right: -17px;
  top: -12px;
}

.fixed-loading {
  position: fixed;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  z-index: 9999;
  top: 0;
  left: 0;
}
.nav-pills .nav-link {
  /* border-radius: 0.6rem; */
  font-size: 12px;
  color: #000;
  background-color: #bbbbbb;
  white-space: nowrap;
  text-align: center;
  margin: 1px;
}
.nav-pills .nav-link.active {
  font-size: 12px;
  color: #000 !important;
  white-space: nowrap;
  background-color: #ffffff !important;
  border: 1px solid black;
}
.tab-content > .active {
  background: #fff !important;
  border-bottom: unset !important;
  border-top-right-radius: unset !important;
  margin-left: 20px;
}
.active {
  border-bottom: none !important;
}
.verification-p {
  font-size: 13px;
  color: rgb(97, 97, 97);
  margin-bottom: none !important;
}
.verification-p-h {
  font-size: 16px;
  color: rgb(97, 97, 97);
  margin-bottom: none !important;
}
.verification-input {
  border-radius: 5px;
  border: 1px solid #bbbbbb;
  outline: none;
}
.verification-input-long {
  border-radius: 5px;
  border: 1px solid #bbbbbb;
  outline: none;
  width: 100%;
}
.verification-textarea-long {
  border-radius: 5px;
  border: 1px solid #bbbbbb;
  outline: none;
  width: 100%;
  background-color: rgb(236, 236, 236);
}
.btn-template {
  background: #007bff;
  color: #ffffff;
  border: none;
  font-weight: 400;
  font-family: 'Helvetica Neue LT W01_77 Bd Cn';
  font-size: 16px;
  padding: 10px 10px;
  text-align: center;
  border-radius: 10px;
}
.btn-cancel {
  background-color: #fff;
  color: #000000;
  border: none;
  font-weight: 400;
  font-family: 'Helvetica Neue LT W01_77 Bd Cn';
  font-size: 16px;
  padding: 10px 10px;
  text-align: center;
  border-radius: 10px;
}
.submit-div {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
@media only screen and (max-width: 999px) {
  .tab-content {
    margin-top: 30px;
  }
}
</style>
