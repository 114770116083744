import {
    beddedPack
} from './beddedPack';
import {
    medias
} from './../Global/medias.js';

class BeddedPackAssessorSlides {

    constructor(app, report, evaluation, group, user) {
        this.app = app;
        this.report = report;
        this.evaluation = evaluation;
        this.group = group;
		this.user = user;
    }

    add(tableOfContentsCalculatorPosition) {

        let assessor = this.group.getAssessorByType("7");

        if (assessor === false || assessor.assessor.data === undefined) {
            throw this.group.getName() + this.app.$t(" - ") + this.app.$t("Bedded Pack assessor is not ready.");
        }

        this.report.tableOfContentsHelper("Bedded Pack", tableOfContentsCalculatorPosition, this.group);

        beddedPack(this.app, this.report, this.evaluation, this.group, assessor);
		medias(this.app, this.report, this.group, "7", this.user);
    }

}

export default BeddedPackAssessorSlides;