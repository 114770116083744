import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';

//Allow Vue to use Vuex
Vue.use(Vuex);
// load vuex i18n module
import vuexI18n from 'vuex-i18n';
// This allows to import all the functions stored in the /store directory to help separate our code
import misc from './modules/misc';
import users from './modules/users';
import company from './modules/company';
import emailTemplate from './modules/emailTemplate';
import evaluations from './modules/evaluation';
import clients from './modules/clients';
import groups from './modules/groups';
import medias from './modules/medias';
import assessors from './modules/assessors';
import reports from './modules/reports';
import auth from './modules/auth';
import axios from 'axios';
import { SET_FILES, SET_TRANSLATIONS, SET_UPLOAD_DOC } from './types';

// store plugins
import VuexPersistence from 'vuex-persist';
import localForage from 'localforage';

// import LogRocket from 'logrocket';
// import createPlugin from 'logrocket-vuex';

// const logrocketPlugin = createPlugin(LogRocket);
const vuexLocal = new VuexPersistence({
  // storage: window.localStorage,
  storage: localForage,
  asyncStorage: true,
});

//NOTE: Please refer to https://www.dropbox.com/preview/Clients/2017%20zinpro/first%20step%20dairy%20application%20redesign/development/Discovery/Zinpro%20(FirstStep)%20-%20Data%20Models%20-%20v9.pdf?role=work to see how these objects relate to one another.
// All the variables must be stored in the store object, however, they maybe found in the src/store/modules folder inorder to seperate concerns.
export const store = new Vuex.Store({
  state: {
    isMultipleTabs: false,
    //Global initialization of the types of assessor that app currently supports
    // baseUrl:"https://"+window.location.hostname,
    // baseUrl:"http://192.168.1.19:5000",
    baseUrl: process.env.VUE_APP_BASE_URL
      ? process.env.VUE_APP_BASE_URL
      : 'https://firststep.zinpro.com',
    dataLoaded: true,
    // isOnline: navigator.onLine,
    settingsList: [
      {
        id: 'currencies',
        title: 'Currency list',
        fileName: 'currencies.xlsx',
        file: null,
        metadata: null,
      },
      {
        id: 'countries',
        title: 'Country list',
        fileName: 'countries.xlsx',
        file: null,
        metadata: null,
      },
      {
        id: 'states',
        title: 'States list',
        fileName: 'states.xlsx',
        file: null,
        metadata: null,
      },
    ],
    languageList: [
      {
        id: 'spanish',
        title: 'Spanish',
        fileName: 'spanish.xlsx',
        code: 'es',
        file: null,
        metadata: null,
      },
      {
        id: 'french',
        title: 'Français',
        code: 'fr',
        fileName: 'french.xlsx',
        file: null,
        metadata: null,
      },
      {
        id: 'turkish',
        title: 'Türkçe',
        code: 'tr',
        fileName: 'turkish.xlsx',
        file: null,
        metadata: null,
      },
      {
        id: 'german',
        title: 'Deutsch',
        code: 'de',
        fileName: 'german.xlsx',
        file: null,
        metadata: null,
      },
      {
        id: 'portuguese',
        title: 'Portugûes',
        code: 'pt',
        fileName: 'portuguese.xlsx',
        file: null,
        metadata: null,
      },
      {
        id: 'chinese',
        title: '中文',
        code: 'ch',
        fileName: 'chinese.xlsx',
        file: null,
        metadata: null,
      },
      {
        id: 'japanese',
        title: '日本語',
        code: 'jp',
        fileName: 'japanese.xlsx',
        file: null,
        metadata: null,
      },
      {
        id: 'polish',
        title: 'Polski',
        code: 'pl',
        fileName: 'polish.xlsx',
        file: null,
        metadata: null,
      },
      {
        id: 'italian',
        title: 'Italiano',
        code: 'it',
        fileName: 'italian.xlsx',
        file: null,
        metadata: null,
      },
      {
        id: 'dutch',
        title: 'Nederlands',
        code: 'nl',
        fileName: 'dutch.xlsx',
        file: null,
        metadata: null,
      },
      {
        id: 'russian',
        title: 'Russian',
        code: 'ru',
        fileName: 'russian.xlsx',
        file: null,
        metadata: null,
      },
    ],
    guideList: [
      {
        id: 'sales-rep-english',
        title: 'Sales Rep (English)',
        file: null,
        metadata: null,
      },
      {
        id: 'sales-rep-spanish',
        title: 'Sales Rep (Español)',
        file: null,
        metadata: null,
      },
      {
        id: 'sales-rep-french',
        title: 'Sales Rep (French)',
        file: null,
        metadata: null,
      },
      {
        id: 'sales-rep-turkish',
        title: 'Sales Rep (Turkish)',
        file: null,
        metadata: null,
      },
      {
        id: 'sales-rep-german',
        title: 'Sales Rep (German)',
        file: null,
        metadata: null,
      },
      {
        id: 'sales-rep-portuguese',
        title: 'Sales Rep (Portuguese)',
        file: null,
        metadata: null,
      },
      {
        id: 'sales-rep-chinese',
        title: 'Sales Rep (中文)',
        file: null,
        metadata: null,
      },
      {
        id: 'sales-rep-japanese',
        title: 'Sales Rep (日本語)',
        file: null,
        metadata: null,
      },
      {
        id: 'sales-rep-polish',
        title: 'Sales Rep (Polski)',
        file: null,
        metadata: null,
      },
      {
        id: 'sales-rep-italian',
        title: 'Sales Rep (Italiano)',
        file: null,
        metadata: null,
      },
      {
        id: 'sales-rep-dutch',
        title: 'Sales Rep (Nederlands)',
        file: null,
        metadata: null,
      },
      {
        id: 'sales-rep-russian',
        title: 'Sales Rep (Russian)',
        file: null,
        metadata: null,
      },
      // Advanced customer
      {
        id: 'advanced-customer-english',
        title: 'Advanced Customer (English)',
        file: null,
        metadata: null,
      },
      {
        id: 'advanced-customer-spanish',
        title: 'Advanced Customer (Español)',
        file: null,
        metadata: null,
      },
      {
        id: 'advanced-customer-french',
        title: 'Advanced Customer (French)',
        file: null,
        metadata: null,
      },
      {
        id: 'advanced-customer-turkish',
        title: 'Advanced Customer (Turkish)',
        file: null,
        metadata: null,
      },
      {
        id: 'advanced-customer-german',
        title: 'Advanced Customer (German)',
        file: null,
        metadata: null,
      },
      {
        id: 'advanced-customer-portuguese',
        title: 'Advanced Customer (Portuguese)',
        file: null,
        metadata: null,
      },
      {
        id: 'advanced-customer-chinese',
        title: 'Advanced Customer (中文)',
        file: null,
        metadata: null,
      },
      {
        id: 'advanced-customer-japanese',
        title: 'Advanced Customer (日本語)',
        file: null,
        metadata: null,
      },
      {
        id: 'advanced-customer-polish',
        title: 'Advanced Customer (Polski)',
        file: null,
        metadata: null,
      },
      {
        id: 'advanced-customer-italian',
        title: 'Advanced Customer (Italiano)',
        file: null,
        metadata: null,
      },
      {
        id: 'advanced-customer-dutch',
        title: 'Advanced Customer (Nederlands)',
        file: null,
        metadata: null,
      },
      {
        id: 'advanced-customer-russian',
        title: 'Advanced Customer (Russian)',
        file: null,
        metadata: null,
      },
      // Customer
      {
        id: 'customer-english',
        title: 'Customer (English)',
        file: null,
        metadata: null,
      },
      {
        id: 'customer-spanish',
        title: 'Customer (Español)',
        file: null,
        metadata: null,
      },
      {
        id: 'customer-french',
        title: 'Customer (French)',
        file: null,
        metadata: null,
      },
      {
        id: 'customer-turkish',
        title: 'Customer (Turkish)',
        file: null,
        metadata: null,
      },
      {
        id: 'customer-german',
        title: 'Customer (German)',
        file: null,
        metadata: null,
      },
      {
        id: 'customer-portuguese',
        title: 'Customer (Portuguese)',
        file: null,
        metadata: null,
      },
      {
        id: 'customer-chinese',
        title: 'Customer (中文)',
        file: null,
        metadata: null,
      },
      {
        id: 'customer-japanese',
        title: 'Customer (日本語)',
        file: null,
        metadata: null,
      },
      {
        id: 'customer-polish',
        title: 'Customer (Polski)',
        file: null,
        metadata: null,
      },
      {
        id: 'customer-italian',
        title: 'Customer (Italiano)',
        file: null,
        metadata: null,
      },
      {
        id: 'customer-dutch',
        title: 'Customer (Nederlands)',
        file: null,
        metadata: null,
      },
      {
        id: 'customer-russian',
        title: 'Customer (Russian)',
        file: null,
        metadata: null,
      },
    ],
    dataCollectionList: [
      {
        id: 'metric',
        title: 'Metric',
        file: null,
        metadata: null,
      },
      {
        id: 'imperial',
        title: 'Imperial',
        file: null,
        metadata: null,
      },
      {
        id: 'spanish',
        title: 'Español',
        file: null,
        metadata: null,
      },
      {
        id: 'french',
        title: 'Français',
        file: null,
        metadata: null,
      },
      {
        id: 'turkish',
        title: 'Türkçe',
        file: null,
        metadata: null,
      },
      {
        id: 'german',
        title: 'Deutsch',
        file: null,
        metadata: null,
      },
      {
        id: 'portuguese',
        title: 'Portugûes',
        file: null,
        metadata: null,
      },
      {
        id: 'chinese',
        title: '中文',
        file: null,
        metadata: null,
      },
      {
        id: 'japanese',
        title: '日本語',
        file: null,
        metadata: null,
      },
      {
        id: 'polish',
        title: 'Polski',
        file: null,
        metadata: null,
      },
      {
        id: 'italian',
        title: 'Italiano',
        file: null,
        metadata: null,
      },
      {
        id: 'dutch',
        title: 'Nederlands',
        file: null,
        metadata: null,
      },
      {
        id: 'russian',
        title: 'Russian',
        file: null,
        metadata: null,
      },
    ],
    roles: [
      {
        id: 'data_entry',
        name: 'Customer',
      },
      {
        id: 'external',
        name: 'Advanced Customer',
      },
      {
        id: 'zinpro_representative',
        name: 'Zinpro Representative',
      },
      {
        id: 'admin',
        name: 'Admin',
      },
    ],
    assessorTypes: [
      {
        id: '7',
        name: 'Bedded Pack',
        description: [
          'Whether a bedded pack is aerobic or anaerobic, it is important to provide sufficient bedding area per cow. Using an estimate of the average body weight, this assessor will determine if your operation features sufficient bedded-area per cow and will also help evaluate its ventilation, drainage, location and cleanliness.',
        ],
        enabled: true,
      },
      {
        id: '6',
        name: 'Biosecurity',
        description: [
          'The Biosecurity Assessor evaluates factors that may impact disease prevention on the dairy.',
          'Biosecurity is assessed to determine if cattle are at risk of being exposed to infectious claw lesions from cattle being brought onto the dairy.',
        ],
        enabled: true,
      },
      {
        id: '5',
        name: 'Dirt Lot',
        description: [
          'To remain healthy and happy, cows on a dirt lot need adequate space for resting, feeding and staying cool. This assessor helps evaluate your dirt lot to ensure that there is enough space-per-cow in these vital areas.',
        ],
        enabled: true,
      },
      {
        id: '2',
        name: 'Freestall',
        description: [
          'When a stall is designed to properly fit a cow, it makes it much easier for that cow to get up and lie down – even when she’s lame.',
          'Use this assessor to determine how to design the perfect stall for varying sized cows. Or, gather measurements of your current stalls to assess how well your stalls fit the cows and whether adjustments should be made.',
        ],
        enabled: true,
      },
      {
        id: '12',
        name: 'Freestall Ventilation',
        description: [
          'Proper ventilation during the summer months is critical for keeping cows cool and limiting changes in behavior and feeding activity. This assessor helps evaluate natural and tunnel/mechanical ventilation systems to ensure that cows stay cool during periods of heat stress.',
        ],
        enabled: true,
      },
      {
        id: '16',
        name: 'Heat Abatement',
        description: [
          'Ventilation, water availability, adequate shade and proper cooling systems are all part of effective heat abatement on a dairy. This assessor helps evaluate the five key factors that most affect heat abatement on freestall and dirt-lot dairies.',
        ],
        enabled: true,
      },
      {
        id: '11',
        name: 'Holding Area',
        description: [
          'The holding area influences time out of the pen, foot trauma and heat stress, making it a critical place for lameness-control efforts. Use this assessor to evaluate your holding area and its effect on lameness, determining if the flooring surface, square footage and cooling system is adequate for healthy, happy cows.',
        ],
        enabled: true,
      },
      {
        id: '20',
        name: 'Holding Yard & Milking Shed',
        description: [
          'For grazing dairies, the holding yard and milk shed are critical areas to evaluate when investigating lameness. Use this assessor to evaluate your facilities and determine if they are contributing to lameness.',
        ],
        enabled: true,
      },
      {
        id: '15',
        name: 'Hoof Trimming',
        description: [
          'The Hoof Trimming Assessor helps identify common trimming mistakes that should be avoided if improvements to lameness management on the farm are to have any effect.',
          'This assessor calculates an ‘expected number of trims’ based on size of the herd, stated frequency of trimming, if heifers are trimmed prior to first calving and if lame cows are seen by the trimmer.',
          'Identifying and eliminating common trimming mistakes is an essential part of successful lameness reduction programs.',
        ],
        enabled: true,
      },
      {
        id: '1',
        name: 'Hygiene/Footbath',
        description: [
          'The hygiene and footbath assessors can be used in combination or individually based on need. The hygiene scores provide a starting point for determining the required frequency of footbaths. Use these assessors to determine the overall state of hoof cleanliness.',
        ],
        enabled: true,
      },
      {
        id: '17',
        name: 'Lesion',
        description: [
          'Identification of claw lesions is critical in diagnosing the causes of lameness. This application helps identify the type of lesion and its severity. It also allows assessment of lesion prevalence in groups of animals.',
        ],
        enabled: true,
      },
      {
        id: '3',
        name: 'Locomotion',
        description: [
          'The Locomotion Assessor evaluates the prevalence and severity of lameness using locomotion scoring results from either whole herd or group data. It calculates the percentage of cows at each score, estimates milk yield loss and the associated financial loss due to lameness.',
          'Locomotion Scoring is an effective tool for assessing the prevalence and severity of lameness within the herd. Determine your increased revenue potential now.',
        ],
        enabled: true,
      },
      {
        id: '14',
        name: 'Nutrition and Feeding',
        description: [
          'Nutrition and feed management play critical roles in lameness prevention. This assessor helps evaluate feed delivery, feed access, risk factors for sub-acute ruminal acidosis (SARA) and nutrient supply, and places special emphasis on nutrients believed to play a role in lameness.',
        ],
        enabled: true,
      },
      {
        id: '9',
        name: 'Races, Tracks and Lanes',
        description: [
          'Properly designed races, tracks and lanes are critical for facilitating good cow flow and minimizing risk in grazing dairies. This assessor helps evaluate risk factors in these areas.',
        ],
        enabled: true,
      },
      {
        id: '10',
        name: 'Tie Stall/Stanchion',
        description: [
          'The Tiestall/Stanchion Assessor evaluates five key aspects of stall design from the perspective of the cow entering, lying down, standing and leaving the stall.',
          'Tiestalls and stanchions are assessed from the perspective of the cow entering, lying down, standing and leaving the stall.',
        ],
        enabled: true,
      },

      {
        id: '13',
        name: 'Tiestall/Stanchion Heat Abatement',
        description: [
          'For cattle housed in tiestall or stanchion barns, effective ventilation and heat abatement programs are critical for prevention of lameness in the late summer months. This assessor helps evaluate tunnel and cross-ventilated facilities to ensure that a sufficient amount of air exchanges per hour is achieved.',
        ],
        enabled: true,
      },
      {
        id: '4',
        name: 'Time Budget',
        description: [
          'When cows are comfortable, they are more productive and have a reduced incidence of lameness. Conversely, when they spend too much time on their feet, lameness is more prevalent, older cow retention decreases and herd turnover rates increase. Use this calculator to determine how your herd is doing where time budget is concerned.',
        ],
        enabled: true,
      },
      {
        id: '19',
        name: 'Transition',
        description: [
          'This assessor helps you evaluate the adequacy of the transition period management in your operation.',
        ],
        enabled: true,
      },
      {
        id: '18',
        name: 'Walking Surface',
        description: [
          'Walking surfaces impact lameness by creating trauma and wear, influencing the tendency to slip and causing concussion. Use this assessor to evaluate each walking and standing surface outside the stall for these four common risk factors.',
        ],
        enabled: true,
      },
    ],
    adminRoles: ['admin', 'zinpro_representative'],
    error: false,
    loader: false,
    offline: false,
    successMessage: false,
  },
  getters: {
    isOnline() {
      return navigator.onLine;
    },
    getRoleName: (state, getters) => roleId => {
      const role = state.roles.find(role => {
        return role.id === roleId;
      });

      if (role) {
        return role.name;
      }

      return 'N/A';
    },

    handleConnectivityChange: (state, getters) => status => {
      state.offline = !status;
    },

    getAssessorInfoByType: (state, getters) => assessorType => {
      return state.assessorTypes.find(assessorTypeInfo => {
        return assessorTypeInfo.id === assessorType;
      });
    },

    isIOS: (state, getters) => () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    },

    isAndroid: (state, getters) => () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /Android|android/.test(userAgent);
    },

    isPWA: (state, getters) => () => {
      return (
        window.matchMedia('(display-mode: standalone)').matches ||
        window.navigator.standalone
      );
    },

    resizeBase64: (state, getters) => (base64, maxWidth, maxHeight) => {
      // Max size for thumbnail
      if (typeof maxWidth === 'undefined') maxWidth = 500;
      if (typeof maxHeight === 'undefined') maxHeight = 500;

      // Create and initialize two canvas
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      var canvasCopy = document.createElement('canvas');
      var copyContext = canvasCopy.getContext('2d');

      // Create original image
      var img = new Image();
      img.src = base64;

      // Determine new ratio based on max size
      var ratio = 1;
      if (img.width > maxWidth) ratio = maxWidth / img.width;
      else if (img.height > maxHeight) ratio = maxHeight / img.height;

      // Draw original image in second canvas
      canvasCopy.width = img.width;
      canvasCopy.height = img.height;
      copyContext.drawImage(img, 0, 0);

      // Copy and resize second canvas to first canvas
      canvas.width = img.width * ratio;
      canvas.height = img.height * ratio;
      ctx.drawImage(
        canvasCopy,
        0,
        0,
        canvasCopy.width,
        canvasCopy.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      return canvas.toDataURL();
    },

    getOrientation: (state, getters) => (file, callback) => {
      var reader = new FileReader();
      reader.onload = function(e) {
        var view = new DataView(e.target.result);
        if (view.getUint16(0, false) != 0xffd8) {
          return callback(-2);
        }
        var length = view.byteLength,
          offset = 2;
        while (offset < length) {
          if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
          var marker = view.getUint16(offset, false);
          offset += 2;
          if (marker == 0xffe1) {
            if (view.getUint32((offset += 2), false) != 0x45786966) {
              return callback(-1);
            }

            var little = view.getUint16((offset += 6), false) == 0x4949;
            offset += view.getUint32(offset + 4, little);
            var tags = view.getUint16(offset, little);
            offset += 2;
            for (var i = 0; i < tags; i++) {
              if (view.getUint16(offset + i * 12, little) == 0x0112) {
                return callback(view.getUint16(offset + i * 12 + 8, little));
              }
            }
          } else if ((marker & 0xff00) != 0xff00) {
            break;
          } else {
            offset += view.getUint16(offset, false);
          }
        }
        return callback(-1);
      };
      reader.readAsArrayBuffer(file);
    },

    resetOrientation: (state, getters) => (
      srcBase64,
      srcOrientation,
      callback
    ) => {
      var img = new Image();

      img.onload = function() {
        var width = img.width,
          height = img.height,
          canvas = document.createElement('canvas'),
          ctx = canvas.getContext('2d');

        // set proper canvas dimensions before transform & export
        if (4 < srcOrientation && srcOrientation < 9) {
          canvas.width = height;
          canvas.height = width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }

        // transform context before drawing image
        switch (srcOrientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, width, 0);
            break;
          case 3:
            ctx.transform(-1, 0, 0, -1, width, height);
            break;
          case 4:
            ctx.transform(1, 0, 0, -1, 0, height);
            break;
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6:
            ctx.transform(0, 1, -1, 0, height, 0);
            break;
          case 7:
            ctx.transform(0, -1, -1, 0, height, width);
            break;
          case 8:
            ctx.transform(0, -1, 1, 0, 0, width);
            break;
          default:
            break;
        }

        // draw image
        ctx.drawImage(img, 0, 0);

        // export base64
        callback(canvas.toDataURL());
      };

      img.src = srcBase64;
    },

    commaFormatted: (state, getters) => amount => {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    getPercentagesRoundedTo100: (state, getters) => (l, target) => {
      var off =
        target -
        _.reduce(
          l,
          (acc, x) => {
            return acc + Math.round(x);
          },
          0
        );
      return _.chain(l)
        .map((x, i) => {
          return Math.round(x) + (off > i) - (i >= l.length + off);
        })
        .value();
    },

    getGroupStatus: (state, getters) => group => {
      if (group.assessors === undefined || group.assessors.length === 0) {
        return 0;
      }
      let assessorStatuses = group.assessors.map(assessor => {
        return getters.getAssessorStatus(assessor);
      });
      // check for all red statuses
      let redStatuses = assessorStatuses.filter(status => {
        return status === 0;
      });
      if (redStatuses.length === assessorStatuses.length) {
        return 0;
      }
      // check for all green statuses
      let greenStatuses = assessorStatuses.filter(status => {
        return status === 2;
      });
      if (greenStatuses.length === assessorStatuses.length) {
        return 2;
      }
      // if not all red or green, then default to yellow
      return 1;
    },
    getEvaluationStatus: (state, getters) => evaluation => {
      if (evaluation.groups === undefined || evaluation.groups.length === 0) {
        return 0;
      }
      let groupsStatuses = evaluation.groups.map(group => {
        return getters.getGroupStatus(group);
      });
      // check for all red statuses
      let redStatuses = groupsStatuses.filter(status => {
        return status === 0;
      });
      if (redStatuses.length === groupsStatuses.length) {
        return 0;
      }
      // check for all green statuses
      let greenStatuses = groupsStatuses.filter(status => {
        return status === 2;
      });
      if (greenStatuses.length === groupsStatuses.length) {
        return 2;
      }
      // if not all red or green, then default to yellow
      return 1;
    },
    getAssessorStatusByGroups: (state, getters) => (
      evaluation,
      assessorType
    ) => {
      let groupsStatuses = evaluation.groups.map(group => {
        if (group.assessors === undefined || group.assessors.length === 0) {
          return 0;
        }
        let assessor = group.assessors.find(assessor => {
          return assessor.type === assessorType;
        });
        if (assessor === undefined) {
          return 0;
        }
        return getters.getAssessorStatus(assessor);
      });
      // check for all red statuses
      let redStatuses = groupsStatuses.filter(status => {
        return status === 0;
      });
      if (redStatuses.length === groupsStatuses.length) {
        return 0;
      }
      // check for all green statuses
      let greenStatuses = groupsStatuses.filter(status => {
        return status === 2;
      });
      if (greenStatuses.length === groupsStatuses.length) {
        return 2;
      }
      // if not all red or green, then default to yellow
      return 1;
    },
    getCurrentUser: (state, getters) => endpoint => {
      return state.user;
    },
    getData: (state, getters) => endpoint => {
      let endpointsArray;
      if (endpoint === undefined) {
        endpointsArray = [];
      } else {
        endpointsArray = endpoint.split('/'); //Example URL: clients/1
      }

      let isEvaluation = false;
      if (endpointsArray[2] === 'evaluations' && endpointsArray.length === 4) {
        isEvaluation = true;
      }

      let subState;

      if (state.user.id === endpointsArray[1]) {
        subState = state.user;
        // Shift the users and userId out of the endpoints array
        endpointsArray.shift();
        endpointsArray.shift();
      } else {
        subState = state;
      }

      let isCollection = true;
      for (let i = 0; i < endpointsArray.length; i++) {
        if (!isCollection) {
          if (Array.isArray(subState)) {
            subState = subState.find(doc => doc.id === endpointsArray[i]);
          } else {
            subState = subState[endpointsArray[i]];
          }

          if (subState !== undefined && subState.length === 1) {
            subState = subState[0];
          } else if (!subState) {
            return subState;
          }
        } else {
          subState = subState[endpointsArray[i]];

          if (Array.isArray(subState) && endpointsArray[i] !== 'evaluations') {
            let object = {};
            for (let i = 0; i < subState.length; i++) {
              object[subState[i].id] = subState[i];
            }
            subState = object;
          }

          if (!subState) return undefined;
        }
        isCollection = !isCollection;
      }

      return subState;
    },
    getAssessorTypeById: (state, getters) => assessorId => {
      console.log();
      let assessorType = state.assessorTypes.find(
        assessorType => assessorType.id === assessorId
      );

      if (!assessorType) {
        assessorType = {
          name: 'Unavailable Assessor',
        };
      }

      return assessorType;
    },
    getDate: (state, getters) => (timestamp = new Date()) => {
      let format = 'en-GB';
      let options = { day: 'numeric', month: 'short', year: 'numeric' };
      // showing the different format for china
      if (state.i18n.locale === 'ch') {
        format = 'sv-SE';
        options = {}
      }

      const dateFormatter = Intl.DateTimeFormat(format, {...options});
     const formattedDate = dateFormatter.format(new Date(timestamp)).replaceAll('.', '/');
    //  console.log({formattedDate: formattedDate.split(' '), app});
     return formattedDate
    },
  },

  mutations: {
    [SET_UPLOAD_DOC](STATE, UPLOADED_FILE) {
      const fileId = _.get(UPLOADED_FILE, 'metadata.customMetadata.fileId', '');
      const listType = _.get(UPLOADED_FILE, 'type', '');
      const list = STATE[`${listType}List`];

      const updatedList = list.map(listItem =>
        listItem.id === fileId
          ? { ...listItem, metadata: UPLOADED_FILE.metadata }
          : listItem
      );

      STATE[`${listType}List`] = updatedList;
    },

    [SET_FILES](STATE, { fileList, type }) {
      // fileUrl = _this.storageFileUrl + encodeURIComponent(metadata.fullPath) + '?alt=media';
      // get the dynamic list

      const localList = STATE[`${type}List`];

      // update the local list with api return document metadata
      fileList.forEach(fileData => {
        const fileId = _.get(fileData, 'metadata.customMetadata.fileId', '');
        const docIndex = localList.findIndex(el => el.id === fileId);

        if (docIndex < 0) return;

        // add the metadata into the list item
        localList[docIndex].metadata = {
          ...JSON.parse(JSON.stringify(fileData.metadata)),
          // fileUrl,
        };
      });

      STATE[`${type}List`] = JSON.parse(JSON.stringify(localList));
    },
    [SET_TRANSLATIONS](STATE, { FILE_DATA, CODE, $app }) {
      console.log({ CODE, isExist: $app.$i18n.localeExists(CODE) });
      const outputData = {};
      FILE_DATA.forEach(item => {
        outputData[item.originalValue] = item.translatedValue;
      });

      if ($app.$i18n.localeExists(CODE)) {
        $app.$i18n.replace(CODE, outputData);
      }

      $app.$i18n.add(CODE, outputData);
    },
    setData: (state, payload) => {
      return;
    },
    deleteData: (state, payload) => {
      return;
    },
    resetUser(state) {
      // acquire initial state

      state.user = { lang: state.user ? state.user.lang : 'en' };
      state.users = undefined;
    },
  },

  actions: {
    requestReport: ({ commit }, { currentVueInstance, evaluationName }) => {
      let self = currentVueInstance;

      self.$store.state.loader = true;
      const customer = {
        customerId: self.$store.state.user.id,
        email: self.$store.state.user.email,
        firstName: self.$store.state.user.firstName,
        lastName: self.$store.state.user.lastName,
        lang: self.$store.state.user.lang,
        evaluationName: evaluationName,
      };

      self.$firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(idToken => {
          customer.sessionToken = idToken;
          return axios.post(
            self.$store.state.baseUrl + '/request-report',
            customer
          );
        })
        .then(response => {
          self.$store.state.successMessage = true;
          self.$store.state.loader = false;

          setTimeout(() => {
            self.$store.state.successMessage = false;
          }, 5000);
        })
        .catch(error => {
          self.$store.state.error = true;
          self.$store.state.loader = false;

          setTimeout(() => {
            self.$store.state.error = false;
          }, 5000);
        });
    },
    getCustomerData: async ({ commit }, { currentVueInstance, userId }) => {
      if (currentVueInstance.$store.state.user.id === userId) {
        return resolve();
      }
    },
  },
  namespaced: true,
  modules: {
    misc,
    users,
    company,
    evaluations,
    clients,
    groups,
    medias,
    assessors,
    emailTemplate,
    emailTemplate,
    reports,

    // auth
  },
  // logrocketPlugin
  plugins: [vuexLocal.plugin],
});

// Set Localization
Vue.use(vuexI18n.plugin, store, {
  moduleName: 'i18n',
  warnings: false,
});

// set a fallback locale if translation for current locale does not exist
// Vue.i18n.fallback('en');

// set the start locale to use
// Vue.i18n.set((window.navigator.userLanguage || window.navigator.language).includes("es") ? 'es' : 'en');

//REQUIRED
export default {};
