import { transition } from './transition';
import { medias } from './../Global/medias.js';
import { group } from "./group";

class TransitionAssessorSlides {

    constructor(app, report, evaluation, group, user) {
        this.app = app;
        this.report = report;
        this.evaluation = evaluation;
        this.group = group;
        this.user = user;
    }

    add(tableOfContentsCalculatorPosition) {
        let assessor = this.group.getAssessorByType("19");

        if ( assessor === false || assessor.assessor.data === undefined ) {
            throw this.group.getName() + this.app.$t(" - ") + this.app.$t("Transition") + this.app.$t(" assessor is not ready.");
        }

        this.report.tableOfContentsHelper("Transition", tableOfContentsCalculatorPosition, this.group);

        transition(this.app, this.report, this.evaluation, this.group, assessor);
        medias(this.app, this.report, this.group, "19", this.user);
    }

    groupSummary(tableOfContentsCalculatorPosition) {
        group(this.app, this.report, this.evaluation);
    }

}

export default TransitionAssessorSlides;