<template>
  <div class="dashboard-notes">
    <div class="row my-0">
      <div class="col-md-24">
        <h2 class="h2 d-inline-block">
          {{ 'Personal Notes' | translate }}
        </h2>
        <modal-info :value="'personal-notes'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pt-3 px-3 text-center">
              {{
                'Personal Notes: Not included when outputting reports.'
                  | translate
              }}
            </h2>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
              >
                Cancel
              </a>
            </div>
          </template>
        </modal-info>
      </div>
      <div class="col-md-24">
        <textarea
          v-model="dashboardNotes"
          v-on:input="$emit('input', $event.target.value)"
          rows="6"
          class="form-control d-none d-lg-block"
        />
        <textarea
          v-model="dashboardNotes"
          v-on:input="$emit('input', $event.target.value)"
          rows="4"
          class="form-control d-lg-none"
        />
      </div>
    </div>

    <div
      class="row mx-0 justify-content-center justify-content-md-start center align-items-center pb-0 mt-4"
    >
      <photoUpload />
    </div>
  </div>
</template>

<script>
import ModalInfo from '@/components/misc/modal-info.vue';
import PhotoUpload from '@/components/misc/photoUpload';

export default {
  name: 'DashboardNotes',
  data() {
    return {
      dashboardNotes: '',
    };
  },
  components: {
    ModalInfo,
    PhotoUpload,
  },
};
</script>
