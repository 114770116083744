/**
 * The biosecurity assessor provides recommendations and warnings for the user depending on if the user has exposed their herd to any
 * outside cattle. The assessor is made up of all yes/no questions.
 */

import Assessor from './Assessor';

class Biosecurity extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;
    }

    getAreAnyMovingOnToTheMainFarm() {
        return this.assessor.data.are_any_cows_heifers_calves_moving_on;
    }

    getIsTheDairyBringingAnyCattleOntoTheDairy() {
        return this.assessor.data.is_the_dairy_bringing_any_cattle_onto_the_dairy;
    }

    getAreCattleFromTheDairyShownAtFairsAndCattleShows() {
        return this.assessor.data.are_cattle_from_the_dairy_shown_at_fairs_and_cattle_shows;
    }

    getAreReturningCattleOrPurchasedCattleFootbathedBeforeComminglingWithTheMainHerd() {
        return this.assessor.data.are_returning_cattle_or_purchased_cattle_footbathed_before_commingling_with_the_main_herd;
    }

    getIsTheHoofTrimmersEquipmentAlwaysClean() {
        return this.assessor.data.is_the_hoof_trimmers_equipment_always_clean;
    }

    getDoesTheHerdTestForBvdPiCattle() {
        return this.assessor.data.does_the_herd_test_for_bvd_pi_cattle;
    }

    getHaveAnyPiCattleBeenIdentifiedInTheLast12Months() {
        return this.assessor.data.have_any_pi_cattle_been_identified_in_the_last_12_months;
    }

};

export default Biosecurity;