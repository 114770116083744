<!-- @format -->

<template>
  <div class="custom-modal-overlay" v-if="isOpen">
    <div class="custom-modal">    
      <div class="custom-modal-content">
        <h2 class="h2 pt-3 px-3 text-center">
          {{ "Zinpro Products" | translate }}
        </h2>
        <div class="radio-btn m-4 scrollable">
          <table>
            <thead>
              <tr>
                <th class="pt-3 pb-2 px-3 text-center">{{ "Zinpro Products" | translate }}</th>
                <th class="pt-3 pb-2 px-3 text-center">{{ "Inclusion" | translate }}</th>
                <th class="pt-3 pb-2 px-3 text-center">{{"Unit" | translate }}</th>
                <th class="pt-3 pb-2 px-3 text-center">{{ "Delete" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <!-- Your table data goes here -->
              <tr
                class="table-row"
                v-for="selectedMineral in Object.keys(
                  selectedMinerals
                )"
                :key="`${selectedMineral.name}-${Math.random()}`"
              >
               <td class="pt-3 pb-2 px-3 text-center">
                <span class="with-trademark">Zinpro</span>&nbsp;&nbsp;
                <span> </span>
                <span v-if="selectedMinerals[selectedMineral].mineral === 'IsoFerm'" class="with-trademark">
                   {{ selectedMinerals[selectedMineral].mineral }}
                </span>
                <span v-else>
                  {{ selectedMinerals[selectedMineral].mineral | translate }}
                </span>
                </td>
                <td>
                  <div class="form-group" style="margin-bottom: 0">
                    <input
                      class="form-control"
                      style="margin-bottom: 0"
                      type="text"
                      v-model="
                        selectedMinerals[selectedMineral].value
                      "
                    />
                  </div>
                </td>
                <th class="pt-3 pb-2 px-3 text-center"> {{selectedMinerals[selectedMineral].unit | translate}}</th>
                <td>
                  <button
                    class="btn shrink-on-mobile"
                    @click="() => deleteMineral(selectedMineral)"
                  >
                    {{ "Delete" | translate }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

       <select
          name="mineral"
          class="form-control"
          data-vv-as="Avg. Cow Size"
          v-validate="'required'"
          @click="handleSelect"
        >
          <option :value="null" selected disabled>
            {{ "Select" | translate }}
          </option>
          <option
            v-for="mineral in minerals"
            :value="mineral.name"
            :key="mineral.name"
            :disabled="selectedMinerals[mineral.name]"
          >
           <span class="trademark">Zinpro<span>®</span></span>
          {{ getMineralDisplayName(mineral) | translate}}
          </option>
        </select>

        <button
          @click="$emit('close')"
          class="btn btn-primary btn-small-modal ml-auto"
        >
          {{ "Done" | translate }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MineralDetailModal",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedMinerals: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      evaluationId: this.$route.params.evaluationId,
      evaluation: {},
      value: "",
       minerals: [
      { name: "Co", unit: "PPM" },
      { name: "Cr", unit: "PPM" },
      { name: "Cu", unit: "PPM" },
      { name: "Fe", unit: "PPM" },
      { name: "Mn", unit: "PPM" },
      { name: "Se", unit: "PPM" },
      { name: "Zn", unit: "PPM" },
      { name: "IsoFerm", unit: "g" }
    ]
    };
  },
  methods: {
      getMineralDisplayName(mineral) {
    if (mineral.name === "IsoFerm") {
      return mineral.name + "®";
    } else {
      return mineral.name;
    }
  },
    closeModal() {
      this.$emit("update:isOpen", false); // Emit event to close the modal
    },
    handleSelect(event) {
      const selectedMineral = event.target.value;
      if (!selectedMineral) return;
      this.selectedMinerals[selectedMineral] = {
        mineral: selectedMineral,
        value: this.selectedMinerals?.[selectedMineral]?.value || "",
        unit : selectedMineral === "IsoFerm" ? "g" : "PPM"
      };
      this.$forceUpdate();
    },
    deleteMineral(mineralKey) {
      const selectedMinerals = Object.fromEntries(
        Object.entries(this.selectedMinerals).filter(
          ([mineral]) => mineral !== mineralKey
        )
      );
      this.$emit("delete-mineral", selectedMinerals);
    },
  },
};
</script>

<style scoped>
td .with-trademark {
  position: relative;
}

td .with-trademark::after {
  content: "®";
  position: absolute;
  top: -0.4em;
  left: 97%;
  font-size: smaller;
}

.scrollable {
  max-height: 300px;
  overflow-y: auto;
}
.custom-modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.custom-modal {
  border: 10px solid #ffffff;
  padding: 10px 20px 20px 20px;
  background: #e9ecef;
  width: 80%;
  max-width: 600px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

@media (max-width: 520px) {
  .shrink-on-mobile {
    min-width: 100px;
  }
}

@media (max-width: 420px) {
  .shrink-on-mobile {
    min-width: 60px;
  }
}

.custom-modal-content {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th:nth-child(2),
td:nth-child(2) {
  width: 40%;
}

th:first-child,
td:first-child {
  width: 50%;
}

th:last-child,
td:last-child {
  width: 20%;
}
</style>
