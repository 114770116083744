<template>
  <div class="container main-container main-container--hygiene view">
    <div class="row">
      <div class="col-24">
        <h2 class="h2 mb-3 d-inline-block mt-2 mt-md-0">
          {{ "Tell Us How Your Cows Scored While Walking" | translate }}
        </h2>
        <modal-info :value="'operation'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ "Locomotion" | translate }}
            </h2>
            <p class="px-3">
              {{
                "For more information about Locomotion, click the link to learn more from"
                  | translate
              }}
              <a
                :href="
                  $t('https://library.zinprofirststep.com/locomotion-scoring/')
                "
                @click="$goToLameness($t('/locomotion-scoring/'), $event)"
                target="_blank"
                >{{ "FirstStep Resources" | translate }}</a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ "Cancel" | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
      <div class="col-md-12">
        <h2 class="h2 d-inline-block mb-0 mr-1">
          {{ "Scoring Type" | translate }}
        </h2>
        <modal-info :value="'scoring-type'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ "Scoring Type" | translate }}
            </h2>
            <p class="px-3">
              {{
                'Choose "4 Point Scoring" when the animals are moving through the scoring area and differentiating between locomotion scores 2 and 3 is not possible.'
                  | translate
              }}
            </p>
            <p class="px-3">
              {{
                'Choose "5 Point Scoring" in all other instances.' | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ "Cancel" | translate }}</a
              >
            </div>
          </template>
        </modal-info>
        <hr class="my-4" />
        <img
          src="@/assets/img/Scoring_Type.jpg"
          alt="Scoring Type"
          class="w-100 mb-3 mb-lg-0"
        />
        <label class="mb-2 mt-3">{{
          "Select a Scoring Type" | translate
        }}</label>
        <div class="d-flex align-self-center flex-row mb-3 mb-md-0">
          <div
            class="label_group label_group--width-sm label_group--inline mr-3 mr-sm-3"
          >
            <input
              class="label_group__input"
              v-model.number="assessor.data.locomotion_type"
              type="radio"
              id="4point"
              value="0"
            />
            <label
              class="label_group__label label_group__label--sm"
              for="4point"
              >{{ "4 Point Scoring" | translate }}</label
            >
          </div>
          <div class="label_group label_group--width-sm label_group--inline">
            <input
              class="label_group__input"
              v-model.number="assessor.data.locomotion_type"
              type="radio"
              id="5point"
              value="1"
            />
            <label
              class="label_group__label label_group__label--sm"
              for="5point"
              >{{ "5 Point Scoring" | translate }}</label
            >
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <h2 class="h2 d-inline-block mb-0 mr-1 mt-3 mt-md-0">
          {{ "Economics On/Off" | translate }}
        </h2>
        <modal-info :value="'economics'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ "Economics On/Off" | translate }}
            </h2>
            <p class="px-3">
              {{
                'Choose "Economics On" if you want to supply a milk price to measure your economic profit potential gained from improvements in locomotion score.'
                  | translate
              }}
            </p>
            <p class="px-3">
              {{
                'Choose "Economics Off" if you want to determine your herd\'s increased milk production potential but not supply a specific milk price.'
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ "Cancel" | translate }}</a
              >
            </div>
          </template>
        </modal-info>
        <hr class="my-4" />
        <img
          src="@/assets/img/Economics_OnOff.jpg"
          alt="Economics On Off"
          class="w-100 mb-3 mb-lg-0"
        />
        <label class="mb-2 mt-3">{{
          "Select Economics On/Off" | translate
        }}</label>
        <div class="d-flex align-self-center flex-row">
          <div
            class="label_group label_group--width-sm label_group--inline mr-3 mr-sm-3"
          >
            <input
              class="label_group__input"
              v-model.number="assessor.data.economics"
              type="radio"
              id="on"
              :value="1"
              :disabled="
                ['close-up', 'far-off', 'heifers-growing'].includes(
                  groupClass.getTypeOfAnimal()
                )
              "
            />
            <label
              class="label_group__label label_group__label--sm"
              :class="{
                disabled: ['close-up', 'far-off', 'heifers-growing'].includes(
                  groupClass.getTypeOfAnimal()
                ),
              }"
              for="on"
              >{{ "Economics On" | translate }}</label
            >
          </div>
          <div class="label_group label_group--width-sm label_group--inline">
            <input
              class="label_group__input"
              v-model.number="assessor.data.economics"
              type="radio"
              id="off"
              :value="0"
            />
            <label
              class="label_group__label label_group__label--sm"
              for="off"
              >{{ "Economics Off" | translate }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <hr class="my-4" />

    <div class="row">
      <div class="col-24">
        <h2 class="h2 mb-3 d-inline-block mt-2 mt-md-0">
          {{ "Tell Us About Your Operation" | translate }}
        </h2>
        <modal-info :value="'operation'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ "Locomotion" | translate }}
            </h2>
            <p class="px-3">
              {{
                "For more information about Locomotion, click the link to learn more from"
                  | translate
              }}
              <a
                :href="
                  $t('https://library.zinprofirststep.com/locomotion-scoring/')
                "
                @click="$goToLameness($t('/locomotion-scoring/'), $event)"
                target="_blank"
                >{{ "FirstStep Resources" | translate }}</a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ "Cancel" | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label class="d-block">
            <span class="d-block d-md-none d-lg-block">
              {{ "Select Your Type of Production" | translate }}
            </span>
            <span class="d-none d-md-block d-lg-none">
              {{ "Select Your Type of Prod." | translate }}
            </span>
          </label>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input"
                type="radio"
                name="measurements"
                value="confined_cattle"
                v-model="assessor.data.production_type"
              />
              {{ "Confined Cattle" | translate }}
            </label>
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input"
                type="radio"
                name="measurements"
                value="grazing_cattle"
                v-model="assessor.data.production_type"
              />
              {{ "Grazing Cattle" | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label class="mb-1">{{ "Date" | translate }}</label>
          <input
            type="text"
            class="form-control date-picker date-picker--disabled"
            name="evaluation_date"
            :value="getDate(evaluation.data.consultation_date)"
            data-vv-as="Evaluation Date"
            v-validate="'required'"
            disabled
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label class="mb-1">{{ "Group/Pen Size" | translate }}</label>
          <input
            type="number"
            inputmode="numeric"
            class="form-control"
            :value="groupClass.getGroupSize()"
            readonly
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label class="mb-1">{{ "Suggested Sample Size" | translate }}</label>
          <modal-info :value="'cow-passes'">
            <template slot="modal-content">
              <h2 class="h2 pb-2 pt-3 px-3 text-center">
                {{ "Suggested Sample Size" | translate }}
              </h2>
              <p class="px-3">
                {{
                  "For a detailed explanation of how sample size is calculated, please visit the link:"
                    | translate
                }}
                <a
                  href="https://s3.wp.wsu.edu/uploads/sites/2147/2015/03/LamenessScoringFactsheetTemplate_March2012.pdf"
                  target="_blank"
                  >{{ "More Information" | translate }}</a
                >
              </p>
              <div class="row modal-bottom pb-3">
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center"
                  data-dismiss="modal"
                  >{{ "Cancel" | translate }}</a
                >
              </div>
            </template>
          </modal-info>
          <input
            type="number"
            inputmode="numeric"
            class="form-control"
            :value="groupClass.getSampleSize()"
            readonly
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label class="mb-1"
            >{{ "Milk Production" | translate }} ({{
              $t($getEquivalentUnit(evaluation.data.measurements, "kg"))
            }}{{ "/day)" | translate }}</label
          >
          <input
            type="number"
            inputmode="decimal"
            class="form-control"
            :value="groupClass.getShowMilkProduction()"
            readonly
          />
        </div>
      </div>
      <div v-if="assessor.data.economics === 1" class="col-md-8">
        <div class="form-group">
          <label class="mb-1"
            >{{ "Milk Price" | translate }} ({{ evaluation.data.currency }}) /
            {{
              $t($getEquivalentUnit(evaluation.data.measurements, "kg"))
            }}</label
          >
          <input
            disabled
            type="number"
            inputmode="decimal"
            class="form-control"
            :value="evaluationClass.getMilkPrice(false)"
          />
        </div>
      </div>
    </div>

    <hr class="mb-4" />

    <div class="row">
      <div class="col-24 d-none d-md-block">
        <h2 class="h2 mb-3 mt-2 mt-md-0">
          {{
            "Score Your Cows for Locomotion (Click on image below for video)"
              | translate
          }}
        </h2>
      </div>
      <div class="col-24 d-md-none">
        <h2 class="h2 mb-3 mt-2 mt-md-0">
          {{ "Score Your Cows for Locomotion" | translate }}
        </h2>
      </div>
      <div class="col-24 d-md-none d-flex">
        <a
          href="#"
          class="hygiene-score__link"
          v-if="showGuide === false"
          @click.prevent="showGuide = true"
        >
          {{ "View Scoring Guide" | translate }}
        </a>
        <a
          href="#"
          class="hygiene-score__link"
          v-if="showGuide === true"
          @click.prevent="showGuide = false"
        >
          {{ "Hide Scoring Guide" | translate }}
        </a>
      </div>
      <div class="col-xl-12 hygiene-score-images">
        <div class="row d-flex">
          <div
            class="col-6 col-md-12 d-flex justify-content-center flex-column"
          >
            <!-- mobile -->
            <transition name="fade">
              <!-- :image="1" -->
              <!-- video="wxoiyacx0w" -->
              <modal-locomotion
                v-if="showGuide === true"
                :video="getScoringVideo('one')"
                :image="1"
                :type="isOnline ? assessor.data.locomotion_type : false"
                class="d-md-none"
              ></modal-locomotion>
            </transition>
            <transition name="fade">
              <p
                class="p text-center font-weight-bold mb-4 d-md-none"
                v-if="showGuide === true"
              >
                {{ "Score 1" | translate }}
              </p>
            </transition>
            <!-- desktop -->
            <!-- :image="1" -->
            <modal-locomotion
              :image="1"
              :video="getScoringVideo('one')"
              :type="isOnline ? assessor.data.locomotion_type : false"
              :size="'lg'"
              class="d-none d-md-flex justify-content-center"
            ></modal-locomotion>
            <p class="p text-center font-weight-bold mb-4 d-none d-md-block">
              {{ "Score 1" | translate }}
            </p>
          </div>
          <div
            class="col-6 col-md-12 d-flex justify-content-center flex-column"
          >
            <!-- mobile -->
            <transition name="fade">
              <modal-locomotion
                v-if="showGuide === true"
                :video="getScoringVideo('two')"
                :type="isOnline ? assessor.data.locomotion_type : false"
                class="d-md-none"
                :image="2"
              ></modal-locomotion>
            </transition>
            <transition name="fade">
              <p
                class="p text-center font-weight-bold mb-4 d-md-none"
                v-if="showGuide === true"
              >
                <span>{{ "Score 2" | translate }}</span>
              </p>
            </transition>
            <!-- dektop -->
            <!-- video="552syk3n5q" -->
            <modal-locomotion
              :video="getScoringVideo('two')"
              :type="isOnline ? assessor.data.locomotion_type : false"
              :size="'lg'"
              class="d-none d-md-flex justify-content-center"
              :image="2"
            ></modal-locomotion>
            <p class="p text-center font-weight-bold mb-4 d-none d-md-block">
              <span>{{ "Score 2" | translate }}</span>
            </p>
          </div>
          <div
            class="col-6 col-md-12 d-flex justify-content-center flex-column"
          >
            <!-- mobile -->
            <!-- video="g9hf5fjosb" -->
            <transition name="fade">
              <modal-locomotion
                :video="getScoringVideo('three')"
                :type="isOnline ? assessor.data.locomotion_type : false"
                class="d-md-none"
                v-if="showGuide === true"
                :image="3"
              ></modal-locomotion>
            </transition>
            <transition name="fade">
              <p
                class="p text-center font-weight-bold mb-4 d-md-none"
                v-if="showGuide === true"
              >
                <!-- {{ 'Score 3' | translate }} -->
              </p>
            </transition>
            <!-- dektop -->
            <!-- video="g9hf5fjosb" -->
            <modal-locomotion
              :video="getScoringVideo('three')"
              :type="isOnline ? assessor.data.locomotion_type : false"
              :size="'lg'"
              class="d-none d-md-flex justify-content-center"
              :image="3"
            ></modal-locomotion>
            <p class="p text-center font-weight-bold mb-4 d-none d-md-block">
              {{ "Score 3" | translate }}
            </p>
          </div>
          <div
            class="col-6 col-md-12 d-flex justify-content-center flex-column"
          >
            <!-- mobile -->
            <!-- video="r1knvthcuj" -->
            <transition name="fade">
              <modal-locomotion
                :video="getScoringVideo('four')"
                :type="isOnline ? assessor.data.locomotion_type : false"
                class="d-md-none"
                v-if="showGuide === true"
                :image="4"
              ></modal-locomotion>
            </transition>
            <transition name="fade">
              <p
                class="p text-center font-weight-bold mb-4 d-md-none"
                v-if="showGuide === true"
              >
                <span>{{ "Score 4" | translate }}</span>
                <!-- <span v-else>{{ 'Score 4/5' | translate }}</span> -->
              </p>
            </transition>
            <!-- dektop -->
            <!-- video="r1knvthcuj" -->
            <modal-locomotion
              :video="getScoringVideo('four')"
              :type="isOnline ? assessor.data.locomotion_type : false"
              :size="'lg'"
              class="d-none d-md-flex justify-content-center"
              :image="4"
            ></modal-locomotion>
            <p class="p text-center font-weight-bold mb-4 d-none d-md-block">
              <span>{{ "Score 4" | translate }}</span>
              <!-- <span v-else>{{ 'Score 4/5' | translate }}</span> -->
            </p>
          </div>
          <div
            v-if="assessor.data.locomotion_type === 1"
            class="col-6 col-md-12 d-flex justify-content-center flex-column"
          >
            <!-- mobile -->
            <!-- video="tc9bap9h91" -->
            <transition name="fade">
              <modal-locomotion
                :video="getScoringVideo('five')"
                :type="isOnline ? assessor.data.locomotion_type : false"
                class="d-md-none"
                v-if="showGuide === true"
                :image="5"
              ></modal-locomotion>
            </transition>
            <transition name="fade">
              <p
                class="p text-center font-weight-bold mb-4 d-md-none"
                v-if="showGuide === true"
              >
                {{ "Score 5" | translate }}
              </p>
            </transition>
            <!-- dektop -->
            <!-- video="tc9bap9h91" -->
            <modal-locomotion
              :video="getScoringVideo('five')"
              :type="isOnline ? assessor.data.locomotion_type : false"
              :size="'lg'"
              class="d-none d-md-flex justify-content-center"
              :image="5"
            ></modal-locomotion>
            <p class="p text-center font-weight-bold mb-4 d-none d-md-block">
              {{ "Score 5" | translate }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="col-6 col-lg-4 mt-1 col-xl-3 locomotion-score d-flex flex-column align-items-md-end"
      >
        <p class="p d-flex mb-1">
          {{ "Target" | translate }}
          <span class="d-none d-md-inline">%</span>
        </p>
        <input
          type="number"
          inputmode="numeric"
          @keypress="$isNumber"
          class="form-control form-control--sm mb-0"
          v-model.number="assessor.data.locomotion_target_1"
        />
        <input
          type="number"
          inputmode="numeric"
          @keypress="$isNumber"
          class="form-control form-control--sm"
          v-model.number="assessor.data.locomotion_target_2"
        />
        <input
          type="number"
          inputmode="numeric"
          @keypress="$isNumber"
          class="form-control form-control--sm"
          v-model.number="assessor.data.locomotion_target_3"
        />
        <input
          v-if="assessor.data.locomotion_type === 1"
          type="number"
          inputmode="numeric"
          @keypress="$isNumber"
          class="form-control form-control--sm"
          v-model.number="assessor.data.locomotion_target_4"
        />
        <input
          v-if="assessor.data.locomotion_type === 1"
          type="number"
          inputmode="numeric"
          @keypress="$isNumber"
          class="form-control form-control--sm"
          v-model.number="assessor.data.locomotion_target_5"
        />
        <input
          v-else
          type="number"
          inputmode="numeric"
          @keypress="$isNumber"
          class="form-control form-control--sm"
          v-model.number="assessor.data.locomotion_target_45"
        />

        <input
          v-if="
            assessor.data.locomotion_type === 0 &&
            total_locomotion_target_4 !== 100
          "
          type="number"
          inputmode="numeric"
          @keypress="$isNumber"
          class="form-control form-control--sm form-control--sm--total is-invalid mb-0"
          v-model.number="total_locomotion_target_4"
          readonly
        />
        <input
          v-else-if="
            assessor.data.locomotion_type === 0 &&
            total_locomotion_target_4 === 100
          "
          type="number"
          inputmode="numeric"
          @keypress="$isNumber"
          class="form-control form-control--sm form-control--sm--total mb-0"
          v-model.number="total_locomotion_target_4"
          readonly
        />
        <input
          v-else-if="
            assessor.data.locomotion_type === 1 &&
            total_locomotion_target_5 !== 100
          "
          type="number"
          inputmode="numeric"
          @keypress="$isNumber"
          class="form-control form-control--sm form-control--sm--total is-invalid mb-0"
          v-model.number="total_locomotion_target_5"
          readonly
        />
        <input
          v-else
          type="number"
          inputmode="numeric"
          class="form-control form-control--sm form-control--sm--total mb-0"
          @keypress="$isNumber"
          v-model.number="total_locomotion_target_5"
          readonly
        />
      </div>

      <div class="col-18 col-lg-20 mt-1 col-xl-9 locomotion-score">
        <div
          class="counter counter--locomotion d-flex flex-column justify-content-xl-center align-items-xs-end"
        >
          <div class="loco-score d-flex justify-content-center">
            <p class="p mb-1">{{ "Locomotion Scores" | translate }}</p>
          </div>
          <div class="d-flex flex-row justify-content-xl-center">
            <button
              class="btn btn-clicker btn-clicker--left"
              :disabled="assessor.data.locomotion_score_1 <= 0"
              @click="assessor.data.locomotion_score_1--"
            >
              <i class="fa fa-minus" aria-hidden="true"></i>
            </button>
            <div class="score-position">
              <span class="score-position__score">1</span>
            </div>
            <div>
              <input
                type="number"
                inputmode="numeric"
                @keypress="$isNumber"
                @blur="checkIfEmpty('locomotion_score_1')"
                class="score-count score-count--sm"
                v-model.number="assessor.data.locomotion_score_1"
              />
            </div>
            <button
              class="btn btn-clicker btn-clicker--right"
              @click="assessor.data.locomotion_score_1++"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <div class="counter d-flex justify-content-xl-center">
          <button
            class="btn btn-clicker btn-clicker--left"
            @click="assessor.data.locomotion_score_2--"
            :disabled="assessor.data.locomotion_score_2 <= 0"
          >
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
          <div class="score-position">
            <span class="score-position__score">2</span>
          </div>
          <input
            type="number"
            inputmode="numeric"
            @keypress="$isNumber"
            @blur="checkIfEmpty('locomotion_score_2')"
            class="score-count score-count--sm"
            v-model.number="assessor.data.locomotion_score_2"
          />
          <button
            class="btn btn-clicker btn-clicker--right"
            @click="assessor.data.locomotion_score_2++"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>

        <div class="counter d-flex justify-content-xl-center">
          <button
            class="btn btn-clicker btn-clicker--left"
            @click="assessor.data.locomotion_score_3--"
            :disabled="assessor.data.locomotion_score_3 <= 0"
          >
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
          <div class="score-position">
            <span class="score-position__score">3</span>
          </div>
          <input
            type="number"
            inputmode="numeric"
            @keypress="$isNumber"
            @blur="checkIfEmpty('locomotion_score_3')"
            class="score-count score-count--sm"
            v-model.number="assessor.data.locomotion_score_3"
          />
          <button
            class="btn btn-clicker btn-clicker--right"
            @click="assessor.data.locomotion_score_3++"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>

        <div
          v-if="assessor.data.locomotion_type === 1"
          class="counter d-flex justify-content-xl-center"
        >
          <button
            class="btn btn-clicker btn-clicker--left"
            @click="assessor.data.locomotion_score_4--"
            :disabled="assessor.data.locomotion_score_4 <= 0"
          >
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
          <div class="score-position">
            <span class="score-position__score">4</span>
          </div>
          <input
            type="number"
            inputmode="numeric"
            @keypress="$isNumber"
            @blur="checkIfEmpty('locomotion_score_4')"
            class="score-count score-count--sm"
            v-model.number="assessor.data.locomotion_score_4"
          />
          <button
            class="btn btn-clicker btn-clicker--right"
            @click="assessor.data.locomotion_score_4++"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>

        <div
          v-if="assessor.data.locomotion_type === 1"
          class="counter d-flex justify-content-xl-center"
        >
          <button
            class="btn btn-clicker btn-clicker--left"
            @click="assessor.data.locomotion_score_5--"
            :disabled="assessor.data.locomotion_score_5 <= 0"
          >
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
          <div class="score-position">
            <span class="score-position__score">5</span>
          </div>
          <input
            type="number"
            inputmode="numeric"
            @keypress="$isNumber"
            @blur="checkIfEmpty('locomotion_score_5')"
            class="score-count score-count--sm"
            v-model.number="assessor.data.locomotion_score_5"
          />
          <button
            class="btn btn-clicker btn-clicker--right"
            @click="assessor.data.locomotion_score_5++"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>

        <div v-else class="counter d-flex justify-content-xl-center">
          <button
            class="btn btn-clicker btn-clicker--left"
            @click="assessor.data.locomotion_score_45--"
            :disabled="assessor.data.locomotion_score_45 <= 0"
          >
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
          <div class="score-position">
            <span class="score-position__score--small">4</span>
          </div>
          <input
            type="number"
            inputmode="numeric"
            @keypress="$isNumber"
            @blur="checkIfEmpty('locomotion_score_45')"
            class="score-count score-count--sm"
            v-model.number="assessor.data.locomotion_score_45"
          />
          <button
            class="btn btn-clicker btn-clicker--right"
            @click="assessor.data.locomotion_score_45++"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>

        <div class="d-flex flex-row justify-content-xl-center">
          <div class="counter-total counter-total--locomotion pt-0">
            <span class="total-title mr-3">{{ "Total" | translate }}</span>
            <input
              v-if="assessor.data.locomotion_type === 0"
              type="number"
              inputmode="numeric"
              @keypress="$isNumber"
              class="total-hygiene-score total-hygiene-score--locomotion"
              v-model="total_locomotion_score_4"
              readonly
            />
            <input
              v-else
              type="number"
              inputmode="numeric"
              class="total-hygiene-score total-hygiene-score--locomotion"
              v-model="total_locomotion_score_5"
              readonly
            />
          </div>
        </div>

        <div
          v-if="
            (assessor.data.locomotion_type === 1 &&
              total_locomotion_target_5 !== 100) ||
            (assessor.data.locomotion_type === 0 &&
              total_locomotion_target_4 !== 100)
          "
          class="alert alert-danger"
        >
          <p class="mb-0">
            {{ "The target percentages need to add up to 100!" | translate }}
          </p>
        </div>
      </div>
    </div>

    <hr />

    <div class="row my-0">
      <div class="col-md-12">
        <personal-notes v-model="assessor.data.personal_notes"></personal-notes>
      </div>
      <div class="col-md-12">
        <presentation-notes
          v-model="assessor.data.presentation_notes"
        ></presentation-notes>
      </div>
    </div>

    <div
      class="row mx-0 justify-content-center justify-content-md-start center align-items-center pb-0 mt-4"
    >
      <photoUpload :assessorId="3" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

    <div class="row misc-bottom">
      <div class="col-md-12">
        <button
          data-target="#areYouSure"
          data-toggle="modal"
          class="btn btn-secondary mb-2 mb-md-0"
        >
          {{ "Default Values" | translate }}
        </button>
        <div
          class="modal fade"
          id="areYouSure"
          tabindex="-1"
          role="dialog"
          aria-labelledby="areYouSure"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content edit-modal">
              <h2 class="h2 h2--modal text-center mt-3 mt-md-0">
                {{ "Return to Default Values" | translate }}
              </h2>
              <div class="px-3 pb-3">
                <p>
                  {{
                    "Are you sure that you would like to return values to default? Once completed, the data cannot be restored."
                      | translate
                  }}
                </p>
                <p>
                  <a
                    @click="resetDefaults"
                    href="#"
                    data-dismiss="modal"
                    class="btn btn-full btn-full--modal mb-2 mb-md-0"
                    >{{ "Return to Defaults" | translate }}</a
                  >
                </p>
                <div class="row mt-2">
                  <div class="col-md-24 modal-bottom">
                    <a href="#" class="btn-cancel-modal" data-dismiss="modal">{{
                      "Cancel" | translate
                    }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <router-link
          v-if="user && user.role !== 'data_entry'"
          :to="'/reports'"
          class="btn btn-primary btn-full--sm pull-right"
          >{{ "Reports" | translate }}</router-link
        >
        <button
          v-else-if="
            user && user.role === 'data_entry' && !$store.state.offline
          "
          data-toggle="modal"
          @click="requestReport(evaluation.name)"
          :data-target="['#requestSent-' + user.id]"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ "Request Report" | translate }}
          <span v-show="requestReportLoader" class="loader ml-1"></span>
        </button>
      </div>
    </div>

    <div
      v-show="successMessage"
      class="alert alert-primary mt-3 mb-0"
      role="alert"
    >
      {{ "Your request has been sent!" | translate }}
    </div>

    <div
      v-show="errorMessage"
      class="alert alert-danger mt-3 mb-0"
      role="alert"
    >
      {{ "An error has occurred during your request!" | translate }}
    </div>
  </div>
  <!-- end container -->
</template>

<script>
import _ from "lodash";
import PresentationNotes from "@/pages/assessors/assessor_components/presentation-notes";
import PersonalNotes from "@/pages/assessors/assessor_components/personal-notes";
import Question from "@/pages/assessors/assessor_components/question";
import ModalInfo from "@/components/misc/modal-info";
import ModalLocomotion from "@/components/misc/modal-locomotion.vue"; // will need to create a new component for this when relevant images are received
import PhotoUpload from "@/components/misc/photoUpload";
import requestReportMixin from "@/mixins/requestReport.js";

import Evaluation from "@/libs/Classes/Evaluation.js";
import Group from "@/libs/Classes/Group.js";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    ModalInfo,
    ModalLocomotion,
    PhotoUpload,
    Question,
    PersonalNotes,
    PresentationNotes,
  },
  mixins: [requestReportMixin],
  props: ["assessor", "group", "evaluation"],
  data() {
    return {
      showGuide: false,
      assessorClass: null,
      groupClass: null,
      evaluationClass: null,
      scoring: {
        en: {
          pointScoring4: {
            one: "u9tifcjvhb",
            two: "ugjxsw0940",
            three: "iqdyv2ra8l",
            four: "gkkg9grtiq",
          },
          pointScoring5: {
            one: "14k50qppuc",
            two: "r0ke50eekr",
            three: "vcyen6lehq",
            four: "lcq6sp4iba",
            five: "bgrb97trtv",
          },
        },

        es: {
          pointScoring4: {
            one: "4aetynxpy5",
            two: "h707qdozsx",
            three: "l3uqvp1570",
            four: "kau791wzar",
          },
          pointScoring5: {
            one: "p107h3q5id",
            two: "37wczo1bnj",
            three: "sqolu0ix3d",
            four: "xo5ddtba32",
            five: "pw347dlysi",
          },
        },
        ch: {
          pointScoring4: {
            one: "p4g1anflc0",
            two: "nmh2v04vij",
            three: "ipipa7tgxl",
            four: "m1bp44lgkn",
          },
          pointScoring5: {
            one: "qn3i3isah8",
            two: "scs9mk9g7q",
            three: "uqyoadlwe5",
            four: "n43pvwbxk1",
            five: "tnn1fyhe3e",
          },
        },
        fr: {
        pointScoring4: {
          one: "0m69cmblnr",
          two: "h20ljgob0g",
          three: "l63fx55h55",
          four: "lftvofj3o4",
          },
        pointScoring5: {
          one: "7fdt45w6hg",
          two: "dk7nfediwh",
          three: "1uoyfg4x1t",
          four: "mz7lqonuv4",
          five: "pxbwz4q2go",
          },
        },
      },
    };
  },
  methods: {
    checkIfEmpty(scoreKey) {
    if (this.assessor.data[scoreKey] === '' || this.assessor.data[scoreKey] === null) {
      this.assessor.data[scoreKey] = 0;
    }
  },
    /* this function is handing the show scoring videos, 
    base on the current selected user language, if user selected chinese lang
    and we don't have video for that specific lang, we by default we showed videos in english  
    */
    getScoringVideo(scoringType) {
      const scoring = this.scoring[this.lang] || this.scoring.en;

      return this.is5PointScoring
        ? scoring.pointScoring5[scoringType]
        : scoring.pointScoring4[scoringType];
    },

    getDate(timestamp) {
      let date = new Date(timestamp);
      var monthNames = [
        this.$t("Jan."),
        this.$t("Feb."),
        this.$t("Mar."),
        this.$t("Apr."),
        this.$t("May"),
        this.$t("Jun."),
        this.$t("Jul."),
        this.$t("Aug."),
        this.$t("Sep."),
        this.$t("Oct."),
        this.$t("Nov."),
        this.$t("Dec."),
      ];
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
      return day + " " + monthNames[monthIndex] + " " + year;
    },
    // requestReport(evaluationName) {
    //   this.$store.dispatch('requestReport', {
    //     currentVueInstance: this,
    //     evaluationName: evaluationName,
    //   });
    // },
    resetDefaults() {
      // arbitrary defaults. needs to be changed
      this.assessor.data.locomotion_score_1 = 0;
      this.assessor.data.locomotion_score_2 = 0;
      this.assessor.data.locomotion_score_3 = 0;
      this.assessor.data.locomotion_score_4 = 0;
      this.assessor.data.locomotion_score_5 = 0;
      this.assessor.data.locomotion_score_45 = 0;
      this.assessor.data.locomotion_target_1 = 40;
      this.assessor.data.locomotion_target_2 = 40;
      this.assessor.data.locomotion_target_3 = 15;
      this.assessor.data.locomotion_target_4 = 5;
      this.assessor.data.locomotion_target_5 = 0;
      this.assessor.data.locomotion_target_45 = 5;
    },
  },
  computed: {
    ...mapGetters(["isOnline"]),
    ...mapState({
      user: (state) => state.users.user,
      lang: (state) => state.i18n.locale,
    }),
    total_locomotion_score_4: function () {
      return (
        this.assessor.data.locomotion_score_1 +
        this.assessor.data.locomotion_score_2 +
        this.assessor.data.locomotion_score_3 +
        this.assessor.data.locomotion_score_45
      );
    },
    total_locomotion_score_5: function () {
      return (
        this.assessor.data.locomotion_score_1 +
        this.assessor.data.locomotion_score_2 +
        this.assessor.data.locomotion_score_3 +
        this.assessor.data.locomotion_score_4 +
        this.assessor.data.locomotion_score_5
      );
    },
    total_locomotion_target_4: function () {
      return (
        this.assessor.data.locomotion_target_1 +
        this.assessor.data.locomotion_target_2 +
        this.assessor.data.locomotion_target_3 +
        this.assessor.data.locomotion_target_45
      );
    },
    total_locomotion_target_5: function () {
      return (
        this.assessor.data.locomotion_target_1 +
        this.assessor.data.locomotion_target_2 +
        this.assessor.data.locomotion_target_3 +
        this.assessor.data.locomotion_target_4 +
        this.assessor.data.locomotion_target_5
      );
    },

    is5PointScoring() {
      return this.assessor.data.locomotion_type === 1;
    },
  },
  created() {
    this.evaluationClass = new Evaluation(this, this.evaluation);
    this.groupClass = new Group(this, this.evaluationClass, this.group);
    this.assessorClass = this.groupClass.getAssessorByType("3");

    if (this.assessorClass.getScore("45") === undefined) {
      this.assessorClass.setScore("45", 0);
    }

    if (this.assessorClass.getTarget("45") === undefined) {
      this.assessorClass.setTarget("45", 5);
    }

    // this.user = this.$store.getters.getData("user");
  },
};
</script>
