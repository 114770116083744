import {
    nutritionAndFeeding
} from './nutritionAndFeeding';
import {
    medias
} from './../Global/medias.js';

class NutritionAndFeedingAssessorSlides {

    constructor(app, report, evaluation, group, user) {
        this.app = app;
        this.report = report;
        this.evaluation = evaluation;
        this.group = group;
        this.user = user;
    }

    add(tableOfContentsCalculatorPosition) {

        let assessor = this.group.getAssessorByType("14");

        if (assessor === false || assessor.assessor.data === undefined) {
            throw this.group.getName() + this.app.$t(" - ") + this.app.$t("Nutrition and Feeding") + this.app.$t(" assessor is not ready.");
        }

        this.report.tableOfContentsHelper("Nutrition and Feeding", tableOfContentsCalculatorPosition, this.group);

        nutritionAndFeeding(this.app, this.report, this.evaluation, this.group, assessor);
        medias(this.app, this.report, this.group, "14", this.user);
    }

}

export default NutritionAndFeedingAssessorSlides;