<template>
  <button
    class="file-link border-0"
    @click="() => $emit('open-file', file)"
    target="_blank"
    download="file"
  >
    Open
    <svg version="1.1" viewBox="0 0 100 125" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <path
          d="m43.505 50.11 6.6231 6.6231 35.71-35.71-0.078835 20.871 9.347-0.034022 0.13247-36.861-36.861 0.13247-0.034261 9.3468 20.871-0.078835-35.71 35.71zm6.3927-34.151-2.526e-4 11.291-33.695 6.243e-4v56.458h56.159l-8.79e-4 -33.876 11.233 8.728e-4v45.166h-78.623v-79.041h44.927z"
          fill="#000"
        />
      </g>
    </svg>
  </button>
</template>

<script>
import formUploadMixin from '@/mixin/formUpload.js';
import { get } from 'lodash';
export default {
  name: 'DownloadFormButton',
  mixins: [formUploadMixin],
  props: {
    file: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    fileData() {
      return {
        url: get(this.file, 'metadata.customMetadata.fileURL', ''),
        fileName: this.file.id,
      };
    },
  },
};
</script>

<style></style>
