export default {
  methods: {
    getRepresentativeNameAndId() {
      let representativeId;
      let representativeName;

      if (this.customer.representative) {
        representativeId = this.customer.representative;
        const { firstName, lastName } =
          this.zinproReps.find(zinproRep => zinproRep._id === representativeId) || {};
        representativeName = firstName + ' ' + lastName;
      } else {
        representativeId = this.user._id;
        representativeName = this.user.firstName + ' ' + this.user.lastName;
      }

      return { representativeName, representativeId };
    },
  },
};
