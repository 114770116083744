<template>
 <div>
    <div class="container main-container main-container--hygiene view">
      <div class="row">
        <div class="col-xl-18 col-lg-16 col-md-14 d-flex align-items-center">
          <div>
            <h2 class="h2 d-inline">
              {{
                'Tell Us About Your Tiestall / Stanchion Heat Abatement Procedures'
                  | translate
              }}
            </h2>
            <modal-info :value="'tsha'">
              <template slot="modal-content">
                <h2 class="h2 text-center pb-2 pt-3 px-3">
                  {{ 'Tiestall/Stanchion Heat Abatement' | translate }}
                </h2>
                <p class="px-3">
                  {{
                    'For more information about Tiestall/Stanchion Heat Abatement, click the link to learn more from'
                      | translate
                  }}
                  <a
                    :href="$t('https://library.zinprofirststep.com/tie-stall/')"
                    @click="$goToLameness($t('/tie-stall/'), $event)"
                    target="_blank"
                    >{{ 'FirstStep Resources' | translate }}</a
                  >.
                </p>
                <div class="row modal-bottom my-3">
                  <a
                    href="#"
                    class="btn-cancel-modal d-flex justify-content-center"
                    data-dismiss="modal"
                    >{{ 'Cancel' | translate }}</a
                  >
                </div>
              </template>
            </modal-info>
          </div>
        </div>
      </div>

      <hr class="mt-2 mb-4 mt-md-3" />

      <div class="row">
        <div class="col-24">
          <div class="form-group">
            <label class="mb-1 d-block">{{
              $t('Summer Ventilation System')
            }}</label>
            <div class="form-check form-check-inline mr-4">
              <input
                v-model="assessor.data.summer_ventilation_system"
                type="radio"
                id="cross"
                name="cross"
                value="cross"
                class="form-check-input mr-2"
              />
              <label class="form-check-label mt-1" for="cross">
                {{ $t('Cross Ventilation') }}
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                v-model="assessor.data.summer_ventilation_system"
                type="radio"
                id="tunnel"
                name="tunnel"
                value="tunnel"
                class="form-check-input mr-2"
              />
              <label class="form-check-label mt-1" for="tunnel">
                {{ $t('Tunnel Ventilation') }}
              </label>
            </div>
          </div>
          <hr />
        </div>

        <div class="col-24">
          <question
            @change="
              assessor.data.are_cows_allowed_out_of_the_barn_at_night_to_cool_off =
                $event.value
            "
            :initial-value="
              assessor.data
                .are_cows_allowed_out_of_the_barn_at_night_to_cool_off
            "
          >
            <div class="mb-1 mb-md-0">
              <label class="label d-inline">
                {{
                  $t('Are cows allowed out of the barn at night to cool off?')
                }}
              </label>
            </div>
          </question>
          <hr />
        </div>

        <div class="col-24">
          <question
            @change="
              assessor.data.are_soakers_misters_used_to_help_cool_cows_off =
                $event.value
            "
            :initial-value="
              assessor.data.are_soakers_misters_used_to_help_cool_cows_off
            "
          >
            <div class="mb-1 mb-md-0">
              <label class="label d-inline">
                {{ $t('Are soakers/misters used to help cool cows off?') }}
              </label>
            </div>
          </question>
          <hr />
        </div>

        <div class="col-24">
          <question-three
            @change="
              assessor.data.is_there_shade_over_outside_feed_area = $event.value
            "
            :initial-value="assessor.data.is_there_shade_over_outside_feed_area"
          >
            <label class="label d-inline-block">
              {{ $t('Is there shade over outside feed area?') }}
            </label>
          </question-three>
          <hr />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-7 measurements mb-4 mb-md-0">
          <volume-tie-stall-stanchion-heat-abatement
            v-model="assessor"
          ></volume-tie-stall-stanchion-heat-abatement>
        </div>
        <div class="col-lg-17">
          <!-- <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-1">
                  {{
                    $t('Fan Width') +
                      ' (' +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                      ')'
                  }}
                  <modal-info :value="'base-layer'" class="d-inline">
                    <template slot="modal-content">
                      <h2 class="h2 pb-2 pt-3 px-3 text-center">
                        {{ 'Number of Fans' | translate }}
                      </h2>
                      <p class="px-3">
                        {{
                          'Enter up to three fan sizes and quantities below'
                            | translate
                        }}
                      </p>
                      <div class="row modal-bottom pb-3">
                        <a
                          href="#"
                          class="btn-cancel-modal d-flex justify-content-center"
                          data-dismiss="modal"
                          >{{ 'Cancel' | translate }}</a
                        >
                      </div>
                    </template>
                  </modal-info>
                </label>
                <input
                  v-model.number="assessor.data.fan_width_1"
                  type="number"
                  inputmode="numeric"
                  class="form-control"
                  @keypress="$isNumber($event)"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-1">{{ $t('Number of Fans') }}</label>
                <input
                  type="number"
                  inputmode="numeric"
                  @keypress="$isNumber"
                  class="form-control"
                  v-model.number="assessor.data.number_of_fans_1"
                />
              </div>
            </div>
          </div> -->

          <Fans
          :show-modal="true"
          :evaluation="evaluation"
          :assessor="assessor"
          :fan-width.sync="converted_fan_width_1"
          no-of-fan="number_of_fans_1"
          />

          <hr />

          <!-- <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-1">
                  {{
                    $t('Fan Width') +
                      ' (' +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                      ')'
                  }}
                  <modal-info :value="'base-layer'" class="d-inline">
                    <template slot="modal-content">
                      <h2 class="h2 pb-2 pt-3 px-3 text-center">
                        {{ 'Number of Fans' | translate }}
                      </h2>
                      <p class="px-3">
                        {{
                          'Enter up to three fan sizes and quantities below'
                            | translate
                        }}
                      </p>
                      <div class="row modal-bottom pb-3">
                        <a
                          href="#"
                          class="btn-cancel-modal d-flex justify-content-center"
                          data-dismiss="modal"
                          >{{ 'Cancel' | translate }}</a
                        >
                      </div>
                    </template>
                  </modal-info>
                </label>
                <input
                  v-model.number="assessor.data.fan_width_2"
                  type="number"
                  inputmode="numeric"
                  class="form-control"
                  @keypress="$isNumber($event)"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-1">{{ $t('Number of Fans') }}</label>
                <input
                  type="number"
                  inputmode="numeric"
                  @keypress="$isNumber"
                  class="form-control"
                  v-model.number="assessor.data.number_of_fans_2"
                />
              </div>
            </div>
          </div> -->

          <Fans
          :show-modal="true"
          :evaluation="evaluation"
          :assessor="assessor"
          :fan-width.sync="converted_fan_width_2"
           no-of-fan="number_of_fans_2"
          />
          <hr />
<!-- 
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-1">
                  {{
                    $t('Fan Width') +
                      ' (' +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'cm')) +
                      ')'
                  }}
                  <modal-info :value="'base-layer'" class="d-inline">
                    <template slot="modal-content">
                      <h2 class="h2 pb-2 pt-3 px-3 text-center">
                        {{ 'Number of Fans' | translate }}
                      </h2>
                      <p class="px-3">
                        {{
                          'Enter up to three fan sizes and quantities below'
                            | translate
                        }}
                      </p>
                      <div class="row modal-bottom pb-3">
                        <a
                          href="#"
                          class="btn-cancel-modal d-flex justify-content-center"
                          data-dismiss="modal"
                          >{{ 'Cancel' | translate }}</a
                        >
                      </div>
                    </template>
                  </modal-info>
                </label>
                <input
                  v-model.number="assessor.data.fan_width_3"
                  type="number"
                  inputmode="numeric"
                  class="form-control"
                  @keypress="$isNumber($event)"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-1">{{ $t('Number of Fans') }}</label>
                <input
                  type="number"
                  inputmode="numeric"
                  @keypress="$isNumber"
                  class="form-control"
                  v-model.number="assessor.data.number_of_fans_3"
                />
              </div>
            </div>
          </div> -->

          <Fans
          :show-modal="true"
          :evaluation="evaluation"
          :assessor="assessor"
          :fan-width.sync="converted_fan_width_3"
          no-of-fan="number_of_fans_3"
          />
          <hr />

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-1">{{
                  $t('Total Air Movement Capacity') +
                    ' (' +
                    $t($getEquivalentUnit(evaluation.data.measurements, 'cmh')) +
                    ')'
                }}</label>
                <input
                  type="number"
                  inputmode="numeric"
                  @keypress="$isNumber"
                  class="form-control"
                  :value="computed_air_movement_capacity"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
        <!-- end col-lg-17 -->
      </div>
      <!-- end row -->

      <hr class="mt-md-5" />

      <div class="row my-0">
        <div class="col-md-12">
          <personal-notes
            v-model="assessor.data.personal_notes"
          ></personal-notes>
        </div>
        <div class="col-md-12">
          <presentation-notes
            v-model="assessor.data.presentation_notes"
          ></presentation-notes>
        </div>
      </div>

      <div
        class="row mx-0 mt-4 justify-content-center justify-content-md-start center align-items-center"
      >
        <photoUpload :assessorId="13" :assessor="assessor"></photoUpload>
      </div>

      <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

      <div class="row misc-bottom">
        <div class="col-md-24">
          <router-link
            v-if="user && user.role !== 'data_entry'"
            :to="'/reports'"
            class="btn btn-primary btn-full--sm pull-right"
            >{{ 'Reports' | translate }}
          </router-link>
          <button
            v-else-if="
              user && user.role === 'data_entry' && !$store.state.offline
            "
            data-toggle="modal"
            @click="requestReport(evaluation.name)"
            :data-target="['#requestSent-' + user.id]"
            class="btn btn-primary btn-full--sm pull-right"
          >
            {{ 'Request Report' | translate }}
            <span v-show="requestReportLoader" class="loader ml-1"></span>
          </button>
        </div>
      </div>

      <div
        v-show="successMessage"
        class="alert alert-primary mt-3 mb-0"
        role="alert"
      >
        {{ 'Your request has been sent!' | translate }}
      </div>

      <div
        v-show="errorMessage"
        class="alert alert-danger mt-3 mb-0"
        role="alert"
      >
        {{ 'An error has occurred during your request!' | translate }}
      </div>
    </div>
  </div>
</template>

<script>
import PersonalNotes from './assessor_components/personal-notes';
import PresentationNotes from './assessor_components/presentation-notes';
import VolumeTieStallStanchionHeatAbatement from './assessor_components/volume-tsha';
import Question from './assessor_components/question';
import QuestionThree from './assessor_components/question-three';
import ModalInfo from '@/components/misc/modal-info.vue';
import TieStallStanchionHeatAbatement from '@/libs/Classes/TieStallStanchionHeatAbatement.js';
import convert from 'convert-units';
import HygieneHeader from './assessor_components/hygiene-header.vue';
import PhotoUpload from '@/components/misc/photoUpload';
import Group from '@/libs/Classes/Group.js';
import Evaluation from '@/libs/Classes/Evaluation.js';
import requestReportMixin from '@/mixins/requestReport.js';
import Fans from '@/components/misc/Fans.vue';

export default {
  components: {
    PersonalNotes,
    PresentationNotes,
    VolumeTieStallStanchionHeatAbatement,
    ModalInfo,
    Question,
    QuestionThree,
    PhotoUpload,
    HygieneHeader,
    Fans
  },
  props: ['assessor', 'group', 'evaluation'],
  mixins: [requestReportMixin],
  data() {
    return {
      tiestallstanchionheatabatement: null,
    };
  },
  computed: {
    computed_air_movement_capacity() {
      return this.tiestallstanchionheatabatement.calculateTotalAirMovementCapacity();
    },
    converted_fan_width_1:{
      get(){
       return this.tiestallstanchionheatabatement.getFanWidthOne()
      },
      set(newVal){
        return this.tiestallstanchionheatabatement.setFanWidthOne(newVal)
      }
    },
    converted_fan_width_2:{
      get(){
       return this.tiestallstanchionheatabatement.getFanWidthTwo()
      },
      set(newVal){
        return this.tiestallstanchionheatabatement.setFanWidthTwo(newVal)
      }
    },
    converted_fan_width_3:{
      get(){
       return this.tiestallstanchionheatabatement.getFanWidthThree()
      },
      set(newVal){
        return this.tiestallstanchionheatabatement.setFanWidthThree(newVal)
      }
    },
    fanWidths() {
      const widths_in_cm = [
        35.56,
        40.64,
        45.72,
        60.96,
        91.44,
        121.92,
        127,
        129.54,
        137.16,
      ];
      let converted_widths = [];

      for (let i = 0; i < widths_in_cm.length; i++) {
        let converted_value = parseInt(
          convert(widths_in_cm[i])
            .from('cm')
            .to(
              this.$getEquivalentUnit(this.evaluation.data.measurements, 'cm')
            )
            .toFixed(0)
        );

        converted_widths.push({
          value: widths_in_cm[i],
          label: converted_value,
        });
      }

      return converted_widths;
    },
  },
  created() {
    let evaluationClass = new Evaluation(this, this.evaluation);
    let groupClass = new Group(this, evaluationClass, this.group);
    // this.user = this.$store.getters.getData("user");
    this.tiestallstanchionheatabatement = new TieStallStanchionHeatAbatement(
      this,
      evaluationClass,
      groupClass,
      this.assessor
    );
  },
};
</script>
