export const create = function (self, slide, obj, options) {
    var presets = {
        chartColors: ['0000FF', 'A1A3A6', '000000', '98C793'],
        invertedColors: ['CB0019'],
        catAxisLineShow: false,
        catAxisLabelFontSize: 10,
        valAxisLineShow: false,
        valAxisHidden: true,
        dataLabelColor: 'ffffff',
        grindLineColor: 'ffffff',
        axisLineColor: 'ffffff',
        barGapWidthPct: 75,
        showLabel: true,
        showValue: true,
        showLegend: true,
        legendPos: 'b',
        legendFontSize: 9
    };

    var mergedObject = {
        ...presets, // jshint ignore:line
        ...options // jshint ignore:line
    };

    slide.addChart(self.pptx.charts.BAR, obj, mergedObject);
}
