var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isOpen)?_c('div',{staticClass:"custom-modal-overlay"},[_c('div',{staticClass:"custom-modal"},[_c('div',{staticClass:"custom-modal-content"},[_c('h2',{staticClass:"h2 pt-3 px-3 text-center"},[_vm._v(" "+_vm._s(_vm._f("translate")("Zinpro Products"))+" ")]),_c('div',{staticClass:"radio-btn m-4 scrollable"},[_c('table',[_c('thead',[_c('tr',[_c('th',{staticClass:"pt-3 pb-2 px-3 text-center"},[_vm._v(_vm._s(_vm._f("translate")("Zinpro Products")))]),_c('th',{staticClass:"pt-3 pb-2 px-3 text-center"},[_vm._v(_vm._s(_vm._f("translate")("Inclusion")))]),_c('th',{staticClass:"pt-3 pb-2 px-3 text-center"},[_vm._v(_vm._s(_vm._f("translate")("Unit")))]),_c('th',{staticClass:"pt-3 pb-2 px-3 text-center"},[_vm._v(_vm._s(_vm._f("translate")("Delete")))])])]),_c('tbody',_vm._l((Object.keys(
                _vm.selectedMinerals
              )),function(selectedMineral){return _c('tr',{key:`${selectedMineral.name}-${Math.random()}`,staticClass:"table-row"},[_c('td',{staticClass:"pt-3 pb-2 px-3 text-center"},[_c('span',{staticClass:"with-trademark"},[_vm._v("Zinpro")]),_vm._v("   "),_c('span'),(_vm.selectedMinerals[selectedMineral].mineral === 'IsoFerm')?_c('span',{staticClass:"with-trademark"},[_vm._v(" "+_vm._s(_vm.selectedMinerals[selectedMineral].mineral)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("translate")(_vm.selectedMinerals[selectedMineral].mineral))+" ")])]),_c('td',[_c('div',{staticClass:"form-group",staticStyle:{"margin-bottom":"0"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.selectedMinerals[selectedMineral].value
                    ),expression:"\n                      selectedMinerals[selectedMineral].value\n                    "}],staticClass:"form-control",staticStyle:{"margin-bottom":"0"},attrs:{"type":"text"},domProps:{"value":(
                      _vm.selectedMinerals[selectedMineral].value
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.selectedMinerals[selectedMineral], "value", $event.target.value)}}})])]),_c('th',{staticClass:"pt-3 pb-2 px-3 text-center"},[_vm._v(" "+_vm._s(_vm._f("translate")(_vm.selectedMinerals[selectedMineral].unit)))]),_c('td',[_c('button',{staticClass:"btn shrink-on-mobile",on:{"click":() => _vm.deleteMineral(selectedMineral)}},[_vm._v(" "+_vm._s(_vm._f("translate")("Delete"))+" ")])])])}),0)])]),_c('select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",attrs:{"name":"mineral","data-vv-as":"Avg. Cow Size"},on:{"click":_vm.handleSelect}},[_c('option',{attrs:{"selected":"","disabled":""},domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm._f("translate")("Select"))+" ")]),_vm._l((_vm.minerals),function(mineral){return _c('option',{key:mineral.name,attrs:{"disabled":_vm.selectedMinerals[mineral.name]},domProps:{"value":mineral.name}},[_vm._m(0,true),_vm._v(" "+_vm._s(_vm._f("translate")(_vm.getMineralDisplayName(mineral)))+" ")])})],2),_c('button',{staticClass:"btn btn-primary btn-small-modal ml-auto",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm._f("translate")("Done"))+" ")])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"trademark"},[_vm._v("Zinpro"),_c('span',[_vm._v("®")])])
}]

export { render, staticRenderFns }