import { GET_USER_BY_ID } from '../../../../store/types';

export const medias = async (app, report, group, assessorType, user) => {
  let assessor = group.getAssessorByType(assessorType);
  let medias = [];
  const assessorInfo = app.$store.getters.getAssessorInfoByType(assessor.getType());

  if (!!assessor.assessor.data.medias) {
    // let userMedias = app.$store.getters.getData('users/' + user._id + '/medias');

    let userMedias = user.medias;

    if (userMedias === undefined) {
      userMedias = [];
    }

    if (Array.isArray(assessor.assessor.data.medias)) {
      for (let i = 0; i < assessor.assessor.data.medias.length; i++) {
        // let media = app.$store.getters.getData('users/' + user.id + '/medias/' + assessor.assessor.data.medias[i]);

        let media = userMedias[assessor.assessor.data.medias[i]];
        if (media !== undefined) {
          medias.push(media);
        }
      }
    } else {
       Object.keys(assessor.assessor.data.medias).forEach(mediaKey => {
        if (userMedias[mediaKey]) medias.push(userMedias[mediaKey]);
      });
    }
  }

  var slide;
  var hasPresentationNotes = false;
  var imagesPerSlide = 6;
  if (assessor.getPresentationNotes()) {
    report.slideHeight = 2.2;

    slide = report.pptx.addSlide('Slide master');

    function getAssessorName() {
      let assessorName;
      switch (assessor.assessor.type) {
        case '1':
          assessorName = 'Hygiene/Footbath Assessor';
          break;
        case '2':
          assessorName = 'Freestall Assessor';
          break;
        case '3':
          assessorName = 'Locomotion Assessor';
          break;
        case '4':
          assessorName = 'Time Budget Assessor';
        case '16':
          assessorName = 'Heat Abatement Assessor';
          break;
        case '11':
          assessorName = 'Holding Area Assessor';
          break;
        case '14':
          assessorName = 'Nutrition and Feeding Assessor';
          break;
        case '13':
          assessorName = 'Tiestall/Stanchion Heat Abatement Assessor';
          break;
        case '15':
          assessorName = 'Hoof Trimming Assessor';
          break;
        case '17':
          assessorName = 'Lesion Assessor';
          break;
        case '18':
          assessorName = 'Walking Surface Assessor';
          break;
        case '9':
          assessorName = 'Races, Tracks and Lanes Assessor';
          break;
        case '12':
          assessorName = 'Freestall Ventilation Assessor';
          break;
        case '10':
          assessorName = 'Tie Stall/Stanchion Assessor';
          break;
        case '7':
          assessorName = 'Bedded Pack Assessor';
          break;
        case '6':
          assessorName = 'Biosecurity Assessor';
          break;
        case '5':
          assessorName = 'Dirt Lot Assessor';
          break;
      }

      return app.$t(assessorName);
    }

    hasPresentationNotes = true;

    slide.addText(
      app.$t(assessorInfo ? assessorInfo.name + ' Assessor' : 'Assessor Unknown') + app.$t(' - ') + group.group.name,
      report.styleOptions.slideHeader
    );

    slide.addText(app.$t('Comments'), {
      x: 0.5,
      y: report.slideHeight,
      ...report.styleOptions.subheaderBlue,
    });

    report.slideHeight += 0.7;

    // slide.addText(
    //   assessor.getPresentationNotes(), {
    //     x: 0.5,
    //     y: report.slideHeight,
    //     h: 1,
    //     color: '000000',
    //     fontSize: 11,
    //     fontFace: 'Arial',
    //   }
    // );

    slide.addText(assessor.getPresentationNotes(), {
      shape: report.pptx.shapes.RECTANGLE,
      x: 0.5,
      y: 2.5, // report.slideHeight,
      w: 7.5,
      h: 1.3,
      color: '000000',
      isTextBox: true,
      valign: 'top',
      fontSize: 11,
      fontFace: 'Arial',
    });

    report.slideHeight += 1.35;
  }

  if (medias.length === 0) return false;

  let mediaCount = 0;

  for (const [index, media] of medias.entries()) {
    if (mediaCount === 0 || mediaCount === imagesPerSlide) {
      if (imagesPerSlide === 4) {
        imagesPerSlide = 6;
      }

      if (hasPresentationNotes) {
        hasPresentationNotes = false;
        imagesPerSlide = 4;
      } else {
        slide = report.pptx.addSlide('Slide master');
        report.slideHeight = 2.2;
        mediaCount = 0;
      }

      if (!assessor.getPresentationNotes()) {
        slide.addText(
          app.$t(assessorInfo ? assessorInfo.name + ' Assessor' : 'Assessor Unknown') + app.$t(' - ') + group.getName(),
          report.styleOptions.slideHeader
        );
      }

      slide.addText(app.$t('Images'), {
        x: 0.5,
        y: report.slideHeight,
        ...report.styleOptions.subheaderBlue,
      });

      report.slideHeight += 0.5;
    }

    let x;
    // if index is zero or even
    if (index % 2 === 0) {
      x = 0.5;
      if (mediaCount !== 0 && mediaCount !== imagesPerSlide) {
        report.slideHeight += 1.05;
      }
    } else {
      x = 4.5;
    }

    slide.addShape(report.pptx.shapes.RECTANGLE, {
      x: x,
      y: report.slideHeight,
      w: 3.5,
      h: 2.15,
      line: '000000',
      line_size: 1,
    });

    let maxWidth = 2.9;
    let maxHeight = media.comment !== null && media.comment !== '' ? 1.25 : 1.65;
    let image = {
      // data: media.base64,
      path: media.base64,
      x: x + 0.3,
      y: report.slideHeight + 0.25,
      h: maxHeight,
      w: maxWidth,
    };

    if (media.height !== undefined && media.width !== undefined) {
      if (media.width / media.height < maxWidth / maxHeight) {
        image.h = maxHeight;
        image.w = (maxHeight * media.width) / media.height;
        image.x += (maxWidth - image.w) / 2;
      } else {
        image.w = maxWidth;
        image.h = (maxWidth * media.height) / media.width;
        image.y += (maxHeight - image.h) / 2;
      }
    }
    slide.addImage(image);
    if (media.comment !== null && media.comment !== '') {
      slide.addText(media.comment, {
        x: x + 0.3,
        y: report.slideHeight + 1.55,
        h: 1,
        w: 2.9,
        color: '000000',
        fontSize: 11,
        fontFace: 'Arial',
        valign: 'top',
      });
    }
    if (index % 2 !== 0) {
      report.slideHeight += 1.4;
    }
    mediaCount++;
  }
};
