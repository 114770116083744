<template>
  <div>
    <a href="#" data-toggle="modal" :data-target="['#score-' + value]">
      <img
        v-if="size === 'lg'"
        :src="score_img"
        :alt="[$t('score cow ') + value]"
        class="hygiene-score-images__img mb-3"
      />
      <img
        v-else
        :src="score_img"
        :alt="[$t('score cow ') + value]"
        class="hygiene-score-images__img mb-3 w-100"
      />
    </a>
    <div
      class="modal fade"
      :id="['score-' + value]"
      tabindex="-1"
      role="dialog"
      :aria-labelledby="['score-' + value]"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog--edit" role="document">
        <div class="modal-content edit-modal">
          <h2 class="h2 h2--modal text-center mt-1 mt-md-0 mb-0">
            {{ $t('Score ') + value }}
          </h2>
          <div class="modal-body my-0">
            <img
              :src="score_img"
              :alt="[$t('score cow ') + value]"
              class="mb-3 w-100"
            />
            <p v-if="value === 1" class="mb-1">
              <strong>{{ 'Clean' | translate }} - </strong
              >{{
                'Little or no manure contamination of the lower limb.'
                  | translate
              }}
            </p>
            <p v-if="value === 2" class="mb-1">
              <strong>{{ 'Slightly Dirty' | translate }} - </strong
              >{{ 'Lower limb is lightly splashed with manure.' | translate }}
            </p>
            <p v-if="value === 3" class="mb-1">
              <strong>{{ 'Moderately Dirty' | translate }} - </strong
              >{{
                'Distinct plaques of manure on the foot progressing up the limb.'
                  | translate
              }}
            </p>
            <p v-if="value === 4" class="mb-1">
              <strong>{{ 'Very Dirty' | translate }} - </strong
              >{{
                'Confluent plaques of caked on manure on the foot and higher up the lower limb.'
                  | translate
              }}
            </p>
            <div class="row">
              <div class="col-md-24 modal-bottom">
                <a
                  href="#"
                  class="btn-cancel-modal mt-2"
                  data-dismiss="modal"
                  >{{ 'Cancel' | translate }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'size', 'src'],
  computed: {
    score_img() {
      return '/images/score_' + this.value + '.jpg';
    },
  },
};
</script>
