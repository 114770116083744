<template>
  <div class="dashboard-ventilation dashboard__stats-block main-container mb-0">
    <div class="row no-gutters w-100 align-items-start mb-lg-4">
      <div class="col-24 col-md-24 col-lg-12">
        <h2 class="h2 d-inline-block dashboard-ventilation__title">
          {{ "Heat Stress & Ventilation" | translate }}
          ({{ "Type - " | translate }} {{ translateType(groupType) }})
        </h2>
      </div>
      <div
        v-if="filteredGroups.length > 1"
        class="col-24 col-md-24 col-lg-12 mb-3 my-1 my-lg-0 text-lg-right"
      >
        <select
          v-model="groupId"
          class="form-control dashboard__select d-inline"
        >
          <option :value="null" disabled>{{ "Select" | translate }}</option>

          <option
            v-for="group in filteredGroups"
            :key="`freestall_${group.id}`"
            :value="group.id"
          >
            {{ group.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row d-flex justify-content-between">
      <div class="col-md-12 text-center mb-3">
        <img
          v-if="
            !airSpeedAllCowsKey(groupId) ||
            ['no-vent', 'less-5'].includes(airSpeedAllCowsKey(groupId))
          "
          class="dashboard-ventilation__img-all-cows mw-100"
          src="@/assets/img/dashboard/air-speed-red.svg"
        />
        <img
          v-if="airSpeedAllCowsKey(groupId) === '5-8'"
          class="dashboard-ventilation__img-all-cows mw-100"
          src="@/assets/img/dashboard/air-speed-yellow.svg"
        />
        <img
          v-if="airSpeedAllCowsKey(groupId) === 'more-5'"
          class="dashboard-ventilation__img-all-cows mw-100"
          src="@/assets/img/dashboard/air-speed-green.svg"
        />
        <h6 class="mt-2">{{ "Avg Air Speed at Cow Level" | translate }}</h6>
        <small>{{ airSpeedAllCows(groupId) || "-" | translate }}</small>
      </div>
      <div class="col-md-12 text-center mb-3">
        <img
          v-if="
            !airSpeedCowKey(groupId) ||
            ['no-vent', 'less-300'].includes(airSpeedCowKey(groupId))
          "
          class="dashboard-ventilation__img-cow mw-100"
          src="@/assets/img/dashboard/cow-red.svg"
        />
        <img
          v-if="airSpeedCowKey(groupId) === '300-1000'"
          class="dashboard-ventilation__img-cow mw-100"
          src="@/assets/img/dashboard/cow-yellow.svg"
        />
        <img
          v-if="airSpeedCowKey(groupId) === 'more-1000'"
          class="dashboard-ventilation__img-cow mw-100"
          src="@/assets/img/dashboard/cow-green.svg"
        />
        <h6 class="mt-2">{{ "Avg Air Volume per Cow" | translate }}</h6>
        <small>{{ airSpeedCow(groupId) || "-" | translate }}</small>
      </div>
      <div class="col-md-12 text-center mb-3">
        <img
          v-if="
            !heatAbatementKey(groupId) ||
            heatAbatementKey(groupId) === 'no-fans'
          "
          class="dashboard-ventilation__img-cooling mw-100"
          src="@/assets/img/dashboard/water-cooling-red.svg"
        />
        <img
          v-else-if="heatAbatementKey(groupId) === 'fans'"
          class="dashboard-ventilation__img-cooling mw-100"
          src="@/assets/img/dashboard/water-cooling-yellow.svg"
        />
        <img
          v-else-if="heatAbatementKey(groupId) === 'fans-water'"
          class="dashboard-ventilation__img-cooling mw-100"
          src="@/assets/img/dashboard/water-cooling-green.svg"
        />
        <h6 class="mt-2">{{ "Water Cooling?" | translate }}</h6>
        <small>{{ heatAbatement(groupId) || "-" | translate }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import { animalMixin } from "@/mixins/animal.mixin.js";
import { translationsMixin } from "@/mixins/translations.mixin.js";
import { assessorIds } from "@/libs/Classes/constants/assessorIds";

export default {
  name: "DashboardVentilation",
  mixins: [animalMixin, translationsMixin],
  props: {
    evaluation: Object,
  },
  data() {
    return {
      groupId: "",
      assessorIds,
    };
  },
  created() {
    let defaultGroupId = this.filteredGroups[0].id;
    this.groupId = defaultGroupId;
  },
  computed: {
    filteredGroups() {
      return Object.values(this.evaluation.groups).filter((group) => {
        const values = [
          this.airSpeedAllCowsKey(group.id),
          this.airSpeedCowKey(group.id),
          this.heatAbatementKey(group.id),
        ];
        return values.filter((v) => v).length;
      });
    },
    groupType() {
      return this.getGroup(this.groupId).data.type_of_animal;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../_dashboard.scss";

.dashboard-ventilation {
  height: 100%;

  &__title {
    line-height: 23px;
  }

  &__img-all-cows {
    width: 135px;
  }

  &__img-cow {
    width: 115px;
  }

  &__img-cooling {
    width: 77px;
  }
}
</style>
