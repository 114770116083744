<template>
	<div>
		{{ group_count }}
	</div>
</template>

<script>
export default {
	props: [
		'evaluation'
	],
	computed: {
		group_count () {
			return this.evaluation.groups.length;
		}
	}
}
</script>
