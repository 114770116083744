import {
    racetrack
} from './racetrack';
import {
    medias
} from './../Global/medias.js';

class RaceTrackAssessorSlides {

    constructor(app, report, evaluation, group, user) {
        this.app = app;
        this.report = report;
        this.evaluation = evaluation;
        this.group = group;
        this.user = user;
    }

    add(tableOfContentsCalculatorPosition) {

        let assessor = this.group.getAssessorByType("9");

        if (assessor === false || assessor.assessor.data === undefined) {
            throw this.group.getName() + this.app.$t(" - ") + this.app.$t("Races, Tracks and Lanes") + this.app.$t(" assessor is not ready.");
        }

        this.report.tableOfContentsHelper("Races, Tracks and Lanes", tableOfContentsCalculatorPosition, this.group);

        racetrack(this.app, this.report, this.evaluation, this.group, assessor);
        medias(this.app, this.report, this.group, "9", this.user);
    }

}

export default RaceTrackAssessorSlides;