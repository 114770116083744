export const $getEquivalentUnit = function (measurement, unit) {
  const equalUnit = {
    'ml/L': 'oz/gal',
    'g/L': 'lb/gal',
    'L': 'gal',
    'l': 'gal',
    'kg': 'lb',
    'm2': 'ft2',
    'm3/h': 'ft3/h',
    'm3': 'ft3',
    'km/h': 'm/h',
    'm/s': 'm/h',
    'km': 'mi',
    'm': 'ft',
    'C': 'F',
    'g': 'oz',
    'ml': 'fl-oz',
    'cm': 'in',
    'square meters': 'square feet',
    'sq m': 'sq ft',
    'cmh': 'cfm',
    'cu meters': 'cu feet',
    'cu m': 'cu ft',
    'meters': 'feet'
  }

  if (measurement === 'imperial') {
    return equalUnit[unit]
  }

  return unit
}

export function changeToMilesPerHr(unit) {
  if (unit === 'm/h') return 'mph';
  return unit;
}

export const $getDate = function (timestamp) {
  let date = new Date(timestamp)
  var monthNames = [
    this.app.$t('Jan.'), this.app.$t('Feb.'), this.app.$t('Mar.'),
    this.app.$t('Apr.'), this.app.$t('May'), this.app.$t('Jun.'),
    this.app.$t('Jul.'), this.app.$t('Aug.'), this.app.$t('Sep.'),
    this.app.$t('Oct.'), this.app.$t('Nov.'), this.app.$t('Dec.')
  ]
  var day = date.getDate()
  var monthIndex = date.getMonth()
  var year = date.getFullYear()
  return day + ' ' + monthNames[monthIndex] + ' ' + year
}
