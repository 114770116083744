import { mapActions, mapGetters, mapState } from 'vuex';
import {
  GET_REQUEST_REPORT,
  GET_USERS_BY_ID_GETTER,
  GET_USER_BY_ID,
} from '../store/types';

export default {
  data() {
    return {
      requestReportLoader: false,
      successMessage: null,
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters('users', [GET_USERS_BY_ID_GETTER]),
    ...mapState({
      user: state => state.users.user,
    }),
  },
  methods: {
    ...mapActions('users', [GET_USER_BY_ID]),
    ...mapActions('reports', [GET_REQUEST_REPORT]),
    async requestReport(evaluationName) {
      try {
        this.resetLoaders();
        const emailTo = await this.representorEmailToData(evaluationName);
        await this[GET_REQUEST_REPORT](emailTo);
        this.requestReportLoader = false;
        this.successMessage = true;
        setTimeout(() => {
          this.errorMessage = false;
          this.successMessage = false;
        }, 5000);
        console.log('not catching the error ');
      } catch (error) {
        this.requestReportLoader = false;
        this.errorMessage = error.message || error;
        this.displayError('request-report', error.message);
      }
    },

    resetLoaders() {
      this.requestReportLoader = true;
      this.errorMessage = false;
      this.successMessage = false;
    },

    async representorEmailToData(evaluationName) {
      let representative;
      representative = await this[GET_USERS_BY_ID_GETTER](
        this.user.representative
      );
      if (!representative)
        representative = await this[GET_USER_BY_ID](this.user.representative);

      return {
        customerId: representative._id,
        email: representative.email,
        firstName: representative.firstName,
        lastName: representative.lastName,
        lang: representative.lang,
        evaluationName,
      };
    },

    displayError() {},
  },
};
