/**
 * The Tiestall/Stanchion Heat Abatement Assessor evaluates tunnel and cross-ventilated facilities to ensure a sufficient amount of air
 * exchanges per hour is achieved.
 */

const convert = require('convert-units');
import Assessor from './Assessor';

class TieStallStanchion extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;

        //set ideals, mins, and maxes
        this.getIdealDimensions();
    }

    getCowSizeLabel() {
        if (this.evaluation.getMeasurements() === 'metric') {
            switch (this.group.getCowSize()) {
                case '408.2-498.9':
                    return '408-499';

                case '498.9-589.6':
                    return '499-590';

                case '589.6-680.3':
                    return '590-680';

                case '680.3-771':
                    return '680-771';

                case '771-861.8':
                    return '771-862';

                case '861.8-952.5':
                    return '862-953';
            }
        } else {
            switch (this.group.getCowSize()) {
                case '408.2-498.9':
                    return '900-1100';

                case '498.9-589.6':
                    return '1100-1300';

                case '589.6-680.3':
                    return '1300-1500';

                case '680.3-771':
                    return '1500-1700';

                case '771-861.8':
                    return '1700-1900';

                case '861.8-952.5':
                    return '1900-2100';
            }
        }
    }

    rangeIndex() {
        return this.evaluation.getMeasurements() === 'imperial' ? 1 : 2
    }

    getResultMessage(messageKey) {

        const messages = [{
                key: "bedding_depth",
                title: this.app.$t("Bedding Depth (") + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
                target: this.getMinBeddingDepth(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: this.getBeddingDepth() >= this.getMinBeddingDepth(),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t("Cow comfort would improve with the addition of more bedding."),
                        critera: this.getBeddingDepth() < this.getMinBeddingDepth(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "stall_length",
                title: this.app.$t("Stall Length") + ' (' + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
                target: this.getIdealStallLength(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: this.getStallLength() >= this.getIdealStallLength() - this.rangeIndex() && this.getStallLength() <= this.getIdealStallLength() + this.rangeIndex(),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t("Short stalls reduce lying space."),
                        critera: this.getStallLength() < this.getIdealStallLength() - this.rangeIndex(),
                        mood: "text-danger"
                    },
                    {
                        response: this.app.$t("Stalls that are too long become excessively soiled."),
                        critera: this.getStallLength() > this.getIdealStallLength() + this.rangeIndex(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "stall_width",
                title: this.app.$t("Stall Width") + ' (' + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
                target: this.getIdealStallWidth(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: this.getStallWidth() >= this.getIdealStallWidth() - this.rangeIndex() && this.getStallWidth() <= this.getIdealStallWidth() + this.rangeIndex(),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t("Stalls that are too narrow will reduce lying time and increase disturbances between neighboring cows."),
                        critera: this.getStallWidth() < this.getIdealStallWidth() - this.rangeIndex(),
                        mood: "text-danger"
                    },
                    {
                        response: this.app.$t("Stalls that are too wide may allow cows to lie diagonally and soil the stall."),
                        critera: this.getStallWidth() > this.getIdealStallWidth() + this.rangeIndex(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "height_of_feed_curb_bunk_side",
                title: this.app.$t("Height of Feed Curb - Bunk Side") + ' (' + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
                target: this.getIdealHeightOfFeedCurbBunkSide(),
                options: [{
                    response: this.app.$t("Good!"),
                    critera: this.getHeightOfFeedCurbBunkSide() >= this.getIdealHeightOfFeedCurbBunkSide() - this.rangeIndex() && this.getHeightOfFeedCurbBunkSide() <= this.getIdealHeightOfFeedCurbBunkSide() + this.rangeIndex(),
                    mood: "text-success"
                }, {
                    response: this.app.$t("Low curbs allow feed to contaminate stall surface."),
                    critera: this.getHeightOfFeedCurbBunkSide() < this.getIdealHeightOfFeedCurbBunkSide() - this.rangeIndex(),
                    mood: "text-danger"
                }, {
                    response: this.app.$t("High curbs prevent normal front leg movement when rising."),
                    critera: this.getHeightOfFeedCurbBunkSide() > this.getIdealHeightOfFeedCurbBunkSide() + this.rangeIndex(),
                    mood: "text-danger"
                }]
            },
            {
                key: "height_of_feed_curb_stall_side",
                title: this.app.$t("Height of Feed Curb - Stall Side") + ' (' + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
                target: '≤' + this.getMaxHeightOfFeedCurbStallSide(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: this.getHeightOfFeedCurbStallSide() <= this.getMaxHeightOfFeedCurbStallSide(),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t("High curbs prevent normal front leg movement when rising."),
                        critera: this.getHeightOfFeedCurbStallSide() > this.getMaxHeightOfFeedCurbStallSide(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "height_of_headrail_above_stall_surface",
                title: this.app.$t("Height of Headrail Above Stall Surface") + ' (' + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
                target: this.getIdealHeightOfHeadrailAboveStallSurface(),
                options: [{
                    response: this.app.$t("Good!"),
                    critera: this.getHeightOfHeadrailAboveStallSurface() >= this.getIdealHeightOfHeadrailAboveStallSurface() - this.rangeIndex() && this.getHeightOfHeadrailAboveStallSurface() <= this.getIdealHeightOfHeadrailAboveStallSurface() + this.rangeIndex(),
                    mood: "text-success"
                }, {
                    response: this.app.$t("Low headrails prevent normal lunging movement."),
                    critera: this.getHeightOfHeadrailAboveStallSurface() < this.getIdealHeightOfHeadrailAboveStallSurface() - this.rangeIndex(),
                    mood: "text-danger"
                }, {
                    response: this.app.$t("Make sure neck chain is long enough to allow cow to eat and lie comfortably."),
                    critera: this.getHeightOfHeadrailAboveStallSurface() > this.getIdealHeightOfHeadrailAboveStallSurface() + this.rangeIndex(),
                    mood: "text-danger"
                }]
            },
            {
                key: "length_of_chain",
                title: this.app.$t("Length of Chain") + ' (' + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
                target: this.getIdealLengthOfChain(),
                options: [{
                    response: this.app.$t("Good!"),
                    critera: this.getLengthOfChain() >= this.getIdealLengthOfChain() - this.rangeIndex() && this.getLengthOfChain() <= this.getIdealLengthOfChain() + this.rangeIndex(),
                    mood: "text-success"
                }, {
                    response: this.app.$t("Short chains prevent normal lunging movement."),
                    critera: this.getLengthOfChain() < this.getIdealLengthOfChain() - this.rangeIndex(),
                    mood: "text-danger"
                }, {
                    response: this.app.$t("Long chains may injure cow by entangling front foot."),
                    critera: this.getLengthOfChain() > this.getIdealLengthOfChain() + this.rangeIndex(),
                    mood: "text-danger"
                }]
            },
            {
                key: "height_of_drinking_cup_floor_above_stall_surface",
                title: this.app.$t("Height of Drinking Cup Above Stall Surface") + ' (' + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
                target: this.getIdealHeightOfDrinkingCupFloorAboveStallSurface(),
                options: [{
                    response: this.app.$t("Good!"),
                    critera: this.getHeightOfDrinkingCupFloorAboveStallSurface() >= this.getIdealHeightOfDrinkingCupFloorAboveStallSurface() - this.rangeIndex() && this.getHeightOfDrinkingCupFloorAboveStallSurface() <= this.getIdealHeightOfDrinkingCupFloorAboveStallSurface() + this.rangeIndex(),
                    mood: "text-success"
                }, {
                    response: this.app.$t("Drinking cups may become contaminated with feed faster."),
                    critera: this.getHeightOfDrinkingCupFloorAboveStallSurface() < this.getIdealHeightOfDrinkingCupFloorAboveStallSurface() - this.rangeIndex(),
                    mood: "text-danger"
                }, {
                    response: this.app.$t("Cups that are too high do not allow comfortable drinking position."),
                    critera: this.getHeightOfDrinkingCupFloorAboveStallSurface() > this.getIdealHeightOfDrinkingCupFloorAboveStallSurface() + this.rangeIndex(),
                    mood: "text-danger"
                }]
            },
            {
                key: "headrail_to_rear_curb",
                title: this.app.$t("Headrail to Rear Curb") + ' (' + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
                target: this.getIdealHeadrailToRearCurb(),
                options: [{
                    response: this.app.$t("Good!"),
                    critera: this.getHeadrailToRearCurb() >=  this.getIdealHeadrailToRearCurb() - this.rangeIndex() &&  this.getHeadrailToRearCurb() <= this.getIdealHeadrailToRearCurb() + this.rangeIndex(),
                    mood: "text-success"
                }, {
                    response: this.app.$t("The headrail should not limit the rising motion of the cow."),
                    critera: this.getHeadrailToRearCurb() < this.getIdealHeadrailToRearCurb() - this.rangeIndex(),
                    mood: "text-danger"
                }, {
                    response: this.app.$t("Headrails too far forward may obstruct feeder."),
                    critera: this.getHeadrailToRearCurb() > this.getIdealHeadrailToRearCurb() + this.rangeIndex(),
                    mood: "text-danger"
                }]
            },
            {
                key: "rear_edge_of_divider_to_curb",
                title: this.app.$t("Rear Edge of Divider to Curb") + ' (' + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
                target: this.getIdealRearEdgeOfDividerToCurb(),
                options: [{
                    response: this.app.$t("Good!"),
                    critera: this.getRearEdgeOfDividerToCurb() >= this.getIdealRearEdgeOfDividerToCurb() - this.rangeIndex() && this.getRearEdgeOfDividerToCurb() <= this.getIdealRearEdgeOfDividerToCurb() + this.rangeIndex(),
                    mood: "text-success"
                }, {
                    response: this.app.$t("May not allow cow to turn properly when exiting."),
                    critera: this.getRearEdgeOfDividerToCurb() < this.getIdealRearEdgeOfDividerToCurb() - this.rangeIndex(),
                    mood: "text-danger"
                }, {
                    response: this.app.$t("Too much space may allow cows to roam the back of the stalls."),
                    critera: this.getRearEdgeOfDividerToCurb() > this.getIdealRearEdgeOfDividerToCurb() + this.rangeIndex(),
                    mood: "text-danger"
                }]
            },
            {
                key: "distance_of_trainers_from_rear_curb",
                title: this.app.$t("Distance of Trainers From Rear Curb") + ' (' + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
                target: this.getIdealDistanceOfTrainersFromRearCurb(),
                options: [{
                    response: this.app.$t("Good!"),
                    critera: this.getDistanceOfTrainersFromRearCurb() >= this.getIdealDistanceOfTrainersFromRearCurb() - this.rangeIndex() && this.getDistanceOfTrainersFromRearCurb() <= this.getIdealDistanceOfTrainersFromRearCurb() + this.rangeIndex(),
                    mood: "text-success"
                }, {
                    response: this.app.$t("Trainers that are too far back are inhumane."),
                    critera: this.getDistanceOfTrainersFromRearCurb() < this.getIdealDistanceOfTrainersFromRearCurb() - this.rangeIndex(),
                    mood: "text-danger"
                }, {
                    response: this.app.$t("Trainers too far forward may prove to be ineffective."),
                    critera: this.getDistanceOfTrainersFromRearCurb() > this.getIdealDistanceOfTrainersFromRearCurb() + this.rangeIndex(),
                    mood: "text-danger"
                }]
            },
            {
                key: "height_of_trainer_above_wither",
                title: this.app.$t("Height of Trainer Above Wither") + ' (' + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
                target: this.getIdealHeightOfTrainerAboveWither(),
                options: [{
                    response: this.app.$t("Good!"),
                    critera: this.getHeightOfTrainerAboveWither() >= this.getIdealHeightOfTrainerAboveWither() && this.getHeightOfTrainerAboveWither() <= this.getIdealHeightOfTrainerAboveWither(),
                    mood: "text-success"
                }, {
                    response: this.app.$t("Low trainer height is inhumane."),
                    critera: this.getHeightOfTrainerAboveWither() < this.getIdealHeightOfTrainerAboveWither(),
                    mood: "text-danger"
                }, {
                    response: this.app.$t("High trainer heights above the withers may reduce effectiveness."),
                    critera: this.getHeightOfTrainerAboveWither() > this.getIdealHeightOfTrainerAboveWither(),
                    mood: "text-danger"
                }]
            }
        ];


        for (var i = 0; i < messages.length; i++) {
            if (messages[i].key === messageKey) {
                for (var j = 0; j < messages[i].options.length; j++) {
                    if (messages[i].options[j].critera === true) {
                        return {
                            title: messages[i].title,
                            target: messages[i].target,
                            feedback: messages[i].options[j]
                        };
                    }
                }
            }
        }

        return {
            title: '',
            feedback: ''
        };
    }

    getStallSurfaceValue() {
        return this.assessor.data.stall_surface;
    }

    getStallSurface() {
        switch (this.getStallSurfaceValue()) {
            case 'concrete':
                return app.$t('Concrete');
            case 'foam_mattress':
                return app.$t('Foam Mattress');
            case 'manure_solids':
                return app.$t('Manure Solids');
            case 'mats':
                return app.$t('Mats');
            case 'rubber_mattress':
                return app.$t('Rubber Mattress');
            case 'sand':
                return app.$t('Sand');
            case 'straw':
                return app.$t('Straw');
            case 'water_bed':
                return app.$t('Waterbed');
            case 'sawdust':
                return app.$t('Sawdust');
            case 'other':
                return app.$t('Other');
        }
    }

    getBeddingDepth(roundPoint) {
        if (this.assessor.data.bedding_depth === undefined || this.assessor.data.bedding_depth === null) {
            return this.assessor.data.bedding_depth;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.bedding_depth.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.bedding_depth)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(roundPoint);
    }

    setBeddingDepth(newValue) {
        if (newValue < 1) {
            return this.assessor.data.bedding_depth = null;
        }

        this.assessor.data.bedding_depth = parseFloat(convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getMinBeddingDepth() {
        return parseFloat(convert(this.minBeddingDepth)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getDoesTheStallPassTheKneeDropTest() {
        return this.assessor.data.does_the_stall_pass_the_knee_drop_test;
    }

    getIsThereAnObstructionAboveDrinkingCups() {
        return this.assessor.data.is_there_an_obstruction_above_drinking_cups;
    }

    getIsThereABobZoneObstruction() {
        return this.assessor.data.is_there_a_bob_zone_obstruction;
    }

    getStallLength(roundPoint) {
        if (this.assessor.data.stall_length === undefined || this.assessor.data.stall_length === null) {
            return this.assessor.data.stall_length;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.stall_length.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.stall_length)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(roundPoint);
    }

    setStallLength(newValue) {
        if (newValue === '') {
            return this.assessor.data.stall_length = null;
        }

        this.assessor.data.stall_length = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
                .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getIdealStallLength() {
        return parseFloat(convert(this.idealStallLength)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getStallWidth(roundPoint) {
        if (this.assessor.data.stall_width === undefined || this.assessor.data.stall_width === null) {
            return this.assessor.data.stall_width;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.stall_width.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.stall_width)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(roundPoint);
    }

    setStallWidth(newValue) {
        if (newValue === '') {
            return this.assessor.data.stall_width = null;
        }

        this.assessor.data.stall_width = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
                .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getIdealStallWidth() {
        return parseFloat(convert(this.idealStallWidth)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getHeightOfFeedCurbBunkSide(roundPoint) {
        if (this.assessor.data.height_of_feed_curb_bunk_side === undefined || this.assessor.data.height_of_feed_curb_bunk_side === null) {
            return this.assessor.data.height_of_feed_curb_bunk_side;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.height_of_feed_curb_bunk_side.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.height_of_feed_curb_bunk_side)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(roundPoint);
    }

    setHeightOfFeedCurbBunkSide(newValue) {
        if (newValue === '') {
            return this.assessor.data.height_of_feed_curb_bunk_side = null;
        }

        this.assessor.data.height_of_feed_curb_bunk_side = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
                .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getIdealHeightOfFeedCurbBunkSide() {
        return parseFloat(convert(this.idealHeightOfFeedCurbBunkSide)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getHeightOfFeedCurbStallSide(roundPoint) {
        if (this.assessor.data.height_of_feed_curb_stall_side === undefined || this.assessor.data.height_of_feed_curb_stall_side === null) {
            return this.assessor.data.height_of_feed_curb_stall_side;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.height_of_feed_curb_stall_side.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.height_of_feed_curb_stall_side)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(roundPoint);
    }

    setHeightOfFeedCurbStallSide(newValue) {
        if (newValue === '') {
            return this.assessor.data.height_of_feed_curb_stall_side = null;
        }

        this.assessor.data.height_of_feed_curb_stall_side = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
                .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getMaxHeightOfFeedCurbStallSide() {
        return parseFloat(convert(this.maxHeightOfFeedCurbStallSide)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getHeightOfHeadrailAboveStallSurface(roundPoint) {
        if (this.assessor.data.height_of_headrail_above_stall_surface === undefined || this.assessor.data.height_of_headrail_above_stall_surface === null) {
            return this.assessor.data.height_of_headrail_above_stall_surface;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.height_of_headrail_above_stall_surface.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.height_of_headrail_above_stall_surface)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(roundPoint);
    }

    setHeightOfHeadrailAboveStallSurface(newValue) {
        if (newValue === '') {
            return this.assessor.data.height_of_headrail_above_stall_surface = null;
        }

        this.assessor.data.height_of_headrail_above_stall_surface = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
                .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getIdealHeightOfHeadrailAboveStallSurface() {
        return parseFloat(convert(this.idealHeightOfHeadrailAboveStallSurface)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getLengthOfChain(roundPoint) {
        if (this.assessor.data.length_of_chain === undefined || this.assessor.data.length_of_chain === null) {
            return this.assessor.data.length_of_chain;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.length_of_chain.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.length_of_chain)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(roundPoint);
    }

    setLengthOfChain(newValue) {
        if (newValue === '') {
            return this.assessor.data.length_of_chain = null;
        }

        this.assessor.data.length_of_chain = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
                .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getIdealLengthOfChain() {
        return parseFloat(convert(this.idealLengthOfChain)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getHeightOfDrinkingCupFloorAboveStallSurface(roundPoint) {
        if (this.assessor.data.height_of_drinking_cup_floor_above_stall_surface === undefined || this.assessor.data.height_of_drinking_cup_floor_above_stall_surface === null) {
            return this.assessor.data.height_of_drinking_cup_floor_above_stall_surface;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.height_of_drinking_cup_floor_above_stall_surface.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.height_of_drinking_cup_floor_above_stall_surface)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(roundPoint);
    }

    setHeightOfDrinkingCupFloorAboveStallSurface(newValue) {
        if (newValue === '') {
            return this.assessor.data.height_of_drinking_cup_floor_above_stall_surface = null;
        }

        this.assessor.data.height_of_drinking_cup_floor_above_stall_surface = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
                .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getIdealHeightOfDrinkingCupFloorAboveStallSurface() {
        return parseFloat(convert(this.idealHeightOfDrinkingCupFloorAboveStallSurface)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getHeadrailToRearCurb(roundPoint) {
        if (this.assessor.data.headrail_to_rear_curb === undefined || this.assessor.data.headrail_to_rear_curb === null) {
            return this.assessor.data.headrail_to_rear_curb;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.headrail_to_rear_curb.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.headrail_to_rear_curb)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(roundPoint);
    }

    setHeadrailToRearCurb(newValue) {
        if (newValue === '') {
            return this.assessor.data.headrail_to_rear_curb = null;
        }

        this.assessor.data.headrail_to_rear_curb = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
                .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getIdealHeadrailToRearCurb() {
        return parseFloat(convert(this.idealHeadrailToRearCurb)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getRearEdgeOfDividerToCurb(roundPoint) {
        if (this.assessor.data.rear_edge_of_divider_to_curb === undefined || this.assessor.data.rear_edge_of_divider_to_curb === null) {
            return this.assessor.data.rear_edge_of_divider_to_curb;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.rear_edge_of_divider_to_curb.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.rear_edge_of_divider_to_curb)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(roundPoint);
    }

    setRearEdgeOfDividerToCurb(newValue) {
        if (newValue === '') {
            return this.assessor.data.rear_edge_of_divider_to_curb = null;
        }

        this.assessor.data.rear_edge_of_divider_to_curb = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
                .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getIdealRearEdgeOfDividerToCurb() {
        return parseFloat(convert(this.idealRearEdgeOfDividerToCurb)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getDistanceOfTrainersFromRearCurb(roundPoint) {
        if (this.assessor.data.distance_of_trainers_from_rear_curb === undefined || this.assessor.data.distance_of_trainers_from_rear_curb === null) {
            return this.assessor.data.distance_of_trainers_from_rear_curb;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.distance_of_trainers_from_rear_curb.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.distance_of_trainers_from_rear_curb)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(roundPoint);
    }

    setDistanceOfTrainersFromRearCurb(newValue) {
        if (newValue === '') {
            return this.assessor.data.distance_of_trainers_from_rear_curb = null;
        }

        this.assessor.data.distance_of_trainers_from_rear_curb = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
                .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getIdealDistanceOfTrainersFromRearCurb() {
        return parseFloat(convert(this.idealDistanceOfTrainersFromRearCurb)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getHeightOfTrainerAboveWither(roundPoint) {
        if (this.assessor.data.height_of_trainer_above_wither === undefined || this.assessor.data.height_of_trainer_above_wither === null) {
            return this.assessor.data.height_of_trainer_above_wither;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.height_of_trainer_above_wither.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.height_of_trainer_above_wither)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(roundPoint);
    }

    setHeightOfTrainerAboveWither(newValue) {
        if (newValue === '') {
            return this.assessor.data.height_of_trainer_above_wither = null;
        }

        this.assessor.data.height_of_trainer_above_wither = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
                .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getIdealHeightOfTrainerAboveWither() {
        return parseFloat(convert(this.idealHeightOfTrainerAboveWither)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    // calculations
    getStallSurfaceRecommendation() {
        switch (this.getStallSurfaceValue()) {
            case 'sand':
                return this.app.$t("Sand is the preferred surface for stalls.");
            case 'manure_solids':
                return this.app.$t("");
            case 'straw':
                return this.app.$t('Good!');
            case 'foam_mattress':
                return this.app.$t("The surface will not facilitate the rising and lying movements of lame cows.");
            case 'waterbed':
                return this.app.$t("The surface will not facilitate the rising and lying movements of lame cows.");
            case 'rubber_mattress':
                return this.app.$t("The surface will not facilitate the rising and lying movements of lame cows.");
            case 'mats':
                return this.app.$t("The surface will not facilitate the rising and lying movements of lame cows.");
            case 'concrete':
                return this.app.$t("Concrete is an unacceptable stall surface for a dairy cow.");
        }
    }

    // Need response from client regarding differing weights from groups to what they have in the original application
    getIdealDimensions() {
        this.idealHeightOfFeedCurbBunkSide = 10;
        this.maxHeightOfFeedCurbStallSide = 20;
        this.idealRearEdgeOfDividerToCurb = 76;
        this.idealDistanceOfTrainersFromRearCurb = 122;
        this.idealHeightOfTrainerAboveWither = 5;
        this.idealHeightOfDrinkingCupFloorAboveStallSurface = 46;
        this.minBeddingDepth = 10;

        switch (this.group.getCowSize()) {

            case '408.2-498.9':
                this.idealStallLength = 178;
                this.idealStallWidth = 137;
                this.idealHeightOfHeadrailAboveStallSurface = 117;
                this.idealLengthOfChain = 86;
                this.idealHeadrailToRearCurb = 213;

                break;
            case '498.9-589.6':
                this.idealStallLength = 181;
                this.idealStallWidth = 141;
                this.idealHeightOfHeadrailAboveStallSurface = 120;
                this.idealLengthOfChain = 89;
                this.idealHeadrailToRearCurb = 216;

                break;
            case '589.6-680.3':
                this.idealStallLength = 183;
                this.idealStallWidth = 145;
                this.idealHeightOfHeadrailAboveStallSurface = 122;
                this.idealLengthOfChain = 91;
                this.idealHeadrailToRearCurb = 218;

                break;
            case '680.3-771':
                this.idealStallLength = 188;
                this.idealStallWidth = 148;
                this.idealHeightOfHeadrailAboveStallSurface = 125;
                this.idealLengthOfChain = 94;
                this.idealHeadrailToRearCurb = 224;

                break;
            case '771-861.8':
                this.idealStallLength = 193;
                this.idealStallWidth = 152;
                this.idealHeightOfHeadrailAboveStallSurface = 127;
                this.idealLengthOfChain = 97;
                this.idealHeadrailToRearCurb = 229;

                break;
            case '861.8-952.5':
                this.idealStallLength = 198;
                this.idealStallWidth = 155;
                this.idealHeightOfHeadrailAboveStallSurface = 130;
                this.idealLengthOfChain = 100;
                this.idealHeadrailToRearCurb = 235;

                break;
                // } else {
                //     this.idealStallLength = 203
                //     this.idealStallWidth = 158
                //     this.idealHeightOfHeadrailAboveStallSurface = 132
                //     this.idealLengthOfChain = 103
                //     this.idealHeadrailToRearCurb = 240
        }
    }

};

export default TieStallStanchion;
