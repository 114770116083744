/**
 * The Bedded Pack Assessor will determine if your operation features sufficient bedded-area per cow
 * and will also help evaluate its ventilation, drainage, location and cleanliness.
 */

const convert = require('convert-units');
import Assessor from './Assessor';

class BeddedPack extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;

        this.idealHowMuchFreshBeddingIsAdded = 12;
        this.idealHowMuchFreshBeddingIsAddedAerobicMin = 5;
        this.idealHowMuchFreshBeddingIsAddedAerobicMax = 15;
    }

    getHowFrequentlyIsTheWholeBedRemoved() {
        return this.assessor.data.how_frequently_is_the_whole_bed_removed;
    }

    getWhatIsTheBeddingAreaPerCow(roundPoint) {
        if (this.assessor.data.what_is_the_bedding_area_per_cow === undefined || this.assessor.data.what_is_the_bedding_area_per_cow === null) {
            return this.assessor.data.what_is_the_bedding_area_per_cow;
        }

        if (!roundPoint) {
            roundPoint = this.assessor.data.what_is_the_bedding_area_per_cow.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.what_is_the_bedding_area_per_cow)
            .from('m2')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm2'))
            .toDecimal(roundPoint);
    }



    setWhatIsTheBeddingAreaPerCow(newValue) {
        this.assessor.data.what_is_the_bedding_area_per_cow = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm2'))
                .to('m2'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getIsThePackManagedAerobicallyOrAnaerobically() {
        return this.assessor.data.is_the_pack_managed_aerobically_or_anaerobically;
    }

    getHowFrequentlyIsFreshBeddingAdded() {
        return this.assessor.data.how_frequently_is_fresh_bedding_added;
    }

    getLongAxisOrientationOfTheBarn() {
        return this.assessor.data.long_axis_orientation_of_the_barn;
    }

    getLongAxisOrientationOfTheBarnText() {
        switch (this.assessor.data.long_axis_orientation_of_the_barn){
            case "n_to_s":
                return app.$t('N to S');
            case "ne_to_sw":
                return app.$t('NE to SW');
            case "e_to_w":
                return app.$t('E to W');
            case "se_to_nw":
                return app.$t('SE to NW');
        }
        return "";
    }

    getLongAxisOrientationOfTheBarnComment() {
        switch (this.assessor.data.long_axis_orientation_of_the_barn){
            case "e_to_w":
                return app.$t("Good! East to West orientation is preferred to maximize wind capture.");
            default:
                return app.$t("East to West orientation is preferred to minimize the sun inside the barn. For 'NE to SW' or 'SE to NW' orientations, place cows with lower production on the side facing the sunset.");
        }
    }

    getPercentageOfCowsHaveScoreOfThreeOrFour() {
        return this.assessor.data.what_percentage_of_cows_have_score_of_three_or_four;
    }

    getTemperatureOfBeddedPack() {
        return this.assessor.data.what_is_the_temperature_of_the_bedded_pack;
    }

    getTemperatureOfBeddedPackMin() {
        if(this.evaluation.getMeasurements() === 'imperial') {
            return 108;
        } else {
            return 42;
        }
    }

    getFanWentSpeedThresholdText() {
        if(this.evaluation.getMeasurements() === 'imperial') {
            return '6.5 ' + app.$t('ft/s');
        } else {
            return '2 ' + app.$t('m/s');
        }
    }

    getFanWentPerfectSpeedThresholdText() {
        if(this.evaluation.getMeasurements() === 'imperial') {
            return '9.5 ' + app.$t('ft/s');
        } else {
            return '3 ' + app.$t('m/s');
        }
    }

    formatTemperature(temperature) {
        if(this.evaluation.getMeasurements() === 'imperial') {
            return temperature + '° ' + app.$t('F')
        } else {
            return temperature + '° ' +  app.$t('C');
        }
    }

    getSquareMeasurements() {
        if (this.evaluation.getMeasurements() === 'imperial') {
            return 'sq ft';
        }
        return 'm²';
    }

    getHowFrequentlyIsTheWholeOrPartialBedRemoved() {
        return this.assessor.data.how_frequently_is_the_whole_or_partial_bed_removed;
    }

    getWhatIsTheWindsSpeedCreatedByTheFans() {
        return this.assessor.data.what_is_the_winds_speed_created_by_the_fans;
    }

    getIsThereSmellOfAmmoniaPresentInTheBarn() {
        return this.assessor.data.is_there_a_smell_of_ammonia_present_in_the_barn;
    }

    getDoesThePackHaveTheCorrectMoistureConsistency() {
        return this.assessor.data.does_the_pack_have_the_correct_moisture_consistency;
    }

    getIsThePackManagedAerobicallyOrAnaerobically() {
        return this.assessor.data.is_the_pack_managed_aerobically_or_anaerobically;
    }

    getDoesThePackRaiseDustWhenTheAnimalsWalk() {
        return this.assessor.data.does_the_pack_raise_dust_when_the_animals_walk;
    }

    getIsTheBeddingSoftAndComfortable() {
        return this.assessor.data.is_the_bedding_soft_and_comfortable;
    }

    getIsThereEvidenceOfLiquidPoolingInTheBarn() {
        return this.assessor.data.is_there_evidence_of_liquid_pooling_in_the_barn;
    }

    getWhatIsTheTemperatureOfTheBeddedPack(roundPoint) {
        if (this.assessor.data.what_is_the_temperature_of_the_bedded_pack === undefined || this.assessor.data.what_is_the_temperature_of_the_bedded_pack === null) {
            return this.assessor.data.what_is_the_temperature_of_the_bedded_pack;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.what_is_the_temperature_of_the_bedded_pack.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }
        return convert(this.assessor.data.what_is_the_temperature_of_the_bedded_pack)
          .from('C')
          .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
          .toDecimal(roundPoint);
    }

    getWhatIsTheWidthOfTheFeedAlley(roundPoint) {
        if (this.assessor.data.what_is_the_width_of_the_feed_alley === undefined || this.assessor.data.what_is_the_width_of_the_feed_alley === null) {
            return this.assessor.data.what_is_the_width_of_the_feed_alley;
        }

        if (!roundPoint) {
            roundPoint = this.assessor.data.what_is_the_width_of_the_feed_alley.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }
        return convert(this.assessor.data.what_is_the_width_of_the_feed_alley)
          .from('m')
          .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
          .toDecimal(roundPoint);
    }

    setWhatIsTheWidthOfTheFeedAlley(newValue) {
        this.assessor.data.what_is_the_width_of_the_feed_alley = parseFloat(convert(newValue)
          .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
          .to('m'))
          .toDecimal(newValue.countDecimals() + 3);
    }

    setWhatIsTheTemperatureOfTheBeddedPack(newValue) {
        this.assessor.data.what_is_the_temperature_of_the_bedded_pack = parseFloat(convert(newValue)
          .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
          .to('C'))
          .toDecimal(newValue.countDecimals() + 3);
    }

    getWhatIsTheWidthOfTheFeedAlleyMin() {
        if(this.evaluation.getMeasurements() === 'imperial') {
            return 12;
        } else {
            return 3.6;
        }
    }

    getWhatIsTheWidthOfTheFeedAlleyMax() {
        if(this.evaluation.getMeasurements() === 'imperial') {
            return 14;
        } else {
            return 4.3;
        }
    }

    getIsPackExcessivelyWetAroundAccessPoints() {
        return this.assessor.data.is_pack_excessively_wet_around_access_points;
    }

    getAccessPointsDistance() {
        if(this.evaluation.getMeasurements() === 'imperial') {
            return '165 ' + app.$t('ft');
        } else {
            return '50 ' + app.$t('m');
        }
    }

    getIsTheBedStirredToTheProperDepth() {
        return this.assessor.data.is_the_bed_stirred_to_the_proper_depth;
    }

    getProperDepthFirstValue() {
        if(this.evaluation.getMeasurements() === 'imperial') {
            return '4';
        } else {
            return '10';
        }
    }

    getProperDepthSecondValue() {
        if(this.evaluation.getMeasurements() === 'imperial') {
            return '8-12';
        } else {
            return '20-30';
        }
    }

    getProperDepthMeasurements() {
        if(this.evaluation.getMeasurements() === 'imperial') {
            return 'in';
        } else {
            return 'cm';
        }
    }

    getInAerobicBarnsHowFrequentlyIsThePackStirred() {
        return this.assessor.data.in_aerobic_barns_how_frequently_is_the_pack_stirred;
    }

    getTargetHowMuchFreshBeddingIsAdded() {
        return parseFloat(convert(this.idealHowMuchFreshBeddingIsAdded)
                .from('kg')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg')))
            .toDecimal(0);
    }

    getTargetHowMuchFreshBeddingIsAddedAerobicMin() {
        return parseFloat(convert(this.idealHowMuchFreshBeddingIsAddedAerobicMin)
          .from('kg')
          .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg')))
          .toDecimal(0);
    }

    getTargetHowMuchFreshBeddingIsAddedAerobicMax() {
        return parseFloat(convert(this.idealHowMuchFreshBeddingIsAddedAerobicMax)
          .from('kg')
          .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg')))
          .toDecimal(0);
    }

    getHowMuchFreshBeddingIsAdded(roundPoint) {
        if (this.assessor.data.how_much_fresh_bedding_is_added === undefined || this.assessor.data.how_much_fresh_bedding_is_added === null) {
            return this.assessor.data.how_much_fresh_bedding_is_added;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.how_much_fresh_bedding_is_added.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.how_much_fresh_bedding_is_added)
            .from('kg')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg'))
            .toDecimal(roundPoint);
    }

    setHowMuchFreshBeddingIsAdded(newValue) {
        this.assessor.data.how_much_fresh_bedding_is_added = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg'))
                .to('kg'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getIsTheBeddedAreaDemarcatedFromTheConcreteFeedingAreaByABeddingRetainer() {
        return this.assessor.data.is_the_bedded_area_separated_from_the_concrete_feeding_area_by_a_bedding_retainer;
    }

    getIsTheConcreteFeedingAreScrapedAtLeastTwiceADay() {
        return this.assessor.data.is_the_concrete_feeding_area_scraped_at_least_twice_a_day;
    }

    getIsTheAreaWellVentilatedWithFansOverTheBeddedArea() {
        return this.assessor.data.is_the_area_well_ventilated_with_fans_over_the_bedded_area;
    }

    getDoesTheBeddedAreaDrainWell() {
        return this.assessor.data.does_the_bedded_area_drain_well;
    }

    getCanCowsAccessWaterTroughsFromTheBeddedArea() {
        return this.assessor.data.can_cows_access_water_troughs_from_the_bedded_area;
    }

    /**
     * Calculation
     */
    // need to figure out how to proceed with this since bedded pack has smaller weight ranges than what has beeen set in groups
    getTargetWhatIsTheBeddingAreaPerCow() {
        let recommendation
        // The results will be changed. We need to hear back from the client to hear how they want to continue on with the differing recommended bedded areas due to the weight ranges.
        if (this.evaluation.getMeasurements() === 'metric') {
            switch (this.group.getCowSize()) {
                case '408.2-498.9':
                    recommendation = '7.0' // '4.1'
                    break
                case '498.9-589.6':
                    recommendation = '8.4' // '4.3'
                    break
                case '589.6-680.3':
                    recommendation =  '10.2' // '4.1'
                    break
                case '680.3-771':
                    recommendation = '12.1' // '4.3'
                    break
                case '771-861.8':
                    recommendation = '13.9' // '4.6'
                    break
                case '861.8-952.5':
                    recommendation = '15.8' // '5.1'
                    break
                default:
                    recommendation = 0
            }
        } else {
            switch (this.group.getCowSize()) {
                case '408.2-498.9':
                    recommendation = '75.0' // '44.1'
                    break
                case '498.9-589.6':
                    recommendation = '90.0' // '46.3'
                    break
                case '589.6-680.3':
                    recommendation = '110.0' // '44.1'
                    break
                case '680.3-771':
                    recommendation = '130.0' // '46.3'
                    break
                case '771-861.8':
                    recommendation = '150.0' // '49.5'
                    break
                case '861.8-952.5':
                    recommendation =  '170.0' // '53.4'
                    break
                default:
                    recommendation = 0
            }
        }


        return recommendation
    }


};

export default BeddedPack;