import Cover from '@/assets/img/Cover.png';
import NewCover from '@/assets/img/Cover.png';
import { calculateAspectRatioFit } from '@/mixin/convertImgUrlToBase64';
import { store } from '@/store/store';

export const generateTitle = self => {
  let slide = self.pptx.addSlide('Title Slide');
  return (self.generateTitle = function(report_title, report_date, user) {
    let evaluation = null;
    if (self.app.evaluations) {
      evaluation = self.app.evaluations.filter(
        item => item.name === report_title
      )[0];
    }
    // function getDate(timestamp) {
    //   let date = new Date(timestamp);
    //   var monthNames = [
    //     app.$t('Jan.'),
    //     app.$t('Feb.'),
    //     app.$t('Mar.'),
    //     app.$t('Apr.'),
    //     app.$t('May'),
    //     app.$t('Jun.'),
    //     app.$t('Jul.'),
    //     app.$t('Aug.'),
    //     app.$t('Sep.'),
    //     app.$t('Oct.'),
    //     app.$t('Nov.'),
    //     app.$t('Dec.'),
    //   ];
    //   var day = date.getDate();
    //   var monthIndex = date.getMonth();
    //   var year = date.getFullYear();
    //   return day + ' ' + monthNames[monthIndex] + ' ' + year;
    // }

    slide.addText(report_title, {
      x: 3,
      y: 7.48,
      w: 5.11,
      h: 1.2,
      color: '0000FF',
      fontSize: 30,
      fontFace: 'Arial',
      bold: true,
      align: 'right',
    });

    slide.addText(app.$t('Evaluation Report'), {
      x: 3,
      y: 9,
      w: 5.11,
      color: '000000',
      fontSize: 20,
      fontFace: 'Arial',
      align: 'right',
    });

    slide.addText(store.getters.getDate(new Date()), {
      x: 3,
      y: 10,
      h: 0.39,
      w: 5.11,
      align: 'right',
      color: '000000',
      fontSize: 20,
      fontFace: 'Arial',
    });

    const MAX_HEIGHT = 1.5;
    const MAX_WIDTH = 2.3;
    let { brandingImageHeight, brandingImageWidth, brandingImageBase64 } =
      evaluation.clientData || {};
    if (!brandingImageBase64) {
      ({ brandingImageHeight, brandingImageWidth, brandingImageBase64 } = user);
    }

    const { w, h } = calculateAspectRatioFit(
      brandingImageHeight,
      brandingImageWidth,
      MAX_WIDTH,
      MAX_HEIGHT
    );

    if (brandingImageBase64) {
      // calculate the size of branding image based on the aspect ratio of the branding image
      let imageInfo = {
        path: brandingImageBase64,
        x: 5.7,
        y: 5.5,
      };

      if (
        brandingImageHeight !== undefined &&
        brandingImageWidth !== undefined
      ) {
        if (brandingImageWidth / brandingImageHeight < MAX_WIDTH / MAX_HEIGHT) {
          imageInfo.w = (MAX_HEIGHT * brandingImageWidth) / brandingImageHeight;
          imageInfo.h = MAX_HEIGHT;
          imageInfo.x += MAX_WIDTH - imageInfo.w;
        } else {
          imageInfo.w = MAX_WIDTH;
          imageInfo.h = (MAX_WIDTH * brandingImageHeight) / brandingImageWidth;
        }
      }

      brandingImageBase64 && slide.addImage(imageInfo);
    }

    // let { brandingImageHeight, brandingImageWidth, brandingImageBase64 } =
    //   evaluation.clientData || {};
    // if (!brandingImageBase64) {
    //   ({ brandingImageHeight, brandingImageWidth, brandingImageBase64 } = user);
    // }
    // let maxHeight = 0.75;
    // let maxWidth = 1.15;
    // let imageInfo = {
    //   data: brandingImageBase64,
    //   x: 0.5,
    //   y: 7,
    // };

    // if (brandingImageHeight !== undefined && brandingImageWidth !== undefined) {
    //   if (brandingImageWidth / brandingImageHeight < maxWidth / maxHeight) {
    //     imageInfo.w = (maxHeight * brandingImageWidth) / brandingImageHeight;
    //     imageInfo.h = maxHeight;
    //   } else {
    //     imageInfo.w = maxWidth;
    //     imageInfo.h = (maxWidth * brandingImageHeight) / brandingImageWidth;
    //   }
    // }

    // slide.addImage(imageInfo);

    slide.addShape(this.pptx.shapes.LINE, {
      x: 4.5,
      y: 9.6,
      w: 3.5,
      h: 0,
      line: 'dddddd',
      lineSize: 1,
    });

    return true;
  });
};
