<template>
  <div
    class="dashboard-typed-groups col-md-12 row align-items-end text-center align-content-start"
  >
    <div
      class="dashboard-typed-groups__heading row no-gutters w-100 align-items-start mb-md-4"
    >
      <div class="col-24 col-md-12 text-left">
        <h2 class="h2 d-inline-block mb-0">
          {{ translateType(groupType) }}
          {{ 'Cow Evaluation' | translate }}
        </h2>
      </div>
      <div v-if="groups.length > 1" class="col-24 col-md-12 d-flex justify-content-md-end my-2 my-md-0">
        <select
          v-model="groupId"
          class="form-control dashboard__select"
        >
          <option :value="null" disabled>{{ 'Select' | translate }}</option>
          <option :value="idx" v-for="(group, idx) in groups" :key="group.id">
            {{ group.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-12">
      <label class="mb-1">
        {{
            groupType === 'fresh'
              ? '% Cows Rumen Fill < 3'
              : '% Cows Rumen Fill < 4' | translate
          }}
      </label>
      <input
        disabled
        :value="groupAssessor.cows_rumen_fill"
        class="form-control text-center border-2"
        :class="classInput(groupAssessor.cows_rumen_fill)"
      />
    </div>
    <div class="col-md-12">
      <label class="mb-1">
        {{ '% of Cows with Manure Score < 3' | translate }}
      </label>
      <input
        disabled
        :value="groupAssessor.cows_manure_score"
        class="form-control text-center border-2"
        :class="classInput(groupAssessor.cows_manure_score)"
      />
    </div>
    <div class="col-md-12">
      <label class="mb-1">
        {{ '% of Cows with BCS ≤2.5 or ≥3.75, 1st lact' | translate }}
      </label>
      <input
        disabled
        :value="groupAssessor.body_condition_score_1"
        class="form-control text-center border-2"
        :class="classInput(groupAssessor.body_condition_score_1)"
      />
    </div>
    <div class="col-md-12">
      <label class="mb-1">
        {{ '% of Cows with BCS ≤2.5 or ≥3.75, ≥2nd lact' | translate }}
      </label>
      <input
        disabled
        :value="groupAssessor.body_condition_score_2"
        class="form-control text-center border-2"
        :class="classInput(groupAssessor.body_condition_score_2)"
      />
    </div>
    <div class="col-md-12">
      <label class="mb-1">
        {{ 'Refusal %' | translate }}
      </label>
      <input
        disabled
        :value="groupAssessor.refusal"
        class="form-control text-center border-2"
        :class="classInputRefusal(groupAssessor.refusal)"
      />
    </div>

    <template v-if="typeIsFresh">
      <div class="col-md-12">
        <label class="mb-1">
          {{ 'Feed Available after Milking?' | translate }}
        </label>
        <input
          disabled
          :value="
            translateBoolValues(groupAssessor.is_feed_available_af_milking)
          "
          class="form-control text-center border-2"
          :class="classInput(groupAssessor.is_feed_available_af_milking)"
        />
      </div>
      <div class="col-md-12">
        <label class="mb-1">
          {{ 'Checks Done After Milking?' | translate }}
        </label>
        <input
          disabled
          :value="translateBoolValues(groupAssessor.checks_af_milking)"
          class="form-control text-center border-2"
          :class="classInput(groupAssessor.checks_af_milking)"
        />
      </div>
      <div class="col-md-12">
        <label class="mb-1">
          {{ 'Time Spent Checking Fresh Cows (hr)' | translate }}
        </label>
        <input
          disabled
          :value="$t(comparativeValues.hr[groupAssessor.time_checking_hr])"
          class="form-control text-center border-2"
          :class="classTimeChecking(groupAssessor.time_checking_hr)"
        />
      </div>
      <div class="col-md-12">
        <label class="mb-1">
          {{ 'Check Appetite?' | translate }}
        </label>
        <input
          disabled
          :value="translateBoolValues(groupAssessor.is_appetite_checked)"
          class="form-control text-center border-2"
          :class="classInput(groupAssessor.is_appetite_checked)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { comparativeValues, transitionConstants } from '@/libs/Classes/constants/transition';
import { translationsMixin } from '@/mixins/translations.mixin.js';

export default {
  name: 'DashboardTypedGroups',
  inject: ['assessorType'],
  mixins: [translationsMixin],
  props: {
    groupType: String,
    groups: Array,
  },
  data() {
    return {
      groupId: 0,
      comparativeValues,
    };
  },
  created () {
  },
  computed: {
    typeIsFresh() {
      return this.groupType === 'fresh';
    },
    groupAssessor() {
      return this.groups[this.groupId].assessors[this.assessorType].data;
    },
    selectedGroup() {
      return this.groups[this.groupId];
    },
  },
  methods: {
    classInput(value, field = null) {
      let min = 15;
      let max = 25;

      if (field && transitionConstants[`${field}_min`] && transitionConstants[`${field}_max`]) {
        min = transitionConstants[`${field}_min`];
        max = transitionConstants[`${field}_max`];
      }

      if (value && value == Number(value)) {
        return value < min
          ? 'border--success'
          : value > max
            ? 'border--danger'
            : 'border--warning';
      } else if (value) {
        return value === 'yes'
          ? 'border--success'
          : value === 'no'
          ? 'border--danger'
          : 'border--warning';
      }
    },


    classInputRefusal(value) {
      const min = transitionConstants.refusal_min || 3;
      const max = transitionConstants.refusal_max || 5;

      const valueAsNumber = Number.parseFloat(value);

      return valueAsNumber < min
        ? 'border--danger'
        : valueAsNumber > max
          ? 'border--success'
          : 'border--warning';
    },


    classTimeChecking(value) {
      switch (value) {
        case 'more-3':
          return 'border--danger';
        case 'equal-2':
          return 'border--warning';
        case 'less-1':
          return 'border--success';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../_dashboard.scss';
</style>
