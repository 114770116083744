var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container main-container main-container--hygiene view"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-24"},[_c('h2',{staticClass:"h2 d-inline-block"},[_vm._v(" "+_vm._s(_vm.$t('Tell Us About Your Biosecurity Procedures'))+" ")])])]),_c('hr',{staticClass:"mb-lg-4"}),_c('question',{attrs:{"initial-value":_vm.assessor.data.are_any_cows_heifers_calves_moving_on},on:{"change":function($event){_vm.assessor.data.are_any_cows_heifers_calves_moving_on = $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t( 'Are any cows/heifers/calves moving on to the main farm coming from a property where hygiene practices and previous infectious disease presence is unknown?' ))+" ")])])]),_c('hr'),_c('question',{attrs:{"initial-value":_vm.assessor.data.is_the_dairy_bringing_any_cattle_onto_the_dairy},on:{"change":function($event){_vm.assessor.data.is_the_dairy_bringing_any_cattle_onto_the_dairy =
        $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t( 'Is the dairy bringing ANY cattle (purchased or from custom heifer grower) on to the dairy?' ))+" ")])])]),_c('hr'),_c('question',{attrs:{"initial-value":_vm.assessor.data.are_cattle_from_the_dairy_shown_at_fairs_and_cattle_shows},on:{"change":function($event){_vm.assessor.data.are_cattle_from_the_dairy_shown_at_fairs_and_cattle_shows =
        $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t('Are cattle from the dairy shown at fairs and cattle shows?'))+" ")])])]),_c('hr'),_c('question',{attrs:{"initial-value":_vm.assessor.data
        .are_returning_cattle_or_purchased_cattle_footbathed_before_commingling_with_the_main_herd},on:{"change":function($event){_vm.assessor.data.are_returning_cattle_or_purchased_cattle_footbathed_before_commingling_with_the_main_herd =
        $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t( 'Are returning cattle or purchased cattle footbathed before co-mingling with the main herd?' ))+" ")])])]),_c('hr'),_c('question',{attrs:{"initial-value":_vm.assessor.data.is_the_hoof_trimmers_equipment_always_clean},on:{"change":function($event){_vm.assessor.data.is_the_hoof_trimmers_equipment_always_clean = $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t( 'Is the hoof trimmer’s equipment always clean (manure free) before arrival on the dairy?' ))+" ")])])]),_c('hr'),_c('question',{attrs:{"initial-value":_vm.assessor.data.does_the_herd_test_for_bvd_pi_cattle},on:{"change":function($event){_vm.assessor.data.does_the_herd_test_for_bvd_pi_cattle = $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t('Does the herd test for BVD PI cattle?'))+" ")])])]),(_vm.assessor.data.does_the_herd_test_for_bvd_pi_cattle === 1)?_c('hr'):_vm._e(),(_vm.assessor.data.does_the_herd_test_for_bvd_pi_cattle === 1)?_c('question',{attrs:{"initial-value":_vm.assessor.data.have_any_pi_cattle_been_identified_in_the_last_12_months},on:{"change":function($event){_vm.assessor.data.have_any_pi_cattle_been_identified_in_the_last_12_months =
        $event.value}}},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"label d-inline"},[_vm._v(" "+_vm._s(_vm.$t('Have any PI cattle been identified in the last 12 months?'))+" ")])])]):_vm._e(),_c('hr'),_c('div',{staticClass:"row my-0"},[_c('div',{staticClass:"col-md-12"},[_c('personal-notes',{model:{value:(_vm.assessor.data.personal_notes),callback:function ($$v) {_vm.$set(_vm.assessor.data, "personal_notes", $$v)},expression:"assessor.data.personal_notes"}})],1),_c('div',{staticClass:"col-md-12"},[_c('presentation-notes',{model:{value:(_vm.assessor.data.presentation_notes),callback:function ($$v) {_vm.$set(_vm.assessor.data, "presentation_notes", $$v)},expression:"assessor.data.presentation_notes"}})],1)]),_c('div',{staticClass:"row mx-0 mt-4 justify-content-center justify-content-md-start center align-items-center"},[_c('photoUpload',{attrs:{"assessorId":6,"assessor":_vm.assessor}})],1),_c('hr',{staticClass:"mb-3 mb-md-5 mt-3 mt-md-5"}),_c('div',{staticClass:"row misc-bottom"},[_c('div',{staticClass:"col-md-12 flex-sm-row-reverse"},[_c('a',{staticClass:"btn btn-secondary btn-full--sm mb-2 mt-md-0",attrs:{"href":"javascript:history.go(-1)"}},[_vm._v(_vm._s(_vm._f("translate")('Back')))])]),_c('div',{staticClass:"col-md-12"},[(_vm.user && _vm.user.role !== 'data_entry')?_c('router-link',{staticClass:"btn btn-primary btn-full--sm pull-right",attrs:{"to":'/reports'}},[_vm._v(" "+_vm._s(_vm._f("translate")('Reports'))+" ")]):(
          _vm.user && _vm.user.role === 'data_entry' && !_vm.$store.state.offline
        )?_c('button',{staticClass:"btn btn-primary btn-full--sm pull-right",attrs:{"data-toggle":"modal","data-target":['#requestSent-' + _vm.user.id]},on:{"click":function($event){return _vm.requestReport(_vm.evaluation.name)}}},[_vm._v(" "+_vm._s(_vm._f("translate")('Request Report'))+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.requestReportLoader),expression:"requestReportLoader"}],staticClass:"loader ml-1"})]):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.successMessage),expression:"successMessage"}],staticClass:"alert alert-primary mt-3 mb-0",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm._f("translate")('Your request has been sent!'))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],staticClass:"alert alert-danger mt-3 mb-0",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm._f("translate")('An error has occurred during your request!'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }