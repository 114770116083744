import { mapActions } from "vuex";
import {
  GET_COMPANIES,
  GET_SIGN_IN_USER,
  GET_EVALUATIONS,
  GET_CLIENTS,
  SET_USERS,
  GET_FETCH_BASE64,
  SET_CLIENTS,
  SET_EVALUATIONS,
  SET_UPDATE_ME,
} from '../store/types';
import {cloneDeep, get} from 'lodash'
import { URLS } from "../constants";
import axios from '../../Axios.js';
export default {

  methods:{
    ...mapActions('company', [GET_COMPANIES]),
    ...mapActions('clients', [GET_CLIENTS]),
    ...mapActions('evaluations', [GET_EVALUATIONS]),
    ...mapActions('medias', [GET_FETCH_BASE64]),

    async fetchUserAssessorsMediasBase64(user) {
      const { medias } = user;
      const clonedMedias = cloneDeep(medias);

      const base64Imgs = [];
      for (const media of Object.values(medias)) {
        try {
          // if file doesn't have the the fileName, don't fetch it.
          if (!media || !media.fileName) continue;

          base64Imgs.push(await this[GET_FETCH_BASE64](media.fileName));
        } catch (error) {
          console.debug(
            'getting an errror while fetching image userId: ',
            user._id
          );
          continue;
        }
      }

      base64Imgs.forEach(({ id, base64 }) => {
        clonedMedias[id] = { ...clonedMedias[id], base64 };
      });
      return clonedMedias;
    },

    async populateMediasIntoAllUsers(users) {
      console.log('populating medias on all users', users);
      // const cloneUsers = cloneDeep(users);
      // populate the images in to the user medias
      const clonedUsers = [];
      for (const user of users) {
        // if user don't have medias
        if (!user.medias || !Object.keys(user.medias).length) {
          clonedUsers.push(user);
          continue;
        }

        // otherwise we will fetch all the images base64 and update the medias into user object
        const medias = await this.fetchUserAssessorsMediasBase64(user);
        user.medias = medias;
        clonedUsers.push(user);
      }
      this.$store.commit(`users/${SET_USERS}`, clonedUsers);
      return clonedUsers;
    },

    async pupulateBase64FromImgName(array, gettingPath, settingPath) {
      console.log('populate base 64', gettingPath, settingPath, array);
      // const clonedArray = cloneDeep(array);
      const resultArray = [];
      for (const item of array) {
        try {
          // const photoLink = item.clientPicture || item.profilePicture;
          const photoLink = get(item, gettingPath, settingPath);
          if (!photoLink) {
            resultArray.push(item);
            continue;
          }
          const { base64 } = await this[GET_FETCH_BASE64](photoLink);
          if(settingPath){
            item[settingPath].brandingImageBase64 = base64;
          }
          else {
            item.brandingImageBase64 = base64;
          }
          resultArray.push(item);
        } catch (error) {
          resultArray.push(item);
          console.log(error);
          continue;
        }
      }
      console.log({ resultArray });
      return resultArray;
    },
    async populateLoggedInUserMedias() {
      let base64 = this.user.brandingImageBase64;

      const {data:{data: me} = {}} = await axios.get(URLS.ME)
      console.log({me});
      const medias = await this.fetchUserAssessorsMediasBase64(me || this.user);
      if (this.user.profilePicture) {
        ({ base64 } = await this[GET_FETCH_BASE64](this.user.profilePicture));
      }

      this.$store.commit(`users/${SET_UPDATE_ME}`, {
        medias,
        brandingImageBase64: base64,
      });
    },
    async fetchImgsForClientEvaluationsAndClient(entities) {
      console.log({entities});
      const [evaluations, clients, users] = entities;
      // populate the client photo, the client is embeded in the evalutions.
      if(evaluations && evaluations.lenth){
        const mediaPopulatedEvaluations = await this.pupulateBase64FromImgName(
          evaluations,
          'clientData.brandingImage',
          'clientData'
          );
          
          this.$store.commit(
            `evaluations/${SET_EVALUATIONS}`,
            mediaPopulatedEvaluations
            );
        }

      // this function will fetch the all the users profile and assessor images
      if(users && users.lenth){
        this.pupulateBase64FromImgName(users, 'profilePicture').then(
          this.populateMediasIntoAllUsers
          );
        }

      // fetching the each client img.
      if(clients && clients.lenth){
        const mediasPupulatedClietns = await this.pupulateBase64FromImgName(
          clients,
          'clientPicture'
          );
          
          this.$store.commit(`clients/${SET_CLIENTS}`, mediasPupulatedClietns);
        }
    },

  }


}