/**
 * The Holding Area Assessor focuses on evaluation time out of the pen, foot trauma and heat stress as it relates to controlling lameness
 * on the dairy.
 */

const convert = require('convert-units');
import Assessor from './Assessor';

class HoldingArea extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;

        this.minHoldingAreaSize = 1.4;
        this.maxTemperatureThatTheSoakersActivate = 21;
    }

    getDoCowsSpendMoreThan3HoursDayOnAverageInTheHoldingArea() {
        return this.assessor.data.do_cows_spend_more_than_3_hours_day_on_average_in_the_holding_area;
    }

    getAreFirstLactationHeifersMixedWithMatureCowsInTheHoldingArea() {
        return this.assessor.data.are_first_lactation_heifers_mixed_with_mature_cows_in_the_holding_area;
    }

    getIsTheHoldingAreaFloorExcessivelySloped() {
        return this.assessor.data.is_the_holding_area_floor_excessively_sloped;
    }

    getIsThereAnySlippingAsTheCowsMoveBetweenEachOtherThatMayTraumatizeTheHoof() {
        return this.assessor.data.is_there_any_slipping_as_the_cows_move_between_each_other_that_may_traumatize_the_hoof;
    }

    getAreRecirculatingFansInTheHoldingArea() {
        return this.assessor.data.are_recirculating_fans_in_the_holding_area;
    }

    getHoldingAreaSize(roundPoint) {
        if (this.assessor.data.holding_area_size === undefined || this.assessor.data.holding_area_size === null) {
            return undefined;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.holding_area_size.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.holding_area_size)
            .from('m2')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm2'))
            .toDecimal(roundPoint);
    }

    setHoldingAreaSize(newValue) {
        if (newValue === '') {
            return this.assessor.data.holding_area_size = null;
        }

        this.assessor.data.holding_area_size = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm2'))
                .to('m2'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getMinHoldingAreaSize() {
        let roundPoint = 1;
        if(this.evaluation.getMeasurements() === 'imperial') {
            roundPoint = 0;
        }
        return convert(this.minHoldingAreaSize)
            .from('m2')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm2'))
            .toDecimal(roundPoint);
    }

    getIdealHoldingAreaSize() {
        return (this.getHoldingAreaSize() / this.getMinHoldingAreaSize()).toDecimal(0);
    }

    getMaxTemperatureThatTheSoakersActivate() {
        return convert(this.maxTemperatureThatTheSoakersActivate)
            .from('C')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
            .toDecimal(0);
    }

    getTemperatureThatTheFansActivate(roundPoint) {
        if (this.assessor.data.fan_temperature_that_the_soakers_activate === undefined || this.assessor.data.fan_temperature_that_the_soakers_activate === null || this.assessor.data.fan_temperature_that_the_soakers_activate === '') {
            return undefined;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.fan_temperature_that_the_soakers_activate.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.fan_temperature_that_the_soakers_activate)
            .from('C')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
            .toDecimal(roundPoint);
    }

    setTemperatureThatTheFansActivate(newValue) {
        if (newValue === '') {
            return this.assessor.data.fan_temperature_that_the_soakers_activate = null;
        }

        this.assessor.data.fan_temperature_that_the_soakers_activate = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
                .to('C'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getSoakersTemperatureThatTheSoakersActivate(roundPoint) {
        if (this.assessor.data.soakers_temperature_that_the_soakers_activate === undefined || this.assessor.data.soakers_temperature_that_the_soakers_activate === null) {
            return undefined;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.soakers_temperature_that_the_soakers_activate.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.soakers_temperature_that_the_soakers_activate)
            .from('C')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
            .toDecimal(roundPoint);
    }

    setSoakersTemperatureThatTheSoakersActivate(newValue) {
        this.assessor.data.soakers_temperature_that_the_soakers_activate = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
                .to('C'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getFanWidth(fan_width = "fan_width") {
      if (!this.assessor.data[fan_width]) {
        return null;
      }
  
      return convert(this.assessor.data[fan_width])
        .from("cm")
        .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), "cm"))
        .toDecimal(0);
    }

    setFanWidth(newValue, fan_width = "fan_width") {
      if (newValue < 1) {
        return this.assessor.data[fan_width] = null;
      }
  
      return this.assessor.data[fan_width] = parseFloat(convert(newValue)
      .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
      .to('cm'))
      .toDecimal(newValue.countDecimals() + 3);
    }
  
    getNumberOfFans(number_of_fans = "number_of_fans") {
      if (this.assessor.data[number_of_fans] === "") {
        return null;
      }
      return this.assessor.data[number_of_fans];
    }
  
    isNumberOfFansAvailable(key) {
      for (let i = 1; i < 4; i++) {
        if (this.assessor.data[`${key}_${i}`]) return true;
        continue;
      }
      return false;
    }
  
    getAllNumOfFans() {
      let numOfFans = 0;
      for (let i = 1; i < 4; i++) {
        const fans = this.assessor.data[`number_of_fans_${i}`];
        if (fans > 0) numOfFans += fans;
        continue;
      }
      return numOfFans
    }
  
    getCurrentCFM() {
      let result = 0;
      if (
        this.getNumberOfFans("number_of_fans_1") &&
        this.getNumberOfFans("number_of_fans_1") > 0 &&
        this.getGroupPenSizeInHoldingArea() &&
        this.getGroupPenSizeInHoldingArea() > 0
      ) {
        result += Math.round(
          (this.getNumberOfFans("number_of_fans_1") *
            this.getCalcFanWidth("fan_width_1")) /
            this.getGroupPenSizeInHoldingArea()
        );
      }
      if (
        this.getNumberOfFans("number_of_fans_2") &&
        this.getNumberOfFans("number_of_fans_2") > 0 &&
        this.getGroupPenSizeInHoldingArea() &&
        this.getGroupPenSizeInHoldingArea() > 0
      ) {
        result += Math.round(
          (this.getNumberOfFans("number_of_fans_2") *
            this.getCalcFanWidth("fan_width_2")) /
            this.getGroupPenSizeInHoldingArea()
        );
      }
      if (
        this.getNumberOfFans("number_of_fans_3") &&
        this.getNumberOfFans("number_of_fans_3") > 0 &&
        this.getGroupPenSizeInHoldingArea() &&
        this.getGroupPenSizeInHoldingArea() > 0
      ) {
        result += Math.round(
          (this.getNumberOfFans("number_of_fans_3") *
            this.getCalcFanWidth("fan_width_3")) /
            this.getGroupPenSizeInHoldingArea()
        );
      }
      return result;
    }
  
    getCalcFanWidth(fan_width = "fan_width") {
      let result;
  
      const input = parseFloat(this.assessor.data[fan_width]);
      const imperialMeasurements =
        this.evaluation.getMeasurements() === "imperial";
      if (input >= 1 && input < 40.64) {
        result = imperialMeasurements ? 1790 : 3043;
      } else if (input >= 40.64 && input < 45.72) {
        result = imperialMeasurements ? 2920 : 4964;
      } else if (input >= 45.72 && input < 60.96) {
        result = imperialMeasurements ? 4200 : 7140;
      } else if (input >= 60.96 && input < 91.44) {
        result = imperialMeasurements ? 5600 : 9520;
      } else if (input >= 91.44 && input < 121.92) {
        result = imperialMeasurements ? 11000 : 18700;
      } else if (input >= 121.92 && input < 127) {
        result = imperialMeasurements ? 20000 : 34000;
      } else if (input >= 127 && input < 129.54) {
        result = imperialMeasurements ? 22000 : 37400;
      } else if (input >= 129.54 && input < 137.16) {
        result = imperialMeasurements ? 24000 : 40800;
      } else if (input >= 137.16) {
        result = imperialMeasurements ? 27000 : 45900;
      } else {
        result = 0;
      }
  
      return result;
    }
  
    getRecommendedCFM() {
        if (this.evaluation.getMeasurements() === 'metric') {
            return 1700
        }
        return 1000
    }

    getSoakersDuration() {
        if (this.assessor.data.soakers_duration === '') {
            return null;
        }
        return this.assessor.data.soakers_duration;
    }

    getIsTheCrowdGateUsedAppropriately() {
        return this.assessor.data.is_the_crowd_gate_used_appropriately;
    }

    getIsSLargeProportionMoreThan30OfCowsWithTheirHeadsUpTatherThanDownLookingAtTheGround() {
        return this.assessor.data.is_a_large_proportion_more_than_30_of_cows_with_their_heads_up_rather_than_down_looking_at_the_ground;
    }

    getSoakersFrequency() {
        if (this.assessor.data.soakers_frequency === '') {
            return null;
        }
        return this.assessor.data.soakers_frequency;
    }

    // getFrequencyRange() {
    //     console.log('HIT getFrequencyRange', this.getSoakersTemperatureThatTheSoakersActivate());
    //     if (this.evaluation.getMeasurements() === 'metric' && this.getSoakersTemperatureThatTheSoakersActivate() < 27) {
    //         console.log('HIT first');
    //         return this.app.$t('between') + this.app.$t(' ') + '21˚ - 26˚';
    //     } else if (this.evaluation.getMeasurements() === 'imperial' && this.getSoakersTemperatureThatTheSoakersActivate() < 80) {
    //         console.log('HIT second');
    //         return this.app.$t('between') + this.app.$t(' ') + '70˚ - 80˚';
    //     } else if ((this.evaluation.getMeasurements() === 'metric' && this.getSoakersTemperatureThatTheSoakersActivate() > 27) && (this.evaluation.getMeasurements() === 'metric' && this.getSoakersTemperatureThatTheSoakersActivate() < 32)) {
    //         console.log('HIT third');
    //         return this.app.$t('between') + this.app.$t(' ') + '27˚ - 32˚';
    //     } else if ((this.evaluation.getMeasurements() === 'imperial' && this.getSoakersTemperatureThatTheSoakersActivate() > 80) && (this.evaluation.getMeasurements() === 'imperial' && this.getSoakersTemperatureThatTheSoakersActivate() < 90)) {
    //         console.log('HIT fourth');
    //         return this.app.$t('between') + this.app.$t(' ') + '81˚ - 90˚';
    //     } else if (this.evaluation.getMeasurements() === 'metric' && this.getSoakersTemperatureThatTheSoakersActivate() > 32) {
    //         console.log('HIT fifth');
    //         return '> 32˚';
    //     } else {
    //         console.log('HIT sixth');
    //         return '> 90˚';
    //     }
    // }

    // getFrequencyFeedback() {
    //     console.log('measurements', this.evaluation.getMeasurements());

    //     if ((this.evaluation.getMeasurements() === 'metric' && this.getSoakersTemperatureThatTheSoakersActivate() < 26) || (this.evaluation.getMeasurements() === 'imperial' && this.getSoakersTemperatureThatTheSoakersActivate() < 70)) {
    //         return 'For Temperatures ' + this.getFrequencyRange() + this.app.$t(', the duration should be 1-2 min, with a frequency of every 15 min.');
    //     } else if ((this.evaluation.getMeasurements() === 'metric' && this.getSoakersTemperatureThatTheSoakersActivate() > 27 && this.evaluation.getMeasurements() === 'metric' && this.getSoakersTemperatureThatTheSoakersActivate() < 32) || (this.evaluation.getMeasurements() === 'imperial' && this.getSoakersTemperatureThatTheSoakersActivate() > 80 && this.evaluation.getMeasurements() === 'imperial' && this.getSoakersTemperatureThatTheSoakersActivate() < 90)) {
    //         return 'For Temperatures ' + this.getFrequencyRange() + this.app.$t(', the duration should be 1-2 min, with a frequency of every 10 min.');
    //     } else {
    //         return 'For Temperatures ' + this.getFrequencyRange() + this.app.$t(', the duration should be 1-2 min, with a frequency of every 5 min.');
    //     }
    // }

    getGroupPenSizeInHoldingArea() {
        if (this.assessor.data.group_pen_size_in_holding_area === '') {
            return null;
        }
        return this.assessor.data.group_pen_size_in_holding_area;
    }

    getAreSoakersMistersInTheHoldingArea() {
        return this.assessor.data.are_soakers_misters_in_the_holding_area;
    }

    getIsThereShadeOverTheHoldingArea() {
        return this.assessor.data.is_there_shade_over_the_holding_area;
    }

};

export default HoldingArea;