<template>
	<span>
		<span v-if="status == 0" :class="['status status--notstarted status--border-' + border]"></span>
		<span v-else-if="status == 1" :class="['status status--started status--border-' + border]"></span>
		<span v-else-if="status == 2" :class="['status status--completed status--border-' + border]"></span>
		<span v-else :class="['status status--error status--border-' + border]"></span>
	</span>
</template>

<script>
export default {
  computed: {
    status: function () {
      if (this.assessor === undefined || this.assessor.data === undefined) {
        return 0;
      } else if (this.assessor.isReported === true) {
        return 2;
      } else {
        return 1;
      }
    },
  },
  props: [
    'assessor',
    'border'
  ]
}
</script>
