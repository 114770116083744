<template>
  <div class="container main-container main-container--hygiene view">
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">
          {{ $t('Tell Us About Your Heat Abatement Procedures') }}
        </h2>
        <modal-info :value="'heat-abatement'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ $t('Heat Abatement') }}
            </h2>
            <p class="px-3">
              {{
                'For more information about Heat Abatement, click the link to learn more from'
                  | translate
              }}
              <a
                :href="
                  $t('https://library.zinprofirststep.com/heat-abatement/')
                "
                @click="$goToLameness($t('/heat-abatement/'), $event)"
                target="_blank"
                >{{ 'FirstStep Resources' | translate }}</a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ $t('Cancel') }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </div>

    <hr class="mb-4" />

    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">{{ $t('Water') }}</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <label class="mb-1">{{ $t('Group/Pen Size') }}</label>
        <input
          :value="groupClass.getGroupSize()"
          type="number"
          inputmode="numeric"
          @keypress="$isNumber"
          class="form-control"
          readonly
        />
      </div>

      <div class="col-lg-12">
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{
            $t('Accessible Water Trough Perimeter in Pen') +
              ' (' +
              $t($t($getEquivalentUnit(evaluation.data.measurements, 'cm'))) +
              ')'
          }}</label>
          <input
            v-model.number="converted_accessible_water"
            type="number"
            inputmode="numeric"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.is_drinking_water_readily_available_to_cows_exiting_the_milking_parlor =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_drinking_water_readily_available_to_cows_exiting_the_milking_parlor
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Is drinking water readily available to cows exiting the milking parlor?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_area_around_the_water_trough_free_of_mud_stones_etc =
          $event.value
      "
      :initial-value="
        assessor.data.is_the_area_around_the_water_trough_free_of_mud_stones_etc
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t('Is the area around the water trough free of mud, stones, etc?')
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <div class="row">
      <div class="col-lg-24 mt-3">
        <h2 class="h2 mb-lg-3 d-inline-block">{{ $t('Fans') }}</h2>
      </div>

      <div class="col-lg-24">
        <h3 class="h3 d-inline-block">{{ $t('Feed Bunk Area') }}</h3>
      </div>

      <div class="col-lg-24">
        <hr />

        <question-three
          @change="
            assessor.data.are_fans_located_in_the_feed_bunk_area = $event.value
          "
          :initial-value="assessor.data.are_fans_located_in_the_feed_bunk_area"
        >
          <div class="mb-1">
            <label class="label d-inline">
              {{ $t('Are fans located in the Feed Bunk Area?') }}
            </label>
          </div>
        </question-three>

        <hr
          v-show="assessor.data.are_fans_located_in_the_feed_bunk_area === 1"
        />

        <question
          v-show="assessor.data.are_fans_located_in_the_feed_bunk_area === 1"
          @change="
            assessor.data.feed_bunk_area_are_hvls_fans_used = $event.value
          "
          :initial-value="assessor.data.feed_bunk_area_are_hvls_fans_used"
        >
          <div class="mb-1">
            <label class="label d-inline">
              {{ $t('Are HVLS Fans used (high volume, low speed)?') }}
            </label>
          </div>
        </question>
        <hr />
      </div>
    </div>

    <div
      v-show="assessor.data.are_fans_located_in_the_feed_bunk_area === 1"
      class="row"
    >
      <div class="col-lg-9">
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{
            $t('Temperature Fans Activate (°') +
              $t($getEquivalentUnit(evaluation.data.measurements, 'C')) +
              ')'
          }}</label>
          <input
            v-model.number="converted_feed_bunk_area_temperature_fans_activate"
            type="number"
            inputmode="numeric"
            class="form-control"
            @keypress="$isNumber"
          />
        </div>
      </div>

      <div class="col-lg-8">
        <label class="mb-1">{{
          $t('Fan Diameter (') +
            $t($t($getEquivalentUnit(evaluation.data.measurements, 'cm'))) +
            ')'
        }}</label>
         <input
          v-model.number="converted_feed_bunk_area_fan_diameter"
          type="number"
          inputmode="numeric"
          class="form-control"
          @keypress="$isNumber($event)"
        />
        <!-- <select
          name="feed_bunk_area_fan_diameter"
          v-model.number="assessor.data.feed_bunk_area_fan_diameter"
          class="form-control"
        >
          <option :value="null" disabled>{{ 'Select' | translate }}</option>
          <option
            v-for="(fanDiameter, index) in fanDiameters"
            :value="fanDiameter.value"
            :key="index"
            >{{ fanDiameter.label }}</option
          >
        </select> -->
      </div>

      <div class="col-lg-7">
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{
            $t('Spacing (') +
              $t($getEquivalentUnit(evaluation.data.measurements, 'm')) +
              ')'
          }}</label>
          <input
            v-model.number="converted_feed_bunk_area_spacing"
            type="number"
            inputmode="numeric"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <hr v-show="assessor.data.are_fans_located_in_the_feed_bunk_area === 1" />

    <div class="row mt-2">
      <div class="col-lg-24 mt-3">
        <h3 class="h3 d-inline-block">{{ $t('Resting Area/Paddock') }}</h3>
      </div>

      <div class="col-lg-24">
        <hr />

        <question-three
          @change="
            assessor.data.are_fans_located_in_the_resting_area_paddock =
              $event.value
          "
          :initial-value="
            assessor.data.are_fans_located_in_the_resting_area_paddock
          "
        >
          <div class="mb-1">
            <label class="label d-inline">
              {{ $t('Are fans located in the resting area/paddock?') }}
            </label>
          </div>
        </question-three>

        <hr
          v-show="
            assessor.data.are_fans_located_in_the_resting_area_paddock === 1
          "
        />

        <question
          v-show="
            assessor.data.are_fans_located_in_the_resting_area_paddock === 1
          "
          @change="
            assessor.data.resting_area_paddock_are_hvls_fans_used = $event.value
          "
          :initial-value="assessor.data.resting_area_paddock_are_hvls_fans_used"
        >
          <div class="mb-1">
            <label class="label d-inline">
              {{ $t('Are HVLS Fans used (high volume, low speed)?') }}
            </label>
          </div>
        </question>
        <hr />
      </div>
    </div>

    <div
      v-show="assessor.data.are_fans_located_in_the_resting_area_paddock === 1"
      class="row"
    >
      <div class="col-lg-9">
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{
            $t('Temperature Fans Activate (°') +
              $t($getEquivalentUnit(evaluation.data.measurements, 'C')) +
              ')'
          }}</label>
          <input
            v-model.number="
              converted_resting_area_paddock_temperature_fans_activate
            "
            type="number"
            inputmode="numeric"
            class="form-control"
            @keypress="$isNumber"
          />
        </div>
      </div>

      <div class="col-lg-8">
        <label class="mb-1">{{
          $t('Fan Diameter (') +
            $t($t($getEquivalentUnit(evaluation.data.measurements, 'cm'))) +
            ')'
        }}</label>
        <input
          v-model.number="converted_resting_area_paddock_fan_diameter"
          type="number"
          inputmode="numeric"
          class="form-control"
          @keypress="$isNumber($event)"
        />
      </div>

      <div class="col-lg-7">
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{
            $t('Spacing (') +
              $t($getEquivalentUnit(evaluation.data.measurements, 'm')) +
              ')'
          }}</label>
          <input
            v-model.number="converted_resting_area_paddock_spacing"
            type="number"
            inputmode="numeric"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <hr
      v-show="assessor.data.are_fans_located_in_the_resting_area_paddock === 1"
    />

    <div class="row mt-3">
      <div class="col-lg-24 mt-3">
        <h2 class="h2 d-inline-block">{{ $t('Soakers/Misters') }}</h2>
      </div>

      <div class="col-lg-24">
        <hr />

        <question-three
          @change="
            assessor.data.are_soakers_misters_located_in_the_feed_bunk_area =
              $event.value
          "
          :initial-value="
            assessor.data.are_soakers_misters_located_in_the_feed_bunk_area
          "
        >
          <div class="mb-1">
            <label class="label d-inline">
              {{ $t('Are soakers/misters located in the Feed Bunk Area?') }}
            </label>
          </div>
        </question-three>

        <hr />

        <question-three
          @change="
            assessor.data.are_there_soakers_misters_located_in_the_resting_area_paddock =
              $event.value
          "
          :initial-value="
            assessor.data
              .are_there_soakers_misters_located_in_the_resting_area_paddock
          "
        >
          <div class="mb-1">
            <label class="label d-inline">
              {{
                $t(
                  'Are there soakers/misters located in the Resting Area/Paddock?'
                )
              }}
            </label>
          </div>
        </question-three>

        <hr
          v-show="
            assessor.data
              .are_there_soakers_misters_located_in_the_resting_area_paddock ===
              1
          "
        />

        <question-three
          v-show="
            assessor.data
              .are_there_soakers_misters_located_in_the_resting_area_paddock ===
              1
          "
          @change="
            assessor.data.does_water_droplet_effectively_soak_cows =
              $event.value
          "
          :initial-value="
            assessor.data.does_water_droplet_effectively_soak_cows
          "
        >
          <div class="mb-1">
            <label class="label d-inline">
              {{ $t('Does water droplet effectively soak cows?') }}
            </label>
          </div>
        </question-three>
        <hr
          v-show="
            assessor.data
              .are_there_soakers_misters_located_in_the_resting_area_paddock ===
              1
          "
        />
      </div>
    </div>

    <hr
      v-show="
        assessor.data.are_soakers_misters_located_in_the_feed_bunk_area === 1 &&
          assessor.data
            .are_there_soakers_misters_located_in_the_resting_area_paddock !== 1
      "
    />

    <div class="row">
      <div
        v-show="
          assessor.data.are_soakers_misters_located_in_the_feed_bunk_area ===
            1 ||
            assessor.data
              .are_there_soakers_misters_located_in_the_resting_area_paddock ===
              1
        "
        class="col-lg-8"
      >
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{
            $t('Temperature Soakers/Misters Activate (°') +
              $t($getEquivalentUnit(evaluation.data.measurements, 'C')) +
              ')'
          }}</label>
          <input
            v-model.number="converted_temperature_soakers_misters_activate"
            type="number"
            inputmode="numeric"
            class="form-control"
            @keypress="$isNumber"
          />
        </div>
      </div>

      <div
        v-show="
          assessor.data
            .are_there_soakers_misters_located_in_the_resting_area_paddock === 1
        "
        class="col-lg-8"
      >
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{ $t('Duration (min)') }}</label>
          <input
            v-model.number="assessor.data.duration"
            type="number"
            inputmode="decimal"
            class="form-control"
          />
        </div>
      </div>

      <div
        v-show="
          assessor.data
            .are_there_soakers_misters_located_in_the_resting_area_paddock === 1
        "
        class="col-lg-8"
      >
        <div class="form-group mb-0 mb-sm-1">
          <label class="mb-1">{{ $t('Frequency (min)') }}</label>
          <input
            v-model.number="assessor.data.frequency"
            type="number"
            inputmode="decimal"
            class="form-control"
          />
        </div>
      </div>

      <div
        class="col-lg-24"
        v-show="
          assessor.data
            .are_there_soakers_misters_located_in_the_resting_area_paddock === 1
        "
      >
        <hr />
        <question-three
          @change="
            assessor.data.does_the_frequency_of_soaking_vary_depending_upon_temperature =
              $event.value
          "
          :initial-value="
            assessor.data
              .does_the_frequency_of_soaking_vary_depending_upon_temperature
          "
        >
          <div class="mb-1">
            <label class="label d-inline">
              {{
                $t(
                  'Does the frequency of soaking vary depending upon temperature?'
                )
              }}
            </label>
          </div>
        </question-three>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-lg-24 mt-3">
        <h2 class="h2 d-inline-block">{{ $t('Shade') }}</h2>
      </div>

      <div class="col-lg-24">
        <hr />

        <question-three
          @change="
            assessor.data.is_there_shade_over_the_feed_bunk_area = $event.value
          "
          :initial-value="assessor.data.is_there_shade_over_the_feed_bunk_area"
        >
          <div class="mb-1">
            <label class="label d-inline">
              {{ $t('Is there shade over the Feed Bunk Area?') }}
            </label>
          </div>
        </question-three>

        <hr />

        <question-three
          @change="
            assessor.data.is_there_shade_over_the_resting_area_paddock =
              $event.value
          "
          :initial-value="
            assessor.data.is_there_shade_over_the_resting_area_paddock
          "
        >
          <div class="mb-1">
            <label class="label d-inline">
              {{ $t('Is there shade over the Resting Area/Paddock?') }}
            </label>
          </div>
        </question-three>

        <hr
          v-show="
            assessor.data.is_there_shade_over_the_resting_area_paddock === 1
          "
        />

        <question-three
          v-show="
            assessor.data.is_there_shade_over_the_resting_area_paddock === 1
          "
          @change="
            assessor.data.are_air_cooling_units_such_as_shade_tracker_or_koral_kool_used_below_shade =
              $event.value
          "
          :initial-value="
            assessor.data
              .are_air_cooling_units_such_as_shade_tracker_or_koral_kool_used_below_shade
          "
        >
          <div class="mb-1">
            <label class="label d-inline">
              {{
                $t(
                  'Are air cooling units such as Shade-Tracker or Koral-Kool used below shade?'
                )
              }}
            </label>
          </div>
        </question-three>
        <hr />
      </div>
    </div>

    <div class="row my-0">
      <div class="col-md-12">
        <personal-notes v-model="assessor.data.personal_notes"></personal-notes>
      </div>
      <div class="col-md-12">
        <presentation-notes
          v-model="assessor.data.presentation_notes"
        ></presentation-notes>
      </div>
    </div>

    <div
      class="row mx-0 mt-4 justify-content-center justify-content-md-start center align-items-center"
    >
      <photoUpload :assessorId="16" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

    <div class="row misc-bottom">
      <div class="col-md-24">
        <router-link
          v-if="user && user.role !== 'data_entry'"
          :to="'/reports'"
          class="btn btn-primary btn-full--sm pull-right"
          >{{ 'Reports' | translate }}</router-link
        >
        <button
          v-else-if="
            user && user.role === 'data_entry' && !$store.state.offline
          "
          data-toggle="modal"
          @click="requestReport(evaluation.name)"
          :data-target="['#requestSent-' + user.id]"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Request Report' | translate }}
          <span v-show="requestReportLoader" class="loader ml-1"></span>
        </button>
      </div>
    </div>
    <div
      v-show="successMessage"
      class="alert alert-primary mt-3 mb-0"
      role="alert"
    >
      {{ 'Your request has been sent!' | translate }}
    </div>

    <div
      v-show="errorMessage"
      class="alert alert-danger mt-3 mb-0"
      role="alert"
    >
      {{ 'An error has occurred during your request!' | translate }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import PresentationNotes from './assessor_components/presentation-notes';
import PersonalNotes from './assessor_components/personal-notes';
import Question from './assessor_components/question';
import QuestionThree from './assessor_components/question-three';
import ModalInfo from '@/components/misc/modal-info.vue';
import PhotoUpload from '@/components/misc/photoUpload';
import HygieneHeader from './assessor_components/hygiene-header.vue';
import HeatAbatement from '@/libs/Classes/HeatAbatement.js';
import Evaluation from '@/libs/Classes/Evaluation.js';
import Group from '@/libs/Classes/Group.js';
import convert from 'convert-units';
import { mapState } from 'vuex';
import requestReportMixin from '@/mixins/requestReport.js';

export default {
  components: {
    PersonalNotes,
    PresentationNotes,
    Question,
    QuestionThree,
    HygieneHeader,
    PhotoUpload,
    ModalInfo,
  },
  mixins: [requestReportMixin],

  props: ['assessor', 'group', 'evaluation'],
  data() {
    return {
      heatabatement: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.users.user,
    }),
    fanDiameters() {
      const diameters_in_cm = [
        35.56,
        40.64,
        45.72,
        60.96,
        91.44,
        121.92,
        127,
        129.54,
        137.16,
      ];
      let converted_diameters = [];

      for (let i = 0; i < diameters_in_cm.length; i++) {
        let converted_value = parseInt(
          convert(diameters_in_cm[i])
            .from('cm')
            .to(
              this.$getEquivalentUnit(this.evaluation.data.measurements, 'cm')
            )
            .toFixed(0)
        );

        converted_diameters.push({
          value: diameters_in_cm[i],
          label: converted_value,
        });
      }

      return converted_diameters;
    },
    converted_accessible_water: {
      get: function() {
        return this.heatabatement !== null
          ? this.heatabatement.getAccessibleWaterTroughPerimeterInPen(0)
          : false;
      },
      set: function(newValue) {
        return this.heatabatement.setAccessibleWaterTroughPerimeterInPen(
          newValue
        );
      },
    },
    converted_feed_bunk_area_temperature_fans_activate: {
      get: function() {
        return this.heatabatement !== null
          ? this.heatabatement.getFeedBunkAreaTemperatureFansActivate(0)
          : false;
      },
      set: function(newValue) {
        return this.heatabatement.setFeedBunkAreaTemperatureFansActivate(
          newValue
        );
      },
    },
    converted_feed_bunk_area_spacing: {
      get: function() {
        return this.heatabatement !== null
          ? this.heatabatement.getFeedBunkAreaSpacing(0)
          : false;
      },
      set: function(newValue) {
        return this.heatabatement.setFeedBunkAreaSpacing(newValue);
      },
    },
    converted_resting_area_paddock_temperature_fans_activate: {
      get: function() {
        return this.heatabatement !== null
          ? this.heatabatement.getRestingAreaPaddockTemperatureFansActivate(0)
          : false;
      },
      set: function(newValue) {
        return this.heatabatement.setRestingAreaPaddockTemperatureFansActivate(
          newValue
        );
      },
    },
    converted_resting_area_paddock_spacing: {
      get: function() {
        return this.heatabatement !== null
          ? this.heatabatement.getRestingAreaPaddockSpacing(0)
          : false;
      },
      set: function(newValue) {
        return this.heatabatement.setRestingAreaPaddockSpacing(newValue);
      },
    },
    converted_temperature_soakers_misters_activate: {
      get: function() {
        return this.heatabatement !== null
          ? this.heatabatement.getTemperatureSoakersMistersActivate(0)
          : false;
      },
      set: function(newValue) {
        return this.heatabatement.setTemperatureSoakersMistersActivate(
          newValue
        );
      },
    },
    converted_feed_bunk_area_fan_diameter:{
      get(){
        return this.heatabatement.getFeedBunkAreaFanDiameter()
      },
      set(newValue){
        return this.heatabatement.setFeedBunkAreaFanDiameter(newValue)
      }
    },
    converted_resting_area_paddock_fan_diameter:{
      get(){
        return this.heatabatement.getRestingAreaPaddockFanDiameter()
      },
      set(newValue){
        return this.heatabatement.setRestingAreaPaddockFanDiameter(newValue)
      }
    }
  },
  methods: {
    // requestReport(evaluationName) {
    //     this.$store.dispatch('requestReport', {
    //         currentVueInstance: this,
    //         evaluationName: evaluationName
    //     })
    // },
  },
  created() {
    let evaluationClass = new Evaluation(this, this.evaluation);
    this.groupClass = new Group(this, evaluationClass, this.group);
    // this.user = this.$store.getters.getData("user");
    this.heatabatement = new HeatAbatement(
      this,
      evaluationClass,
      this.groupClass,
      this.assessor
    );
  },
};
</script>
