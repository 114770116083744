<template>
  <div>
    <div class="fixed-loading" v-show="!isLoaded">
      <div class="loading">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>

    <div class="container main-container main-container--accordion">
      <div class="card mb-0">
        <h1 class="h1">{{ 'Upload settings' | translate }}</h1>
        <div class="card-body p-4">
          <div v-for="file in settingsList" :key="file.id">
            <div class="card-body__top">
              <h2 class="h2 mb-0">{{ file.title | translate }}</h2>
            </div>
            <div class="row card-section mt-0">
              <div class="file-info" v-if="file.metadata">
                <p>
                  {{ 'Last Update' }}:
                  {{ lastUpdated(file) }}
                </p>
                <download-form :file="file"></download-form>
              </div>
              <div class="last-user-name">
                <p>
                  {{ 'Modified User Name' }}:
                  {{ fullName(file) }}
                </p>
              </div>
              <div class="col-md-24 background-white p-2 mb-3 border">
                <input
                  data-type="settings"
                  :name="file.id"
                  type="file"
                  class="upload-file-input"
                  @change="inputFile"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
              </div>
              <div class="col-md-24 text-right p-0">
                <button
                  class="btn btn-primary btn-full--sm"
                  @click="fileUpload(file.id)"
                >
                  {{ 'Upload' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- end row -->
    </div>
  </div>
</template>

<script>
import AppHeader from '../../../components/AppHeader';
import AppFooter from '../../../components/AppFooter';
import {  mapState } from 'vuex';
import formUploadMixin from '@/mixin/formUpload.js';
import DownloadForm from '@/components/misc/DownloadForm.vue';

export default {
  components: {
    AppHeader,
    AppFooter,
    DownloadForm,
  },
  mixins: [formUploadMixin],
  data() {
    return {
      info: false,
      infoMessage: '',
      isLoaded: true,
      filesRef: null,
      type: 'settingsList',
    };
  },
  computed: {
    ...mapState({
      storageList: state => state.storageList,
      settingsList: state => state.settingsList,
    }),
  },
  methods: {},
  mounted() {
    this.isLoaded = true;
  },
};
</script>
<style>
input[type='file'].upload-file-input {
  display: block;
  position: relative;
  opacity: 1;
  z-index: 2;
}
.file-info {
  display: flex;
  align-items: center;
}
.file-link {
  color: #0000ff;
  text-decoration: underline;
  margin: 0 10px;
  position: relative;
}

.file-link svg {
  height: 16px;
  width: 11px;
  margin: 13px 3px;
  position: absolute;
  right: -17px;
  top: -12px;
}

.fixed-loading {
  position: fixed;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  z-index: 9999;
  top: 0;
  left: 0;
}

</style>
