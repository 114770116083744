<template>
    <div class="row">
        <div class="col-md-12 col-lg-11 col-xl-13 d-lg-flex align-self-center">
            <slot></slot>
        </div>
		<div class="col-md-12 col-lg-13 col-xl-11 d-flex flex-row flex-lg-row justify-content-center justify-content-md-end flex-md-wrap flex-lg-nowrap">
            <div class="label_group label_group--width-sm label_group--inline mr-2 mr-sm-2 ml-0">
                <input @change="sendEvent" class="label_group__input" v-model="value" type="radio" :id="yesId" :value="0"/>
                <label class="label_group__label" :for="yesId">{{'No' | translate}}</label>						
            </div>
            <div class="label_group label_group--width-sm label_group--inline">
                <input @change="sendEvent" class="label_group__input" v-model="value" type="radio" :id="noId" :value="1"/>
                <label class="label_group__label" :for="noId">{{'Yes' | translate}}</label>
            </div>
            <div class="label_group label_group--width-sm label_group--inline ml-2">
				<input @change="sendEvent" class="label_group__input" v-model="value" type="radio" :id="naId" :value="-1"/>
				<label class="label_group__label" :for="naId">{{'N/A' | translate}}</label>
			</div>
		</div>
    </div>
</template>

<script>

/*
*  @TODO We are passing in the entire prop, we need to only pass key value pair instead of the whole array
*/

export default {
    props: ['initialValue'],
    data() {
        return {
            yesId: Math.random().toString(36).substring(7),
            noId: Math.random().toString(36).substring(7),
            naId: Math.random().toString(36).substring(7),
            value: null
        }
    },
    methods: {
        sendEvent() {
            this.$emit('change', {
                value: this.value
            });
        }
    },
    watch: {
        initialValue: function(newValue) {
            this.value = newValue;
        }
    },
    mounted() {
        this.value = this.initialValue;        
    }
}

</script>