// Update vallues here

import { SET_COUNTRIES, SET_STATES, SET_CURRENCIES } from '../types';

const state = () => ({
  tempPassword: '',
  prefixes: {
    'Dipl.': 'Dipl.',
    'Ing.': 'Ing.',
    'Dott.': 'Dott.',
    'Dr.': 'Dr.',
    'Dra.': 'Dra.',
    'Fr.': 'Fr.',
    Frau: 'Frau',
    'Gen.': 'Gen.',
    Herr: 'Herr',
    'Hon.': 'Hon.',
    'Hr.': 'Hr.',
    'Ir.': 'Ir.',
    'M.V.Z.': 'M.V.Z.',
    'Mme.': 'Mme.',
    Monsieur: 'Monsieur',
    'Mr.': 'Mr.',
    'Mrs.': 'Mrs.',
    'Ms.': 'Ms.',
    Prof: 'Prof',
    'Ref.': 'Ref.',
    'Rep.': 'Rep.',
    'Rev.': 'Rev.',
    'Sr.': 'Sr.',
    'Sra.': 'Sra.',
  },
  currencies: {
    AUD: 'Australian Dollar (AUD, A$)',
    BRL: 'Brazilian Real (BRL, R$)',
    GBP: 'British Pounds (GBP, £)',
    CAD: 'Canadian Dollar (CAD, C$)',
    CNY: 'Chinese Yuan (CNY, C¥)',
    EGP: 'Egyptian Pound (EGP, £)',
    EUR: 'Euro (EUR, €)',
    INR: 'Indian Rupee (INR, ₹)',
    IDR: 'Indonesian Rupiah (IDR, Rp)',
    JPY: 'Japanese Yen (JPY, J¥)',
    JOD: 'Jordanian Dinar (JOD, JD)',
    MYR: 'Malaysian Ringgit (MYR, RM)',
    MXN: 'Mexican Peso (MXN, M$)',
    MAD: 'Moroccan Dirham (MAD, د.م.)',
    TWD: 'Taiwan Dollar (TWD, NT$)',
    TRY: 'Turkish Lira (TRY, ₺)',
    NZD: 'New Zealand Dollar (NZD, NZ$)',
    PEN: 'Nuevo Sol (PEN, S/)',
    SAR: 'Saudi Riyal (SAR, ریال)',
    RSD: 'Serbian Dinar (RSD, РСД)',
    KRW: 'South Korean Won (KRW, ₩)',
    SEK: 'Swedish Krona (SEK, kr)',
    CHF: 'Swiss Franc (CHF, CHF)',
    THB: 'Thai Baht (THB, ฿)',
    USD: 'US Dollars (USD, US$)',
    VEB: 'Venezuelan bolívar (VEB, Bs)',
    VND: 'Vietnamese đồng (VND, ₫)',
  },
  countries: {
    AF: 'Afghanistan',
    DZ: 'Algeria',
    AR: 'Argentina',
    AM: 'Armenia',
    AU: 'Australia',
    AT: 'Austria',
    BD: 'Bangladesh',
    BY: 'Belarus',
    BE: 'Belgium',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BR: 'Brazil',
    BG: 'Bulgaria',
    KH: 'Cambodia',
    CL: 'Chile',
    CN: 'China',
    CO: 'Colombia',
    KM: 'Comoros',
    CR: 'Costa Rica',
    HR: 'Croatia',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    EE: 'Estonia',
    FI: 'Finland',
    DE: 'Germany',
    GR: 'Greece',
    GT: 'Guatemala',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IQ: 'Iraq',
    IE: 'Ireland',
    IL: 'Israel',
    IT: 'Italy',
    JP: 'Japan',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MG: 'Madagascar',
    MY: 'Malaysia',
    MT: 'Malta',
    YT: 'Mayotte',
    MX: 'Mexico',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NL: 'Netherlands',
    NZ: 'New Zealand',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestine',
    PA: 'Panama',
    PE: 'Peru',
    PH: 'Philippines',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RO: 'Romania',
    RU: 'Russia',
    SA: 'Saudi Arabia',
    CS: 'Serbia',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    ZA: 'South Africa',
    KR: 'South Korea',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TH: 'Thailand',
    TN: 'Tunisia',
    TR: 'Turkey',
    AE: 'United Arab Emirates',
    UY: 'Uruguay',
    VE: 'Venezuela',
    VN: 'Viet Nam',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  states: {
    CA: {
      AB: 'Alberta',
      BC: 'British Columbia',
      MB: 'Manitoba',
      NB: 'New Brunswick',
      NL: 'Newfoundland and Labrador',
      NS: 'Nova Scotia',
      NT: 'Northwest Territories',
      NU: 'Nunavut',
      ON: 'Ontario',
      PE: 'Prince Edward Island',
      QC: 'Québec',
      SK: 'Saskatchewan',
      YT: 'Yukon',
    },
    US: {
      AL: 'Alabama',
      AK: 'Alaska',
      AS: 'American Samoa',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DE: 'Delaware',
      DC: 'District Of Columbia',
      FM: 'Federated States Of Micronesia',
      FL: 'Florida',
      GA: 'Georgia',
      GU: 'Guam',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MH: 'Marshall Islands',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      MP: 'Northern Mariana Islands',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PW: 'Palau',
      PA: 'Pennsylvania',
      PR: 'Puerto Rico',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VI: 'Virgin Islands',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming',
    },
  },
  countriesPrimary: {},
});

const mutations = {
  [SET_STATES](STATE, STATES) {
    const outputData = STATES.reduce((states, state) => {
      const { country_code, isPrimary, code, title } = state;
      if (!states[country_code]) {
        states[country_code] = {};
        states[country_code].primary = {};
        states[country_code].regular = {};
        // states = {
        //   ...states,
        //   states[country_code]: {
        //     primary: {},
        //     regular: {}
        //   }
        // }
      }

      states[country_code][isPrimary ? 'primary' : 'regular'][code] = title;
      return states;
    }, {});

    STATE.states = outputData;
  },
  [SET_COUNTRIES](STATE, COUNTRIES) {
    const countriesList = {};
    const countriesPrimaryList = {};
    COUNTRIES.forEach((country = {}) => {
      const { code, title } = country;
      country.isPrimary ? (countriesPrimaryList[code] = title) : (countriesList[code] = title);
    });
    STATE.countries = countriesList;
    STATE.countriesPrimary = countriesPrimaryList;
  },
  [SET_CURRENCIES](STATE, CURRENCIES) {
    const outputData = {};
    CURRENCIES.forEach(currency => {
      const { title, code, symbol } = currency;
      outputData[code] = `${title} (${code}, ${symbol})`;
    });
    STATE.currencies = outputData;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
