const Table = require('../../Misc/Table');

export const walkingSurface = (app, report, evaluation, group, assessor) => {
  let slide = report.pptx.addSlide('Slide master');
  let slide2;

  console.log(app.$t);

  const language = self.app.$store.state.i18n.locale;

  const surfaceCategoriesObj = [{
    text: app.$t('Surface'),
    options: report.styleOptions.tableHeader
  }, {
    text: app.$t('Risk Assessment'),
    options: report.styleOptions.tableHeader
  }];
  const categoriesObj = [{
    text: app.$t('Question'),
    options: report.styleOptions.tableHeader
  }, {
    text: app.$t('Answer'),
    options: report.styleOptions.tableHeader
  }, {
    text: app.$t('Comment'),
    options: report.styleOptions.tableHeader
  }];

  let surfaceObj = [];
  let questionObj = [];

  slide.addText(
    `${app.$t('Walking Surface Assessor')} - ${group.getName()}`, report.styleOptions.slideHeader
  ).addText(
    app.$t('Results'), {
      x: 0.5,
      y: 2.38,
      ...report.styleOptions.subheaderBlue
    }
  ).addShape(report.pptx.shapes.RECTANGLE, {
    x: 0.5,
    y: 2.7,
    w: 7.5,
    h: 2.7,
    line: '000000',
    line_size: 1
  })

  // risk assesssment descriptions
  slide.addText(
    `${app.$t('Trauma')}:`, {
      x: 0.7,
      y: 2.98,
      color: '000000',
      fontSize: 11,
      fontFace: 'Arial',
      bold: true
    }
  ).addText(
    app.$t('Rocks, stones and very rough, degraded concrete can lead to sole penetrations and white line disease.'), {
      x: 0.7,
      w: 3.35,
      y: 3.1,
      color: '000000',
      fontSize: 11,
      fontFace: 'Arial',
      bold: false,
      valign: 'top'
    }
  ).addText(
    app.$t('Slipping') + ':', {
      x: 4.4,
      y: 2.98,
      color: '000000',
      fontSize: 11,
      fontFace: 'Arial',
      bold: true
    }
  ).addText(
    app.$t('Very smooth concrete surfaces where cows must make sharp turns may contribute to white line separation.'), {
      x: 4.4,
      w: 3.35,
      y: 3.1,
      color: '000000',
      fontSize: 11,
      fontFace: 'Arial',
      bold: false,
      valign: 'top'
    }
  ).addText(
    app.$t('Wearing') + ':', {
      x: 0.7,
      y: 3.98,
      color: '000000',
      fontSize: 11,
      fontFace: 'Arial',
      bold: true
    }
  ).addText(
    app.$t('Rough surfaces and walkways that are excessively sloped (>2%) may lead to sole horn removal, low heels and toe ulcers.'), {
      x: 0.7,
      w: 3.35,
      y: 4.1,
      color: '000000',
      fontSize: 11,
      fontFace: 'Arial',
      bold: false,
      valign: 'top'
    }
  ).addText(
    app.$t('Concussion') + ':', {
      x: 4.4,
      y: 3.98,
      color: '000000',
      fontSize: 11,
      fontFace: 'Arial',
      bold: true
    }
  ).addText(
    app.$t('Hard concrete surfaces provide little comfort to cows with sore feet and make lame cows look much worse compared to rubber surfaces.'), {
      x: 4.4,
      w: 3.35,
      y: 4.1,
      color: '000000',
      fontSize: 11,
      fontFace: 'Arial',
      bold: false,
      valign: 'top'
    }
  );

  if (assessor.getFeedAlley().length !== 0) {

    surfaceObj.push([{
      text: app.$t("Feed Alley"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.buildRiskFactorMessage(assessor.getFeedAlley()),
        options: {
          align: 'left',
          color: '000000',
          fill: 'efefef',
          bold: false
        }
      },
    ]);
  }

  if (assessor.getStallAlley().length !== 0) {
    surfaceObj.push([{
      text: app.$t("Stall Alley"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.buildRiskFactorMessage(assessor.getStallAlley()),
        options: {
          align: 'left',
          color: '000000',
          fill: 'efefef',
          bold: false
        }
      },
    ]);
  }

  if (assessor.getCrossoverAlley().length !== 0) {
    surfaceObj.push([{
      text: app.$t("Crossover Alley"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.buildRiskFactorMessage(assessor.getCrossoverAlley()),
        options: {
          align: 'left',
          color: '000000',
          fill: 'efefef',
          bold: false
        }
      },
    ]);
  }

  if (assessor.getTransferLanes().length !== 0) {
    surfaceObj.push([{
      text: app.$t('Transfer Lanes'),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.buildRiskFactorMessage(assessor.getTransferLanes()),
        options: {
          align: 'left',
          color: '000000',
          fill: 'efefef',
          bold: false
        }
      }
    ]);
  }

  if (assessor.getHoldingArea().length !== 0) {
    surfaceObj.push([{
      text: app.$t("Holding Area"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.buildRiskFactorMessage(assessor.getHoldingArea()),
        options: {
          align: 'left',
          color: '000000',
          fill: 'efefef',
          bold: false
        }
      },
    ]);
  }

  if (assessor.getParlor().length !== 0) {
    surfaceObj.push([{
      text: app.$t("Parlor"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.buildRiskFactorMessage(assessor.getParlor()),
        options: {
          align: 'left',
          color: '000000',
          fill: 'efefef',
          bold: false
        }
      },
    ]);
  }

  if (assessor.getParlorReturnLanes().length !== 0) {
    surfaceObj.push([{
      text: app.$t("Parlor Return Lanes"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.buildRiskFactorMessage(assessor.getParlorReturnLanes()),
        options: {
          align: 'left',
          color: '000000',
          fill: 'efefef',
          bold: false
        }
      },
    ]);
  }

  if (assessor.getStepsBetweenPenAndMilkingParlor().length !== 0) {
    surfaceObj.push([{
      text: app.$t("Steps Between Pen and Milking Parlor"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.buildRiskFactorMessage(assessor.getStepsBetweenPenAndMilkingParlor()),
        options: {
          align: 'left',
          color: '000000',
          fill: 'efefef',
          bold: false
        }
      },
    ]);
  }

  if (surfaceObj.length > 0) {

    Table.create(slide, surfaceCategoriesObj, surfaceObj, report, group, 'Walking Surface Assessor', {
      ...report.styleOptions.tableOptions,
      y: 5.2,
      colW: [3.75, 3.75]
    });

  }

  if (assessor.getAreStepsInThePathOfMovementBetweenThePenAndMilkingParlor() !== null) {
    questionObj.push([{
      text: app.$t("Are stair steps in the path of movement between the pen and milking parlor?"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.getAreStepsInThePathOfMovementBetweenThePenAndMilkingParlor() === 1
          ? app.$t('Yes')
          : assessor.getAreStepsInThePathOfMovementBetweenThePenAndMilkingParlor() === 0
            ? app.$t('No')
            : app.$t('N/A'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false
        }
      },
      {
        text: '',
        options: {
          fill: 'efefef'
        }
      }
    ]);
  }

  if (assessor.getAreCowsBeingExcessivelyPushedOverSteps() !== null && assessor.getAreStepsInThePathOfMovementBetweenThePenAndMilkingParlor() === 1) {
    questionObj.push([{
      text: app.$t("Are cows being excessively pushed over steps?"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.getAreCowsBeingExcessivelyPushedOverSteps() === 1
          ? app.$t('Yes')
          : assessor.getAreCowsBeingExcessivelyPushedOverSteps() === 0
            ? app.$t('No')
            : app.$t('N/A'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false
        }
      },
      {
        text: assessor.getAreCowsBeingExcessivelyPushedOverSteps() === 1
          ? app.$t('Forcing cows over steps may increase slipping and trauma to feet.')
          : assessor.getAreCowsBeingExcessivelyPushedOverSteps() === 0
            ? app.$t('Good!')
            : '',
        options: {
          align: 'left',
          color: assessor.getAreCowsBeingExcessivelyPushedOverSteps() === 1
            ? app.$t('a40000')
            : '249824',
          fill: 'efefef',
          bold: false
        }
      },
    ]);
  }

  if (assessor.getIsTheDistanceFromCowPensToMilkingCenterGreaterThan() !== null) {
    questionObj.push([{
      text: `${app.$t("Is the distance from cow pens to milking center greater than")} ${assessor.getMinIsTheDistanceFromCowPensToMilkingCenterGreaterThan()} ${app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm'))}${app.$t('?')}`,
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.getIsTheDistanceFromCowPensToMilkingCenterGreaterThan() === 1
          ? app.$t('Yes')
          : app.$t('No'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false
        }
      },
      {
        text: assessor.getIsTheDistanceFromCowPensToMilkingCenterGreaterThan() === 1
          ? app.$t('Long distances can lead to excessive hoof wear if the flooring surface is not rubberized.')
          : app.$t('Good!'),
        options: {
          align: 'left',
          color: assessor.getIsTheDistanceFromCowPensToMilkingCenterGreaterThan() === 1 ? 'a40000' : '249824',
          fill: 'efefef',
          bold: false
        }
      },
    ]);
  }

  if (assessor.getDoesHerdspersonAllowTheCowsToWalkAtTheirOwnPaceToFromTheMilkingParlor() !== null) {
    questionObj.push([{
      text: app.$t("Does herdsperson allow the cows to walk at their own pace to/from the milking parlor?"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.getDoesHerdspersonAllowTheCowsToWalkAtTheirOwnPaceToFromTheMilkingParlor() === 1
          ? app.$t('Yes')
          : assessor.getDoesHerdspersonAllowTheCowsToWalkAtTheirOwnPaceToFromTheMilkingParlor() === 0
            ? app.$t('No')
            : app.$t('N/A'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false
        }
      },
      {
        text: assessor.getDoesHerdspersonAllowTheCowsToWalkAtTheirOwnPaceToFromTheMilkingParlor() === 1
          ? app.$t('Good!')
          : assessor.getDoesHerdspersonAllowTheCowsToWalkAtTheirOwnPaceToFromTheMilkingParlor() === 0
            ? app.$t('If cows are rushed along rough tracks/alleys, they are more likely to traumatize their hooves.')
            : '',
        options: {
          align: 'left',
          color: assessor.getDoesHerdspersonAllowTheCowsToWalkAtTheirOwnPaceToFromTheMilkingParlor() === 1
            ? '249824'
            : 'a40000',
          fill: 'efefef',
          bold: false
        }
      },
    ]);
  }

  if (assessor.getNumberOf90DegreeTurns() !== null) {
    questionObj.push([{
      text: app.$t("Number of 90˚ Turns"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.getNumberOf90DegreeTurns(),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false
        }
      },
      {
        text: assessor.getNumberOf90DegreeTurns() === '6+'
          ? app.$t('Excessive sharp turns result in excessive claw wear, increasing the risk of sole wear and white line separation.')
          : app.$t('Good!'),
        options: {
          align: 'left',
          color: assessor.getNumberOf90DegreeTurns() === '6+'
            ? 'a40000'
            : '249824',
          fill: 'efefef',
          bold: false
        }
      },
    ]);
  }

  if (assessor.getFlooringTypeValue()) {
    questionObj.push([{
      text: app.$t("Flooring Type"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.getFlooringType(),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false
        }
      },
      {
        text: '',
        options: {
          fill: 'efefef'
        }
      }
    ]);
  }


  if (assessor.getIsTheRubberSurfaceGroovedOrFinishedToMinimizeCowSlipping() !== null) {
    questionObj.push([{
      text: app.$t("Is the rubber surface grooved or finished to minimize cow slipping?"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.getIsTheRubberSurfaceGroovedOrFinishedToMinimizeCowSlipping() === 1
          ? app.$t('Yes')
          : assessor.getIsTheRubberSurfaceGroovedOrFinishedToMinimizeCowSlipping() === 0
            ? app.$t('No')
            : app.$t('N/A'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false
        }
      },
      {
        text: assessor.getIsTheRubberSurfaceGroovedOrFinishedToMinimizeCowSlipping() === 1
          ? app.$t('Good!')
          : assessor.getIsTheRubberSurfaceGroovedOrFinishedToMinimizeCowSlipping() === 0
            ? app.$t('Rubber surfaces can become slippery, increasing risk of injury to cattle.')
            : '',
        options: {
          align: 'left',
          color: assessor.getIsTheRubberSurfaceGroovedOrFinishedToMinimizeCowSlipping() === 1
            ? '249824'
            : 'a40000',
          fill: 'efefef',
          bold: false
        }
      },
    ]);
  }

  if (assessor.getIsThereAnyWireOrSharpMetalProtrudingFromTheRubberSurface() !== null) {
    questionObj.push([{
      text: app.$t("Is there any wire or sharp metal protruding from the rubber surface?"),
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.getIsThereAnyWireOrSharpMetalProtrudingFromTheRubberSurface() === 1
          ? app.$t('Yes')
          : assessor.getIsThereAnyWireOrSharpMetalProtrudingFromTheRubberSurface() === 0
            ? app.$t('No')
            : app.$t('N/A'),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false
        }
      },
      {
        text: assessor.getIsThereAnyWireOrSharpMetalProtrudingFromTheRubberSurface() === 1
          ? app.$t('Metal protruding from rubber surfaces can cause injury to cattle, resulting in lameness. Rubber surfaces with metal protruding should be removed or replaced as soon as possible.')
          : assessor.getIsThereAnyWireOrSharpMetalProtrudingFromTheRubberSurface() === 0
            ? app.$t('Good!')
            : '',
        options: {
          align: 'left',
          color: assessor.getIsThereAnyWireOrSharpMetalProtrudingFromTheRubberSurface() === 1 ? 'a40000' : '249824',
          fill: 'efefef',
          bold: false
        }
      },
    ]);
  }

  if (assessor.getPassageAlleyWidth()) {
    let passageAlleyBadMessage = language === 'es'
      ? `El ancho de los pasillos de tránsito ha de ser de un mínimo de ${assessor.getMinPassageAlleyWidth()} ${app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm')) }`
      : `${app.$t('Passage alleys should be at least')} ${assessor.getMinPassageAlleyWidth()} ${app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm'))}${app.$t(' or greater in width.')}`
    questionObj.push([{
      text: `${app.$t("Passage Alley Width")} (${app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm'))})`,
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.getPassageAlleyWidth(),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false
        }
      },
      {
        text: assessor.getPassageAlleyWidth() < assessor.getMinPassageAlleyWidth()
          ?  passageAlleyBadMessage
          : app.$t('Good!'),
        options: {
          align: 'left',
          color: assessor.getPassageAlleyWidth() < assessor.getMinPassageAlleyWidth() ? 'a40000' : '249824',
          fill: 'efefef',
          bold: false
        }
      }
    ]);
  }

  if (assessor.getFeedAlleyWidth()) {
    questionObj.push([{
      text: `${app.$t("Feed Alley Width")} (${app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm'))})`,
      options: {
        color: '000000',
        align: 'left',
        fill: 'ffffff',
        bold: false
      }
    },
      {
        text: assessor.getFeedAlleyWidth(),
        options: {
          color: '000000',
          fill: 'ffffff',
          bold: false
        }
      },
      {
        text: assessor.getFeedAlleyWidth() < assessor.getMinFeedAlleyWidth()
          ? `${app.$t('Feed alleys should be at least')} ${assessor.getMinFeedAlleyWidth()} ${app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'm'))}${app.$t(' or greater in width.')}`
          : app.$t('Good!'),
        options: {
          align: 'left',
          color: assessor.getFeedAlleyWidth() < assessor.getMinFeedAlleyWidth() ? 'a40000' : '249824',
          fill: 'efefef',
          bold: false
        }
      }
    ]);
  }

  if (questionObj.length > 0) {

    slide2 = report.pptx.addSlide('Slide master');

    slide2.addText(
      app.$t('Walking Surface Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
    ).addText(
      app.$t('Walking Surface'), {
        x: 0.5,
        y: 2.38,
        ...report.styleOptions.subheaderBlue
      }
    );

    Table.create(slide2, categoriesObj, questionObj, report, group, 'Walking Surface Assessor', {
      ...report.styleOptions.tableOptions,
      y: 2.7,
      colW: [3.25, 1.25, 3]
    });
  }
}
