import { mapActions } from 'vuex';
import {
  GET_UPDATE_ASSESSOR,
  GET_UPDATE_ME,
  GET_UPDATE_USER,
} from '../store/types';

export default {
  isAdmin() {
    console.log(this.$store);
    return this.$store.state.adminRoles.includes(this.user.role);
  },
  methods: {
    ...mapActions('users', [GET_UPDATE_USER, GET_UPDATE_ME]),
    ...mapActions('assessors', [GET_UPDATE_ASSESSOR]),
    async setUpdateAndDelete({ userMedias, assessorMedias }) {
      const UPDATE_USER = this.isAdmin ? GET_UPDATE_USER : GET_UPDATE_ME;
      const updatedUser = {
        // ...this.user,
        ...(this.isAdmin ? { _id: this.evaluation.userId } : {}),
        medias: { ...(this.user.medias || {}), ...userMedias },
      };
      await Promise.all(
        this[UPDATE_USER](updatedUser),

        this[GET_UPDATE_ASSESSOR]({
          evaluationId: this.evaluation._id,
          groupId: this.group.id,
          assessor: {
            ...this.assessor,
            data: {
              ...this.assessor.data,
              medias: assessorMedias,
            },
          },
        })
      );
    },

    getFilteredUserAndAssessorMedias(imgsNeedToDelete) {
      let { medias: assessorMedias = {} } = this.assessor.data || {};
      let { medias: userMedias = {} } = this.user || {};
      imgsNeedToDelete.forEach(mediaId => {
        if (userMedias[mediaId] || assessorMedias[mediaId]) {
          userMedias[mediaId] && delete userMedias[mediaId];
          assessorMedias[mediaId] && delete assessorMedias[mediaId];
        }
      });
      return { assessorMedias, userMedias };
    },

    async deletePhotos(deleteImages) {
      const {
        userMedias,
        assessorMedias,
      } = await this.getFilteredUserAndAssessorMedias(deleteImages);

      // update both user and assessor object with the remaining photos
      this.setUpdateAndDelete({ userMedias, assessorMedias });
      this.$set(this.assessor.data, 'medias', assessorMedias);
      this.$set(this.user, 'medias', userMedias);
      this.$forceUpdate();
    },

    imageSize(url) {
      const img = document.createElement('img');
      const promise = new Promise((resolve, reject) => {
        img.onload = () => {
          // Natural size is the actual image size regardless of rendering.
          const width = img.naturalWidth;
          const height = img.naturalHeight;

          // Resolve promise with the width and height
          resolve({ width, height });
        };

        // Reject promise on error
        img.onerror = reject;
      });

      // Setting the source makes it start downloading and eventually call `onload`
      img.src = url;

      return promise;
    },
  },
};
