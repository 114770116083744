/**
 * The time budget assessor allows the user to calculate the laying time for a cow in a typical day by taking estimated times that the cows
 * do certain actions and producing a calculated estimated lying time. The system will make certain recommendations based off the
 * information input and the calculated lying time.
 */
const red_color = 'CC0000';
const orange_color = 'FFCC66';
const green_color = '00cc00';
import constantValues from './constants/transition.js';
import _ from 'lodash';

const convert = require('convert-units');
import Assessor from './Assessor';

class Transition extends Assessor {
  constructor(app, evaluation, group, assessor) {
    super(app, evaluation, group, assessor);

    this.app = app;
    this.evaluation = evaluation;
    this.group = group;
    this.assessor = assessor;
  }

  getProductionType() {
    return this.assessor.data.production_type;
  }

  getDataField(field) {
    if (this.assessor.data[field]) {
      return this.assessor.data[field];
    }
    return null;
  }

  getDataNumberField(field, unit, global = false, roundPoint = true) {
    const value = global ? this.evaluation.evaluation.data[field] : this.assessor.data[field];
    if (value !== null) {
      if (unit === '%') {
        return value + '%';
      }
      if (unit === null) {
        return value;
      }
      if (field === 'projected_first_test_305') {
        return this.evaluation.evaluation.data[field + '_' + this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), unit)];
      }

      return parseFloat(
        convert(Number(value))
          .from(unit)
          .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), unit))
      ).toDecimal(roundPoint);
    }
    return null;
  }

  calculatePosition(field, mode = 'ltr', global = false) {
    let value = Number(global ? this.evaluation.evaluation.data[field] : this.assessor.data[field]);
    if (field === 'projected_first_test_305') {
      value = this.evaluation.evaluation.data[field + '_' + this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg')];
    }
    if (value !== null && constantValues[field + '_min'] && constantValues[field + '_mid'] && constantValues[field + '_max']) {
      let min = Number(constantValues[field + '_min']);
      let mid = Number(constantValues[field + '_mid']);
      let max = Number(constantValues[field + '_max']);
      if (field === 'projected_first_test_305') {
        min = parseFloat(
          convert(min)
            .from('kg')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg'))
        ).toDecimal(0);
        mid = parseFloat(
          convert(mid)
            .from('kg')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg'))
        ).toDecimal(0);
        max = parseFloat(
          convert(max)
            .from('kg')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg'))
        ).toDecimal(0);
      }
      let position = [1, 2, 3, 4, 5];
      if (mode === 'rtl' || mode === 'rtl-slider') {
        position.reverse();
      }
      if (value <= min) {
        return position[0];
      }
      if (value > min && value < mid) {
        return position[1];
      }

      if (value === mid) {
        return position[2];
      }
      if (value > mid && value < max) {
        return position[3];
      }
      if (value >= max) {
        return position[4];
      }
    }
    return null;
  }

  generate5ValueRow(value, unit, mode = 'ltr', global = false) {
    const colors = [red_color, red_color, orange_color, orange_color, green_color];
    const position = this.calculatePosition(value, mode, global);
    const rowItem = {
      text: '',
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: 'FFFFFF',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    };
    const output = [
      JSON.parse(JSON.stringify(rowItem)),
      JSON.parse(JSON.stringify(rowItem)),
      JSON.parse(JSON.stringify(rowItem)),
      JSON.parse(JSON.stringify(rowItem)),
      JSON.parse(JSON.stringify(rowItem)),
    ];
    if (output[position - 1]) {
      output[position - 1].text = this.getDataNumberField(value, unit, global);
      output[position - 1].options.fill = colors[position - 1];
      if (mode === 'rtl-slider') {
        let x = Number(this.getDataNumberField(value, null, true));
        if (x <= constantValues[`${value}_green`]) {
          output[position - 1].options.fill = green_color;
        } else if (x > constantValues[`${value}_yellow`]) {
          output[position - 1].options.fill = red_color;
        } else {
          output[position - 1].options.fill = orange_color;
        }
      }
      return output;
    } else {
      return [];
    }
  }

  generate3ValueRow_LEGACY(field, unit, type = 'number', mode = 'ltr') { // New function in Table.js
    const rowItem = {
      text: '',
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: 'FFFFFF',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    };
    const output = [JSON.parse(JSON.stringify(rowItem)), JSON.parse(JSON.stringify(rowItem)), JSON.parse(JSON.stringify(rowItem))];
    if (this.assessor.data[field] === null) {
      return output;
    }
    let value = this.assessor.data[field];
    const min = constantValues[field + '_min'];
    const mid = constantValues[field + '_mid'];
    const max = constantValues[field + '_max'];
    let positions = [1, 2, 3];
    const colors = [red_color, orange_color, green_color];
    let position = 1;
    if (mode === 'rtl') {
      positions.reverse();
    }    
    if (type === 'number') {
      value = Number(value);
      if (value <= min) {
        position = positions[2];
      }
      if ((value > min) & (value < max)) {
        position = positions[1];
      }
      if (value >= max) {
        position = positions[0];
      }
if (['body_condition_score_1', 'body_condition_score_2', 'feed_bunk_space'].includes(field)) {
        if (value < min) {
          position = positions[2];
        }
        if ((value >= min) & (value <= max)) {
          position = positions[1];
        }
        if (value > max) {
          position = positions[0];

        }
      }
      output[position - 1].text = 'X'; //this.getDataNumberField(field, unit)
    } else {
      if (value == min) {
      position = positions[2];
      }
      if (value == mid) {
        position = positions[1];
      }
      if (value == max) {
        position = positions[0];
      }
      output[position - 1].text = 'X';
    }

    if (['freestall_occupation_area'].includes(field)) {

      if (value == mid) {
        position = positions[2];
        }
        if (value == min) {
          position = positions[1];
        }
        if (value == max) {
          position = positions[0];
        }
        output[position - 1].text = 'X';
    }

    output[position - 1].options.fill = colors[position - 1];
    return output;
  }

  generate4ValueRow(field, unit, type = 'number', mode = 'ltr') {
    const rowItem = {
      text: '',
      options: {
        color: 'FFFFFF',
        valign: 'center',
        align: 'center',
        fill: 'FFFFFF',
        fontFace: 'Arial',
        margin: 5,
        bold: true,
      },
    };
    const output = [JSON.parse(JSON.stringify(rowItem)), JSON.parse(JSON.stringify(rowItem)), JSON.parse(JSON.stringify(rowItem)), JSON.parse(JSON.stringify(rowItem))];
    if (this.assessor.data[field] === null) {
      return output;
    }
    if (this.assessor.data[field] === null) {
      return;
    }
    let value = this.assessor.data[field];
    const min = constantValues[field + '_min'];
    const mid = constantValues[field + '_mid'];
    const mid2 = constantValues[field + '_mid2'];
    const max = constantValues[field + '_max'];
    const options = [min, mid, mid2, max];
    const colors = [red_color, orange_color, orange_color, green_color];
    let position = 1;
    if (type === 'select') {
      position = options.indexOf(value);
      if (position === -1) {
        return;
      }
      output[position].text = 'X';
    }
    output[position].options.fill = colors[position];

    return output;
  }

  getFeedingSpacePerCow() {
    let feedingSpace = this.getDataField('feed_bunk_space');
    let numberOfCows = this.group.group.data.size;
    let convertedFeedingSpace = null;
    if (this.evaluation.getMeasurements() === 'imperial') {
      convertedFeedingSpace = convert(Number(feedingSpace))
        .from('ft')
        .to('in');
    } else {
      convertedFeedingSpace = feedingSpace * 100;
    }

    return Number(convertedFeedingSpace / numberOfCows).toDecimal(1);
  }

  getDrinkingSpacePerCow() {
    let drinkingSpace = this.getDataField('drinking_space');
    let numberOfCows = this.group.group.data.size;
    let convertedDrinkingSpace = null;
    if (this.evaluation.getMeasurements() === 'imperial') {
      convertedDrinkingSpace = convert(Number(drinkingSpace))
        .from('ft')
        .to('in');
    } else {
      convertedDrinkingSpace = drinkingSpace * 100;
    }
    
    return Number(convertedDrinkingSpace / numberOfCows).toDecimal(1);
  }
}

export default Transition;
