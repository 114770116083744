import { URLS } from '../../constants';
import axios from '../../../Axios';
import { GET_REQUEST_REPORT, SET_REQUEST_REPORT } from '../types';

const state = {
  reports: {
    id: null,
    assessorId: null,
    dateCreated: null,
    fileName: null,
    fileSize: null,
    outputData: null,
  },
};

const getters = {};

const mutations = {};
const actions = {
  [GET_REQUEST_REPORT]({ commit: COMMIT }, { evaluationName, ...rest }) {
    return new Promise((resolve, reject) => {
      axios
        .post(URLS.REQUEST_REPORT, { evaluationName })
        .then(function(res) {
          // COMMIT(SET_REQUEST_REPORT, res.data.data);
          resolve();
        })
        .catch(err => {
          console.log('in catch');
          return reject(err.response ? err.response.data : err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
