
import FootbathSlide from './FootbathSlide';

const Table = require('../../Misc/Table');

/**
 *  Generates the first slide of footbath assessor
 */
export const footbath = (app, report, evaluation, group, assessor) => {

    let footbaths = assessor.getExistingFootbaths();

    if(Object.keys(footbaths).length === 0) return false;

    for ( let footbathId in footbaths ) {
        let footbathSlide = new FootbathSlide(app, report, evaluation, group);
        footbathSlide.addSlide(footbaths[footbathId]);
    }
}
