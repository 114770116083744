<template>
  <div
    v-if="filteredGroups.length"
    class="dashboard-time-budget dashboard__stats-block main-container mb-0"
  >
    <div class="row no-gutters w-100 align-items-start mb-md-4">
      <div class="col-24 col-md-16 text-left">
        <h2 class="h2 d-inline-block">
          {{ "Time Budget" | translate }} -
          {{ productionType(evaluation.groups[groupId]) | translate }}
        </h2>
      </div>
      <div
        v-if="filteredGroups.length > 1"
        class="col-24 col-md-8 d-flex justify-content-md-end mb-3 my-md-0"
      >
        <select v-model="groupId" class="form-control dashboard__select">
          <option :value="null" disabled>
            {{ "Select" | translate }}
          </option>

          <option
          v-for="group in filteredGroups"
          :key="`freestall_${group.id}`"
          :value="group.id"
          >
            >
            {{ group.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mt-4">
        <!-- <10 >12 -->
        <Timemeter
          :label="'Time Lying' | translate"
          :units="'hrs' | translate"
          :value="timeLying(evaluation.groups[groupId])"
          :success="(val) => val > 12"
          :danger="(val) => val < 10"
          :reversed="true"
        />
      </div>
      <div class="col-md-8 mt-4">
        <!-- >3.5 <1.5  -->
        <Timemeter
          :label="'Time Standing' | translate"
          :units="'hrs' | translate"
          :value="timeStanding(evaluation.groups[groupId])"
          :success="(val) => val < 1.5"
          :danger="(val) => val > 3.5"
        />
      </div>
      <div class="col-md-8 mt-4">
        <!-- >4 <2 -->
        <Timemeter
          :label="'Time Milking' | translate"
          :units="'hrs' | translate"
          :value="timeMilking(evaluation.groups[groupId])"
          :success="(val) => val < 2"
          :danger="(val) => val > 4"
        />
      </div>
    </div>
  </div>
</template>

<script>
/** Mixins */
import { animalMixin } from "@/mixins/animal.mixin.js";
import { translationsMixin } from "@/mixins/translations.mixin.js";

/** Components */
import Timemeter from "@/components/Timemeter";

export default {
  name: "DashboardTimeBudget",

  components: {
    Timemeter,
  },

  props: {
    evaluation: Object,
  },

  mixins: [animalMixin, translationsMixin],

  computed: {
    filteredGroups() {
      return Object.values(this.evaluation.groups).filter((group) => {
        const values = [
          this.timeLying(group),
          this.timeStanding(group),
          this.timeMilking(group),
        ];

        return (values.filter((v) => v || false) || []).length;
      });
    },

    groupType() {
      return this.getGroup(this.groupId).data.type_of_animal;
    },
  },

  data() {
    return {
      groupId: "",
    };
  },

  created() {
    if (this.filteredGroups.length) {
      let defaultGroupId = this.filteredGroups[0].id;
      this.groupId = defaultGroupId;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../_dashboard.scss";
</style>
