<template>
  <div>
    <div class="loading" v-show="!isLoaded">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <div
      class="main-container main-container--margin-bt container"
      v-show="isLoaded"
    >
      <div class="card mb-0">
        <div class="row mx-0">
          <h1 class="h1 mb-3 mb-md-1">{{ 'Edit Company' | translate }}</h1>
        </div>

        <div class="card-body mt-3">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label
                  >{{ 'Name' | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="company_name"
                  v-model="company.name"
                  data-vv-as="Name"
                  v-validate="'required'"
                />
                <span v-show="errors.has('company_name')" class="text-danger">
                  <small>{{ 'The Name field is required.' | translate }}</small>
                </span>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group">
                <label
                  >{{ 'Country' | translate }}
                  <span class="required">*</span>
                </label>
                <select
                  class="form-control"
                  v-model="company.country"
                  name="company_country"
                  data-vv-as="Country"
                  v-validate="'required'"
                >
                  <option :value="null" disabled>{{
                    'Select' | translate
                  }}</option>
                  <option
                    v-for="(country, index) in $store.state.misc
                      .countriesPrimary"
                    :key="index"
                    :value="index"
                  >
                    {{ country | translate }}
                  </option>
                  <option disabled>------------------</option>
                  <option
                    v-for="(country, index) in $store.state.misc.countries"
                    :key="index"
                    :value="index"
                  >
                    {{ country | translate }}
                  </option>
                </select>
                <span
                  v-show="errors.has('company_country')"
                  class="text-danger"
                >
                  <small>{{
                    'The Country field is required.' | translate
                  }}</small>
                </span>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <label
                  >{{ 'Address' | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="text"
                  name="address_v"
                  class="form-control"
                  v-model="company.address_1"
                  data-vv-as="Address"
                  v-validate="'required'"
                />
                <span v-show="errors.has('address_v')" class="text-danger">
                  <small>{{
                    'The Address field is required.' | translate
                  }}</small>
                </span>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <label
                  >{{ 'City' | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="text"
                  name="city"
                  class="form-control"
                  v-model="company.city"
                  data-vv-as="City"
                  v-validate="'required'"
                />
                <span v-show="errors.has('city')" class="text-danger">
                  <small>{{ 'The City field is required.' | translate }}</small>
                </span>
              </div>
            </div>
            <div
              class="col-lg-6"
              v-if="
                misc.states[company.country] &&
                  Object.keys(misc.states[company.country]).length
              "
            >
              <div class="form-group">
                <label>{{ 'State/Province' | translate }}</label>
                <select class="form-control" v-model="company.state">
                  <option :value="null" selected disabled>{{
                    'Select' | translate
                  }}</option>
                  <template
                    v-if="
                      Object.keys(misc.states[company.country].primary).length
                    "
                  >
                    <option
                      v-for="(state, abbr) in misc.states[company.country]
                        .primary"
                      :key="state"
                      :value="abbr"
                    >
                      {{ state }}
                    </option>
                    <option disabled>------------------</option>
                  </template>
                  <option
                    v-for="(state, abbr) in misc.states[company.country]
                      .regular"
                    :key="state"
                    :value="abbr"
                  >
                    {{ state }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <label
                  >{{ 'Postal Code' | translate }}
                </label>
                <input
                  type="text"
                  name="postal_code"
                  class="form-control"
                  v-model="company.postal_code"
                  data-vv-as="Postal"
                />
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <label
                  >{{ 'Phone' | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="tel"
                  name="phone"
                  class="form-control"
                  v-model="company.phone"
                  data-vv-as="Phone"
                  v-validate="'required'"
                />
                <span v-show="errors.has('phone')" class="text-danger">
                  <small>{{
                    'The Phone field is required.' | translate
                  }}</small>
                </span>
              </div>
            </div>
          </div>
          <!-- end row -->
        </div>
        <!-- end card body -->

        <div
          v-show="errorMessage === true"
          class="alert alert-danger mt-4 mb-0"
          role="alert"
        >
          {{ message || 'Please fill out the required fields!' | translate }}
        </div>

        <div
          v-if="successMessage === true"
          class="alert alert-primary mt-4 mb-0"
          role="alert"
        >
          {{ 'Company has been updated!' | translate }}
        </div>

        <div class="row d-flex">
          <div
            class="col-md-12 order-2 order-md-1 d-flex flex-column flex-md-row"
          >
            <router-link
              to="/admin/company-management"
              class="btn btn-secondary btn-full--sm mt-2 mt-md-4 order-2 order-md-1"
              >{{ 'Back' | translate }}</router-link
            >
            <button
              @click="deleteCompany()"
              class="btn btn-secondary btn-full--sm mt-2 mt-md-4 ml-md-4 order-1 order-md-2"
              :disabled="loader2"
            >
              {{ 'Delete' | translate }}
              <span v-if="loader2" class="loader ml-1"></span>
            </button>
          </div>
          <div class="col-md-12 order-1 order-md-2">
            <button
              @click="editCompany()"
              type="button"
              class="btn btn-primary btn-full--sm float-right mt-4"
              :disabled="loader"
            >
              {{ 'Update' | translate }}
              <span v-if="loader" class="loader ml-1"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end container -->
  </div>
</template>

<script>
import Vue from 'vue';
import { Validator } from 'vee-validate';

// profile component
import generalProfile from '../../../components/admin/general.vue';
import {
  GET_COMPANY_BY_ID,
  GET_COMPANY_DELETE,
  GET_COMPANY_UPDATE,
} from '../../../store/types';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    generalProfile,
  },
  props: ['data'],
  computed: {
    ...mapState({
      companies: state => state.company.companies,
      misc: state => state.misc,
    }),
  },
  data() {
    return {
      admin: true,
      companyId: this.$route.params.id,
      company: {
        //     name: null,
        //     country: null,
        //     address_1: null,
        //     city: null,
        //     postal_code: null,
        //     state: null,
        //     phone: null
      },
      errorMessage: false,
      message : null,
      isLoaded: true,
      loader: false,
      loader2: false,
      successMessage: false,
    };
  },
  methods: {
    // map all the actions from store, into the components
    ...mapActions('company', [
      GET_COMPANY_UPDATE,
      GET_COMPANY_DELETE,
      GET_COMPANY_BY_ID,
    ]),
    isCompanyExistInStore() {
      const companyFound = this.companies.find(
        ({ _id: companyId }) => companyId === this.companyId
      );

      if (companyFound) {
        this.company = companyFound;
      }

      return !!companyFound;
    },
    editCompany() {
      this.loader = true;
      const company = {
        name: this.company.name,
        country: this.company.country,
        address_1: this.company.address_1,
        city: this.company.city,
        postal_code: this.company.postal_code,
        state: this.company.state,
        phone: this.company.phone,
      };
      this.$validator.validateAll().then(result => {
        if (result === true) {
          this[GET_COMPANY_UPDATE](this.company)
            .then(response => {
              this.successMessage = true;
              this.loader = false;
              setTimeout(() => {
                this.successMessage = false;
              }, 4000);
              console.log('updated company', response);
            })
            .catch(error => {
              this.errorMessage = true;
              this.loader = false;
              this.message = error.message
          setTimeout(() => {
            this.errorMessage = false;
            this.message = null
          }, 4000);
            });
        } else {
          this.errorMessage = true;
          this.loader = false;
          setTimeout(() => {
            this.errorMessage = false;
          }, 4000);
        }
      });
    },
    deleteCompany() {
      this.loader2 = true;
      this[GET_COMPANY_DELETE](this.companyId)
        .then(res => {
          this.$router.push({
            path: '/admin/company-management',
            query: { info_code: 'companies/company-deleted' },
          });
        })
        .catch(error => {
          this.errorMessage = true;
              this.loader2 = false;
              this.message = error.message
          setTimeout(() => {
            this.errorMessage = false;
            this.message = null
          }, 4000);
          this.loader2 = false;
          console.error(error);
        });
    },
  },
  created() {
    if (!this.isCompanyExistInStore()) {
      this.isLoaded = false;
      this[GET_COMPANY_BY_ID](this.companyId)
        .then(res => {
          this.company = res;
          this.isLoaded = true;
        })
        .catch(error => {
          this.isLoaded = true;
          console.error(error);
        });
    }
  },
};
</script>
