<template>
    <div>
        <div class="row mt-4 misc-bottom">
            <div class="col-md-6">
                <!--<image-upload></image-upload>-->
            </div>
            <div class="col-md-6 text-right product_selector_desktop">
                <button class="btn">{{ 'Add Mix' | translate }}</button>
            </div>
        </div>
        <hr />
        <div class="row misc-bottom">
            <div class="col- col-md-6">
                <button @click="back" class="btn btn-secondary">{{ 'Back' | translate }}</button>
                <button class="btn btn-secondary">{{ 'Design New footbath' | translate }}</button>
            </div>
            <div class="col- col-md-6 text-right">
                <button v-if="user && user.role !== 'data_entry'" class="btn btn-primary">{{ 'Reports' | translate }}</button>
            </div>
        </div>
    </div>
</template>

<script>
//import ImageUpload from '@/components/image-upload';

export default {
    components: {
    },
    props: [
		'data'
	],
	data: function() {
        return {
           
        }
    },
    methods: {
        back (){
            this.$emit("back");
        }
    },
    mounted () {  
        // this.user = this.$store.getters.getData("user");
	}
}
</script>