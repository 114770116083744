import { render, staticRenderFns } from "./DashboardTypedGroups.vue?vue&type=template&id=3fe98fbe&scoped=true&"
import script from "./DashboardTypedGroups.vue?vue&type=script&lang=js&"
export * from "./DashboardTypedGroups.vue?vue&type=script&lang=js&"
import style0 from "./DashboardTypedGroups.vue?vue&type=style&index=0&id=3fe98fbe&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fe98fbe",
  null
  
)

export default component.exports