<template>
  <div :class="`col-lg-6 ${classes}`" >
    <div class="form-group">
      <label class="d-block"
        >{{ "Measurements" | translate }}
        <span class="required">*</span>
      </label>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            name="measurements"
            value="metric"
            data-vv-as="Measurements"
            v-validate="'required'"
            v-model="localMeasurements"
            @change="$forceUpdate()"
          />
          {{ "Metric" | translate }}
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            name="measurements"
            value="imperial"
            data-vv-as="Measurements"
            v-validate="'required'"
            v-model="localMeasurements"
            @change="$forceUpdate()"
          />
          {{ "Imperial" | translate }}
        </label>
      </div>
      <span v-show="errors.has('measurements')" class="text-danger d-block">
        <!-- <small>{{ 'The Measurements field is required.' | translate }}</small> -->
        <small>{{ errors.first("measurements") }}</small>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeasurementSelectionRadio",
  props: {
    value: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      default: "",
    }
  },
 data() {
    return {
      localMeasurements: this.value,
    };
  },
  watch: {
      localMeasurements(newValue) {
      // Emit an event to update the parent component with the new value
      this.$emit("input", newValue);
    },
  }
};
</script>

<style></style>
