<template>
    <div>
        <div class="row">
            <div class="col-md-24">
                <div class="form-group mb-2">
                    <label class="mr-3">{{ $t("Select Risk Factors") }}
                        <span class="required">*</span>
                    </label>
                    <label class="label-checkbox label-checkbox--select-all">
                        <input @change="sendEvent()" @click="checkAll" :checked="check_all" :id="differentiator + 'checkAll'" type="checkbox" value="1" />
                        <span class="checkbox"></span>
                    </label>
                    <label :for="differentiator + 'checkAll'" class="label--font-thin">{{ $t("Select/Deselect All") }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12" v-for="( risk, index ) in riskFactors" :key="index">
                <div class="form-group mb-2">
                    <label class="label-checkbox">
                        <input v-if="risk.value === 'n_a'" @change="uncheckAllExceptNotApplicable();sendEvent()" :id="risk.value" :value="risk.value" v-model="value" type="checkbox"/>
                        <input v-else @change="removeNotApplicable();sendEvent()" :id="differentiator + risk.value" :value="risk.value" v-model="value" type="checkbox"/>
                        <span class="checkbox"></span>
                    </label>
                    <label :for="differentiator + risk.value" class="label--font-thin d-inline mr-1">
                        {{ risk.name | translate }}
                    </label>
                    <modal-info v-if="risk.description" :value="risk.value">
                        <template slot="modal-content">
                            <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ risk.name | translate }}</h2>
                            <p class="p--md px-3">{{ risk.description | translate }}</p>
                            <div class="row modal-bottom pb-3 mt-3">
                                <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t('Cancel') }}</a>
                            </div>
                        </template>
                    </modal-info>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import _ from 'lodash';
    import ModalInfo from '@/components/misc/modal-info.vue';

    export default {
        components: {
            ModalInfo
        },
        //differentiator is used to differentiate the id from the other inputs to make the label connected to the correct input.
        props: ['initialValue', 'differentiator'],
        data() {
            return {
                value: null,
                riskFactors: [
                    {
                        "value": "trauma",
                        "name": "Trauma",
                        "description": "Rocks, stones and very rough, degraded concrete can lead to sole penetrations and white line disease."
                    },
                    {
                        "value": "slipping",
                        "name": "Slipping",
                        "description": "Very smooth concrete surfaces where cows must make sharp turns may contribute to white line separation."
                    },
                    {
                        "value": "wearing",
                        "name": "Wearing",
                        "description": "Rough surfaces and walkways that are excessively sloped (>2%) may lead to sole horn removal, low heels and toe ulcers."
                    },
                    {
                        "value": "concussion",
                        "name": "Concussion",
                        "description": "Hard concrete surfaces provide little comfort to cows with sore feet and make lame cows look much worse compared to rubber surfaces."
                    },
                    {
                        "value": "n_a",
                        "name": "N/A"
                    }
                ],
            }
        },
        computed: {
            check_all() {
                if(this.value === null) return;
            	return this.value.length === 4;
            }
        },
        methods: {
            uncheckAllExceptNotApplicable() {
                this.value = ['n_a'];
            },
            removeNotApplicable() {
                let filteredValue = this.value.filter((risk) => {
                    return risk !== 'n_a';
                });
                this.value = filteredValue;
            },
            sendEvent() {
                this.$emit('change', {
                    value: this.value
                });
            },
            checkAll() {
                if ( ! this.check_all ) {
                    this.value = ["concussion", "wearing", "slipping", "trauma"]
                } else {
                    this.value = [];
                }
            }
        },
        watch: {
            initialValue: function(newValue) {
                this.value = newValue;
            }
        },
        mounted() {
            this.value = this.initialValue;
        }
    }
</script>
