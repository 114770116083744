import Product from './Product';

class Mix {
    constructor(app, evaluation, group, assessor, footbath, mix) {
        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;
        this.footbath = footbath;
    	this.mix = mix;
    }

    getId() {
        return this.mix.id;
    }

    getName() {
    	return this.mix.name;
    }

    getProducts() {
        let productsToDisplay = [];
        if(this.mix.productsToDisplay) {
            for (let productId in this.mix.productsToDisplay) {
                const product = new Product(this.app, this.evaluation, this.group, this.assessor, this.footbath, this, this.mix.productsToDisplay[productId]);
                productsToDisplay.push(product);
            }
        }

        return productsToDisplay;
    }
    
    getTotalFootbathsPerWeek() {
        return this.mix.score_total;
    }

    getWasteWaterPerYear(roundPoint) {
        let wasteWaterPerYear = (this.footbath.getVolume() * (((this.getTotalFootbathsPerWeek() * 52) * this.group.getGroupSize()) / this.footbath.getCowPassesPerBath()));
        
        if ( roundPoint === undefined ) {
            roundPoint = wasteWaterPerYear.countDecimals();
        }

        return wasteWaterPerYear.toDecimal(roundPoint);
    }
};

export default Mix;