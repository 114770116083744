<style>
    .multiple-tabs-overlay {
        position:fixed;
        top:0;
        left:0;
        height:100%;
        width:100%;
        background:#eeeeee;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index:999;
    }
    .multiple-tabs-overlay .multple-tabs-content {
        width:100%;
        max-width:500px;
        text-align:center;
        padding:30px;
        background-color:#ffffff;
    }
</style>

<template>
    <div class="multiple-tabs-overlay">
        <div class="multple-tabs-content">
            <h3>Warning! Only one tab can be open to ensure data is saved correctly.</h3>
            <p>Please close other tabs and refresh the page to continue.</p>
            <p><button @click="refreshPage" class="btn btn-primary">Refresh Page</button></p>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        methods: {
            refreshPage(){
                window.location = this.$route.fullPath;
            }
        }
    }
</script>
