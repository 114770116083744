export const GET_ADD_EMPTY_ARRAY_EVALUATION_INTO_USER =
  'GET_ADD_EMPTY_ARRAY_EVALUATION_INTO_USER';
export const SET_ADD_EMPTY_ARRAY_EVALUATION_INTO_USER =
  'SET_ADD_EMPTY_ARRAY_EVALUATION_INTO_USER';

export const GET_UPDATE_EVALUATION = 'GET_UPDATE_EVALUATION';
export const SET_UPDATE_EVALUATION = 'SET_UPDATE_EVALUATION';

export const GET_DELETE_EVALUATION = 'GET_DELETE_EVALUATION';
export const SET_DELETE_EVALUATION = 'SET_DELETE_EVALUATION';

export const GET_EVALUATIONS = 'GET_EVALUATIONS';
export const SET_EVALUATIONS = 'SET_EVALUATIONS';

export const GET_EVALUATION_BY_ID = 'GET_EVALUATION_BY_ID';
export const SET_EVALUATION_BY_ID = 'SET_EVALUATION_BY_ID';

export const GET_EVALUATION_DELETE = 'GET_EVALUATION_DELETE';
export const SET_EVALUATION_DELETE = 'SET_EVALUATION_DELETE';

export const GET_CUSTOMER_EVALUATIONS = 'GET_CUSTOMER_EVALUATIONS';
export const SET_CUSTOMER_EVALUATIONS = 'SET_CUSTOMER_EVALUATIONS';

export const GET_ADD_EVALUATION_IN_DB = 'GET_ADD_EVALUATION_IN_DB';
export const SET_ADD_EVALUATION_IN_DB = 'SET_ADD_EVALUATION_IN_DB';

export const GET_USER_EVALUATIONS = 'GET_USER_EVALUATIONS';
export const SET_USER_EVALUATIONS = 'SET_USER_EVALUATIONS';

export const SET_CLIENT_IN_EVALUATION = 'SET_CLIENT_IN_EVALUATION';

// getters
export const GET_EVALUATIONS_BY_ID_GETTER = 'GET_EVALUATIONS_BY_ID_GETTER';
export const GET_EVALUATION_BY_ID_GETTER = 'GET_EVALUATION_BY_ID_GETTER';
export const GET_EVALUATION_BY_USER_ID_GETTER =
  'GET_EVALUATION_BY_USER_ID_GETTER';
export const GET_USER_EVALUATIONS_GETTER = 'GET_USER_EVALUATIONS_GETTER';
