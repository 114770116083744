<template>
  <div>
    <div class="loading" v-show="!isLoaded">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <div
      v-show="isLoaded"
      class="main-container main-container--margin-bt container"
    >
      <div class="card mb-0">
        <h1 class="h1">{{ 'My Profile' | translate }}</h1>
        <div class="card-body mt-4">
          <general-profile
            v-model="profile"
            :companies="userCompanies"
          ></general-profile>

          <div v-if="$route.path.search(/admin/) === -1">
            <div class="card-body__top">
              <h2 class="h2 card-body__top__h3">
                {{ 'Branding Image' | translate }}
              </h2>
            </div>
            <hr />
            <div class="row card-section">
              <div class="col-md-12">
                <p v-if="!profile.brandingImageBase64">
                  {{
                    'You can add an image to be included on all reports you generate.'
                      | translate
                  }}
                </p>
                <div class="brandingImg">
                  <img
                    class="w-100 mb-3 mb-md-0"
                    :src="
                      (profile.brandingImageBase64 || '').startsWith(
                        'data:image'
                      ) && dataURItoBlob(profile.brandingImageBase64)
                    "
                    alt=""
                    ref="brandingImageBase64"
                  />
                  <i
                    v-if="profile.brandingImageBase64"
                    class="fa fa-close"
                    @click="() => removeBrandingImage('profile')"
                  ></i>
                </div>
              </div>
              <div class="col-md-12">
                <label
                  for="upload-photo"
                  class="btn btn-primary btn-full--sm"
                  >{{ 'Upload Image' | translate }}</label
                >
                <input
                  class=""
                  ref="fileInput"
                  id="upload-photo"
                  @change="e => fileUpload(e, 'profile')"
                  type="file"
                  name="pic"
                  accept=".jpg, .png, .heif"
                />
                <p class="p--inline">{{ "Max upload size: 5MB (JPG, PNG, HEIF)" | translate }}</p>
                <p style="color: red;" v-if="fileSizeError" >
                  {{ "This file is too large, must be less than 5MB" | translate }}</p>
                <p style="color: red;" v-if="fileFormatError" >
                  {{ "Invalid file format. Only .jpg, .png, and .heif files are allowed." | translate }}</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              :class="{
                'col-md-12': $route.path.search(/admin/) === -1,
                'col-md-24': $route.path.search(/admin/) === 1,
              }"
            >
              <password-reset
                v-model="password"
                :user="profile"
              ></password-reset>
            </div>
          </div>
          <div class="mt-5" v-if="lastUpdatedDate">
            <div class="card-body__top">
              <h2 class="h2 card-body__top__h3">
                {{ 'Application Info' | translate }}
              </h2>
            </div>
            <hr />
            <p>{{ 'Last updated' | translate }}: {{ $store.getters.getDate(lastUpdatedDate) }}</p>
          </div>

          <!-- end row -->
        </div>

        <div
          v-if="confirmMessage"
          class="alert alert-primary mt-4"
          role="alert"
        >
          {{ 'Updated Successfully' | translate }}
        </div>
        <div v-if="errorMessage" class="alert alert-danger mt-4" role="alert">
          {{ errorMessageText | translate }}
        </div>

        <!-- end card body -->
        <div class="row">
          <div class="col-md-24">
            <button
              type="button"
              data-toggle="modal"
              data-target="#updateModal"
              :class="[
                'btn btn-primary btn-full--sm float-right',
                { 'mt-2': confirmMessage === true || errorMessage === true },
                { 'mt-4': confirmMessage === false && errorMessage === false },
              ]"
            >
              {{ 'Update' | translate }}
              <span v-if="loader" class="loader ml-1"></span>
            </button>

            <!-- Modal -->
            <div
              class="modal fade"
              id="updateModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="updateModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content edit-modal">
                  <h2 class="h2 text-center mb-0" id="updateModalLabel">
                    {{ 'Enter Your Current Password' | translate }} <br />
                    {{ 'to Confirm Changes' | translate }}
                  </h2>
                  <div class="modal-body my-0">
                    <input
                      type="password"
                      class="form-control"
                      name="passwordConfirm"
                      data-vv-as="Password"
                      v-model="passwordConfirm"
                    />
                    <span
                      v-show="errors.has('passwordConfirm')"
                      class="text-danger d-block"
                    >
                      <small>{{
                        'The Password field is required.' | translate
                      }}</small>
                    </span>
                    <div class="row d-flex">
                      <div
                        class="col-md-12 d-flex justify-content-center align-items-center order-2 order-md-1"
                      >
                        <a
                          href="#"
                          class="btn-cancel-modal"
                          data-dismiss="modal"
                        >
                          {{ 'Cancel' | translate }}
                        </a>
                      </div>
                      <div class="col-md-12 order-1 order-md-2">
                        <button
                          type="button"
                          @click="updateProfile"
                          class="btn btn-primary btn-full--sm mb-2 mb-md-0"
                          data-dismiss="modal"
                        >
                          {{ 'Save' | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end modal -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
    <!-- end container -->
  </div>
</template>

<script>
import Vue from 'vue';

// profile component
import generalProfile from '../components/admin/general.vue';
import passwordReset from '../components/admin/password-reset.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import imageUploadServiceMixin from '@/mixins/imageUploadFn.js';
import companyMixin from '@/mixins/companyMixin';

import {
  GET_UPDATE_ME,
  GET_UPLOAD_PROFILE_IMAGE,
  GET_VERIFY_USER_PASSWORD,
} from '../store/types';
export default {
  components: {
    generalProfile,
    passwordReset,
  },
  props: ['data'],
  mixins: [imageUploadServiceMixin, companyMixin],
  data() {
    return {
      confirmMessage: false,
      errorMessage: false,
      errorMessageText: null,
      fileSizeError: false,
      fileFormatError: false,
      isLoaded: true,
      loader: false,
      password: {
        new_password: null,
        new_password2: null,
        isActive: false,
      },
      passwordConfirm: null,
      passwordError: true,
      profile: {
        address: null,
        brandingImageBase64: '',
        brandingImageHeight: 0,
        brandingImageWidth: 0,
        brandingImageBase64: '',
        city: null,
        company: null,
        countryCode: null,
        currency: null,
        email: '',
        firstName: null,
        lastName: null,
        measurements: null,
        phone: null,
        postalCode: null,
        prefix: null,
        stateProvince: null,
        title: null,
      },
      selectedRep: null,
      zinproReps: [],
      lastUpdatedDate: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.users.user,
    }),
  },
  methods: {
    // map all the actions from store, into the components
    ...mapActions('users', [
      GET_VERIFY_USER_PASSWORD,
      GET_UPLOAD_PROFILE_IMAGE,
      GET_UPDATE_ME,
    ]),

    async updateProfile() {
      const result = await this.$validator.validateAll();

      if (result !== true) {
        this.loader = false;
        this.errorMessage = true;
        this.errorMessageText = 'Please fill in required fields';
        setTimeout(() => {
          this.errorMessage = false;
        }, 5000);
        return false;
      }

      try {
        await this[GET_VERIFY_USER_PASSWORD](this.passwordConfirm);
        this.loader = true;

        const updatedProfile = {
          ...this.profile,
          ...this.getCompanyName('profile'),
          dateUpdated: new Date().getTime(),
          stateProvince:
            this.profile.countryCode === 'US' ||
            this.profile.countryCode === 'CA'
              ? this.profile.stateProvince
              : null,
        };

        const { new_password, new_password2 } = this.password;
        const passwords = {
          password: new_password,
          confirmPassword: new_password2,
        };

        await this[GET_UPDATE_ME]({
          ...updatedProfile,
          ...(new_password && new_password ? passwords : {}),
        });

        // this.$store.commit(`users/${SET_SIGN_IN_USER}`, updatedProfile);

        this.loader = false;
        this.confirmMessage = true;
        setTimeout(() => {
          this.confirmMessage = false;
        }, 5000);
      } catch (err) {
        this.loader = false;
        this.errorMessage = true;
        console.log({ err });
        this.errorMessageText = this.generateErrorMsg(err);
        setTimeout(() => {
          this.errorMessage = false;
        }, 5000);
      }

      this.passwordConfirm = null;
    },
    generateErrorMsg({ errors, message = '' }) {
      return errors ? Object.values(errors).join(', ') : message;
    },
    async fileUpload(e, objectToUpdate) {
    
    
    // Getting the files from the vue upload
    var files = e.target.files;
    // Getting the first file
    var getFile = files[0];
    
    // Your existing size and format validation
    if (getFile.size / 1024 / 1024 > 5) {
      this.fileSizeError = true;
      setTimeout(() => {
        this.fileSizeError = false;
      }, 5000);
      this.resetFileInput();
      return;
    }
    const allowedFormats = ['image/jpeg', 'image/png', 'image/heif']; 
    if (!allowedFormats.includes(getFile.type)) {


      this.fileFormatError = true;
      setTimeout(() => {
        this.fileFormatError = false;
      }, 5000);
      this.resetFileInput();
      return;
    }
    var url = URL.createObjectURL(getFile);

  console.log(url);

  var self = this;

  const { dataUrl, height, width } = await this.getBase64WithWidthAndHeight(
    url
  );
  self[objectToUpdate].brandingImageBase64 = dataUrl;
  self[objectToUpdate].brandingImageHeight = height;
  self[objectToUpdate].brandingImageWidth = width;
  // this.imgUrls.push(url);
  this.toDataURL(url, function(dataUrl) {
    console.log({ dataUrl });
    self[objectToUpdate].brandingImageBase64 = dataUrl;
    self.$forceUpdate();
  });
  },

  // Add this new method to your component
  resetFileInput() {
    // Reset the file input
    this.$refs.fileInput.value = '';
  },
    },
  mounted() {
    if (window && localStorage) {
      this.lastUpdatedDate = localStorage.getItem('lastUpdated');
    }
    setTimeout(() => {
      this.profile = cloneDeep(this.user);
    }, 1000);
  },
};
</script>
