<template>
    <!-- Modal -->
    <div class="d-inline">
        <button data-toggle="modal" :data-target="'#addNew' + evaluationid" class="btn btn-primary--thin btn-full--sm mg-mb-sm">
           {{ 'Add Executive Summary' | translate }}
        </button>
        <div class="modal fade" :id="'addNew' + evaluationid" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content edit-modal">
                    <h2 class="h2 h2--modal text-center mt-3 mt-md-0" id="exampleModalLabel">
                        <slot name="modal-title"></slot>
                    </h2>
                    <div class="modal-body my-0 pt-1">
                        <slot name="modal-content"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:["evaluationid"],
        data() {
            return {
                showModal: false,
            }
        },
    }
</script>
