
const Table = require('../../Misc/Table');

class FootbathSlide {

    constructor(app, report, evaluation, group) {
        this.app = app;
        this.report = report;
        this.evaluation = evaluation;
        this.group = group;
    }

    addSlide(footbath, newFootbath) {
        if (newFootbath === undefined) newFootbath = false;

        let footbathType = (newFootbath === true) ? this.app.$t('Design New Footbath') : this.app.$t('Existing Footbath')

        let mixes = footbath.getMixes();

        //make sure that this footbath has mixes
        // if (mixes.length === 0) return false;

        //if there is at least on mix, then ensure that at least one mix has at least one product
        let hasProduct = false;
        for (let k = 0; k < mixes.length; k++) {
            if (mixes[k].getProducts().length > 0) {
                hasProduct = true;
                break;
            }
        }

        // if (hasProduct === false) return false;

        let slide = this.report.pptx.addSlide('Slide master');

        let createTableHygieneHeight = 6.75;
        let obj2 = [];
        let obj3;
        let obj4;
        let table;

        const mixMeasurement = this.evaluation.getMixMeasurements();

        let tableHeader = [
            {
                text: this.app.$t('Product'),
                options: this.report.styleOptions.tableHeader
            },
            {
                text: this.app.$t('Price ') + '(' + this.evaluation.getCurrency() + '/' + this.app.$t(this.app.$getEquivalentUnit(mixMeasurement, 'ml/L')) + this.app.$t(' or ') + this.evaluation.getCurrency() + '/' + this.app.$t(this.app.$getEquivalentUnit(mixMeasurement, 'g/L')) + ')',
                options: this.report.styleOptions.tableHeader
            },
            {
                text: this.app.$t('Conc (%)'),
                options: this.report.styleOptions.tableHeader
            },
            {
                text: this.app.$t('Incl Rate ') + '(' + this.app.$t(this.app.$getEquivalentUnit(mixMeasurement, 'ml/L')) + this.app.$t(' or ') + this.app.$t(this.app.$getEquivalentUnit(mixMeasurement, 'g/L')) + ')',
                options: this.report.styleOptions.tableHeader
            },
            {
                text: this.app.$t('Amt/Bath ') + '(' + this.app.$t(this.app.$getEquivalentUnit(mixMeasurement, 'kg')) + this.app.$t(' or ') +  this.app.$t(this.app.$getEquivalentUnit(mixMeasurement, 'l')) + ')',
                options: this.report.styleOptions.tableHeader
            },
            {
                text: this.evaluation.getCurrency() + '/' + this.app.$t('Bath'),
                options: this.report.styleOptions.tableHeader
            },
            {
                text: this.evaluation.getCurrency() + '/' + this.app.$t('cow') + '/' + this.app.$t('year'),
                options: this.report.styleOptions.tableHeader
            },
            {
                text: this.evaluation.getCurrency() + '/' + this.app.$t('group') + '/' + this.app.$t('year'),
                options: this.report.styleOptions.tableHeader
            }
        ];

        // @TODO Conditionally Display based on response @TODO
        let productPriceTotal = 0;

        slide.addText(
            this.app.$t('Hygiene/Footbath Assessor') + this.app.$t(' - ') + this.group.getName(), this.report.styleOptions.slideHeader
        );

        slide.addText(
            footbathType + this.app.$t(' - ') + footbath.getName(), {
                x: 0.5,
                y: 2.4,
                ...this.report.styleOptions.subheaderBlue
            }
        );

        for (let i = 0; i < mixes.length; i++) { // Start Mixes
            let products = mixes[i].getProducts();
            if (products.length > 0) {

                obj2.push([{
                    text: mixes[i].getName() + this.app.$t(' (Used ') + mixes[i].getTotalFootbathsPerWeek() + this.app.$t(' times per week)'),
                    options: {
                        colspan: 8,
                        color: '000000',
                        fill: 'efefef',
                        bold: true,
                        align: 'left'
                    }
                }]);

                createTableHygieneHeight += 1;

                let costPerCowPerYearTotal = 0;
                let costPerGroupPerYearTotal = 0;
                let costTotal = 0;


                for (let j = 0; j < products.length; j++) { // Start Products

                    let inclusionUnit = (products[j].getClassification() === 'Liquid') ? 'ml/L' : 'g/L';
                    let amountPerBathUnit = (products[j].getClassification() === 'Liquid') ? 'l' : 'kg';

                    costPerCowPerYearTotal += products[j].getCostPerCowPerYear(true);
                    costPerGroupPerYearTotal += products[j].getCostPerHerdPerYear(true);
                    costTotal += products[j].getCostPerBath(true);

                    obj2.push([{
                        text: products[j].getTitle(),
                        options: {
                            colspan: 1
                        }
                    }, {
                        text: products[j].getPricePerUnit(),
                    }, {
                        text: products[j].getConcentrationPercentage(),
                    }, {
                        text: products[j].getInclusionRate() + this.app.$t(' ') + this.app.$t(this.app.$getEquivalentUnit(mixMeasurement, inclusionUnit)),
                    }, {
                        text: products[j].getAmountPerFootbath() + this.app.$t(' ') + this.app.$t(this.app.$getEquivalentUnit(mixMeasurement, amountPerBathUnit)),
                    }, {
                        text: products[j].getCostPerBath(),
                    }, {
                        text: products[j].getCostPerCowPerYear(),
                    }, {
                        text: products[j].getCostPerHerdPerYear(),
                    }]);
                }; // End Products

                obj2.push([{
                    text: mixes[i].getName() + this.app.$t(' Total'),
                    options: {
                        colspan: 5,
                        color: '000000',
                        fill: 'ffffff',
                        bold: true,
                        align: 'right'
                    }
                }, {
                    text: costTotal.toCurrency(),
                }, {
                    text: costPerCowPerYearTotal.toCurrency(),
                }, {
                    text: costPerGroupPerYearTotal.toCurrency(),
                }]);

                obj2.push([{
                    text: this.app.$t('Total Waste Water Used/Year (') + this.app.$t(this.app.$getEquivalentUnit(mixMeasurement, 'l')) + ')',
                    options: {
                        color: '000000',
                        colspan: 7,
                        bold: true,
                        align: 'center',
                        fill: 'ffffff'
                    }
                }, {
                    text: mixes[i].getWasteWaterPerYear(2),
                    options: {
                        color: '000000',
                        align: 'center',
                        fill: 'ffffff',
                        bold: false
                    }
                }]);

                obj2.push([{
                    text: this.app.$t('Is there a footbath mixing system in place?'),
                    options: {
                        align: 'center',
                        bold: true,
                        color: '000000',
                        colspan: 7,
                        fill: 'ffffff'
                    }
                }, {
                    text: mixes[i].mix.footbath_system === 1 ? this.app.$t('Yes') : this.app.$t('No'),
                    options: {
                        align: 'center',
                        bold: false,
                        color: mixes[i].mix.footbath_system === 1 ? '249824' : 'a40000',
                        fill: 'ffffff',
                    }
                }]);

                if (mixes[i].mix.footbath_system === 1) {
                    obj2.push([{
                        text: this.app.$t('Is the bath mixed properly?'),
                        options: {
                            align: 'center',
                            bold: true,
                            color: '000000',
                            colspan: 7,
                            fill: 'ffffff'
                        }
                    }, {
                        text: mixes[i].mix.mixed_properly === 1 ? this.app.$t('Yes') : this.app.$t('No'),
                        options: {
                            align: 'center',
                            bold: false,
                            color: mixes[i].mix.mixed_properly === 1 ? '249824' : 'a40000',
                            fill: 'ffffff'
                        }
                    }]);
                }

                // obj2.push([{
                //     text: this.app.$t('* Hygiene score is weighted based on the number of animals in each group'),
                //     options: {
                //         color: 'ffffff',
                //         colspan: 8,
                //         align: 'c',
                //         valign: 'm',
                //         fill: '004684',
                //         bold: false
                //     }
                // }]);

            }

        } // End Mixes


        obj3 = [
            {
                text: app.$t('Question'),
                options: this.report.styleOptions.tableHeader
            },
            {
                text: app.$t('Answer'),
                options: this.report.styleOptions.tableHeader
            },
            {
                text: app.$t('Comment'),
                options: this.report.styleOptions.tableHeader
            }
        ]
        obj4 = [];

        if (footbath.isProperlyLocated() !== undefined) {
            obj4.push([{
                text: this.app.$t('Is the footbath properly located?'),
            }, {
                text: footbath.isProperlyLocated() === 1 ? this.app.$t('Yes') : this.app.$t('No'),
            }, {
                text: footbath.isProperlyLocated() === 1 ? this.app.$t('Good!') : this.app.$t('Locate footbaths where they facilitate cow flow.'),
                options: {
                    color: footbath.isProperlyLocated() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (footbath.isStones() !== undefined) {
            obj4.push([{
                text: this.app.$t('Are there stones at the bottom?'),
            }, {
                text: footbath.isStones() === 1 ? this.app.$t('Yes') : this.app.$t('No'),
            }, {
                text: footbath.isStones() === 1 ? this.app.$t('Stones and debris can cause bruising to the sole of the hoof.') : this.app.$t('Good!'),
                options: {
                    color: footbath.isStones() === 1 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (footbath.isFloorDeteriorated() !== undefined) {
            obj4.push([{
                text: this.app.$t('Is the floor deteriorated?'),
            }, {
                text: footbath.isFloorDeteriorated() === 1 ? this.app.$t('Yes') : this.app.$t('No'),
            }, {
                text: footbath.isFloorDeteriorated() === 1 ? this.app.$t('Cows may resist walking through footbaths if flooring is deteriorated, overly rough or painful to walk on. This, in turn, may injure their feet.') : this.app.$t('Good!'),
                options: {
                    color: footbath.isFloorDeteriorated() === 1 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (footbath.canAvoidStepping() !== undefined) {
            obj4.push([{
                text: this.app.$t('Is there a way the cow can avoid stepping into the footbath?'),
            }, {
                text: footbath.canAvoidStepping() === 1 ? this.app.$t('Yes') : this.app.$t('No'),
            }, {
                text: footbath.canAvoidStepping() === 1 ? this.app.$t('The effect of the footbath is greatly reduced.') : this.app.$t('Good!'),
                options: {
                    color: footbath.canAvoidStepping() === 1 ? 'a40000' : '249824',
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (footbath.canBypass() !== undefined) {
            obj4.push([{
                text: this.app.$t('Is it possible to bypass the footbath when not in use?'),
            }, {
                text: footbath.canBypass() === 1 ? this.app.$t('Yes') : this.app.$t('No'),
            }, {
                text: footbath.canBypass() === 1 ? this.app.$t('Good!') : this.app.$t('When not in use, footbaths can fill with manure and spread infectious hoof disease. Lesion healing may be slowed by some footbath chemicals.'),
                options: {
                    color: footbath.canBypass() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (footbath.isCleanPath() !== undefined) {
            obj4.push([{
                text: this.app.$t('Do cows walk from the footbath to the freestalls in a clean path?'),
            }, {
                text: footbath.isCleanPath() === 1 ? this.app.$t('Yes') : this.app.$t('No'),
            }, {
                text: footbath.isCleanPath() === 1 ? this.app.$t('Good!') : this.app.$t('The effect of the footbath is greatly reduced.'),
                options: {
                    color: footbath.isCleanPath() === 1 ? '249824' : 'a40000',
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (footbath.getLaborCost() !== undefined) {
            obj4.push([{
                text: this.app.$t('Labor Costs / Bath'),
            }, {
                text: footbath.getLaborCost(),
            }, {
                text: '',
                options: {
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (footbath.getCowPassesPerBath() !== undefined) {
            obj4.push([{
                text: this.app.$t('Cow Passes / Bath'),
            }, {
                text: footbath.getCowPassesPerBath(),
            }, {
                text: footbath.getCowPassesPerBath() >= footbath.getIdealCowPassesPerBath() ? this.app.$t(`Most chemicals should be changed every ${footbath.getIdealCowPassesPerBath()} passes.`) : this.app.$t('Good!'),
                options: {
                    color: footbath.getCowPassesPerBath() >= footbath.getIdealCowPassesPerBath() ? '000000' : '249824',
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (footbath.getPhLevel() !== undefined && footbath.getPhLevel() !== "") {
            obj4.push([{
                text: this.app.$t('pH Level'),
            }, {
                text: footbath.getPhLevel(),
            }, {
                text: '',
                options: {
                    color: '000000',
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (footbath.getLength() !== undefined) {
            // const measurements = this.evaluation.getMeasurements() === 'imperial' ? false : true;
             const getSmallLengthValue = footbath.getSmallLengthValue()

            obj4.push([{
                text: this.app.$t('Footbath Length (') + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
            }, {
                text: footbath.getLength(),
            }, {
                    text: footbath.getLength() >= getSmallLengthValue ?
                        this.app.$t('Good!') :
                        this.app.$t('Footbaths shorter than ') + this.app.$t('10 feet / 3 meters') + this.app.$t(' will allow a higher than acceptable percentage of cows to walk through the bath with one rear foot receiving only one immersion per pass.'),
                options: {
                    color: footbath.getLength() >= getSmallLengthValue ? '249824' : '000000',
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (footbath !== undefined) {
            obj4.push([{
                text: this.app.$t('Footbath Width (') + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + ')',
            }, {
                text: footbath.getWidth(),
            }, {
                text: footbath < 60 ? this.app.$t('Footbaths that are too narrow can impede cow flow.') : this.app.$t('Footbaths need to be at least the width of the alley. If the footbath is narrower than the alley, the footbath should have side walls so all feet of the cow must pass through the footbath.'),
                options: {
                    color: '000000',
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (footbath.getStepIn() !== undefined) {
            obj4.push([{
                // text: this.app.$t('Depth (') + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + this.app.$t(') of Solution at Step In'),
                text: this.evaluation.isImperial ? this.app.$t('Depth (in) of Solution at Step In') : this.app.$t('Depth (cm) of Solution at Step In') 
            }, {
                text: footbath.getStepIn(),
            }, {
                text: footbath.getStepIn() >= footbath.getSmallDepthValue() ?
                this.evaluation.isImperial ?
                 this.app.$t('Good! A step in depth of at least 4 inches will allow for proper penetration of chemical into the interdigital space.') :
                 this.app.$t('Good! A step in depth of at least 10 cm will allow for proper penetration of chemical into the interdigital space.')
                : this.evaluation.isImperial ? this.app.$t('Baths that are too shallow, less than 4 inches, will not allow for penetration of chemical into interdigital space.') : this.app.$t("Baths that are too shallow, less than 10 cm, will not allow for penetration of chemical into interdigital space."),
                // this.app.$t('Baths that are too shallow, less than ') + footbath.getSmallDepthValue() + ' ' + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + this.app.$t(', will not allow for penetration of chemical into interdigital space.'),
                options: {
                    color: footbath.getStepIn() >= footbath.getSmallDepthValue() ? '249824' : '000000',
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (footbath.getStepOut() !== undefined) {
            obj4.push([{
                // text: this.app.$t('Depth (') + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + this.app.$t(') of Solution at Step Out'),
                text: this.evaluation.isImperial ? this.app.$t('Depth (in) of Solution at Step Out')  : this.app.$t('Depth (cm) of Solution at Step Out')
            }, {
                text: footbath.getStepOut(),
            }, {
                text: '',
                options: {
                    color: '000000',
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (footbath.getVolume() !== undefined) {
            obj4.push([{
                text: this.app.$t('Footbath Volume (') + this.app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'l')) + ')',
            }, {
                text: footbath.getVolume(),
            }, {
                text: this.app.$t('It is possible that compounds discussed here may be illegal in your area. Please check local regulations.'),
                options: {
                    color: '000000',
                    fill: 'efefef',
                    bold: false,
                    align: 'left'
                }
            }]);
        }

        if (obj4.length > 0) {
            Table.create(slide, obj3, obj4, this.report, this.group, 'Hygiene/Footbath Assessor', {
                y: 2.7,
                colW: [2.25, 1, 4.25],
                ...this.report.styleOptions.tableOptions
            });

            slide = this.report.pptx.addSlide('Slide master');
            slide.addText(
                this.app.$t('Hygiene/Footbath Assessor') + this.app.$t(' - ') + this.group.getName(), this.report.styleOptions.slideHeader
            );

            slide.addText(
                footbathType + this.app.$t(' - ') + footbath.getName(), {
                    x: 0.5,
                    y: 2.38,
                    ...this.report.styleOptions.subheaderBlue
                }
            );

        }

        // Mixes and Products Table in Footbath
        Table.create(slide, tableHeader, obj2, this.report, this.group, 'Hygiene/Footbath Assessor', {
            y: 2.6,
            ...this.report.styleOptions.tableOptions
        });

    }
}

export default FootbathSlide;
