/**
 * The lesion assessor determines the overall hoof health of a group of cows by recording the number of lesions present in the group. The
 * farmer can then use the lesion information as a comparison for future assessments to assess the overall breakdown of lesions in the herd
 * is changing depending on whatever treatment has been done in between assessments.
 */

import _ from 'lodash';
import Assessor from './Assessor';

class Lesion extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;
    }

    getWhatTypeOfLesionsDoYouWantToEnter() {
        return this.assessor.data.what_type_of_lesions_do_you_want_to_enter;
    }

    getNumberOfBlocksApplied() {
        return this.assessor.data.number_of_blocks_applied;
    }

    getNumberOfWrapsApplied() {
        return this.assessor.data.number_of_wraps_applied;
    }

    getSoleUlcerLame() {
        if (this.assessor.data.sole_ulcer_lame === '' || this.assessor.data.sole_ulcer_lame === null) {
            return 0;
        }
        return this.assessor.data.sole_ulcer_lame;
    }

    getSoleUlcerTrim() {
        if (this.assessor.data.sole_ulcer_trim === '' || this.assessor.data.sole_ulcer_trim === null) {
            return 0;
        }
        return this.assessor.data.sole_ulcer_trim;
    }

    getSoleFractureHeelUlcerLame() {
        if (this.assessor.data.sole_fracture_heel_ulcer_lame === '' || this.assessor.data.sole_fracture_heel_ulcer_lame === null) {
            return 0;
        }
        return this.assessor.data.sole_fracture_heel_ulcer_lame;
    }

    getSoleFractureHeelUlcerTrim() {
        if (this.assessor.data.sole_fracture_heel_ulcer_trim === '' || this.assessor.data.sole_fracture_heel_ulcer_trim === null) {
            return 0;
        }
        return this.assessor.data.sole_fracture_heel_ulcer_trim;
    }

    getSoleHemorrhageBruiseLame() {
        if (this.assessor.data.sole_hemorrhage_bruise_lame === '' || this.assessor.data.sole_hemorrhage_bruise_lame === null) {
            return 0;
        }
        return this.assessor.data.sole_hemorrhage_bruise_lame;
    }

    getSoleHemorrhageBruiseTrim() {
        if (this.assessor.data.sole_hemorrhage_bruise_trim === '' || this.assessor.data.sole_hemorrhage_bruise_trim === null) {
            return 0;
        }
        return this.assessor.data.sole_hemorrhage_bruise_trim;
    }

    getAxialWallCrackLame() {
        if (this.assessor.data.axial_wall_crack_lame === '' || this.assessor.data.axial_wall_crack_lame === null) {
            return 0;
        }
        return this.assessor.data.axial_wall_crack_lame;
    }

    getAxialWallCrackTrim() {
        if (this.assessor.data.axial_wall_crack_trim === '' || this.assessor.data.axial_wall_crack_trim === null) {
            return 0;
        }
        return this.assessor.data.axial_wall_crack_trim;
    }

    getHorizontalFissureTrim() {
        if (this.assessor.data.horizontal_fissure_trim === '' || this.assessor.data.horizontal_fissure_trim === null) {
            return 0;
        }
        return this.assessor.data.horizontal_fissure_trim;
    }

    getHorizontalFissureLame() {
        if (this.assessor.data.horizontal_fissure_lame === '' || this.assessor.data.horizontal_fissure_lame === null) {
            return 0;
        }
        return this.assessor.data.horizontal_fissure_lame;
    }

    getCorkscrewClawTrim() {
        if (this.assessor.data.corkscrew_claw_trim === '' || this.assessor.data.corkscrew_claw_trim === null) {
            return 0;
        }
        return this.assessor.data.corkscrew_claw_trim;
    }

    getCorkscrewClawLame() {
        if (this.assessor.data.corkscrew_claw_lame === '' || this.assessor.data.corkscrew_claw_lame === null) {
            return 0;
        }
        return this.assessor.data.corkscrew_claw_lame;
    }

    getDeepDigitalSepsisTrim() {
        if (this.assessor.data.deep_digital_sepsis_trim === '' || this.assessor.data.deep_digital_sepsis_trim === null) {
            return 0;
        }
        return this.assessor.data.deep_digital_sepsis_trim;
    }

    getDeepDigitalSepsisLame() {
        if (this.assessor.data.deep_digital_sepsis_lame === '' || this.assessor.data.deep_digital_sepsis_lame === null) {
            return 0;
        }
        return this.assessor.data.deep_digital_sepsis_lame;
    }

    getHeelHornErosionTrim() {
        if (this.assessor.data.heel_horn_erosion_trim === '' || this.assessor.data.heel_horn_erosion_trim === null) {
            return 0;
        }
        return this.assessor.data.heel_horn_erosion_trim;
    }

    getHeelHornErosionLame() {
        if (this.assessor.data.heel_horn_erosion_lame === '' || this.assessor.data.heel_horn_erosion_lame === null) {
            return 0;
        }
        return this.assessor.data.heel_horn_erosion_lame;
    }

    getInterdigitalPhlegmonFootRotTrim() {
        if (this.assessor.data.interdigital_phlegmon_foot_rot_trim === '' || this.assessor.data.interdigital_phlegmon_foot_rot_trim === null) {
            return 0;
        }
        return this.assessor.data.interdigital_phlegmon_foot_rot_trim;
    }

    getInterdigitalPhlegmonFootRotLame() {
        if (this.assessor.data.interdigital_phlegmon_foot_rot_lame === '' || this.assessor.data.interdigital_phlegmon_foot_rot_lame === null) {
            return 0;
        }
        return this.assessor.data.interdigital_phlegmon_foot_rot_lame;
    }

    getToeUlcerTrim() {
        if (this.assessor.data.toe_ulcer_trim === '' || this.assessor.data.toe_ulcer_trim === null) {
            return 0;
        }
        return this.assessor.data.toe_ulcer_trim;
    }

    getToeUlcerLame() {
        if (this.assessor.data.toe_ulcer_lame === '' || this.assessor.data.toe_ulcer_lame === null) {
            return 0;
        }
        return this.assessor.data.toe_ulcer_lame;
    }

    getThinSoleTrim() {
        if (this.assessor.data.thin_sole_trim === '' || this.assessor.data.thin_sole_trim === null) {
            return 0;
        }
        return this.assessor.data.thin_sole_trim;
    }

    getThinSoleLame() {
        if (this.assessor.data.thin_sole_lame === '' || this.assessor.data.thin_sole_lame === null) {
            return 0;
        }
        return this.assessor.data.thin_sole_lame;
    }

    getWhiteLineDiseaseTrim() {
        if (this.assessor.data.white_line_disease_trim === '' || this.assessor.data.white_line_disease_trim === null) {
            return 0;
        }
        return this.assessor.data.white_line_disease_trim;
    }

    getWhiteLineDiseaseLame() {
        if (this.assessor.data.white_line_disease_lame === '' || this.assessor.data.white_line_disease_lame === null) {
            return 0;
        }
        return this.assessor.data.white_line_disease_lame;
    }

    getInterdigitalHyperplasiaTrim() {
        if (this.assessor.data.interdigital_hyperplasia_trim === '' || this.assessor.data.interdigital_hyperplasia_trim === null) {
            return 0;
        }
        return this.assessor.data.interdigital_hyperplasia_trim;
    }

    getInterdigitalHyperplasiaLame() {
        if (this.assessor.data.interdigital_hyperplasia_lame === '' || this.assessor.data.interdigital_hyperplasia_lame === null) {
            return 0;
        }
        return this.assessor.data.interdigital_hyperplasia_lame;
    }

    getVerticalFissureTrim() {
        if (this.assessor.data.vertical_fissure_trim === '' || this.assessor.data.vertical_fissure_trim === null) {
            return 0;
        }
        return this.assessor.data.vertical_fissure_trim;
    }

    getVerticalFissureLame() {
        if (this.assessor.data.vertical_fissure_lame === '' || this.assessor.data.vertical_fissure_lame === null) {
            return 0;
        }
        return this.assessor.data.vertical_fissure_lame;
    }

    getInjuryTrim() {
        if (this.assessor.data.injury_trim === '' || this.assessor.data.injury_trim === null) {
            return 0;
        }
        return this.assessor.data.injury_trim;
    }

    getInjuryLame() {
        if (this.assessor.data.injury_lame === '' || this.assessor.data.injury_lame === null) {
            return 0;
        }
        return this.assessor.data.injury_lame;
    }

    getDigitalDermatitisTrim() {
        if (this.assessor.data.digital_dermatitis_trim === '' || this.assessor.data.digital_dermatitis_trim === null) {
            return 0;
        }
        return this.assessor.data.digital_dermatitis_trim;
    }

    getDigitalDermatitisLame() {
        if (this.assessor.data.digital_dermatitis_lame === '' || this.assessor.data.digital_dermatitis_lame === null) {
            return 0;
        }
        return this.assessor.data.digital_dermatitis_lame;
    }

    getInterdigitalDermatitisTrim() {
        if (this.assessor.data.interdigital_dermatitis_trim === '' || this.assessor.data.interdigital_dermatitis_trim === null) {
            return 0;
        }
        return this.assessor.data.interdigital_dermatitis_trim;
    }

    getInterdigitalDermatitisLame() {
        if (this.assessor.data.interdigital_dermatitis_lame === '' || this.assessor.data.interdigital_dermatitis_lame === null) {
            return 0;
        }
        return this.assessor.data.interdigital_dermatitis_lame;
    }

    // report functions
    // helper function
    getLesionValues(returnFull = false) {
        const values = {
            lame: [
                {isInfectious:false,title:this.app.$t('Sole Ulcer (U)'),value:this.getSoleUlcerLame()},
                {isInfectious:false,title:this.app.$t('Sole Fracture/Heel Ulcer (S)'),value:this.getSoleFractureHeelUlcerLame()},
                {isInfectious:false,title:this.app.$t('Sole Hemorrhage/Bruise (H)'),value:this.getSoleHemorrhageBruiseLame()},
                {isInfectious:false,title:this.app.$t('Axial Wall Crack (X)'),value:this.getAxialWallCrackLame()},
                {isInfectious:false,title:this.app.$t('Horizontal Fissure (G)'),value:this.getHorizontalFissureLame()},
                {isInfectious:false,title:this.app.$t('Corkscrew Claw (C)'),value:this.getCorkscrewClawLame()},
                {isInfectious:false,title:this.app.$t('Deep Digital Sepsis'),value:this.getDeepDigitalSepsisLame()},
                {isInfectious:true,title:this.app.$t('Heel Horn Erosion (E)'),value:this.getHeelHornErosionLame()},
                {isInfectious:true,title:this.app.$t('Interdigital Phlegmon/Foot Rot (F)'),value:this.getInterdigitalPhlegmonFootRotLame()},
                {isInfectious:false,title:this.app.$t('Toe Ulcer (T)'),value:this.getToeUlcerLame()},
                {isInfectious:false,title:this.app.$t('Thin Sole (Z)'),value:this.getThinSoleLame()},
                {isInfectious:false,title:this.app.$t('White Line Disease (W)'),value:this.getWhiteLineDiseaseLame()},
                {isInfectious:false,title:this.app.$t('Interdigital Hyperplasia (K)'),value:this.getInterdigitalHyperplasiaLame()},
                {isInfectious:false,title:this.app.$t('Vertical Fissure (V)'),value:this.getVerticalFissureLame()},
                {isInfectious:false,title:this.app.$t('Injury (J)'),value:this.getInjuryLame()},
                {isInfectious:true,title:this.app.$t('Digital Dermatitis (D)'),value:this.getDigitalDermatitisLame()},
                {isInfectious:true,title:this.app.$t('Interdigital Dermatitis (I)'),value:this.getInterdigitalDermatitisLame()},
            ],
            trim: [
                {isInfectious:false,title:this.app.$t('Sole Ulcer (U)'),value:this.getSoleUlcerTrim()},
                {isInfectious:false,title:this.app.$t('Sole Fracture/Heel Ulcer (S)'),value:this.getSoleFractureHeelUlcerTrim()},
                {isInfectious:false,title:this.app.$t('Sole Hemorrhage/Bruise (H)'),value:this.getSoleHemorrhageBruiseTrim()},
                {isInfectious:false,title:this.app.$t('Axial Wall Crack (X)'),value:this.getAxialWallCrackTrim()},
                {isInfectious:false,title:this.app.$t('Horizontal Fissure (G)'),value:this.getHorizontalFissureTrim()},
                {isInfectious:false,title:this.app.$t('Corkscrew Claw (C)'),value:this.getCorkscrewClawTrim()},
                {isInfectious:false,title:this.app.$t('Deep Digital Sepsis'),value:this.getDeepDigitalSepsisTrim()},
                {isInfectious:true,title:this.app.$t('Heel Horn Erosion (E)'),value:this.getHeelHornErosionTrim()},
                {isInfectious:true,title:this.app.$t('Interdigital Phlegmon/Foot Rot (F)'),value:this.getInterdigitalPhlegmonFootRotTrim()},
                {isInfectious:false,title:this.app.$t('Toe Ulcer (T)'),value:this.getToeUlcerTrim()},
                {isInfectious:false,title:this.app.$t('Thin Sole (Z)'),value:this.getThinSoleTrim()},
                {isInfectious:false,title:this.app.$t('White Line Disease (W)'),value:this.getWhiteLineDiseaseTrim()},
                {isInfectious:false,title:this.app.$t('Interdigital Hyperplasia (K)'),value:this.getInterdigitalHyperplasiaTrim()},
                {isInfectious:false,title:this.app.$t('Vertical Fissure (V)'),value:this.getVerticalFissureTrim()},
                {isInfectious:false,title:this.app.$t('Injury (J)'),value:this.getInjuryTrim()},
                {isInfectious:true,title:this.app.$t('Digital Dermatitis (D)'),value:this.getDigitalDermatitisTrim()},
                {isInfectious:true,title:this.app.$t('Interdigital Dermatitis (I)'),value:this.getInterdigitalDermatitisTrim()},
            ]
        }
        if (returnFull) {
            return values;
        }
        if (this.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame') {
            return values.lame;
        } else if (this.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim') {
            return values.trim;
        }

        return values;
    }

    getLesionSum(lesionToReturn) {
        let lesionValues;
        if (lesionToReturn) {
            lesionValues = this.getRemoveNullValues(lesionToReturn)
        } else {
            lesionValues = this.getRemoveNullValues()
        }

        return _.sumBy(lesionValues, v => v.value);
    }

    getNonInfectiousOrInfectiousTotal(isInfected, lesionToReturn) {
        let lesionValues = this.getRemoveNullValues(lesionToReturn)
            .filter(v => v.isInfectious === isInfected);

        return _.sumBy(lesionValues, v => v.value);
    }

    getNonInfectiousOrInfectiousPercent(isInfected, lesionToReturn) {
        const infectionSum = this.getNonInfectiousOrInfectiousTotal(isInfected, lesionToReturn);
        const lesionSum = this.getLesionSum(lesionToReturn);
        return Number((infectionSum / lesionSum)).toPercentage(1);
    }

    getLesionPercent(showField, lesionTypeAmount, lesionToReturn = false) {
        const lesionSum = this.getLesionSum(lesionToReturn === 'both' ? false : lesionToReturn);
        if (lesionTypeAmount === null || lesionTypeAmount === 0 || showField === false) {
            return '';
        }
        return (lesionTypeAmount / lesionSum).toPercentage(1) + '%';
    }

    getRemoveNullValues(lesionToReturn) {
        let lesionValues = this.getLesionValues();

        if (lesionToReturn === 'trim' && this.getWhatTypeOfLesionsDoYouWantToEnter() === 'both') {
            lesionValues = lesionValues.trim;
        } else if (lesionToReturn === 'lame' && this.getWhatTypeOfLesionsDoYouWantToEnter() === 'both') {
            lesionValues = lesionValues.lame;
        }

        if (!lesionToReturn && this.getWhatTypeOfLesionsDoYouWantToEnter() === 'both') {
            lesionValues = _.concat(lesionValues.lame, lesionValues.trim);
        }
        return Array.isArray(lesionValues) ? lesionValues.filter(v => v.value !== 0) : lesionValues;
    }

    // comparator function
    bothZero(a, b) {
        return a.value == 0 && b.value == 0;
    }


    getRemoveNullValuesForBarGraph(lesionToReturn) {
        let lesionValues = this.getLesionValues(true);
        let output = []
        let lesionCode = ['trim','lame'].includes(lesionToReturn) ? lesionToReturn : 'trim'
        console.log({ lesionCode, lesionToReturn, lesionValues })
        // remove values where they are equivalent to 0 in both arrays
        for (let valueIndex = 0; valueIndex < lesionValues[lesionCode].length; valueIndex++) {
            if (lesionValues.trim[valueIndex].value === 0 && lesionValues.lame[valueIndex].value === 0) {
                continue
            } else {
                output.push(lesionValues[lesionCode][valueIndex])
            }
        }
        return output
    }

    getBarValuesPercentages(lesionToReturn) {
        const lesionSum = this.getLesionSum();
        // turn into an array of values
        const values = _.map(this.getRemoveNullValuesForBarGraph(lesionToReturn), 'value');
        let percentages = [];

        for (let i = 0; i < values.length; i++) {
            percentages.push((values[i] / lesionSum).toPercentage(1));
        }

        return percentages;
    }

    getBarTitles(lesionToReturn) {
        return _.map(this.getRemoveNullValuesForBarGraph(lesionToReturn), 'title');
    }
}

export default Lesion;
