/**
 * The Tiestall/Stanchion Heat Abatement Assessor evaluates tunnel and cross-ventilated facilities to ensure a sufficient
 * amount of air exchanges per hour is achieved.
 */

const convert = require('convert-units');
import Assessor from './Assessor';

class TieStallStanchionHeatAbatement extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;
    }

    getSummerVentilationSystem() {
        return this.assessor.data.summer_ventilation_system;
    }

    getNumberOfFansOne() {
        return this.assessor.data.number_of_fans_1;
    }

    getNumberOfFansTwo() {
        return this.assessor.data.number_of_fans_2;
    }

    getNumberOfFansThree() {
        return this.assessor.data.number_of_fans_3;
    }

    getFanWidthOne() {
        if (this.assessor.data.fan_width_1 === null || this.assessor.data.fan_width_1 === undefined) {
            return null;
        }

        return convert(this.assessor.data.fan_width_1)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(0);
    }

    setFanWidthOne(newValue) {
        if (newValue < 1) {
            return this.assessor.data.fan_width_1 = null;
        }

    this.assessor.data.fan_width_1 = parseFloat(convert(newValue)
        .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
        .to('cm'))
        .toDecimal(newValue.countDecimals() + 3);
    }

    getFanWidthTwo() {

        if (this.assessor.data.fan_width_2 === null || this.assessor.data.fan_width_2 === undefined) {
            return null;
        }

        return convert(this.assessor.data.fan_width_2)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(0);
    }

    setFanWidthTwo(newValue) {
        if (newValue < 1) {
            return this.assessor.data.fan_width_2 = null;
        }

    this.assessor.data.fan_width_2 = parseFloat(convert(newValue)
        .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
        .to('cm'))
        .toDecimal(newValue.countDecimals() + 3);
    }

    getFanWidthThree() {
        if (this.assessor.data.fan_width_3 === null || this.assessor.data.fan_width_3 === undefined) {
            return null;
        }

        return convert(this.assessor.data.fan_width_3)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(0);
    };

    setFanWidthThree(newValue) {
       if (newValue < 1) {
            return this.assessor.data.fan_width_3 = null;
        }

        this.assessor.data.fan_width_3 = parseFloat(convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getAreCowsAllowedOutOfTheBarnAtNightToCoolOff() {
        return this.assessor.data.are_cows_allowed_out_of_the_barn_at_night_to_cool_off;
    }

    getAreSoakersMistersUsedToHelpCoolCowsOff() {
        return this.assessor.data.are_soakers_misters_used_to_help_cool_cows_off;
    }

    getIsThereShadeOverOutsideFeedArea() {
        return this.assessor.data.is_there_shade_over_outside_feed_area;
    }

    getPrevailingWindDirection() {
        return this.assessor.data.prevailing_wind_direction;
    }

    getBarnLength(roundPoint) {
        if (this.assessor.data.barn_length === undefined || this.assessor.data.barn_length === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.barn_length.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.barn_length)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(roundPoint);
    }

    setBarnLength(newValue) {
        if (newValue === '') {
            return this.assessor.data.barn_length = null;
        }

        this.assessor.data.barn_length = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
                .to('m'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getBarnWidth(roundPoint) {
        if (this.assessor.data.barn_width === undefined || this.assessor.data.barn_width === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.barn_width.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.barn_width)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(roundPoint);
    }

    setBarnWidth(newValue) {
        if (newValue === '') {
            return this.assessor.data.barn_width = null;
        }

        this.assessor.data.barn_width = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
                .to('m'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getBarnCeilingHeight(roundPoint) {
        if (this.assessor.data.barn_ceiling_height === undefined || this.assessor.data.barn_ceiling_height === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.barn_ceiling_height.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.barn_ceiling_height)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(roundPoint);
    }

    setBarnCeilingHeight(newValue) {
        if (newValue === '') {
            return this.assessor.data.barn_ceiling_height = null;
        }

        this.assessor.data.barn_ceiling_height = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
                .to('m'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getInletArea(roundPoint) {
        if (this.assessor.data.inlet_area === undefined || this.assessor.data.inlet_area === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.inlet_area.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.inlet_area)
            .from('m2')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm2'))
            .toDecimal(roundPoint);
    }

    setInletArea(newValue) {
        if (newValue === '') {
            return this.assessor.data.inlet_area = null;
        }

        this.assessor.data.inlet_area = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm2'))
                .to('m2'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    /**
     * Calculations
     */
    getCrossSectionalArea() {
        if (this.getBarnWidth() && this.getBarnCeilingHeight()) {
            // Calculation = [Barn Width] * [Barn Height]
            return this.getBarnWidth() * this.getBarnCeilingHeight();
        }

        return null
    }

    getBarnVolume() {
        if (this.getSummerVentilationSystem() == 'cross' && this.getBarnWidth() && this.getBarnCeilingHeight() && this.getBarnLength()) {
            // Calculation = [Barn Width] * [Barn Height] * [Barn Length]
            return this.getBarnWidth() * this.getBarnCeilingHeight() * this.getBarnLength();
        }

        return null
    }

    calculateTargetInletArea() {
        let target;
        if (this.getSummerVentilationSystem() == 'tunnel') {
            const targetAirMovementCapacity = this.calculateTargetAirMovementCapacity();
            if (this.evaluation.getMeasurements() === 'metric') {
                // target = (TotalAirMovementCapacityTarget / 1700) * 0.23225 as a whole number
                target = Math.round((targetAirMovementCapacity / 1700) * 0.23225);
            } else {
                // target = TotalAirMovementCapacityTarget / 400 as a whole number
                target = Math.round(targetAirMovementCapacity / 400);
            }
        } else {
            if (this.getSummerVentilationSystem() == 'cross' && this.getBarnWidth() && this.getBarnCeilingHeight() && this.getBarnLength()) {
                // Barn Volume = Barn Width * Barn Height * Barn Length
                const barnVolume = this.getBarnVolume()
                if (this.evaluation.getMeasurements() === 'metric') {
                    // target = (Barn Width * Barn Height * Barn Length * 40) / 9144 as a whole number
                    target = Math.round(barnVolume * 40 / 9144);
                } else {
                    // target = Barn Volume / 750 as a whole number
                    target = Math.round(barnVolume / 750);
                }
            }
        }

        return target
    }

    calculateTargetAirMovementCapacity() {
        let targetAirMovementCapacity = 0;
        if (this.getSummerVentilationSystem() == 'tunnel' && this.getBarnWidth() && this.getBarnCeilingHeight()) {
            const endArea = this.getBarnWidth() * this.getBarnCeilingHeight();
            if (this.evaluation.getMeasurements() === 'metric') {
                // TotalAirMovementCapacityTarget = [EndArea] * 4023
                targetAirMovementCapacity = endArea * 4023;
            } else {
                // TotalAirMovementCapacityTarget = [EndArea] * 220
                targetAirMovementCapacity = endArea * 220;
            }
        } else if (this.getBarnLength() && this.getBarnWidth() && this.getBarnCeilingHeight()) {
            // Barn Volume = Barn Width * Barn Height * Barn Length
            const barnVolume = this.getBarnWidth() * this.getBarnCeilingHeight() * this.getBarnLength();
            if (this.evaluation.getMeasurements() === 'metric') {
                // TotalAirMovementCapacityTarget = [BarnVol] * 40
                targetAirMovementCapacity = barnVolume * 40;
            } else {
                // TotalAirMovementCapacityTarget = [BarnVol] / 1.5
                targetAirMovementCapacity = barnVolume / 1.5;
            }
        }

        return Math.round(targetAirMovementCapacity);
    }

    /*
     * Total Air Movement Capacity = ([FanCount1] * [Fan width cell value (cfm / cmh) from tiestallheat.csv]) +
     *                               ([FanCount2] * [Fan width cell value (cfm / cmh) from tiestallheat.csv]) +
     *                               ([FanCount3] * [Fan width cell value (cfm / cmh) from tiestallheat.csv])
     */
    calculateTotalAirMovementCapacity() {
        let totalAirMovementCapacity = 0.0;

        if (this.getFanWidthOne() && this.getNumberOfFansOne()) {
            totalAirMovementCapacity = this.getNumberOfFansOne() * this.getCalcFanWidth('fan_width_1');
        }

        if (this.getFanWidthTwo() && this.getNumberOfFansTwo()) {
            totalAirMovementCapacity += this.getNumberOfFansTwo() * this.getCalcFanWidth('fan_width_2');
        }

        if (this.getFanWidthThree() && this.getNumberOfFansThree()) {
            totalAirMovementCapacity += this.getNumberOfFansThree() * this.getCalcFanWidth('fan_width_3');
        }

        return totalAirMovementCapacity > 0.0 ? Math.round(totalAirMovementCapacity) : null;
    }

    getCalcFanWidth(fan_width) {
        let result;
    
        const input = parseFloat(this.assessor.data[fan_width]);
        const imperialMeasurements =
          this.evaluation.getMeasurements() === "imperial";
        if (input >= 1 && input < 40.64) {
          result = imperialMeasurements ? 1790 : 3043;
        } else if (input >= 40.64 && input < 45.72) {
          result = imperialMeasurements ? 2920 : 4964;
        } else if (input >= 45.72 && input < 60.96) {
          result = imperialMeasurements ? 4200 : 7140;
        } else if (input >= 60.96 && input < 91.44) {
          result = imperialMeasurements ? 5600 : 9520;
        } else if (input >= 91.44 && input < 121.92) {
          result = imperialMeasurements ? 11000 : 18700;
        } else if (input >= 121.92 && input < 127) {
          result = imperialMeasurements ? 20000 : 34000;
        } else if (input >= 127 && input < 129.54) {
          result = imperialMeasurements ? 22000 : 37400;
        } else if (input >= 129.54 && input < 137.16) {
          result = imperialMeasurements ? 24000 : 40800;
        } else if (input >= 137.16) {
          result = imperialMeasurements ? 27000 : 45900;
        } else {
          result = 0;
        }
    
        return result;
      }

};

export default TieStallStanchionHeatAbatement;