/**
 * The footbath assessor allows the user to calculate the cost of their footbath while also determining if their footbath is put together
 * properly. There are two main uses for the assessor. First, is for a farmer to determine how much their existing footbath(s) costs. The
 * second purpose is for a farmer to explore the cost of operating a new footbath or a new mix of chemicals. However, the user can both
 * find the cost of his exiting footbath and look at the costs of future footbaths. The reports give the farmer an idea of the total cost
 * in dollars and waste water of one location of a footbath for an entire year.
 *
 * Footbath - A footbath is a chemical bath that cows or other farm animals walk through to clean their feet. Footbaths will cut down on
 * foot disease and improve hygiene in the herd. Cows typically will use a footbath several times a week depending on their housing
 * conditions. Each footbath may be made up of different chemicals depending on what the purpose of the footbath is.
 *
 * Location - A location is an individual physical place where a footbath is located. Large diaries will have multiple locations where
 * cows can use footbaths. In order to get a full view of a farms footbath cost the footbath assessor allows for the user to enter multiple
 * footbath locations. Each locations footbath can have multiple mixes and can be used multiple days a week.
 *
 * Mix – A mix is a combination of chemicals both liquid and solid that a farmer will add to water to create a footbath. A mix is typically
 * used multiple times a year for every cow on the farm. Each mix targets a specific item so each individual footbath will possibly use many
 * mixes over the course of the year. The assessor allows a user to enter multiple mixes for each footbath location and specify the chemicals
 * and the number of times the mix is used per week.
 */

import Mix from './Mix.js';

const convert = require('convert-units');

class Footbath {

  constructor(app, evaluation, group, assessor, footbath) {
    if (footbath.volume === undefined) {
      throw group.getName() + app.$t(" - This footbath is not complete in this Hygiene/Footbath assessor.");
    }
    this.app = app;
    this.evaluation = evaluation;
    this.group = group;
    this.assessor = assessor;
    this.footbath = footbath;
    this.smallLengthValue = 10;
    this.minLengthCm = 300
    this.minLengthIn = 120
    this.idealDepthValue = 25;
    this.smallDepthValue = 10;
    this.idealPassesPerBath = 200;
    this.idealLength = 213;
    this.idealWidth = 91;
    this.idealStepInDepth = 10;
    this.idealStepOutDepth = 10;
  }

  getId() {
    return this.footbath.id;
  }

  getName() {
    return this.footbath.name;
  }

  getIdealLength() {
    return convert(this.idealLength)
      .from('cm')
      .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
      .toDecimal(0);
  }

  getIdealWidth() {
    return convert(this.idealWidth)
      .from('cm')
      .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
      .toDecimal(0);
  }

  getIdealStepInDepth() {
    return convert(this.idealStepInDepth)
      .from('cm')
      .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
      .toDecimal(0);
  }

  getIdealStepOutDepth() {
    return convert(this.idealStepOutDepth)
      .from('cm')
      .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
      .toDecimal(0);
  }

  getL(number) {
    return convert(number)
      .from('L')
      .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'L'))
  }

  // @NOTE Final
  getLength(roundPoint) {
    if (this.footbath.volume.measurements_length === undefined || this.footbath.volume.measurements_length === null) {
      return this.footbath.volume.measurements_length;
    }

    if (roundPoint === false) {
      roundPoint = this.footbath.volume.measurements_length.countDecimals() - 3;
      if (roundPoint < 0) roundPoint = 0;
    }

    return convert(this.footbath.volume.measurements_length)
      .from('cm')
      .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
      .toDecimal(roundPoint);
  }

  setLength(newValue) {
    this.footbath.volume.measurements_length = parseFloat(convert(newValue)
      .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
      .to('cm'))
      .toDecimal(newValue.countDecimals() + 3);
  }

  // getSmallLengthValue(convertValue) {
  //     if (convertValue !== false) {
  //         return convert(this.smallLengthValue)
  //             .from('ft')
  //             .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
  //             .toDecimal(0);
  //     }
  //
  //     return this.smallLengthValue;
  // }

  getSmallLengthValue() {
    if (this.evaluation.getMeasurements() === 'imperial') {
      return this.minLengthIn
    }
    return this.minLengthCm
  }

  // @NOTE Final
  getWidth(roundPoint) {
    if (this.footbath.volume.measurements_width === undefined || this.footbath.volume.measurements_width === null) {
      return this.footbath.volume.measurements_width;
    }

    if (roundPoint === false) {
      roundPoint = this.footbath.volume.measurements_width.countDecimals() - 3;
      if (roundPoint < 0) roundPoint = 0;
    }

    return convert(this.footbath.volume.measurements_width)
      .from('cm')
      .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
      .toDecimal(roundPoint);
  }

  setWidth(newValue) {
    if (newValue === "") {
      this.footbath.volume.measurements_width = undefined;
    } else {
      this.footbath.volume.measurements_width = parseFloat(convert(newValue)
        .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
        .to('cm'))
        .toDecimal(newValue.countDecimals() + 3);
    }
  }

  // @NOTE Final
  getStepIn(roundPoint) {
    if (this.footbath.volume.measurements_step_in_depth === undefined || this.footbath.volume.measurements_length === null) {
      return this.footbath.volume.measurements_step_in_depth;
    }

    if (roundPoint === false) {
      roundPoint = this.footbath.volume.measurements_step_in_depth.countDecimals() - 3;
      if (roundPoint < 0) roundPoint = 0;
    }

    return convert(this.footbath.volume.measurements_step_in_depth)
      .from('cm')
      .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
      .toDecimal(roundPoint);
  }

  setStepIn(newValue) {
    this.footbath.volume.measurements_step_in_depth = parseFloat(convert(newValue)
      .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
      .to('cm'))
      .toDecimal(newValue.countDecimals() + 3);
  }

  // @NOTE Final
  getStepOut(roundPoint) {
    if (this.footbath.volume.measurements_step_out_depth === undefined || this.footbath.volume.measurements_length === null) {
      return this.footbath.volume.measurements_step_out_depth;
    }

    if (roundPoint === false) {
      roundPoint = this.footbath.volume.measurements_step_out_depth.countDecimals() - 3;
      if (roundPoint < 0) roundPoint = 0;
    }

    return convert(this.footbath.volume.measurements_step_out_depth)
      .from('cm')
      .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
      .toDecimal(roundPoint);
  }

  setStepOut(newValue) {
    this.footbath.volume.measurements_step_out_depth = parseFloat(convert(newValue)
      .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
      .to('cm'))
      .toDecimal(newValue.countDecimals() + 3);
  }

  getIdealDepthValue(convertValue) {
    if (convertValue !== false) {
      return convert(this.idealDepthValue)
        .from('cm')
        .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
        .toDecimal(0);
    }

    return this.idealDepthValue;
  }

  getSmallDepthValue(convertValue) {
    if (convertValue !== false) {
      return convert(this.smallDepthValue)
        .from('cm')
        .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
        .toDecimal(0);
    }

    return this.smallDepthValue;
  }

  getVolume() {
    let volume;

    if (this.footbath.volume.measurements_length === 0 || this.footbath.volume.measurements_width === 0 || this.footbath.volume.measurements_step_in_depth === 0) return 0;

    if (this.footbath.volume.measurements_step_out_depth !== undefined) {
      let largerStepMeasurement = null;
      let smallerStepMeasurement = null;
      if (this.footbath.volume.measurements_step_in_depth > this.footbath.volume.measurements_step_out_depth) {
        largerStepMeasurement = "measurements_step_in_depth";
        smallerStepMeasurement = "measurements_step_out_depth";
      } else {
        largerStepMeasurement = "measurements_step_out_depth";
        smallerStepMeasurement = "measurements_step_in_depth";
      }

      // Calculate the cubic retangle porition of the footbath
      let cubicRectangleCalculation = this.footbath.volume.measurements_length * this.footbath.volume.measurements_width * (this.footbath.volume[largerStepMeasurement] - (this.footbath.volume[largerStepMeasurement] - this.footbath.volume[smallerStepMeasurement]));

      // Calculate the cubic triangle porition of the footbath
      let cubicTriangleCalculation = this.footbath.volume.measurements_length * this.footbath.volume.measurements_width * ((this.footbath.volume[largerStepMeasurement] - this.footbath.volume[smallerStepMeasurement]) * .5);

      volume = cubicRectangleCalculation + cubicTriangleCalculation;
    } else {
      // Calculate the cubic retangle porition of the footbath
      volume = this.footbath.volume.measurements_length * this.footbath.volume.measurements_width * this.footbath.volume.measurements_step_in_depth;
    }

    if (volume === 0) {
      return 0;
    }

    // Add the two cubic calculations together
    return convert(volume)
      .from('cm3')
      .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'l'))
      .toDecimal(2);
  }

  getCowPassesPerBath() {
    return this.footbath.data.cow_passes_bath;
  }

  getIdealCowPassesPerBath() {
    return this.idealPassesPerBath;
  }

  getPhLevel() {
    return this.footbath.data.ph;
  }

  isProperlyLocated() {
    return this.footbath.data.properly_located;
  }

  isStones() {
    return this.footbath.data.stones_bottom;
  }

  isFloorDeteriorated() {
    return this.footbath.data.floor_deteriorated;
  }

  canAvoidStepping() {
    return this.footbath.data.avoid_stepping;
  }

  canBypass() {
    return this.footbath.data.bypass_footbath;
  }

  isCleanPath() {
    return this.footbath.data.clean_path;
  }

  getLaborCost() {
    if (this.footbath.data.labor_cost === undefined || this.footbath.data.labor_cost === "") {
      return 0;
    }

    return parseFloat(this.footbath.data.labor_cost);
  }

  createMix() {
    let defaults = {
      id: 0,
      name: null,
      mixed_weekly_personal_notes: '',
      mixed_personal_notes: '',
      mixed_presentation_notes: '',
      score_sunday: 0,
      score_monday: 0,
      score_tuesday: 0,
      score_wednesday: 0,
      score_thursday: 0,
      score_friday: 0,
      score_saturday: 0,
      score_total: 0,
      productsToDisplay: [],
      footbath_system: null,
      mixed_properly: null
    };

    defaults.id = this.app.$generateId();

    if (this.footbath === undefined) {
      this.footbath = [];
    }

    let mix = new Mix(this.app, this.evaluation, this.group, this.assessor, this, defaults);

    this.footbath.mixes.push(mix);

    return mix;
  }

  getMixes() {
    let mixes = [];
    if (this.footbath.mixes && Object.keys(this.footbath.mixes).length > 0) {
      for (let mixId in this.footbath.mixes) {
        let mix = new Mix(this.app, this.evaluation, this.group, this.assessor, this, this.footbath.mixes[mixId]);
        mixes.push(mix);
      }
    }

    return mixes;
  }

};

export default Footbath;
