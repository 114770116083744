const Table = require('../../Misc/Table');
const BarGraph = require('../../Misc/BarGraph');

export const lesion = (app, report, evaluation, group, assessor) => {

    let slide = report.pptx.addSlide('Slide master');
    let slide2;

    const lesionTypeCategoriesObj = [{
        text: app.$t('Lesion Type'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Lesion Totals'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Non-Infectious'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Infectious'),
        options: report.styleOptions.tableHeader
    }];
    let infectiousLesionsCategoriesObj;

    if (assessor.getWhatTypeOfLesionsDoYouWantToEnter() !== 'both') {
        infectiousLesionsCategoriesObj = [{
            text: app.$t('Lesion'),
            options: report.styleOptions.tableHeader
        }, {
            text: app.$t('Lame'),
            options: report.styleOptions.tableHeader
        }, {
            text: app.$t('Lesion'),
            options: report.styleOptions.tableHeader
        }, {
            text: app.$t('Lame'),
            options: report.styleOptions.tableHeader
        }];
    } else {
        infectiousLesionsCategoriesObj = [{
            text: app.$t('Lesion'),
            options: report.styleOptions.tableHeader
        }, {
            text: app.$t('Lame'),
            options: report.styleOptions.tableHeader
        }, {
            text: app.$t('Trim'),
            options: report.styleOptions.tableHeader
        }, {
            text: app.$t('Lesion'),
            options: report.styleOptions.tableHeader
        }, {
            text: app.$t('Lame'),
            options: report.styleOptions.tableHeader
        }, {
            text: app.$t('Trim'),
            options: report.styleOptions.tableHeader
        }];
    }

    let infectiousColWidths;
    if (assessor.getWhatTypeOfLesionsDoYouWantToEnter() !== 'both') {
        infectiousColWidths = [3, .75, 3, .75];
    } else {
        infectiousColWidths = [2.25, .75, .75, 2.25, .75, .75];
    }

    let lesionTypeObj = [];
    let nonInfectiousLesionsObj = [];
    let infectiousLesionsObj = [];

    let rectangleY = 4;
    let barGraphY = 4.5;

    // console.log('HIT 1')

    let barObj;

    if (assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both') {
        // an array on objects is used to have a title for mulitple fields on the bar graph
        barObj = [
            {
                name: app.$t('Lame'),
                labels: assessor.getBarTitles('lame_both'),
                values: assessor.getBarValuesPercentages('lame_both')
            },
            {
                name: app.$t('Trim'),
                labels: assessor.getBarTitles('trim_both'),
                values: assessor.getBarValuesPercentages('trim_both')
            }
        ];
    } else {
        barObj = [
            {
                name: app.$t('Percentage of Recorded Hoof Lesions'),
                labels: assessor.getBarTitles(assessor.getWhatTypeOfLesionsDoYouWantToEnter()),
                values: assessor.getBarValuesPercentages(assessor.getWhatTypeOfLesionsDoYouWantToEnter())
            }
        ];
    }

    // console.log('HIT 2')

    slide.addText(
        app.$t('Lesion Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
    ).addText(
        app.$t('Your Operation'), {
            x: 0.5,
            y: 2.38,
            ...report.styleOptions.subheaderBlue
        }
    )

    slide.addText(
        [
            { text: app.$t('Type of Lesions') + app.$t(': '), options:{ bold:true,fontFace: 'Arial',  } },
            { text: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? app.$t('Lame') : assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' ? app.$t('Trim') : app.$t('Both'), options:{ fontFace: 'Arial',  } },
        ],
        { x:0.5, y:2.7, fontSize: 12 }
    );

    if (assessor.getNumberOfBlocksApplied() !== null) {
        slide.addText(
            [
                { text: app.$t('Number of Blocks Applied') + app.$t(': '), options:{ bold:true,fontFace: 'Arial',  } },
                { text: assessor.getNumberOfBlocksApplied(), options:{ fontFace: 'Arial',  } },
            ],
            { x:0.5, y:2.9, fontSize: 12 }
        );
    }

    if (assessor.getNumberOfWrapsApplied() !== null) {
        slide.addText(
            [
                { text: app.$t('Number of Wraps Applied') + app.$t(': '), options: { bold: true,fontFace: 'Arial',  } },
                { text: assessor.getNumberOfWrapsApplied(), options:{ fontFace: 'Arial', } },
            ],
            { x:0.5, y:3.1, fontSize: 12 }
        );
    }

    // console.log('HIT 3')

    if (assessor.getWhatTypeOfLesionsDoYouWantToEnter() !== 'trim') {
        lesionTypeObj.push([{
                text: app.$t('Lame'),
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'ffffff'
                }
            },
            {
                text: assessor.getLesionSum('lame'),
                options: {
                    color: '000000',
                    fill: 'ffffff'
                }
            },
            {
                text: assessor.getNonInfectiousOrInfectiousTotal(false, 'lame') + ' (' + assessor.getNonInfectiousOrInfectiousPercent(false, 'lame') + app.$t('%)'),
                options: {
                    color: '000000',
                    fill: 'ffffff'
                }
            },
            {
                text: assessor.getNonInfectiousOrInfectiousTotal(true, 'lame') + ' (' + assessor.getNonInfectiousOrInfectiousPercent(true, 'lame') + app.$t('%)'),
                options: {
                    color: '000000',
                    fill: 'ffffff'
                }
            }
        ]);
        rectangleY += .5;
        barGraphY += .5;
    }

    // console.log('HIT 4')

    if (assessor.getWhatTypeOfLesionsDoYouWantToEnter() !== 'lame') {
        lesionTypeObj.push([{
                text: app.$t('Trim'),
                options: {
                    align: 'left',
                    color: '000000',
                    fill: 'ffffff'
                }
            },
            {
                text: assessor.getLesionSum('trim'),
                options: {
                    color: '000000',
                    fill: 'ffffff'
                }
            },
            {
                text: assessor.getNonInfectiousOrInfectiousTotal(false, 'trim') + ' (' + assessor.getNonInfectiousOrInfectiousPercent(false, 'trim') + app.$t('%)'),
                options: {
                    color: '000000',
                    fill: 'ffffff'
                }
            },
            {
                text: assessor.getNonInfectiousOrInfectiousTotal(true, 'trim') + ' (' + assessor.getNonInfectiousOrInfectiousPercent(true, 'trim') + app.$t('%)'),
                options: {
                    color: '000000',
                    fill: 'ffffff'
                }
            },
        ]);
        rectangleY += .5;
        barGraphY += .5;
    }

    // console.log('HIT 5')

    Table.create(slide, lesionTypeCategoriesObj, lesionTypeObj, report, group, 'Lesion Assessor', {
        y: 3.5,
        ...report.styleOptions.tableOptions
    });

    slide.addShape(report.pptx.shapes.RECTANGLE, {
        x: 0.5,
        y: rectangleY,
        w: 7.5,
        h: 5,
        line: '000000',
        line_size: 1
    })
    .addText(
        app.$t('Percentage of Recorded Hoof Lesions'), {
            x: 2.8,
            y: rectangleY + .28,
            bold: true,
            color: '000000',
            fontSize: 11,
        }
    );

    // Had to add "Percent of Recorded Hoof Lesions" text manually since pptxgenjs doesn't provide the option to make titles bold on bar graphs.
    BarGraph.create(report, slide, barObj, {
        x: .6,
        y: barGraphY,
        valAxisLineShow: true,
        showPercent: true,
        showValue: false,
        valAxisHidden: false,
        catAxisLineShow: true,
        catAxisLabelFontSize: 7,
        showLegend: false,
        chartColors: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? ['0000FF', 'A1A3A6'] : assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' ? ['A1A3A6'] : ['0000FF'],
        w: 7.3,
        h: 4.45,
        legendPos: 'tr'
    });

    if (assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both') {
        slide.addShape(report.pptx.shapes.RECTANGLE, {
            x: 6.27,
            y: rectangleY + .24,
            w: .1,
            h: .1,
            fill: '0000ff'
        })
        .addText(
            app.$t('Lame'), {
                x: 6.35,
                y: rectangleY + .28,
                color: '000000',
                fontSize: 10,
            }
        )
        .addShape(report.pptx.shapes.RECTANGLE, {
            x: 7.02,
            y: rectangleY + .24,
            w: .1,
            h: .1,
            fill: 'A1A3A6'
        })
        .addText(
            app.$t('Trim'), {
                x: 7.1,
                y: rectangleY + .28,
                color: '000000',
                fontSize: 10,
            }
        );
    }

    // console.log('HIT 6')

    // start of second page
    nonInfectiousLesionsObj.push([{
            text: app.$t("Sole Ulcer (U)"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getSoleUlcerLame() !== null ? false : true,
              assessor.getSoleUlcerLame(),
              'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getSoleUlcerTrim() !== null ? false : true,
              assessor.getSoleUlcerTrim(),
              'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff'
            }
        },
        {
            text: app.$t("Interdigital Hyperplasia (K)"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        }, {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getInterdigitalHyperplasiaLame() !== null ? false : true,
              assessor.getInterdigitalHyperplasiaLame(),
              'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        }, {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getInterdigitalHyperplasiaTrim() !== null ? false : true,
              assessor.getInterdigitalHyperplasiaTrim(),
              'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff'
            }
        },
    ]);

    // console.log('HIT 7');

    nonInfectiousLesionsObj.push([{
            text: app.$t("Toe Ulcer (T)"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getToeUlcerLame() !== null ? false : true,
              assessor.getToeUlcerLame(),
              'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getToeUlcerTrim() !== null ? false : true,
              assessor.getToeUlcerTrim(),
              'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff',
            }
        },
        {
            text: app.$t("Horizontal Fissure (G)"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        }, {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getHorizontalFissureLame() !== null ? false : true,
              assessor.getHorizontalFissureLame(),
              'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        }, {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getHorizontalFissureTrim() !== null ? false : true,
              assessor.getHorizontalFissureTrim(),
              'trim'),
            options: {
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff',
                color: '000000'
            }
        },
    ]);

    // console.log('HIT 8');

    nonInfectiousLesionsObj.push([{
            text: app.$t("Sole Fracture/Heel Ulcer (S)"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getSoleFractureHeelUlcerLame() !== null ? false : true,
              assessor.getSoleFractureHeelUlcerLame(),
              'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getSoleFractureHeelUlcerTrim() !== null ? false : true,
              assessor.getSoleFractureHeelUlcerTrim(),
              'trim'),
            options: {
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff',
                color: '000000'
            }
        },
        {
            text: app.$t("Vertical Fissure (V)"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        }, {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getVerticalFissureLame() !== null ? false : true,
              assessor.getVerticalFissureLame(),
              'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        }, {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getVerticalFissureTrim() !== null ? false : true,
              assessor.getVerticalFissureTrim(),
              'trim'),
            options: {
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff',
                color: '000000'
            }
        },
    ]);

    // console.log('HIT 9');

    nonInfectiousLesionsObj.push([{
            text: app.$t("Thin Sole (Z)"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getThinSoleLame() !== null ? false : true,
              assessor.getThinSoleLame(),
              'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(
              assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getThinSoleTrim() !== null ? false : true,
              assessor.getThinSoleTrim(),
              'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff',
            }
        },
        {
            text: app.$t("Corkscrew Claw (C)"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        }, {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getCorkscrewClawLame() !== null ? false : true, assessor.getCorkscrewClawLame(), 'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        }, {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getCorkscrewClawTrim() !== null ? false : true, assessor.getCorkscrewClawTrim(), 'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff',
            }
        },
    ]);

    // console.log('HIT 10');

    nonInfectiousLesionsObj.push([{
            text: app.$t("Sole Hemorrhage/Bruise (H)"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getSoleHemorrhageBruiseLame() !== null ? false : true, assessor.getSoleHemorrhageBruiseLame(), 'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getSoleHemorrhageBruiseTrim() !== null ? false : true, assessor.getSoleHemorrhageBruiseTrim(), 'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff',
            }
        },
        {
            text: app.$t("Injury (J)"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        }, {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getInjuryLame() !== null ? false : true, assessor.getInjuryLame(), 'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        }, {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getInjuryTrim() !== null ? false : true, assessor.getInjuryTrim(), 'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff',
            }
        },
    ]);

    // console.log('HIT 11');

    nonInfectiousLesionsObj.push([{
            text: app.$t("White Line Disease (W)"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getWhiteLineDiseaseLame() !== null ? false : true, assessor.getWhiteLineDiseaseLame(), 'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getWhiteLineDiseaseTrim() !== null ? false : true, assessor.getWhiteLineDiseaseTrim(), 'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff',
            }
        },
        {
            text: app.$t("Deep Digital Sepsis"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        }, {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getDeepDigitalSepsisLame() !== null ? false : true, assessor.getDeepDigitalSepsisLame(), 'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        }, {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getDeepDigitalSepsisTrim() !== null ? false : true, assessor.getDeepDigitalSepsisTrim(), 'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff',
            }
        },
    ]);

    // console.log('HIT 12');

    nonInfectiousLesionsObj.push([{
            text: app.$t("Axial Wall Crack (X)"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getAxialWallCrackLame() !== null ? false : true, assessor.getAxialWallCrackLame(), 'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getAxialWallCrackTrim() !== null ? false : true, assessor.getAxialWallCrackTrim(), 'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff',
            }
        },
        {
            text: '',
            options: {
                colspan: 3,
                fill: 'ffffff'
            }
        },
    ]);

    // console.log('HIT 13');

    // filter out items that aren't lame or trim
    if (assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame') {
        for(let i = 0; i < nonInfectiousLesionsObj.length;i++) {
            //remove at these specified indexes
            if(nonInfectiousLesionsObj[i]) {
                _.pullAt(nonInfectiousLesionsObj[i], [2, 5]);
            }
        }
    } else if (assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim') {
        for(let i = 0; i < nonInfectiousLesionsObj.length;i++) {
            //remove at these specified indexes
            if(nonInfectiousLesionsObj[i]) {
                _.pullAt(nonInfectiousLesionsObj[i], [1, 4]);
            }
        }
    }

    // nonInfectiousLesionsObj = this.getTrimOrLameValues(nonInfectiousLesionsObj);

    // if (nonInfectiousLesionsObj.length > 0) {
    slide2 = report.pptx.addSlide('Slide master');

    slide2.addText(
        app.$t('Lesion Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
    ).addText(
        app.$t('Non-Infectious Lesions (Percent of recorded Hoof Lesions)'), {
            x: 0.5,
            y: 2.38,
            w: 7.5,
            ...report.styleOptions.subheaderBlue
        }
    );

    Table.create(slide2, infectiousLesionsCategoriesObj, nonInfectiousLesionsObj, report, group, 'Lesion Assessor', {
        y: 2.7,
        colW: infectiousColWidths,
        ...report.styleOptions.tableOptions
    });
    // }

    // console.log('HIT 14');

    infectiousLesionsObj.push([{
            text: app.$t("Digital Dermatitis (D)"),
            options: {
                align: 'left',
                fill: 'ffffff',
                color: '000000',
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getDigitalDermatitisLame() !== null ? false : true, assessor.getDigitalDermatitisLame(), 'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getDigitalDermatitisTrim() !== null ? false : true, assessor.getDigitalDermatitisTrim(), 'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff'
            }
        },
        {
            text: app.$t("Interdigital Dermatitis (I)"),
            options: {
                align: 'left',
                fill: 'ffffff',
                color: '000000',
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getInterdigitalDermatitisLame() !== null ? false : true, assessor.getInterdigitalDermatitisLame(), 'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getInterdigitalDermatitisTrim() !== null ? false : true, assessor.getInterdigitalDermatitisTrim(), 'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff'
            }
        }
    ]);

    // console.log('HIT 15');

    infectiousLesionsObj.push([{
            text: app.$t("Heel Horn Erosion (E)"),
            options: {
                align: 'left',
                fill: 'ffffff',
                color: '000000',
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getHeelHornErosionLame() !== null ? false : true, assessor.getHeelHornErosionLame(), 'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getHeelHornErosionTrim() !== null ? false : true, assessor.getHeelHornErosionTrim(), 'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff'
            }
        },
        {
            text: app.$t("Interdigital Phlegmon/Foot Rot (F)"),
            options: {
                align: 'left',
                color: '000000',
                fill: 'ffffff'
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' && assessor.getInterdigitalPhlegmonFootRotLame() !== null ? false : true, assessor.getInterdigitalPhlegmonFootRotLame(), 'lame'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' ? 'efefef' : 'ffffff',
            }
        },
        {
            text: assessor.getLesionPercent(assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame' && assessor.getInterdigitalPhlegmonFootRotTrim() !== null ? false : true, assessor.getInterdigitalPhlegmonFootRotTrim(), 'trim'),
            options: {
                color: '000000',
                fill: assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim' || assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'both' ? 'efefef' : 'ffffff'
            }
        }
    ]);

    // console.log('HIT 16');

    // infectiousLesionsObj = this.getTrimOrLameValues(infectiousLesionsObj);

    if (assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'lame') {
        for (let i = 0; i < infectiousLesionsObj.length; i++) {
            //remove at these specified indexes
            if (infectiousLesionsObj[i]) {
                _.pullAt(infectiousLesionsObj[i], [2, 5]);
            }
        }
    } else if (assessor.getWhatTypeOfLesionsDoYouWantToEnter() === 'trim') {
        for (let i = 0; i < infectiousLesionsObj.length; i++) {
            //remove at these specified indexes
            if (infectiousLesionsObj[i]) {
                _.pullAt(infectiousLesionsObj[i], [1, 4]);
            }
        }
    }

    // if (infectiousLesionsObj.length > 0) {
    slide2.addText(
        app.$t('Infectious Lesions (Percent of recorded Hoof Lesions)'), {
            x: 0.5,
            y: 6.2,
            ...report.styleOptions.subheaderBlue
        }
    );

    Table.create(slide2, infectiousLesionsCategoriesObj, infectiousLesionsObj, report, group, 'Lesion Assessor', {
        y: 6.52,
        colW: infectiousColWidths,
        ...report.styleOptions.tableOptions
    });
    // }

    return slide;
}
