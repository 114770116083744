import axios from '../../../Axios';
import Vue from 'vue';
import {
  GET_COMPANIES,
  GET_COMPANIES_BY_USER_ID,
  GET_COMPANY_BY_ID,
  GET_COMPANY_DELETE,
  GET_COMPANY_UPDATE,
  GET_NEW_COMPANY,
  SET_COMPANIES,
  SET_COMPANY_DELETE,
  SET_COMPANY_UPDATE,
  SET_NEW_COMPANY,
} from '../types';
import { URLS } from '../../constants';
const state = {
  company: {
    id: null,
    name: null,
    address_line_1: null,
    address_line_2: null,
    country: null,
    state: null,
    city: null,
    zip_code: null,
    user_id: null,
  },
  companies: [],
};

const getters = {
  [GET_COMPANIES_BY_USER_ID]: STATE => USER_ID => {
    const companies = STATE.companies.filter(
      ({ createdBy }) => createdBy === USER_ID
    );
    return companies;
    console.log({ companies, USER_ID, companiesState: STATE.companies });
  },
};

const mutations = {
  [SET_NEW_COMPANY](STATE, COMPANY) {
    STATE.companies.unshift(COMPANY);
  },

  [SET_COMPANIES](STATE, COMPANIES) {
    STATE.companies = COMPANIES
    // .sort((a, b) => {
    //   if(a.name < b.name)  return -1;
    //   if(a.name > b.name)  return 1;
    //   return 0;
    // });
  },

  [SET_COMPANY_UPDATE](STATE, COMPANY) {
    const updatedCompanyIdx = findCompanyIdx(STATE.companies, COMPANY._id);
    STATE.companies[updatedCompanyIdx] = COMPANY;
  },

  [SET_COMPANY_DELETE](STATE, COMPANY_ID) {
    // const updatedCompanyIdx = findCompanyIdx(STATE.companies, COMPANY_ID);
    // STATE.companies.splice(updatedCompanyIdx, 1);
    // STATE.companies = { ...STATE.companies };
    STATE.companies = STATE.companies.filter(
      company => company._id !== COMPANY_ID
    );
  },
};

const actions = {
  [GET_NEW_COMPANY]({ commit: COMMIT }, COMPANY) {
    return new Promise((resolve, reject) => {
      axios
        .post(URLS.COMPANY, COMPANY)
        .then(function(newCompany) {
          COMMIT(SET_NEW_COMPANY, newCompany.data.data);
          return resolve(newCompany);
        })
        .catch(function(ERR) {
          reject(ERR.response.data);
          return ERR.response.data;
        });
    });
  },
  [GET_COMPANIES]({ commit: COMMIT }, TYPE) {
    return new Promise((resolve, reject) => {
      axios
        .get(URLS.COMPANY, { query: { type: TYPE } })
        .then(function(companies) {
          COMMIT(SET_COMPANIES, companies.data.data);
          return resolve(companies);
        })
        .catch(function(err) {
          reject(err.response.data);
          return err.response.data;
        });
    });
  },
  [GET_COMPANY_BY_ID]({ commit: COMMIT }, COMPANY_ID) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URLS.COMPANY}/${COMPANY_ID}`)
        .then(function(company) {
          COMMIT(SET_NEW_COMPANY, company.data.data);
          return resolve(company.data.data);
        })
        .catch(function(err) {
          reject(err.response.data);
          return err.response.data;
        });
    });
  },
  [GET_COMPANY_UPDATE]({ commit: COMMIT }, COMPANY) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${URLS.COMPANY}/${COMPANY._id}`, COMPANY)
        .then(function(company) {
          COMMIT(SET_COMPANY_UPDATE, company.data.data);
          return resolve(company.data.data);
        })
        .catch(function(err) {
          reject(err.response.data);
          return err.response.data;
        });
    });
  },
  [GET_COMPANY_DELETE]({ commit: COMMIT }, COMPANY_ID) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URLS.COMPANY}/${COMPANY_ID}`)
        .then(function(company) {
          COMMIT(SET_COMPANY_DELETE, COMPANY_ID);
          return resolve(company);
        })
        .catch(function(err) {
          reject(err.response.data);
          return err.response.data;
        });
    });
  },
};

function findCompanyIdx(COMPANIES, COMPANY) {
  return COMPANIES.indexOf(({ _id }) => _id === COMPANY._id);
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
