export const GET_USER_MEDIAS = 'GET_USER_MEDIAS';
export const SET_USER_MEDIAS = 'SET_USER_MEDIAS';

export const GET_FILES_METADATA = 'GET_FILES_METADATA';
export const SET_FILES_METADATA = 'SET_FILES_METADATA';

export const GET_STATES = 'GET_STATES';
export const SET_STATES = 'SET_STATES';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const SET_COUNTRIES = 'SET_COUNTRIES';

export const GET_TRANSLATION = 'GET_TRANSLATION';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';

export const GET_CURRENCIES = 'GET_CURRENCIES';
export const SET_CURRENCIES = 'SET_CURRENCIES';

export const GET_FILES = 'GET_FILES';
export const SET_FILES = 'SET_FILES';

export const GET_UPLOAD_DOC = 'GET_UPLOAD_DOC';
export const SET_UPLOAD_DOC = 'SET_UPLOAD_DOC';

export const GET_FETCH_BASE64 = 'GET_FETCH_BASE64';
export const SET_FETCH_BASE64 = 'SET_FETCH_BASE64';
