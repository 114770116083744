<template>
  <div class="dashboard-requirements dashboard__stats-block">
    <div class="row w-100">
      <div class="col-lg-24 ml-3 mb-2">
        <h2 class="h2 d-inline-block">
          {{ requirementsData.table.title | translate }}
        </h2>
      </div>
    </div>

    <div class="table-responsive">
      <!-- Table for desktop version -->
      <table class="dashboard__table table table-borderless table-sm d-none d-md-table">
        <thead class="dashboard__table-head border-bottom px-2">
          <tr>
            <th class="h6 pl-3" scope="col" colspan="1">
              {{ 'Type' | translate }}
            </th>
            <th v-for="value in requirementsData.table.headings" :key="value" class="h6 text-center" scope="col">
              {{ value | translate }}
            </th>
          </tr>
        </thead>
        <tbody class="px-2">
          <template v-if="requirementsData.table.subtitles">
            <tr>
              <th />
              <td
                v-for="subtitle in requirementsData.table.subtitles"
                :key="subtitle.value"
                class="text-center"
                
                scope="col"
                :colspan="subtitle.colspan"
              >
                <small>{{ subtitle.value | translate }}</small>
              </td>
            </tr>
          </template>
          <tr v-for="(groupType, idx) in groupTypesCustom" :key="groupType + idx" class="p-2">
            <th class="pl-3" scope="row">
              <small>{{ translateType(groupType) || '-' }}</small>
            </th>
            <td
              v-for="(computeValue, i) in requirementsData.table.computedValues"
              :key="`${groupType}_${computeValue.name}`"
              class="dashboard__table-cell text-center align-middle overflow-auto"
            >
              <small v-if="groupType !== 'maternity'">
                {{ computeValue(typedGroups[groupType].groups, groupType) }}
              </small>
              <small v-else-if="[0,1].includes(i)">
                {{ computeValue(typedGroups[groupType].groups, groupType) }}
              </small>
            </td>
            <td>
              <button v-if="groupType !== 'maternity'" type="button" class="btn btn-sm mr-4" @click="showChart(groupType)">
                {{ 'Chart' | translate }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Table for mobile version -->
      <table class="dashboard__table dashboard__table--sm table table-borderless table-sm d-table d-md-none">
        <thead class="border-bottom px-2">
          <tr>
            <th class="dashboard__table-head-tr h6 pl-3" scope="col">
              {{ 'Type' | translate }}
            </th>
            <th v-for="(groupType, idx) in groupTypesCustom" :key="groupType + idx" class="text-center" width="70px" scope="col">
              <small>{{ translateType(groupType) || '-' }}</small>
            </th>
          </tr>
        </thead>
        <tbody class="px-2">
          <div 
          v-for="(value, idx) in requirementsData.table.headings"
          :key="'hor-line' + value + idx"
          >
            <tr >
              <td class="dashboard__td-hr" width="100%" colspan="6" height="1px">
                <hr class="p-0 my-1" height="1px" />
              </td>
            </tr>
            <template v-if="requirementsData.table.subtitles && idx == 0">
              <tr :key="'subtitle' + idx">
                <td class="text-center" scope="col" colspan="4">
                  <small>{{ requirementsData.table.subtitles[idx].value | translate }}</small>
                </td>
              </tr>
            </template>
            <template v-else-if="requirementsData.table.subtitles && idx == 1">
              <tr :key="'subtitle' + idx">
                <td class="text-center" scope="col" colspan="4">
                  <small>{{ requirementsData.table.subtitles[idx].value | translate }}</small>
                </td>
              </tr>
            </template>
            <tr class="dashboard__table-tr">
              <th class="h6 pl-3">
                <small>
                  {{ value | translate }}
                </small>
              </th>
              <td v-for="(groupType, groupIdx) in groupTypesCustom" :key="groupType + groupIdx" class="dashboard__table-cell text-center align-middle overflow-auto">
                <small>
                  {{ requirementsData.table.computedValues[idx](typedGroups[groupType].groups, groupType) || '-' }}
                </small>
              </td>
            </tr>
          </div>
          <tr>
            <td class="dashboard__td-hr" width="100%" colspan="6" height="1px">
              <hr class="p-0 my-1" height="1px" />
            </td>
          </tr>
          <tr>
            <td />
            <td v-for="(groupType, idx) in groupTypesCustom" :key="groupType + idx" class="text-center">
              <button v-if="groupType !== 'maternity'" type="button" class="dashboard__btn btn btn-sm" @click="showChart(groupType)">
                {{ 'Chart' | translate }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <template v-if="chartShown">
      <div class="col text-right pr-5">
        <button type="button" @click="hideChart" class="btn px-4 py-1">
          {{ 'Hide Chart' | translate }}
        </button>
      </div>
      <h2 class="h2 d-inline-block mx-4 mt-4">
        {{ translateType(type) }}
      </h2>

      <template
        v-if="maternity"
      >
        <div class="row">
          <div class="dashboard__chart-container col-md-12">

            <BarChart
              :barChartData="createBarChartData(typedGroups[type][0])"
            />

          </div>
        </div>
      </template>

      <template
        v-else
      >
        <Carousel
          class="Vue-carousel"
          navigationEnabled
          paginationActiveColor="#0000FF"
          paginationColor="#AAADB0"
        >
          <Slide
            v-for="(group, idx) in typedGroups[type].groups"
            :key="'brchd' + idx"
          >
            <template>
              <BarChart
                :barChartData="createBarChartData(group, idx)"
              />
            </template>
          </Slide>
        </Carousel>
      </template>
    </template>
  </div>
</template>

<script>
import BarChart from '@/components/BarChart';
import { Slide, Carousel } from 'vue-carousel';

import { translationsMixin } from '@/mixins/translations.mixin.js';
import { animalMixin } from '@/mixins/animal.mixin.js';
import { assessorIds } from '@/libs/Classes/constants/assessorIds';
import { group } from '../../../libs/ReportPptx/Assessors/HygieneFootbath/group';

export default {
  name: 'DashboardRequirements',
  inject: ['assessorType'],

  components: { BarChart, Carousel, Slide },

  mixins: [translationsMixin, animalMixin],

  props: {
    evaluation: Object,
    requirementsData: Object,
    maternity: Boolean,
  },

  computed: {
    typedGroups() {
      let groups = Object.fromEntries(this.groupTypes
        .map(type => [
          type,
          {
            groups: this.filterGroupsByType(type, this.evaluation.groups),
            selectedIdx: 0,
          },
          ])
        );

      if (!this.maternity) return groups;

      const maternityGroups = groups['close-up'].groups
        .filter(group => {
          return (
            this.getAssessorField(group, assessorIds.transition, 'is_maternity_pen') === 'yes'
            && this.getAssessorField(group, assessorIds.transition, 'total_maternity_pen_space')
          );
        });

      if (maternityGroups.length) {
        groups['maternity'] = {
          groups: maternityGroups,
          selectedIdx: 0,
        };
        !this.groupTypesCustom.includes('maternity') && this.groupTypesCustom.push('maternity');
      }

      return groups;
    },
  },

  data() {
    return {
      chartShown: false,
      type: '',
      groupTypesCustom: [],
    };
  },

  created() {
    this.groupTypesCustom = this.groupTypes;
  },

  methods: {
    showChart(groupType) {
      this.chartShown = true;

      this.type = groupType;
    },


    hideChart() {
      this.chartShown = false;
    },


    createBarChartData(group, idx) {
      let labels = [
        this.$t('Bedded Pack'),
        this.$t('Freestall'),
        this.$t('Headlocks'),
        this.$t('Feeding Space'),
        this.$t('Drinking Space'),
      ];

      const groups = this.typedGroups[this.type].groups;

      const cowsSizeByMaternity = this.maternity
        ? this.maximumCows('', this.type)
        : group.data.size;
      const groupsByMaternity = this.maternity
        ? groups
        : [group];

      const values = [
        cowsSizeByMaternity / this.beddedPackAvailableCowSpaces(groupsByMaternity) * 100,
        cowsSizeByMaternity / this.freestallAvailableCowSpaces(groupsByMaternity) * 100,
        cowsSizeByMaternity / this.headlockAvailableCowSpaces(groupsByMaternity) * 100,
        cowsSizeByMaternity / this.feedingAvailableCowSpaces(groupsByMaternity) * 100,
        cowsSizeByMaternity / this.drinkingAvailableCowSpaces(groupsByMaternity) * 100,
      ];

      const filteredValues = values
        .filter((value, _) => Number.isFinite(value));
      const filteredLabels = labels
        .filter((_, idx) => Number.isFinite(values[idx]));

      labels = labels
        .map((_, idx) => (filteredLabels[idx]
          ? filteredLabels[idx]
          : ''));

      return {
        chartTitle: this.translateType(this.type),
        titleX: this.maternity ? false : group.name,
        titleY: idx > 0 ? ' ' : this.$t('Percent of Capacity Utilized'),
        labels,
        values: filteredValues,
        min: 5,
        max: 150,
        stepSize: 25,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../_dashboard.scss';

.dashboard-requirements {
  &__hide-chart {
    cursor: pointer;
    text-decoration: underline;
  }
}

.Vue-carousel {
  /deep/ .VueCarousel-navigation {
    & button {
      background: #a1a3a6 !important;
      color: #ffffff !important;
      border: none;
      border-radius: 0px !important;
      font-weight: 400;
      font-size: 0px;
      padding: 5px 12px !important;

      &:hover {
        background-color: #0000ff !important;
        color: #ffffff !important;
      }

      &:first-child {
        left: 20px;
        &::after {
          content: '\276E';
          font-size: 16px;
        }
      }

      &:last-child {
        right: 20px;
        &::after {
          content: '\276F';
          font-size: 16px;
        }
      }

      top: unset !important;
      transform: unset !important;
    }
  }

  /deep/ .VueCarousel-pagination {
      .VueCarousel-dot-container {
        margin-top: 0 !important;
        button {
          margin-top: 0 !important;
        }
      }
    }
}
</style>
