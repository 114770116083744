<template>
  <div>{{'EmailAddressChange' | translate}}</div>
</template>

<script>
export default {
  name: 'EmailAddressChange',
};
</script>

<style lang="scss" scoped>
</style>