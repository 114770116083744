/* eslint-disable no-console */

import { register } from 'register-service-worker';
console.log(process.env.NODE_ENV, process.env.BASE_URL);
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound(registration) {
      console.log('New content is downloading.');
      document.dispatchEvent(
        new CustomEvent('swUpdateFound', { detail: registration })
      );
    },
    updated(registration) {
      console.log('New content is available; Refreshing in 6s...');
      if (window && localStorage) {
        localStorage.setItem('lastUpdated', new Date(Date.now()).toGMTString());
      }
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      );
      setTimeout(() => {
        window.location.reload(true);
      }, 4000);
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
