import { get } from 'lodash';
import { mapActions } from 'vuex';
import { GET_UPLOAD_DOC } from '../store/types';

export default {
  methods: {
    ...mapActions('medias', [GET_UPLOAD_DOC]),
    async saveDownloadURLInDataCollectionList() {
      const promises = this.dataCollectionList
        .map(({ id, ...listItem }) => {
          const url = get(listItem, 'metadata.customMetadata.fileURL', null);
          if (!url) return;

          return fetch(url)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => {
              var blob = new Blob([arrayBuffer], { type: 'application/pdf' });
              this.downloadURLS[id] = window.URL.createObjectURL(
                new File([blob], id, { type: 'application/pdf' })
              );
              // this.downloadURLS[id] = URL.createObjectURL(file);
            });
        })
        .filter(Boolean);
      await Promise.all(promises);
    },
    openFileInNewTab(id) {
      window.open(this.downloadURLS[id], '_blank');
    },
    downloadFile(url, fileName) {
      // .then(response => response.arrayBuffer())
      // .then(arrayBuffer => {
      //   var file = new Blob([arrayBuffer], { type: 'application/pdf' });
      //   var fileURL = URL.createObjectURL(file);
      //   window.open(fileURL, '_blank');
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.target = '_blank';
          // we need to append the element to the dom -> otherwise it will not work in firefox
          document.body.appendChild(a);
          a.click();
          a.remove(); //afterwards we remove the element again
        });
    },

    lastUpdated(file) {
      // return new Date(get(file, 'metadata.customMetadata.lastUpdated', new Date().getTime())).toGMTString();
      const date = get(
        file,
        'metadata.customMetadata.lastUpdated',
        new Date().getTime()
      );
      if(!date) return ''
      const time = date
        .split(' ')
        .slice(-2)
        .join(' ');
      return `${this.$store.getters.getDate(date)} ${time}`;
      //
    },

    inputFile(e) {
      const fileID = e.target.name;
      this.type =  e.target.dataset.collection || this.type;
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this[this.type][this[this.type].findIndex(el => el.id === fileID)].file =
        files[0];
    },

    fileUpload(id) {
      // dynamically access the listing base on the type which is defined in the original component.(list and type both defined in the original component)
      const docIndex = this[this.type].findIndex(el => el.id === id)
      let doc = this[this.type][docIndex];
      console.log({doc});
      if (!doc || doc.file === null) {
        alert('Please upload a file "' + doc.title + '"');
        return;
      }

      this.isLoaded = false;
      const formData = new FormData();
      formData.append('type', this.type.replace('List', ''));
      formData.append('fileId', id);
      formData.append('lastUpdated', new Date(Date.now()).toGMTString());
      formData.append('files', doc.file);

      if (this.type === 'languageList') formData.append('code', doc.code);

      this[GET_UPLOAD_DOC](formData).then(() => {
        this[this.type][docIndex].file = null;
        document.querySelector('input[name=' + doc.id + ']').value = '';
        this.isLoaded = true;
      }).catch((e)=> {
        this.isLoaded = true;
      })
    },
    fullName (file ) {
      return file.metadata?.customMetadata?.userName ? file.metadata.customMetadata.userName : 'N/A' 
    }
  },
};
