import axios from "axios";
import axiosRetry from "axios-retry";

// const baseURL = 'https://zinpro-backend.herokuapp.com/api/'
export const baseURL = process.env.VUE_APP_API_URL;

// export const baseURL = 'https://sgp1.zinprofirststep.com/api/';

const instance = axios.create({
  baseURL,
});

axiosRetry(instance, {
  retries: 10,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
