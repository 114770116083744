<template>
  <div class="loading">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { GET_LOGOUT_USER } from '../store/types';

export default {
  async mounted() {
    try {
      this.logoutuser();
      this.$router.push('/login');
    } catch (error) {
      console.log('error', error);
      this.$router.push('/login');
    }
  },

  methods: {
    ...mapActions('users', [GET_LOGOUT_USER]),
    logoutuser() {
      this[GET_LOGOUT_USER]();
    },
  },
};
</script>
