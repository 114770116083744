/**
 * The Races Tracks Lanes Assessor evaluates if these areas on grazing dairies are a risk factor for lameness.
 */

const convert = require('convert-units');
import Assessor from './Assessor';

class Racetrack extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;
        this.targetAboveWaterTable = 60;
        this.minTargetTerminal = 50;
        this.maxTargetTerminal = 100;
        this.targetTerminalWidthMin = 300;
        this.targetTerminalWidthMax = 400;
        this.targeDistanceBetweenHerdsmanAndTailEnderCowsMin = 6;
        this.targetIsTheFreeChoiceWalkingSpeedOfCows = 1.25;
        this.minGravelSurface = 2;
        this.maxGravelSurface = 2.5;
        this.targetMinStoneBarrier = 15;
        this.targetBoard = 5;
    }

    getIsBaseLayerCompactedAndDry() {
        return this.assessor.data.is_base_layer_compacted_and_dry;
    }

    getTargetAboveWaterTable() {
        return convert(this.targetAboveWaterTable)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(0);
    }

    getAboveWaterTable() {
        return this.assessor.data.above_water_table;
    }

    getIsTopLayerSmoothAndCompacted() {
        return this.assessor.data.is_top_layer_smooth_and_compacted;
    }

    getAreTherePotHolesPresent() {
        return this.assessor.data.are_there_pot_holes_present;
    }

    getIsSurfaceSlipperyWhenWet() {
        return this.assessor.data.is_surface_slippery_when_wet;
    }

    getMachineCompacted() {
        return this.assessor.data.machine_compacted;
    }

    getTargetGravelSurface() {
        let min = convert(this.minGravelSurface)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(1);

        let max = convert(this.maxGravelSurface)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(1);

        return min + this.app.$t(' - ' )+ max;
    }

    getGravelSurface() {
        return this.assessor.data.gravel_surface;
    }

    getCrownedOrTilted() {
        return this.assessor.data.crowned_or_tilted;
    }

    getAnyScouringOnSteepSectionDiversionBarsNotInPlace() {
        return this.assessor.data.any_scouring_on_steep_section_diversion_bars_not_in_place;
    }

    getAnyTrackJuncturesToLowerQualitySurfaces() {
        return this.assessor.data.any_track_junctures_to_lower_quality_surfaces;
    }

    getAnyPuddlesWetOrMuddyAreas() {
        return this.assessor.data.any_puddles_wet_or_muddy_areas;
    }

    getTargetIsTheFreeChoiceWalkingSpeedOfCows() {
        try {
            if (this.evaluation.getMeasurements() === 'imperial') {
                return  '4.1 ' +  this.app.$t('ft/s')
            } else {
                return '1.25 ' +  this.app.$t('m/s')
            }
        } catch (error) {
            console.log({error});
        }
    }

    getIsTheFreeChoiceWalkingSpeedOfCows() {
        return this.assessor.data.is_the_free_choice_walking_speed_of_cows;
    }

    getDoesTheRacePassTheHumanWalkTest() {
        return this.assessor.data.does_the_race_pass_the_human_walk_test;
    }

    getTargetTrackWidth() {
        let minWidth;
        const size = this.assessor.data.track_width;
            
        if (size < 120) {
            minWidth = 4.9
        } else if (size < 250) {
            minWidth = 5.5
        } else if (size < 350) {
            minWidth = 5.9
        } else if (size < 450) {
            minWidth = 6.4
        } else {
            minWidth = 7.9
        }

        return convert(minWidth)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(1);
    }

    getTrackWidth(roundPoint) {
        if (this.assessor.data.track_width === undefined || this.assessor.data.track_width === null) {
            return undefined;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.track_width.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.track_width)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(roundPoint);
    }

    setTrackWidth(newValue) {
        if (newValue === "") {
            this.assessor.data.track_width = undefined;
        } else {
            this.assessor.data.track_width = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
                .to('m'))
                .toDecimal(newValue.countDecimals() + 3);
        }
    }

    getIsFullWidthAvailable() {
        return this.assessor.data.is_full_width_available;
    }

    getAreThereAnyMaterialsBagsTrashEtcInOrBesideTheTrackThatCowsAvoidOrReduceEffectiveTrackWidth() {
        return this.assessor.data.are_there_any_materials; // _bags_trash_etc_in_or_beside_the_track_that_cows_avoid_or_reduce_effective_track_width;
    }

    getCornersReplacedByCurvedCorners() {
        return this.assessor.data.corners_replaced_by_curved_corners;
    }

    getGateWidthRaceWidth() {
        return this.assessor.data.gate_width_race_width;
    }

    getGatesAngledAtRace() {
        return this.assessor.data.gates_angled_at_race;
    }

    getAreExitSurfacesCappedDryWellDrainedAndFirm() {
        return this.assessor.data.are_exit_surfaces_capped_dry_well_drained_and_firm;
    }

    // Had to write conditionals for DistanceFromPaddockToMilkingShed since the convert-units library does not convert km to miles and vice-versa.
    getMinDistanceFromPaddockToMilkingShed() {
        if (this.evaluation.getMeasurements() === 'metric') {
            return 1;
        } else {
            return .5;
        }
    }

    getMidDistanceFromPaddockToMilkingShed() {
        let min = this.getMinDistanceFromPaddockToMilkingShed();
        let max = this.getMaxDistanceFromPaddockToMilkingShed();

        return min + this.app.$t(' - ') + max;
    }

    getMaxDistanceFromPaddockToMilkingShed() {
        if (this.evaluation.getMeasurements() === 'metric') {
            return 2.5;
        } else {
            return 1.5;
        }
    }

    getFurthestDistanceFromPaddockToMilkingShed() {
        return Number(this.assessor.data.furthest_distance_from_paddock_to_milking_shed);
    }

    getAreWaterAndFeedTroughsAwayFromExits() {
        return this.assessor.data.are_water_and_feed_troughs_away_from_exits;
    }

    getAutoGateLatchesUsed() {
        return this.assessor.data.auto_gate_latches_used;
    }

    getMinTerminalExtraWide() {
        let min = convert(this.minTargetTerminal)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(0);

        let max = convert(this.maxTargetTerminal)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(0);

        return min + this.app.$t(' - ') + max;
    }

    getTargetIsTheTerminalExtraWide() {
        let min = convert(this.targetTerminalWidthMin)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(0);

        let max = convert(this.targetTerminalWidthMax)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(0);

        return Math.round10(min, 1) + this.app.$t(' - ') + Math.round10(max, 1);
    }

    getIsTheTerminalExtraWide() {
        return this.assessor.data.is_the_terminal_extra_wide;
    }

    getIsTheYardEntranceWidthRaceWidth() {
        return this.assessor.data.is_the_yard_entrance_width_race_width;
    }

    getDoCowsHaveToTurnToEnterTheYard() {
        return this.assessor.data.do_cows_have_to_turn_to_enter_the_yard;
    }

    getFirmSmoothNonSlipSurface() {
        return this.assessor.data.firm_smooth_non_slip_surface;
    }

    getExcellentDrainage() {
        return this.assessor.data.excellent_drainage;
    }
    
    getTargetMinStoneBarrier() {
        return convert(this.targetMinStoneBarrier)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(0);
    }

    getTargetBoard() {
        return convert(this.targetBoard)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(0);
    }

    getAreStonesBroughtOntoYards() {
        return this.assessor.data.are_stones_brought_onto_yards;
    }

    getDocumentedMaintenancePlanAdheredTo() {
        return this.assessor.data.documented_maintenance_plan_adhered_to;
    }

    getEmergencyRepairsCarriedOutASAP() {
        return this.assessor.data.emergency_repairs_carried_out_ASAP;
    }

    getAreCowsPushed() {
        return this.assessor.data.are_cows_pushed;
    }

    getTargetDistanceBetweenHerdsmanAndTailEnderCows() {
        return convert(this.targeDistanceBetweenHerdsmanAndTailEnderCowsMin)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(0);
    }

    getDistanceBetweenHerdsmanAndTailEnderCows() {
        return this.assessor.data.distance_between_herdsman_and_tail_ender_cows;
    }

    getAllowsCowsTimeToNavigateObstacles() {
        return this.assessor.data.allows_cows_time_to_navigate_obstacles;
    }

    getNoYellingClaxonsRevvingMotor() {
        return this.assessor.data.no_yelling_claxons_revving_motor;
    }

};

export default Racetrack;