/**
 * The hygiene assessor determines the overall hoof hygiene of a group of cows by assigning each animal a number representing the level of
 * hygiene of the cow (the higher the number the worse the hygiene of the cow) then summing and averaging the numbers across the entire herd.
 * The farmer can then use the average hygiene scores as a comparison for future assessments. The assessor also provides suggestions to the
 * user depending on the details of their dairies hygiene practices.
 */

require('./utils.js');
import Footbath from './Footbath';
import Assessor from './Assessor';

class HygieneAssessor extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;
        this.minimumFrequencyOfManureRemoval = 3
    }

    /* Score Based Functions */
    getFirstScore() {
        return this.assessor.data.hygiene_score_1;
    }

    getSecondScore() {
        return this.assessor.data.hygiene_score_2;
    }

    getThirdScore() {
        return this.assessor.data.hygiene_score_3;
    }

    getFourthScore() {
        return this.assessor.data.hygiene_score_4;
    }

    getTotalScore() {
        return this.getFirstScore() + this.getSecondScore() + this.getThirdScore() + this.getFourthScore();
    }

    getPercentageOfTotal(score) {
        return (score / this.getTotalScore()).toPercentage();
    }

    getFrequencyOfManureRemoval() {
        return this.assessor.data.frequency_of_manure_removal;
    }

    getMinimumFrequencyOfManureRemoval() {
        return this.minimumFrequencyOfManureRemoval;
    }

    getSummerManureRemovalMethod() {
        return this.app.$t(this.assessor.data.summer_manure_removal_method);
    }

    getWinterManureRemovalMethod() {
        return this.app.$t(this.assessor.data.winter_manure_removal_method);
    }

    getBeddingType() {
        return this.app.$t(this.assessor.data.bedding_type);
    }

    getCowsInPen() {
        return this.assessor.data.cows_in_pen;
    }

    getCowsDryLot() {
        return this.assessor.data.cows_dry_lot;
    }

    getIsTheLotDry() {
        return this.assessor.data.is_the_lot_dry;
    }

    getExistingFootbaths() {
        if (this.assessor.footbaths) {
            let footbaths = {};
            if ( Array.isArray(this.assessor.footbaths) ) {
                return this.assessor.footbaths
                        .filter(footbath => {
                            return footbath.volume !== undefined;
                        })
                        .map(footbath => {
                            return new Footbath(this.app, this.evaluation, this.group, this.assessor, footbath);
                        });
            } else {
                for (let footbathId in this.assessor.footbaths) {
                    if ( this.assessor.footbaths[footbathId].volume ) {
                        footbaths[footbathId] = new Footbath(this.app, this.evaluation, this.group, this.assessor, this.assessor.footbaths[footbathId]);
                    };
                }
                return footbaths;
            }
        }
        return {};
    }

    getNewFootbath() {
        if (this.assessor.new_footbath === undefined) return undefined;

        return new Footbath(this.app, this.evaluation, this.group, this.assessor, this.assessor.new_footbath);
    }

    /** 
     *   gets the suggested footbath frequency
     */
    getFrequencySuggested(score) {
        if (score >= 75) {
            return 7;
        } else if (score <= 75 && score >= 51) {
            return 5;
        } else if (score <= 50 && score >= 25) {
            return 2;
        } else {
            return 0;
        }
    }

    // getFrequencySuggested(percent3and4) {
    //     if (percent3and4 >= 75) {
    //         this.suggestedFrequency = 7;
    //     } else if (percent3and4 <= 75 && percent3and4 >= 51) {
    //         this.suggestedFrequency = 5;
    //     } else if (percent3and4 <= 50 && percent3and4 >= 25) {
    //         this.suggestedFrequency = 2;
    //     } else {
    //         this.suggestedFrequency = 0;
    //     }
    // },

};

export default HygieneAssessor;