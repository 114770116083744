<template>
  <div
    class="container main-container background-white"
    v-if="clonedAssessorTypes !== null"
  >
    <div class="row row__assessors">
      <div class="col-md-24">
        <h1 class="h1 h1--assessors">{{ 'Assessor Order' | translate }}</h1>
        {{
          'Drag and drop the assessors in the order you would like them to appear in your evaluation.'
            | translate
        }}
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card bg-light mt-2">
          <div class="card-body card-body--white card-body--white--none-sm">
            <ul
              class="list-group list-group-flush"
              v-sortable2="{ onUpdate: onUpdate }"
            >
              <li
                class="list-group-item drag-item"
                v-for="(assessorTypeId, index) in clonedAssessorTypes"
                v-if="
                  $store.state.assessorTypes.find(
                    assessorType => assessorType.id === assessorTypeId
                  )
                "
                :key="index"
                :data-id="assessorTypeId"
              >
                <div class="two-bar-nav">
                  <div class="bar1"></div>
                  <div class="bar2"></div>
                </div>
                <p class="list-group-item__text">
                  {{
                    $t(
                      $store.state.assessorTypes.find(
                        assessorType => assessorType.id === assessorTypeId
                      ).name
                    )
                  }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row row__assessors-bottom d-flex">
      <div class="col-sm-12 order-2 order-sm-1">
        <router-link
          v-if="showQuickStartBackButton === true"
          :to="'/quick-start/' + $route.params.evaluationId"
          class="btn btn-secondary btn-block--xs mt-2 mt-sm-0"
          >{{ 'Back' | translate }}</router-link
        >
        <a
          v-else
          class="btn btn-secondary btn-block--xs mt-2 mt-sm-0"
          href="javascript:history.go(-1)"
          >{{ 'Back' | translate }}</a
        >
      </div>
      <div class="col-sm-12 order-1 order-sm-2">
        <button
          @click="nextStep"
          v-if="firstAssessorId"
          class="btn btn-primary btn-block--xs pull-right"
        >
          {{ 'Next' | translate }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @TODO The mobile state needs to be audited.

import Vue from 'vue';
import _ from 'lodash';
import Sortable from 'sortablejs';
import {
  GET_EVALUATION_BY_ID_GETTER,
  GET_UPDATE_EVALUATION,
} from '../../store/types';
import { mapActions, mapGetters, mapState } from 'vuex';

Vue.directive('sortable2', {
  inserted: function(el, binding) {
    let sortable = new Sortable(el, binding.value || {});
  },
});

export default {
  data() {
    return {
      evaluation: null,
      assessorTypes: null,
      clonedAssessorTypes: null,
      evaluationId: this.$route.params.evaluationId,
      load: false,
      showQuickStartBackButton: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    let self = this;
    next(self => {
      if (from.path === '/quick-start/') {
        self.showQuickStartBackButton = true;
      } else {
        self.showQuickStartBackButton = false;
      }
      next();
    });
  },
  methods: {
    ...mapActions('evaluations', [GET_UPDATE_EVALUATION]),
    onUpdate(event) {
      this.evaluation.order.splice(
        event.newIndex,
        0,
        this.evaluation.order.splice(event.oldIndex, 1)[0]
      );
    },
    async saveAssessorOrder() {
      this[GET_UPDATE_EVALUATION](this.evaluation);
    },
    nextStep() {
      this.saveAssessorOrder();

      let group;

      for (let groupId in this.evaluation.groups) {
        if (
          this.evaluation.groups[groupId].assessor_list.includes(
            this.evaluation.order[0]
          )
        ) {
          group = this.evaluation.groups[groupId];
          break;
        }
      }

      this.$router.push(
        '/users/' +
          this.$route.params.userId +
          '/evaluations/' +
          this.$route.params.evaluationId +
          '/groups/' +
          group.id +
          '/assessors/' +
          this.evaluation.order[0]
      );
    },
  },
  computed: {
    ...mapGetters('evaluations', [GET_EVALUATION_BY_ID_GETTER]),
    ...mapState({
      // evaluation: state => state.evaluations.evaluation,
    }),
    firstAssessorId() {
      let foundAssessorType;
      for (let groupId in this.evaluation.groups) {
        foundAssessorType = this.evaluation.groups[groupId].assessor_list.find(
          assessorType => {
            return assessorType === this.evaluation.order[0];
          }
        );
      if (foundAssessorType !== undefined) break;
      }

      if (foundAssessorType !== undefined) {
        return foundAssessorType;
      }

      return false;
    },
  },
  async created() {
    setTimeout(() => {
      this.evaluation = this[GET_EVALUATION_BY_ID_GETTER](this.evaluationId);
      let assessorsInEvaluation = [];

      for (let groupId in this.evaluation.groups) {
        for (let j in this.evaluation.groups[groupId].assessor_list) {
          assessorsInEvaluation.push(
            this.evaluation.groups[groupId].assessor_list[j]
          );
        }
      }

      assessorsInEvaluation = _.uniqWith(assessorsInEvaluation, _.isEqual)

      this.clonedAssessorTypes = _.clone(this.evaluation.order);
      if(this.evaluation.order) {
        assessorsInEvaluation = assessorsInEvaluation.sort(
          (a, b) =>
          this.clonedAssessorTypes.indexOf(a) -
          this.clonedAssessorTypes.indexOf(b)
          )
        }

      let sortedAssessorsInEvaluation = assessorsInEvaluation; //.sort();
      let sortedEvalOrder = this.clonedAssessorTypes; //_.sortBy(this.clonedAssessorTypes);

      if (!_.isEqual(sortedAssessorsInEvaluation, sortedEvalOrder)) {
        this.clonedAssessorTypes = _.clone(assessorsInEvaluation);
        this.evaluation.order = assessorsInEvaluation;
      }
      console.log('3', this.evaluation);

      // this.saveAssessorOrder();
    }, 1000);
  },
};
</script>
