import convert from 'convert-units';

import { comparativeValues } from '@/libs/Classes/constants/transition';
import { assessorIds } from '@/libs/Classes/constants/assessorIds';

import Evaluation from '@/libs/Classes/Evaluation.js';
import TimeBudget from '@/libs/Classes/TimeBudget.js';
import FreeStall from '@/libs/Classes/FreeStall.js';
import Group from '@/libs/Classes/Group.js';

// @TODO: The goal is to remove evaluation dependecy

export function getAssessorField(group, assessorId, key) {
  let assessor = group.assessors[assessorId];
  if (!assessor) return;
  return assessor.data[key];
}

export function filterGroupsByFieldValue(groups, assessorId, key, value) {
  let filteredGroups = groups.filter(
    group => getAssessorField(group.group || group, assessorId, key) == value
  );

  return filteredGroups;
}

export function filterGroupsByType(typeOfAnimal, groups) {
  let filteredGroups = [];

  if (groups && Array.isArray(groups)) {
    filteredGroups = groups.filter(
      group => (group.getTypeOfAnimal ? group.getTypeOfAnimal() : group.data.type_of_animal) === typeOfAnimal
    );
  } else {
    for (const group of Object.values(groups)) {
      if (group.data.type_of_animal === typeOfAnimal) {
        filteredGroups.push(group);
      }
    }
  }

  return filteredGroups;
}

// Requires evaluation
export const animalMixin = {
  computed: {
    groupTypes() {
      const groupTypes = new Set();

      for (let group of Object.values(this.evaluation.groups)) {
        groupTypes.add(group.data.type_of_animal);
      }

      return [...groupTypes];
    },
  },

  methods: {
    filterGroupsByType,
    filterGroupsByFieldValue,
    getAssessorField,


    sumFields(typedGroups, callback) {
      return typedGroups.reduce(
        (accum, curr) => accum + Number(callback(curr)) || 0,
        0
      );
    },


    getGroup(idx) {
      return this.evaluation.groups[idx];
    },


    timeBudgetInstance(group) {
      if (!group.assessors[assessorIds.timeBudget]) return {};

      return new TimeBudget(
        this,
        this.evaluationInstance(),
        this.groupInstance(group),
        group.assessors[assessorIds.timeBudget]
      );
    },


    freestallInstance(group) {
      if (!group.assessors[assessorIds.freestall]) return null;

      return new FreeStall(
        this,
        this.evaluationInstance(),
        this.groupInstance(group),
        group.assessors[assessorIds.freestall]
      );
    },


    groupInstance(group) {
      return new Group(this, this.evaluation, group);
    },


    evaluationInstance() {
      return new Evaluation(this, this.evaluation);
    },


    getFeedingSpace(group) {
      let feedingSpace = Number(
        this.getAssessorField(group, assessorIds.transition, 'feed_bunk_space')
      );
      let feedingRequiredSpace
      let convertedFeedingSpace;

      if (this.evaluation.data.measurements === 'imperial') {
        feedingRequiredSpace = 30
        convertedFeedingSpace = convert(feedingSpace)
          .from('ft')
          .to('in');
      } else {
        convertedFeedingSpace = feedingSpace * 100
        feedingRequiredSpace = 75
      }

      // feeding space required per cow (75 cm / 30 in)
      return Math.round(convertedFeedingSpace / feedingRequiredSpace);
    },


    getDrinkingSpace(group) {
      let drinkingSpace = Number(
        this.getAssessorField(group, assessorIds.transition, 'drinking_space')
      );
      let convertedDrinkingSpace;
      let drinkingRequiredSpace

      if (this.evaluation.data.measurements === 'imperial') {
        drinkingRequiredSpace = 4
        convertedDrinkingSpace = convert(drinkingSpace)
          .from('ft')
          .to('in');
      } else {
        drinkingRequiredSpace = 10
        convertedDrinkingSpace = drinkingSpace * 100;
      }

      // drinking space required per cow (10 cm / 4 in)
      return Math.round(convertedDrinkingSpace / drinkingRequiredSpace);
    },


    getRecommendedSpace(cowSize) {
      const isMetric = this.evaluation.data.measurements === 'metric'
      switch (cowSize) {
        case '408.2-498.9':
          return isMetric ? 7 : 75
        case '498.9-589.6':
          return isMetric ? 8.4 : 90
        case '589.6-680.3':
          return isMetric ? 10.2 : 110
        case '680.3-771':
          return isMetric ? 12.1 : 130
        case '771-861.8':
          return isMetric ? 13.9 : 150
        case '861.8-952.5':
          return isMetric ? 15.8 : 170
      }
    },


    maximumCows(_, type) {
      const {
        herdSize,
        freshening_rate,
        calvin_pattern,
        close_up_days,
        maternity_days,
        fresh_days,
        length_of_dry_period_days,
        length_in_calving_pen
      } = this.evaluation.data;

      let res,
          calvingsPerDay;
      const defaultCalvingLen = 21;

      if (calvin_pattern === comparativeValues.calvinPattern.yearRound) {
        calvingsPerDay = (herdSize * freshening_rate) / length_in_calving_pen;
      } else if (calvin_pattern === comparativeValues.calvinPattern.seasonal) {
        calvingsPerDay = (herdSize * freshening_rate) / defaultCalvingLen;
      }

      let penStayMultipleFactor;
      let daysInPen = 0;

      if (type === 'fresh') {
        daysInPen = fresh_days;
      } else if (type === 'close-up') {
        daysInPen = close_up_days;
      } else if (type === 'far-off') {
        daysInPen = length_of_dry_period_days - close_up_days;
      } else if (type === 'maternity') {
        daysInPen = maternity_days;
      }

      if (daysInPen <= 2) {
        penStayMultipleFactor = 1.5;
      } else if (daysInPen > 2 && daysInPen < 22) {
        penStayMultipleFactor = 1.4;
      } else if (daysInPen >= 22 && daysInPen <= 31) {
        penStayMultipleFactor = 1.3;
      } else {
        penStayMultipleFactor = 1.2;
      }

      res = penStayMultipleFactor * calvingsPerDay * daysInPen;

      return Math.round(res);
    },


    cowsInGroup(typedGroups) {
      return this.sumFields(typedGroups, group => Number(group.data.size));
    },


    beddedPackAvailableCowSpaces(typedGroups, type) {
      const { data } = this.evaluation;
      const { measurements } = data;      

      return this.sumFields(typedGroups, group => {
        const isMaternityPen = this.getAssessorField(group, assessorIds.transition, 'is_maternity_pen');
        const totalMaternityPenSpace = this.getAssessorField(group, assessorIds.transition, 'total_maternity_pen_space');

        const maternityAccess = [
          type === 'close-up',
          isMaternityPen === 'yes',
          totalMaternityPenSpace || false,
          totalMaternityPenSpace !== '',
        ];

        return type === 'maternity'
          ? Math.round(
              Number(totalMaternityPenSpace) / (measurements === 'metric' ? 13 : 144)
            )
          // TO DO: when Joe needs to revert 'Bedded Pack Available Cow Spaces'&'Bedded Pack Cow Spaces' close-up dividing on Maternity and not Maternity
          // : maternityAccess.every(el => el)
          //   ? 0
            : Math.round(
                this.getAssessorField(group, assessorIds.transition, 'lying_space') /
                this.getRecommendedSpace(group.data.cow_size)
              );
      });
    },


    freestallAvailableCowSpaces(typedGroups) {
      return this.sumFields(typedGroups, group =>
        this.getAssessorField(group, assessorIds.transition, 'stalls_per_cow')
      );
    },


    headlockAvailableCowSpaces(typedGroups) {
      return this.sumFields(typedGroups, group => 
        this.getAssessorField(group, assessorIds.transition, 'total_headlocks'));
    },


    feedingAvailableCowSpaces(typedGroups) {
      return this.sumFields(typedGroups, group =>
        this.getFeedingSpace(group)
      );
    },


    drinkingAvailableCowSpaces(typedGroups) {
      return this.sumFields(typedGroups, group =>
        this.getDrinkingSpace(group)
      );
    },


    airSpeedAllCowsKey(groupId) {
      let key = this.getAssessorField(
        this.getGroup(groupId),
        assessorIds.transition,
        'air_speed_all_cows'
      );
      return key;
    },


    airSpeedAllCows(groupId) {
      const key = this.getAssessorField(
        this.getGroup(groupId),
        assessorIds.transition,
        'air_speed_all_cows'
      );

      return comparativeValues.airSpeedAllCows(
        key,
        this.evaluation.data.measurements
      );
    },


    airSpeedCowKey(groupId) {
      const key = this.getAssessorField(
        this.getGroup(groupId),
        assessorIds.transition,
        'air_speed_cow'
      );

      return key;
    },


    airSpeedCow(groupId) {
      const key = this.getAssessorField(
        this.getGroup(groupId),
        assessorIds.transition,
        'air_speed_cow'
      );

      return comparativeValues.airSpeedCow(key, this.evaluation.data.measurements);
    },


    heatAbatement(groupId) {
      const key = this.getAssessorField(
        this.getGroup(groupId),
        assessorIds.transition,
        'heat_abatement'
      );

      return comparativeValues.heatAbatement[key];
    },


    heatAbatementKey(groupId) {
      const key = this.getAssessorField(
        this.getGroup(groupId),
        assessorIds.transition,
        'heat_abatement'
      );

      return key;
    },


    timeLying(group) {
      const assessor = group.assessors[assessorIds.timeBudget];

      if (!assessor) return;

      return Number(this.timeBudgetInstance(group).getLyingHours()).toDecimal(1);
    },


    timeStanding(group) {
      const assessor = group.assessors[assessorIds.timeBudget];

      if (!assessor) return;

      return Number(24 - this.timeBudgetInstance(group).getLyingHours()).toDecimal(1);
      // return Number(this.timeBudgetInstance(group).getAlleyStandingHours());
    },


    timeMilking(group) {
      const assessor = group.assessors[assessorIds.timeBudget];

      if (!assessor) return;

      const timeBudgetInstanceMilkingHours = this.timeBudgetInstance(group)
        .getMilkingHours();

      return Number.parseFloat(timeBudgetInstanceMilkingHours
        .toFixed(1));
    },


    productionType(group) {
      const assessor = group.assessors[assessorIds.timeBudget];

      if (!assessor) return;

      return this.timeBudgetInstance(group).getProductionTypeTitle();
    }
  },
};
