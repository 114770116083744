import { locomotion } from './locomotion';
import { group } from './group';
import { medias } from './../Global/medias.js';

class LocomotionSlides {

    constructor(app, report, evaluation, group, user) {
    	this.app = app;
    	this.report = report;
    	this.evaluation = evaluation;
        this.group = group;
        this.user = user;
    }

	async add(tableOfContentsCalculatorPosition) {
        let assessor = this.group.getAssessorByType("3");

        if ( assessor === false || assessor.assessor.data === undefined ||
             [undefined, null].includes(assessor.getProductionType()) ) {

            throw this.group.getName() + this.app.$t(" - ") + this.app.$t("Locomotion assessor is not ready.");
        
        }

        this.report.tableOfContentsHelper("Locomotion", tableOfContentsCalculatorPosition, this.group);

		locomotion(this.app, this.report, this.evaluation, this.group, assessor);
        medias(this.app, this.report, this.group, "3", this.user);
	}

    groupSummary(tableOfContentsCalculatorPosition) {
        group(this.app, this.report, this.evaluation, 0); // Four Point Group Report
        group(this.app, this.report, this.evaluation, 1); // Five Point Group Report
    }
}

export default LocomotionSlides;
