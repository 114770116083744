<template>
  <div class="container main-container main-container--padding-b">
    <h1 class="h1">{{"Assessor Summary" | translate}}</h1>
    <div class="container main-container main-container--hygiene view">
      <div v-for="(assessorType, index) in evaluation.order" :key="index">
        <h3 class="h3">
          {{ $store.getters.getAssessorTypeById(assessorType).name | translate }}
        </h3>
        <li v-for="( group, g_index ) in getGroupsByAssessorType(assessorType, evaluation.groups)" :key="g_index"
            class="ml-4 mt-2 d-block">
          <status :assessor="getAssessor(assessorType, group)" :border="'grey'"/>
          <router-link
            :to="'/users/' + $route.params.userId + '/evaluations/' + evaluation._id + '/groups/' + group.id  + '/assessors/' + assessorType + '/?assessor_nav=assessor'"
            class="text-dark">
            {{group.name}}
          </router-link>
        </li>

        <hr v-if="index !== (evaluation.order.length-1)">

      </div>
    </div>

  </div>
</template>

<script>
/**
 *  @NICE Put Footbath in its own collection, so we can make updates to individual footbaths without having to update the whole assessor
 *  @TODO Scot to update state when we call 'setData'
 */

import Status from '@/components/evaluations/status.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Status
  },
  computed: {
    // evaluation: function () {
    //   return this.$store.getters.getData("users/" + this.$route.params.userId + "/evaluations/" + this.$route.params.evaluationId);
    // }
    ...mapState({
      evaluation: state => state.evaluations.evaluation
    })
  },
  data() {
    return {
      default_footbath: {
        id: this.$generateId(),
        name: '',
        consultation_date: '',
        group_pen_size: 0,
        cow_passes_bath: 0,
        labor_cost: 0,
        ph: 0,
        personal_notes: '',
        volume: {
          measurements_volume: 0,
          measurements_length: 0,
          measurements_width: 0,
          measurements_depth: 0
        },
        footbathMixIds: [],
      },
      footbath: null
    }
  },
  methods: {
    getGroupsByAssessorType(assessorType, groups) {
      if (Array.isArray(groups)) {
        return groups.filter(group => {
          return group.assessor_list.includes(assessorType);
        });
      } else {
        let newGroups = Object.values(groups)
        return newGroups.filter(group => {
          return group.assessor_list.includes(assessorType);
        });
      }
    },
    getAssessor(assessorType, group) {
      let assessorsList = group.assessors
      if (group.assessors === undefined) return {};
      if (!Array.isArray(group.assessors)) {
        assessorsList = Object.values(group.assessors)
      }
      return assessorsList.find(assessor => {
        if (assessorType === assessor.type) {
          return true;
        }
        return false;
      });
    },
  }
}
</script>
