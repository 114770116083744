export const GET_CLIENT_BY_ID = 'GET_CLIENT_BY_ID';
export const SET_CLIENT_BY_ID = 'SET_CLIENT_BY_ID';

export const GET_CLIENT_BY_USER_ID = 'GET_CLIENT_BY_USER_ID';
export const SET_CLIENT_BY_USER_ID = 'SET_CLIENT_BY_USER_ID';

export const GET_ADD_CLIENT_INTO_DB = 'GET_ADD_CLIENT_INTO_DB';
export const SET_ADD_CLIENT_INTO_DB = 'SET_ADD_CLIENT_INTO_DB';

export const GET_UPDATE_CLIENT = 'GET_UPDATE_CLIENT';
export const SET_UPDATE_CLIENT = 'SET_UPDATE_CLIENT';

export const GET_CLIENTS = 'GET_CLIENTS';
export const SET_CLIENTS = 'SET_CLIENTS';

// GETTERS TYPES
export const GET_DAIRY_LOCATION_BY_CLIENT_ID_GETTER = 'GET_DAIRY_LOCATION_BY_CLIENT_ID_GETTER';
export const GET_CONTACT_BY_CLIENT_ID_GETTER = 'GET_CONTACT_BY_CLIENT_ID_GETTER';
export const GET_CLIENT_BY_ID_GETTER = 'GET_CLIENT_BY_ID_GETTER';
// export const GET_CONTACT_BY_ID_GETTER = 'GET_CONTACT_BY_ID_GETTER';
