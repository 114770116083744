<template>
	<div class="container main-container main-container--hygiene view">
		<div class="row">
			<div class="col-lg-24">
				<h2 class="h2 d-inline-block">{{ $t('Tell Us About Your Hoof Trimming Program') }}</h2>
				<modal-info :value="'tell-us'" class="d-inline">
					<template slot="modal-content">
						<h2 class="h2 pb-2 pt-3 px-3 text-center">{{ $t('Hoof Trimming') }}</h2>
						<p class="px-3">{{ 'For more information about Hoof Trimming, click the link to learn more from' | translate }}
							<a :href="$t('https://library.zinprofirststep.com/hoof-trimming/')" @click="$goToLameness($t('/hoof-trimming/'), $event);" target="_blank">{{'FirstStep Resources' | translate}}</a>.
						</p>
                        <div class="row modal-bottom pb-3">
                            <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ $t("Cancel") }}</a>
                        </div>
					</template>
				</modal-info>
			</div>
		</div>

		<hr class="mb-lg-4">

        <div class="row">
			<div class="col-lg-24">
				<h2 class="h2 d-inline-block">{{ $t('Capacity') }}</h2>
			</div>
        </div>

		<div class="row">
			<div class="col-lg-12">
				<label class="mb-1">{{ $t('Group/Pen Size') }}</label>
				<input :value="groupClass.getGroupSize()" type="number" inputmode="numeric" @keypress="$isNumber" class="form-control" readonly/>
			</div>

            <div class="col-lg-12">
                <div class="form-group mb-0 mb-sm-1">
                    <label class="mb-1">{{ $t('Primary Hoof Trimmer') }}</label>
                    <select class="form-control" v-model="assessor.data.primary_hoof_trimmer">
                        <option :value="null" disabled>{{ $t("Select") }}</option>
                        <option value="employee">{{ $t("Employee") }}</option>
                        <option value="family_member">{{ $t("Family Member") }}</option>
                        <option value="outside_contractor">{{ $t("Outside Contractor") }}</option>
                        <option value="owner">{{ $t("Owner") }}</option>
                    </select>
                </div>
            </div>

            <div class="col-lg-12">
                <label class="mb-1">{{$t('Trimming Frequency (times/lactation)') }}</label>
                <input v-model.number="assessor.data.trimming_frequency" type="number" inputmode="numeric"  @keypress="$isNumber" class="form-control" />
            </div>

            <div class="col-lg-12">
                <label class="mb-1">{{$t('Trimmer Visit Frequency (times/year)') }}</label>
                <input v-model.number="assessor.data.trimmer_visit_frequency" type="number" inputmode="numeric"  @keypress="$isNumber" class="form-control" />
            </div>

            <div class="col-lg-12">
                <label class="mb-1">{{$t('Number of Trims Recorded per Year (Lame Included)') }}</label>
                <input v-model.number="assessor.data.number_of_trims_recorded_per_year" type="number" inputmode="numeric"  @keypress="$isNumber" class="form-control" />
            </div>

            <div class="col-lg-12">
                <label class="mb-1">{{$t('Percentage of Cows in Herd with Locomotion Scores of 4 and 5') }}</label>
                <input v-model.number="assessor.data.percentage_of_cows_in_herd_with_locomotion_scores_of_4_and_5" type="number" inputmode="decimal" class="form-control" />
            </div>
		</div>

        <hr>

        <question-three @change="assessor.data.are_heifers_trimmed_prior_to_calving = $event.value" :initial-value="assessor.data.are_heifers_trimmed_prior_to_calving">
            <div class="mb-1">
                <label class="label d-inline">
                    {{ $t("Are heifers trimmed prior to calving?") }}
                </label>
            </div>
        </question-three>

        <hr v-if="assessor.data.are_heifers_trimmed_prior_to_calving === 1">

        <div v-if="assessor.data.are_heifers_trimmed_prior_to_calving === 1" class="row">
            <div class="col-lg-12">
                <label class="mb-1">{{ $t('Heifers in group (# of heifers)') }}</label>
                <input v-model.number="assessor.data.heifers_in_group_number" type="number" inputmode="numeric"  @keypress="$isNumber" class="form-control" :disabled="assessor.data.heifers_in_group_percent !== null && assessor.data.heifers_in_group_percent !== ''"/>
            </div>

            <div class="col-lg-12">
                <label class="mb-1">{{ $t('Heifers in group (% of group)') }}</label>
                <input v-model.number="assessor.data.heifers_in_group_percent" type="number" inputmode="decimal" class="form-control" :disabled="assessor.data.heifers_in_group_number !== null && assessor.data.heifers_in_group_number !== ''"/>
            </div>
        </div>

        <hr>

        <question-three @change="assessor.data.are_lame_cows_treated_by_hoof_trimmer = $event.value" :initial-value="assessor.data.are_lame_cows_treated_by_hoof_trimmer">
            <div class="mb-1">
                <label class="label d-inline">
                    {{ $t("Are lame cows treated by hoof trimmer?") }}
                </label>
            </div>
        </question-three>

        <hr class="mb-4">

        <div class="row">

            <div class="col-lg-24">
                <h2 class="h2 d-inline-block">{{ $t('Technique') }}</h2>
            </div>

            <div class="col-lg-12">
                <div class="form-group mb-0 mb-sm-1">
                    <label class="mb-1">{{ $t('At what locomotion score are the dairy personnel trained to identify cows in need of treatment?') }}</label>
                    <select class="form-control" v-model="assessor.data.at_what_locomotion_score_are_the_dairy_personnel_trained_to_identify_cows_in_need_of_treatment">
                        <option :value="null" disabled>{{ $t("Select") }}</option>
                        <option value="1">{{ $t("1") }}</option>
                        <option value="2">{{ $t("2") }}</option>
                        <option value="3">{{ $t("3") }}</option>
                        <option value="4">{{ $t("4") }}</option>
                        <option value="5">{{ $t("5") }}</option>
                    </select>
                </div>
            </div>

            <div class="col-lg-24">
                <hr>

                <question-three @change="assessor.data.does_dairy_personnel_actively_identify_lame_cows_for_treatment_as_soon_as_they_occur = $event.value" :initial-value="assessor.data.does_dairy_personnel_actively_identify_lame_cows_for_treatment_as_soon_as_they_occur">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Do dairy personnel actively identify lame cows for treatment as soon as they occur?") }}
                        </label>
                    </div>
                </question-three>

                <hr>

                <question-three @change="assessor.data.does_the_hoof_trimmer_like_working_with_cows = $event.value" :initial-value="assessor.data.does_the_hoof_trimmer_like_working_with_cows">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Does the hoof trimmer like working with cows?") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.does_the_hoof_trimmer_handle_cows_gently = $event.value" :initial-value="assessor.data.does_the_hoof_trimmer_handle_cows_gently">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Does the hoof trimmer handle cows gently?") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.is_the_trimming_area_a_pleasant_place_to_work_in_all_weather_conditions = $event.value" :initial-value="assessor.data.is_the_trimming_area_a_pleasant_place_to_work_in_all_weather_conditions">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Is the trimming area a pleasant place to work in all weather conditions?") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.is_shade_available = $event.value" :initial-value="assessor.data.is_shade_available">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Is shade available for animals waiting to be trimmed or animals that have been trimmed?") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.is_water_available = $event.value" :initial-value="assessor.data.is_water_available">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Is water available for animals waiting to be trimmed or animals that have been trimmed?") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.is_feed_available = $event.value" :initial-value="assessor.data.is_feed_available">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Is feed available for animals waiting to be trimmed or animals that have been trimmed?") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.has_the_hoof_trimmer_received_formal_training = $event.value" :initial-value="assessor.data.has_the_hoof_trimmer_received_formal_training">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Has the hoof trimmer received formal training?") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.does_the_hoof_trimmer_use_a_lesion_recording_system_with_standard_definitions_for_hoof_lesions = $event.value" :initial-value="assessor.data.does_the_hoof_trimmer_use_a_lesion_recording_system_with_standard_definitions_for_hoof_lesions">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Does the hoof trimmer use a lesion recording system with standard definitions for hoof lesions?") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.is_there_a_noticeable_increase_in_new_cases_of_lameness_within_a_week_of_routine_trimming = $event.value" :initial-value="assessor.data.is_there_a_noticeable_increase_in_new_cases_of_lameness_within_a_week_of_routine_trimming">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Is there a noticeable increase in new cases of lameness within a week of routine trimming?") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.are_toes_trimmed_to_maintain_the_top_front_dorsal_hoof_wall_at_a_length_of_or_greater = $event.value" :initial-value="assessor.data.are_toes_trimmed_to_maintain_the_top_front_dorsal_hoof_wall_at_a_length_of_or_greater">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Are toes trimmed to maintain the top-front (dorsal) hoof wall at a length of ") + hooftrimming.getIdealAreToesTrimmed() + ' ' + $t($getEquivalentUnit(evaluation.data.measurements,'cm')) + $t(" or greater?") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.does_the_hoof_trimmer_spare_the_height_of_the_medial_claw_heel_inside_claw_of_the_hind_foot_in_particular_to_the_heel = $event.value" :initial-value="assessor.data.does_the_hoof_trimmer_spare_the_height_of_the_medial_claw_heel_inside_claw_of_the_hind_foot_in_particular_to_the_heel">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Does the hoof trimmer spare the height of the medial claw heel (inside claw) of the hind foot, in particular to the heel?") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.is_the_inside_wall_axial_wall_or_toe_of_the_inner_or_outer_wall_removed = $event.value" :initial-value="assessor.data.is_the_inside_wall_axial_wall_or_toe_of_the_inner_or_outer_wall_removed">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Is the inside wall (axial wall) or toe of the inner or outer wall removed?") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.are_claws_trimmed_so_that_the_sole_surface_is_flat_rather_than_concave = $event.value" :initial-value="assessor.data.are_claws_trimmed_so_that_the_sole_surface_is_flat_rather_than_concave">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Are claws trimmed so that the sole surface is flat rather than concave? (Do the toes splay?)") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.is_the_outer_wall_abaxial_wall_removed_from__the_toe_and_side_wall_excessively = $event.value" :initial-value="assessor.data.is_the_outer_wall_abaxial_wall_removed_from__the_toe_and_side_wall_excessively">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Is the outer wall (abaxial wall) removed from the toe and side wall excessively?") }}
                        </label>
                    </div>
                </question-three>
                <hr>

                <question-three @change="assessor.data.is_the_sole_trimmed_excessively = $event.value" :initial-value="assessor.data.is_the_sole_trimmed_excessively">
                    <div class="mb-1">
                        <label class="label d-inline">
                            {{ $t("Is the sole trimmed excessively? Does the sole spring under thumb pressure after trimming? (This conveys that the soles have been trimmed too thin.)") }}
                        </label>
                    </div>
                </question-three>
                <hr>
            </div>
        </div>

		<div class="row my-0">
			<div class="col-md-12">
				<personal-notes v-model="assessor.data.personal_notes"></personal-notes>
			</div>
			<div class="col-md-12">
				<presentation-notes v-model="assessor.data.presentation_notes"></presentation-notes>
			</div>
		</div>

		<div class="row mx-0 mt-4 justify-content-center justify-content-md-start center align-items-center">
			<photoUpload :assessorId="15" :assessor="assessor"></photoUpload>
		</div>

		<hr class="mb-3 mb-md-5 mt-3 mt-md-5">

		<div class="row misc-bottom">
			<div class="col-md-24">
				<router-link v-if="user && user.role !== 'data_entry'" :to="'/reports'" class="btn btn-primary btn-full--sm pull-right">{{"Reports" | translate}}</router-link>
				<button v-else-if="user && user.role === 'data_entry' && !$store.state.offline" data-toggle="modal" @click="requestReport(evaluation.name)" :data-target="['#requestSent-' + user.id]" class="btn btn-primary btn-full--sm pull-right">
					{{ 'Request Report' | translate }}
					<span v-show="requestReportLoader" class="loader ml-1"></span>
				</button>
			</div>
		</div>
		<div v-show="successMessage" class="alert alert-primary mt-3 mb-0" role="alert">
			{{ 'Your request has been sent!' | translate }}
		</div>

		<div v-show="errorMessage" class="alert alert-danger mt-3 mb-0" role="alert">
			{{ 'An error has occurred during your request!' | translate }}
		</div>
	</div>
</template>

<script>
	import _ from 'lodash';
	import PresentationNotes from './assessor_components/presentation-notes';
	import PersonalNotes from './assessor_components/personal-notes';
    import Question from './assessor_components/question';
    import QuestionThree from './assessor_components/question-three';
	import ModalInfo from '@/components/misc/modal-info.vue';
	import PhotoUpload from '@/components/misc/photoUpload';
	import HygieneHeader from './assessor_components/hygiene-header.vue';
	import HoofTrimming from '@/libs/Classes/HoofTrimming.js';
	import Evaluation from '@/libs/Classes/Evaluation.js';
	import Group from '@/libs/Classes/Group.js';
	import convert from 'convert-units';
    import requestReportMixin from '@/mixins/requestReport.js';
    import { mapState } from 'vuex';

	export default {
		components: {
			PersonalNotes,
			PresentationNotes,
            Question,
            QuestionThree,
			HygieneHeader,
			PhotoUpload,
			ModalInfo
		},
          mixins: [requestReportMixin],
		props: ['assessor', 'group', 'evaluation'],
		data() {
			return {
                hooftrimming: null
			}
        },
        watch: {
            // 'assessor.data': {
            //     deep: true
            // },
            'assessor.data.number_of_trims_recorded_per_year': function (newVal, oldVal){
                if(newVal < 0) {
                    this.assessor.data.number_of_trims_recorded_per_year = null;
                }
            },
            'assessor.data.percentage_of_cows_in_herd_with_locomotion_scores_of_4_and_5': function(newVal, oldVal){
                if (newVal > 100) {
                    this.assessor.data.percentage_of_cows_in_herd_with_locomotion_scores_of_4_and_5 = 100;
                } else if(newVal < 0) {
                    this.assessor.data.percentage_of_cows_in_herd_with_locomotion_scores_of_4_and_5 = null;
                }
            }
        },
        computed:{
            ...mapState({
                user: state => state.users.user
            })
        },
		created() {
			let evaluationClass = new Evaluation(this, this.evaluation);
			this.groupClass = new Group(this, evaluationClass, this.group);
            this.hooftrimming = new HoofTrimming(this, evaluationClass, this.groupClass, this.assessor);
		}
	}
</script>

