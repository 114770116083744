<template>
    <div>
        <a href="#" data-toggle="modal" :data-target="['#' + value]">
            <img src="@/assets/icons/Freestall_Warning.svg" alt="" class="warning ml-2">
        </a>
        <div class="modal fade" tabindex="-1" :id="[value]" role="dialog" :aria-labelledby="[value]" aria-hidden="true">
            <div class="modal-dialog modal-dialog--edit" role="document">
                <div class="modal-content edit-modal pt-0 px-0">
                    <div class="modal-body my-0 py-0 px-0">
                        <slot name="modal-content"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['value']
    }
</script>
