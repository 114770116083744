import {
  GET_USER_MEDIAS,
  SET_USER_MEDIAS,
  GET_FILES_METADATA,
  SET_FILES_METADATA,
  GET_EACH_FILE_DATA,
  SET_COUNTRIES,
  SET_STATES,
  SET_TRANSLATIONS,
  GET_COUNTRIES,
  GET_STATES,
  GET_TRANSLATION,
  GET_FILES,
  SET_FILES,
  GET_UPLOAD_DOC,
  SET_UPLOAD_DOC,
  GET_CURRENCIES,
  SET_CURRENCIES,
  SET_FETCH_BASE64,
  GET_FETCH_BASE64,
} from '../types';
import axios, { baseURL } from '../../../Axios.js';
import { URLS } from '../../constants';
import XLSX from 'xlsx';
import { get } from 'lodash';

const state = {
  medias: {
    id: null,
    dateCreated: null,
    fileName: null,
    mimeType: null,
    fileSize: null,
    comment: null,
  },
};

const getters = {};

const mutations = {
  [SET_FILES_METADATA](STATE, { METADATA, TYPE }) {
    const FILE_LIST = state[TYPE];

    METADATA.forEach(metadata => {
      // fileUrl = baseURL + encodeURIComponent(metadata.fullPath) + '?alt=media';
      const specificFileIndex = FILE_LIST.findIndex(
        el => el.fileName === metadata.name
      );
      FILE_LIST[specificFileIndex].metadata = {
        ...JSON.parse(JSON.stringify(metadata)),
        // fileUrl,
      };
    });

    STATE.settingsList = FILE_LIST;
  },

  [SET_FETCH_BASE64](STATE, { medias }) {},
  // [SET_STATES](STATE, STATES) {
  //   const outputData = STATES.reduce((states, state) => {
  //     const { country_code, isPrimary, code, title } = state;
  //     if (!states[country_code]) {
  //       states[country_code] = {};
  //       states[country_code].primary = {};
  //       states[country_code].regular = {};

  //     }

  //     states[country_code][isPrimary ? 'primary' : 'regular'][code] = title;
  //     return states;
  //   }, {});

  //   STATE.misc.states = outputData;
  // },

  // [SET_TRANSLATIONS](STATE, { FILE_DATA, CODE}) {
  //   const outputData = {};
  //   FILE_DATA.forEach(item => {
  //     outputData[item.originalValue] = item.translatedValue;
  //   });
  //   console.log({ CODE: outputData });
  //   STATE.$i18n.add(CODE, outputData);
  // },
};

const actions = {
  [GET_USER_MEDIAS]({ commit: COMMIT }, USER_ID) {
    return new Promise(function(resolve, reject) {
      axios
        .get(URLS.MEDIAS, { params: { id: USER_ID } })
        .then(function(res) {
          COMMIT(SET_USER_MEDIAS, res.data.data);
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_UPLOAD_DOC]({ commit: COMMIT }, DATA) {
    return new Promise(function(resolve, reject) {
      axios
        .post(URLS.DOC, DATA)
        .then(function(res) {
          COMMIT(SET_UPLOAD_DOC, res.data.data, { root: true });
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  [GET_FETCH_BASE64]({ commit: COMMIT }, FILE_ID = '') {
    return new Promise(function(resolve, reject) {
      axios
        .get(`${URLS.MEDIAS}/${FILE_ID}`)
        .then(function(res) {
          // COMMIT(SET_FETCH_BASE64, res.data.data);
          return resolve({ base64: res.data.data, id: FILE_ID.split('.')[0] });
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },

  // [GET_EACH_FILE_DATA]({ commit: COMMIT }, { FILE_URL, TYPE }) {
  //   axios
  //     .get(FILE_URL)
  //     .then(res => res.arrayBuffer())
  //     .then(out => {
  //       const workbook = XLSX.read(new Uint8Array(out), {
  //         type: 'array',
  //       });
  //       const sheet = workbook.Sheets['currency'];
  //       const currencies = XLSX.utils.sheet_to_json(sheet);
  //       const outputData = {};
  //       currencies.forEach(item => {
  //         outputData[item.code] = `${item.title} (${item.code}, ${item.symbol})`;
  //       });
  //       this.$store.state.misc[TYPE] = outputData;
  //     });
  // },

  async [GET_FILES]({ commit: COMMIT }, TYPE) {
    const list = await axios.get(URLS.MEDIAS, { params: { type: TYPE } });
    COMMIT(SET_FILES, { fileList: list.data.data, type: TYPE }, { root: true });
  },

  [GET_COUNTRIES]({ commit: COMMIT }, FILE_URL) {
    return new Promise(function(resolve, reject) {
      fetch(FILE_URL)
        .then(res => res.arrayBuffer())
        .then(out => {
          const workbook = XLSX.read(new Uint8Array(out), {
            type: 'array',
          });
          const sheet = workbook.Sheets['countries'];
          const countries = XLSX.utils.sheet_to_json(sheet);
          COMMIT(`misc/${SET_COUNTRIES}`, countries, { root: true });
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_STATES]({ commit: COMMIT }, FILE_URL) {
    return new Promise(function(resolve, reject) {
      fetch(FILE_URL)
        .then(res => res.arrayBuffer())
        .then(out => {
          const workbook = XLSX.read(new Uint8Array(out), {
            type: 'array',
          });
          const sheet = workbook.Sheets['states'];
          const states = XLSX.utils.sheet_to_json(sheet);
          COMMIT(`misc/${SET_STATES}`, states, { root: true });
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_CURRENCIES]({ commit: COMMIT }, FILE_URL) {
    return new Promise(function(resolve, reject) {
      fetch(FILE_URL)
        .then(res => res.arrayBuffer())
        .then(out => {
          const workbook = XLSX.read(new Uint8Array(out), {
            type: 'array',
          });
          const sheet = workbook.Sheets['currency'];
          const currencies = XLSX.utils.sheet_to_json(sheet);
          COMMIT(`misc/${SET_CURRENCIES}`, currencies, { root: true });
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_TRANSLATION]({ commit: COMMIT }, { FILE_URL, CODE, $app }) {
    return new Promise(function(resolve, reject) {
      fetch(FILE_URL)
        .then(res => res.arrayBuffer())
        .then(out => {
          const workbook = XLSX.read(new Uint8Array(out), {
            type: 'array',
          });
          const sheet = workbook.Sheets['Sheet 1'];
          const fileData = XLSX.utils.sheet_to_json(sheet);
          COMMIT(
            SET_TRANSLATIONS,
            { FILE_DATA: fileData, CODE, $app },
            { root: true }
          );
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
