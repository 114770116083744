const convert = require('convert-units');

class Product {

    constructor(app, evaluation, group, assessor, footbath, mix, product) {
        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;
        this.footbath = footbath;
        this.mix = mix;
        this.product = product;
    }

    getTitle() {
        return this.product.title;
    }

    getClassification() {
        return this.product.classification;
    }

    getInclusionRate(measurement = this.evaluation.getMixMeasurements() , roundPoint) {

        let concentrationPercentage = parseFloat(this.getConcentrationPercentage());
    
        if (isNaN(concentrationPercentage)) {
            concentrationPercentage = 0;
        }
        let value = concentrationPercentage;
      
        if (measurement === "imperial" && this.product.classification === 'Liquid') {
            value = concentrationPercentage * 1.28;
        }
    
        if (measurement === "metric" && ['Solid', 'Liquid'].includes(this.product.classification)) {
            value = concentrationPercentage * 10;
        }
    
        if (measurement === "imperial" && this.product.classification === 'Solid') {
            value = concentrationPercentage * 0.0834;
        }
    
        // Check if value is decimal
        const isDecimal = value % 1 !== 0;
    
        // If it's decimal, return the value as is
        if (isDecimal) {
            return value.toFixed(4);
        } else {
            // If it's not decimal, round to 4 decimal places
            return parseFloat(value).toFixed(4);
        }
    }
    

    getConcentrationPercentage() {
        return this.product.concentration;
    }

    setConcentrationPercentage(newValue) {
        this.product.concentration = newValue;
    }

    getPricePerUnit(returnNumber) {
        let value = this.product.price;

        let fromUnit = (this.getClassification() === "Solid") ? "g" : "ml";

        if ( typeof value !== "number" )
            value = 0;

        value = convert(value)
                .from(fromUnit)
                .to(this.app.$getEquivalentUnit(this.evaluation.getMixMeasurements(), fromUnit));

        if ( returnNumber === undefined )
            returnNumber = false;

        if ( returnNumber === true ) {
            return value;
        }

        return value.toCurrency();
    }


    setPricePerUnit(newValue) {
        let fromUnit = (this.getClassification() === "Solid") ? "g" : "ml";

        this.product.price = parseFloat(convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMixMeasurements(),fromUnit))
            .to(fromUnit))
            .toDecimal(newValue.countDecimals() + 3);
    }

    /**
     *  Gets the total amount of product included in footbath
     */

    getAmountPerFootbath(roundPoint) {
        let amountPerFootbath = (this.getInclusionRate() * this.footbath.getVolume() / 1000); 
        if (this.evaluation.getMeasurements() === 'imperial' ) {
            amountPerFootbath = (this.getInclusionRate() * this.footbath.getVolume()); 
        }
        
        if ( this.getClassification() === "Liquid" ) {
            amountPerFootbath = (this.getInclusionRate() * this.footbath.getVolume());
            amountPerFootbath = convert(amountPerFootbath)
                                 .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), "ml"))
                                 .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),"l"));
        }

        if(roundPoint === undefined){
            roundPoint = amountPerFootbath.countDecimals();
            if (roundPoint > 3) roundPoint = 3;
        }

        return amountPerFootbath.toDecimal(roundPoint);
    }

    getCostPerBath(returnNumber) {
        if ( returnNumber === undefined ) returnNumber = false;

        let costPerBath = (this.getPricePerUnit(true) * this.getAmountPerFootbath()) + (this.footbath.getLaborCost() / this.mix.getProducts().length);

        if ( returnNumber === true ) {
            return parseFloat(costPerBath);
        }

        return costPerBath.toCurrency();
    }

    getCostPerCowPerYear(returnNumber) {
        if ( returnNumber === undefined ) returnNumber = false;

        let costPerCowPerYear = (this.getCostPerBath(true) * (this.mix.getTotalFootbathsPerWeek() * 52)) / this.footbath.getCowPassesPerBath();

        if ( returnNumber === true ) {
            return parseFloat(costPerCowPerYear);
        }

        return costPerCowPerYear.toCurrency();
    }

    getCostPerHerdPerYear(returnNumber) {
        if ( returnNumber === undefined ) returnNumber = false;

        let costPerHerdPerYear = this.getCostPerCowPerYear(true) * this.group.getGroupSize();

        if ( returnNumber === true ) {
            return parseFloat(costPerHerdPerYear);
        }

        return costPerHerdPerYear.toCurrency();
    }
};

export default Product;
