<template>
    <div style="display: inline">
        <a href="#" data-toggle="modal" :data-target="['#info-' + value]">
            <!-- <i aria-hidden="true" class="fa fa-info-circle tooltips ml-1"></i> -->
            <img v-if="iconColor === 'white'" src="@/assets/img/Zinpro_ibutton_white.svg" alt="" class="info ml-1">
            <img v-else src="@/assets/img/Zinpro_ibutton_DarkBG.svg" alt="" class="info ml-1">
        </a>
        <div class="modal fade" tabindex="-1" :id="['info-' + value]" role="dialog" :aria-labelledby="['info-' + value]" aria-hidden="true">
            <div class="modal-dialog modal-dialog--edit" role="document">
                <div class="modal-content edit-modal pt-0 px-0">
                    <div class="modal-body my-0 py-0 px-0">
                        <slot name="modal-content"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showModal: this.show
            }
        },
        props: {
            iconColor: {
              type: String,
              default: 'blue'
            },
            value: {
                type: String,
                default: ''
            },
            show: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            showModal(newValue) {
                if (newValue !== this.show) {
                    this.$emit('show:update', newValue)
                }
            },
            show(newValue) {
                if (newValue !== this.showModal) {
                    this.showModal = newValue
                }
            }
        }
    }
</script>
