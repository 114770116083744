// export default {
//   methods: {
//     async createFile(file_url) {
//       let response = await fetch(file_url);
//       let data = await response.blob();
//       const extension = file_url.split('.')[file_url.split('.').length - 1];
//       let metadata = {
//         type: `image/${extension}`,
//       };
//       return new File([data], `test.${extension}`, metadata);
//       // ... do something with the file or return it
//     },
//     //Upload a photo to the local
//    async fileUpload(url) {
//       // Getting the first file
//       let getFile = await createFile(url); // getFile is now a Blob
//       if (getFile.size / 1024 / 1024 > 5) {
//         // 5MB
//         alert('This file is too large, must be less than 5MB');
//         return;
//       }

//       let orientation;

//       this.toDataURL(getFile)
//         .then(processedImage => {
//           const maxWidth = 300;
//           let aspectRatio = processedImage.width / processedImage.height;
//           let height = maxWidth / aspectRatio;
//           let base64 = processedImage.base64;

//           if (processedImage.width > maxWidth) {
//             base64 = this.$store.getters.resizeBase64(base64, 300, height);
//           }

//           const orientationPromise = new Promise((resolve, reject) => {
//             this.$store.getters.getOrientation(getFile, orientationNumber => {
//               if (orientationNumber !== undefined) {
//                 resolve(orientationNumber);
//               } else {
//                 reject(Error('It broke'));
//                 resolve(false);
//               }
//             });
//           })
//             .then(result => {
//               if (result !== false) {
//                 orientation = result;
//                 this.$store.getters.resetOrientation(base64, orientation, resetBase64Image => {
//                   base64 = resetBase64Image;
//                   this.profile.brandingImageBase64 = base64;
//                   this.profile.brandingImageHeight = height;
//                   this.profile.brandingImageWidth = maxWidth;
//                   this.$forceUpdate();
//                 });
//               } else {
//                 this.profile.brandingImageBase64 = base64;
//                 this.profile.brandingImageHeight = height;
//                 this.profile.brandingImageWidth = maxWidth;
//               }
//             })
//             .catch(err => {
//               console.log('ERROR #1', err);
//             });
//         })
//         .catch(err => {
//           console.log('ERROR #2', err);
//         });
//     },
//     removeBrandingImage(e) {
//       this.profile.brandingImageBase64 = null;
//       this.profile.brandingImageHeight = null;
//       this.profile.brandingImageWidth = null;
//     },
//     //Converts an uploaded photo to base64
//     toDataURL(file) {
//       return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onerror = reject;
//         reader.onloadend = e => {
//           resolve({
//             base64: e.target.result,
//             id: this.$generateId(),
//             comment: null,
//             date_created: Date.now(),
//           });
//         };
//         reader.readAsDataURL(file);
//       }).then(
//         file =>
//           new Promise((resolve, reject) => {
//             let image = new Image();
//             image.src = file.base64;
//             image.onload = () => {
//               file.width = image.width;
//               file.height = image.height;
//               resolve(file);
//             };
//           })
//       );
//     },
//     //Converts binary (DATA URI) from the database to a more uniform URL
//     dataURItoBlob(dataURI) {
//       let array, binary, i;

//       //Gets the binary from the Data URI
//       binary = atob(dataURI.split(',')[1]);
//       array = [];
//       i = 0;
//       while (i < binary.length) {
//         array.push(binary.charCodeAt(i));
//         i++;
//       }
//       //Takes a blob and converts it to a url:
//       return URL.createObjectURL(
//         new Blob([new Uint8Array(array)], {
//           type: 'image/jpeg',
//         })
//       );
//     },
//   },
// };

/** @format */

async function createFile(file_url) {
  console.log(file_url);
  let response = await fetch(file_url, { mode: 'no-cors' });
  let data = await response.blob();
  const extension = file_url.split('.')[file_url.split('.').length - 1];
  let metadata = {
    type: `image/${extension}`,
  };
  return new File([data], `test.${extension}`, metadata);
  // ... do something with the file or return it
}

//Upload a photo to the local
export function fileUpload(url) {
  console.log(url, 'running');
  return new Promise((resolve, reject) => {
    console.log(url, 'inside');
    let brandingImageBase64, brandingImageHeight, brandingImageWidth;
    // Getting the first file
    createFile(url).then(getFile => {
      console.log({ getFile });
      let orientation;
      toDataURL(getFile)
        .then(processedImage => {
          const maxWidth = 300;
          let aspectRatio = processedImage.width / processedImage.height;
          let height = maxWidth / aspectRatio;
          let base64 = processedImage.base64;

          if (processedImage.width > maxWidth) {
            base64 = resizeBase64(base64, 300, height);
          }

          new Promise((resolve, reject) => {
            getOrientation(getFile, orientationNumber => {
              if (orientationNumber !== undefined) {
                resolve(orientationNumber);
              } else {
                reject(Error('It broke'));
                resolve(false);
              }
            });
          })
            .then(result => {
              if (result !== false) {
                orientation = result;
                resetOrientation(base64, orientation, resetBase64Image => {
                  base64 = resetBase64Image;
                  brandingImageBase64 = base64;
                  brandingImageHeight = height;
                  brandingImageWidth = maxWidth;
                });
              } else {
                brandingImageBase64 = base64;
                brandingImageHeight = height;
                brandingImageWidth = maxWidth;
              }
              resolve({
                brandingImageBase64,
                brandingImageHeight,
                brandingImageWidth,
              });
              console.log(base64, height, maxWidth);
              return {
                brandingImageBase64,
                brandingImageHeight,
                brandingImageWidth,
              };
            })
            .catch(err => {
              reject(err);
              console.log('ERROR #1', err);
            });
        })
        .catch(err => {
          console.log('ERROR #2', err);
        });
    });
  }); // getFile is now a Blob
}

//Converts an uploaded photo to base64
function toDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onloadend = e => {
      resolve({
        base64: e.target.result,
        id: '123',
        comment: null,
        date_created: Date.now(),
      });
    };
    reader.readAsDataURL(file);
  }).then(
    file =>
      new Promise((resolve, reject) => {
        let image = new Image();
        image.src = file.base64;
        image.onload = () => {
          file.width = image.width;
          file.height = image.height;
          resolve(file);
        };
      })
  );
}

//Converts binary (DATA URI) from the database to a more uniform URL
function dataURItoBlob(dataURI) {
  let array, binary, i;

  //Gets the binary from the Data URI
  binary = atob(dataURI.split(',')[1]);
  array = [];
  i = 0;
  while (i < binary.length) {
    array.push(binary.charCodeAt(i));
    i++;
  }
  //Takes a blob and converts it to a url:
  return URL.createObjectURL(
    new Blob([new Uint8Array(array)], {
      type: 'image/jpeg',
    })
  );
}

function resizeBase64(base64, maxWidth, maxHeight) {
  // Max size for thumbnail
  if (typeof maxWidth === 'undefined') maxWidth = 500;
  if (typeof maxHeight === 'undefined') maxHeight = 500;

  // Create and initialize two canvas
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  var canvasCopy = document.createElement('canvas');
  var copyContext = canvasCopy.getContext('2d');

  // Create original image
  var img = new Image();
  img.src = base64;

  // Determine new ratio based on max size
  var ratio = 1;
  if (img.width > maxWidth) ratio = maxWidth / img.width;
  else if (img.height > maxHeight) ratio = maxHeight / img.height;

  // Draw original image in second canvas
  canvasCopy.width = img.width;
  canvasCopy.height = img.height;
  copyContext.drawImage(img, 0, 0);

  // Copy and resize second canvas to first canvas
  canvas.width = img.width * ratio;
  canvas.height = img.height * ratio;
  ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);

  return canvas.toDataURL();
}

function resetOrientation(srcBase64, srcOrientation, callback) {
  var img = new Image();

  img.onload = function() {
    var width = img.width,
      height = img.height,
      canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');

    // set proper canvas dimensions before transform & export
    if (4 < srcOrientation && srcOrientation < 9) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    // transform context before drawing image
    switch (srcOrientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, width, height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, height, width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, width);
        break;
      default:
        break;
    }

    // draw image
    ctx.drawImage(img, 0, 0);

    // export base64
    callback(canvas.toDataURL());
  };

  img.src = srcBase64;
}
function getOrientation(file, callback) {
  var reader = new FileReader();
  reader.onload = function(e) {
    var view = new DataView(e.target.result);
    if (view.getUint16(0, false) != 0xffd8) {
      return callback(-2);
    }
    var length = view.byteLength,
      offset = 2;
    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
      var marker = view.getUint16(offset, false);
      offset += 2;
      if (marker == 0xffe1) {
        if (view.getUint32((offset += 2), false) != 0x45786966) {
          return callback(-1);
        }

        var little = view.getUint16((offset += 6), false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        var tags = view.getUint16(offset, little);
        offset += 2;
        for (var i = 0; i < tags; i++) {
          if (view.getUint16(offset + i * 12, little) == 0x0112) {
            return callback(view.getUint16(offset + i * 12 + 8, little));
          }
        }
      } else if ((marker & 0xff00) != 0xff00) {
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }
    return callback(-1);
  };
  reader.readAsArrayBuffer(file);
}

export function getMeta(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.onload = function() {
      console.log(this.width + ' ' + this.height);
      resolve({ height: this.height, width: this.height });
      return { width: this.width, height: this.height };
    };
    img.src = url;
  }).catch(e => { 
    console.log(e)
  });
}

export function getBase64FromUrl(url) {
  return new Promise((resolve, reject) => {
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = function() {
      var reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload = function(e) {
        console.log('DataURL:', e.target.result);
        resolve(e.target.result);
        return e.target.result;
      };
    };
    request.send();
  });
}
export function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
}






// // How to use in an async function
// (async () => {
//   const imageUrl = 'http://your.website.com/userUploadedImage.jpg';
  // const imageDimensions = await imageSize(imageUrl);

//   console.info(imageDimensions); // {width: 1337, height: 42}
// })();
