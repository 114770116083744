<template>
  <div>
    <div class="main-container main-container--margin-bt container">
      <div class="card mb-0">
        <div class="row mx-0">
          <h1 class="h1 mb-3 mb-md-1">{{ 'Company Creation' | translate }}</h1>
        </div>

        <div class="card-body mt-3">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label
                  >{{ 'Name' | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  v-model="company.name"
                  data-vv-as="Name"
                  v-validate="'required'"
                />
                <span v-show="errors.has('name')" class="text-danger">
                  <small>{{ 'The Name field is required.' | translate }}</small>
                </span>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group">
                <label
                  >{{ 'Country' | translate }}
                  <span class="required">*</span>
                </label>
                <select
                  class="form-control"
                  v-model="company.country"
                  name="company_country"
                  data-vv-as="Country"
                  v-validate="'required'"
                >
                  <option :value="null" disabled>{{
                    'Select' | translate
                  }}</option>
                  <option
                    v-for="(country, index) in $store.state.misc
                      .countriesPrimary"
                    :key="index"
                    :value="index"
                  >
                    {{ country | translate }}
                  </option>
                  <option disabled>------------------</option>
                  <option
                    v-for="(country, index) in $store.state.misc.countries"
                    :key="index"
                    :value="index"
                  >
                    {{ country | translate }}
                  </option>
                </select>
                <span
                  v-show="errors.has('company_country')"
                  class="text-danger"
                >
                  <small>{{
                    'The Country field is required.' | translate
                  }}</small>
                </span>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <label
                  >{{ 'Address' | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="text"
                  name="address_v"
                  class="form-control"
                  v-model="company.address_1"
                  data-vv-as="Address"
                  v-validate="'required'"
                />
                <span v-show="errors.has('address_v')" class="text-danger">
                  <small>{{
                    'The Address field is required.' | translate
                  }}</small>
                </span>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <label
                  >{{ 'City' | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="text"
                  name="city"
                  class="form-control"
                  v-model="company.city"
                  data-vv-as="City"
                  v-validate="'required'"
                />
                <span v-show="errors.has('city')" class="text-danger">
                  <small>{{ 'The City field is required.' | translate }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-6" v-if="misc.states[company.country]">
              <div class="form-group">
                <label>{{ 'State/Province' | translate }}</label>
                <select class="form-control" v-model="company.state">
                  <option :value="null" selected disabled>{{
                    'Select' | translate
                  }}</option>
                  <template
                    v-if="
                      Object.keys(misc.states[company.country].primary).length
                    "
                  >
                    <option
                      v-for="(state, abbr) in misc.states[company.country]
                        .primary"
                      :key="state"
                      :value="abbr"
                    >
                      {{ state }}
                    </option>
                    <option disabled>------------------</option>
                  </template>
                  <template v-if="regularCountries">
                    <option
                      v-for="(state, abbr) in regularCountries"
                      :key="state"
                      :value="abbr"
                    >
                      {{ state }}
                    </option>
                  </template>
                </select>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <label
                  >{{ 'Postal Code' | translate }}
                </label>
                <input
                  type="text"
                  name="postal_code"
                  class="form-control"
                  v-model="company.postal_code"
                  data-vv-as="Postal"
                />
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <label
                  >{{ 'Phone' | translate }}
                  <span class="required">*</span>
                </label>
                <input
                  type="tel"
                  name="phone"
                  class="form-control"
                  v-model="company.phone"
                  data-vv-as="Phone"
                  v-validate="'required'"
                />
                <span v-show="errors.has('phone')" class="text-danger">
                  <small>{{
                    'The Phone field is required.' | translate
                  }}</small>
                </span>
              </div>
            </div>
          </div>
          <!-- end row -->
        </div>
        <!-- end card body -->

        <div
          v-show="errorMessage === true"
          class="alert alert-danger mt-4 mb-0"
          role="alert"
        >
          {{ message || 'Please fill out the required fields!' | translate }}
        </div>

        <div class="row d-flex">
          <div class="col-md-12 order-2 order-md-1">
            <router-link
              to="/admin/company-management"
              class="btn btn-secondary btn-full--sm mt-2 mt-md-4"
              >{{ 'Back' | translate }}</router-link
            >
          </div>
          <div class="col-md-12 order-1 order-md-2">
            <button
              @click="createCompany()"
              type="button"
              class="btn btn-primary btn-full--sm float-right mt-4"
              :disabled="loader"
            >
              {{ 'Create' | translate }}
              <span v-if="loader" class="loader ml-1"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end container -->
  </div>
</template>

<script>
import Vue from 'vue';
import { Validator } from 'vee-validate';

// profile component
import generalProfile from '../../../components/admin/general.vue';
import { GET_CREATE_COMPANY, GET_NEW_COMPANY } from '../../../store/types';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    generalProfile,
  },
  props: ['data'],
  data() {
    return {
      admin: true,
      errorMessage: false,
      message : null,
      company: {
        name: null,
        country: null,
        address_1: null,
        city: null,
        createdBy: null,
        state: null,
        postal: null,
        country: null,
        phone: null,
      },
      id: this.$route.params.id,
      isLoaded: true,
      isLoaded2: true,
      isLoaded3: true,
      loader: false,
      successMessage: false,
      userID: null,
    };
  },

  updated(data) {
    console.log(this.$store.state.misc.states);
  },

  methods: {
    // map all the actions from store, into the components
    ...mapActions('company', [GET_NEW_COMPANY]),
    createCompany() {
      this.loader = true;
      const { country, state } = this.company;
      const company = {
        createdBy: this.userId,
        ...this.company,
        state: country === 'US' || country === 'CA' ? state : null,
      };
      this.$validator.validateAll().then(result => {
        if (result === true) {
          this[GET_NEW_COMPANY](company)
            .then(res => {
              this.$router.push({
                path: '/admin/company-management',
                query: { info_code: 'companies/company-created' },
              });
            })
            .catch(error => {
              console.error(error);
              this.loader = false;
              this.message = error.message;
          this.errorMessage = true;
          setTimeout(() => {
            this.errorMessage = false;
            this.message = null
          }, 4000);
            });
        } else {
          this.loader = false;
          this.errorMessage = true;
          setTimeout(() => {
            this.errorMessage = false;
          }, 4000);
        }
      });
    },
  },
  computed: {
    /* this component uses these state, which is comming from store,
      everytime state goes changes, any component who uses this state will rernder. */
    ...mapState({
      userId: state => state.users.user._id,
      user: state => state.users.user,
      misc: state => state.misc,
    }),
    regularCountries() {
      return this.misc.states && this.misc.states[this.company.country].regular;
    },
  },
  created() {
    this.userID = this.user._id;
  },
};
</script>
