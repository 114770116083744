<template>
  <div class="container main-container main-container--accordion">
    <!-- START USER EVALUATIONS -->
    <div class="row">
      <div
        class="col-lg-16 evaluations__top-left d-inline-flex justify-content-between align-items-center"
      >
        <h2 class="h1 h1--evaluations">
          <span>{{ 'My Evaluations' | translate }}</span>
        </h2>
      </div>
      <div
        class="col-lg-8 d-flex align-items-center justify-content-lg-end flex-column flex-md-row"
      >
        <router-link
          to="/quick-start/"
          class="btn btn-primary btn-primary--thin btn-primary--thin--long btn-full--sm ml-0 ml-xl-2"
        >
          {{ 'Quick Start Evaluation' | translate }}
        </router-link>
        <router-link
          to="/evaluations/new"
          class="btn btn-primary btn-primary--thin btn-primary--thin--short btn-full--sm ml-md-2 mt-2 mt-md-0"
        >
          {{ 'New Evaluation' | translate }}
        </router-link>
      </div>
    </div>

    <hr class="my-4 d-lg-none" />

    <evaluation-list :user="user"></evaluation-list>
    <!-- END USER EVALUATIONS -->

    <!-- START CUSTOMER EVALUATIONS -->
    <div v-show="user.role === ROLES.zinpro_representative">
      <div class="row mt-5">
        <div
          class="col-lg-16 evaluations__top-left d-inline-flex justify-content-between align-items-center"
        >
          <h2 class="h1 h1--evaluations">
            <span>{{ 'Customer Evaluations' | translate }}</span>
          </h2>
        </div>
        <div class="col-lg-8">
          <select
            class="form-control"
            v-model="customerId"
            @change="changeCustomer(customerId)"
          >
            <option selected disabled :value="null">{{
              'Select' | translate
            }}</option>
            <option
              v-for="(customer, index) in user.customers"
              :key="index"
              :value="customer.id"
            >
              {{ customer.name }} ({{
                $store.getters.getRoleName(customer.role) | translate
              }})
            </option>
          </select>
        </div>
      </div>
      <hr class="my-4 d-lg-none" />
      <div v-if="customer === null">
        <div class="mt5 text-center">
          <h5 class="text-uppercase">
            {{ 'You have not selected a customer.' | translate }}
          </h5>
        </div>
      </div>
      <evaluation-list v-else :customerId="customer._id"></evaluation-list>
      <!-- END CUSTOMER EVALUATIONS -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import EvaluationList from '@/pages/evaluations/EvaluationList.vue';
import { ROLES } from '../../constants';
import { mapActions, mapState } from 'vuex';
import {
  GET_CUSTOMER_BY_ID,
  GET_USER_BY_ID,
  SET_UPDATE_LOGIN_USER,
} from '../../store/types';

export default {
  components: {
    EvaluationList,
  },
  data() {
    return {
      customer: null,
      customerId: null,
      ROLES,
    };
  },
  computed: {
    ...mapState({
      user: state => state.users.user,
      users: state => state.users.users,
    }),
  },
  methods: {
    ...mapActions('users', [GET_CUSTOMER_BY_ID, GET_USER_BY_ID]),
    async changeCustomer(customerId) {
      this.customer = this.users.find(
        user => user && user._id === this.customerId
      );
      if (!this.customer)
        this.customer = await this[GET_CUSTOMER_BY_ID](this.customerId);
    },
    displayError(errorCode) {
      console.log('errorCode', errorCode);
      if (errorCode === 'evaluation/not-found') {
        this.errorMessage =
          'Sorry! We can not find that evaluation at this time.';
      } else {
        this.errorMessage = 'Sorry! Something went wrong.';
      }
    },
  },
  async created() {
    // const user = await this[GET_USER_BY_ID](this.user._id);
    // this.$store.commit(`users/${SET_UPDATE_LOGIN_USER}`, user);

    if (this.$route.query.errorCode !== undefined) {
      this.displayError(this.$route.query.errorCode);
    }
  },
};
</script>
