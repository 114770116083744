/**
 * The Holding Yard & Milking Shed Assessor focuses on evaluation time out of the pen, foot trauma and heat stress as it relates to controlling lameness
 * on the dairy.
 */
const convert = require('convert-units');
import Assessor from './Assessor';

class HoldingYardMilkShed extends Assessor {
  constructor(app, evaluation, group, assessor) {
    super(app, evaluation, group, assessor);

    this.app = app;
    this.evaluation = evaluation;
    this.group = group;
    this.assessor = assessor;
  }

  getYardShape() {
    return this.assessor.data.yard_shape;
  }

  getYardDiameter() {
    return this.assessor.data.yard_diameter;
  }

  getMilkingShedType() {
    return this.assessor.data.milking_shed_type;
  }

  getJerseys() {
    return this.assessor.data.jerseys;
  }

  getFresians() {
    return this.assessor.data.fresians;
  }

  getJFCrossbreed() {
    return this.assessor.data.jf_rossbreed;
  }

  getYardSizeFtWide() {
    return this.assessor.data.yard_size_ft_wide;
    // return convert(this.assessor.data.yard_size_ft_wide)
    //   .from('m')
    //   .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
    //   .toDecimal(0);
  }

  getYardSizeFtLong() {
    return this.assessor.data.yard_size_ft_long;
    // return convert(this.assessor.data.yard_size_ft_long)
    //   .from('m')
    //   .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
    //   .toDecimal(0);
  }

  getYardArea() {
    if (this.assessor.data.yard_shape === 'rectangular') {
      return (this.getYardSizeFtWide() * this.getYardSizeFtLong()).toDecimal(0);
    } else {
      return Math.round(Math.PI * Math.pow((this.getYardDiameter() / 2), 2)).toDecimal(0);
    }
  }

  getCowAmountPerArea() {
    if (this.evaluation.getMeasurements() === 'metric') {
      return (this.getYardArea() / 4.6).toDecimal(0);
    } else {
      return (this.getYardArea() / 15).toDecimal(0);
    }

  }

  // Pipework

  getAreThereAnySharpProtrudingParts() {
    return this.assessor.data.are_there_any_sharp_protruding_parts;
  }

  getAreThereAnyProjectionsInHipboneOrShoulderRange() {
    return this.assessor.data
      .are_there_any_projections_in_hipbone_or_shoulder_range;
  }

  getIsThereProtectionAroundSolitaryPipesObjects() {
    return this.assessor.data.is_there_protection_around_solitary_pipes_objects;
  }

  getIsThereAClearFieldOfViewForCowsExiting() {
    return this.assessor.data.is_there_a_clear_field_of_view_for_cows_exiting;
  }

  // Yard and Shed Entrance

  getIsTheYardEntranceAtLeastAsWideAsTheRaceLeadingToTheYard() {
    return this.assessor.data.is_the_yard_entrance_at_least_as_wide_as_the_race_leading_to_the_yard;
  }

  getIsThereA4InchHighCurbInstalledInTheRaceToYardEntrance() {
    return this.assessor.data
      .is_there_a_4_inch_high_curb_installed_in_the_race_to_yard_entrance;
  }

  getIsARubberMatInstalledInTheYardEntrance() {
    return this.assessor.data.is_a_rubber_mat_installed_in_the_yard_entrance;
  }

  getIsTheYardBailEntranceStraightLined() {
    return this.assessor.data.is_the_yard_bail_entrance_straight_lined;
  }

  getIsTheYardBailEntranceCowSized3032Inches() {
    return this.assessor.data.is_the_yard_bail_entrance_cow_sized_30_32_inches;
  }

  getIsTheEntranceFunneledWithAnAngleLessThan45And34MetersLong() {
    return this.assessor.data
      .is_the_entrance_funneled_with_an_angle_less_than_45_and_3_4_meters_long;
  }

  getIsTheLast1013FeetOfSurroundingYardFenceOfSolidMaterial55InchesHigh() {
    return this.assessor.data
      .is_the_last_10_13_feet_of_surrounding_yard_fence_of_solid_material_55_inches_high;
  }

  getRotaryOnlyIsTheAntiJamGateInstalledOnTheEntrySide() {
    return this.assessor.data
      .rotary_only_is_the_anti_jam_gate_installed_on_the_entry_side;
  }

  getRotaryOnlyAreHingedDividersInstalled() {
    return this.assessor.data.rotary_only_are_hinged_dividers_installed;
  }

  // Milking Platform

  getDoesTheBreastrailMissTheShoulderJoints() {
    return this.assessor.data.does_the_breastrail_miss_the_shoulder_joints;
  }

  getDoesTheRumprailSitBetweenTheTopOfUdderAndBottomOfTheVulva() {
    return this.assessor.data
      .does_the_rumprail_sit_between_the_top_of_udder_and_bottom_of_the_vulva;
  }

  getRumprails() {
    return this.assessor.data.rumprails;
  }

  getDoTheCowsFitComfortablyIntoTheBail() {
    return this.assessor.data.do_the_cows_fit_comfortably_into_the_bail;
  }

  getDoCowsFitWellIntoTheFirstBail() {
    return this.assessor.data.do_cows_fit_well_into_the_first_bail;
  }

  getIsAnAntiJumpbarMounted() {
    return this.assessor.data.is_an_anti_jumpbar_mounted;
  }

  getIsTheAntiJumpbarInTheCorrectLocation() {
    return this.assessor.data.is_the_anti_jumpbar_in_the_correct_location;
  }

  getAreRumprailsSetInBy2550mm() {
    return this.assessor.data.are_rumprails_set_in_by_25_50_mm;
  }

  getAreTheInsideCornersOfTheRumprailsOverThePitEdge() {
    return this.assessor.data.are_the_inside_corners_of_the_rumprails_over_the_pit_edge;
  }

  getIsASteelBarOrConcreteCurbAlongThePitEdge() {
    return this.assessor.data.is_a_steel_bar_or_concrete_curb_along_the_pit_edge;
  }

  getIsTheSurfaceMadeOfNonSlipMaterial() {
    return this.assessor.data.is_the_surface_made_of_non_slip_material;
  }

  getIsThereAnExcessiveAmountOfDungInTheMilkingShed() {
    return this.assessor.data.is_there_an_excessive_amount_of_dung_in_the_milking_shed;
  }

  getIsTheSurfaceKeptCleanFreeOfStonesAndDebrisDuringMilking() {
    return this.assessor.data.is_the_surface_kept_clean_free_of_stones_and_debris_during_milking;
  }

  // Platform Exit/Exit Race

  getExitRaceIsTheSurfaceMadeOfNonSlipMaterial() {
    return this.assessor.data.exit_race_is_the_surface_made_of_non_slip_material;
  }

  getHerringboneDoCowsExitInAStraightLineFromBails() {
    return this.assessor.data.herringbone_do_cows_exit_in_a_straight_line_from_bails;
  }

  getHerringboneCanTheExitRaceContainAFullRowOfCows() {
    return this.assessor.data.herringbone_can_the_exit_race_contain_a_full_row_of_cows;
  }

  getRotaryIsARubberMatInstalledAtTheCowsTurningPoint() {
    return this.assessor.data
      .rotary_is_a_rubber_mat_installed_at_the_cows_turning_point;
  }

  getRotaryDoCowsTurnFreelyWithoutBendingTheirBackboneOrNeck() {
    return this.assessor.data
      .rotary_do_cows_turn_freely_without_bending_their_backbone_or_neck;
  }

  // Slopes/Steps

  getApproximateYardSlope() {
    return this.assessor.data.approximate_yard_slope;
  }

  getDrop() {
    return this.assessor.data.drop;
  }

  getLength() {
    return this.assessor.data.length;
  }

  getAreThereCowRamps() {
    return this.assessor.data.are_there_cow_ramps;
  }

  getAreThereSteps() {
    return this.assessor.data.are_there_steps;
  }

  // Concrete Composition, Surface

  getIsTheSurfaceRoughWithAPronouncedAggregate() {
    return this.assessor.data.is_the_surface_rough_with_a_pronounced_aggregate;
  }

  getIsTheSurfaceSlippery() {
    return this.assessor.data.is_the_surface_slippery;
  }

  getIsAlgaePresentOnAnySurface() {
    return this.assessor.data.is_algae_present_on_any_surface;
  }

  // Cleanliness

  getIsTheYardCleanWhenNotInUseEspeciallyFreeOfStones() {
    return this.assessor.data
      .is_the_yard_clean_when_not_in_use_especially_free_of_stones;
  }

  // Feedbins in Shed

  getAreCowsFedInTheShed() {
    return this.assessor.data.are_cows_fed_in_the_shed;
  }

  // Bulls/Dogs/Social

  getAreBullsKeptOutOfTheYardAtAllTimes() {
    return this.assessor.data.are_bulls_kept_out_of_the_yard_at_all_times;
  }

  getAreAnyBitingDogsPresent() {
    return this.assessor.data.are_any_biting_dogs_present;
  }

  getAreNonBitingDogsKeptAtANoFlightDistanceFromCows() {
    return this.assessor.data
      .are_non_biting_dogs_kept_at_a_no_flight_distance_from_cow;
  }

  getDoCowsHaveLowFlightDistance() {
    return this.assessor.data.do_cows_have_low_flight_distance;
  }

  getAreYoungCowsSeparatedFromMatureCows() {
    return this.assessor.data.are_young_cows_separated_from_mature_cows;
  }

  // Milker Behavior

  getDoesTheMilkerHitTheCows() {
    return this.assessor.data.does_the_milker_hit_the_cows;
  }

  getDoesTheMilkerMakeSuddenUnexpectedMovements() {
    return this.assessor.data.does_the_milker_make_sudden_unexpected_movements;
  }

  getAreThereAnySuddenNoises() {
    return this.assessor.data.are_there_any_sudden_noises;
  }

  getDoesTheMilkerLeaveTheShedToGetCows() {
    return this.assessor.data.does_the_milker_leave_the_shed_to_get_cows;
  }

  // Light

  getIsTheShedWellLitWithRoofPanelsWindowsAndOrAnArtificialLightSource() {
    return this.assessor.data
      .is_the_shed_well_lit_with_roof_panels_windows_and_or_an_artificial_light_source;
  }

  getIsTheExitPathPoorlyLitOrShadedByAWallShrubsOrOtherObjects() {
    return this.assessor.data
      .is_the_exit_path_poorly_lit_or_shaded_by_a_wall_shrubs_or_other_objects;
  }

  // Stray Voltage

  getIsTheFenceUnitSwitchedOffDuringMilking() {
    return this.assessor.data.is_the_fence_unit_switched_off_during_milking;
  }

  getTheShedHasBeenCheckedForStrayVoltageByACompetentOperatorAndRemediedWhereNecessary() {
    return this.assessor.data
      .the_shed_has_been_checked_for_stray_voltage_by_a_competent_operator_and_remedied_where_necessary;
  }

  getAreThereAnySignsOfCowsThatAreReluctantToEnterCertainAreas() {
    return this.assessor.data
      .are_there_any_signs_of_cows_that_are_reluctant_to_enter_certain_areas;
  }

  // Backing Gate/Top Gate

  getIsTheBackingGateElectrified() {
    return this.assessor.data.is_the_backing_gate_electrified;
  }

  getIsTheBackingGateUsedToFillUpSpaceBehindCowsNotPushingThemCowsNotLeaningBack() {
    return this.assessor.data
      .is_the_backing_gate_used_to_fill_up_space_behind_the_cows_it_is_not_pushing_on_them_and_the_cows_are_not_leaning_back;
  }

  getIsTheBackingGateSpeedExcessive() {
    return this.assessor.data.is_the_backing_gate_speed_excessive;
  }

  getIsTheBackingGateUsedInTheFirst15MinutesOfMilking() {
    return this.assessor.data
      .is_the_backing_gate_used_in_the_first_15_minutes_of_milking;
  }

  getAreTheCowsHeadsUp() {
    return this.assessor.data.are_the_cows_heads_up;
  }

  getIsTheTopGateUsedToBringUpSubgroupsOfCows() {
    return this.assessor.data
      .is_the_top_gate_used_to_bring_up_subgroups_of_cows;
  }

  getDoCowsEscapeThroughTopGate() {
    return this.assessor.data.do_cows_escape_through_top_gate;
  }

  getIsTheBuzzerBellWorkingInConjunctionWithBackingTopGate() {
    return this.assessor.data.is_the_buzzer_bell_working_in_conjunction_with_backing_top_gate;
  }

  getDoMilkersHaveAnExcellentViewOfTheYardFromThePlatformEnablingMilkersToBeAwareOfCowBehaviorAndPositionsOfTheBackingAndTopGates() {
    return this.assessor.data.do_milkers_have_an_excellent_view_of_the_yard_from_the_platform_enabling_milkers_to_be_aware_of_cow_behavior_and_positions_of_the_backing_and_top_gates;
  }
}

export default HoldingYardMilkShed;
