import { render, staticRenderFns } from "./mineral-detail-modal.vue?vue&type=template&id=635afc57&scoped=true&"
import script from "./mineral-detail-modal.vue?vue&type=script&lang=js&"
export * from "./mineral-detail-modal.vue?vue&type=script&lang=js&"
import style0 from "./mineral-detail-modal.vue?vue&type=style&index=0&id=635afc57&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "635afc57",
  null
  
)

export default component.exports