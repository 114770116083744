/**
 * The Heat Abatement Assessor is designed for use on freestall and dirt lot dairies and supplements
 * the Freestall Ventilation Assessor to help evaluate five key factors related to heat abatement.
 */

const convert = require('convert-units');
import Assessor from './Assessor';

class HeatAbatement extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;

        this.minAccessibleWaterTroughPerimeterInPen = 9;
        this.idealTemperatureThatFansActivate = 21;
    }

    getIsDrinkingWaterReadilyAvailableToCowsExitingTheMilkingParlor() {
        return this.assessor.data.is_drinking_water_readily_available_to_cows_exiting_the_milking_parlor;
    }

    getIsTheAreaAroundTheWaterTroughFreeOfMudStonesEtc() {
        return this.assessor.data.is_the_area_around_the_water_trough_free_of_mud_stones_etc;
    }

    getAccessibleWaterTroughPerimeterInPen(roundPoint) {
        if (this.assessor.data.accessible_water_trough_perimeter_in_pen === undefined || this.assessor.data.accessible_water_trough_perimeter_in_pen === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.accessible_water_trough_perimeter_in_pen.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.accessible_water_trough_perimeter_in_pen)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(roundPoint);
    }

    setAccessibleWaterTroughPerimeterInPen(newValue) {
        if (newValue === '') {
            return this.assessor.data.accessible_water_trough_perimeter_in_pen = null;
        }

        this.assessor.data.accessible_water_trough_perimeter_in_pen = parseFloat(convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getMinAccessibleWaterTroughPerimeterInPen() {
        let roundPoint = 1;

        if (this.evaluation.getMeasurements() === 'metric') {
            roundPoint = 0;
        }
        return convert(this.minAccessibleWaterTroughPerimeterInPen)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(roundPoint);
    }

    getAreFansLocatedInTheFeedBunkArea() {
        return this.assessor.data.are_fans_located_in_the_feed_bunk_area;
    }

    getFeedBunkAreaAreHvlsFansUsed() {
        return this.assessor.data.feed_bunk_area_are_hvls_fans_used;
    }

    getFeedBunkAreaTemperatureFansActivate(roundPoint) {
        if (this.assessor.data.feed_bunk_area_temperature_fans_activate === undefined || this.assessor.data.feed_bunk_area_temperature_fans_activate === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.feed_bunk_area_temperature_fans_activate.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.feed_bunk_area_temperature_fans_activate)
            .from('C')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
            .toDecimal(roundPoint);
    }

    setFeedBunkAreaTemperatureFansActivate(newValue) {
        if (newValue === '') {
            return this.assessor.data.feed_bunk_area_temperature_fans_activate = null;
        }

        this.assessor.data.feed_bunk_area_temperature_fans_activate = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
                .to('C'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getFeedBunkAreaFanDiameter() {
        if (this.assessor.data.feed_bunk_area_fan_diameter === undefined || this.assessor.data.feed_bunk_area_fan_diameter === null) {
            return null;
        }

        return convert(this.assessor.data.feed_bunk_area_fan_diameter)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(0);
    }
    setFeedBunkAreaFanDiameter(newValue) {
        if (newValue < 1) {
           this.assessor.data.feed_bunk_area_fan_diameter =  null;
        }

       return this.assessor.data.feed_bunk_area_fan_diameter = parseFloat(convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getFeedBunkAreaSpacing(roundPoint) {
        if (this.assessor.data.feed_bunk_area_spacing === undefined || this.assessor.data.feed_bunk_area_spacing === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.feed_bunk_area_spacing.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.feed_bunk_area_spacing)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(roundPoint);
    }

    setFeedBunkAreaSpacing(newValue) {
        if (newValue === '') {
            return this.assessor.data.feed_bunk_area_spacing = null;
        }

        this.assessor.data.feed_bunk_area_spacing = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
                .to('m'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getAreFansLocatedInTheRestingAreaPaddock() {
        return this.assessor.data.are_fans_located_in_the_resting_area_paddock;
    }

    getRestingAreaPaddockAreHvlsFansUsed() {
        return this.assessor.data.resting_area_paddock_are_hvls_fans_used;
    }

    getRestingAreaPaddockTemperatureFansActivate(roundPoint) {
        if (this.assessor.data.resting_area_paddock_temperature_fans_activate === undefined || this.assessor.data.resting_area_paddock_temperature_fans_activate === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.resting_area_paddock_temperature_fans_activate.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.resting_area_paddock_temperature_fans_activate)
            .from('C')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
            .toDecimal(roundPoint);
    }

    setRestingAreaPaddockTemperatureFansActivate(newValue) {
        if (newValue === '') {
            return this.assessor.data.resting_area_paddock_temperature_fans_activate = null;
        }

        this.assessor.data.resting_area_paddock_temperature_fans_activate = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
                .to('C'))
                .toDecimal(newValue.countDecimals() + 3);
    }

    setRestingAreaPaddockFanDiameter(newValue) {
        if (newValue === '') {
            return this.assessor.data.resting_area_paddock_fan_diameter = null;
        }

        this.assessor.data.resting_area_paddock_fan_diameter = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
                .to('cm'))
                .toDecimal(newValue.countDecimals() + 3);
    }

    getRestingAreaPaddockFanDiameter() {
        if (this.assessor.data.resting_area_paddock_fan_diameter === undefined || this.assessor.data.resting_area_paddock_fan_diameter === null) {
            return null;
        }

        return convert(this.assessor.data.resting_area_paddock_fan_diameter)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(0);
    }

    getRestingAreaPaddockSpacing(roundPoint) {
        if (this.assessor.data.resting_area_paddock_spacing === undefined || this.assessor.data.resting_area_paddock_spacing === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.resting_area_paddock_spacing.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.resting_area_paddock_spacing)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(roundPoint);
    }

    setRestingAreaPaddockSpacing(newValue) {
        if (newValue === '') {
            return this.assessor.data.resting_area_paddock_spacing = null;
        }

        this.assessor.data.resting_area_paddock_spacing = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
                .to('m'))
                .toDecimal(newValue.countDecimals() + 3);
    }

    getAreSoakersMistersInTheHoldingArea() {
        return this.assessor.data.are_soakers_misters_located_in_the_feed_bunk_area;
    }

    getTemperatureSoakersMistersActivate(roundPoint) {
        if (this.assessor.data.temperature_soakers_misters_activate === undefined || this.assessor.data.temperature_soakers_misters_activate === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.temperature_soakers_misters_activate.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.temperature_soakers_misters_activate)
            .from('C')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
            .toDecimal(roundPoint);
    }

    setTemperatureSoakersMistersActivate(newValue) {
        if (newValue === '') {
            return this.assessor.data.temperature_soakers_misters_activate = null;
        }

        this.assessor.data.temperature_soakers_misters_activate = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
                .to('C'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getAreThereSoakersMistersLocatedInTheRestingAreaPaddock() {
        return this.assessor.data.are_there_soakers_misters_located_in_the_resting_area_paddock;
    }

    getDoesTheFrequencyOfSoakingVaryDependingUponTemperature() {
        return this.assessor.data.does_the_frequency_of_soaking_vary_depending_upon_temperature;
    }

    getDoesWaterDropletEffectivelySoakCows() {
        return this.assessor.data.does_water_droplet_effectively_soak_cows;
    }

    getFrequency() {
        return this.assessor.data.frequency;
    }

    getDuration() {
        return this.assessor.data.duration;
    }

    getIsThereShadeOverTheFeedBunkArea() {
        return this.assessor.data.is_there_shade_over_the_feed_bunk_area;
    }

    getIsThereShadeOverTheRestingAreaPaddock() {
        return this.assessor.data.is_there_shade_over_the_resting_area_paddock;
    }

    getAreAirCoolingUnitsSuchAsShadeTrackerOrKoralKoolUsedBelowShade() {
        return this.assessor.data.are_air_cooling_units_such_as_shade_tracker_or_koral_kool_used_below_shade;
    }

    /**
     * Calculation and Ideals
     */
    waterTroughPerimeterCalc() {
        if (this.group.getGroupSize() && this.group.getGroupSize() > 0) {
            return (this.getAccessibleWaterTroughPerimeterInPen() / this.group.getGroupSize()).toDecimal(1);
        }

        return 0;
    }

    idealFeedBunkFanSpacing() {
        if (this.evaluation.getMeasurements() === 'metric') {
            return Math.round(((this.getFeedBunkAreaFanDiameter() / 0.254) / 12) * 0.3048);
        } else {
            return Math.round(this.getFeedBunkAreaFanDiameter() / 1.2);
        }
    }

    idealRestingAreaFanSpacing() {
        if (this.evaluation.getMeasurements() === 'metric') {
            return Math.round(((this.getRestingAreaPaddockFanDiameter() / 0.254) / 12) * 0.3048);
        } else {
            return Math.round(this.getRestingAreaPaddockFanDiameter() / 1.2);
        }
    }

    frequencyVariedByTempRecommendation() {
        if (this.evaluation.getMeasurements() === 'metric') {
            return this.app.$t("It is recommended that as temperature increases, frequency of soaking is increased. For instance if soaking frequency is every 15 minutes at 21 to 26°C, then at 27 to 32°C, it should be every 10 minutes and above 32°C, it should be every 5 minutes. The soaking on time per cycle will vary with the nozzle type and size, but is generally 1-2 minutes.");
        } else {
            return this.app.$t("It is recommended that as temperature increases, frequency of soaking is increased. For instance if soaking frequency is every 15 minutes at 70 to 80°F, then at 81 to 90°F, it should be every 10 minutes and above 90°F, it should be every 5 minutes. The soaking on time per cycle will vary with the nozzle type and size, but is generally 1-2 minutes.");
        }
    }

    temperatureThatFansActivateRecommendation() {
        return convert(this.idealTemperatureThatFansActivate)
            .from('C')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'C'))
            .toDecimal(0);
    }

};

export default HeatAbatement;