import {
  holdingYardMilkShed
} from './holdingYardMilkShed';
import {
  medias
} from '../Global/medias.js';

class HoldingYardMilkShedAssessorSlides {

  constructor(app, report, evaluation, group, user) {
    this.app = app;
    this.report = report;
    this.evaluation = evaluation;
    this.group = group;
    this.user = user;
  }

  add (tableOfContentsCalculatorPosition) {

    let assessor = this.group.getAssessorByType("20");

    if (assessor === false || assessor.assessor.data === undefined) {
      throw this.group.getName() + this.app.$t(" - ") + this.app.$t("Holding Yard & Milking Shed assessor is not ready.");
    }

    this.report.tableOfContentsHelper("Holding Yard & Milking Shed", tableOfContentsCalculatorPosition, this.group);

    holdingYardMilkShed(this.app, this.report, this.evaluation, this.group, assessor);
    medias(this.app, this.report, this.group, "20", this.user);
  }

}

export default HoldingYardMilkShedAssessorSlides;