class ErrorSlides {

    constructor(app, report) {
    	this.app = app;
    	this.report = report;
	}

	display() {
		if(this.report.slideErrors.length === 0) return false;

    	let slide = this.report.pptx.addSlide('Slide master');

		slide.addText(
	        this.app.$t('Assessors not in report'), {
	            x: .5,
	            y: 1.4,
	            ...this.report.styleOptions.subheaderBlue
	        }
	    );

	    let slideHeight = 2.25;

		for ( var i = 0; i < this.report.slideErrors.length; i++ ) {

			if(typeof this.report.slideErrors[i] !== "string") continue;

			slide.addText(
		        this.app.$t(this.report.slideErrors[i]), {
		            x: .5,
		            y: slideHeight,
		            ...this.report.styleOptions.subheaderBlue
		        }
		    );

		    slideHeight += .45;

		}

	}

}

export default ErrorSlides;
