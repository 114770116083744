<template>
  <div>
    <div
      :class="[
        'app-footer',
        {
          'pt-3 pt-lg-0':
            !isLoggedIn() || (isLoggedIn() && $store.getters.isPWA()),
        },
      ]"
    >
      <div class="container">
        <div class="row d-flex align-items-center">
          <div
            class="col-lg-4 app-footer__right d-flex justify-content-center justify-content-lg-end order-lg-3 my-4 my-lg-0"
          >
            <template v-if="isLoggedIn() && !$store.getters.isPWA()">
              <a
                v-if="platform === 'iOS'"
                data-target="#installiOS"
                data-toggle="modal"
                href="#"
                class="app-footer__right__link"
              >
                <img
                  class="app-footer__right__icon"
                  src="@/assets/icons/iOS-Icon.svg"
                  alt="iOS Icon"
                  width="50"
                  height="50"
                />
                <span class="ml-2">{{ "Download iOS App" | translate }}</span>
              </a>
              <span
                @click="downloadApp"
                v-if="platform === 'android'"
                data-target="#installAndroid"
                data-toggle="modal"
                href="#"
                class="app-footer__right__link"
              >
                <img
                  class="app-footer__right__icon"
                  src="@/assets/icons/Androidicon.svg"
                  alt="Android Icon"
                  width="50"
                  height="50"
                />
                <span class="ml-2">{{
                  "Download for Android" | translate
                }}</span>
              </span>
              <div
                class="modal fade"
                id="installiOS"
                tabindex="-1"
                role="dialog"
                aria-labelledby="installSafari"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content edit-modal">
                    <h2 class="h2 h2--modal text-center mt-3 mt-md-0">
                      {{ "Download for iOS" | translate }}
                    </h2>
                    <div class="px-3 pb-3">
                      <div v-if="browser === 'Safari'">
                        <p>
                          {{
                            "Install this webapp on your iOS device: Tap "
                              | translate
                          }}<img
                            class="navgation-icon"
                            src="@/assets/icons/Navigation_Action_2x.png"
                            alt="iOS share icon"
                          />{{ " and then add to homescreen." | translate }}
                        </p>
                        <p>
                          {{
                            "Note: If app is not downloading, try again. Some iOS devices restrict the initial download. Please delete secondary apps if multiple are downloaded."
                              | translate
                          }}
                        </p>
                      </div>

                      <p v-else>
                        {{
                          "Please use the Safari browser to download the iOS application."
                            | translate
                        }}
                      </p>
                      <div class="row mt-2">
                        <div class="col-md-24 modal-bottom">
                          <a
                            href="#"
                            class="btn-cancel-modal"
                            data-dismiss="modal"
                            >{{ "Cancel" | translate }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="modal fade"
                id="installAndroid"
                tabindex="-1"
                role="dialog"
                aria-labelledby="installAndroid"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content edit-modal">
                    <h2 class="h2 h2--modal text-center mt-3 mt-md-0">
                      {{ "Download for Android" | translate }}
                    </h2>
                    <div class="px-3 pb-3">
                      <p v-if="browser === 'Chrome'">
                        {{
                          "Install this webapp on your Android: Tap" | translate
                        }}
                        <img
                          class="navgation-icon"
                          src="@/assets/icons/dots-horizontal-triple.svg"
                          alt="Android Options Icon"
                        />
                        {{ 'and then "Add to Home screen".' | translate }}
                      </p>
                      <p v-else-if="browser === 'Firefox'">
                        {{
                          "Install this webapp on your Android: Tap" | translate
                        }}
                        <img
                          class="navgation-icon"
                          src="@/assets/icons/dots-horizontal-triple.svg"
                          alt="Android Options Icon"
                        />
                        {{
                          'and then select "Page" followed by "Add to Home Screen".'
                            | translate
                        }}
                      </p>
                      <p v-else>
                        {{
                          "Please use the Chrome or Firefox browsers to download the Android application."
                            | translate
                        }}
                      </p>
                      <div class="row mt-2">
                        <div class="col-md-24 modal-bottom">
                          <a
                            href="#"
                            class="btn-cancel-modal"
                            data-dismiss="modal"
                            >{{ "Cancel" | translate }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div
              class="modal fade"
              id="dataCollection"
              tabindex="-1"
              role="dialog"
              aria-labelledby="dataCollection"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content edit-modal">
                  <h2 class="h2 h2--modal text-center mt-3 mt-md-0 pb-2">
                    {{ "Data Collection Forms" | translate }}
                  </h2>
                  <div class="px-3 pb-2">
                    <a
                      v-for="item in $store.state.dataCollectionList.filter(
                        (item) => item.metadata !== null
                      )"
                      class="btn btn-full btn-full--modal mb-2"
                      target="_blank"
                      :href="fileURL(item)"
                      >{{ item.title | translate }}</a
                    >
                  </div>
                  <a
                    href="#"
                    class="btn-cancel-modal d-flex justify-content-center mb-3"
                    data-dismiss="modal"
                    >{{ "Cancel" | translate }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-20">
            <ul class="app-footer__list d-flex flex-column flex-lg-row">
              <li
                class="order-2 order-lg-1 app-footer__list__item mt-4 mt-lg-1"
              >
                <p class="app-footer__link m-0">
                  &copy; {{ getYear }} Zinpro Corporation.
                  {{ "All Rights Reserved." | translate }}
                </p>
              </li>
              <div
                class="order-1 order-lg-2 d-flex flex-column d-sm-block mt-0"
              >
                <li class="app-footer__list__item">
                  <router-link
                    :to="'/terms-of-use'"
                    class="app-footer__link app-footer__link--underline focus"
                    >{{ "Terms of Use" | translate }}</router-link
                  >
                </li>
                <li class="app-footer__list__item">
                  <a
                    :href="$t('https://www.zinpro.com/privacy-policy')"
                    class="app-footer__link app-footer__link--underline focus"
                    target="blank"
                    >{{ "Privacy Policy" | translate }}</a
                  >
                </li>
                <li v-if="!isLoggedIn()" class="app-footer__list__item">
                  <a
                    class="app-footer__link app-footer__link--underline focus"
                    target="blank"
                    href="https://www.zinpro.com/contact-sales/?_expert_type=representative"
                    >{{ "Find a Representative" | translate }}</a
                  >
                </li>
                <li v-if="isLoggedIn()" class="app-footer__list__item">
                  <a
                    href="https://library.zinprofirststep.com/sources"
                    class="app-footer__link app-footer__link--underline focus"
                    target="blank"
                    @click="$goToLameness($t('/sources'), $event)"
                    >{{ "Sources" | translate }}</a
                  >
                </li>
                <li v-if="isLoggedIn()" class="app-footer__list__item">
                  <a
                    class="app-footer__link app-footer__link--underline focus"
                    :href="$t('https://library.zinprofirststep.com/sources')"
                    data-target="#dataCollection"
                    data-toggle="modal"
                    href="#"
                    >{{ "Data Collection Forms" | translate }}</a
                  >
                </li>
                <li
                  v-if="isLoggedIn() && userGuideFile && userGuideFile.file"
                  class="app-footer__list__item"
                >
                  <a
                    class="app-footer__link app-footer__link--underline cursor-pointer focus"
                    target="_blank"
                    :href="userGuideFile.file"
                    >{{ "User Guide" | translate }}</a
                  >
                </li>
              </div>
            </ul>
            <v-offline @detected-condition="handleConnectivityChange">
              <div slot="offline">
                <p>{{ "You appear to be offline" | translate }}</p>
              </div>
            </v-offline>
          </div>
          <div
            class="col-md-4 d-flex align-items-center justify-content-center"
            v-if="!['/', '/login'].includes($route.path)"
          >
          <router-link to="/" exact>
              <img
                class="app-footer__logo__img"
                src="@/assets/img/FirstStep-RGB-Line.png"
                :alt="'ZinPro FirstStep Home' | translate"
              />
          </router-link>
          </div>
        </div>
      </div>
      <!-- end container -->
    </div>

    <div
      v-if="cookieMessage"
      class="cookie-warning d-flex justify-content-center align-items-center"
    >
      <div class="inner">
        {{
          "This website uses cookies to provide necessary site functionality and to improve your online experience. By using this site, you agree to the "
            | translate
        }}<a target="_blank" :href="$t('https://www.zinpro.com/privacy-policy/')">{{
          "Privacy Policy" | translate
        }}</a
        >{{ " and " | translate
        }}<router-link
                    :to="'/terms-of-use'"
                    class="app-footer__link app-footer__link--underline focus"
                    >{{ "Terms of Use" | translate }}</router-link
                  >
        <div @click="setCookie()" class="close">
          <i class="fa fa-times"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileDetect from "mobile-detect";
import { VOffline } from 'v-offline';
import Cookie from "js-cookie";
import { mapGetters, mapState } from "vuex";
import { get } from "lodash";
import formUploadMixin from "@/mixin/formUpload.js";

export default {
  components: {
    VOffline,
  },
  mixins: [formUploadMixin],
  data() {
    return {
      platform: null,
      browser: null,
      cookieMessage: false,
      deferredPrompt: null,
    };
  },
  methods: {
    isIosDevice() {
      return (
        !!navigator.platform &&
        /iPad|iPhone|MacIntel|iPod/.test(navigator.platform) &&
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      );
    },

    backgrounSyncAPIForIOS() {
      // if (this.isIosDevice()) {
      console.log("background syncing for ios devices", this.isIosDevice());
      if (window.MessageChannel) {
        // var messageChannel = new MessageChannel();
        // messageChannel.port1.onmessage = event => {
        //   // this.onMessageSuccess(event);
        // };
      } else {
        navigator.serviceWorker.onmessage = (event) => {
          // this.onMessageSuccess(event);
        };
      }
      navigator.serviceWorker.ready.then((reg) => {
        try {
          console.log("serviceworker get ready", reg);
          reg.active.postMessage(
            {
              text: "sync",
              port: messageChannel && messageChannel.port1,
            },
            [messageChannel && messageChannel.port1]
          );
        } catch (e) {
          //firefox support
          reg.active.postMessage({
            text: "sync",
          });
        }
      });
      // }
    },
    isLoggedIn() {
      const tokenNotExpired =
        new Date(localStorage.getItem("expireAt")) > new Date();
      const isLoggedIn = localStorage.getItem("token");
      return !!(tokenNotExpired && isLoggedIn);
    },
    fileURL(file) {
      return get(file, "metadata.customMetadata.fileURL", "");
    },
    setCookie() {
      Cookie.set("warning", "value", { expires: 30 });
      this.cookieMessage = false;
    },
    getPlatform() {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        windows = ["Win32", "Win64", "Windows", "WinCE"],
        macOS = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        iOS = ["iPhone", "iPad", "iPod"];

      if (windows.indexOf(platform) !== -1) this.platform = "windows";
      else if (macOS.indexOf(platform) !== -1) this.platform = "macOS";
      else if (iOS.indexOf(platform) !== -1) this.platform = "iOS";
      else if (/Android/.test(userAgent)) this.platform = "android";
      else this.platform = null;
    },
    getBrowser() {
      var mobileDetect = new MobileDetect(window.navigator.userAgent);

      this.browser = mobileDetect.userAgent();
    },
    downloadApp() {
      // Show the prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        this.deferredPrompt = null;
      });
    },
    handleConnectivityChange(status) {
      this.$store.state.offline = !navigator.onLine;

      console.log("status", this.$store.state.offline);
      if (navigator.onLine) {
        console.log("navigator gets online");
        this.backgrounSyncAPIForIOS();
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.users.user,
      guideList: (state) => state.guideList,
    }),
    ...mapGetters(["isOnline"]),

    getYear() {
      return new Date().getFullYear();
    },
    // isLoggedIn() {
    //   if (
    //     this.$route.path.search(/login/) === -1 &&
    //     this.$route.path.search(/password-reset/) === -1 &&
    //     this.$route.path.search(/verify-account/) === -1 &&
    //     this.$route.path.search(/sign-up/) === -1
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // isLoggedIn() {
    //   const tokenNotExpired =
    //     new Date(localStorage.getItem('expireAt')) > new Date();
    //   const isLoggedIn = localStorage.getItem('token');
    //   return !!(tokenNotExpired && isLoggedIn);
    // },
    languageName() {
      return {
        en: "english",
        es: "spanish",
        fr: "french",
        tr: "turkish",
        de: "german",
        pt: "portuguese",
        ch: "chinese",
        jp: "japanese",
        pl: "polish",
        it: "italian",
        nl: "dutch",
        ru: "russian",
      };
    },
    userGuideFile() {
      let userRoleName = "";
      const userLanguageName =
        this.languageName[this.user.lang] || this.languageName.en;
      const userRole = this.user.role;
      switch (userRole) {
        case "zinpro_representative":
        case "admin":
          // return sales rep guide
          userRoleName = "sales-rep";
          break;
        case "external":
          // return adv customer guide
          userRoleName = "advanced-customer";
          break;
        case "data_entry":
          // return customer guide
          userRoleName = "customer";
          break;
      }

      const defId = userRoleName + "-" + this.languageName["en"];
      const fileId = userRoleName + "-" + userLanguageName;

      const userGuideFile =
        this.$store.state.guideList.find(
          (item) =>
            item.id === fileId &&
            item.metadata &&
            item.metadata.customMetadata.fileURL
        ) || this.guideList.find((item) => item.id === defId);

      if (!userGuideFile) {
        return;
      } else {
        const file = get(
          userGuideFile,
          "metadata.customMetadata.fileURL",
          null
        );
        return {
          title: userGuideFile.title,
          file,
          fileId,
        };
      }
    },
  },
  created() {
    this.getPlatform();
    this.getBrowser();

    if (!Cookie.get("warning")) {
      this.cookieMessage = true;
    }

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      console.log("e", e);
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      console.log("deffered", this.deferredPrompt);
    });
  },
};
</script>
