<template>
  <div>
    <div class="container main-container main-container--hygiene view">
      <div
        v-if="scoresFilledOut === false"
        class="row mx-0 mb-4 score-note score-note--border-orange"
      >
        <div class="col-md-24">
          <strong>{{
            'It is recommended to complete the hygiene assessor before analyzing your new footbath.'
              | translate
          }}</strong>
        </div>
      </div>
      <div v-else class="row mx-0 mb-4 score-note score-note--border-blue">
        <div class="col-md-24">
          <strong>{{
            $t('Hygiene Assessor percent of cows scoring 3 or 4 is ') + percent3_4 + '%.'
          }}</strong>
          {{
            $t('Based upon hygiene scores, your suggested footbath frequency is: ') +
              suggestedFrequency +
              $t(' days per week.')
          }}
        </div>
      </div>

      <div class="row">
        <div class="col-md-18">
          <h2 class="h2 d-inline">
            {{ 'Tell Us About Your New Footbath' | translate }}
          </h2>
          <modal-info :value="'design-new'">
            <template slot="modal-content">
              <div class="p-3">
                <h2 class="h2 pb-2 text-center">
                  {{ 'Design a New Footbath' | translate }}
                </h2>
                <p>
                  {{
                    'Footbaths are a proven method of fighting lesions that can lead to lameness. Depending upon environmental factors and management practices, the amount of footbaths that are necessary can vary.'
                      | translate
                  }}
                </p>
                <p>
                  {{
                    'Make adjustments based on FirstStep® recommendations that arrive at a footbath program that works for your budget and your herd.'
                      | translate
                  }}
                </p>
                <p>
                  {{
                    'For more information about footbaths, click the link to learn more from'
                      | translate
                  }}
                  <a
                    :href="$t('https://library.zinprofirststep.com/footbath/')"
                    @click="$goToLameness($t('/footbath/'), $event)"
                    target="_blank"
                  >
                    {{ 'FirstStep Resources' | translate }}</a
                  >.
                </p>
                <div class="row modal-bottom mt-3">
                  <a
                    href="#"
                    class="btn-cancel-modal d-flex justify-content-center"
                    data-dismiss="modal"
                    >{{ 'Cancel' | translate }}</a
                  >
                </div>
              </div>
            </template>
          </modal-info>
        </div>
        <div
          class="col-md-6 d-flex align-items-center"
          v-if="$route.params.footbathId !== undefined"
        >
          <select
            @change="changeFootbath()"
            class="form-control"
            v-model="selectedFootbath"
          >
            <option
              v-for="(footbath, index) in assessor.footbaths"
              :key="new_footbath.id + index"
              :value="new_footbath.id"
            >
              {{ footbath.name }}
            </option>
          </select>
        </div>
      </div>
      <hr class="mt-2 mb-4 mt-md-3" />
      <div class="row">
        <div class="col-lg-16">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-1">{{ 'Footbath Location/Name' | translate }}</label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  v-model="new_footbath.name"
                  data-vv-as="Footbath Location/Name"
                  v-validate="'required'"
                />
                <span v-show="errors.has('name')" class="text-danger">
                  <small>{{
                    'The Footbath Location/Name field is required.' | translate
                  }}</small>
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-1">{{ 'Evaluation Date' | translate }}</label>
                <input
                  type="text"
                  class="form-control date-picker date-picker--disabled"
                  name="evaluation_date"
                  :value="getDate(evaluation.data.consultation_date)"
                  data-vv-as="Evaluation Date"
                  v-validate="'required'"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-1">{{ 'Group/Pen Size' | translate }}</label>
                <input
                  type="number"
                  inputmode="numeric"
                  @keypress="$isNumber"
                  v-model="group.data.size"
                  class="form-control"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-1">{{ 'Cow Passes / Bath' | translate }}</label>
                <modal-info :value="'cow-passes'">
                  <template slot="modal-content">
                    <h2 class="h2 pb-2 pt-3 px-3 text-center">
                      {{ 'Cow Passes / Bath' | translate }}
                    </h2>
                    <p class="px-3">
                      {{
                        'There is little data to suggest how long a product remains effective. Each rear leg should have at least two dunks per pass. It is suggested to change the bath after every 200 cow passes (on average).'
                          | translate
                      }}
                    </p>
                    <div class="row modal-bottom pb-3">
                      <a
                        href="#"
                        class="btn-cancel-modal d-flex justify-content-center"
                        data-dismiss="modal"
                        >{{ 'Cancel' | translate }}</a
                      >
                    </div>
                  </template>
                </modal-info>
                <input
                  type="number"
                  inputmode="numeric"
                  class="form-control"
                  name="cow_passes_bath"
                  v-model.number="new_footbath.data.cow_passes_bath"
                  data-vv-as="Cow Passes/Bath"
                  v-validate="'required'"
                />
                <span v-show="errors.has('cow_passes_bath')" class="text-danger">
                  <small>{{
                    'The Cow Passes/Bath field is required.' | translate
                  }}</small>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-1">{{
                  $t('Labor Cost (') + evaluation.data.currency + $t('/Footbath)')
                }}</label>
                <input
                  type="number"
                  inputmode="decimal"
                  class="form-control"
                  name="labor_cost"
                  v-model.number="new_footbath.data.labor_cost"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="mb-1">{{ 'Recommended pH' | translate }}</label>
                <modal-info :value="'pH'">
                  <template slot="modal-content">
                    <h2 class="h2 pb-2 pt-3 px-3 text-center">
                      {{ 'Recommended pH' | translate }}
                    </h2>
                    <p class="px-3">
                      {{
                        'Target concentration of copper sulfate should be 2-3%. Target pH for acidification is pH 4.'
                          | translate
                      }}
                    </p>
                    <div class="row modal-bottom pb-3">
                      <a
                        href="#"
                        class="btn-cancel-modal d-flex justify-content-center"
                        data-dismiss="modal"
                        >Cancel</a
                      >
                    </div>
                  </template>
                </modal-info>
                <input
                  type="number"
                  inputmode="decimal"
                  class="form-control"
                  name="recommended_ph"
                  v-model.number="new_footbath.data.ph"
                />
              </div>
            </div>
          </div>
          <div class="row d-none d-lg-block">
            <div class="col-md-24 mb-3">
              <!-- show desktop -->
              <div class="row">
                <div class="col-md-24">
                  <label class="mb-1 mr-1">{{ 'Personal Notes' | translate }}</label>
                  <modal-info :value="'personal-notes'">
                    <template slot="modal-content">
                      <h2 class="h2 pt-3 pb-2 px-3 text-center">
                        {{
                          'Personal Notes: Not included when outputting reports.'
                            | translate
                        }}
                      </h2>
                      <div class="row modal-bottom pb-3">
                        <a
                          href="#"
                          class="btn-cancel-modal d-flex justify-content-center"
                          data-dismiss="modal"
                          >{{ 'Cancel' | translate }}</a
                        >
                      </div>
                    </template>
                  </modal-info>
                </div>
                <div class="col-md-24">
                  <textarea
                    :value="new_footbath.personal_notes"
                    v-on:input="$emit('input', $event.target.value)"
                    rows="7"
                    class="form-control d-none d-lg-block"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row mx-0 d-none d-lg-flex justify-content-center justify-content-md-start align-items-center pb-2 pb-md-3"
          >
            <photoUpload :assessorId="1" :assessor="assessor"></photoUpload>
          </div>
        </div>

        <div class="col-lg-8 measurements">
          <volume :footbath="footbathClass" v-model="new_footbath.volume"></volume>
          <div class="mt-3">
            <button
              @click="saveNewFootbath"
              class="btn btn-primary btn-full--sm pull-right d-none d-lg-block mt-3"
            >
              {{ 'Save Footbath' | translate }}
            </button>
          </div>
        </div>

        <!-- show mobile -->
        <div class="row w-100 mx-0 d-lg-none mt-3">
          <div class="col-md-24">
            <label class="mb-1 mr-1">{{ 'Personal Notes' | translate }}</label>
            <modal-info :value="'personal-notes-sm'">
              <template slot="modal-content">
                <h3 class="h3 px-3 py-3">
                  {{
                    'Personal Notes: Not included when outputting reports.' | translate
                  }}
                </h3>
                <div class="row modal-bottom">
                  <a
                    href="#"
                    class="btn-cancel-modal d-flex justify-content-center"
                    data-dismiss="modal"
                    >{{ 'Cancel' | translate }}</a
                  >
                </div>
              </template>
            </modal-info>
            <textarea
              v-bind:value="new_footbath.personal_notes"
              v-on:input="$emit('input', $event.target.value)"
              rows="4"
              class="form-control"
            ></textarea>
          </div>
          <div class="col-md-24 pb-2">
            <photoUpload :assessorId="1" :target="1" :assessor="assessor"></photoUpload>
            <button
              @click="saveNewFootbath"
              class="btn btn-primary btn-full--sm pull-right d-lg-block mt-3"
            >
              {{ 'Save Footbath' | translate }}
            </button>
          </div>
        </div>
      </div>

      <hr
        :class="[
          'mb-4',
          'mt-4',
          { 'mb-md-5': successMessage === false && errorMessage === false },
          { 'mb-md-4': successMessage === true || errorMessage === true },
        ]"
      />

      <div v-show="errorMessage" class="alert alert-danger mb-4 mt-0" role="alert">
        {{ 'Please fill out the required fields!' | translate }}
      </div>

      <div v-if="successMessage" class="alert alert-primary mb-4 mt-0" role="alert">
        {{ 'Footbath has been saved!' | translate }}
      </div>

      <modal-confirm
        v-if="showModal"
        @close="showModal = false"
        :link="url"
      ></modal-confirm>

      <div class="row misc-bottom">
        <div class="col-md-5">
          <button
            @click="
              checkIfDataHasChanged(
                '/users/' +
                  $route.params.userId +
                  '/evaluations/' +
                  $route.params.evaluationId +
                  '/groups/' +
                  group.id +
                  '/assessors/' +
                  assessor.type
              )
            "
            class="btn btn-secondary btn-full--sm mb-2 mb-md-0"
          >
            {{ 'Back' | translate }}
          </button>
        </div>
        <div class="order-1 order-md-2 col-md-19 flex-sm-row-reverse">
          <button
            @click="goToMixes()"
            :class="[
              'btn btn-primary pull-right ml-4 ml-lg-5 d-lg-block',
              { disabled: assessor.new_footbath === undefined },
            ]"
          >
            {{ 'Next' | translate }}
          </button>
          <button
            data-target="#areYouSure"
            data-toggle="modal"
            class="btn btn-secondary pull-right mt-2 mt-md-0"
          >
            {{ 'Default Values' | translate }}
          </button>
          <div
            class="modal fade"
            id="areYouSure"
            tabindex="-1"
            role="dialog"
            aria-labelledby="areYouSure"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content edit-modal">
                <h2 class="h2 h2--modal text-center mt-3 mt-md-0">
                  {{ 'Return to Default Values' | translate }}
                </h2>
                <div class="px-3 pb-3">
                  <p>
                    {{
                      'Are you sure that you would like to return values to default? Once completed, the data cannot be restored.'
                        | translate
                    }}
                  </p>
                  <p>
                    <a
                      @click="resetDefaults"
                      href="#"
                      data-dismiss="modal"
                      class="btn btn-full btn-full--modal mb-2 mb-md-0"
                      >{{ 'Return to Defaults' | translate }}</a
                    >
                  </p>
                  <div class="row mt-2">
                    <div class="col-md-24 modal-bottom">
                      <a href="#" class="btn-cancel-modal" data-dismiss="modal">{{
                        'Cancel' | translate
                      }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 *  @NICE Put Footbath in its own collection, so we can make updates to individual footbaths without having to update the whole assessor
 *  @TODO Scot to update state when we call 'setData'
 */
import PersonalNotes from '../assessor_components/personal-notes';
import Volume from '../assessor_components/volume';
import ModalInfo from '@/components/misc/modal-info.vue';
import HygieneHeader from '../assessor_components/hygiene-header.vue';
import CreateNewMix from './create-new-mix';
import ModalConfirm from '@/components/misc/modal-confirm.vue';
import PhotoUpload from '@/components/misc/photoUpload';
import Footbath from '@/libs/Classes/Footbath.js';
import Group from '@/libs/Classes/Group.js';
import Evaluation from '@/libs/Classes/Evaluation.js';
import { mapState } from 'vuex';

export default {
  name:"DesignNewFootbath",
  components: {
    PersonalNotes,
    Volume,
    CreateNewMix,
    ModalConfirm,
    ModalInfo,
    PhotoUpload,
    HygieneHeader,
  },
  data() {
    return {
      assessor: null,
      new_footbath: null,
      scoresFilledOut: false,
      footbathClass: null,
      successMessage: false,
      default_footbath: {
        id: null,
        name: null,
        personal_notes: null,
        data: {
          cow_passes_bath: null,
          labor_cost: 50,
          ph: null,
        },
        volume: {
          measurements_length: null,
          measurements_width: null,
          measurements_step_in_depth: null,
        },
      },
      errorMessage: false,
      showModal: false,
      group: null,
      percent3_4: null,
      suggestedFrequency: null,
    };
  },
  methods: {
    checkIfDataHasChanged(url) {
      let clonedDefault = this.getClonedDefaultFootbath();

      if (
        (this.assessor.new_footbath !== undefined &&
          !_.isEqual(this.new_footbath, this.assessor.new_footbath)) ||
        (this.assessor.new_footbath === undefined &&
          !_.isEqual(this.new_footbath, clonedDefault))
      ) {
        this.url = url;
        this.showModal = true;
      } else {
        this.$router.push(url);
      }
    },
    getDate(timestamp) {
      let date = new Date(timestamp);
      var monthNames = [
        this.$t('Jan.'),
        this.$t('Feb.'),
        this.$t('Mar.'),
        this.$t('Apr.'),
        this.$t('May'),
        this.$t('Jun.'),
        this.$t('Jul.'),
        this.$t('Aug.'),
        this.$t('Sep.'),
        this.$t('Oct.'),
        this.$t('Nov.'),
        this.$t('Dec.'),
      ];
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
      return day + ' ' + monthNames[monthIndex] + ' ' + year;
    },
    getFirstAssessorTypeByGroup: function(group) {
      return this.evaluation.order.find(assessorType => {
        return group.assessor_list.find(groupAssessorType => {
          return groupAssessorType == assessorType;
        });
      });
    },
    getClonedDefaultFootbath() {
      let clonedDefault = _.cloneDeep(this.default_footbath);
      clonedDefault.id = this.new_footbath.id;
      clonedDefault.volume = {
        measurements_length: 0, //this.footbathClass.getIdealLength(),
        measurements_width: 0, // this.footbathClass.getIdealWidth(),
        measurements_step_in_depth: 0, //this.footbathClass.getIdealStepInDepth()
      };
      return clonedDefault;
    },
    resetDefaults() {
      this.new_footbath = _.cloneDeep(this.getClonedDefaultFootbath());
      this.footbathClass.footbath = this.new_footbath;
      this.$validator.reset();
    },
    createNewFootbath() {
      let footbath = _.cloneDeep(this.default_footbath);
      footbath.id = this.$generateId();
      return footbath;
    },
    goToMixes() {
      /**
       * Check to see if this footbath is different than the one in state.
       * If it is different, we want to warn the user of unsaved changes
       */

      if (
        this.assessor.new_footbath !== undefined &&
        !_.isEqual(this.new_footbath, this.assessor.new_footbath)
      ) {
        this.url =
          '/users/' +
          this.$route.params.userId +
          '/evaluations/' +
          this.$route.params.evaluationId +
          '/groups/' +
          this.$route.params.groupId +
          '/assessors/' +
          this.$route.params.assessorType +
          '/footbaths/new/' +
          this.assessor.new_footbath.id +
          '/mixes/';
        this.showModal = true;
      } else {
        this.$router.push(
          '/users/' +
            this.$route.params.userId +
            '/evaluations/' +
            this.$route.params.evaluationId +
            '/groups/' +
            this.$route.params.groupId +
            '/assessors/' +
            this.$route.params.assessorType +
            '/footbaths/new/' +
            this.assessor.new_footbath.id +
            '/mixes/'
        );
      }
    },
    saveNewFootbath() {
      this.$validator.validateAll().then(result => {
        if (result === true) {
          this.assessor.new_footbath = _.cloneDeep(this.new_footbath);

          this.$store.dispatch('setData', {
            currentVueInstance: this,
            endpointUrl:
              'users/' +
              this.$route.params.userId +
              '/evaluations/' +
              this.$route.params.evaluationId +
              '/groups/' +
              this.$route.params.groupId +
              '/assessors/' +
              this.assessor.id,
            objectBeingUpdated: this.assessor,
          });

          this.successMessage = true;

          setTimeout(() => {
            this.successMessage = false;
          }, 5000);
        } else {
          this.errorMessage = true;
          setTimeout(() => {
            this.errorMessage = false;
          }, 5000);
        }
      });
    },
    getFrequencySuggested(percent3and4, suggestedFrequency) {
      if (percent3and4 >= 75) {
        this.suggestedFrequency = 7;
      } else if (percent3and4 <= 75 && percent3and4 >= 51) {
        this.suggestedFrequency = 5;
      } else if (percent3and4 <= 50 && percent3and4 >= 25) {
        this.suggestedFrequency = 2;
      } else {
        this.suggestedFrequency = 0;
      }
    },
    getPercent3_4() {
      let total =
        this.assessor.data.hygiene_score_1 +
        this.assessor.data.hygiene_score_2 +
        this.assessor.data.hygiene_score_3 +
        this.assessor.data.hygiene_score_4;
      let percent_score_1 = (this.assessor.data.hygiene_score_1 / total) * 100;
      let percent_score_2 = (this.assessor.data.hygiene_score_2 / total) * 100;
      let percent_score_3 = (this.assessor.data.hygiene_score_3 / total) * 100;
      let percent_score_4 = (this.assessor.data.hygiene_score_4 / total) * 100;
      let percent_arr = [
        percent_score_1,
        percent_score_2,
        percent_score_3,
        percent_score_4,
      ];
      let new_percents = this.$store.getters.getPercentagesRoundedTo100(percent_arr, 100);

      this.percent3_4 = new_percents[2] + new_percents[3];
    },
    scoresEntered() {
      const total =
        this.assessor.data.hygiene_score_1 +
        this.assessor.data.hygiene_score_2 +
        this.assessor.data.hygiene_score_3 +
        this.assessor.data.hygiene_score_4;
      if (total !== 0) {
        this.getPercent3_4();
        this.getFrequencySuggested(this.percent3_4);
        this.scoresFilledOut = true;
      } else {
        this.scoresFilledOut = false;
      }
    },
  },
  computed: {
    ...mapState({
      evaluation: state => state.evaluations.evaluation,
    }),
    nextGroup: function() {
      let foundCurrentGroup = false;
      // return the next group id if it exists
      return this.evaluation.groups.find(group => {
        if (foundCurrentGroup === false) {
          if (group.id === this.$route.params.groupId) foundCurrentGroup = true;

          return false;
        }
        return true;
      });
    },
    previousGroup: function() {
      let foundCurrentGroup = false;
      let previousGroup = undefined;
      // return the next group id if it exists

      for (let i = 0; i < this.evaluation.groups.length; i++) {
        if (this.evaluation.groups[i].id === this.$route.params.groupId) {
          return previousGroup;
        }

        previousGroup = this.evaluation.groups[i];
      }

      return undefined;
    },
  },
  created() {
    const { groupId } = this.$route.params;
    // this.evaluation = this.$store.getters.getData("users/" + this.$route.params.userId + "/evaluations/" + this.$route.params.evaluationId);
    // this.group = this.$store.getters.getData("users/" + this.$route.params.userId + "/evaluations/" + this.$route.params.evaluationId + "/groups/" + this.$route.params.groupId);
    console.log({evaluation: this.evaluation})
    this.group = this.evaluation.groups[groupId];
    this.assessor = this.group.assessors[this.$route.params.assessorType];

    let evaluationClass = new Evaluation(this, this.evaluation);
    let groupClass = new Evaluation(this, evaluationClass, this.group);

    if (this.assessor.new_footbath === undefined) {
      this.new_footbath = this.createNewFootbath();
      this.footbathClass = new Footbath(
        this,
        evaluationClass,
        groupClass,
        this.assessor,
        this.new_footbath
      );
      this.new_footbath.volume.measurements_length = this.footbathClass.idealLength;
      this.new_footbath.volume.measurements_width = this.footbathClass.idealWidth;
      this.new_footbath.volume.measurements_step_in_depth = this.footbathClass.idealStepInDepth;
    } else {
      this.new_footbath = _.cloneDeep(this.assessor.new_footbath);
      this.footbathClass = new Footbath(
        this,
        evaluationClass,
        groupClass,
        this.assessor,
        this.new_footbath
      );
    }
  },
  beforeMount() {
    this.scoresEntered();
  },
};
</script>
