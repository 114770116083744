<template>
    <div class="row">
        <div class="col-md-24">
            <label class="mb-1 d-inline-block">{{ 'Personal Notes' | translate }} </label>
            <modal-info :value="'personal-notes'" class="d-inline-block d-lg-none">
                <template slot="modal-content">
                    <h2 class="h2 pt-3 px-3 text-center">{{'Personal Notes: Not included when outputting reports.' | translate}}</h2>
                    <div class="row modal-bottom pb-3">
                        <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">Cancel</a>
                    </div>
                </template>
            </modal-info>
            <modal-info :value="'personal-notes-sm'" class="d-none d-lg-inline-block">
                <template slot="modal-content">
                    <h2 class="h2 pt-3 pb-2 px-3 text-center">{{'Personal Notes: Not included when outputting reports.' | translate}}</h2>
                    <div class="row modal-bottom pb-3">
                        <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{'Cancel' | translate}}</a>
                    </div>
                </template>
            </modal-info>
        </div>
        <div class="col-md-24">
            <textarea v-model="tempValue" v-on:input="$emit('input', $event.target.value)" rows="6" class="form-control d-none d-lg-block"></textarea>
            <textarea v-model="tempValue" v-on:input="$emit('input', $event.target.value)" rows="4" class="form-control d-lg-none"></textarea>
        </div>
    </div>
</template>

<script>
    import ModalInfo from '@/components/misc/modal-info.vue';

    export default {
        components: {
            ModalInfo
        },
        data() {
			return {
                tempValue: this.$props.value
			}
		},
        props: ['value']
    }
    
</script>
