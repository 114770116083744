export const LINE_HEIGHTS = {
  en: {
    ONE_LINE: 0.4,
    TWO_LINE: 0.55,
    THREE_LINE: 0.7,
    FOUR_LINE: 0.95,
    FIVE_LINE: 1.1,
    SIX_LINE: 1.25,
    SEVEN_LINE: 1.35,
    TEN_LINE: 1.85,
  },

  ch: {
    ONE_LINE: 0.4,
    TWO_LINE: 0.55,
    THREE_LINE: 0.55,
    FOUR_LINE: 0.8,
    FIVE_LINE: 1.05,
    SIX_LINE: 1.25,
    SEVEN_LINE: 1.35,
    TEN_LINE: 1.85,
  },

  es: {
    ONE_LINE: 0.5,
    TWO_LINE: 0.6,
    THREE_LINE: 0.8,
    FOUR_LINE: 1,
    FIVE_LINE: 1.2,
    SIX_LINE: 1.4,
    SEVEN_LINE: 1.35,
    TEN_LINE: 2,
  },

  fr: {
    ONE_LINE: 0.45,
    TWO_LINE: 0.55,
    THREE_LINE: 0.7,
    FOUR_LINE: 0.95,
    FIVE_LINE: 1.1,
    SIX_LINE: 1.25,
    SEVEN_LINE: 1.35,
    TEN_LINE: 1.85,
  },
};
