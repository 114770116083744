import { timebudget } from './timebudget';
import { medias } from './../Global/medias.js';

class TimebudgetAssessorSlides {

    constructor(app, report, evaluation, group, user) {
    	this.app = app;
    	this.report = report;
    	this.evaluation = evaluation;
		this.group = group;
		this.user = user;
	}

	add(tableOfContentsCalculatorPosition) {

        let assessor = this.group.getAssessorByType("4");

        if ( assessor === false || assessor.assessor.data === undefined ) {
            throw this.group.getName() + this.app.$t(" - ") + this.app.$t("Time Budget assessor is not ready.");
        }

        this.report.tableOfContentsHelper("Time Budget", tableOfContentsCalculatorPosition, this.group);

		timebudget(this.app, this.report, this.evaluation, this.group, assessor);
		medias(this.app, this.report, this.group, "4", this.user);
	}

}

export default TimebudgetAssessorSlides;