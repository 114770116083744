<template>
  <div class="container main-container main-container--hygiene view">
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">
          {{ 'Tell Us About Your Bedded Pack' | translate }}
        </h2>
        <modal-info :value="'tell-us'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ 'Bedded Pack' | translate }}
            </h2>
            <p class="px-3">
              {{
                'For more information about Bedded Packs, click the link to learn more from '
                  | translate
              }}
              <a
                :href="$t('https://library.zinprofirststep.com/bedded-packs/')"
                @click="$goToLameness($t('/bedded-packs/'), $event)"
                target="_blank"
                >{{ 'FirstStep Resources' | translate }}</a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </div>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-12">
        <label class="label d-inline">
          {{
            $t('Average Cow Size on Bedded Pack? (') +
              $t($getEquivalentUnit(evaluation.data.measurements, 'kg')) +
              ')'
          }}
        </label>
        <input
          type="text"
          :value="groupClass.getCowSizeLabel()"
          class="form-control"
          readonly
        />
      </div>

      <div class="col-lg-12">
        <label class="label d-inline">
          {{
            $t('Bedding/Resting Area Per Cow (') +
              $t(getSquareMeasurements) +
              $t(')?')
          }}
          <modal-info class="d-inline" :value="'modal-bedding'">
            <template slot="modal-content">
              <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                {{
                  $t('Bedding/Resting Area Per Cow (') +
                    $t(getSquareMeasurements) +
                    $t(')?')
                }}
              </h2>
              <p class="px-3 mb-2">
                <!-- {{
                  $t('Calculate resting area per cow by determining total') +
                    ' ' +
                    $t(getSquareMeasurements) +
                    ' ' +
                    $t('available less')
                }}
                {{ convertedBeddingRestingAreaPerCowThreshold }}
                {{ $t(getSquareMeasurements) }}
                {{
                  $t(
                    'per entry point, divided by cow number. ((length X width of bedded pack) - (# of entry points X'
                  )
                }}
                {{ convertedBeddingRestingAreaPerCowThreshold }}
                {{ $t(getSquareMeasurements) }}
                {{ $t(')) / number of cows.') }} -->
              {{ 
              evaluationClass.isImperial ? 
                    'Calculate resting area per cow by determining total sq ft available less 200 sq ft per entry point, divided by cow number. ((length X width of bedded pack) - (# of entry points X 200 sq ft )) / number of cows.'
                    : 'Calculate resting area per cow by determining total m² available less 18.6 m² per entry point, divided by cow number. ((length X width of bedded pack) - (# of entry points X 18.6 m² )) / number of cows.' | translate
              }}
              </p>
              <div class="row modal-bottom pb-3">
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center"
                  data-dismiss="modal"
                  >{{ 'Cancel' | translate }}</a
                >
              </div>
            </template>
          </modal-info>
        </label>
        <input
          v-model.number="converted_bedding_resting_area_per_cow"
          type="number"
          inputmode="decimal"
          class="form-control"
        />
      </div>

      <div class="col-lg-12">
        <label class="label d-inline">
          {{ $t('How is Pack Managed?') }}
        </label>
        <select
          v-model="
            assessor.data.is_the_pack_managed_aerobically_or_anaerobically
          "
          class="form-control"
        >
          <!-- <option :value="null">{{"No Bedded Pack" | translate}}</option> -->
          <option value="aerobic">{{ 'Aerobic (Compost)' | translate }}</option>
          <option value="anaerobic">{{ 'Anaerobic' | translate }}</option>
        </select>
      </div>

      <div class="col-lg-12">
        <label class="mb-1">{{
          $t('Long Axis Orientation of the Barn')
        }}</label>
        <select
          v-model="assessor.data.long_axis_orientation_of_the_barn"
          class="form-control"
        >
          <option :value="null" selected disabled>{{ $t('Select') }}</option>
          <option value="n_to_s">{{ $t('N to S') }}</option>
          <option value="ne_to_sw">{{ $t('NE to SW') }}</option>
          <option value="e_to_w">{{ $t('E to W') }}</option>
          <option value="se_to_nw">{{ $t('SE to NW') }}</option>
        </select>
      </div>

      <div
        v-if="
          assessor.data.is_the_pack_managed_aerobically_or_anaerobically ===
            'aerobic'
        "
        class="col-lg-12"
      >
        <label class="label d-inline">
          {{ $t('How Frequently is Fresh Bedding Added (days)?') }}
        </label>
        <input
          v-model.number="assessor.data.how_frequently_is_fresh_bedding_added"
          type="number"
          @keypress="$isNumber"
          inputmode="numeric"
          class="form-control"
        />
      </div>

      <div class="col-lg-12">
        <div class="mb-1">
          <label class="label d-inline">
            {{
              $t('Amount of Fresh Bedding Added per Cow per Day (') +
                $t($getEquivalentUnit(evaluation.data.measurements, 'kg')) +
                $t(')?')
            }}
          </label>
          <input
            v-model.number="converted_fresh_bedding_added"
            type="number"
            @keypress="$isNumber"
            inputmode="numeric"
            class="form-control"
          />
        </div>
      </div>

      <div
        v-if="
          assessor.data.is_the_pack_managed_aerobically_or_anaerobically ===
            'aerobic'
        "
        class="col-lg-12"
      >
        <label class="label d-inline">
          {{ $t('Frequency Pack is Stirred (Times/Day)?') }}
          <modal-info class="d-inline" :value="'modal-frequency-pack'">
            <template slot="modal-content">
              <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                {{ $t('Frequency Pack is Stirred (Times/Day)?') }}
              </h2>
              <p class="px-3 mb-2">
                {{
                  'Compost packs should be stirred at least 2 times per day. In cold climates this may reduce the pack temperature and stirring frequency may need to be reduced.'
                    | translate
                }}
              </p>
              <div class="row modal-bottom pb-3">
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center"
                  data-dismiss="modal"
                  >{{ 'Cancel' | translate }}</a
                >
              </div>
            </template>
          </modal-info>
        </label>
        <input
          v-model.number="
            assessor.data.in_aerobic_barns_how_frequently_is_the_pack_stirred
          "
          type="number"
          @keypress="$isNumber"
          inputmode="numeric"
          class="form-control"
        />
      </div>

      <div class="col-lg-12">
        <label class="label d-inline modal-wide-container">
          {{
            $t('What Percentage of Cows have an Udder Hygiene Score of 3 or 4?')
          }}
          <modal-info class="d-inline" :value="'modal-percentage-3-4'">
            <template slot="modal-content">
              <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                {{ $t('Udder Hygiene Score') }}
              </h2>
              <div class="bp-table">
                <div class="bp-table--prev">
                  <span @click="tablePrev"> {{'<'}} </span>
                </div>
                <div class="bp-table--content">
                  <table class="table-bedded-pack-ibutton">
                    <tbody>
                      <tr>
                        <td class="bp-table-cell">
                          <b>{{ $t('Score 1') }}</b>
                        </td>
                        <td class="bp-table-cell">
                          <b>{{ $t('Score 2') }}</b>
                        </td>
                        <td class="bp-table-cell">
                          <b>{{ $t('Score 3') }}</b>
                        </td>
                        <td class="bp-table-cell">
                          <b>{{ $t('Score 4') }}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="@/assets/img/ibutton/image1.jpg"
                            class="bp-table-image"
                          />
                        </td>
                        <td>
                          <img
                            src="@/assets/img/ibutton/image3.jpg"
                            class="bp-table-image"
                          />
                        </td>
                        <td>
                          <img
                            src="@/assets/img/ibutton/image2.jpg"
                            class="bp-table-image"
                          />
                        </td>
                        <td>
                          <img
                            src="@/assets/img/ibutton/image4.jpg"
                            class="bp-table-image"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><span></span></p>
                          <p>
                            <span>{{ $t('Free of dirt and manure') }}</span>
                          </p>
                        </td>

                        <td>
                          <p><span></span></p>
                          <p>
                            <span>{{ $t('Slightly dirty') }}</span>
                          </p>
                          <p>
                            <span>({{ $t('2-10% of surface area') }})</span>
                          </p>
                          <p><span></span></p>
                        </td>
                        <td>
                          <p><span></span></p>
                          <p>
                            <span>{{
                              $t('Moderately covered with dirt and manure')
                            }}</span>
                          </p>
                          <p>
                            <span>({{ $t('10-30% of surface area') }})</span>
                          </p>
                        </td>
                        <td>
                          <p><span></span></p>
                          <p>
                            <span>{{
                              $t('Covered with caked on dirt and manure')
                            }}</span>
                          </p>
                          <p>
                            <span>({{ $t('> 30% of surface area') }})</span>
                          </p>
                          <p><span></span></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="bp-table--next">
                  <span @click="tableNext"> > </span>
                </div>
              </div>
              <h6 class="text-center">
                {{
                  $t('Copyright 2002 © Pamela L. Ruegg, all rights reserved.')
                }}
              </h6>
              <h5 class="text-center">
                {{
                  $t(
                    'Udders scored 3 and 4 have increased risk of mastitis as compared to scores 1 & 2.'
                  )
                }}
              </h5>
              <h4 class="text-center">{{ $t('Suggested Sample Size') }}:</h4>
              <div class="table-bedded-pack-ibutton-bottom">
                <table class="table-bedded-pack-ibutton">
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <span>{{ $t('Group Size') }}</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>{{ $t('Suggested Sample Size') }}</span>
                        </p>
                      </td>
                      <td>
                        <p><span>%</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span>50</span></p>
                      </td>
                      <td>
                        <p><span>44</span></p>
                      </td>
                      <td>
                        <p><span>88</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span>100</span></p>
                      </td>
                      <td>
                        <p><span>80</span></p>
                      </td>
                      <td>
                        <p><span>80</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span>200</span></p>
                      </td>
                      <td>
                        <p><span>132</span></p>
                      </td>
                      <td>
                        <p><span>66</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span>300</span></p>
                      </td>
                      <td>
                        <p><span>169</span></p>
                      </td>
                      <td>
                        <p><span>56</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span>400</span></p>
                      </td>
                      <td>
                        <p><span>196</span></p>
                      </td>
                      <td>
                        <p><span>49</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span>500</span></p>
                      </td>
                      <td>
                        <p><span>217</span></p>
                      </td>
                      <td>
                        <p><span>43</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span>750</span></p>
                      </td>
                      <td>
                        <p><span>254</span></p>
                      </td>
                      <td>
                        <p><span>34</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span>1000</span></p>
                      </td>
                      <td>
                        <p><span>278</span></p>
                      </td>
                      <td>
                        <p><span>28</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><span>2000</span></p>
                      </td>
                      <td>
                        <p><span>322</span></p>
                      </td>
                      <td>
                        <p><span>16</span></p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row modal-bottom pb-3">
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center"
                  data-dismiss="modal"
                  >{{ 'Cancel' | translate }}</a
                >
              </div>
            </template>
          </modal-info>
        </label>
        <input
          v-model.number="
            assessor.data.what_percentage_of_cows_have_score_of_three_or_four
          "
          type="number"
          @keypress="$isNumber"
          inputmode="numeric"
          class="form-control"
        />
      </div>

      <div
        v-if="
          assessor.data.is_the_pack_managed_aerobically_or_anaerobically ===
            'aerobic'
        "
        class="col-lg-12"
      >
        <label class="label d-inline">
          {{
            $t('What is the Temperature of the Bedded Pack?') +
              ' (°' +
              $t($getEquivalentUnit(evaluation.data.measurements, 'C')) +
              ')'
          }}
          <modal-info class="d-inline" :value="'modal-temperature-bedded-pack'">
            <template slot="modal-content">
              <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                {{ $t('What is the Temperature of the Bedded Pack?') }}
              </h2>
              <p class="px-3 mb-2">
                {{
                  'The quantity of bedding applied should be related to the temperature of the compost pack. The pack temperature should be measured at a depth of'
                    | translate
                }}
                {{ temperatureDepth }}
                {{ temperatureDepthMeasurements | translate }}.
              </p>
              <div class="row modal-bottom pb-3">
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center"
                  data-dismiss="modal"
                  >{{ 'Cancel' | translate }}</a
                >
              </div>
            </template>
          </modal-info>
        </label>
        <input
          v-model.number="converted_what_is_the_temperature_of_the_bedded_pack"
          type="number"
          @keypress="$isNumber"
          inputmode="numeric"
          class="form-control"
        />
      </div>

      <div
        v-if="
          assessor.data.is_the_pack_managed_aerobically_or_anaerobically ===
            'aerobic'
        "
        class="col-lg-12"
      >
        <label class="label d-inline">
          {{
            $t('How Frequently is the Whole or Partial Bed Removed (months)?')
          }}
        </label>
        <input
          v-model.number="
            assessor.data.how_frequently_is_the_whole_or_partial_bed_removed
          "
          type="number"
          @keypress="$isNumber"
          inputmode="numeric"
          class="form-control"
        />
      </div>

      <div
        v-if="
          assessor.data.is_the_pack_managed_aerobically_or_anaerobically ===
            'anaerobic'
        "
        class="col-lg-12"
      >
        <label class="label d-inline">
          {{ $t('How Frequently is the Whole Bed Removed (weeks)?') }}
        </label>
        <input
          v-model.number="assessor.data.how_frequently_is_the_whole_bed_removed"
          type="number"
          inputmode="numeric"
          @keypress="$isNumber"
          class="form-control"
        />
      </div>

      <div class="col-lg-12">
        <label class="label d-inline">
          {{
            $t('What is the Width of the Feed Alley (') +
              $t($getEquivalentUnit(evaluation.data.measurements, 'm')) +
              $t(')?')
          }}
        </label>
        <input
          v-model.number="converted_what_is_the_width_of_the_feed_alley"
          type="number"
          inputmode="decimal"
          class="form-control"
        />
      </div>
    </div>

    <hr />

    <question
      v-if="
        assessor.data.is_the_pack_managed_aerobically_or_anaerobically ===
          'aerobic'
      "
      @change="
        assessor.data.is_the_bed_stirred_to_the_proper_depth = $event.value
      "
      :initial-value="assessor.data.is_the_bed_stirred_to_the_proper_depth"
    >
      <div class="mb-1">
        <label
          :class="[
            'label d-inline',
            {
              'text-danger':
                assessor.data.is_the_bed_stirred_to_the_proper_depth === 0,
            },
          ]"
        >
          {{ $t('Is the bed stirred to the proper depth?') }}
        </label>
        <modal-info class="d-inline" :value="'modal-stirred'">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ $t('Is the bed stirred to the proper depth?') }}
            </h2>
            <p class="px-3 mb-2">
              {{ 'The bed should be stirred to within' | translate }}
              {{ properDepthFirstValue }}
              {{ properDepthMeasurements | translate }}
              {{
                'of the pack base to ensure it is properly stirred without contacting the soil base. If it is not possible to achieve the depth on very deep packs, aim for'
                  | translate
              }}
              {{ properDepthSecondValue }}
              {{ properDepthMeasurements | translate }}
              {{ 'depth.' | translate }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.is_the_bedded_area_separated_from_the_concrete_feeding_area_by_a_bedding_retainer =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_the_bedded_area_separated_from_the_concrete_feeding_area_by_a_bedding_retainer
      "
    >
      <div class="mb-1">
        <label
          :class="[
            'label d-inline',
            {
              'text-danger':
                assessor.data
                  .is_the_bedded_area_separated_from_the_concrete_feeding_area_by_a_bedding_retainer ===
                0,
            },
          ]"
        >
          {{
            $t(
              'Is the bedded area separated from the concrete feeding area by a bedding retainer or curb?'
            )
          }}
        </label>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.is_the_concrete_feeding_area_scraped_at_least_twice_a_day =
          $event.value
      "
      :initial-value="
        assessor.data.is_the_concrete_feeding_area_scraped_at_least_twice_a_day
      "
    >
      <div class="mb-1">
        <label
          :class="[
            'label d-inline',
            {
              'text-danger':
                assessor.data
                  .is_the_concrete_feeding_area_scraped_at_least_twice_a_day ===
                0,
            },
          ]"
        >
          {{ $t('Is the concrete feeding area scraped at least twice a day?') }}
        </label>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.is_pack_excessively_wet_around_access_points =
          $event.value
      "
      :initial-value="
        assessor.data.is_pack_excessively_wet_around_access_points
      "
    >
      <div class="mb-1">
        <label
          :class="[
            'label d-inline',
            {
              'text-danger':
                assessor.data.is_pack_excessively_wet_around_access_points ===
                1,
            },
          ]"
        >
          {{
            $t(
              'Is the pack excessively wet and sloppy around the access points from alleyways and the feed bunk?'
            )
          }}
        </label>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.is_the_area_well_ventilated_with_fans_over_the_bedded_area =
          $event.value
      "
      :initial-value="
        assessor.data.is_the_area_well_ventilated_with_fans_over_the_bedded_area
      "
    >
      <div class="mb-1">
        <label
          :class="[
            'label d-inline',
            {
              'text-danger':
                assessor.data
                  .is_the_area_well_ventilated_with_fans_over_the_bedded_area ===
                0,
            },
          ]"
        >
          {{ $t('Is the area ventilated with fans over the bedded area?') }}
        </label>
      </div>
    </question>

    <hr
      v-if="
        assessor.data.is_the_area_well_ventilated_with_fans_over_the_bedded_area
      "
    />

    <div
      class="row"
      v-if="
        assessor.data.is_the_area_well_ventilated_with_fans_over_the_bedded_area
      "
    >
      <div class="col-md-12 col-lg-11 col-xl-13 d-lg-flex align-self-center">
        <div class="mb-1">
          <label
            class="label d-inline"
            :class="[
              'label d-inline',
              {
                'text-danger':
                  assessor.data.what_is_the_winds_speed_created_by_the_fans ===
                  'less_two_m_s',
              },
            ]"
          >
            {{ $t('What is the wind speed created by the fans?') }}
          </label>
          <modal-info class="d-inline" :value="'modal-fan-speed'">
            <template slot="modal-content">
              <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
                {{ $t('What is the wind speed created by the fans?') }}
              </h2>
              <p class="px-3 mb-2">
                {{
                  "The wind speed should be measured at the height of the cow's head lying on the bed, under the next fan."
                    | translate
                }}
                {{ fanWentSpeedThresholdText }}
                {{ 'is good for ventilation and' | translate }}
                {{ fanWentPerfectSpeedThresholdText }}
                {{
                  'should be the ideal wind speed to reduce heat stress.'
                    | translate
                }}
              </p>
              <div class="row modal-bottom pb-3">
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center"
                  data-dismiss="modal"
                  >{{ 'Cancel' | translate }}</a
                >
              </div>
            </template>
          </modal-info>
        </div>
      </div>
      <div
        class="col-md-12 col-lg-13 col-xl-11 d-flex flex-row flex-lg-row justify-content-center justify-content-md-end flex-md-wrap flex-lg-nowrap"
      >
        <div
          class="label_group label_group--width-sm label_group--inline mr-2 mr-sm-2 ml-0"
        >
          <input
            class="label_group__input"
            v-model="assessor.data.what_is_the_winds_speed_created_by_the_fans"
            type="radio"
            id="less_two_m_s"
            value="less_two_m_s"
          />
          <label class="label_group__label" for="less_two_m_s">
            &#60; {{ fanWentSpeedThresholdText }}
          </label>
        </div>
        <div class="label_group label_group--width-sm label_group--inline">
          <input
            class="label_group__input"
            v-model="assessor.data.what_is_the_winds_speed_created_by_the_fans"
            type="radio"
            id="more_two_m_s"
            value="more_two_m_s"
          />
          <label class="label_group__label" for="more_two_m_s">
            &ge; {{ fanWentSpeedThresholdText }}
          </label>
        </div>
      </div>
    </div>

    <hr />

    <question
      @change="
        assessor.data.is_there_a_smell_of_ammonia_present_in_the_barn =
          $event.value
      "
      :initial-value="
        assessor.data.is_there_a_smell_of_ammonia_present_in_the_barn
      "
    >
      <div class="mb-1">
        <label
          :class="[
            'label d-inline',
            {
              'text-danger':
                assessor.data
                  .is_there_a_smell_of_ammonia_present_in_the_barn === 1,
            },
          ]"
        >
          {{ $t('Is there a smell of ammonia present in the barn?') }}
        </label>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.does_the_pack_have_the_correct_moisture_consistency =
          $event.value
      "
      :initial-value="
        assessor.data.does_the_pack_have_the_correct_moisture_consistency
      "
    >
      <div class="mb-1">
        <label
          :class="[
            'label d-inline',
            {
              'text-danger':
                assessor.data
                  .does_the_pack_have_the_correct_moisture_consistency === 0,
            },
          ]"
        >
          {{ $t('Does the pack have the correct moisture consistency?') }}
        </label>
        <modal-info class="d-inline" :value="'modal-pack-correct'">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ $t('Does the pack have the correct moisture consistency?') }}
            </h2>
            <p class="px-3 mb-2">
              {{
                'For anaerobic, the bedding surface should be dry.' | translate
              }}
            </p>
            <p class="px-3 mb-2">
              {{
                'For aerobic (compost) packs, squeeze a portion of the bed in your hands. If it forms a cake that doesn’t fall apart when you open your hand, the bedding is too wet. Adequate moisture is between 40% and 65%. For aerobic (compost) packs, the bedding should form a cake that easily falls apart, as shown in this video.'
                  | translate
              }}
            </p>
            <div
              class="wistia_responsive_padding mx-3"
              style="padding:73% 0 0 0;position:relative;"
            >
              <div
                class="wistia_responsive_wrapper"
                style="height:100%;left:0;position:absolute;top:0;width:100%;"
              >
                <div
                  class="wistia_embed wistia_async_xj58s0l2xi videoFoam=true"
                  style="height:100%;width:100%"
                >
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </question>

    <hr
      v-if="
        assessor.data.is_the_pack_managed_aerobically_or_anaerobically ===
          'aerobic'
      "
    />

    <question
      v-if="
        assessor.data.is_the_pack_managed_aerobically_or_anaerobically ===
          'aerobic'
      "
      @change="
        assessor.data.does_the_pack_raise_dust_when_the_animals_walk =
          $event.value
      "
      :initial-value="
        assessor.data.does_the_pack_raise_dust_when_the_animals_walk
      "
    >
      <div class="mb-1">
        <label
          :class="[
            'label d-inline',
            {
              'text-danger':
                assessor.data.does_the_pack_raise_dust_when_the_animals_walk ===
                1,
            },
          ]"
        >
          {{ $t('Does the pack raise dust when the animals walk?') }}
        </label>
        <modal-info class="d-inline" :value="'modal-dust'">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ $t('Does the pack raise dust when the animals walk?') }}
            </h2>
            <p class="px-3 mb-2">
              {{
                'This video shows a pack that is too dry. Notice the dust that is created as the animals walk, which could lead to hygiene and respiratory issues. Water should be added to the pack prior to stirring to correct the moisture and control the dust.'
                  | translate
              }}
            </p>
            <div
              class="wistia_responsive_padding mx-3"
              style="padding:73% 0 0 0;position:relative;"
            >
              <div
                class="wistia_responsive_wrapper"
                style="height:100%;left:0;position:absolute;top:0;width:100%;"
              >
                <div
                  class="wistia_embed wistia_async_q8bozxf4m9 videoFoam=true"
                  style="height:100%;width:100%"
                >
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.is_there_evidence_of_liquid_pooling_in_the_barn =
          $event.value
      "
      :initial-value="
        assessor.data.is_there_evidence_of_liquid_pooling_in_the_barn
      "
    >
      <div class="mb-1">
        <label
          :class="[
            'label d-inline',
            {
              'text-danger':
                assessor.data
                  .is_there_evidence_of_liquid_pooling_in_the_barn === 1,
            },
          ]"
        >
          {{ $t('Is there evidence of liquid “pooling” in the barn?') }}
        </label>
        <modal-info class="d-inline" :value="'modal-polling'">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ $t('Is there evidence of liquid “pooling” in the barn?') }}
            </h2>
            <p class="px-3 mb-2">
              {{
                'Bedded pack is not overly wet. Pack is shielded from precipitation. No pooling of urine. Lack of wet spots at pack entrance.'
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </question>

    <hr />

    <question
      @change="assessor.data.is_the_bedding_soft_and_comfortable = $event.value"
      :initial-value="assessor.data.is_the_bedding_soft_and_comfortable"
    >
      <div class="mb-1">
        <label
          :class="[
            'label d-inline',
            {
              'text-danger':
                assessor.data.is_the_bedding_soft_and_comfortable === 0,
            },
          ]"
        >
          {{ $t('Is the bedding soft and comfortable?') }}
        </label>
        <modal-info class="d-inline" :value="'modal-comfortable'">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ $t('Is the bedding soft and comfortable?') }}
            </h2>
            <p class="px-3 mb-2">
              {{
                'Comfortable beds must not have areas of stiffness or lump formation.'
                  | translate
              }}
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.can_cows_access_water_troughs_from_the_bedded_area =
          $event.value
      "
      :initial-value="
        assessor.data.can_cows_access_water_troughs_from_the_bedded_area
      "
    >
      <div class="mb-1">
        <label
          :class="[
            'label d-inline',
            {
              'text-danger':
                assessor.data
                  .can_cows_access_water_troughs_from_the_bedded_area === 1,
            },
          ]"
        >
          {{ $t('Can cows access water troughs from the bedded area?') }}
        </label>
      </div>
    </question>

    <hr />

    <div class="row my-0">
      <div class="col-md-12">
        <personal-notes v-model="assessor.data.personal_notes"></personal-notes>
      </div>
      <div class="col-md-12">
        <presentation-notes
          v-model="assessor.data.presentation_notes"
        ></presentation-notes>
      </div>
    </div>
    <div
      class="row mx-0 mt-4 justify-content-center justify-content-md-start center align-items-center"
    >
      <photoUpload :assessorId="7" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

    <div class="row misc-bottom">
      <div class="col-md-12 flex-sm-row-reverse">
        <a
          class="btn btn-secondary btn-full--sm mb-2 mt-md-0"
          href="javascript:history.go(-1)"
          >{{ 'Back' | translate }}</a
        >
      </div>
      <div class="col-md-12">
        <router-link
          v-if="user && user.role !== 'data_entry'"
          :to="'/reports'"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Reports' | translate }}
        </router-link>
        <button
          v-else-if="
            user && user.role === 'data_entry' && !$store.state.offline
          "
          data-toggle="modal"
          @click="requestReport(evaluation.name)"
          :data-target="['#requestSent-' + user.id]"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Request Report' | translate }}
          <span v-show="requestReportLoader" class="loader ml-1"></span>
        </button>
      </div>
    </div>

    <div
      v-show="successMessage"
      class="alert alert-primary mt-3 mb-0"
      role="alert"
    >
      {{ 'Your request has been sent!' | translate }}
    </div>

    <div
      v-show="errorMessage"
      class="alert alert-danger mt-3 mb-0"
      role="alert"
    >
      {{ 'An error has occurred during your request!' | translate }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import PresentationNotes from './assessor_components/presentation-notes';
import PersonalNotes from './assessor_components/personal-notes';
import Question from './assessor_components/question';
import ModalInfo from '@/components/misc/modal-info.vue';
import PhotoUpload from '@/components/misc/photoUpload';
import HygieneHeader from './assessor_components/hygiene-header.vue';
import BeddedPack from '@/libs/Classes/BeddedPack.js';
import Evaluation from '@/libs/Classes/Evaluation.js';
import Group from '@/libs/Classes/Group.js';
import convert from 'convert-units';
import requestReportMixin from '@/mixins/requestReport.js';

export default {
  components: {
    PersonalNotes,
    PresentationNotes,
    Question,
    HygieneHeader,
    PhotoUpload,
    ModalInfo,
  },
  props: ['assessor', 'group', 'evaluation'],
  mixins: [requestReportMixin],
  data() {
    return {
      beddedpack: null,
      evaluationClass: null
    };
  },
  computed: {
    converted_fresh_bedding_added: {
      get() {
        return this.beddedpack.getHowMuchFreshBeddingIsAdded(false);
      },
      set(newValue) {
        return this.beddedpack.setHowMuchFreshBeddingIsAdded(newValue);
      },
    },
    converted_bedding_resting_area_per_cow: {
      get() {
        return this.beddedpack.getWhatIsTheBeddingAreaPerCow(false);
      },
      set(newValue) {
        return this.beddedpack.setWhatIsTheBeddingAreaPerCow(newValue);
      },
    },
    converted_what_is_the_width_of_the_feed_alley: {
      get() {
        return this.beddedpack.getWhatIsTheWidthOfTheFeedAlley(false);
      },
      set(newValue) {
        return this.beddedpack.setWhatIsTheWidthOfTheFeedAlley(newValue);
      },
    },
    converted_what_is_the_temperature_of_the_bedded_pack: {
      get() {
        return this.beddedpack.getWhatIsTheTemperatureOfTheBeddedPack(false);
      },
      set(newValue) {
        return this.beddedpack.setWhatIsTheTemperatureOfTheBeddedPack(newValue);
      },
    },
    fanWentSpeedThresholdText: function() {
      return this.beddedpack.getFanWentSpeedThresholdText();
    },
    fanWentPerfectSpeedThresholdText: function() {
      return this.beddedpack.getFanWentPerfectSpeedThresholdText();
    },
    convertedBeddingRestingAreaPerCowThreshold: function() {
      if (this.evaluation.data.measurements === 'imperial') {
        return '200';
      }
      return '18.6';
    },
    temperatureDepth: function() {
      if (this.evaluation.data.measurements === 'imperial') {
        return '6-12';
      }
      return '15-30';
    },
    temperatureDepthMeasurements: function() {
      if (this.evaluation.data.measurements === 'imperial') {
        return 'inches';
      }
      return 'cm';
    },
    getSquareMeasurements: function() {
      return this.beddedpack.getSquareMeasurements();
    },
    properDepthFirstValue: function() {
      return this.beddedpack.getProperDepthFirstValue();
    },
    properDepthSecondValue: function() {
      return this.beddedpack.getProperDepthSecondValue();
    },
    properDepthMeasurements: function() {
      return this.beddedpack.getProperDepthMeasurements();
    },
  },
  created() {
    this.evaluationClass = new Evaluation(this, this.evaluation);
    this.groupClass = new Group(this, this.evaluationClass, this.group);
    this.beddedpack = new BeddedPack(
      this,
      this.evaluationClass,
      this.groupClass,
      this.assessor
    );
  },
  methods: {
    tableGetEl() {
      return this.$el.querySelector('.bp-table--content');
    },
    tableGetCellWidth() {
      return this.$el.querySelector('.bp-table-cell').clientWidth;
    },
    tableGetPosition() {
      return this.tableGetEl().scrollLeft;
    },
    tableScrollToPosition(position) {
      this.tableGetEl().scroll({
        left: position,
        behavior: 'smooth',
      });
    },
    tableSlidesNumber() {
      return this.$el.querySelectorAll('.bp-table-cell').length;
    },
    tableGetActiveSlideN() {
      return Math.round(this.tableGetPosition() / this.tableGetCellWidth());
    },
    tableNext() {
      let slide = this.tableGetActiveSlideN() + 1;
      if (slide >= this.tableSlidesNumber()) {
        slide = 0;
      }
      this.tableScrollToPosition(slide * (this.tableGetCellWidth() + 1));
    },
    tablePrev() {
      let slide = this.tableGetActiveSlideN() - 1;
      if (slide < 0) {
        slide = this.tableSlidesNumber();
      }
      this.tableScrollToPosition(slide * (this.tableGetCellWidth() + 1));
    },
  },
};
</script>
<style lang="scss" scoped>
.table-bedded-pack-ibutton {
  table-layout: fixed;
  td {
    border: 1px solid black;
    width: 25%;
    text-align: center;
    //width:100%;
  }
}

.bp-table-wrapper {
  &--content {
    overflow: auto;
    .table-bedded-pack-ibutton {
      td {
        @media (max-width: 767px) {
          min-width: 319px;
        }
      }
    }
  }
}
.table-bedded-pack-ibutton-bottom {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.bp-table-image {
  width: 200px;
  height: 250px;
}
@media (min-width: 768px) and (max-width: 1100px) {
  .bp-table-image {
    width: 150px;
    height: 188px;
  }
}
@media (min-width: 768px) {
  .bp-table {
    display: flex;
    justify-content: center;
    overflow: auto;
    &--next,
    &--prev {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .bp-table {
    display: flex;
    align-items: center;
    &--next,
    &--prev {
      span {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        padding: 7px;
        cursor: pointer;
        &.inactive {
          opacity: 0.5;
        }
      }
    }
    &--content {
      overflow: auto;
      td {
        min-width: 261px;
      }
    }
  }
}
</style>
