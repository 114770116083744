<template>
  <div class="container-dashboard">
    <div class="logo-overlay">
      <!-- <img src="@/assets/img/FirstStep-RGB-Line.png" alt="FirstStep" /> -->
    </div>
    <div class="container">
      <div class="row">
        <div class="col-24 mx-auto">
          <div class="card card--dashboard">
            <div class="card-body card-body--dashboard">
              <h1 class="text-center h1 h1--dashboard">
                {{ 'What Would You Like To Do Today?' | translate }}
              </h1>
              <router-link
                to="/admin/customer-management/"
                class="btn btn-dashboard btn-block"
                active-class="active"
                exact
                >{{ 'Manage Customers' | translate }}</router-link
              >

              <router-link
                to="/admin/company-management/"
                class="btn btn-dashboard btn-block"
                active-class="active"
                exact
                >{{ 'Manage Companies' | translate }}</router-link
              >
              <router-link
                to="/admin/invite-customer/"
                class="btn btn-dashboard btn-block"
                active-class="active"
                exact
                >{{ 'Invite A Customer' | translate }}</router-link
              >
              <router-link
                v-if="user && user.role === 'admin'"
                to="/admin/upload-forms/"
                class="btn btn-dashboard btn-block"
                active-class="active"
                exact
                >{{ 'Upload Forms' | translate }}</router-link
              >
              <router-link
                v-if="user && user.role === 'admin'"
                to="/admin/upload-translations/"
                class="btn btn-dashboard btn-block"
                active-class="active"
                exact
                >{{ 'Upload Translations' | translate }}</router-link
              >
              <router-link
                v-if="user && user.role === 'admin'"
                to="/admin/settings/"
                class="btn btn-dashboard btn-block"
                active-class="active"
                exact
                >{{ 'Settings' | translate }}</router-link
              >
              <router-link
                v-if="user && user.role === 'admin'"
                to="/admin/email-template/"
                class="btn btn-dashboard btn-block"
                active-class="active"
                exact
                >{{ 'Email Template' | translate }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '../../components/AppHeader';
import AppFooter from '../../components/AppFooter';
import { mapState } from 'vuex';
export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      user: state => state.users.user,
    }),
  },
};
</script>
