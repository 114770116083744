<template>
    <div>
        <!-- Assessor/Group Title -->
        <div class="row">
            <div class="col-md-20">
                <div class="d-flex flex-column flex-xl-row">
                    <h1 class="h1 h1--mb-small" v-if="Number(assessorTypeInfo.id) === 19">{{ assessorTypeInfo.name | translate }}</h1>
                    <h1 class="h1 h1--mb-small" v-else>{{ title | translate }}</h1>
                    <div class="assessor-description-wrapper d-flex align-items-center mt-0 mt-xl-1">
                        <span v-if="assessorTypeInfo && assessorTypeInfo.description && assessorTypeInfo.description.length > 0" class="assessor-description ml-0 ml-xl-3">{{ assessorTypeInfo.description[0] | translate }}</span>
                        <a href="#" class="hygiene__link" data-toggle="modal" data-target="#more">{{'More' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="more" tabindex="-1" role="dialog" aria-labelledby="more" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content edit-modal">
                    <div class="modal-body my-0 pt-0">
                        <h2 class="h2 py-2 text-center"> {{ assessorTypeInfo ? assessorTypeInfo.name : "Assessor Unknown" | translate }}</h2>
                        <div v-if="assessorTypeInfo && assessorTypeInfo.description && assessorTypeInfo.description.length > 0">
                            <p class="p--md" v-for="( description, index ) in assessorTypeInfo.description" :key="index">{{ description | translate }}</p>
                        </div>
                        <div class="row modal-bottom">
                            <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{ "Cancel" | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Assessor/Group Tabs desktop version-->
        <div v-if="$route.params.evaluationId && showNav" class="nav nav-tabs nav-fill d-none d-lg-flex row no-gutters">
            <div :class="[{'col-22' : total.length !== 4}, {'col-24' : total.length === 4}]">
                <div class="row no-gutters">
                    <li v-for="( item, index ) in first4"
                        class="nav-item nav-item--nav col-6"
                        :key="index"
                    >
                        <!-- routing for groups needs to be set up -->
                        <router-link
                                v-if="item.assessor && item.assessor.id == 19 && showOnlyGroupTabs"
                                :to="item.to + 'main'"
                                :class="['nav-link', 'nav-link--alt', {'mr-0' : index === (first4.length-1)}]"
                                active-class="active"
                        >
                            <status :assessor="item.assessor" border="white"></status>

                            <span>{{ item.name | translate }}</span>
                        </router-link>
                        <router-link
                                v-else
                                :to="item.to"
                                :class="['nav-link', 'nav-link--alt', {'mr-0' : index === (first4.length-1)}]"
                                active-class="active"
                        >
                            <status :assessor="item.assessor" border="white"></status>

                            <span>{{ item.name | translate }}</span>
                        </router-link>
                    </li>
                </div>
            </div>
            <div v-if="total.length !== 4" class="col-2">
                <a href="#" v-if="lastItems.length > 0" class="nav-link nav-link--alt ml-2 mr-0 text-center" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                    <img src="@/assets/icons/ellipsis.svg" alt="Ellipsis" height="20px">
                </a>
                <ul v-if="lastItems.length > 0" class="dropdown-menu dropdown-menu-right dropdown-menu--nav multi-level" role="menu" aria-labelledby="dropdownMenu" data-children=".dropdown-item">
                    <li v-for="( item, index ) in lastItems" class="dropdown-item p-0" :key="index">
                        <router-link v-if="item.assessor.id == 19 && showOnlyGroupTabs"
                                     :to="item.to + 'main'" class="nav-link" active-class="active" data-toggle="dropdown">
                            {{ item.name | translate }}
                        </router-link>
                        <router-link v-else :to="item.to" class="nav-link" active-class="active" data-toggle="dropdown">
                            {{ item.name | translate }}
                        </router-link>
                    </li>
                    <li class="dropdown-item p-0">
                        <router-link :to="'/users/' + $route.params.userId + '/evaluations/' + evaluation._id + '/groups'" class="nav-link">
                            {{ 'Add/Edit Groups' | translate }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- mobile version of dropdwon -->
        <div
            v-if="assessorTypeInfo.id !== '19' ||
            (assessorTypeInfo.id === '19' && (showOnlyGroupTabs || $route.query.assessor_nav !== 'assessor'))"
        >
            <div class="footbath-dropdown-link d-lg-none" href="javascript:;" role="button" @click="toggleMobileDropdown( $event )">
                {{ selectedItemName | translate}}
                <a class="fa fa-angle-down fa-angle-down--font-lg"></a>
            </div>
            <ul v-if="$route.params.evaluationId !== undefined" class="dropdown-menu dropdown-menu--footbath multi-level">
                <!-- mobileDropdownArray filters the current group -->
                <li v-for="( item, g_index ) in total" class="dropdown-item" :key="g_index" @click="toggleMobileDropdown( $event )">
                    <router-link
                            v-if="item.assessor && item.assessor.id == 19 && showOnlyGroupTabs"
                            @click="toggleMobileDropdown( $event )"
                            :to="item.to + 'main'"
                    >
                        <status :assessor="item.assessor" border="white"></status>
                        {{ item.name | translate }}
                    </router-link>
                    <router-link v-else :to="item.to" @click="toggleMobileDropdown( $event )">
                        <status :assessor="item.assessor" :border="'white'"></status>
                        {{ item.name | translate}}
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Status from '@/components/evaluations/status.vue';
    import Cookie from 'js-cookie'
import { mapState } from 'vuex';

    var $ = window.jQuery = require('jquery');
    $(document).click(function() {
        let dropdown = $('.dropdown-menu--footbath');
        if (dropdown.hasClass("dropped")) {
            dropdown.prev().children('a').removeClass("fa-angle-up");
            dropdown.prev().children('a').addClass("fa-angle-down");
        }
        dropdown.slideUp();
    });
    $(".dropdown-menu--footbath").click(function(e) {
        e.stopPropagation();
    });

    export default {
        name: 'HygieneHeader',
        components: {
            Status
        },
        props: [
            'assessors', 'groups', 'assessorId', 'showNav', 'showOnlyGroupTabs'
        ],
        data() {
            return {
                assessor: null,
                assessorTypeInfo: null,
                assessorUrl: null,
                assessorNav: null,
                by_assessor:false,
                currentGroup: null,
                // evaluation: null,
                group: null,
                mobileArray: [],
                noMargin: false,
                text: this.text,
                title:'',
                show: true,
                total: []
            }
        },
        watch: {
            '$route' (to, from) {
                this.initializeData();
            }
        },
        methods: {
            initializeData() {
                // console.log('assessor', this.assessor)
                this.assessorTypeInfo = this.$store.getters.getAssessorInfoByType(this.$route.params.assessorType);

                this.total = [];
                // this.evaluation = this.$store.getters.getData('users/' + this.$route.params.userId + '/evaluations/' + this.$route.params.evaluationId);
                // this.group = this.$store.getters.getData('users/' + this.$route.params.userId + '/evaluations/' + this.$route.params.evaluationId + '/groups/' + this.$route.params.groupId);
                this.group = this.evaluation.groups[this.$route.params.groupId]

                // If this is assessor page (group tabs should be displayed) ?
                this.assessorUrl = (Cookie.get('assessor_nav') && Cookie.get('assessor_nav') === 'assessor');
                this.assessorNav = Cookie.get('assessor_nav');
                // console.log('groupAssessors', this.group.assessors)
                if ( Array.isArray(this.group.assessors) ) {
                    this.assessor = this.group.assessors.find(assessor => {
                        return Number(assessor.type) === this.$route.params.assessorType;
                    });
                } else {
                    for (let assessorId in this.group.assessors) {
                        if(this.group.assessors[assessorId].type === this.$route.params.assessorType) {
                            this.assessor = this.group.assessors[assessorId];
                            break;
                        }
                    }
                }

                if(this.assessorUrl || this.showOnlyGroupTabs) {
                    const assessorTypeInfo = this.$store.getters.getAssessorTypeById(this.$route.params.assessorType);
                    this.title = assessorTypeInfo ? assessorTypeInfo.name : "Unknown Assessor";
                    this.by_assessor = true;

                    // TODO:
                    // let allGroups = this.$store.getters.getData('users/' + this.$route.params.userId + '/evaluations/' + this.$route.params.evaluationId + '/groups');
                    let allGroups = this.evaluation.groups;
                    if ( Array.isArray(allGroups) ) {
                        allGroups.forEach(group => {
                            if(group.assessor_list !== undefined) {

                                let matchedAssessor = this.getGroupAssessorByAssessorType(this.$route.params.assessorType, group);
                                console.log({matchedAssessor})
                                if(matchedAssessor !== false) {
                                    this.total.push({
                                        to: '/users/' + this.$route.params.userId + '/evaluations/' + this.evaluation._id + '/groups/' + group.id + '/assessors/' + this.$route.params.assessorType + '/',
                                        name: group.name,
                                        id: group.id,
                                        assessor: matchedAssessor,
                                        status: (matchedAssessor !== undefined) ? matchedAssessor.status : 0
                                    });
                                }
                            }
                        });
                    } else {
                        for ( let groupId in allGroups ) {
                            let group = allGroups[groupId];
                            if(group.assessor_list !== undefined) {
                                let matchedAssessor = this.getGroupAssessorByAssessorType(this.$route.params.assessorType, group);

                                if(matchedAssessor !== false) {
                                    this.total.push({
                                        to: '/users/' + this.$route.params.userId + '/evaluations/' + this.evaluation._id + '/groups/' + group.id + '/assessors/' + this.$route.params.assessorType + '/',
                                        name: group.name,
                                        id: group.id,
                                        assessor: matchedAssessor,
                                        status: (matchedAssessor !== undefined) ? matchedAssessor.status : 0
                                    });
                                }
                            }
                        }
                    }
                } else {
                    this.title = this.group.name;
                    this.by_assessor = false;
                    let order = this.getEvaluationOrder(this.evaluation);
                    let assessor;
                    order.forEach(assessorType => {
                        if (this.group.assessor_list.includes(assessorType)) {
                            if ( Array.isArray(this.group.assessors) ) {
                                assessor = this.group.assessors.find(assessor => {
                                  return assessorType === assessor.type;
                                });
                            } else {
                                for (let assessorId in this.group.assessors) {
                                    if(this.group.assessors[assessorId].type === assessorType) {
                                        assessor = this.group.assessors[assessorId];
                                        break;
                                    }
                                }
                            }

                            const assessorTypeInfo = this.$store.getters.getAssessorTypeById(assessorType);

                            if ( assessorTypeInfo && this.total.find(assessor => assessor.id === assessorType) === undefined ) {
                                this.total.push({
                                    to: '/users/' + this.$route.params.userId + '/evaluations/' + this.evaluation._id + '/groups/' + this.group.id + '/assessors/' + assessorType + '/',
                                    id: assessorType,
                                    name: assessorTypeInfo.name,
                                    assessor: _.clone(assessor)
                                });
                            }
                        }
                    })
                }

                if (this.first4.length === 1) {
                    this.noMargin = true;
                }
            },

            getGroupAssessorByAssessorType(assessorType, group) {
                // check if assessor type is in a groups assessor list
                let existsInAssessorList = group.assessor_list.find(groupAssessorType => {
                    return assessorType === groupAssessorType;
                });

                // if does not exist in assessor list return false
                if(existsInAssessorList === undefined)
                    return false;


                // return group assessor if matches assessorType
                if ( Array.isArray(group.assessors) ) {
                    return group.assessors.find(assessor => {
                        return assessor.type === assessorType;
                    });
                } else {
                    return group.assessors[assessorType];
                }
            },

            getFirstAssessorTypeByGroup(group) {
                return this.evaluation.order.find(assessorType => {
                    return group.assessor_list.find(groupAssessorType => {
                        return groupAssessorType === assessorType;
                    });
                });
            },

            toggleMobileDropdown(e) {
                let dropdown = $('.dropdown-menu--footbath');
                dropdown.toggleClass('dropped');
                if (dropdown.hasClass("dropped")) {
                    $(dropdown).prev().children('a').removeClass("fa-angle-down");
                    $(dropdown).prev().children('a').addClass("fa-angle-up");
                } else {
                    $(dropdown).prev().children('a').addClass("fa-angle-down");
                    $(dropdown).prev().children('a').removeClass("fa-angle-up");
                }

                dropdown.slideToggle();

                e.preventDefault();
                e.stopPropagation();
            },

            getEvaluationOrder(evaluation) {
                if (evaluation.order !== undefined && evaluation.order.length > 0) {
                    return evaluation.order;
                } else {
                    var self = this;
                    var assessorOrder = [];

                    if ( Array.isArray(evaluation.groups) ) {

                        evaluation.groups.forEach(function(group) {
                            group.assessor_list.forEach(function(assessorTypeId) {
                                let assessorTypeExists = assessorOrder.find(assessorType => {
                                    return assessorType.id === assessorTypeId;
                                });

                                if(assessorTypeExists === undefined) {
                                    assessorOrder.push(self.$store.state.assessorTypes.find(function(assessorType) {
                                        return assessorType.id === assessorTypeId;
                                    }).id);
                                }
                            });
                        });

                    } else {
                        for (let groupId in evaluation.groups) {
                            evaluation.groups[groupId].assessor_list.forEach(function(assessorTypeId) {
                                let assessorTypeExists = assessorOrder.find(assessorType => {
                                    return assessorType.id === assessorTypeId;
                                });

                                if(assessorTypeExists === undefined) {
                                    assessorOrder.push(self.$store.state.assessorTypes.find(function(assessorType) {
                                        return assessorType.id === assessorTypeId;
                                    }).id);
                                }
                            });
                        }
                    }


                    return assessorOrder;
                }
            }
        },

        computed: {
            ...mapState({
                evaluation: state => state.evaluations.evaluation
            }),
            adjusted_total() {
                let adjusted_total = _.clone(this.total);
                let first4 = adjusted_total.slice(0,4);
                if ( !this.by_assessor && !first4.find(assessor => assessor.id === this.$route.params.assessorType) ) {
                    let lastItems = adjusted_total.slice(4, adjusted_total.length);
                    let from = lastItems.findIndex(assessor => assessor.type === this.$route.params.assessorType);
                    adjusted_total.splice(0, 0, adjusted_total.splice(from, 1)[0]);
                } else if ( this.by_assessor && !first4.find(group => group.id === this.$route.params.groupId) ) {
                    let from = this.total.findIndex(group => group.id === this.$route.params.groupId);
                    adjusted_total.splice(0, 0, adjusted_total.splice(from, 1)[0]);
                }
                return adjusted_total.filter(item => item.name !== 'Unavailable Assessor') || [];
            },
            first4() {
                return this.adjusted_total.slice(0, 4);
            },
            lastItems() {
                return this.adjusted_total.slice(4, this.adjusted_total.length);
            },
            selectedItemName() {
                if ( this.by_assessor ) {
                    return this.total.find(item => item.id === this.$route.params.groupId).name;
                } else {
                    return this.total.find(item => item.id === this.$route.params.assessorType).name;
                }
            }
        },

        created() {
        //   console.log('showOnlyGroupTabs', this.showOnlyGroupTabs)
            this.initializeData();
        },
    }
</script>
