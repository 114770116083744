<template>
  <main :class="[$route.name]">
    <div class="app-wrapper">
      <div class="main-content">
        <app-header :key="$route.path" />
        <router-view ></router-view>
      </div>
      <app-footer />
      <multiple-tabs-overlay v-if="tabIsAlreadyOpen"></multiple-tabs-overlay>
    </div>
    <notifications position="top center" width="400" />
  </main>
</template>

<script>
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import MultipleTabsOverlay from "./components/MultipleTabsOverlay";
import fetchAllData from "@/mixins/fetchAllData";

import { mapActions, mapState } from "vuex";
import {
  GET_COUNTRIES,
  GET_CURRENCIES,
  GET_FILES,
  GET_STATES,
  GET_TRANSLATION,
  GET_USER_LOGGED_FROM_LOCAL_STORAGE,
  GET_COMPANIES,
  GET_EVALUATIONS,
  GET_USERS,
  GET_CLIENTS,
} from "./store/types";
import { get } from "lodash";
export default {
  components: {
    AppHeader,
    AppFooter,
    MultipleTabsOverlay,
  },
  mixins: [fetchAllData],

  data() {
    return {
      storageFileUrl: "",
      timeoutSave: null,
      guidesRef: null,
      dataCollectionsRef: null,
      tabIsAlreadyOpen: false,
    };
  },

  computed: {
    multipleTabs() {
      console.log(this.$tabs);
      return this.$tabs;
    },

    ...mapState({
      user: (state) => state.users.user,
      languageList: (state) => state.languageList,
      settingsList: (state) => state.settingsList,
    }),
  },

  watch: {
    $route(to, from) {
      if (
        (from.path === "/login" && to.path === "/") ||
        (from.path === "/verify-account" && to.path === "/")
      ) {
      }
    },
  },

  async mounted() {
    const role = localStorage.getItem("role");
    if (typeof this.$store.state.user === "undefined") {
      this.$store.state.dataLoaded = false;
    }
    if (!this.$store.state.dataLoaded) {
      this.$store.state.dataLoaded = true;
    }

    localStorage.openpages = Date.now();
    const onLocalStorageEvent = (e) => {
      if (e.key == "openpages") {
        // Emit that you're already available.
        localStorage.page_available = Date.now();
      }
      if (e.key == "page_available") {
        this.tabIsAlreadyOpen = true;
        // alert('One more page already open');
      }
    };
    window.addEventListener("storage", onLocalStorageEvent, false);
    console.log({ user: this.user.role });
    Promise.all([
      this[GET_EVALUATIONS](),
      this[GET_CLIENTS](),
      // if loggedIn user is customer so there is no sence to get users.
      ...(role === "data_entry" ? [] : [this[GET_USERS]()]),
    ]).then((evaluation, clients, users) => {
      this.populateLoggedInUserMedias();
      this.fetchImgsForClientEvaluationsAndClient(evaluation, clients, users);
      this.$forceUpdate();
    });
  },

  async created() {
    try {
      if (localStorage.getItem("token")) {
        document.addEventListener("swUpdated", this.updateAvailable, {
          once: true,
        });
      }

      // this will download and setup the language files in our app
      this.fetchFiles().then(() => {
        this[GET_COMPANIES]();
        this.fetchAllTranslationFiles();
        this.fetchOtherFiles();
      });
      // this will download and setup the the countries and  states files
    } catch (error) {
      console.log(error, "in the the app.vue component ");
      localStorage.clear();
      this.$router.push("/login");
    }
  },
  methods: {
    // map all the actions from store, into the components
    ...mapActions("users", [GET_USER_LOGGED_FROM_LOCAL_STORAGE, GET_USERS]),
    ...mapActions("medias", [
      GET_FILES,
      GET_TRANSLATION,
      GET_COUNTRIES,
      GET_STATES,
      GET_CURRENCIES,
    ]),
    ...mapActions("company", [GET_COMPANIES]),

    loggedInUser() {
      return new Promise((resolve, reject) => {
        const user = JSON.parse(localStorage.getItem("user"));
        this[GET_USER_LOGGED_FROM_LOCAL_STORAGE](user);
        resolve();
      });
    },

    // THIS FUNCTION WILL FETCH THE COUTRIES, STATES, AND CURRENCY
    fetchOtherFiles() {
      const countryURL = get(
        this.settingsList[1],
        "metadata.customMetadata.fileURL",
        ""
      );
      const stateURL = get(
        this.settingsList[2],
        "metadata.customMetadata.fileURL",
        ""
      );
      const currencyURL = get(
        this.settingsList[0],
        "metadata.customMetadata.fileURL",
        ""
      );
      Promise.all([
        this[GET_COUNTRIES](countryURL),
        this[GET_STATES](stateURL),
        this[GET_CURRENCIES](currencyURL),
      ]);
    },

    fetchAllTranslationFiles() {
      const fetchFilesPromises = this.languageList
        .map(({ code, metadata }) => {
          const FILE_URL = get(
            metadata,
            "customMetadata.fileURL",
            ""
          );
          if (!FILE_URL) return;
          return this[GET_TRANSLATION]({
            CODE: code,
            FILE_URL,
            $app: this,
          });
        })
        .filter(Boolean);

      Promise.all(fetchFilesPromises);
    },

    async fetchFiles() {
      // creating the promises for getting all the files listed in the array.
      return Promise.all(
        ["settings", "language", "dataCollection", "guide"].map(
          (el) => this[GET_FILES](el)
        )
      );
    },

    updateAvailable(event) {
      console.log("[updateAvailable]", event);
      this.$notify({
        title: this.$t("Update is available!"),
        text: this.$t("Updating FirstStep, please wait a moment..."),
        duration: 5000,
      });
    },
  },
};
</script>
