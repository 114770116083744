export const create = (self, evaluationData) => {
    const ROWS_ON_PAGE = 13
    let tocSlides = []
    tocSlides.push(self.pptx.addSlide('Slide master'))
    let tocRowsCount = 0
    evaluationData.report.assessors.forEach(assossorId => {
        let assessorGroupsCount = 0
        evaluationData.report.groups.forEach(groupId => {
            let rowSize = 1
            switch (assessorGroupsCount) {
                case 0:
                    rowSize = 1
                    break;
                case 1:
                    rowSize = .9
                    break;
                default:
                    rowSize = .7
                    break;
            }
            if (evaluationData.groups[groupId].assessor_list.includes(assossorId)) {
                assessorGroupsCount += rowSize
            }
        })
        tocRowsCount += assessorGroupsCount
    })
    tocRowsCount = Math.round(tocRowsCount)
    if (tocRowsCount > ROWS_ON_PAGE) {
        let tocPagesCount = Math.round(tocRowsCount / ROWS_ON_PAGE)
        for (let i = 0; i < tocPagesCount; i++) {
            tocSlides.push(self.pptx.addSlide('Slide master'))
        }
    }

    const calcGeneratePageTableOfContents = (y, toc, content, page = 1) => {
        for (var i = 0; i < toc.length; i++) {
            if ( toc[i] === undefined ) continue;
            y += 0.5;
            if (toc[i].children.length > 1) {
                for (var j = 0; j < toc[i].children.length; j++) {
                    if (j === 0) {
                        y += 0.42;
                    } else {
                        y += 0.33;
                    }
                }
            }

            if (y > 8.5) {
                page += 1
                y = (content !== null && content !== '') ? 1.95 : 1.45;
            }

        }
        return page
    }
    const generate = (slide, y, toc, content, calcTablePage = 0, slideNumber = 0) => {
        for (var i = 0; i < toc.length; i++) {

            if ( toc[i] === undefined ) continue;

            y += 0.5;

            if (y > 8) {
                // calcTablePage += 1
                let slide = tocSlides[slideNumber + 1] //self.pptx.addNewSlide('Slide master');
                slide.tableOfContents = true
                slide.addText(app.$t('Table of Contents'), self.styleOptions.slideHeader);

                // if executive summary hasn't been filled out, do not show
                // if (content !== null && content !== '') {
                //     slide.addText(
                //         app.$t('Executive Summary'), {
                //         x: 0.5,
                //         y: 1.95,
                //         h: 1,
                //         color: '000000',
                //         fontSize: 16,
                //         fontFace: 'Arial',
                //         bold: true
                //     }
                //     );
                //
                //     slide.addText(
                //         '2', {
                //         x: 7.5,
                //         y: 1.95,
                //         h: 1,
                //         color: '000000',
                //         fontSize: 16,
                //         fontFace: 'Arial',
                //         bold: true
                //     }
                //     );
                // }

                let y = 1.45 // (content !== null && content !== '') ? 1.95 : 1.45;
                let nextToc = toc.slice(i)
                generate(slide, y, nextToc, content, calcTablePage, slideNumber + 1)
                return
            }

            slide.addText(
                app.$t(toc[i].title), {
                    y: y,
                    ...self.styleOptions.tocAssessorTitle
                }
            );

            slide.addText(
                toc[i].page + 1, { // + calcTablePage, {
                    y: y,
                    ...self.styleOptions.tocAssessorPage
              }
            );

            if (toc[i].children.length > 1) {

                for (var j = 0; j < toc[i].children.length; j++) {

                    if (j === 0) {
                        y += 0.42;
                    } else {
                        y += 0.33;
                    }

                    slide.addText(
                        toc[i].children[j].title, {
                            y: y,
                            ...self.styleOptions.tocGroupTitle
                        }
                    );

                    slide.addText(
                        toc[i].children[j].page + 1, { // + calcTablePage, {
                            y: y,
                            ...self.styleOptions.tocGroupPage
                        }
                    );

                }

            }

        }
    }

    return self.tableOfContents = function (toc, title, content) {
        let slide = tocSlides[0]
        slide.tableOfContents = true
        slide.addText(app.$t('Table of Contents'), self.styleOptions.slideHeader);

        // if executive summary hasn't been filled out, do not show
        if (content !== null && content !== '') {
            slide.addText(
                app.$t('Executive Summary'), self.styleOptions.tocExecutiveSummaryTitle
            );

            slide.addText(
                toc[0].page, self.styleOptions.tocExecutiveSummaryPage
            );
        }

        var y = (content !== null && content !== '') ? 1.95 : 1.45;
        const calcTablePage = calcGeneratePageTableOfContents(y, toc, content)
        generate(slide, y, toc, content, calcTablePage, 0)
        return true;
    };
}
