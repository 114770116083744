import axios from 'axios';
import _ from 'lodash';
// The function below is used to create the initial assessor object the Groups.vue and assessor-base.vue
// @TODO Each of the following assessors should not have a Generated ID. Sense we only are allowed to have one type of assessor per group, we can better protect our app by only ever allowing one type of assessor to be stored in the database.
export const initializeAssessorDataByType = (self, type) => {
  switch (type) {
    case '1':
      return {
        data: {
          bedding_type: null,
          cows_dry_lot: null,
          cows_in_pen: null,
          frequency_of_manure_removal: null,
          hygiene_score_1: 0,
          hygiene_score_2: 0,
          hygiene_score_3: 0,
          hygiene_score_4: 0,
          summer_manure_removal_method: null,
          winter_manure_removal_method: null,
          isReported: false,
        },
        id: '1',
        type: '1',
        isReported: false,
      };
    case '2':
      return {
        data: {
          bedding_type: 'concrete',
          bedding_depth: null,
          bob_zone: null,
          brisket_locator_height: null,
          brisket_locator_properly_locate_cows: null,
          copyField: 'stallWidth',
          concrete_fill: null,
          curb_to_brisket_locator_distance: null,
          divider_type: 'cantilever',
          freestall_personal_notes: null,
          freestall_presentation_notes: null,
          freestall_type: 'analyze_existing',
          hock_lesions: null,
          inside_loop: null,
          knee_drop_test: null,
          lower_divider_height: null,
          neckrail_height: null,
          neckrail_rear_curb: null,
          rear_curb: null,
          rear_curb_height: null,
          stall_alignment: 'one',
          stall_length: null,
          stall_length_single_row: null,
          stall_width: null,
          surface_dry: null,
          isReported: false,
        },
        id: '2',
        type: '2',
        isReported: false,
      };
    case '3':
      return {
        data: {
          cows_in_group: null,
          currency: null,
          date: null,
          economics: '0',
          eval_name: null,
          group_name: null,
          lastIndex: false,
          locomotion_type: 1,
          locomotion_score_1: 0,
          locomotion_score_2: 0,
          locomotion_score_23: 0,
          locomotion_score_3: 0,
          locomotion_score_4: 0,
          locomotion_score_5: 0,
          locomotion_score_45: 0,
          locomotion_target_1: 40,
          locomotion_target_2: 40,
          locomotion_target_3: 15,
          locomotion_target_4: 5,
          locomotion_target_5: 0,
          locomotion_target_45: 5,
          production_type: 'confined_cattle',
          suggested_sample_size: null,
          isReported: false,
        },
        id: '3',
        type: '3',
        isReported: false,
      };
    case '4':
      return {
        data: {
          bedding_type: null,
          cows_only_standing: 0,
          cows_standing_or_lying_in_stall: 0,
          milked_per_hour: 0,
          milkings_per_day: 0,
          other_bedding_type: null,
          production_type: 'confined_cattle',
          quantity_of_grain_supplements: null,
          stalls_available_in_pen: 0,
          personal_notes: null,
          presentation_notes: null,
          time_held_from_paddocks: null,
          type_production: null,
          soft_bedding: null,
          walking_time_per_day: null,
          isReported: false,
        },
        id: '4',
        type: '4',
        isReported: false,
      };
    case '11':
      return {
        data: {
          holding_area_size: null,
          group_pen_size_in_holding_area: null,
          do_cows_spend_more_than_3_hours_day_on_average_in_the_holding_area: null,
          are_first_lactation_heifers_mixed_with_mature_cows_in_the_holding_area: null,
          is_the_crowd_gate_used_appropriately: null,
          is_a_large_proportion_more_than_30_of_cows_with_their_heads_up_rather_than_down_looking_at_the_ground: null,
          is_the_holding_area_floor_excessively_sloped: null,
          is_there_any_slipping_as_the_cows_move_between_each_other_that_may_traumatize_the_hoof: null,
          are_recirculating_fans_in_the_holding_area: null,
          fan_temperature_that_the_soakers_activate: null,
          fan_width_1: null,
          fan_width_2: null,
          fan_width_3: null,
          number_of_fans_1: null,
          number_of_fans_2: null,
          number_of_fans_3: null,
          group_pen_size_in_holding_rea: null,
          personal_notes: null,
          presentation_notes: null,
          are_soakers_misters_in_the_holding_area: null,
          soakers_temperature_that_the_soakers_activate: null,
          soakers_duration: null,
          soakers_frequency: null,
          is_there_shade_over_the_holding_area: null,
          isReported: false,
        },
        id: '11',
        type: '11',
        isReported: false,
      };
    case '14':
      return {
        data: {
          are_cows_fed_more_than_of_concentrate_grain_in_the_parlor: null,
          are_grazing_cows_held_off_pasture_for_prolonged_periods_other_than_milking_time: null,
          are_feed_delivery_and_pushups_timed_to_maximize_feed_access: null,
          are_there_any_empty_feed_bunks_after_milking: null,
          availa_4: null,
          availa_cu_100: null,
          availa_mn_80: null,
          availa_zn_120: null,
          availa_zn_40: null,
          availa_plus: null,
          feed_space: null,
          percentage_of_group_lying_down_chewing_cud: null,
          time_of_lying_down_chewing_cud_assessment: null,
          dry_matter: null,
          tmr_top_screen: null,
          tmr_middle_screen: null,
          tmr_bottom_screen: null,
          tmr_pan: null,
          rumination_time_per_day: null,
          is_there_a_highly_fermentable_carbohydrate_feed_delivered_separate_at_any_time: null,
          if_rumenocentesis_has_been_performed_does_the_best_indicate_a_problem_with_sara: null,
          is_a_complete_nutrient_workup_required: null,
          dry_matter_intake: null,
          cu_ppm: null,
          cu_ppm_select: null,
          mn_ppm: null,
          mn_ppm_select: null,
          co_ppm: null,
          co_ppm_select: null,
          i_ppm: null,
          i_ppm_select: null,
          se_ppm: null,
          se_ppm_select: null,
          fe_ppm: null,
          fe_ppm_select: null,
          mo_ppm: null,
          biotin: null,
          buffer: null,
          buffer_imp: null,
          forage_ndf: null,
          ndf: null,
          nfc: null,
          adf: null,
          p: null,
          cp: null,
          ca: null,
          mg: null,
          s: null,
          k: null,
          zn_ppm: null,
          zn_ppm_select: null,
          four_plex_c: null,
          copro_25: null,
          cuplex_100: null,
          cuplex_50: null,
          manpro_80: null,
          zinpro_120: null,
          zinpro_180: null,
          zinpro_40: null,
          personal_notes: null,
          presentation_notes: null,
          isReported: false,
        },
        id: '14',
        type: '14',
        isReported: false,
      };
    case '13':
      return {
        data: {
          are_cows_allowed_out_of_the_barn_at_night_to_cool_off: null,
          are_soakers_misters_used_to_help_cool_cows_off: null,
          barn_ceiling_height: null,
          barn_length: null,
          barn_width: null,
          fan_width_1: null,
          fan_width_2: null,
          fan_width_3: null,
          is_there_shade_over_outside_feed_area: null,
          inlet_area: null,
          number_of_fans_1: null,
          number_of_fans_2: null,
          number_of_fans_3: null,
          personal_notes: null,
          presentation_notes: null,
          summer_ventilation_system: 'cross',
          isReported: false,
        },
        id: '13',
        type: '13',
        isReported: false,
      };
    case '15':
      return {
        data: {
          primary_hoof_trimmer: null,
          trimming_frequency: null,
          trimmer_visit_frequency: null,
          number_of_trims_recorded_per_year: null,
          percentage_of_cows_in_herd_with_locomotion_scores_of_4_and_5: null,
          are_heifers_trimmed_prior_to_calving: null,
          are_lame_cows_treated_by_hoof_trimmer: null,
          at_what_locomotion_score_are_the_dairy_personnel_trained_to_identify_cows_in_need_of_treatment: null,
          does_dairy_personnel_actively_identify_lame_cows_for_treatment_as_soon_as_they_occur: null,
          does_the_hoof_trimmer_like_working_with_cows: null,
          does_the_hoof_trimmer_handle_cows_gently: null,
          heifers_in_group_number: null,
          heifers_in_group_percent: null,
          personal_notes: null,
          presentation_notes: null,
          is_the_trimming_area_a_pleasant_place_to_work_in_all_weather_conditions: null,
          is_shade_available: null,
          is_water_available: null,
          is_feed_available: null,
          has_the_hoof_trimmer_received_formal_training: null,
          does_the_hoof_trimmer_use_a_lesion_recording_system_with_standard_definitions_for_hoof_lesions: null,
          is_there_a_noticeable_increase_in_new_cases_of_lameness_within_a_week_of_routine_trimming: null,
          are_toes_trimmed_to_maintain_the_top_front_dorsal_hoof_wall_at_a_length_of_or_greater: null,
          does_the_hoof_trimmer_spare_the_height_of_the_medial_claw_heel_inside_claw_of_the_hind_foot_in_particular_to_the_heel: null,
          is_the_inside_wall_axial_wall_or_toe_of_the_inner_or_outer_wall_removed: null,
          are_claws_trimmed_so_that_the_sole_surface_is_flat_rather_than_concave: null,
          is_the_outer_wall_abaxial_wall_removed_from__the_toe_and_side_wall_excessively: null,
          is_the_sole_trimmed_excessively: null,
          isReported: false,
        },
        id: '15',
        type: '15',
        isReported: false,
      };
    case '17':
      return {
        data: {
          axial_wall_crack_lame: null,
          axial_wall_crack_trim: null,
          corkscrew_claw_trim: null,
          corkscrew_claw_lame: null,
          deep_digital_sepsis_trim: null,
          deep_digital_sepsis_lame: null,
          digital_dermatitis_trim: null,
          digital_dermatitis_lame: null,
          horizontal_fissure_trim: null,
          horizontal_fissure_lame: null,
          heel_horn_erosion_trim: null,
          heel_horn_erosion_lame: null,
          injury_trim: null,
          injury_lame: null,
          interdigital_dermatitis_trim: null,
          interdigital_dermatitis_lame: null,
          interdigital_hyperplasia_trim: null,
          interdigital_hyperplasia_lame: null,
          interdigital_phlegmon_foot_rot_trim: null,
          interdigital_phlegmon_foot_rot_lame: null,
          number_of_blocks_applied: null,
          number_of_wraps_applied: null,
          personal_notes: null,
          presentation_notes: null,
          sole_ulcer_lame: null,
          sole_ulcer_trim: null,
          sole_fracture_heel_ulcer_lame: null,
          sole_fracture_heel_ulcer_trim: null,
          sole_hemorrhage_bruise_lame: null,
          sole_hemorrhage_bruise_trim: null,
          toe_ulcer_trim: null,
          toe_ulcer_lame: null,
          thin_sole_trim: null,
          thin_sole_lame: null,
          vertical_fissure_trim: null,
          vertical_fissure_lame: null,
          what_type_of_lesions_do_you_want_to_enter: 'both',
          white_line_disease_trim: null,
          white_line_disease_lame: null,
          isReported: false,
        },
        id: '17',
        type: '17',
        isReported: false,
      };
    case '18':
      return {
        data: {
          are_cows_being_excessively_pushed_over_steps: null,
          are_steps_in_the_path_of_movement_between_the_pen_and_milking_parlor: null,
          crossover_alley: [],
          does_herdsperson_allow_the_cows_to_walk_at_their_own_pace_to_from_the_milking_parlor: null,
          feed_alley: [],
          feed_alley_width: null,
          flooring_type: null,
          holding_area: [],
          is_the_distance_from_cow_pens_to_milking_center_greater_than: null,
          is_the_rubber_surface_grooved_or_finished_to_minimize_cow_slipping: null,
          is_there_any_wire_or_sharp_metal_protruding_from_the_rubber_surface: null,
          number_of_90_degree_turns: null,
          parlor: [],
          parlor_return_lanes: [],
          passage_alley_width: null,
          personal_notes: null,
          presentation_notes: null,
          stall_alley: [],
          steps_between_pen_and_milking_parlor: [],
          transfer_lanes: [],
          isReported: false,
        },
        id: '18',
        type: '18',
        isReported: false,
      };
    case '9':
      return {
        data: {
          above_water_table: null,
          allows_cows_time_to_navigate_obstacles: null,
          any_scouring_on_steep_section_diversion_bars_not_in_place: null,
          any_track_junctures_to_lower_quality_surfaces: null,
          any_puddles_wet_or_muddy_areas: null,
          are_cows_pushed: null,
          are_exit_surfaces_capped_dry_well_drained_and_firm: null,
          are_there_any_materials_bags_trash_etc_in_or_beside_the_track_that_cows_avoid_or_reduce_effective_track_width: null,
          are_there_pot_holes_present: null,
          are_stones_brought_onto_yards: null,
          are_water_and_feed_troughs_away_from_exits: null,
          auto_gate_latches_used: null,
          corners_replaced_by_curved_corners: null,
          crowned_or_tilted: null,
          distance_between_herdsman_and_tail_ender_cows: null,
          do_cows_have_to_turn_to_enter_the_yard: null,
          documented_maintenance_plan_adhered_to: null,
          does_the_race_pass_the_human_walk_test: null,
          emergency_repairs_carried_out_ASAP: null,
          excellent_drainage: null,
          firm_smooth_non_slip_surface: null,
          furthest_distance_from_paddock_to_milking_shed: null,
          gates_angled_at_race: null,
          gate_width_race_width: null,
          gravel_surface: null,
          is_base_layer_compacted_and_dry: null,
          is_full_width_available: null,
          is_surface_slippery_when_wet: null,
          is_top_layer_smooth_and_compacted: null,
          is_the_free_choice_walking_speed_of_cows: null,
          is_the_terminal_extra_wide: null,
          is_the_yard_entrance_width_race_width: null,
          machine_compacted: null,
          no_yelling_claxons_revving_motor: null,
          personal_notes: null,
          presentation_notes: null,
          track_width: null,
          isReported: false,
        },
        id: '9',
        type: '9',
        isReported: false,
      };
    case '12':
      return {
        data: {
          barn_eave_height: null,
          barn_is_free_of_wind_shadows: null,
          barn_length: null,
          barn_width: null,
          fifty_percent_of_side_wall_can_be_opened: null,
          inlet_area: null,
          long_axis_orientation_of_the_barn: null,
          personal_notes: null,

          fan_width_1: null,
          fan_width_2: null,
          fan_width_3: null,
          number_of_fans_1: null,
          number_of_fans_2: null,
          number_of_fans_3: null,
          
          fan_width_natural_1: null,
          fan_width_natural_2: null,
          fan_width_natural_3: null,
          number_of_fans_natural_1: null,
          number_of_fans_natural_2: null,
          number_of_fans_natural_3: null,

          presentation_notes: null,
          prevailing_wind_direction: null,
          ridge_opening_width: null,
          roof_height: null,
          summer_ventilation_system: 'natural',
          isReported: false,
        },
        id: '12',
        type: '12',
        isReported: false,
      };
    case '10':
      return {
        data: {
          average_cow_size: null,
          bedding_depth: null,
          distance_of_trainers_from_rear_curb: null,
          does_the_stall_pass_the_knee_drop_test: null,
          headrail_to_rear_curb: null,
          height_of_drinking_cup_floor_above_stall_surface: null,
          height_of_feed_curb_bunk_side: null,
          height_of_feed_curb_stall_side: null,
          height_of_headrail_above_stall_surface: null,
          height_of_trainer_above_wither: null,
          is_there_an_obstruction_above_drinking_cups: null,
          is_there_a_bob_zone_obstruction: null,
          length_of_chain: null,
          personal_notes: null,
          presentation_notes: null,
          rear_edge_of_divider_to_curb: null,
          stall_surface: null,
          stall_length: null,
          stall_width: null,
          isReported: false,
        },
        id: '10',
        type: '10',
        isReported: false,
      };
    case '16':
      return {
        data: {
          accessible_water_trough_perimeter_in_pen: null,
          is_drinking_water_readily_available_to_cows_exiting_the_milking_parlor: null,
          is_the_area_around_the_water_trough_free_of_mud_stones_etc: null,
          are_fans_located_in_the_feed_bunk_area: null,
          feed_bunk_area_are_hvls_fans_used: null,
          feed_bunk_area_temperature_fans_activate: null,
          feed_bunk_area_fan_diameter: null,
          feed_bunk_area_spacing: null,
          are_fans_located_in_the_resting_area_paddock: null,
          resting_area_paddock_are_hvls_fans_used: null,
          resting_area_paddock_temperature_fans_activate: null,
          resting_area_paddock_fan_diameter: null,
          resting_area_paddock_spacing: null,
          personal_notes: null,
          presentation_notes: null,
          are_soakers_misters_located_in_the_feed_bunk_area: null,
          are_there_soakers_misters_located_in_the_resting_area_paddock: null,
          does_water_droplet_effectively_soak_cows: null,
          temperature_soakers_misters_activate: null,
          frequency: null,
          duration: null,
          does_the_frequency_of_soaking_vary_depending_upon_temperature: null,
          is_there_shade_over_the_feed_bunk_area: null,
          is_there_shade_over_the_resting_area_paddock: null,
          are_air_cooling_units_such_as_shade_tracker_or_koral_kool_used_below_shade: null,
          isReported: false,
        },
        id: '16',
        type: '16',
        isReported: false,
      };
    case '7':
      return {
        data: {
          can_cows_access_water_troughs_from_the_bedded_area: null,
          does_the_bedded_area_drain_well: null,
          how_frequently_is_the_whole_bed_removed: null,
          how_much_fresh_bedding_is_added: null,
          in_aerobic_barns_how_frequently_is_the_pack_stirred: null,
          is_the_pack_managed_aerobically_or_anaerobically: 'aerobic',
          is_the_bedded_area_separated_from_the_concrete_feeding_area_by_a_bedding_retainer: null,
          is_the_concrete_feeding_area_scraped_at_least_twice_a_day: null,
          is_the_area_well_ventilated_with_fans_over_the_bedded_area: null,
          what_is_the_bedding_area_per_cow: null,
          long_axis_orientation_of_the_barn: null,
          how_frequently_is_fresh_bedding_added: null,
          what_percentage_of_cows_have_score_of_three_or_four: null,
          what_is_the_temperature_of_the_bedded_pack: null,
          how_frequently_is_the_whole_or_partial_bed_removed: null,
          what_is_the_width_of_the_feed_alley: null,
          is_the_bed_stirred_to_the_proper_depth: null,
          is_pack_excessively_wet_around_access_points: null,
          is_there_a_smell_of_ammonia_present_in_the_barn: null,
          does_the_pack_have_the_correct_moisture_consistency: null,
          does_the_pack_raise_dust_when_the_animals_walk: null,
          is_there_evidence_of_liquid_pooling_in_the_barn: null,
          is_the_bedding_soft_and_comfortable: null,
          what_is_the_winds_speed_created_by_the_fans: null,
          isReported: false,
        },
        id: '7',
        type: '7',
        isReported: false,
      };
    case '6':
      return {
        data: {
          is_the_dairy_bringing_any_cattle_onto_the_dairy: null,
          are_cattle_from_the_dairy_shown_at_fairs_and_cattle_shows: null,
          are_returning_cattle_or_purchased_cattle_footbathed_before_commingling_with_the_main_herd: null,
          is_the_hoof_trimmers_equipment_always_clean: null,
          does_the_herd_test_for_bvd_pi_cattle: null,
          have_any_pi_cattle_been_identified_in_the_last_12_months: null,
          isReported: false,
        },
        id: '6',
        type: '6',
        isReported: false,
      };
    case '5':
      // @TODO set up Locomotion backend:
      return {
        data: {
          corral_area_per_cow: null,
          at_least_one_headlock_per_cow: null,
          concrete_feeding_area_scraped_at_least_once_per_day: null,
          bedding_beneath_free_of_wet_manure_contamination: null,
          at_least_3_inches_of_loose_manure_in_lying_area: null,
          lying_area_pass_knee_drop_test: null,
          isReported: false,
        },
        id: '5',
        type: '5',
        isReported: false,
      };
    case '19': {
      return {
        data: {
          resting_space: null,
          // Transition Performance Parameters
          projected_first_test_305: null,
          projected_first_test_305_kg: null,
          projected_first_test_305_lb: null,
          peak_ratio: null,
          first_test_fat_protein_ratio_1: null,
          first_test_fat_protein_ratio_2: null,
          scc_at_first_test_1: null,
          scc_at_first_test_2: null,
          stillbirths_1: null,
          stillbirths_2: null,
          dry_period_cure_rate: null,
          turnover_sold_died: null,
          deaths: null,

          // Herd Disease Rate (%)
          clinical_milk_fever: 0,
          retained_placenta: 0,
          metritis: 0,
          displaced_abomasum: 0,
          pneumonia: 0,
          enteritis: 0,
          ketosis: 0,

          // Facilities & Management
          length_of_dry_period_days: null,
          length_in_calving_pen: null,
          calvings_per_day: null,
          maximum_variation_in_calvings_per_month: null,
          close_up_heifers_mixed_with_cows: null,
          how_many_moves_into_close_up_pen: null,
          move_into_calving_pen: null,
          how_are_sick_cows_managed: null,
          close_up_days: null,
          fresh_days: null,
          yield_305_days: null,
          // main
          troughs_per_group: null,
          lying_space: null,
          stalls_per_cow: null,
          water_trough_length: null,
          feed_bunk_space: null,
          bunk_divider_type: null,
          lying_space: null,
          freestall_surface_cushion: null,
          freestall_occupation_area: null,
          freestalls_lunge_obstruction: null,
          heat_abatement: null,
          body_condition_score_1: null,
          body_condition_score_2: null,
          tmr_sorting: null,
          time_spent_in_lockups: null,
          screening_method: null,
          isReported: false,
        },
        id: '19',
        type: '19',
        isReported: false,
      };
    }
    case '20':
      return {
        data: {
          yard_shape: 'rectangular',
          yard_size_ft_wide: null,
          yard_size_ft_long: null,
          yard_diameter: null,
          milking_shed_type: null,
          fresians: null,
          jerseys: null,
          jf_rossbreed: null,
          are_there_any_sharp_protruding_parts: null,
          are_there_any_projections_in_hipbone_or_shoulder_range: null,
          is_there_protection_around_solitary_pipes_objects: null,
          is_there_a_clear_field_of_view_for_cows_exiting: null,
          is_the_yard_entrance_at_least_as_wide_as_the_race_leading_to_the_yard: null,
          is_there_a_4_inch_high_curb_installed_in_the_race_to_yard_entrance: null,
          is_a_rubber_mat_installed_in_the_yard_entrance: null,
          is_the_yard_bail_entrance_straight_lined: null,
          is_the_yard_bail_entrance_cow_sized_30_32_inches: null,
          is_the_entrance_funneled_with_an_angle_less_than_45_and_3_4_meters_long: null,
          is_the_last_10_13_feet_of_surrounding_yard_fence_of_solid_material_55_inches_high: null,
          does_the_breastrail_miss_the_shoulder_joints: null,
          does_the_rumprail_sit_between_the_top_of_udder_and_bottom_of_the_vulva: null,
          rumprails: null,
          are_rumprails_set_in_by_25_50_mm: null,
          are_the_inside_corners_of_the_rumprails_over_the_pit_edge: null,
          do_the_cows_fit_comfortably_into_the_bail: null,
          do_cows_fit_well_into_the_first_bail: null,
          is_an_anti_jumpbar_mounted: null,
          is_the_anti_jumpbar_in_the_correct_location: null,
          is_a_steel_bar_or_concrete_curb_along_the_pit_edge: null,
          is_the_surface_made_of_non_slip_material: null,
          is_there_an_excessive_amount_of_dung_in_the_milking_shed: null,
          is_the_surface_kept_clean_free_of_stones_and_debris_during_milking: null,
          herringbone_do_cows_exit_in_a_straight_line_from_bails: null,
          herringbone_can_the_exit_race_contain_a_full_row_of_cows: null,
          drop: null,
          length: null,
          approximate_yard_slope: null,
          are_there_cow_ramps: null,
          are_there_steps: null,
          is_the_surface_rough_with_a_pronounced_aggregate: null,
          is_the_surface_slippery: null,
          is_algae_present_on_any_surface: null,
          is_the_yard_clean_when_not_in_use_especially_free_of_stones: null,
          are_cows_fed_in_the_shed: null,
          are_bulls_kept_out_of_the_yard_at_all_times: null,
          are_any_biting_dogs_present: null,
          are_non_biting_dogs_kept_at_a_no_flight_distance_from_cow: null,
          do_cows_have_low_flight_distance: null,
          are_young_cows_separated_from_mature_cows: null,
          does_the_milker_hit_the_cows: null,
          does_the_milker_make_sudden_unexpected_movements: null,
          are_there_any_sudden_noises: null,
          does_the_milker_leave_the_shed_to_get_cows: null,
          is_the_shed_well_lit_with_roof_panels_windows_and_or_an_artificial_light_source: null,
          is_the_exit_path_poorly_lit_or_shaded_by_a_wall_shrubs_or_other_objects: null,
          is_the_fence_unit_switched_off_during_milking: null,
          the_shed_has_been_checked_for_stray_voltage_by_a_competent_operator_and_remedied_where_necessary: null,
          are_there_any_signs_of_cows_that_are_reluctant_to_enter_certain_areas: null,
          is_the_backing_gate_electrified: null,
          is_the_backing_gate_used_to_fill_up_space_behind_the_cows_it_is_not_pushing_on_them_and_the_cows_are_not_leaning_back: null,
          is_the_backing_gate_speed_excessive: null,
          is_the_backing_gate_used_in_the_first_15_minutes_of_milking: null,
          are_the_cows_heads_up: null,
          is_the_buzzer_bell_working_in_conjunction_with_backing_top_gate: null,
          is_the_top_gate_used_to_bring_up_subgroups_of_cows: null,
          do_cows_escape_through_top_gate: null,
          do_milkers_have_an_excellent_view_of_the_yard_from_the_platform_enabling_milkers_to_be_aware_of_cow_behavior_and_positions_of_the_backing_and_top_gates: null,
          personal_notes: null,
          presentation_notes: null,
          rotary_only_are_hinged_dividers_installed: null,
          rotary_only_is_the_anti_jam_gate_installed_on_the_entry_side: null,
          exit_race_is_the_surface_made_of_non_slip_material: null,
          rotary_is_a_rubber_mat_installed_at_the_cows_turning_point: null,
          rotary_do_cows_turn_freely_without_bending_their_backbone_or_neck: null,
        },
        id: '20',
        type: '20',
        isReported: false,
      };
    default:
      throw 'ERROR: Assessor Type does not exist';
  }
};

export const evaluationArrayToObj = evaluation => {
  if (evaluation.groups) {
    if (Array.isArray(evaluation.groups)) {
      let groups = {};
      for (let i = 0; i < evaluation.groups.length; i++) {
        if (evaluation.groups[i]) {
          let group = evaluation.groups[i];
          if (group.assessors) {
            if (Array.isArray(group.assessors)) {
              let assessors = {};
              for (let j = 0; j < group.assessors.length; j++) {
                if (group.assessors[j]) {
                  let assessor = group.assessors[j];
                  if (assessor.id === '1') {
                    if (assessor.footbaths) {
                      if (Array.isArray(assessor.footbaths)) {
                        let footbaths = {};
                        for (let k = 0; k < assessor.footbaths.length; k++) {
                          let footbath = assessor.footbaths[k];
                          if (footbath.mixes) {
                            if (Array.isArray(footbath.mixes)) {
                              let mixes = {};
                              for (let l = 0; l < footbath.mixes.length; l++) {
                                let mix = footbath.mixes[l];
                                if (mix.productsToDisplay) {
                                  if (Array.isArray(mix.productsToDisplay)) {
                                    let products = {};
                                    for (
                                      let m = 0;
                                      m < mix.productsToDisplay.length;
                                      m++
                                    ) {
                                      products[mix.productsToDisplay[m].id] =
                                        mix.productsToDisplay[m];
                                    }
                                    mix.productsToDisplay = products;
                                  }
                                }
                                mixes[mix.id] = mix;
                              }
                              footbath.mixes = mixes;
                            }
                          }
                          footbaths[footbath.id] = footbath;
                        }
                        assessor.footbaths = footbaths;
                      }
                    }
                  }
                  assessors[assessor.id] = assessor;
                }
              }
              group.assessors = assessors;
            }
          }
          groups[group.id] = group;
        }
      }
      evaluation.groups = groups;
    }
  }

  return evaluation;
};

export const setAuthInLocalStorage = DATA => {
  DATA.token && localStorage.setItem('token', DATA.token);
  DATA.refreshToken && localStorage.setItem('refreshToken', DATA.refreshToken);
  DATA.expiresIn && localStorage.setItem('expireAt', DATA.expiresIn);
  DATA.role && localStorage.setItem('role', DATA.role);
  DATA.lang && localStorage.setItem('lang', DATA.lang);

  // localStorage.setItem('user', JSON.stringify(DATA.user));
};
export const removeAuthInLocalStorage = async () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  };

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  try {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}auth/signout`, {}, config);
  
  } catch (error) {
    // Handle error
    console.error('Error signing out:', error);
  }
  

  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('expireAt');
  localStorage.removeItem('role');
  localStorage.removeItem('openpages');
  localStorage.removeItem('lang');


  // localStorage.removeItem('user');
};

export const newObjectId = () => {
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
  const objectId =
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, () => {
        return Math.floor(Math.random() * 16).toString(16);
      })
      .toLowerCase();

  return objectId;
};
// export function imgUrlToBase64(url, callback) {
export function imgUrlToBase64(url) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        // callback(reader.result);
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }).catch(err => {
    throw err;
  });
}

const usePromise = promise =>
  new Promise.allSettled(promise).map(([{ reason, value }]) => ({
    reason: error,
    data: value,
  }));
