<template>
     <div class="container mt-5">
          <div class="alert alert-danger" role="alert">
               {{ "Sorry page cannot be found!" | translate }}
          </div>
     </div>
</template>

<script>
export default {
     metaInfo: {
          title: 'Page Not Found!',
     },
     created() {
     	console.log("404");
     	if ( this.$route.path === '/index.html' ) {
        	this.$router.push('/');
		}
     }
}
</script>
