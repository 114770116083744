import {
    heatAbatement
} from './heatAbatement';
import {
    medias
} from '../Global/medias.js';

class HeatAbatementAssessorSlides {

    constructor(app, report, evaluation, group, user) {
        this.app = app;
        this.report = report;
        this.evaluation = evaluation;
        this.group = group;
        this.user = user;
    }

    add(tableOfContentsCalculatorPosition) {

        let assessor = this.group.getAssessorByType("16");

        if (assessor === false || assessor.assessor.data === undefined) {
            throw this.group.getName() + this.app.$t(" - ") + this.app.$t("Heat Abatement") + this.app.$t(" assessor is not ready.");
        }

        this.report.tableOfContentsHelper("Heat Abatement", tableOfContentsCalculatorPosition, this.group);

        heatAbatement(this.app, this.report, this.evaluation, this.group, assessor);
        medias(this.app, this.report, this.group, "16", this.user);
    }

}

export default HeatAbatementAssessorSlides;