export default {
  slideHeader: {
    x: 0.4,
    y: 1,
    h: 1,
    valign: 'middle',
    w: 7.5,
    color: '000000',
    fontSize: 25,
    fontFace: 'Arial',
    bold: true
  },

  subheaderBlack: {
    h: 1,
    color: '000000',
    fontSize: 16,
    fontFace: 'Arial',
    bold: true
  },

  subheaderBlue: {
    color: '0000FF',
    fontSize: 16,
    fontFace: 'Arial',
    bold: true
  },

  tableHeader: {
    fill: '000000',
    color: 'ffffff',
    bold: true,
    valign: 'middle',
    align: 'center',
    fontFace: 'Arial'
  },

  tableHeaderGray: {
    fill: 'E2E3E4',
    color: '000000',
    bold: true,
    valign: 'middle',
    align: 'center',
    fontFace: 'Arial',
    charSpacing: -0.25,
    breakLine: false,
  },

  tableOptions: {
    valign: 'middle',
    align: 'center',
    fontFace: 'Arial',
    fontSize: 10,
    color: '000000',
    margin: 5,
    autoPage: true,
    autoPageRepeatHeader: true,
    newSlideStartY: 2.2,
    w: 7.5
  },

  tocAssessorPage: {
    x: 7.5,
    h: 1,
    color: '000000',
    fontSize: 16,
    fontFace: 'Arial',
    bold: true
  },

  tocAssessorTitle: {
    x: 0.5,
    h: 1,
    color: '000000',
    fontSize: 16,
    fontFace: 'Arial',
    bold: true
  },

  tocExecutiveSummaryPage: {
    x: 7.5,
    y: 1.95,
    h: 1,
    color: '000000',
    fontSize: 16,
    fontFace: 'Arial',
    bold: true
  },

  tocExecutiveSummaryTitle: {
    x: 0.4,
    y: 1.95,
    h: 1,
    color: '000000',
    fontSize: 16,
    fontFace: 'Arial',
    bold: true
  },

  tocGroupPage: {
    x: 7.5,
    h: 1,
    color: '000000',
    fontSize: 14,
    fontFace: 'Arial'
  },

  tocGroupTitle: {
    x: 0.75,
    h: 1,
    color: '000000',
    fontSize: 14,
    fontFace: 'Arial'
  },
}
