/**
 * The Freestall assessor allows the user to do two things. You can use the assessor to calculate the ideal freestall construction for a
 * group of cows or you can use the freestall assessor to check the dimensions of an existing freestall for issues.
 */

const convert = require('convert-units');
import Assessor from './Assessor';

class FreeStall extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;
        this.initializeIdealAndConstraints();
        // this.idealPassesPerBath = -1; // @TODO
        // this.idealDoubleRowStallLength = -1; // @TODO
        // this.idealRearCurbWidth = -1; // @TODO
        // this.idealNeckrailToRearCurbDistance = -1; // @TODO
        // this.idealRearCurbHeight = -1; // @TODO missing
        // this.idealBrisketLocatorHeight = -1; // @TODO
        // this.idealInsideLoopDividerDiameter = -1; // @TODO
        // this.minInsideLoopDividerDiameter = -1; // @TODO
        // this.idealSingleRowStallLength = -1; // @TODO
        // this.minSingleRowStallLength = -1; // @TODO
        // this.idealDoubleRowStallLength = -1; // @TODO
        // this.minBeddingDepth = 8; // @TODO
        // this.minDoubleRowStallLength = -1; // @TODO
        // this.minStallWidth = -1; // @TODO
        // this.maxStallWidth = -1; // @TODO
        // this.minNeckrailHeight = -1 //@TODO
        // this.maxNeckrailHeight = -1 //@TODO
        // this.minLowerDividerHeight = -1; // @TODO
        // this.maxLowerDividerHeight = -1; //@TODO
        // this.minRearCurbWidth = -1; //@TODO
        // this.minCurbToBrisketLocatorDistance = -1; // @TODO
        // this.maxCurbToBrisketLocatorDistance = -1; // @TODO
        // this.maxBrisketLocatorHeight = -1; // @TODO
        // this.minNeckrailToRearCurbDistance = -1 // @TODO
        // this.maxNeckrailToRearCurbDistance = -1; // @TODO
    }
    /**
     * @typedef {Object} getting the object for return the correct bedding value according to the weight.
     * @property {String} bedding (required)
     * @property {String} cowWeight (required)
     * @property {String} measurement (required)
     * @return {Object}
     */
    getThineBeddingValues(measurement){
        const bedding  = this.getBeddingTypeValue()
        const cowWeight = this.group.getCowSize()
    
        const thinBeddings = [
                'concrete',
                'foam_mattress',
                'mats',
                'rubber_mattress',
                'water_bed',
                'other'
            ];
            // check if the provided bedding it not a thin bedding.
            if(!thinBeddings.includes(bedding)) {
                return {}
            }
    
            const measurements = {
                neckrailHeight:{
                    '408.2-498.9' :'161-165',
                    '498.9-589.6' :'166-170',
                    '589.6-680.3' :'171-175',
                    '680.3-771' :'176-180',
                    '771-861.8' :'181-185',
                    '861.8-952.5' :'189-193',
                    }
                // here we can add more measurements with their values like as above
            } 
            
            // getting the min/max values and calculate their ideal value
            const [min, max] = measurements[measurement][cowWeight].split('-');
            const ideal = Math.floor((+min + +max ) / 2)
            return {min: +min, max: +max, ideal}
    }
       

    initializeIdealAndConstraints() {

        // bob zone
        this.idealBobZone = 1;

        //concrete fill
        this.idealConcreteFill = 10;

        // Bedding Depth
        this.minBeddingDepth = 20

        // Rear Curb Width
        this.maxRearCurbWidth = 20
        this.idealRearCurbWidth = 15

        // Rear Curb Height
        this.minRearCurbHeight = 18
        this.maxRearCurbHeight = 20
        this.idealRearCurbHeight = 20

        let min, max, ideal;

        switch(this.group.getCowSize()) {

            case '408.2-498.9':

                // Stall Width
                this.minStallWidth = 105;
                this.maxStallWidth = 109;
                this.idealStallWidth = 107;

                // Stall Length - Single Row
                this.minStallLengthSingleRow = 242;
                this.idealStallLengthSingleRow = 244;

                // Stall Length - Double Row
                this.minDoubleRowStallLength = 225;
                this.idealDoubleRowStallLength = 229;

                // Lower Divider Height
                this.minLowerDividerHeight = 13;
                this.maxLowerDividerHeight = 25;
                this.idealLowerDividerHeight = 25;

                // Lower Divider for Michigan
                this.minMichiganLowerDividerHeight = 84;
                this.maxMichiganLowerDividerHeight = 1086;
                this.idealMichiganLowerDividerHeight = 86;

                // Neckrail Height
                this.minNeckrailHeight = 105 ;
                this.maxNeckrailHeight = 109;
                this.idealNeckrailHeight = 107;
                
                // Brisket Locator Height
                this.maxBrisketLocatorHeight = 8;
                this.idealBrisketLocatorHeight = 8;

                // Inside Loop Divider Diameter
                this.minInsideLoopDividerDiameter = 76;
                this.idealInsideLoopDividerDiameter = 76;

                // Curb to Brisket Locator Distance
                this.minCurbToBrisketLocatorDistance = 161;
                this.maxCurbToBrisketLocatorDistance = 165;
                this.idealCurbToBrisketLocatorDistance = 163;

                // Neckrail to Rear Curb Distance
                ({ min=145, max=149, ideal=147 } = this.getThineBeddingValues('neckrailHeight'));
                this.minNeckrailToRearCurbDistance = min;
                this.maxNeckrailToRearCurbDistance = max;
                this.idealNeckrailToRearCurbDistance = ideal;

                break;

            case '498.9-589.6':

                // Stall Width
                this.minStallWidth = 112;
                this.maxStallWidth = 115;
                this.idealStallWidth = 114;

                // Stall Length - Single Row
                this.minStallLengthSingleRow = 272;
                this.idealStallLengthSingleRow = 274;

                // Stall Length - Double Row
                this.minDoubleRowStallLength = 240;
                this.idealDoubleRowStallLength = 244;

                // Lower Divider Height
                this.minLowerDividerHeight = 13;
                this.maxLowerDividerHeight = 25;
                this.idealLowerDividerHeight = 25;

                // Lower Divider for Michigan
                this.minMichiganLowerDividerHeight = 84;
                this.maxMichiganLowerDividerHeight = 1086;
                this.idealMichiganLowerDividerHeight = 86;

                // Neckrail Height
                this.minNeckrailHeight = 112;
                this.maxNeckrailHeight = 116;
                this.idealNeckrailHeight = 114;

                // Brisket Locator Height
                this.maxBrisketLocatorHeight = 8;
                this.idealBrisketLocatorHeight = 8;

                // Inside Loop Divider Diameter
                this.minInsideLoopDividerDiameter = 84;
                this.idealInsideLoopDividerDiameter = 84;

                // Curb to Brisket Locator Distance
                this.minCurbToBrisketLocatorDistance = 166;
                this.maxCurbToBrisketLocatorDistance = 170;
                this.idealCurbToBrisketLocatorDistance = 168;

                // Neckrail to Rear Curb Distance
                ({ min=150, max=154, ideal=152 } = this.getThineBeddingValues('neckrailHeight'));
                this.minNeckrailToRearCurbDistance = min;
                this.maxNeckrailToRearCurbDistance = max;
                this.idealNeckrailToRearCurbDistance = ideal;

                break;

            case '589.6-680.3':

                // Stall Width
                this.minStallWidth = 120;
                this.maxStallWidth = 124;
                this.idealStallWidth = 122;

                // Stall Length - Single Row
                this.minStallLengthSingleRow = 272;
                this.idealStallLengthSingleRow = 274;

                // Stall Length - Double Row
                this.minDoubleRowStallLength = 240;
                this.idealDoubleRowStallLength = 244;

                // Lower Divider Height
                this.minLowerDividerHeight = 13;
                this.maxLowerDividerHeight = 31;
                this.idealLowerDividerHeight = 31;

                // Lower Divider for Michigan
                this.minMichiganLowerDividerHeight = 90;
                this.maxMichiganLowerDividerHeight = 1092;
                this.idealMichiganLowerDividerHeight = 92;

                // Neckrail Height
                this.minNeckrailHeight = 120;
                this.maxNeckrailHeight = 124;
                this.idealNeckrailHeight = 122;

                // Brisket Locator Height
                this.maxBrisketLocatorHeight = 10;
                this.idealBrisketLocatorHeight = 10;

                // Inside Loop Divider Diameter
                this.minInsideLoopDividerDiameter = 84;
                this.idealInsideLoopDividerDiameter = 84;

                // Curb to Brisket Locator Distance
                this.minCurbToBrisketLocatorDistance = 171;
                this.maxCurbToBrisketLocatorDistance = 175;
                this.idealCurbToBrisketLocatorDistance = 173;

                // Neckrail to Rear Curb Distance
                ({ min=156, max=160, ideal=157 } = this.getThineBeddingValues('neckrailHeight'));
                this.minNeckrailToRearCurbDistance = min;
                this.maxNeckrailToRearCurbDistance = max;
                this.idealNeckrailToRearCurbDistance = ideal;

                break;

            case '680.3-771':

                // Stall Width
                this.minStallWidth = 125;
                this.maxStallWidth = 129;
                this.idealStallWidth = 127;

                // Stall Length - Single Row
                this.minStallLengthSingleRow = 303;
                this.idealStallLengthSingleRow = 305;

                // Stall Length - Double Row
                this.minDoubleRowStallLength = 255;
                this.idealDoubleRowStallLength = 259;

                // Lower Divider Height
                this.minLowerDividerHeight = 13;
                this.maxLowerDividerHeight = 31;
                this.idealLowerDividerHeight = 31;

                // Lower Divider for Michigan
                this.minMichiganLowerDividerHeight = 90;
                this.maxMichiganLowerDividerHeight = 1092;
                this.idealMichiganLowerDividerHeight = 92;

                // Neckrail Height
                this.minNeckrailHeight = 125;
                this.maxNeckrailHeight = 129;
                this.idealNeckrailHeight = 127;

                // Brisket Locator Height
                this.maxBrisketLocatorHeight = 10;
                this.idealBrisketLocatorHeight = 10;

                // Inside Loop Divider Diameter
                this.minInsideLoopDividerDiameter = 91;
                this.idealInsideLoopDividerDiameter = 91;

                // Curb to Brisket Locator Distance
                this.minCurbToBrisketLocatorDistance = 176;
                this.maxCurbToBrisketLocatorDistance = 180;
                this.idealCurbToBrisketLocatorDistance = 178;

                // Neckrail to Rear Curb Distance
                ({ min=161, max=165, ideal=163 } = this.getThineBeddingValues('neckrailHeight'));
                this.minNeckrailToRearCurbDistance = min;
                this.maxNeckrailToRearCurbDistance = max;
                this.idealNeckrailToRearCurbDistance = ideal;

                break;

            case '771-861.8':

                // Stall Width
                this.minStallWidth = 135;
                this.maxStallWidth = 139;
                this.idealStallWidth = 137;

                // Stall Length - Single Row
                this.minStallLengthSingleRow = 303;
                this.idealStallLengthSingleRow = 305;

                // Stall Length - Double Row
                this.minDoubleRowStallLength = 255;
                this.idealDoubleRowStallLength = 259;

                // Lower Divider Height
                this.minLowerDividerHeight = 13;
                this.maxLowerDividerHeight = 33;
                this.idealLowerDividerHeight = 33;

                // Lower Divider for Michigan
                this.minMichiganLowerDividerHeight = 92;
                this.maxMichiganLowerDividerHeight = 1094;
                this.idealMichiganLowerDividerHeight = 94;

                // Neckrail Height
                this.minNeckrailHeight = 130;
                this.maxNeckrailHeight = 134;
                this.idealNeckrailHeight = 132;

                // Brisket Locator Height
                this.maxBrisketLocatorHeight = 10;
                this.idealBrisketLocatorHeight = 10;

                // Inside Loop Divider Diameter
                this.minInsideLoopDividerDiameter = 91;
                this.idealInsideLoopDividerDiameter = 91;

                // Curb to Brisket Locator Distance
                this.minCurbToBrisketLocatorDistance = 181;
                this.maxCurbToBrisketLocatorDistance = 185;
                this.idealCurbToBrisketLocatorDistance = 183;

                // Neckrail to Rear Curb Distance
                ({ min=166, max=170, ideal=168 } = this.getThineBeddingValues('neckrailHeight'));
                this.minNeckrailToRearCurbDistance = min;
                this.maxNeckrailToRearCurbDistance = max;
                this.idealNeckrailToRearCurbDistance = ideal;

                break;

            case '861.8-952.5':

                // Stall Width
                this.minStallWidth = 143;
                this.maxStallWidth = 147;
                this.idealStallWidth = 145;

                // Stall Length - Single Row
                this.minStallLengthSingleRow = 318;
                this.idealStallLengthSingleRow = 320;

                // Stall Length - Double Row
                this.minDoubleRowStallLength = 271;
                this.idealDoubleRowStallLength = 275;

                // Lower Divider Height
                this.minLowerDividerHeight = 13;
                this.maxLowerDividerHeight = 36;
                this.idealLowerDividerHeight = 36;

                // Lower Divider for Michigan
                this.minMichiganLowerDividerHeight = 95;
                this.maxMichiganLowerDividerHeight = 1097;
                this.idealMichiganLowerDividerHeight = 97;

                // Neckrail Height
                this.minNeckrailHeight = 135;
                this.maxNeckrailHeight = 139;
                this.idealNeckrailHeight = 137;

                // Brisket Locator Height
                this.maxBrisketLocatorHeight = 10;
                this.idealBrisketLocatorHeight = 10;

                // Inside Loop Divider Diameter
                this.minInsideLoopDividerDiameter = 91;
                this.idealInsideLoopDividerDiameter = 91;

                // Curb to Brisket Locator Distance
                this.minCurbToBrisketLocatorDistance = 189;
                this.maxCurbToBrisketLocatorDistance = 193;
                this.idealCurbToBrisketLocatorDistance = 191;

                // Neckrail to Rear Curb Distance
                ({ min=173, max=177, ideal=175 } = this.getThineBeddingValues('neckrailHeight'));
                this.minNeckrailToRearCurbDistance = min;
                this.maxNeckrailToRearCurbDistance = max;
                this.idealNeckrailToRearCurbDistance = ideal;

            break;
        }
    }

    getResultMessage(messageKey) {

        const messages = [
            {
                key: "bedding_depth",
                title: "Bedding Depth",
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: this.getBeddingDepth() >= this.getMinBeddingDepth(),
                        mood: "text-success"
                    },
                    {
                        title: app.$t("Too Shallow"),
                        description: app.$t("OUT OF RANGE! Bedding depth below ") + this.getMinBeddingDepth() + app.$t(' ') + app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t(" may decrease lying time."),
                        critera: this.getBeddingDepth() < this.getMinBeddingDepth(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "surface_dry",
                title: app.$t("Is the Stall Surface Dry?"),
                options: [
                    {
                        title: app.$t("Good"),
                        criteria: this.isSurfaceDry() === 1,
                        mood: "text-success"
                    },
                    {
                        title: app.$t("Excessive moisture on the lying surface may decrease lying time by about 1 hour per day. (Reich et al., 2010: JDS 93: 1561)"),
                        criteria: this.isSurfaceDry() === 0,
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "stall_width",
                title: app.$t("Stall Width"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: (this.getWidth() >= this.getMinWidth()) && (this.getWidth() <= this.getMaxWidth()),
                        mood: "text-success"
                    },
                    {
                        title: app.$t("Too Narrow"),
                        description: app.$t('OUT OF RANGE! Stall width should be between ') + this.getMinWidth() + app.$t(' and ') + this.getMaxWidth() +  app.$t(app.$t(' ') + app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))) + app.$t('. Stalls that are too narrow will reduce lying time and increase disturbances between neighboring cows.'),
                        critera: this.getWidth() < this.getMinWidth(),
                        mood: "text-danger"
                    },
                    {
                        title: app.$t("Too Wide"),
                        description: app.$t('OUT OF RANGE! Stall width should be between ') + this.getMinWidth() + app.$t(' and ') + this.getMaxWidth() +  app.$t(app.$t(' ') + app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))) + app.$t('. Stalls that are too wide may allow cows to lie diagonally and soil the stall.'),
                        critera: this.getWidth() > this.getMaxWidth(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "stall_length_single_row",
                title: app.$t("Stall Length (Single Row)"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: this.getSingleRowStallLength() >= this.getMinSingleRowStallLength(),
                        mood: "text-success"
                    },
                    {
                        title: app.$t("Too Short"),
                        description: app.$t('OUT OF RANGE! Single row stall length should be at least ') + this.getMinSingleRowStallLength() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t('. Inadequate stall length prevents front lunge and forces cows to side lunge.'),
                        critera: this.getSingleRowStallLength() < this.getMinSingleRowStallLength(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "stall_length_double_row",
                title: app.$t("Stall Length (Double Row)"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: this.getDoubleRowStallLength() >= this.getMinDoubleRowStallLength(),
                        mood: "text-success"
                    },
                    {
                        title: app.$t("Too Short"),
                        description: app.$t('OUT OF RANGE! Double row stall length should be at least ') + this.getMinDoubleRowStallLength() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t('. Inadequate stall length prevents front lunge and forces cows to side lunge.'),
                        critera: this.getDoubleRowStallLength() < this.getMinDoubleRowStallLength(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "rear_curb_height",
                title: app.$t("Rear Curb Height"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: (this.getRearCurbHeight() >= this.getMinRearCurbHeight()) && (this.getRearCurbHeight() <= this.getMaxRearCurbHeight()),
                        mood: "text-success"
                    },
                    {
                        title: app.$t("Too Tall"),
                        description: app.$t('OUT OF RANGE! The rear curb should be no taller than ') + this.getMaxRearCurbHeight() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t('. High curbs make it difficult for lame cows to enter and leave the stall.'),
                        critera: this.getRearCurbHeight() > this.getMaxRearCurbHeight(),
                        mood: "text-danger"
                    },
                    {
                        title: app.$t("Too Short"),
                        description: app.$t('OUT OF RANGE! The rear curb height should be at least ') + this.getMinRearCurbHeight() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t('. Low curbs may lead to soiling in the stall during scraping and flushing.'),
                        critera: this.getRearCurbHeight() < this.getMinRearCurbHeight(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "neckrail_height",
                title: app.$t("Neckrail Height"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: (this.getNeckrailHeight() >= this.getMinNeckrailHeight()) && (this.getNeckrailHeight() <= this.getMaxNeckrailHeight()),
                        mood: "text-success"
                    },
                    {
                        title: app.$t("Too Short"),
                        description: app.$t('OUT OF RANGE! Neckrail height should be between ') + this.getMinNeckrailHeight() + app.$t(' and ') + this.getMaxNeckrailHeight() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t('. A low neckrail may increase time spent standing in the stall and make it more difficult for animals to rise.'),
                        critera: this.getNeckrailHeight() < this.getMinNeckrailHeight(),
                        mood: "text-danger"
                    },
                    {
                        title: app.$t("Too High"),
                        description: app.$t('OUT OF RANGE! Neckrail height should be between ') + this.getMinNeckrailHeight() + app.$t(' and ') + this.getMaxNeckrailHeight() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t('. A neckrail that is too high may be ineffective at animal placement.'),
                        critera: this.getNeckrailHeight() > this.getMaxNeckrailHeight(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "brisket_locator_height",
                title: app.$t("Brisket Locator Height"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: this.getBrisketLocatorHeight() <= this.getIdealBrisketLocatorHeight(),
                        mood: "text-success"
                    },
                    {
                        title: app.$t("Too High"),
                        description: app.$t('OUT OF RANGE! Brisket locators that are taller than ') + this.getMaxBrisketLocatorHeight() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t(' can prevent forward thrust of the forelimb when the cow rises, leading to reduced stall use and more diagonal lying.'),
                        critera: this.getBrisketLocatorHeight() > this.getMaxBrisketLocatorHeight(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "curb_to_brisket_locator_distance",
                title: app.$t("Curb to Brisket Locator Distance"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: (this.getCurbToBrisketLocatorDistance() >= this.getMinCurbToBrisketLocatorDistance()) && (this.getCurbToBrisketLocatorDistance() <= this.getMaxCurbToBrisketLocatorDistance()),
                        mood: "text-success"
                    },
                    {
                        title: app.$t("Too Long"),
                        description: app.$t('OUT OF RANGE! The curb to brisket distance should be between ') + this.getMinCurbToBrisketLocatorDistance() + app.$t(' and ') + this.getMaxCurbToBrisketLocatorDistance() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t('. Stalls with too long of a resting space may become soiled.'),
                        critera: this.getCurbToBrisketLocatorDistance() > this.getMaxCurbToBrisketLocatorDistance(),
                        mood: "text-danger"
                    },
                    {
                        title: app.$t("Too Short"),
                        description: app.$t('OUT OF RANGE! The curb to brisket distance should be between ') + this.getMinCurbToBrisketLocatorDistance() + app.$t(' and ') + this.getMaxCurbToBrisketLocatorDistance() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t('. Stalls with a resting length that is too short will force the cow to lie too far back in the stall.'),
                        critera: this.getCurbToBrisketLocatorDistance() < this.getMinCurbToBrisketLocatorDistance(),
                        mood: "text-danger"

                    }
                ]
            },
            {
                key: "neckrail_to_rear_curb_distance",
                title: app.$t("Neckrail to Rear Curb Distance"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: (this.getNeckrailToRearCurbDistance() >= this.getMinNeckrailToRearCurbDistance()) && (this.getNeckrailToRearCurbDistance() <= this.getMaxNeckrailToRearCurbDistance()),
                        mood: "text-success"
                    },
                    {
                        title: app.$t("Too Long"),
                        description: app.$t('OUT OF RANGE! The neckrail to rear curb distance should be between ') + this.getMinNeckrailToRearCurbDistance() + app.$t(' and ') + this.getMaxNeckrailToRearCurbDistance() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t('. Neckrails too far forward increase soiling of the stall surface.'),
                        critera: this.getNeckrailToRearCurbDistance() > this.getMaxNeckrailToRearCurbDistance(),
                        mood: "text-danger"
                    },
                    {
                        title: app.$t("Too Short"),
                        description: app.$t('OUT OF RANGE! The neckrail to rear curb distance should be between ') + this.getMinNeckrailToRearCurbDistance() + app.$t(' and ') + this.getMaxNeckrailToRearCurbDistance() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t('. Cattle should not hit the neckrail when rising and may have a tendency to perch.'),
                        critera: this.getNeckrailToRearCurbDistance() < this.getMinNeckrailToRearCurbDistance(),
                        mood: "text-danger"

                    }
                ]
            },
            {
                key: "lower_divider_height",
                title: app.$t("Lower Divider Height"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: (this.getLowerDividerHeight() >= this.getMinLowerDividerHeight()) && (this.getLowerDividerHeight() <= this.getMaxLowerDividerHeight()),
                        mood: "text-success"
                    },
                    {
                        title: app.$t("Too Tall"),
                        description: app.$t('OUT OF RANGE! Lower divider rails over ') + this.getMaxLowerDividerHeight() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t(' inhibit side lunging.'),
                        critera: this.getLowerDividerHeight() > this.getMaxLowerDividerHeight(),
                        mood: "text-danger"
                    },
                    {
                        title: app.$t("Too Short"),
                        description: app.$t('OUT OF RANGE! There must be at least ') + this.getMinLowerDividerHeight() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t(' between the top of the brisket locator and the bottom of the lower divider rail to prevent foot entrapment.'),
                        critera: this.getLowerDividerHeight() < this.getMinLowerDividerHeight(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "inside_loop_divider_diameter",
                title: app.$t("Inside Loop Divider Diameter"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: this.getInsideLoopDividerDiameter() >= this.getMinInsideLoopDividerDiameter(),
                        mood: "text-success"
                    },
                    {
                        title: app.$t("Too Short"),
                        description: app.$t('OUT OF RANGE! The diameter should be at least ') + this.getMinInsideLoopDividerDiameter() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t(' to allow for the animal to side lunge.'),
                        critera: this.getInsideLoopDividerDiameter() < this.getMinInsideLoopDividerDiameter(),
                        mood: "text-danger"

                    }
                ]
            },
            {
                key: "rear_curb",
                title: app.$t("Rear Curb Width"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: this.getRearCurbWidth() <= this.getMaxRearCurbWidth(),
                        mood: "text-success"
                    },
                    {
                        title: app.$t("Too Wide"),
                        description: app.$t('OUT OF RANGE! Curb width should be less than ') + this.getMaxRearCurbWidth() + app.$t(' ') +  app.$t(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')) + app.$t('. Wide curbs limit the standing and lying area of the stall.'),
                        critera: this.getRearCurbWidth() > this.getMaxRearCurbWidth(),
                        mood: "text-danger"

                    }
                ]
            },
            {
                key: "bob_zone",
                title: app.$t("Is there a bob-zone obstruction?"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: this.getBobZone() === 0,
                        mood: "text-success"
                    },
                    {
                        title: app.$t("OUT OF RANGE! Bob-zone obstructions limit the forward lunge of the head and make cows lie and lunge diagonally across the stall."),
                        critera: this.getBobZone() === 1,
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "concrete_fill",
                title: app.$t("Is there a concrete fill behind brisket locator?"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: this.getConcreteFill() === 0,
                        mood: "text-success"
                    },
                    {
                        title: app.$t("OUT OF RANGE! Concrete fill limits the forward movement of the front leg when the cow rises."),
                        critera: this.getConcreteFill() === 1,
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "brisket_locator_properly_locate_cows",
                title: app.$t("Does the brisket locator properly locate the cows?"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: this.getBrisketLocatorProperlyLocateCows() === 1,
                        mood: "text-success"
                    },
                    {
                        title: app.$t("OUT OF RANGE! If cows lie on the brisket locator, they have a greater chance of soiling the stall."),
                        critera: this.getBrisketLocatorProperlyLocateCows() === 0,
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "knee_drop_test",
                title: app.$t("Does the freestall pass the knee-drop test?"),
                options:[
                    {
                        title: app.$t("Good!"),
                        critera: this.getKneeDropTest() === 1,
                        mood: "text-success"
                    },
                    {
                        title: app.$t("OUT OF RANGE! Surface cushion is one of the most important aspects of the stall, and the amount of cushioning affects lying times. Inadequate cushioning will reduce stall lying times. The surface should be soft and moldable from the front of the stall to the back."),
                        critera: this.getKneeDropTest() === 0,
                        mood: "text-danger"
                    }
                ]
            }
        ];

        for ( var i = 0; i < messages.length; i++ ) {
            if ( messages[i].key === messageKey ) {

                for ( var j = 0; j < messages[i].options.length; j++ ) {
                    if ( messages[i].options[j].critera === true ) {
                        let formatted_feedback = messages[i].options[j].title
                        if(messages[i].options[j].description !== undefined) {
                            formatted_feedback += app.$t(' ') + messages[i].options[j].description;
                        }
                        return  {
                            title: messages[i].title,
                            feedback: messages[i].options[j],
                            formatted_feedback: formatted_feedback
                        };
                    }
                }
            }
        }

        return {
            title: '',
            feedback: ''
        };
    }

    getBeddingTypeValue() {
        return this.assessor.data.bedding_type;
    }

    getBeddingType(){
        if(this.getBeddingTypeValue() === 'concrete') {
            return app.$t('Concrete');
        } else if( this.getBeddingTypeValue() === 'foam_mattress' ) {
            return app.$t('Foam Mattress');
        } else if( this.getBeddingTypeValue() === 'manure_solids' ) {
            return app.$t('Manure Solids');
        } else if( this.getBeddingTypeValue() === 'mats' ) {
            return app.$t('Mats');
        } else if( this.getBeddingTypeValue() === 'rubber_mattress' ) {
            return app.$t('Rubber Mattress');
        } else if( this.getBeddingTypeValue() === 'sand' ) {
            return app.$t('Sand');
        } else if( this.getBeddingTypeValue() === 'straw' ) {
            return app.$t('Straw');
        } else if( this.getBeddingTypeValue() === 'water_bed' ) {
            return app.$t('Water Bed');
        } else if( this.getBeddingTypeValue() === 'sawdust' ) {
            return app.$t('Sawdust');
        } else {
            if(this.assessor.data.other_bedding_type !== undefined && this.assessor.data.other_bedding_type !== '') {
                return this.assessor.data.other_bedding_type;
            }
            return this.app.$t('Other');
        }
    }


    getStallAlignmentValue(){
        return this.assessor.data.stall_alignment;
    }

    getStallAlignment(){
        if ( this.getStallAlignmentValue() === 'one' ) {
            return this.app.$t('One Row');
        } else if( this.getStallAlignmentValue() === 'two' ) {
            return this.app.$t('Two Row');
        } else if( this.getStallAlignmentValue() === 'three' ) {
            return this.app.$t('Three Row');
        } else if( this.getStallAlignmentValue() === 'two_row_head_to_tail' ) {
            return this.app.$t('Two Row Head to Tail');
        } else {
            return this.app.$t('Two Row Tail to Tail');
        }
    }

    getDividerTypeValue(){
        return this.assessor.data.divider_type;
    }

    getDividerType(){
        if(this.getDividerTypeValue() === 'cantilever') {
            return this.app.$t('Cantilever Divider');
        } else if(this.getDividerTypeValue() === 'michigan') {
            return this.app.$t('Michigan Divider');
        } else if(this.getDividerTypeValue() === 'multiple_blend') {
            return this.app.$t('Multiple Bend Divider');
        } else if(this.getDividerTypeValue() === 'square') {
            return this.app.$t('Square Divider')
        } else {
            return this.app.$t('Sturdy Built Divider');
        }
    }

    getFreeStallType(){
        if(this.assessor.data.freestall_type === 'analyze_existing') {
            return this.app.$t('Analyze Existing');
        } else {
            return this.app.$t('Build Ideal');
        }
    }

    // @NOTE Final
    getBeddingDepth(roundPoint){
        if ( this.assessor.data.bedding_depth === undefined || this.assessor.data.bedding_depth === null ) {
            return this.assessor.data.bedding_depth;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.bedding_depth.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.bedding_depth)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(roundPoint);
    }


    setBeddingDepth(newValue){
        if(newValue === '') {
            return this.assessor.data.bedding_depth = null;
        }

        this.assessor.data.bedding_depth = parseFloat(convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'cm'))
            .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getMinBeddingDepth(){
        return parseFloat(convert(this.minBeddingDepth)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(0);
    }

    isSurfaceDry(){
        return this.assessor.data.surface_dry;
    }

    getHockLesion(){
        return this.assessor.data.hock_lesions;
    }

    // @NOTE Final
    getWidth(roundPoint){
        if ( this.assessor.data.stall_width === undefined ) {
            return this.assessor.data.stall_width;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.stall_width.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.stall_width)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(0);
    }

    setWidth(newValue){
        this.assessor.data.stall_width = convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'cm'))
            .to('cm')
            .toDecimal(0);
    }

    getMinWidth(){
        return parseFloat(convert(this.minStallWidth)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getMaxWidth(){
        return parseFloat(convert(this.maxStallWidth)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getIdealWidth(){
        return parseFloat(convert(this.idealStallWidth)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    // @NOTE Final
    getLength(roundPoint){
        if ( this.assessor.data.stall_length === undefined ) {
            return this.assessor.data.stall_length;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.stall_length.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.stall_length)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(0);
    }

    setLength(newValue){
        this.assessor.data.stall_length = convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'cm'))
            .to('cm')
            .toDecimal(0);
    }

    // @NOTE Final
    getSingleRowStallLength(roundPoint){
        if ( this.assessor.data.stall_length_single_row === undefined ) {
            return this.assessor.data.stall_length_single_row;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.stall_length_single_row.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.stall_length_single_row)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(0);
    }

    setSingleRowStallLength(newValue){
        this.assessor.data.stall_length_single_row = convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'cm'))
            .to('cm')
            .toDecimal(0);
    }

    getMinSingleRowStallLength() {
        return parseFloat(convert(this.minStallLengthSingleRow)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getIdealSingleRowStallLength(){
        return parseFloat(convert(this.idealStallLengthSingleRow)
             .from('cm')
             .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
             .toDecimal(0);
     }

    // @TODO This seems wrong
    // @NOTE final
    getDoubleRowStallLength(roundPoint){
        if ( this.assessor.data.stall_length === undefined ) {
            return this.assessor.data.stall_length;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.stall_length.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.stall_length)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(0);
    }

    setDoubleRowStallLength(newValue){
        this.assessor.data.stall_length = convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'cm'))
            .to('cm')
            .toDecimal(0);
    }

    getMinDoubleRowStallLength(){
        return parseFloat(convert(this.minDoubleRowStallLength)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getIdealDoubleRowStallLength(){
        return parseFloat(convert(this.idealDoubleRowStallLength)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    // @NOTE final
    getRearCurbWidth(roundPoint){
        if ( this.assessor.data.rear_curb === undefined ) {
            return this.assessor.data.rear_curb;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.rear_curb.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.rear_curb)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(0);
    }

    setRearCurbWidth(newValue){
        this.assessor.data.rear_curb = convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'cm'))
            .to('cm')
            .toDecimal(0);
    }

    getMinRearCurbWidth(){
        let value = parseFloat(convert(this.minRearCurbWidth)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);

        if(this.evaluation.getMeasurements() === "imperial"){
            value = value.toDecimal(0);
        }

        return value;
    }

    getMaxRearCurbWidth(){
        return parseFloat(convert(this.maxRearCurbWidth)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getIdealRearCurbWidth(){
       return parseFloat(convert(this.idealRearCurbWidth)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }



    // @NOTE final
    getRearCurbHeight(roundPoint){
        if ( this.assessor.data.rear_curb_height === undefined ) {
            return this.assessor.data.rear_curb_height;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.rear_curb_height.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.rear_curb_height)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(0);
    }

    setRearCurbHeight(newValue){
        this.assessor.data.rear_curb_height = convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'cm'))
            .to('cm')
            .toDecimal(0);
    }

    getMinRearCurbHeight(){
        return parseFloat(convert(this.minRearCurbHeight)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getMaxRearCurbHeight(){
        return parseFloat(convert(this.maxRearCurbHeight)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getIdealRearCurbHeight(){
        return parseFloat(convert(this.idealRearCurbHeight)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    // @NOTE final
    getLowerDividerHeight(roundPoint){
        if ( this.assessor.data.lower_divider_height === undefined ) {
            return this.assessor.data.lower_divider_height;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.lower_divider_height.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.lower_divider_height)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(0);
    }

    setLowerDividerHeight(newValue){
        this.assessor.data.lower_divider_height = convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'cm'))
            .to('cm')
            .toDecimal(0);
    }

    getMinLowerDividerHeight(){
        let value = this.getDividerTypeValue() === 'michigan' ? this.minMichiganLowerDividerHeight : this.minLowerDividerHeight;
        return parseFloat(convert(value)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getMaxLowerDividerHeight(){
        let value = this.getDividerTypeValue() === 'michigan' ? this.maxMichiganLowerDividerHeight : this.maxLowerDividerHeight;
        return parseFloat(convert(value)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getIdealLowerDividerHeight(){
        let value = this.getDividerTypeValue() === 'michigan' ? this.idealMichiganLowerDividerHeight : this.idealLowerDividerHeight;
        return parseFloat(convert(value)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    // @NOTE final
    getNeckrailHeight(roundPoint){
        if ( this.assessor.data.neckrail_height === undefined ) {
            return this.assessor.data.neckrail_height;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.neckrail_height.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.neckrail_height)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(0);
    }

    setNeckrailHeight(newValue){
        this.assessor.data.neckrail_height = convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'cm'))
            .to('cm')
            .toDecimal(0);
    }

    getMinNeckrailHeight(){
        return parseFloat(convert(this.minNeckrailHeight)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getMaxNeckrailHeight(){
        return parseFloat(convert(this.maxNeckrailHeight)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getIdealNeckrailHeight(){
        return parseFloat(convert(this.idealNeckrailHeight)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    // @NOTE final
    getBrisketLocatorHeight(roundPoint){
        if ( this.assessor.data.brisket_locator_height === undefined ) {
            return this.assessor.data.brisket_locator_height;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.brisket_locator_height.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.brisket_locator_height)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(0);
    }

    setBrisketLocatorHeight(newValue){
        this.assessor.data.brisket_locator_height = convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'cm'))
            .to('cm')
            .toDecimal(0);
    }

    getMaxBrisketLocatorHeight(){
        return parseFloat(convert(this.maxBrisketLocatorHeight)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getIdealBrisketLocatorHeight(){
        return parseFloat(convert(this.idealBrisketLocatorHeight)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    // @NOTE final
    getInsideLoopDividerDiameter(roundPoint) {
        if ( this.assessor.data.inside_loop === undefined ) {
            return this.assessor.data.inside_loop;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.inside_loop.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.inside_loop)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(0);
    }

    setInsideLoopDividerDiameter(newValue) {
        this.assessor.data.inside_loop = convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'cm'))
            .to('cm')
            .toDecimal(0);
    }

    getMinInsideLoopDividerDiameter() {
        return parseFloat(convert(this.minInsideLoopDividerDiameter)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getIdealInsideLoopDividerDiameter() {
        return parseFloat(convert(this.idealInsideLoopDividerDiameter)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    // @NOTE final
    getCurbToBrisketLocatorDistance(roundPoint){
        if ( this.assessor.data.curb_to_brisket_locator_distance === undefined ) {
            return this.assessor.data.curb_to_brisket_locator_distance;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.curb_to_brisket_locator_distance.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.curb_to_brisket_locator_distance)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(0);
    }

    setCurbToBrisketLocatorDistance(newValue){
        this.assessor.data.curb_to_brisket_locator_distance = convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'cm'))
            .to('cm')
            .toDecimal(0);
    }

    getMinCurbToBrisketLocatorDistance(){
        return parseFloat(convert(this.minCurbToBrisketLocatorDistance)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getMaxCurbToBrisketLocatorDistance(){
        return parseFloat(convert(this.maxCurbToBrisketLocatorDistance)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getIdealCurbToBrisketLocatorDistance() {
        return parseFloat(convert(this.idealCurbToBrisketLocatorDistance)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    // @NOTE final
    getNeckrailToRearCurbDistance(roundPoint){
        if ( this.assessor.data.neckrail_rear_curb === undefined ) {
            return this.assessor.data.neckrail_rear_curb;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.neckrail_rear_curb.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.neckrail_rear_curb)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
                .toDecimal(0);
    }

    setNeckrailToRearCurbDistance(newValue){
        this.assessor.data.neckrail_rear_curb = convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'cm'))
            .to('cm')
            .toDecimal(0);
    }

    getMinNeckrailToRearCurbDistance(){
        return parseFloat(convert(this.minNeckrailToRearCurbDistance)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getMaxNeckrailToRearCurbDistance(){
        return parseFloat(convert(this.maxNeckrailToRearCurbDistance)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getIdealNeckrailToRearCurbDistance(){
        return parseFloat(convert(this.idealNeckrailToRearCurbDistance)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getBobZone(){
        return this.assessor.data.bob_zone;
    }

    getIdealBobZone() {
        return this.idealBobZone;
    }

    getConcreteFill(){
        return this.assessor.data.concrete_fill;
    }

    getIdealConcreteFill() {
        return parseFloat(convert(this.idealConcreteFill)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(0);
    }

    getBrisketLocatorProperlyLocateCows(){
        return this.assessor.data.brisket_locator_properly_locate_cows;
    }

    getKneeDropTest(){
        return this.assessor.data.knee_drop_test;
    }

};

export default FreeStall;
