<template>
    <div>
        <div class="card-body__top">
            <h2 class="h2">{{"Password Reset" | translate}}</h2>
        </div>

        <hr>
        
        <div v-if="$route.path.search(/admin/) === -1">
            <div class="form-group">
                <label class="new_password">{{"New Password" | translate}}</label>
                <vue-password 
                    v-model="value.new_password" 
                    classes="form-control" 
                    name="password" 
                    ref="password" 
                    data-vv-as="Password"
                    :strengthMessages="strengthMessages"
                ></vue-password>
                <span v-show="errors.has( 'password' )" class="text-danger">
                    <small>{{ errors.first( 'password' ) }}</small>
                </span>
            </div>
            <div class="form-group">
                <label>{{"Confirm New Password" | translate}}</label>
                <vue-password 
                    v-model="value.new_password2" 
                    classes="form-control" 
                    name="new_password2" 
                    v-validate="'confirmed:password'" 
                    data-vv-as="Password"
                ></vue-password>
                <span v-show="errors.has( 'new_password2' )" class="text-danger">
                    <small>{{ 'The password confirmation does not match.' | translate }}</small>
                </span>
            </div>
        </div>

        <div v-else class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label class="new_password">{{"New Password" | translate}}</label>
                    <vue-password 
                        v-model="value.new_password"
                        classes="form-control"
                        name="password"
                        ref="password"
                        data-vv-as="Password"
                        :strengthMessages="strengthMessages"
                    ></vue-password>
                    <span v-show="errors.has( 'password' )" class="text-danger">
                        <small>{{ errors.first( 'password' ) }}</small>
                    </span>
                </div>
                
                <div class="form-group">
                    <label>{{"Confirm New Password" | translate}}</label>
                    <vue-password 
                        v-model="value.new_password2"
                        classes="form-control"
                        name="new_password2"
                        v-validate="'confirmed:password'"
                        data-vv-as="Password"
                        :strengthMessages="strengthMessages"
                    ></vue-password>
                    <span v-show="errors.has('new_password2')" class="text-danger">
                        <small>{{ 'The password confirmation does not match.' | translate }}</small>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VuePassword from 'vue-password'
    var $ = window.jQuery = require('jquery');
    
    export default {
        props: ['value', 'user'],
        inject: ['$validator'],
        components: {
            VuePassword
        },
        methods: {
            movePasswordMeter() {
                $('.VuePassword__Meter').insertBefore('.VuePassword');
            },
            hidePasswordMeter() {
                if(this.user.lang === 'es') {
                    $('.VuePassword__Message').hide();
                }
            }
        },
        computed: {
            strengthMessages() {
                return [this.$t('Very Weak Password'), this.$t('Weak Password'), this.$t('Medium Password'), this.$t('Strong Password'), this.$t('Very Strong Password')]
            }
        },
        mounted() {
            console.log('lang', this.user.lang);
            this.movePasswordMeter();
            this.hidePasswordMeter();
        }
    }
</script>