var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isLoaded)?_c('div',{staticClass:"fixed-loading"},[_vm._m(0)]):_c('div',{staticClass:"container main-container main-container--accordion"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-24 col-xs-24 col-sm-24 col-lg-6"},[_c('div',{staticClass:"nav flex-column nav-pills",attrs:{"id":"v-pills-tab","role":"tablist","aria-orientation":"vertical"}},_vm._l((_vm.emailTemplates),function(emailTemplate,i){return _c('router-link',{key:emailTemplate._id,staticClass:"btn btn-dashboard btn-block tab",class:{ 'selected-tab': _vm.activeTabIndex == i },attrs:{"to":_vm.path(emailTemplate)}},[_c('span',{style:({
                display: 'flex',
                'justify-content': 'center',
                'align-items': 'center',
                height: '100%',
              }),on:{"click":function($event){_vm.activeTabIndex = i}}},[_vm._v(_vm._s(_vm._f("translate")(emailTemplate.title)))])])}),1)]),_c('div',{staticClass:"col-24 col-sm-24 col-lg-18 col-xs-24"},[_c('div',{staticClass:"tab-content",attrs:{"id":"v-pills-tabContent"}},[_c('div',[_c('router-view',{attrs:{"active-tab-index":_vm.activeTabIndex,"content":{ senderName: '', ..._vm.emailTemplates[_vm.activeTabIndex] }}})],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loading"},[_c('div',{staticClass:"double-bounce1"}),_c('div',{staticClass:"double-bounce2"})])
}]

export { render, staticRenderFns }