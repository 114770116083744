import XLSX from 'xlsx';
import Papa from 'papaparse';
import {Parser} from 'json2csv';

class ExportCsv {

    flattenObject(referenceObject) {
        let payload = {};
        for (let subObject in referenceObject) {
            if (!referenceObject.hasOwnProperty(subObject)) continue;
            if ((typeof referenceObject[subObject]) === 'object') {
                let flatObject = this.flattenObject(referenceObject[subObject]);
                for (let x in flatObject) {
                    if (!flatObject.hasOwnProperty(x)) continue;
                    payload[subObject + "-" + x] = flatObject[x];
                }
            } else {
                payload[subObject] = referenceObject[subObject];
            }
        }
        return payload;
    }

    convertObjectToArrays(payload) {

        let clientsArray = [];
        let contactsArray = [];
        let dairy_locationsArray = [];
        let evaluationsArray = [];
        let groupsArray = [];
        let assessorsArray = [];
        let footbathsArray = [];
        let new_footbathsArray = [];
        let footbathMixesArray = [];
        let mediasArray = [];

        let user = Object.keys(payload)
            .map(dataPointKey => {
                if (typeof payload[dataPointKey] !== "object") {
                    return payload[dataPointKey];
                } 
                return null
            })
            .filter(dataPointKey => payload[dataPointKey] !== null);

        let userDataArray = [user];
        let reportsArray = [];
        let dataLoss = [];

        function shallowObjectCopy(objectBeingUpdated) {
            let resultObject = {};
            for (let property in objectBeingUpdated) {
                if (objectBeingUpdated.hasOwnProperty(property)) {
                    if (typeof objectBeingUpdated[property] !== "object" && !Array.isArray(objectBeingUpdated[property])) {
                        resultObject[property] = objectBeingUpdated[property];
                    }
                }
            }
            return resultObject;
        };

        let self = this;

        //cyclomatic complexity = 18 - Love Scot
        function dataToShallowArray(payload) {
            for (let key in payload) {
                if (typeof payload[key] === "object") {
                    let arrayToEval = payload[key];

                    for (let subKey in arrayToEval) {
                        let subObject = arrayToEval[subKey];
                        if (typeof subObject === "object") {

                            switch (key) {
                                case "clients":
                                    clientsArray.push(shallowObjectCopy(arrayToEval[subKey]));
                                    break;
                                case "evaluations":
                                    evaluationsArray.push(shallowObjectCopy({
                                        ...arrayToEval[subKey].data,
                                        ...arrayToEval[subKey]
                                    }));
                                    break;
                                case "medias":
                                    clientsArray.push(shallowObjectCopy(arrayToEval[subKey]));
                                    break;
                                case "contacts":
                                    contactsArray.push(shallowObjectCopy(arrayToEval[subKey]));
                                    break;
                                case "dairy_locations":
                                    dairy_locationsArray.push(shallowObjectCopy(arrayToEval[subKey]));
                                    break;
                                case "groups":
                                    groupsArray.push(shallowObjectCopy({
                                        ...arrayToEval[subKey].data,
                                        ...arrayToEval[subKey]
                                    }));
                                    break;
                                case "reports":
                                    reportsArray.push(shallowObjectCopy(arrayToEval[subKey]));
                                    break;
                                case "assessors":
                                    assessorsArray.push(shallowObjectCopy({
                                        ...arrayToEval[subKey].data,
                                        ...arrayToEval[subKey]
                                    }));
                                    break;
                                case "footbaths":
                                    footbathsArray.push(shallowObjectCopy(arrayToEval[subKey]));
                                    break;
                                case "new_footbaths":
                                    new_footbathsArray.push(shallowObjectCopy(arrayToEval[subKey]));
                                    break;
                                case "mixes":
                                    footbathMixesArray.push(shallowObjectCopy(self.flattenObject(arrayToEval[subKey])));
                                    break;
                                default:
                                    dataLoss.push(shallowObjectCopy(arrayToEval[subKey]));
                            }
                            dataToShallowArray(subObject);
                        }
                    }
                } else {
                    console.log(key, "is NOT an object");
                    dataLoss.push({[key]: payload[key]});
                }
            }
        }

        dataToShallowArray(payload);

        let wb = XLSX.utils.book_new();
        wb.props = {
            Title: "USER DATA",
            Author: "ZINPRO"
        };

        function prepareSheet(array) {
            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }

            let allUniqueKeysForFields = [];
            for (let subIndex in array) {
                let arrayOfKeys = Object.keys(array[subIndex]);
                allUniqueKeysForFields = [...allUniqueKeysForFields, ...arrayOfKeys];
            }

            const fields = allUniqueKeysForFields.filter(onlyUnique); //.filter(onlyUnique)
            const opts = {fields};

            let jsonData = JSON.stringify(array);
            const parser = new Parser(opts);
            const csv = parser.parse(JSON.parse(jsonData));
            let aoa = Papa.parse(csv);

            return XLSX.utils.aoa_to_sheet(aoa.data);
        }

        let clientsWs = prepareSheet(clientsArray);
        XLSX.utils.book_append_sheet(wb, clientsWs, "Clients");

        let contactsWs = prepareSheet(contactsArray);
        XLSX.utils.book_append_sheet(wb, contactsWs, "Contacts");

        let dairy_locationsWs = prepareSheet(dairy_locationsArray);
        XLSX.utils.book_append_sheet(wb, dairy_locationsWs, "Dairy Locations");

        let evaluationsWs = prepareSheet(evaluationsArray);
        XLSX.utils.book_append_sheet(wb, evaluationsWs, "Evaluations");

        let groupsWs = prepareSheet(groupsArray);
        XLSX.utils.book_append_sheet(wb, groupsWs, "Groups");

        let assessorsWs = prepareSheet(assessorsArray);
        XLSX.utils.book_append_sheet(wb, assessorsWs, "Assessors");

        let new_footbathsWs = prepareSheet(new_footbathsArray);
        XLSX.utils.book_append_sheet(wb, new_footbathsWs, "New Footbaths");

        let footbathsWs = prepareSheet(footbathsArray);
        XLSX.utils.book_append_sheet(wb, footbathsWs, "Multiple Footbaths");

        let footbathMixesWs = prepareSheet(footbathMixesArray);
        XLSX.utils.book_append_sheet(wb, footbathMixesWs, "Mixes");

        let mediasWs = prepareSheet(mediasArray);
        XLSX.utils.book_append_sheet(wb, mediasWs, "Medias");

        let reportsWs = prepareSheet(reportsArray);
        XLSX.utils.book_append_sheet(wb, reportsWs, "Reports");

        let userWs = prepareSheet(userDataArray);
        XLSX.utils.book_append_sheet(wb, userWs, "User Information");

        XLSX.writeFile(wb, `User-${payload._id}.xls`);
    }
    
    convertArrayOfObjectsToCSV(args) {
        let result, ctr, keys, columnDelimiter, lineDelimiter, data;

        data = args;
        if (data == null || !data.length) {
            return null;
        }

        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';

        keys = Object.keys(data[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

}

export default ExportCsv;