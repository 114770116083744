<template>
    <div class="row">
        <div class="col-md-16 d-md-flex align-self-center">
            <slot></slot>
        </div>
		<div class="col-md-8 d-flex align-self-center justify-content-between justify-content-md-end flex-row">
			<div class="label_group label_group--width-sm label_group--inline mr-3 mr-sm-1 ml-0">
				<input @change="sendEvent" class="label_group__input" v-model="value" type="radio" :id="yesId" :value="0"/>
				<label class="label_group__label" :for="yesId">{{'No' | translate}}</label>						
			</div>
			<div class="label_group label_group--width-sm label_group--inline ml-0 ml-md-2">
				<input @change="sendEvent" class="label_group__input" v-model="value" type="radio" :id="noId" :value="1"/>
				<label class="label_group__label" :for="noId">{{'Yes' | translate}}</label>
			</div>
		</div>
    </div>
</template>

<script>

/*
*  @TODO We are passing in the entire prop, we need to only pass key value pair instead of the whole array
*/

export default {
    props: ['initialValue'],
    data() {
        return {
            yesId: Math.random().toString(36).substring(7),
            noId: Math.random().toString(36).substring(7),
            value: null
        }
    },
    methods: {
        sendEvent() {
            this.$emit('change', {
                value: this.value
            });
        }
    },
    watch: {
        initialValue: function(newValue) {
            this.value = newValue;
        }
    },
    mounted() {
        this.value = this.initialValue;        
    }
}

</script>