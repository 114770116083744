/**
 * The Freestall Ventilation Assessor evaluates either natural or tunnel/mechanical ventilation systems to ensure cows stay cool during
 * periods of heat stress.
 */

const convert = require('convert-units');
import Assessor from './Assessor';

class FreestallVentilation extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;

        this.targetRidgePitch = '4:1';
    }

    getSummerVentilationSystem() {
        return this.assessor.data.summer_ventilation_system;
    }

    getBarnIsFreeOfWindShadows() {
        return this.assessor.data.barn_is_free_of_wind_shadows;
    }

    getFiftyPercentOfSideWallCanBeOpened() {
        return this.assessor.data.fifty_percent_of_side_wall_can_be_opened;
    }

    getLongAxisOrientationOfTheBarn() {
        return this.assessor.data.long_axis_orientation_of_the_barn;
    }

    getPrevailingWindDirection() {
        return this.assessor.data.prevailing_wind_direction;
    }

    getNumberOfFans(number_of_fans='number_of_fans') {

      if(this.assessor.data[number_of_fans] === ''){
        return null
      }

        return this.assessor.data[number_of_fans];
    }
    
    getBarnLength(roundPoint) {
        if (this.assessor.data.barn_length === undefined || this.assessor.data.barn_length === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.barn_length.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.barn_length)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(roundPoint);
    }

    setBarnLength(newValue) {
        if (newValue === '') {
            return this.assessor.data.barn_length = null;
        }

        this.assessor.data.barn_length = parseFloat(convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .to('m'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getBarnWidth(roundPoint) {
        if (this.assessor.data.barn_width === undefined || this.assessor.data.barn_width === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.barn_width.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.barn_width)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(roundPoint);
    }

    setBarnWidth(newValue) {
        if (newValue === '') {
            return this.assessor.data.barn_width = null;
        }

        this.assessor.data.barn_width = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
                .to('m'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getBarnEaveHeight(roundPoint) {
        if (this.assessor.data.barn_eave_height === undefined || this.assessor.data.barn_eave_height === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.barn_eave_height.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.barn_eave_height)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(roundPoint);
    }

    setBarnEaveHeight(newValue) {
        if (newValue === '') {
            return this.assessor.data.barn_eave_height = null;
        }

        this.assessor.data.barn_eave_height = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
                .to('m'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getRoofHeight(roundPoint) {
        if (this.assessor.data.roof_height === undefined || this.assessor.data.roof_height === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.roof_height.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.roof_height)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(roundPoint);
    }

    setRoofHeight(newValue) {
        if (newValue === '') {
            return this.assessor.data.roof_height = null;
        }

        this.assessor.data.roof_height = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
                .to('m'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getRidgeOpeningWidth(roundPoint) {
        if (this.assessor.data.ridge_opening_width === undefined || this.assessor.data.ridge_opening_width === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.ridge_opening_width.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.ridge_opening_width)
            .from('cm')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .toDecimal(roundPoint);
    }

    setRidgeOpeningWidth(newValue) {
        if (newValue === '') {
            return this.assessor.data.ridge_opening_width = null;
        }

        this.assessor.data.ridge_opening_width = parseFloat(convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getInletArea(roundPoint) {
        if (this.assessor.data.inlet_area === undefined || this.assessor.data.inlet_area === null) {
            return null;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.inlet_area.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.inlet_area)
            .from('m2')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm2'))
            .toDecimal(roundPoint);
    }

    setInletArea(newValue) {
        if (newValue === '') {
            return this.assessor.data.inlet_area = null;
        }

        this.assessor.data.inlet_area = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm2'))
                .to('m2'))
            .toDecimal(newValue.countDecimals() + 3);
    }

  

  getFanWidth(fan_width = "fan_width") {
    if (!this.assessor.data[fan_width]) {
      return null;
    }

    return convert(this.assessor.data[fan_width])
      .from("cm")
      .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), "cm"))
      .toDecimal(0);
  }

  setFanWidth(newValue, fan_width = "fan_width") {
    if (newValue < 1) {
      return this.assessor.data[fan_width] = null;
    }

    return this.assessor.data[fan_width] = parseFloat(convert(newValue)
    .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
    .to('cm'))
    .toDecimal(newValue.countDecimals() + 3);
  }

  /**
   * Calculations
   */
  getRidgePitch() {
    if (this.getRoofHeight() && this.getBarnEaveHeight() && this.getBarnWidth()) {
        let rise = this.calculateRise();
        let run = this.calculateRun();

        if (rise > 0 && run > 0) {
            // console.log('HIT CALC getRidgePitch'), String(run + this.app.$t(":") + rise);
            return String(run + this.app.$t(":") + rise);
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}

    getTargetRidgePitch() {
        return this.targetRidgePitch;
    }

    calculateRise() {
        if (this.getRoofHeight() && this.getBarnEaveHeight()) {
            return this.getRoofHeight() - this.getBarnEaveHeight();
        }

        return 0;
    }

    calculateRun() {
        if (this.getBarnWidth()) {
            return this.getBarnWidth() / 2;
        }

        return 0;
    }

    getRidgeOpening() {
        if (this.getBarnWidth()) {
            if (this.evaluation.getMeasurements() === 'metric') {
                return Math.round(this.getBarnWidth() * 3.28 / 10 * 2 * 2.54);
            } else {
                return Math.round(this.getBarnWidth() / 10 * 2);
            }
        }
    }

    getCrossSectionalArea() {
      let result;
      if (
        this.getBarnWidth() &&
        this.getBarnEaveHeight() &&
        this.getRoofHeight()
      ) {
        result =
          this.getBarnWidth() * this.getBarnEaveHeight() +
          ((this.getRoofHeight() - this.getBarnEaveHeight()) *
            this.getBarnWidth()) /
            2;
  
        return result;
      }
  
      return null;
    }
  
  getTargetAirMovementCapacity() {
    if (
      this.getBarnLength() &&
      this.getBarnWidth() &&
      this.getBarnEaveHeight()
    ) {
      const targetAirMovementCapacity = this.getAirMovementCapacity();

      if (this.evaluation.getMeasurements() === "metric") {
        return Math.round((targetAirMovementCapacity / 1700) * 0.23225);
      } else {
        return Math.round((targetAirMovementCapacity / 1000) * 2.5);
      }
    }

    return 0;
  }


  getAirMovementCapacity() {
    if (this.isNumberOfFansAvailable("number_of_fans")) {
      const rise = this.calculateRise();
      const run = this.calculateRun();
      const endArea =
        this.getBarnWidth() * this.getBarnEaveHeight() + rise * run;
            if (this.evaluation.getMeasurements() === 'metric') {
                return Math.round(endArea * 4023);
            } else {
                return Math.round(endArea * 220);
            }
        }

        return 0;
    }

  //   we've three fields in number of fans we're checking is any field have value;
  isNumberOfFansAvailable(key) {
    for (let i = 1; i < 4; i++) {
      if (this.assessor.data[`${key}_${i}`]) return true;
      continue;
    }
    return false;
  }

  getAirMovementCapacityNatural() {
    if (this.isNumberOfFansAvailable("number_of_fans_natural")) {
      const rise = this.calculateRise();
      const run = this.calculateRun();
      const endArea =
        this.getBarnWidth() * this.getBarnEaveHeight() + rise * run;

            if (this.evaluation.getMeasurements() === 'metric') {
              return Math.round(endArea * 4023);
            } else {
                return Math.round(endArea * 220);
            }
          }

    return 0;
  }

  getDisplayedAirMovementCapacity() {
    let totalAirMovementCapacity = 0.0;
    if (
      this.getFanWidth("fan_width_1") &&
      this.getNumberOfFans("number_of_fans_1")
    ) {
      totalAirMovementCapacity +=
        this.getCalcFanWidth("fan_width_1") *
        this.getNumberOfFans("number_of_fans_1");
    }
    if (
      this.getFanWidth("fan_width_2") &&
      this.getNumberOfFans("number_of_fans_2")
    ) {
      totalAirMovementCapacity +=
        this.getCalcFanWidth("fan_width_2") *
        this.getNumberOfFans("number_of_fans_2");
    }
    if (
      this.getFanWidth("fan_width_3") &&
      this.getNumberOfFans("number_of_fans_3")
    ) {
      totalAirMovementCapacity +=
        this.getCalcFanWidth("fan_width_3") *
        this.getNumberOfFans("number_of_fans_3");
    }


    return totalAirMovementCapacity || null;
  }

  getDisplayedAirMovementCapacityNatural() {
    let totalAirMovementCapacity = 0.0;
    if (
      this.getFanWidth("fan_width_natural_1") &&
      this.getNumberOfFans("number_of_fans_natural_1")
    ) {
      totalAirMovementCapacity +=
        this.getCalcFanWidth("fan_width_natural_1") *
        this.getNumberOfFans("number_of_fans_natural_1");
    }
    if (
      this.getFanWidth("fan_width_natural_2") &&
      this.getNumberOfFans("number_of_fans_natural_2")
    ) {
      totalAirMovementCapacity +=
        this.getCalcFanWidth("fan_width_natural_2") *
        this.getNumberOfFans("number_of_fans_natural_2");
    }
    if (
      this.getFanWidth("fan_width_natural_3") &&
      this.getNumberOfFans("number_of_fans_natural_3")
    ) {
      totalAirMovementCapacity +=
        this.getCalcFanWidth("fan_width_natural_3") *
        this.getNumberOfFans("number_of_fans_natural_3");
    }

    return totalAirMovementCapacity || null;
  }

  getCalcFanWidth(fan_width) {
    let result;

    const input = parseFloat(this.assessor.data[fan_width]);
    const imperialMeasurements =
      this.evaluation.getMeasurements() === "imperial";
    if (input >= 1 && input < 40.64) {
      result = imperialMeasurements ? 1790 : 3043;
    } else if (input >= 40.64 && input < 45.72) {
      result = imperialMeasurements ? 2920 : 4964;
    } else if (input >= 45.72 && input < 60.96) {
      result = imperialMeasurements ? 4200 : 7140;
    } else if (input >= 60.96 && input < 91.44) {
      result = imperialMeasurements ? 5600 : 9520;
    } else if (input >= 91.44 && input < 121.92) {
      result = imperialMeasurements ? 11000 : 18700;
    } else if (input >= 121.92 && input < 127) {
      result = imperialMeasurements ? 20000 : 34000;
    } else if (input >= 127 && input < 129.54) {
      result = imperialMeasurements ? 22000 : 37400;
    } else if (input >= 129.54 && input < 137.16) {
      result = imperialMeasurements ? 24000 : 40800;
    } else if (input >= 137.16) {
      result = imperialMeasurements ? 27000 : 45900;
    } else {
      result = 0;
    }

    return result;
  }
}

export default FreestallVentilation;
