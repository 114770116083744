import {
    tieStallStanchion
} from './tieStallStanchion';

import {
    medias
} from './../Global/medias.js';

class TieStallStanchionAssessorSlides {

    constructor(app, report, evaluation, group, user) {
        this.app = app;
        this.report = report;
        this.evaluation = evaluation;
        this.group = group;
        this.user = user;
    }

    add(tableOfContentsCalculatorPosition) {
        let assessor = this.group.getAssessorByType("10");

        if (assessor === false || assessor.assessor.data === undefined) {
            throw this.group.getName() + this.app.$t(" - ") + this.app.$t("Tie Stall/Stanchion") + this.app.$t(" assessor is not ready.");
        }

        this.report.tableOfContentsHelper("Tie Stall/Stanchion", tableOfContentsCalculatorPosition, this.group);

        tieStallStanchion(this.app, this.report, this.evaluation, this.group, assessor);
        medias(this.app, this.report, this.group, "10", this.user);
    }

}

export default TieStallStanchionAssessorSlides;