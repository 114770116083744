<template>
  <div class="container main-container main-container--hygiene view">
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">
          {{ $t('Tell Us About Your Biosecurity Procedures') }}
        </h2>
      </div>
    </div>

    <hr class="mb-lg-4" />

    <question
      @change="
        assessor.data.are_any_cows_heifers_calves_moving_on = $event.value
      "
      :initial-value="assessor.data.are_any_cows_heifers_calves_moving_on"
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Are any cows/heifers/calves moving on to the main farm coming from a property where hygiene practices and previous infectious disease presence is unknown?'
            )
          }}
        </label>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.is_the_dairy_bringing_any_cattle_onto_the_dairy =
          $event.value
      "
      :initial-value="
        assessor.data.is_the_dairy_bringing_any_cattle_onto_the_dairy
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Is the dairy bringing ANY cattle (purchased or from custom heifer grower) on to the dairy?'
            )
          }}
        </label>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.are_cattle_from_the_dairy_shown_at_fairs_and_cattle_shows =
          $event.value
      "
      :initial-value="
        assessor.data.are_cattle_from_the_dairy_shown_at_fairs_and_cattle_shows
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{ $t('Are cattle from the dairy shown at fairs and cattle shows?') }}
        </label>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.are_returning_cattle_or_purchased_cattle_footbathed_before_commingling_with_the_main_herd =
          $event.value
      "
      :initial-value="
        assessor.data
          .are_returning_cattle_or_purchased_cattle_footbathed_before_commingling_with_the_main_herd
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Are returning cattle or purchased cattle footbathed before co-mingling with the main herd?'
            )
          }}
        </label>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.is_the_hoof_trimmers_equipment_always_clean = $event.value
      "
      :initial-value="assessor.data.is_the_hoof_trimmers_equipment_always_clean"
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Is the hoof trimmer’s equipment always clean (manure free) before arrival on the dairy?'
            )
          }}
        </label>
      </div>
    </question>

    <hr />

    <question
      @change="
        assessor.data.does_the_herd_test_for_bvd_pi_cattle = $event.value
      "
      :initial-value="assessor.data.does_the_herd_test_for_bvd_pi_cattle"
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{ $t('Does the herd test for BVD PI cattle?') }}
        </label>
      </div>
    </question>

    <hr v-if="assessor.data.does_the_herd_test_for_bvd_pi_cattle === 1" />

    <question
      v-if="assessor.data.does_the_herd_test_for_bvd_pi_cattle === 1"
      @change="
        assessor.data.have_any_pi_cattle_been_identified_in_the_last_12_months =
          $event.value
      "
      :initial-value="
        assessor.data.have_any_pi_cattle_been_identified_in_the_last_12_months
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{ $t('Have any PI cattle been identified in the last 12 months?') }}
        </label>
      </div>
    </question>

    <hr />

    <div class="row my-0">
      <div class="col-md-12">
        <personal-notes v-model="assessor.data.personal_notes"></personal-notes>
      </div>
      <div class="col-md-12">
        <presentation-notes
          v-model="assessor.data.presentation_notes"
        ></presentation-notes>
      </div>
    </div>
    <div
      class="row mx-0 mt-4 justify-content-center justify-content-md-start center align-items-center"
    >
      <photoUpload :assessorId="6" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

    <div class="row misc-bottom">
      <div class="col-md-12 flex-sm-row-reverse">
        <a
          class="btn btn-secondary btn-full--sm mb-2 mt-md-0"
          href="javascript:history.go(-1)"
          >{{ 'Back' | translate }}</a
        >
      </div>
      <div class="col-md-12">
        <router-link
          v-if="user && user.role !== 'data_entry'"
          :to="'/reports'"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Reports' | translate }}
        </router-link>
        <button
          v-else-if="
            user && user.role === 'data_entry' && !$store.state.offline
          "
          data-toggle="modal"
          @click="requestReport(evaluation.name)"
          :data-target="['#requestSent-' + user.id]"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Request Report' | translate }}
          <span v-show="requestReportLoader" class="loader ml-1"></span>
        </button>
      </div>
    </div>

    <div
      v-show="successMessage"
      class="alert alert-primary mt-3 mb-0"
      role="alert"
    >
      {{ 'Your request has been sent!' | translate }}
    </div>

    <div
      v-show="errorMessage"
      class="alert alert-danger mt-3 mb-0"
      role="alert"
    >
      {{ 'An error has occurred during your request!' | translate }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import PresentationNotes from './assessor_components/presentation-notes';
import PersonalNotes from './assessor_components/personal-notes';
import Question from './assessor_components/question';
import ModalInfo from '@/components/misc/modal-info.vue';
import PhotoUpload from '@/components/misc/photoUpload';
import HygieneHeader from './assessor_components/hygiene-header.vue';
import Evaluation from '@/libs/Classes/Evaluation.js';
import Group from '@/libs/Classes/Group.js';
import convert from 'convert-units';
import requestReportMixin from '@/mixins/requestReport.js';

export default {
  components: {
    PersonalNotes,
    PresentationNotes,
    Question,
    HygieneHeader,
    PhotoUpload,
    ModalInfo,
  },
  mixins: [requestReportMixin],
  props: ['assessor', 'group', 'evaluation'],
  data() {
    return {};
  },
  created() {
    // this.user = this.$store.getters.getData("user");
  },
  methods: {},
};
</script>
