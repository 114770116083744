<template>
    <div>
        <div class="fixed-loading" v-show="!isLoaded">
            <div class="loading">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        </div>

        <div class="container main-container main-container--accordion" >
            <div class="card mb-0">
                <h1 class="h1">{{"Upload User Guides" | translate}}</h1>
                <div class="card-body p-4">
                    <div v-for="file in guideList" :key="file.id">
                        <div class="card-body__top">
                            <h2 class="h2 mb-0">{{ file.title | translate }}</h2>
                        </div>
                        <div class="row card-section mt-0">
                            <div class="file-info" v-if="file.metadata">
                                <p>{{ 'Last Update' }}: {{ lastUpdated(file) }}</p>
                                <a class="file-link" :href="file.metadata.fileUrl" target="_blank">
                                    Open
                                    <svg version="1.1" viewBox="0 0 100 125" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fill-rule="evenodd">
                                            <path d="m43.505 50.11 6.6231 6.6231 35.71-35.71-0.078835 20.871 9.347-0.034022 0.13247-36.861-36.861 0.13247-0.034261 9.3468 20.871-0.078835-35.71 35.71zm6.3927-34.151-2.526e-4 11.291-33.695 6.243e-4v56.458h56.159l-8.79e-4 -33.876 11.233 8.728e-4v45.166h-78.623v-79.041h44.927z" fill="#000"/>
                                        </g>
                                    </svg>
                                </a>
                            </div>
                            <div class="col-md-24 background-white p-2 mb-3 border">
                                <input :name="file.id" type="file" class="upload-file-input" @change="inputFile">
                            </div>
                            <div class="col-md-24 text-right p-0">
                                <button class="btn btn-primary btn-full--sm" @click="fileUpload(file.id)">{{ "Upload" | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <!-- end row -->
        </div>
    </div>
</template>

<script>
import AppHeader from '../../../components/AppHeader';
import AppFooter from '../../../components/AppFooter';

export default {
  components: {
    AppHeader,
    AppFooter
  },

  data() {
    return {
      info: false,
      infoMessage: '',
      isLoaded: true,
      guidesRef: null,
      storageList: [],
      guideList: null
    }
  },

  methods: {
    inputFile(e) {
      const fileID = e.target.name
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.guideList[this.guideList.findIndex(el => el.id === fileID)].file = files[0]
      console.log(this.guideList)
      console.log(fileID)
    },
    fileUpload(id) {
      let doc = this.guideList[this.guideList.findIndex(el => el.id === id)]
      console.log({doc});
      if (doc.file === null) {
        alert('Please upload a file "' + doc.title + '"')
        return
      }
      this.isLoaded = false
      // upload to firebase storage
      const name = doc.id;
      const metadata = {
        contentType: doc.file.type,
        customMetadata: {
          fileId: id,
          fileTitle: doc.title,
          lastUpdated: new Date().toGMTString()
        }
      };
      const task = this.guidesRef.child(name).put(doc.file, metadata);
      task.then(() => {
        doc.file = null
        document.querySelector('input[name=' + doc.id + ']').value = ''
      })
        // .then(snapshot => snapshot.ref.getDownloadURL())
        // .then(url => console.log(url))

      this.updateList()
    },
    updateList() {
      let _this = this
      this.guidesRef.listAll()
        .then(function(res) {
          res.items.forEach(function(itemRef) {
            let fileUrl = null
            itemRef.getDownloadURL().then(function (url) {
              fileUrl = url
            })
            itemRef.getMetadata().then(function(metadata) {
              _this.storageList.push({ ...metadata, fileUrl })
              console.log(metadata)
              _this.guideList[_this.guideList.findIndex(el => el.id === metadata.name)].metadata = { ...JSON.parse(JSON.stringify(metadata)), fileUrl }
            }).catch(function(error) {
              // Uh-oh, an error occurred!
            });
          });
          _this.isLoaded = true
          _this.$forceUpdate()
        })
        .catch(function(error) {
          console.log('An error occurred!', error)
          _this.isLoaded = true
        });
    }
  },
  async created() {
    // TODO:
    // const storageRef = this.$firebase.storage().ref()
    // this.guidesRef = storageRef.child('guides/')
    this.guideList = this.$store.state.guideList
  },
  mounted() {
    this.isLoaded = true
  }
}
</script>
<style>
    input[type='file'].upload-file-input {
        display: block;
        position: relative;
        opacity: 1;
        z-index: 2;
    }
    .file-info {
        display: flex;
        align-items: center;
    }
    .file-link {
        color: #0000FF;
        text-decoration: underline;
        margin: 0 10px;
        position: relative;
    }

    .file-link svg {
        height: 16px;
        width: 11px;
        margin: 13px 3px;
        position: absolute;
        right: -17px;
        top: -12px;
    }

    .fixed-loading {
        position: fixed;
        background: rgba(255,255,255,0.7);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        z-index: 9999;
        top: 0;
        left: 0;
    }
</style>
