/**
 * The time budget assessor allows the user to calculate the laying time for a cow in a typical day by taking estimated times that the cows
 * do certain actions and producing a calculated estimated lying time. The system will make certain recommendations based off the
 * information input and the calculated lying time.
 */

const convert = require('convert-units');
import Assessor from './Assessor';

class TimeBudget extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;
        this.minSoftBeddingDepth = 20;
        this.defaultMilkingsPerDay = 2;
        this.defaultCowsMilkedPerHour = 64;
    }

    getProductionType() {
        return this.assessor.data.production_type;
    }

    getProductionTypeTitle() {
        switch (this.assessor.data.production_type) {
            case 'confined_cattle': return 'Confined Cattle'
            case 'dirt_lot': return 'Dirt Lot'
            case 'grazing_cattle': return 'Grazing Cattle'
            case 'closed_lot': return 'Closed Lot'
            case 'open_lot': return 'Open Lot'
        }
    }

    getCowsMilkedPerHour() {
        return this.assessor.data.milked_per_hour;
    }

    getMilkingsPerDay() {
        return this.assessor.data.milkings_per_day;
    }

    getDefaultMilkingsPerDay() {
        return this.defaultMilkingsPerDay;
    }

    getDefaultCowsMilkedPerHour() {
        return this.defaultCowsMilkedPerHour;
    }

    getStallsAvailableInPen() {
        if(this.assessor.data.stalls_available_in_pen === undefined || this.assessor.data.stalls_available_in_pen === null) {
            return undefined;
        }

        return this.assessor.data.stalls_available_in_pen;
    }

    getCowsStandingOrLyingInStall() {
        if(this.assessor.data.cows_standing_or_lying_in_stall === undefined || this.assessor.data.cows_standing_or_lying_in_stall === null) {
            return undefined;
        }

        return this.assessor.data.cows_standing_or_lying_in_stall;
    }

    getCowsStandingOnlyInStall() {
        return this.assessor.data.cows_only_standing;
    }

    getMinSoftBeddingDepth() {
       return convert(this.minSoftBeddingDepth)
        .from('cm')
        .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
        .toDecimal(0);
    }

    getWalkingTimePerDay() {
        return this.assessor.data.walking_time_per_day;
    }

    getTimeHeldAwayFromPaddocks() {
        return this.assessor.data.time_held_from_paddocks ? parseFloat(this.assessor.data.time_held_from_paddocks.replace(',','.')) : 0
    }

    // @NOTE final
    getQuantityOfGrainSupplements(roundPoint) {
        if ( this.assessor.data.quantity_of_grain_supplements === undefined || this.assessor.data.quantity_of_grain_supplements === null ) {
            return undefined;
        }

        if ( roundPoint === false ) {
            roundPoint = this.assessor.data.quantity_of_grain_supplements.countDecimals() - 3;
            if(roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.quantity_of_grain_supplements)
            .from('kg')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg'))
            .toDecimal(roundPoint);
    }

    setQuantityOfGrainSupplements(newValue) {
        this.assessor.data.quantity_of_grain_supplements = parseFloat(convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(),'kg'))
            .to('kg'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getSoftBedding() {
        return parseInt(this.assessor.data.soft_bedding);
    }

    getMilkingHours() {
        const cowsMilkedPerHourAsNumber = Number.parseFloat(this.getCowsMilkedPerHour()) || 0;

        if (cowsMilkedPerHourAsNumber > 0) {
            const milkingsPerDayAsNumber = Number.parseFloat(this.getMilkingsPerDay()) || 0;
            const groupSizeAsNumber = Number.parseFloat(this.group.getGroupSize()) || 0;

            return Number((groupSizeAsNumber / cowsMilkedPerHourAsNumber * milkingsPerDayAsNumber).toFixed(1));;
        }

        return 0;
    }

    getDrinkingHours() {
        return .5;
    }

    getEatingHours() {
        if ( this.getProductionType() ===  "grazing_cattle") {

            if (this.getQuantityOfGrainSupplements()) {

                let metricEatingHours = Math.round((578 - (12 * this.getQuantityOfGrainSupplements())) / 60);

                // @TODO This doesn't make sense
                return convert(metricEatingHours)
                 .from('cm')
                 .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
                 .toDecimal(0);

            } else {
                return 9.6;
            }

        } else {
            return 4.5;
        }

    }

    getStockingRate() {
        if ( this.getProductionType() ===  "confined_cattle" && this.getStallsAvailableInPen() > 0) {
            return (this.group.getGroupSize() / this.getStallsAvailableInPen());
        }

        return 0;
    }

    getStockingRatePercentage() {
        return (this.getStockingRate() * 100).toDecimal(1);
    }

    getInitialAlleyStandingHours() {
        if ( this.getProductionType() ===  "confined_cattle" ) {
            return 2;
        }

        return 0;
    }

    getStallStandingHours() {
        if ( this.getProductionType() ===  "confined_cattle" ) {
            return Math.round(Math.max(this.getStallStandingIndex() * 16 - 1, 0.1) * 10) / 10;
        }

        return 0;
    }

    getInitialLyingHours() {
        return Math.max(24 - this.getNotLyingHours(), 0).toFixed(1);
    }

    getLyingHours() {
        if ( this.getProductionType() === "confined_cattle" && this.getStockingRate() > 1 ) {
            return Number(this.getInitialLyingHours() - this.getAlleyStandingHours() + this.getInitialAlleyStandingHours()).toFixed(1);
        }

        return this.getInitialLyingHours();
    }

    getNotLyingHours() {
        let notLyingHours = 0;

        notLyingHours += this.getMilkingHours();
        notLyingHours += this.getEatingHours();
        notLyingHours += this.getDrinkingHours();

        if ( this.getProductionType() ===  "confined_cattle" ) {
            notLyingHours += this.getStallStandingHours();
            notLyingHours += this.getInitialAlleyStandingHours();
        } else {
            notLyingHours += Math.round(this.getWalkingTimePerDay() / 60);
            notLyingHours += this.getTimeHeldAwayFromPaddocks();
        }

        return notLyingHours;
    }

    getInitalLyingHours() {
        return Math.round(Math.max(24 - this.getNotLyingHours(), 0));
    }

    getAlleyStandingHours() {
        if ( this.getProductionType() ===  "confined_cattle" && this.getStockingRate() > 1) {
            return Math.round(this.getInitialAlleyStandingHours() + (0.2427 * (this.getStockingRate() - 1) * this.getInitalLyingHours()));
        }

        return this.getInitialAlleyStandingHours();
    }

    getWalkingToPaddock() {
        return Math.round(this.getWalkingTimePerDay() / 60);
    }

    getWalkingAwayFromPaddock() {
        this.getTimeHeldAwayFromPaddocks();
    }

    getStallStandingIndex() {
        if (this.getCowsStandingOrLyingInStall() > 0) {
            return (this.getCowsStandingOnlyInStall() / this.getCowsStandingOrLyingInStall());
        }
        return this.getCowsStandingOnlyInStall();
    }

    getStallStandingIndexPercentage() {
        return (this.getStallStandingIndex() * 100).toDecimal(1);
    }

};

export default TimeBudget;
