<template>
  <div class="vue-header-by-section-dropdown" v-if="group">
    <template v-if="$route.query.assessor_nav==='group'">
      <a class="nav-link border-none-md d-lg-none"
         @click="isOpen = !isOpen"
         :aria-expanded="isOpen">
        {{ 'By Assessor' | translate }}
        <span class="vue-header-by-section-dropdown__angle">
          <i class="fa fa-angle-down fa-angle-down--font-lg open-dropdown" aria-hidden="true" v-show="!isOpen"></i>
          <i class="fa fa-angle-up fa-angle-up--font-lg open-dropdown" aria-hidden="true" v-show="isOpen"></i>
        </span>
      </a>
      <div class="collapse d-lg-none show" v-show="isOpen">
        <li class="dropdown-item dropdown-item--second pt-0 pr-0 pb-0 pl-0">
          <ul class="dropdown-menu multi-level show" role="menu"
              aria-labelledby="dropdownMenu" id="headerAccordions" data-children=".dropdown-item">
            <div v-for="(assessorType, index) in evaluation.order"
                 :key="index" class="border-bottom-white submenu">
              <li class="dropdown-item dropped" v-if="getFirstGroupByAssessorType(assessorType, evaluation.groups)">
                <router-link @click="forceUpdate()"
                             :to="'/users/' + $route.params.userId + '/evaluations/' + evaluation.id + '/groups/' + (getFirstGroupByAssessorType(assessorType, evaluation.groups).id) + '/assessors/' + assessorType + '/?assessor_nav=assessor'"
                             class="dropdown-item__link nav-link">
                  {{ $store.getters.getAssessorTypeById(assessorType).name | translate }}
                </router-link>
                <a class="fa fa-angle-down fa-angle-down--font-lg open-dropdown"
                   @click="openDropdown($event, index)" aria-expanded="false"></a>
              </li>
              <div class="collapse dark-list" :class="['header-accordion-'+index]">
                <li v-for="( groupObj, g_index ) in getGroupsByAssessorType(assessorType, evaluation.groups)"
                    class="dropdown-item dropdown-item--second" :key="g_index"
                    v-if="groupObj !== undefined">
                  <status :assessor="getAssessor(assessorType, groupObj)" :border="'grey'"/>
                  <router-link @click="forceUpdate()"
                               :to="'/users/' + $route.params.userId + '/evaluations/' + evaluation.id + '/groups/' + groupObj.id  + '/assessors/' + assessorType + '/?assessor_nav=assessor'"
                               class="dropdown-item__link nav-link"
                  >
                    {{ groupObj.name }}
                  </router-link>
                </li>
              </div>
            </div>
            <li class="dropdown-item border-bottom-white">
              <router-link :to="'/users/' + $route.params.userId + '/evaluations/' + evaluation.id +'/assessor-summary'"
                           class="nav-link" exact>
                {{ 'Assessor Summary' | translate }}
              </router-link>
            </li>
            <li class="dropdown-item">
              <router-link @click="forceUpdate()"
                           :to="'/users/' + $route.params.userId + '/evaluations/' + evaluation.id + '/order/'"
                           class="nav-link"
                           exact>
                {{ 'Edit Assessor Order' | translate }}
              </router-link>
            </li>
          </ul>
        </li>
      </div>
    </template>
    <template v-else>
      <a class="nav-link border-none-md d-lg-none"
         @click="isOpen = !isOpen"
         :aria-expanded="isOpen">
        {{ 'By Group' | translate }}
        <span class="vue-header-by-section-dropdown__angle">
          <i class="fa fa-angle-down fa-angle-down--font-lg open-dropdown" aria-hidden="true" v-show="!isOpen"></i>
          <i class="fa fa-angle-up fa-angle-up--font-lg open-dropdown" aria-hidden="true" v-show="isOpen"></i>
        </span>
      </a>
      <div class="collapse d-lg-none show" v-show="isOpen">
        <div class="dropdown-item dropdown-item--second pt-0 pr-0 pb-0 pl-0">
          <ul class="dropdown-menu multi-level show" role="menu"
              aria-labelledby="dropdownMenu">
            <div v-for="(group, index) in evaluation.groups" :key="index"
                 class="border-bottom-white submenu">
              <li class="dropdown-item">
                <router-link @click="forceUpdate()"
                             :to="'/users/' + $route.params.userId + '/evaluations/' + evaluation.id + '/groups/' + group.id + '/assessors/' + group.assessor_list[0] + '/?assessor_nav=group'"
                             class="dropdown-item__link nav-link">
                  {{group.name}}
                </router-link>
                <a class="fa fa-angle-down fa-angle-down--font-lg open-dropdown"
                   @click="openDropdown( $event, 'h' + index )"></a>
              </li>
              <div class="collapse dark-list" :class="'header-accordion-' + 'h' + index">
                <li v-for="( assessorType, a_index ) in group.assessor_list"
                    class="dropdown-item dropdown-item--second" :key="a_index">
                  <router-link @click="forceUpdate()"
                               :to="'/users/' + $route.params.userId + '/evaluations/'+evaluation.id+'/groups/'+group.id+'/assessors/'+assessorType+'/?assessor_nav=group'"
                               class="dropdown-item__link nav-link"
                  >
                    {{ $store.getters.getAssessorTypeById(assessorType).name | translate }}
                  </router-link>
                </li>
              </div>
            </div>
            <li class="dropdown-item">
              <router-link :to="'/users/' + $route.params.userId + '/evaluations/' + evaluation.id + '/groups'"
                           class="dropdown-item__link nav-link">
                {{ 'Add/Edit Groups' | translate }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Status from '@/components/evaluations/status.vue';

export default {
  name: 'HeaderBySectionDropdown',
  components: {
    Status
  },
  props: {
    /**
     * "assessor" or "group"
     */
    section: {
      type: String,
      default: "assessor"
    },
    group: {
      type: Object,
      default: () => {
      }
    },
    evaluation: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {},
  methods: {
    openDropdown(e, index) {
      let dropdown = $(e.target).closest('.submenu').find('.header-accordion-' + index);
      dropdown.toggleClass('dropped');
      if (dropdown.hasClass("dropped")) {
        $(dropdown).prev().children('.open-dropdown').removeClass("fa-angle-down");
        $(dropdown).prev().children('.open-dropdown').addClass("fa-angle-up");
      } else {
        $(dropdown).prev().children('.open-dropdown').addClass("fa-angle-down");
        $(dropdown).prev().children('.open-dropdown').removeClass("fa-angle-up");
      }
      dropdown.slideToggle();
      e.preventDefault();
      e.stopPropagation();
    },
    getAssessor(assessorType, group) {
      if (group.assessors === undefined) return {};

      if (Array.isArray(group.assessors)) {
        return group.assessors.find(assessor => {
          if (assessorType === assessor.type) return true;
          return false;
        });
      } else {
        for (let assessorId in group.assessors) {
          if (assessorType === group.assessors[assessorId].type) {
            return group.assessors[assessorId];
          }
        }
      }
    },
    getFirstGroupByAssessorType(assessorType, groups) {
      if (Array.isArray(groups)) {
        return groups.filter(group => {
          return group.assessor_list && group.assessor_list.includes(assessorType);
        })[0];
      } else {
        let filteredGroups = {};
        for (let groupId in groups) {
          if (groups[groupId].assessor_list && groups[groupId].assessor_list.includes(assessorType)) {
            return groups[groupId];
          }
        }
      }
      return false;
    },
    getGroupsByAssessorType(assessorType, groups) {
      if (Array.isArray(groups)) {
        return groups.filter(group => {
          return group.assessor_list && group.assessor_list.includes(assessorType);
        });
      } else {
        let filteredGroups = {};
        for (let groupId in groups) {
          if (groups[groupId].assessor_list && groups[groupId].assessor_list.includes(assessorType)) {
            filteredGroups[groupId] = groups[groupId];
          }
        }
        return filteredGroups;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .vue-header-by-section-dropdown {
    min-width: 250px;
    user-select: none;
    a.nav-link {
      cursor: pointer;
      color: white;
      font-family: "Helvetica Neue LT W01_77 Bd Cn";
    }
    & > a.nav-link:hover {
      color: #ffffff!important;
      text-decoration: none;
      background: #0000FF;
    }

    .submenu > .collapse {
      a.nav-link {
        color: black;
      }
    }
  }

  .header__main .right-menu .dropdown-menu {
    transform: translate(0px, 0px) !important;
    margin: 0px !important;
    width: 100%;
  }

  .dropdown-item--second .fa {
    color: #0000FF;
  }

  .dark-list {
    .dropdown-item--second {
      padding: 0 10px;
      background-color: #ddd !important;
      display: flex;
      align-items: center;
    }
  }

  .dropdown-item {
    padding: 0;
    display: flex;
    width: 100%;

    a {
      width: 100%;
      padding: 10px;
      cursor: pointer;

      &:hover {
        color: black;
      }

      &.fa {
        width: 45px;
        margin-top: 0px;
      }
    }


    &:not(:last-child) {
      border-bottom: 1px solid #fff;
    }

    &:active {
      background: #0000FF;

      & > a, & > .fa {
        color: #fff;
      }
    }
  }

  .left-menu-mobile {
    .vue-header-by-section-dropdown {
      min-width: auto;
      a.nav-link {
        font-size: 18px;
      }

      &__angle {
        margin-left: 10px;
      }

      .collapse.show {
        transform: translate(0, 8px);
        position: relative;
        z-index: 1;
      }

      .dropdown-menu {
        width: 250px;
        background-color: #0000FF;
      }

      .dropdown-menu .nav-link:not(:last-child) {
        border: none;
      }

      .dropdown-item:hover, .dropdown-item:focus {
        text-decoration: none;
        background-color: transparent;

        & > .nav-link {
          color: #62b1e5 !important;
        }
      }

      .dropdown-item--second .fa {
        color: white;
      }
    }
  }

</style>
