import { URLS } from '../../constants';
import axios from '../../../Axios.js';
import {
  GET_DELETE_ASSESSOR,
  GET_UPDATE_ASSESSOR,
  SET_DELETE_ASSESSOR,
  SET_DELETE_ASSESSOR_IN_EVALUATION,
  SET_UPDATE_ASSESSOR,
  SET_UPDATE_ASSESSOR_IN_EVALUATION,
} from '../types';

const state = {
  assessors: {
    id: null,
    evaluetionId: null,
    assessorIndexId: null,
    dateCreated: null,
    dateUpdated: null,
    order: null,
  },
};

const getters = {};

const mutations = {
  [SET_UPDATE_ASSESSOR](STATE) {
    return STATE;
  },
  [SET_DELETE_ASSESSOR](STATE) {
    return STATE;
  },
};

const actions = {
  [GET_UPDATE_ASSESSOR]({ commit: COMMIT }, assessor) {
    COMMIT(`evaluations/${SET_UPDATE_ASSESSOR_IN_EVALUATION}`, assessor, {
      root: true,
    });
    return new Promise(function(resolve, reject) {
      axios
        .post(URLS.ASSESSORS, assessor)
        .then(function(res) {
          COMMIT(SET_UPDATE_ASSESSOR, res.data.data);
          resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
  [GET_DELETE_ASSESSOR](
    { commit: COMMIT },
    { groupId, assessorId, evaluationId }
  ) {
    return new Promise(function(resolve, reject) {
      const url = `${URLS.EVALUATION}/${evaluationId}/group/${groupId}/assessor/${assessorId}`;
      axios
        .delete(url)
        .then(function(res) {
          COMMIT(SET_DELETE_ASSESSOR, res.data.data);
          COMMIT(
            `evaluations/${SET_DELETE_ASSESSOR_IN_EVALUATION}`,
            { groupId, assessorId, evaluationId },
            {
              root: true,
            }
          );
          return resolve(res.data.data);
        })
        .catch(function(err) {
          return reject(err.response ? err.response.data : err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
