import _ from 'lodash';
import { transitionConstants } from '@/libs/Classes/constants/transition.js';

export const comparedModes = {
  LR_EQUAL: 'lr_equal', 
  M_EQUAL: 'm_equal',
}

export const colors = {
  red: 'CC0000',
  orange: 'FFCC66',
  green: '00CC00',
};

export const create = (slide, headerRows, bodyRows, report, group, assessorName, options) => {
  let concatObj = bodyRows;
  if (headerRows !== null && headerRows.length > 0) {
    concatObj = [[...headerRows], ...bodyRows];
  }
  let presets = {
    fontSize: 11,
    border: {
      pt: '1',
      color: '9a9a9a',
    },
    color: 'ffffff',
    newPageStartY: 2.5,
  };

  // The following syntax will merged the two options defaulting to options when keys match.
  var mergedObject = {
    ...presets, // jshint ignore:line
    ...options, // jshint ignore:line
  };

  let originalSlideLength = report.pptx.slides.length;
  console.log({ concatObj }, Array.isArray(concatObj))
  slide.addTable(concatObj, mergedObject);

  let updatedSlideLength = report.pptx.slides.length;

  // show assessor and group name on page where table has moved onto next pag
  let difference = updatedSlideLength - originalSlideLength;

  let newSlides = _.takeRight(report.pptx._slides, difference);

  if (originalSlideLength !== updatedSlideLength) {
    let newSlideTitle = group.app.$t(assessorName);
    if (typeof group.group !== 'undefined') {
      newSlideTitle += group.app.$t(' - ') + group.group.name;
    }
    for (let i = 0; i < newSlides.length; i++) {
      newSlides[i].addText(newSlideTitle, report.styleOptions.slideHeader);
    }
  }
};

export const createRowHeading = headings => {
  // If more props needed, create object instead simple args ex: { heading, someProp }
  return headings.map((heading, idx) => ({
    text: heading,
    options: {
      color: '000000',
      valign: 'center',
      align: idx ? 'center' : 'left',
      fill: 'dddddd',
      fontFace: 'Arial',
      bold: true,
    },
  }));
};

export const createRowValues = optionsArr => {
  return optionsArr.map(({ text = '', options }, idx) => ({
    text,
    options: {
      color: '000000',
      fill: 'FFFFFF',
      valign: 'center',
      align: idx ? 'center' : 'left',
      fontFace: 'Arial',
      margin: 5,
      bold: idx ? true : false,
      ...options,
    },
  }));
};

export const compareValues = (field, fieldValue, { mode }) => {   
  // Function requires existen min/max values in @transitionConstants
  let min = transitionConstants[field + '_min'];
  let mid = transitionConstants[field + '_mid'];
  let max = transitionConstants[field + '_max'];

  let isMin = false,
    isMid = false,
    isMax = false;

  if (typeof fieldValue === 'number') {
    if (mode === comparedModes.M_EQUAL) {
      isMin = fieldValue < min
      isMid = fieldValue >= min && fieldValue <= max
      isMax = fieldValue > max
    } else if (mode === comparedModes.LR_EQUAL) {
      isMin = fieldValue <= min
      isMid = fieldValue > min && fieldValue < max
      isMax = fieldValue >= max
    }
  } else if (typeof fieldValue === 'string') {
    isMin = min === fieldValue;
    isMid = mid === fieldValue;
    isMax = max === fieldValue;
  }

  return { isMin, isMid, isMax };
};

export const createComparedRow = (field, fieldValue, options = { reverse: false, mode: comparedModes.M_EQUAL }) => {
  if (fieldValue === null ) return [{}, {}, {}];

  let defaultCell = {
    text: '',
    options: {
      color: 'FFFFFF',
      fill: 'FFFFFF',
    },
  };
    
  let row = [_.cloneDeep(defaultCell), _.cloneDeep(defaultCell), _.cloneDeep(defaultCell)];

  let { isMin, isMid, isMax } = compareValues(field, fieldValue, { mode: options.mode || comparedModes.M_EQUAL });

  if (options.reverse) {
    // Swap values
    [isMin, isMax] = [isMax, isMin];
  }

  if (isMin) {
    row[0].text = 'X';
    row[0].options.fill = colors.red;
  } else if (isMid) {
    row[1].text = 'X';
    row[1].options.fill = colors.orange;
  } else if (isMax) {
    row[2].text = 'X';
    row[2].options.fill = colors.green;
  }

  return row;
};
