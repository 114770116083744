<template>
  <div class="container main-container">
    <h1 class="h1 mb-4">{{ 'Terms of Use' | translate }}</h1>
    <div
      v-if="
        this.$store.state.users && this.$store.state.users.user.lang === 'es'
      "
    >
      <h2 class="h2">Por favor, lea con cuidado estos Términos de Uso</h2>
      <p class="p">
        Al utilizar este sitio web de Zinpro FirstStep, sus sistemas subyacentes
        y cualquier aplicación móvil relacionada (el "Sitio web"), Usted acepta
        estar sujeto a los siguientes términos y condiciones (los "Términos de
        uso"). Este sitio web es propiedad y está operado por Zinpro Corporation
        ('Zinpro'). Tal como se utiliza en estos Términos de Uso, 'Zinpro',
        'nosotros' y 'nuestro', todos se refieren al propietario de este sitio
        web, incluidas las compañías que controla, como sus subsidiarias y
        afiliadas.
      </p>

      <p class="p">
        Podemos cambiar los Términos de Uso en cualquier momento sin previo
        aviso. Revise los Términos de Uso cada vez que visite el sitio web. Al
        utilizar este sitio web, significa que acepta y está de acuerdo en
        regirse por la versión más reciente de los Términos de Uso. Si no acepta
        estar sujeto a los Términos de Uso, Usted no podrá ver, acceder o
        utilizar este sitio web.
      </p>

      <h2 class="h2">Aviso de Uso Limitado</h2>

      <p class="p">
        El sitio web está destinado a ser utilizado únicamente como una
        herramienta de apoyo educativo suplementario para ayudar al personal de
        hatos/rebaños lecheros comerciales a evaluar la claudicación/cojera de
        ganado. Las recomendaciones sobre la evaluación de la
        claudicación/cojera, la predicción de la claudicación/cojera y el
        impacto de la claudicación/cojera sobre el potencial de
        ganancias/utilidad se basan en las recomendaciones actuales disponibles
        cuando se escribió esta herramienta de servicio. Las directrices sobre
        la gestión de las claudicaciones/cojeras y la predicción sobre el
        impacto en los beneficios están sujetas a cambios a medida que se
        disponga de nuevos datos. Este sitio web solo está disponible para los
        clientes actuales de Zinpro que están comprando nuestros productos.
      </p>

      <p class="p">
        Al registrarse en este sitio web, usted acepta lo siguiente:
      </p>

      <ul>
        <li>
          La licencia de uso de este sitio web solo es respaldada mientras usa
          Zinpro Performance Minerals®.
        </li>
        <li>
          Sólo los usuarios registrados que cumplan con los criterios de
          aceptación de Zinpro pueden ver o usar este sitio web.
        </li>
        <li>
          Los consultores independientes, como los investigadores afiliados a
          las universidades, son utilizados por el sitio web para revisar y
          evaluar los datos de la claudicación/cojera.
        </li>
        <li>
          Al usar este sitio web, Usted acepta la divulgación y el intercambio
          de información de evaluación de claudicaciones/cojeras asociada a su
          hato/rebaño con consultores independientes.
        </li>
        <li>
          Zinpro se reserva el derecho de terminación del uso del sitio web en
          cualquier momento.
        </li>
      </ul>

      <h2 class="h2">Otorgamiento de licencia</h2>

      <p class="p">
        Zinpro le otorga una licencia limitada, no exclusiva e intransferible
        para acceder y utilizar el sitio web y sus contenidos con fines
        informativos y personales. No se le permite usar el sitio web para
        ningún fin o manera diferente a lo previsto. Usted acepta utilizar el
        sitio web y su contenido únicamente con fines legales adecuados y no con
        fines ilegales, no éticos, fraudulentos, engañosos u otros fines no
        autorizados. Usted acepta no utilizar ningún dispositivo, software o
        rutina para interferir con el funcionamiento adecuado del sitio web.
        Usted acepta no intentar recopilar información sobre otros, incluido el
        correo electrónico o los datos personales.
      </p>

      <h2 class="h2">Su retroalimentación, comentarios e ideas</h2>
      <p class="p">
        Si bien nos complace recibir retroalimentación, comentarios e ideas de
        los visitantes de este sitio web, queremos que comprenda que cualquier
        información que envíe a través de este sitio web se considerará no
        personal, no confidencial y no patentada. Si nos transmite ideas,
        información, conceptos, conocimientos técnicos o técnicas y materiales a
        través de este sitio web, otorga a Zinpro una licencia ilimitada,
        irrevocable, libre de regalías y derechos de autor para usar,
        reproducir, mostrar, ejecutar, modificar, transmitir y distribuir en
        cualquier medio, y acepta que Zinpro es libre de usarlos para cualquier
        propósito. Usted acepta que no estaremos obligados a devolverle ninguna
        aportación de información no solicitada. Usted acepta que tendremos
        derecho al uso sin restricciones de la información aportada, no
        solicitada para cualquier propósito, comercial o de otro tipo, sin
        contraprestación para usted o cualquier otro proveedor de información
        aportada no solicitada.
      </p>

      <h2 class="h2">Enlaces</h2>
      <p class="p">
        El sitio web firststep.zinpro.com incluye enlaces a otras páginas web.
        Estos enlaces a sitios web son operados por otras organizaciones
        propietarias, agencias gubernamentales y empresas / partes privadas.
        Cuando se vincula a otro sitio, ya no se encuentra en el sitio web
        firststep.zinpro.com, y no somos responsables de ninguno de los
        contenidos que pueda ver, usar o tener acceso. Cuando se vincula a otro
        sitio web, está sujeto a los términos y condiciones de ese sitio web. La
        inclusión de cualquier enlace no implica necesariamente una
        recomendación ni respalda las opiniones expresadas en ellos.
      </p>

      <h2 class="h2">Exactitud del contenido</h2>
      <p class="p">
        Ni Zinpro ni ningún funcionario o empleado de Zinpro (las "Partes de
        Zinpro"), garantiza la exactitud, confiabilidad o puntualidad de
        cualquier información publicada por este sitio web. Las Partes de Zinpro
        tampoco avalan ningún contenido, punto de vista, productos o servicios
        vinculados desde este sitio web. Las Partes de Zinpro no serán
        responsables de ninguna pérdida causada por la confianza en la
        exactitud, fiabilidad o puntualidad de dicha información. Partes de
        dicha información pueden ser incorrectas o no actuales. Cualesquiera
        persona o entidad que confíe en cualquier información obtenida de este
        sitio web lo hace bajo su propio riesgo.
      </p>

      <p class="p">
        El sitio web firststep.zinpro.com se presenta como un servicio
        informativo. Cualquier contenido debe ser verificado de forma
        independiente. Los documentos de autoría de agencias estatales y
        federales son de dominio público. Se aplican restricciones de derechos
        de autor y acceso.
      </p>

      <h2 class="h2">Información que recopilamos</h2>
      <p class="p">
        Cuando ve el sitio web, lo hace como un usuario registrado. No
        recopilamos ninguna información personal que lo identifique, a menos que
        nos proporcione esa información de contacto de forma voluntaria. Los
        datos personales incluyen información que es exclusiva de una persona,
        como un nombre, dirección, dirección de correo electrónico o número de
        teléfono. La única información personal que Zinpro obtiene a través de
        su sitio web es suministrada voluntariamente por los visitantes del
        sitio. No compartimos información de usuarios registrados, como la
        ubicación de su granja, con otros usuarios registrados. Al enviar
        información personal, otorga a Zinpro el derecho a transmitir,
        monitorear, recuperar, almacenar y utilizar su información en relación
        con el funcionamiento del sitio web. Es posible que se le solicite que
        proporcione información personal, como información demográfica, si elige
        completar alguno de los formularios en línea utilizados para el registro
        de eventos, eventos especiales y promociones, solicitudes de citas y
        comentarios. La información requerida para completar la transacción se
        indica en cada formulario específico. Se pueden incluir preguntas
        adicionales para ayudarnos a mejorar los servicios que ofrecemos.
        Consulte la Política de Privacidad de Zinpro para obtener información
        adicional sobre la información que recopilamos.
      </p>

      <h2 class="h2">Cómo usamos la información recopilada</h2>
      <p class="p">
        Podemos usar la información que nos proporciona para cumplir con las
        solicitudes en línea y responder a las consultas de servicio al cliente
        o de otras maneras según lo exija la ley. La información solicitada en
        formularios voluntarios se usa para proporcionar los servicios que
        solicita, como registro de eventos, eventos especiales y promociones,
        solicitudes de citas y para proporcionar comentarios. Solo el personal
        autorizado tiene acceso a su información, incluidos aquellos que
        necesitan la información proporcionada para realizar una tarea
        específica.
      </p>
      <p class="p">
        El sitio web puede usar "cookies" para determinar el número de veces que
        se ha accedido a una página del sitio web. Las cookies son pequeños
        archivos de computadora que un sitio web puede transferir a su
        computadora. Los ejemplos de información que podemos recopilar incluyen
        la frecuencia con la que alguien visita nuestro sitio web y sus
        actividades mientras está en nuestro sitio web. Usamos esta información
        en conjunto para comprender cómo nuestros visitantes como grupo utilizan
        diferentes recursos. Estos informes no contienen ninguna información de
        identificación personal. La mayoría de los navegadores de Internet
        aceptan automáticamente las cookies, pero puede configurar su navegador
        para que las rechace o le avise cuando se envíen.
      </p>

      <p class="p">
        Ocasionalmente, Zinpro contratará a proveedores externos de confianza
        para que nos ayuden a administrar nuestro sitio web, a proporcionar
        contenido e información y nos permita interactuar mejor con nuestros
        visitantes.
      </p>

      <h2 class="h2">Renuncias</h2>
      <p class="font-weight-bold text-uppercase">
        ZINPRO NO HACE NINGUNA REPRESENTACIÓN O GARANTÍA QUE EL USO DE ESTE
        SITIO WEB SERÁ ININTERRUMPIDO, CONFIABLE, ADECUADO, SIN ERRORES, LIBRE
        DE VIRUS U OTROS COMPONENTES PERJUDICIALES O DE ALGUNA OTRA FORMA. USTED
        ES RESPONSABLE DE TOMAR TODAS LAS PRECAUCIONES NECESARIAS PARA
        ASEGURARSE DE QUE CUALQUIER CONTENIDO QUE PUEDE OBTENER EN ESTE SITIO
        WEB ES LIBRE DE VIRUS Y CUALQUIER OTRO CÓDIGO DE COMPUTADORA
        POTENCIALMENTE DESTRUCTIVO.
      </p>

      <p class="p">
        La información contenida en este sitio web es sólo para fines de
        información general. Zinpro proporciona la información y, mientras nos
        esforzamos por mantener la información actualizada y correcta, no
        hacemos declaraciones ni garantías de ningún tipo, explícitas o
        implícitas, sobre la integridad, precisión, confiabilidad, idoneidad o
        disponibilidad con respecto al sitio web o la información, productos,
        servicios o gráficos relacionados contenidos en el sitio web para
        cualquier propósito. Cualquier confianza que usted deposite en dicha
        información es, por lo tanto, bajo su propio riesgo.
      </p>

      <p class="p">
        En ningún caso, Zinpro será responsable por cualquier pérdida o daño,
        incluidos, entre otros, pérdidas o daños indirectos o consecuentes, o
        cualquier pérdida o daño que surja de la pérdida de datos o
        ganancias/utilidades que surjan de, o en conexión con, el uso de este
        sitio web, en la medida permitida por la ley aplicable.
      </p>

      <p class="p">
        Se hace todo lo posible para mantener el sitio web funcionando sin
        problemas. Sin embargo, Zinpro no asume ninguna responsabilidad, y no
        será responsable en caso de que el sitio web no esté disponible
        temporalmente.
      </p>

      <p class="p">
        Zinpro no garantiza ni justifica la exactitud, confiabilidad, integridad
        o vigencia de la información contenida en ningún informe generado desde
        este sitio web y los servicios subyacentes, ni la utilidad para lograr
        ningún propósito. Los usuarios del informe son responsables de evaluar
        la relevancia y exactitud de la información en cualquier informe y
        reconocen que no confiarán en dicho informe como una alternativa al
        asesoramiento de un profesional calificado.
      </p>

      <h2 class="h2">General</h2>
      <p class="p">
        Nos reservamos el derecho de buscar todos los recursos disponibles en la
        ley y en justicia por violaciones de estos Términos de Uso, que
        incluyen, entre otros, el derecho de bloquear el acceso a una dirección
        de Internet en particular a nuestro sitio web y sus características.
      </p>

      <p class="p">
        Nuestra imposibilidad o falta de ejercicio para hacer cumplir cualquier
        derecho o disposición de estos Términos de Uso no constituirá una
        renuncia a dicho derecho o disposición.
      </p>

      <p class="p">
        Si alguna disposición de estos Términos de Uso es declarada no válida
        por un tribunal que tenga jurisdicción competente, la invalidez de dicha
        disposición no afectará la validez de las disposiciones restantes de
        estos Términos de Uso, que permanecerán en plena vigencia. Ninguna
        renuncia a cualquiera de estos Términos de Uso se considerará como una
        renuncia adicional o continua a dicho término o condición o cualquier
        otro término o condición. Las disposiciones de estos Términos de Uso
        serán vinculantes y redundarán en beneficio tanto de sus sucesores y
        cesionarios como de nuestros sucesores y cesionarios.
      </p>

      <p class="p">
        Si viola estos Términos de Uso de una manera que cause daño a otros,
        acepta mantener a Zinpro libre de daño y responsabilidad por ese daño.
      </p>

      <p class="p">
        Al usar este sitio web, Usted acepta que la única jurisdicción y lugar
        adecuado para cualquier disputa con Zinpro, o de cualquier manera
        relacionada con el uso de este sitio web, son los tribunales estatales y
        federales del estado de Minnesota, EE. UU.. Además consiente el
        ejercicio de la jurisdicción personal en estos tribunales en relación
        con cualquier disputa que involucre a Zinpro o sus afiliados, empleados,
        funcionarios, directores, agentes o proveedores.
      </p>

      <p class="p">
        Estos Términos de uso se rigen por las leyes sustantivas internas del
        Estado de Minnesota, sin consideración a los principios de conflicto de
        leyes y las leyes federales de los Estados Unidos.
      </p>
      <p class="p">
        Estos Términos de Uso constituyen el acuerdo completo entre Usted y
        Zinpro relacionado con su uso del sitio web y su contenido.
      </p>

      <p class="font-xs">Última actualización: Septiembre de 2018</p>
    </div>
    <div v-else>
      <h2 class="h2">Please Read These Terms of Use Carefully</h2>
      <p class="p">
        By using this Zinpro FirstStep website, its underlying systems and any
        related mobile applications (the ‘Website’), you agree to be bound by
        the following terms and conditions (the 'Terms of Use'). This Website is
        owned and operated by Zinpro Corporation (‘Zinpro’). As used in this
        Terms of Use, 'Zinpro,' 'we,' 'us' and 'our' all refer to the owner of
        this Website, including any of the companies that it controls, such as
        its subsidiaries and affiliates.
      </p>
      <p class="p">
        We may change the Terms of Use at any time without notice to you. Please
        review the Terms of Use each time you visit the Website. By using this
        Website, it means you accept and agree to be bound by the most recent
        version of the Terms of Use. If you do not agree to be bound by the
        Terms of Use, you may not view, access or use this Website.
      </p>
      <h2 class="h2">Disclaimer of Limited Use</h2>
      <p class="p">
        The Website is intended to be used only as a supplemental educational
        support tool to assist commercial dairy herd personnel to assess dairy
        cattle lameness. Recommendations on lameness assessment, lameness
        prediction and the impact of lameness on profit potential are based on
        current recommendations available when this service tool was written.
        Guidelines on lameness management and prediction on impact on profits
        are subject to change as new data becomes available. This Website is
        only available to current customers of Zinpro who are purchasing our
        products.
      </p>
      <p class="p">
        By registering for this Website, you agree to the following:
      </p>
      <ul class="ul">
        <li>
          The Website is only licensed for your use while supporting and using
          Zinpro Performance Minerals®.
        </li>
        <li>
          Only registered users meeting Zinpro's acceptance criteria are allowed
          to view or use this Website.
        </li>
        <li>
          Independent consultants, such as researchers affiliated with
          universities, are utilized by the Website to review and assess
          lameness data.
        </li>
        <li>
          By using this Website, you agree to the disclosure and sharing of
          lameness assessment information associated with your heard to
          independent consultants.
        </li>
        <li>
          Zinpro reserves the right to terminate your use of the Website at any
          time.
        </li>
      </ul>
      <h2 class="h2">License Grant</h2>
      <p class="p">
        Zinpro grants you a limited, nonexclusive, nontransferable license to
        access and use the Website and its contents for informational and
        personal purposes. You are not permitted to use the Website for any
        purposes or in any manner other then as intended. You agree to use the
        Website and its contents only for proper, lawful purposes and not for
        any illegal, unethical, fraudulent, deceptive or other unauthorized
        purposes. You agree not to use any device, software, or routine to
        interfere with the proper working of the Website. You agree not to
        attempt to collect information about others, including email or personal
        data.
      </p>
      <h2 class="h2">Your Feedback, Comments and Ideas</h2>
      <p class="p">
        While we are pleased to receive feedback, comments and ideas from
        visitors to this Website, we want you to understand that any information
        that you submit through this Website will be considered non-personal,
        non-confidential and non-proprietary. If you transmit any ideas,
        information, concepts, know-how or techniques or materials to us through
        this Website, you hereby grant Zinpro an unrestricted, royalty-free,
        irrevocable license to use, reproduce, display, perform, modify,
        transmit and distribute them in any medium, and agree that Zinpro is
        free to use them for any purpose. You agree that we shall not be under
        any obligation whatsoever to return any unsolicited submissions from
        you. You agree that we shall be entitled to the unrestricted use of the
        unsolicited submissions for any purpose whatsoever, commercial or
        otherwise, without compensation to you or any other provider of the
        unsolicited submissions.
      </p>
      <h2 class="h2">Links</h2>
      <p class="p">
        The firststep.zinpro.com website includes links to other Web pages.
        These links to websites are operated by other proprietary organizations,
        government agencies and private businesses/parties. When you link to
        another site, you are no longer on the firststep.zinpro.com Website, and
        we are not responsible for any of the content you may see, use, or have
        access to. When you link to another website, you are subject to the
        terms and conditions of that website. The inclusion of any links does
        not necessarily imply a recommendation or endorse the views expressed
        within them.
      </p>
      <h2 class="h2">Accuracy of Content</h2>
      <p class="p">
        Neither Zinpro nor any officer or employee of Zinpro (the ‘Zinpro
        Parties’), warrants the accuracy, reliability or timeliness of any
        information published by this Website. Neither do the Zinpro Parties
        endorse any content, viewpoints, products or services linked from this
        Website. The Zinpro Parties shall not be held liable for any losses
        caused by reliance on the accuracy, reliability or timeliness of such
        information. Portions of such information may be incorrect or not
        current. Any person or entity that relies on any information obtained
        from this Website does so at his or her own risk.
      </p>
      <p class="p">
        The firststep.zinpro.com Website is presented as an informational
        service. Any content should be independently verified. State and federal
        agency-authored documents are in the public domain. Copyright and access
        restrictions apply.
      </p>
      <h2 class="h2">Information We Collect</h2>
      <p class="p">
        When you view the Website, you do so as a registered user. We do not
        collect any personal information that identifies you, unless you
        specifically provide us with that contact information voluntarily.
        Personal information includes information that is unique to an
        individual, such as a name, address, e-mail address or telephone number.
        The only personal information that Zinpro obtains through its Website is
        supplied voluntarily by Website visitors. We do not share information
        from registered users, such as the location of your farm, with other
        registered users. By submitting personal information, you grant Zinpro
        the right to transmit, monitor, retrieve, store and use your information
        in connection with the operation of the Website. You might be asked to
        provide personal information, such as demographic information, if you
        choose to complete any of the online forms used for event registration,
        special events and promotions, appointment requests, and to provide
        feedback. The information required to complete the transaction is noted
        on each specific form. Additional questions might be included to help us
        improve the services we offer. Please refer to Zinpro’s Privacy Policy
        for additional information regarding the information we collect.
      </p>
      <h2 class="h2">How We Use This Information</h2>
      <p class="p">
        We may use the information you provide to fulfill online requests and
        respond to customer service inquiries or in other ways as required by
        law. Requested information on voluntary forms is used to provide the
        services that you request, such as event registration, special events
        and promotions, appointment requests, and to provide feedback. Only
        authorized personnel have access to your information, including those
        who need the information provided to perform a specific task.
      </p>
      <p class="p">
        The Website may use 'cookies' to determine the number of times a page on
        the Website has been accessed. Cookies are small computer files that a
        website can transfer to your computer. Examples of information we may
        collect include how often someone visits our Website and their
        activities while on our Website. We use this information in the
        aggregate to understand how our visitors as a group use different
        resources. These reports do not contain any personally identifiable
        information. Most Internet browsers automatically accept cookies, but
        you can set your browser to refuse them or to alert you when they are
        being sent.
      </p>
      <p class="p">
        Zinpro occasionally will engage reputable third-party vendors to help us
        manage our Website, provide content and information and enable us to
        interact better with our visitors.
      </p>
      <h2 class="h2">Disclaimers</h2>
      <p class="font-weight-bold text-uppercase">
        ZINPRO MAKES NO REPRESENTATIONS OR WARRANTIES THAT USE OF THIS WEBSITE
        WILL BE UNINTERRUPTED, RELIABLE, SUITABLE, ERROR-FREE, FREE OF VIRUSES
        OR OTHER HARMFUL COMPONENTS OR OTHERWISE. YOU ARE RESPONSIBLE FOR TAKING
        ALL PRECAUTIONS NECESSARY TO ENSURE THAT ANY CONTENT YOU MAY OBTAIN FROM
        THIS WEBSITE IS FREE OF VIRUSES AND ANY OTHER POTENTIALLY DESTRUCTIVE
        COMPUTER CODE.
      </p>
      <p class="p">
        The information contained in this Website is for general information
        purposes only. The information is provided by Zinpro and while we
        endeavor to keep the information up to date and correct, we make no
        representations or warranties of any kind, express or implied, about the
        completeness, accuracy, reliability, suitability or availability with
        respect to the Website or the information, products, services, or
        related graphics contained on the Website for any purpose. Any reliance
        you place on such information is therefore strictly at your own risk.
      </p>
      <p class="p">
        In no event will Zinpro be liable for any loss or damage including
        without limitation, indirect or consequential loss or damage, or any
        loss or damage whatsoever arising from loss of data or profits arising
        out of, or in connection with, the use of this Website, to the extent
        permitted by applicable law.
      </p>
      <p class="p">
        Every effort is made to keep the Website up and running smoothly.
        However, Zinpro takes no responsibility for, and will not be liable for,
        the Website being temporarily unavailable.
      </p>
      <p class="p">
        Zinpro does not guarantee or warrant the accuracy, reliability,
        completeness or currency of the information contained in any report
        generated from this Website and underlying services, nor the usefulness
        in achieving any purpose. Report users are responsible for assessing the
        relevance and accuracy of the information in any report and hereby
        acknowledge that they will not rely on such report as an alternative to
        advice from a qualified professional.
      </p>
      <h2 class="h2">General</h2>
      <p class="p">
        We reserve the right to seek all remedies available at law and in equity
        for violations of these Terms of Use, including but not limited to the
        right to block access from a particular internet address to our Website
        and its features.
      </p>
      <p class="p">
        Our failure to exercise or enforce any right or provisions of these
        Terms of Use shall not constitute a waiver of such right or provisions.
      </p>
      <p class="p">
        If any provision of these Terms of Use is found to be invalid by any
        court having competent jurisdiction, the invalidity of such provision
        shall not affect the validity of the remaining provisions of these Terms
        of Use, which shall remain in full force and effect. No waiver of any of
        these Terms of Use shall be deemed a further or continuing waiver of
        such term or condition or any other term or condition. The provisions of
        these Terms of Use shall be binding on and inure to the benefit of both
        your successors and assignees and our successors and assignees.
      </p>
      <p class="p">
        If you violate these Terms of Use in a way that causes harm to others,
        you agree to hold Zinpro harmless against any liability for that harm.
      </p>
      <p class="p">
        By using this Website, you agree that the only proper jurisdiction and
        venue for any dispute with Zinpro, or in any way relating to your use of
        this Website, is in the state and federal courts in the State of
        Minnesota, U.S.A. You further agree and consent to the exercise of
        personal jurisdiction in these courts in connection with any dispute
        involving Zinpro or its affiliates, employees, officers, directors,
        agents or suppliers.
      </p>
      <p class="p">
        These Terms of Use are governed by the internal substantive laws of the
        State of Minnesota, without respect to its conflict of laws principles,
        and the federal laws of the United States.
      </p>
      <p class="p">
        These Terms of Use constitute the entire agreement between you and
        Zinpro related to your use of the Website and its content.
      </p>
      <p class="font-xs">Last updated: September 2018</p>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: 'Generate Report',
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.scrollToTop();
  },
};
</script>
