<template>
  <div class="dashboard container main-container main-container--padding-b">
    <DashboardHeading :assessorInfo="assessorInfo" />

    <DashboardInput @set-fresh-rate="setFreshRate" :evaluation="evaluation" />
    <DashboardGroups :evaluation="evaluation" />
    <DashboardRequirements :evaluation="evaluation" :requirementsData="requirementsAvailableSpaces" />
    <DashboardRequirements :evaluation="evaluation" :requirementsData="requirementsSpaces" maternity />

    <div class="row d-flex flex-md-row flex-column flex-nowrap mb-5">
      <div class="col-md-16 d-flex flex-column justify-content-between">
        <DashboardFreestall :evaluation="evaluation" />
        <DashboardTimeBudget :evaluation="evaluation" />
      </div>
      <div class="col-md-8 dashboard__ventilation-wrap mt-4 mt-md-0">
        <DashboardVentilation :evaluation="evaluation" />
      </div>
    </div>

    <div class="dashboard__stats-block main-container">
      <DashboardNotes />

      <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

      <div class="row misc-bottom">
        <div class="col-md-12 flex-column flex-lg-row justify-content-md-between justify-content-lg-start d-flex">
          <a class="btn btn-secondary btn-full--sm mb-2 mt-md-0" href="javascript:history.go(-1)">
            {{ 'Back' | translate }}
          </a>
          <router-link class="btn btn-primary btn-full--sm mb-2 mt-md-0 ml-lg-4" :to="assessorTypeLink">
            {{ 'Setup' | translate }}
          </router-link>
        </div>
        <div
          class="col-md-12 flex-column-reverse flex-lg-row-reverse justify-content-md-between justify-content-lg-start d-flex"
        >
          <router-link
            v-if="user && user.role !== 'data_entry'"
            to="/reports"
            class="btn btn-primary btn-full--sm mb-md-2"
          >
            {{ 'Reports' | translate }}
          </router-link>
          <router-link :to="`${assessorTypeLink}/summary`" class="btn btn-primary btn-full--sm mr-lg-4 mb-2">
            {{ 'Summary' | translate }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardHeading from './components/DashboardHeading';
import DashboardInput from './components/DashboardInput';
import DashboardGroups from './components/DashboardGroups';
import DashboardRequirements from './components/DashboardRequirements';
import DashboardFreestall from './components/DashboardFreestall';
import DashboardTimeBudget from './components/DashboardTimeBudget';
import DashboardVentilation from './components/DashboardVentilation';
import DashboardNotes from './components/DashboardNotes';

import { mapActions, mapGetters, mapState } from 'vuex';
import { animalMixin } from '@/mixins/animal.mixin.js';
import { GET_UPDATE_EVALUATION } from '../../store/types';

export default {
  name: 'Dashboard',

  props: {
    evaluationId: String,
    assessorType: String,
    userId: String,
  },

  components: {
    DashboardHeading,
    DashboardInput,
    DashboardGroups,
    DashboardRequirements,
    DashboardFreestall,
    DashboardTimeBudget,
    DashboardVentilation,
    DashboardNotes,
  },

  mixins: [animalMixin],

  provide() {
    return {
      assessorType: this.assessorType,
    };
  },

  computed: {
    ...mapGetters(['getAssessorTypeById', 'getData']),

    ...mapState({
      evaluation: state => state.evaluations.evaluation,
      user: state => state.users.user,
    }),

    assessorTypeLink() {
      const routeParams = this.$route.params || {};

      return `/users/${routeParams.userId}/evaluations/${routeParams.evaluationId}/groups/${routeParams.groupId}/assessors/${routeParams.assessorType}`;
    },
  },

  data() {
    return {
      // evaluation: {},
      user: {},
      assessorInfo: {},
      dashboardNotes: '',

      requirementsAvailableSpaces: {
        table: {
          title: 'Group Requirements and Availability (Day of Assessment)',
          subtitles: false,
          headings: [
            'Cows in Group',
            'Bedded Pack Available Cow Spaces',
            'Freestall Available Cow Spaces',
            'Headlock Available Cow Spaces',
            'Feeding Available Cow Spaces',
            'Drinking Available Cow Spaces',
          ],
          computedValues: [
            this.cowsInGroup,
            this.beddedPackAvailableCowSpaces,
            this.freestallAvailableCowSpaces,
            this.headlockAvailableCowSpaces,
            this.feedingAvailableCowSpaces,
            this.drinkingAvailableCowSpaces,
          ],
        },
      },

      requirementsSpaces: {
        table: {
          title: 'Group Requirements Based on Herd Size and Calving Pattern',
          subtitles: [
            { value: 'Requirements', colspan: 1 },
            { value: 'Cow Spaces Available for Each Group', colspan: 5 },
          ],
          headings: [
            'Maximum Cows',
            'Bedded Pack Cow Spaces',
            'Freestall Cow Spaces',
            'Headlock Cow Spaces',
            'Feeding Cow Spaces',
            'Drinking Cow Spaces',
          ],
          computedValues: [
            this.maximumCows,
            this.beddedPackAvailableCowSpaces,
            this.freestallAvailableCowSpaces,
            this.headlockAvailableCowSpaces,
            this.feedingAvailableCowSpaces,
            this.drinkingAvailableCowSpaces,
          ],
        },
      },
    };
  },

  created() {
    this.initData();
    this.scrollToTop();
  },

  watch: {
    // evaluation:{
    //   handler: _.debounce(async function (newAssessor, oldAssessor) {
    //     // if ids are the same, content hasn't been updated then stop processing
    //     if (oldAssessor !== null && newAssessor.id !== oldAssessor.id) return false;
    //     // @TODO: deep clone so we can delete assessors and groups before sending to firestore
    //     // If we set the assessor ID as the assessorType we protect ourselves from creating more than one assessor in a group. It should be close to impossible.
    //     await this.$store.dispatch('setData', {
    //       currentVueInstance: this,
    //       endpointUrl:  'users/' +
    //       this.userId +
    //       '/evaluations/' +
    //       this.$route.params.evaluationId,
    //       objectBeingUpdated: this.assessor
    //     });
    //   }, 500),
    //   deep: true,
    //   immediate: true
    // }
    'evaluation.data': {
      handler: _.debounce(async function(newEvaluation, oldEvaluation) {

         await this[GET_UPDATE_EVALUATION](newEvaluation);

      }, 500),
      deep: true,
      immediate: true
    },
  },

  methods: {
    ...mapActions('evaluations',[GET_UPDATE_EVALUATION]),
    setFreshRate(freshening_rate) {
      this.evaluation.data = { ...this.evaluation.data, freshening_rate };
    },
    initData() {
      this.assessorInfo = this.getAssessorTypeById(this.assessorType);
     },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '_dashboard.scss';
</style>
