<template>
  <div class="dashboard-heading row mb-4">
    <div class="col-md-20">
      <div class="d-flex flex-column flex-xl-row">
        <h1 class="h1 h1--mb-small" v-if="Number(assessorInfo.id) === 19">
          {{ assessorInfo.name | translate }}
        </h1>
        <div
          class="dashboard__assessor-info d-flex align-items-center mt-0 mt-xl-1"
        >
          <span
            v-if="
              assessorInfo &&
                assessorInfo.description &&
                assessorInfo.description.length > 0
            "
            class="ml-0 ml-xl-3"
          >
            {{ assessorInfo.description[0] | translate }}
            <a href="#" class="hygiene__link" data-toggle="modal" data-target="#more">{{'More' | translate}}</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardHeading',
  props: {
    assessorInfo: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../_dashboard.scss';
</style>
