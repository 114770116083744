import { render, staticRenderFns } from "./DashboardInput.vue?vue&type=template&id=813a37b4&scoped=true&"
import script from "./DashboardInput.vue?vue&type=script&lang=js&"
export * from "./DashboardInput.vue?vue&type=script&lang=js&"
import style0 from "./DashboardInput.vue?vue&type=style&index=0&id=813a37b4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "813a37b4",
  null
  
)

export default component.exports