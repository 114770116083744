<template>
  <div class="progress-container d-flex flex-column">
    <div class="progress-block d-flex w-100 justify-content-between mb-1">
      <small>{{ title || '' }}</small>
      <small>{{ units || '' }}</small>
    </div>
    <div class="progress-block d-flex w-100 align-items-center">
      <div class="progress w-100">
        <div
          class="progress-bar"
          :class="progressBarClass"
          :style="`width: ${convertedValue}%`"
        ></div>
      </div>
      <small class="ml-2">{{ value || 'XX' }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'linear-progress-bar',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
    title: {
      type: String,
      default: '',
    },
    units: {
      type: String,
      default: '',
    },
    danger: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    progressBarClass() {
      const { danger, success } = this;
      return danger
        ? 'progress-bar--danger'
        : success
        ? 'progress-bar--success'
        : '';
    },
    convertedValue() {
      if (this.value === 0) return 0
      return (this.value * 100) / this.maxValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  height: 10px;
  border-radius: 30px;
  background-color: rgb(215, 215, 215);
}

.progress-bar {
  border-radius: 30px;
  background-color: #0000ff;

  &--danger {
    background-color: rgb(204, 0, 0);
  }
  &--success {
    background-color: rgb(0, 128, 0);
  }
}
</style>
