<template>
  <div>{{EnrollmentNotification}}</div>
</template>

<script>
export default {
  name: 'EnrollmentNotification',
};
</script>

<style lang="scss" scoped>
</style>