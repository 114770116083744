<template>
  <div>
    <div class="loading" v-if="isLoading">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <div v-else class="main-container main-container--margin-bt container">
      <div class="card mb-0">
        <h1 class="h1">{{ 'Verify Account' | translate }}</h1>
        <div class="card-body card-body--login mt-4">
          <div class="card-body__top d-flex justify-content-end">
            <p>* {{ 'Field Required' | translate }}</p>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <label class="login__label">{{ 'Prefix' | translate }}</label>
                <span class="required">*</span>
                <select
                  class="form-control"
                  v-model="verify.prefix"
                  name="prefixes"
                  data-vv-as="Prefix"
                  v-validate="'required'"
                >
                  <option disabled value="null">{{ 'Select' | translate }}</option>
                  <option v-for="(prefix, index) in $store.state.misc.prefixes" :key="index" :value="prefix">
                    {{ prefix }}
                  </option>
                </select>
                <span v-show="errors.has('prefixes')" class="text-danger">
                  <small>{{ 'The Prefix field is required.' | translate }}</small>
                </span>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <label class="login__label">{{ 'First Name' | translate }}</label>
                <span class="required">*</span>
                <input
                  type="text"
                  name="first_name_v"
                  class="form-control"
                  v-model="verify.firstName"
                  data-vv-as="First Name"
                  v-validate="'required'"
                />
                <span v-show="errors.has('first_name_v')" class="text-danger">
                  <small>{{ 'The First Name field is required.' | translate }}</small>
                </span>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <label class="login__label">{{ 'Last Name' | translate }}</label>
                <span class="required">*</span>
                <input
                  type="text"
                  class="form-control"
                  name="last_name_v"
                  v-model="verify.lastName"
                  data-vv-as="Last Name"
                  v-validate="'required'"
                />
                <span v-show="errors.has('last_name_v')" class="text-danger">
                  <small>{{ 'The Last Name field is required.' | translate }}</small>
                </span>
              </div>
            </div>
          </div>
          <!-- end row -->

          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="login__label">{{ 'Company' | translate }}</label>
                <span class="required">*</span>
                <input class="form-control" type="text" :value="verify.companyName" disabled />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="login__label">{{ 'Country' | translate }}</label>
                <span class="required">*</span>
                <select
                  class="form-control"
                  v-model="verify.countryCode"
                  name="company_country"
                  data-vv-as="Country"
                  v-validate="'required'"
                >
                  <option :value="null" disabled>{{ 'Select' | translate }}</option>
                  <option v-for="(country, index) in $store.state.misc.countriesPrimary" :key="index" :value="index">
                    {{ country | translate }}
                  </option>
                  <option disabled>------------------</option>
                  <option v-for="(country, index) in $store.state.misc.countries" :key="index" :value="index">
                    {{ country | translate }}
                  </option>
                </select>
                <span v-show="errors.has('company_country')" class="text-danger">
                  <small>{{ 'The Country field is required.' | translate }}</small>
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-24">
              <div class="form-group">
                <label class="login__label">{{ 'Address' | translate }}</label>
                <span class="required">*</span>
                <input
                  type="text"
                  name="address_v"
                  class="form-control"
                  v-model="verify.address"
                  data-vv-as="Address"
                  v-validate="'required'"
                />
                <span v-show="errors.has('address_v')" class="text-danger">
                  <small>{{ 'The Address field is required.' | translate }}</small>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="login__label">{{ 'City' | translate }}</label>
                <span class="required">*</span>
                <input
                  type="text"
                  name="city_v"
                  class="form-control"
                  v-model="verify.city"
                  data-vv-as="City"
                  v-validate="'required'"
                />
                <span v-show="errors.has('city_v')" class="text-danger">
                  <small>{{ 'The City field is required.' | translate }}</small>
                </span>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="login__label">{{ 'State/Province' | translate }}</label>
                <select v-if="misc.states[verify.stateProvince]" class="form-control" v-model="verify.stateProvince">
                  <option :value="null" disabled>{{ 'Select' | translate }}</option>
                  <template v-if="Object.keys(misc.states[verify.stateProvince].primary).length">
                    <option
                      v-for="(state, abbr) in misc.states[verify.stateProvince].primary"
                      :key="state"
                      :value="abbr"
                    >
                      {{ state }}
                    </option>
                    <option disabled>------------------</option>
                  </template>
                  <option v-for="(state, abbr) in misc.states[verify.stateProvince].regular" :key="state" :value="abbr">
                    {{ state }}
                  </option>
                </select>
                <input v-else v-model="verify.stateProvince" type="text" class="form-control" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="login__label">{{ 'Postal Code' | translate }}</label>
                <input
                  type="text"
                  name="postal_v"
                  class="form-control"
                  v-model="verify.postalCode"
                  data-vv-as="Postal Code"
                 
                />
              </div>
            </div>
            <div class="col- col-lg-12">
              <div class="form-group">
                <label class="login__label">{{ 'Phone' | translate }}</label>
                <span class="required">*</span>
                <input
                  type="tel"
                  name="phone_v"
                  class="form-control"
                  v-model="verify.phone"
                  data-vv-as="Phone"
                  v-validate="'required'"
                />
                <span v-show="errors.has('phone_v')" class="text-danger">
                  <small>{{ 'The Phone field is required.' | translate }}</small>
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col- col-lg-24">
              <div class="form-group">
                <label class="login__label">{{ 'Email' | translate }}</label>
                <span class="required">*</span>
                <input
                  type="text"
                  name="email_v"
                  class="form-control"
                  v-model="verify.email"
                  data-vv-as="Email"
                  v-validate="'email|required'"
                  disabled
                />
                <span v-show="errors.has('email_v')" class="text-danger">
                  <small>{{ 'The Email field is required.' | translate }}</small>
                </span>
              </div>
            </div>
          </div>

          <div class="row" v-if="verify.role === 'external'">
            <div class="col-24">
              <div class="form-group">
                <label class="login__label">{{ 'Zinpro Representative' | translate }}</label>
                <span class="required">*</span>
                <input class="form-control" :value="verify.representativeName" disabled />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-24">
              <div class="form-group">
                <label class="login__label">{{ 'Password' | translate }}</label>
                <span class="required">*</span>
                <vue-password
                  v-model="password.new_password"
                  classes="form-control"
                  name="password"
                  v-validate="'required'"
                  ref="password"
                  data-vv-as="Password"
                ></vue-password>
                <span v-show="errors.has('password')" class="text-danger">
                  <small>{{ 'The Password field is required.' | translate }}</small>
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-24 mb-3">
              <div class="form-group">
                <label class="login__label">{{ 'Confirm Password' | translate }}</label>
                <span class="required">*</span>
                <input
                  type="password"
                  class="form-control"
                  v-model="password.new_password2"
                  v-validate="'confirmed:password|required'"
                  name="password2"
                  data-vv-as="Password"
                />
              </div>
              <span v-show="errors.has('password2')" class="text-danger">
                <small>{{ 'The Confirm Password field is required.' | translate }}</small>
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-16 d-flex align-items-center mb-2 mb-md-0">
              <label class="label-checkbox">
                <input type="checkbox" id="agree" name="terms" data-vv-as="Terms" v-validate="'required'" />
                <span class="checkbox"></span>
              </label>
              <label class="label--font-thin label--login-checkbox mb-0" for="agree">
                {{ 'I agree to the' | translate }}
                <router-link :to="'/terms-of-use'" target="_blank" class="underline-bold">{{
                  'Terms of Use' | translate
                }}</router-link>
                {{ 'and' | translate }}
                <a href="https://www.zinpro.com/privacy" target="_blank" class="underline-bold">{{
                  'Privacy Policy' | translate
                }}</a>
              </label>
            </div>
            <div class="col-md-8 d-flex justify-content-end">
              <button type="submit" @click="verifyAccount" class="btn btn-primary btn-full--sm">
                {{ 'Verify' | translate }}
                <span v-if="loader" class="loader ml-1"></span>
              </button>
            </div>
            <span v-show="errors.has('terms')" class="text-danger">
              <small>{{ errors.first('terms')  | translate }}</small>
            </span>
          </div>
        </div>
      </div>
      <div v-if="confirmMessage" class="alert alert-primary mt-3" role="alert">
        {{ 'Update Successful' | translate }}
      </div>
      <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
        {{ errorMessageText | translate }}
      </div>
      <!-- end card body -->
    </div>
    <!-- end container -->
  </div>
</template>

<script>
import VuePassword from 'vue-password';
var $ = (window.jQuery = require('jquery'));
import _ from 'lodash';
import { GET_UNVERIFIED_USER, GET_UPDATE_UNVERIFIED_USER, GET_USER_BY_ID, SET_SIGN_IN_USER } from '../store/types';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    VuePassword,
  },
  data() {
    return {
      agree: false,
      companiesAdmin: null,
      id: this.$route.params.id,
      isLoading: true,
      companyName: null,
      password: {
        new_password: null,
        new_password2: null,
        isActive: false,
      },
      verify: {
        address: null,
        city: null,
        company: null,
        companyName: null,
        countryCode: null,
        email: null,
        isVerified: true,
        firstName: null,
        lastName: null,
        phone: null,
        postalCode: null,
        prefix: null,
        representative: null,
        stateProvince: null,
      },
      token: null,
      confirmMessage: false,
      errorMessage: false,
      errorMessageText: null,
      loader: false,
      tempPassword: null,
      zinproReps: [],
      user: null,
    };
  },
  computed: {
    ...mapState({
      misc: state => state.misc,
    }),
  },
  methods: {
    ...mapActions('users', [GET_USER_BY_ID, GET_UNVERIFIED_USER, GET_UPDATE_UNVERIFIED_USER]),
    movePasswordMeter() {
      $('.VuePassword__Meter').insertBefore('.VuePassword');
    },

    isUserAuthenticateToVerifyHimself() {},
    async initializeAccountData() {
      try {
        const { id } = this.$route.query;
        console.log({ id });
        if (!id) {
          return this.$router.push({
            path: '/login',
            query: {
              error_code: 'auth/cannot-verify',
            },
          });
        }
        const user = await this[GET_UNVERIFIED_USER](id);
        this.verify = user;
        this.isLoading = false;

        this.routeTorLoginScreenIfAlreadyVerified(user);
      } catch (error) {
        console.log({ error });
        this.$router.push({
          path: '/login',
          query: { error_code: 'auth/cannot-verify' },
        });
      }
    },

    routeTorLoginScreenIfAlreadyVerified(user) {
      if (user.isVerified) {
        this.$router.push({
          path: '/login',
          query: {
            error_code: 'auth/already-verified',
          },
        });
      }
    },

    // TODO: verify function
    async verifyAccount() {
      const result = await this.$validator.validateAll();

      if (result !== true) {
        this.errorMessage = true;
        this.errorMessageText = 'Please fill in all required fields!';
        this.loader = false;

        setTimeout(() => {
          this.errorMessage = false;
        }, 4000);

        return false;
      }

      try {
        this.loader = true;
        await this[GET_UPDATE_UNVERIFIED_USER]({
          ...this.verify,
          verifyAccount:true,
          dateUpdated: new Date().getTime(),
          isVerified: true,
          stateProvince:
            this.verify.countryCode === 'US' || this.verify.countryCode === 'CA' ? this.verify.stateProvince : null,
          password: this.password.new_password,
          confirmPassword: this.password.new_password2,
        }).then(() =>{
          this.$router.push('/');
        });

      } catch (err) {
        console.warn('Error', err);
        this.errorMessage = true;
        this.loader = false;
        this.errorMessageText = this.generateErrorMsg(err) ;
      }
    },
    generateErrorMsg({ errors, message = '' }) {
      return errors ? Object.values(errors).join(', ') : message;
    },
  },
  beforeMount() {
    //     if(!this.$firebase.auth().currentUser) {
    //         axios.post(this.$store.state.baseUrl + '/verify-token', {
    //             uid: this.$route.query.uid,
    //             token: this.$route.query.token
    //         })
    //         .then((response) => {
    //             this.token = response.data.token;
    //             this.$firebase.auth().signInWithCustomToken(response.data.token).then((s) => {
    //                 this.initializeAccountData();
    //             })
    //             .catch((error) => {
    //                 this.$router.push({
    //                     path: '/login',
    //                     query: { error_code: 'auth/cannot-verify' }
    //                 });
    //             });
    //         })
    //         .catch((error) => {
    //             console.error(error.response.data);
    //             this.$router.push({
    //                 path: '/login',
    //                 query: { error_code: error.response.data.code }
    //             });
    //         });
    //     } else {
    //     }
    this.initializeAccountData();
  },
  created(){
    const {lang} = this.$route.query
    localStorage.setItem('lang', lang)
    this.$store.state.users.user.lang = lang
  },
  mounted() {
    this.movePasswordMeter();
  },
};
</script>
