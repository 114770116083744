import {
    hoofTrimming
} from './hoofTrimming';
import {
    medias
} from './../Global/medias.js';

class HoofTrimmingAssessorSlides {

    constructor(app, report, evaluation, group, user) {
        this.app = app;
        this.report = report;
        this.evaluation = evaluation;
        this.group = group;
        this.user = user;
    }

    add(tableOfContentsCalculatorPosition) {

        let assessor = this.group.getAssessorByType("15");

        if (assessor === false || assessor.assessor.data === undefined) {
            throw this.group.getName() + this.app.$t(" - ") + this.app.$t("Hoof Trimming assessor is not ready.");
        }

        this.report.tableOfContentsHelper("Hoof Trimming", tableOfContentsCalculatorPosition, this.group);

        hoofTrimming(this.app, this.report, this.evaluation, this.group, assessor);
        medias(this.app, this.report, this.group, "15", this.user);
    }

}

export default HoofTrimmingAssessorSlides;