<template>
  <div class="dashboard-freestall-meas row">
    <div class="col-md-8 mb-3 d-flex flex-column justify-content-end">
      <LinearProgressBar
        :value="width"
        :maxValue="maxWidth"
        :danger="!fitInRange(FREESTALL_FIELDS_NAME.stallWidth)"
        :success="fitInRange(FREESTALL_FIELDS_NAME.stallWidth)"
        :title="'Stall Width' | translate"
        :units="units"
      />
    </div>
    <div
      v-if="freestall && ['two','three'].includes(freestall.getStallAlignmentValue())"
      class="col-md-8 mb-3 d-flex flex-column justify-content-end"
    >
      <LinearProgressBar
        :value="stallLengthDoubleRow"
        :maxValue="maxStallLengthDoubleRow"
        :danger="!fitInRange(FREESTALL_FIELDS_NAME.stallLengthDoubleRow)"
        :success="fitInRange(FREESTALL_FIELDS_NAME.stallLengthDoubleRow)"
        :title="'Stall Length (Double Row)' | translate"
        :units="units"
      />
    </div>
    <div class="col-md-8 mb-3 d-flex flex-column justify-content-end">
      <LinearProgressBar
        :value="rearCurbHeight"
        :maxValue="maxRearCurbHeight"
        :danger="!fitInRange(FREESTALL_FIELDS_NAME.rearCurbHeight)"
        :success="fitInRange(FREESTALL_FIELDS_NAME.rearCurbHeight)"
        :title="'Rear Curb Height' | translate"
        :units="units"
      />
    </div>
    <div class="col-md-8 mb-3 d-flex flex-column justify-content-end">
      <LinearProgressBar
        :value="neckrailToRearCurbDistance"
        :maxValue="maxNeckrailToRearCurbDistance"
        :danger="!fitInRange(FREESTALL_FIELDS_NAME.neckrailToRearCurbDistance)"
        :success="fitInRange(FREESTALL_FIELDS_NAME.neckrailToRearCurbDistance)"
        :title="'Neckrail to Rear Curb Distance' | translate"
        :units="units"
      />
    </div>
    <div v-if="freestall && freestall.getStallAlignmentValue() !== 'two'" class="col-md-8 mb-3 d-flex flex-column justify-content-end">
      <LinearProgressBar
        :value="stallLengthSingleRow"
        :maxValue="maxStallLengthSingleRow"
        :danger="!fitInRange(FREESTALL_FIELDS_NAME.stallLengthSingleRow)"
        :success="fitInRange(FREESTALL_FIELDS_NAME.stallLengthSingleRow)"
        :title="'Stall Length (Single Row)' | translate"
      />
    </div>
    <div v-if="freestall && ['sand','manure_solids','straw','other'].includes(freestall.getBeddingTypeValue())"  class="col-md-8 mb-3 d-flex flex-column justify-content-end">
      <LinearProgressBar
        :value="rearCurbWidth"
        :maxValue="maxRearCurbWidth"
        :danger="!fitInRange(FREESTALL_FIELDS_NAME.rearCurbWidth)"
        :success="fitInRange(FREESTALL_FIELDS_NAME.rearCurbWidth)"
        :title="'Rear Curb Width' | translate"
      />
    </div>
    <div class="col-md-8 mb-3 d-flex flex-column justify-content-end">
      <LinearProgressBar
        :value="brisketLocatorHeight"
        :maxValue="maxBrisketLocatorHeight"
        :danger="!fitInRange(FREESTALL_FIELDS_NAME.brisketLocatorHeight)"
        :success="fitInRange(FREESTALL_FIELDS_NAME.brisketLocatorHeight)"
        :title="'Brisket Locator Height' | translate"
      />
    </div>
    <div class="col-md-8 mb-3 d-flex flex-column justify-content-end">
      <LinearProgressBar
        :value="lowerDividerHeight"
        :maxValue="maxLowerDividerHeight"
        :danger="!fitInRange(FREESTALL_FIELDS_NAME.lowerDividerHeight)"
        :success="fitInRange(FREESTALL_FIELDS_NAME.lowerDividerHeight)"
        :title="'Lower Divider Height' | translate"
      />
    </div>
    <div class="col-md-8 mb-3 d-flex flex-column justify-content-end">
      <LinearProgressBar
        :value="neckrailHeight"
        :maxValue="maxNeckrailHeight"
        :danger="!fitInRange(FREESTALL_FIELDS_NAME.neckrailHeight)"
        :success="fitInRange(FREESTALL_FIELDS_NAME.neckrailHeight)"
        :title="'Neckrail Height' | translate"
      />
    </div>
    <div v-if="freestall && freestall.assessor.data.divider_type !== 'michigan'" class="col-md-8 mb-3 d-flex flex-column justify-content-end">
      <LinearProgressBar
        :value="insideLoopDividerDiameter"
        :maxValue="maxInsideLoopDividerDiameter"
        :danger="!fitInRange(FREESTALL_FIELDS_NAME.insideLoopDividerDiameter)"
        :success="fitInRange(FREESTALL_FIELDS_NAME.insideLoopDividerDiameter)"
        :title="'Inside Loop Divider Diameter' | translate"
      />
    </div>
    <div class="col-md-8 mb-3 d-flex flex-column justify-content-end">
      <LinearProgressBar
        :value="curbToBrisketLocatorDistance"
        :maxValue="maxCurbToBrisketLocatorDistance"
        :danger="!fitInRange(FREESTALL_FIELDS_NAME.curbToBrisketLocatorDistance)"
        :success="fitInRange(FREESTALL_FIELDS_NAME.curbToBrisketLocatorDistance)"
        :title="'Curb to Brisket Locator Distance' | translate"
      />
    </div>
  </div>
</template>

<script>
import { animalMixin } from '@/mixins/animal.mixin.js';
import { translationsMixin } from '@/mixins/translations.mixin.js';
import LinearProgressBar from '@/components/LinearProgressBar';
import _ from 'lodash';

const FREESTALL_FIELDS_NAME = {
  stallWidth: 'stall-width',
  stallLengthDoubleRow: 'stall_length_double_row',
  rearCurbHeight: 'reat-curb-height',
  neckrailToRearCurbDistance: 'neckrail-to-rear-curb-distance',
  stallLengthSingleRow: 'stall-length-single-row',
  rearCurbWidth: 'rear-curb-width',
  brisketLocatorHeight: 'brisket-locator-height',
  stallLengthDoubleRow: 'stallLengthDoubleRow',
  lowerDividerHeight: 'lowerDividerHeight',
  neckrailHeight: 'neckrailHeight',
  insideLoopDividerDiameter: 'inside_loop_divider_diameter'
};

export default {
  name: 'DashboardFreestallMeas',
  mixins: [animalMixin, translationsMixin],
  props: {
    freestall: {
      type: Object | null,
      required: true,
    },
    units: {
      type: String,
      required: true,
    },
  },
  components: {
    LinearProgressBar,
  },
  data() {
    return {
      FREESTALL_FIELDS_NAME,
    };
  },
  computed: {
    freestallDefined() {
      return !_.isEmpty(this.freestall);
    },
    width() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getWidth(0);
      return res || 0;
    },
    lengthDoubleRow() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.assessor.data.stall_length_double_row;
      return res || 0;
    },
    maxWidth() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getIdealWidth() * 2;
      return res || 0;
    },
    maxLengthDouble() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getIdealDoubleRowStallLength() * 2;
      return res || 0;
    },
    insideLoopDividerDiameter() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getInsideLoopDividerDiameter();
      return res || 0;
    },
    maxInsideLoopDividerDiameter() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getIdealInsideLoopDividerDiameter();
      return res || 0;
    },
    rearCurbHeight() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getRearCurbHeight(0);
      return res || 0;
    },
    maxRearCurbHeight() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getIdealRearCurbHeight(0) * 2;
      return res || 0;
    },
    neckrailToRearCurbDistance() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getNeckrailToRearCurbDistance(0);
      return res || 0;
    },
    maxNeckrailToRearCurbDistance() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getMaxNeckrailToRearCurbDistance() * 2;
      return res || 0;
    },
    stallLengthSingleRow() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getSingleRowStallLength(0);
      return res || 0;
    },
    maxStallLengthSingleRow() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getIdealSingleRowStallLength() * 2;
      return res || 0;
    },
    rearCurbWidth() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getRearCurbWidth(0);
      return res || 0;
    },
    maxRearCurbWidth() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getIdealRearCurbWidth() * 2;
      return res || 0;
    },
    brisketLocatorHeight() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getBrisketLocatorHeight(0);
      return res || 0;
    },
    curbToBrisketLocatorDistance() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getCurbToBrisketLocatorDistance();
      return res || 0;
    },
    maxCurbToBrisketLocatorDistance() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getMaxCurbToBrisketLocatorDistance() * 2;
      return res || 0;
    },
    maxBrisketLocatorHeight() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getIdealBrisketLocatorHeight() * 2;
      return res || 0;
    },
    stallLengthDoubleRow() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getLength(0);
      return res || 0;
    },
    maxStallLengthDoubleRow() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getIdealDoubleRowStallLength() * 2;
      return res || 0;
    },
    lowerDividerHeight() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getLowerDividerHeight(0);
      return res || 0;
    },
    maxLowerDividerHeight() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getIdealLowerDividerHeight() * 2;
      return res || 0;
    },
    neckrailHeight() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getNeckrailHeight(0);
      return res || 0;
    },
    maxNeckrailHeight() {
      if (!this.freestallDefined) return 0;
      let res = this.freestall.getIdealNeckrailHeight() * 2;
      return res || 0;
    },
    type() {
      if (!this.freestallDefined) return null;
      return this.freestall.getDividerTypeValue()
    }
  },
  methods: {
    fitInRange(fieldName) {
      if (!this.freestallDefined) return false;

      const freestall = this.freestall;

      switch (fieldName) {
        case FREESTALL_FIELDS_NAME.stallWidth: {
          return (freestall.getWidth(0) < freestall.getMaxWidth() &&
                  freestall.getWidth(0) >= freestall.getMinWidth());
        }
        case FREESTALL_FIELDS_NAME.stallLengthDoubleRow: {
          // TODO: Check stall_length_double_row & stall_length
          return freestall.getMinDoubleRowStallLength() < freestall.assessor.data.stall_length;
        }
        case FREESTALL_FIELDS_NAME.rearCurbHeight: {
          return (freestall.getMinRearCurbHeight() <= freestall.getRearCurbHeight(0) &&
                  freestall.getRearCurbHeight(0) <= freestall.getMaxRearCurbHeight());
        }
        case FREESTALL_FIELDS_NAME.neckrailToRearCurbDistance: {
          return (freestall.getNeckrailToRearCurbDistance(0) >= freestall.getMinNeckrailToRearCurbDistance() &&
                  freestall.getNeckrailToRearCurbDistance(0) <= freestall.getMaxNeckrailToRearCurbDistance());
        }
        case FREESTALL_FIELDS_NAME.stallLengthSingleRow: {
          return freestall.getSingleRowStallLength(0) > freestall.getMinSingleRowStallLength();
        }
        case FREESTALL_FIELDS_NAME.rearCurbWidth: {
          return freestall.getRearCurbWidth(0) <= freestall.getMaxRearCurbWidth();
        }
        case FREESTALL_FIELDS_NAME.brisketLocatorHeight: {
          return freestall.getBrisketLocatorHeight(0) <= freestall.getIdealBrisketLocatorHeight();
        }
        case FREESTALL_FIELDS_NAME.stallLengthDoubleRow: {
          return freestall.getLength(0) > freestall.getMinDoubleRowStallLength();
        }
        case FREESTALL_FIELDS_NAME.lowerDividerHeight: {
          return (freestall.getLowerDividerHeight(0) >= freestall.getMinLowerDividerHeight() &&
                  freestall.getLowerDividerHeight(0) <= freestall.getMaxLowerDividerHeight());
        }
        case FREESTALL_FIELDS_NAME.neckrailHeight: {
          return (freestall.getNeckrailHeight(0) >= freestall.getMinNeckrailHeight() &&
                  freestall.getNeckrailHeight(0) <= freestall.getMaxNeckrailHeight());
        }
        case FREESTALL_FIELDS_NAME.curbToBrisketLocatorDistance: {
          return (freestall.getCurbToBrisketLocatorDistance(0) >= freestall.getMinCurbToBrisketLocatorDistance() &&
                  freestall.getCurbToBrisketLocatorDistance(0) <= freestall.getMaxCurbToBrisketLocatorDistance());
        }
        case FREESTALL_FIELDS_NAME.insideLoopDividerDiameter: {
          return freestall.getInsideLoopDividerDiameter() >= freestall.getMinInsideLoopDividerDiameter() ||
                  freestall.getInsideLoopDividerDiameter() == freestall.getIdealInsideLoopDividerDiameter();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../_dashboard.scss';
</style>
