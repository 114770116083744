<!-- @format -->

<template>
  <div :class="`col-lg-6 ${classes}`">
    <div class="form-group">
      <label class="d-block"
        >{{
          "Does this evaluation contain false data and not actual real-world information?"
            | translate
        }}
        <span class="required">*</span>
      </label>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            name="is_for_testing"
            :value="true"
            data-vv-as="Is For Testing"
            v-validate="'required'"
            v-model="localState"
            @change="$forceUpdate()"
          />
          {{ "Yes" | translate }}
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
          <input
            class="form-check-input"
            type="radio"
            name="is_for_testing"
            :value="false"
            data-vv-as="Is For Testing"
            v-validate="'required'"
            v-model="localState"
            @change="$forceUpdate()"
          />
          {{ "No" | translate }}
        </label>
      </div>
    <p>{{ errors.has('is_for_testing') }}</p>
      <span
        v-if="false"
        class="text-danger d-block"
      >
        <small>{{
          "The 'Is For Testing' field is required." | translate
        }}</small>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndicatorForTestingEvaluation",
  props: {
    value: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      default: "",
    },
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      localState: this.value,
    };
  },
  watch: {
    localState(newValue) {
      // Emit an event to update the parent component with the new value
      this.$emit("input", newValue);
    },
  },
};
</script>

<style></style>
