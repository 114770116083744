/**
 * The Walking Surface Assessor evaluates each walking and standing surface outside the stall for four
 * common risk factors: ability to create trauma, influence the tendency to slip, create wear and cause
 * concussion.
 */

const _ = require('lodash');
const convert = require('convert-units');
import Assessor from './Assessor';

class WalkingSurface extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;

        this.minFeedAlleyWidth = 4.3;
        this.minPassageAlleyWidth = 3;
        this.minIsTheDistanceFromCowPensToMilkingCenterGreaterThan = 61;
    }

    getFeedAlley() {
        return this.assessor.data.feed_alley;
    }

    getCrossoverAlley() {
        return this.assessor.data.crossover_alley;
    }

    getHoldingArea() {
        return this.assessor.data.holding_area;
    }

    getParlorReturnLanes() {
        return this.assessor.data.parlor_return_lanes;
    }

    getStallAlley() {
        return this.assessor.data.stall_alley;
    }

    getTransferLanes() {
        return this.assessor.data.transfer_lanes;
    }

    getParlor() {
        return this.assessor.data.parlor;
    }

    getStepsBetweenPenAndMilkingParlor() {
        return this.assessor.data.steps_between_pen_and_milking_parlor;
    }

    getAreStepsInThePathOfMovementBetweenThePenAndMilkingParlor() {
        return this.assessor.data.are_steps_in_the_path_of_movement_between_the_pen_and_milking_parlor;
    }

    getIsTheDistanceFromCowPensToMilkingCenterGreaterThan() {
        return this.assessor.data.is_the_distance_from_cow_pens_to_milking_center_greater_than;
    }

    getMinIsTheDistanceFromCowPensToMilkingCenterGreaterThan() {
        return parseFloat(convert(this.minIsTheDistanceFromCowPensToMilkingCenterGreaterThan)
                .from('m')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm')))
            .toDecimal(0);
    }

    getDoesHerdspersonAllowTheCowsToWalkAtTheirOwnPaceToFromTheMilkingParlor() {
        return this.assessor.data.does_herdsperson_allow_the_cows_to_walk_at_their_own_pace_to_from_the_milking_parlor;
    }

    getNumberOf90DegreeTurns() {
        return this.assessor.data.number_of_90_degree_turns;
    }

    getIsTheRubberSurfaceGroovedOrFinishedToMinimizeCowSlipping() {
        return this.assessor.data.is_the_rubber_surface_grooved_or_finished_to_minimize_cow_slipping;
    }

    getIsThereAnyWireOrSharpMetalProtrudingFromTheRubberSurface() {
        return this.assessor.data.is_there_any_wire_or_sharp_metal_protruding_from_the_rubber_surface;
    }

    getFlooringTypeValue() {
        return this.assessor.data.flooring_type;
    }

    getFlooringType() {
        switch(this.getFlooringTypeValue()) {
            case 'concrete':
                return this.app.$t('Concrete');
            case 'concrete_slat':
                return this.app.$t('Concrete Slat');
            case 'rubber':
                return this.app.$t('Rubber');
            case 'other':
                return this.app.$t('Other');
        }
    }

    getAreCowsBeingExcessivelyPushedOverSteps() {
        return this.assessor.data.are_cows_being_excessively_pushed_over_steps;
    }

    getPassageAlleyWidth(roundPoint) {
        if (this.assessor.data.passage_alley_width === undefined || this.assessor.data.passage_alley_width === null) {
            return undefined;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.passage_alley_width.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.passage_alley_width)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(roundPoint);
    }

    setPassageAlleyWidth(newValue) {
        this.assessor.data.passage_alley_width = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
                .to('m'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getMinPassageAlleyWidth() {
        return parseFloat(convert(this.minPassageAlleyWidth)
                .from('m')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm')))
            .toDecimal(0);
    }

    getFeedAlleyWidth(roundPoint) {
        if (this.assessor.data.feed_alley_width === undefined || this.assessor.data.feed_alley_width === null) {
            return undefined;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.feed_alley_width.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return convert(this.assessor.data.feed_alley_width)
            .from('m')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
            .toDecimal(roundPoint);
    }

    setFeedAlleyWidth(newValue) {
        this.assessor.data.feed_alley_width = parseFloat(convert(newValue)
                .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm'))
                .to('m'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getMinFeedAlleyWidth() {
        let roundPoint = 1;
        if (this.evaluation.getMeasurements() === 'imperial') roundPoint = 0;
        return parseFloat(convert(this.minFeedAlleyWidth)
                .from('m')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'm')))
            .toDecimal(roundPoint);
    }

    /**
     * Helper
     */
    buildRiskFactorMessage(riskFactor) {
        let message = '';

        if (riskFactor) {
            if (_.includes(riskFactor, 'trauma')) {
                message = this.app.$t('Trauma');
            }
            if (_.includes(riskFactor, 'slipping')) {
                message = this.concatPlus(message, this.app.$t('Slipping'));
            }
            if (_.includes(riskFactor, 'wearing')) {
                message = this.concatPlus(message, this.app.$t('Wearing'));
            }
            if (_.includes(riskFactor, 'concussion')) {
                message = this.concatPlus(message, this.app.$t('Concussion'));
            }
            if (_.includes(riskFactor, 'n_a')) {
                message = this.app.$t('N/A');
            }
        }

        return message;
    }

    concatPlus(message, add) {
        if (message) {
            return message + ' + ' + add;
        }
        return add;
    }

};

export default WalkingSurface;