<template>
  <div class="container main-container main-container--accordion">
    <!-- START USER REPORTS -->
    <div class="row">
      <div class="col-lg-18 col-md-16">
        <h2 class="h1 mb-3">
          <span>{{ 'My Reports' | translate }}</span>
        </h2>
      </div>
    </div>
    <reports-list :user="user" id="report-list-1"></reports-list>
    <!-- END USER REPORTS -->

    <!-- START CUSTOMER REPORTS -->
    <div v-if="user && user.role === ROLES.zinpro_representative">
      <div class="row mt-5">
        <div class="col-lg-18 col-md-16">
          <h2 class="h1 mb-3">
            <span>{{ 'Customer Reports' | translate }}</span>
          </h2>
        </div>
        <div class="col-lg-6 col-md-8">
          <select
            class="form-control"
            v-model="customerId"
            @change="changeCustomer"
          >
            <option selected disabled value="null">{{
              'Select' | translate
            }}</option>
            <option
              v-for="(customer, index) in user.customers"
              :key="index"
              :value="customer.id"
            >
              {{ customer.name }} ({{
                $store.getters.getRoleName(customer.role) | translate
              }})
            </option>
          </select>
        </div>
      </div>
      <hr class="my-4 d-lg-none" />
      <div v-if="isCustomerExist">
        <div class="mt5 text-center">
          <h5 class="text-uppercase">
            {{ 'You have not selected a customer.' | translate }}
          </h5>
        </div>
      </div>
      <reports-list
        v-if="customerId"
        :user="customer"
        :customer-id="customerId"
        id="report-list-2"
      ></reports-list>
    </div>
    <!-- END CUSTOMER REPORTS -->
  </div>
</template>

<script>
import ReportsList from '@/pages/reports/ReportsList.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  GET_CUSTOMER_BY_ID,
  GET_USERS_BY_ID_GETTER,
  GET_USER_BY_ID,
} from '../../store/types';
import { ROLES } from '../../constants';

export default {
  components: {
    ReportsList,
  },
  data() {
    return {
      customer: null,
      customerId: null,
      customers: null,
      ROLES: ROLES,
      customer: null,
    };
  },
  metaInfo: {
    title: 'Evaluations',
  },
  computed: {
    ...mapState({
      user: state => state.users.user,
      // customer: state => state.users.customer,
    }),
    ...mapGetters('users', [GET_USERS_BY_ID_GETTER]),
    isCustomerExist() {
      return !this.customer || !this.customer._id;
    },
  },
  methods: {
    ...mapActions('users', [GET_CUSTOMER_BY_ID, GET_USER_BY_ID]),
    async changeCustomer() {
      this.customer = this[GET_USERS_BY_ID_GETTER](this.customerId);
    },
  },
  // async created() {
  //   const user = await this[GET_USER_BY_ID](this.user._id);
  //   this.$store.commit(`users/${SET_UPDATE_LOGIN_USER}`, user);
  // },
};
</script>
