class NotStartedAssessorSlides {

  constructor(app, report, assessor, group) {
    this.app = app;
    this.report = report;
    this.assessor = assessor;
    this.group = group;
  }

  add(tableOfContentsCalculatorPosition) {
    let assessorName = this.assessor.getName()
    this.report.tableOfContentsHelper(assessorName, tableOfContentsCalculatorPosition, this.group);
    // Add empty slide
    let slide = this.report.pptx.addSlide('Slide master');
    slide.addText(
      app.$t(assessorName + ' Assessor') + app.$t(' - ') + this.group.getName(), this.report.styleOptions.slideHeader
    ).addText(this.app.$t('Assessor not started'), {
      x: 0.5,
      y: 2.38,
      ...this.report.styleOptions.subheaderBlue
    });
  }
}

export default NotStartedAssessorSlides;
